import {
  isMessengerMessageSequenceItemUI,
  isMessengerUserInputSequenceItemUI,
  isRandomSplitSequenceItemUI,
  type MessageUI,
  MessengerTemplateType,
  type SequenceItemUI,
  UserInputValidationType
} from '@ghostmonitor/recartapis'
import { type PayloadAction } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { selectSequenceItemsById, selectSequenceItemsMetaById } from '../sequence-editor.selectors'
import {
  ButtonInitialMeta,
  messageInitialMeta,
  MessageItemInitialMeta,
  QuickReplyInitialMeta,
  type SequenceEditorState,
  sequenceItemInitialMeta,
  splitInitialMeta
} from '../sequence-editor.state'
import { setVariablesMetaInMessageHandler } from './set-variables-meta-in-message.handler'

export function updateSequenceItemActionHandler(
  state: SequenceEditorState,
  action: PayloadAction<{ sequenceItem: SequenceItemUI }>
) {
  const sequenceItemsById = selectSequenceItemsById(state)

  const sequenceItem = cloneDeep(action.payload.sequenceItem)

  if (isMessengerUserInputSequenceItemUI(sequenceItem)) {
    if (sequenceItem.logic.userInput.type === UserInputValidationType.EMAIL) {
      sequenceItem.messages = sequenceItem.messages.filter(
        (message) => message.messengerTemplateType !== MessengerTemplateType.MEDIA
      )
    }
  }

  sequenceItemsById[sequenceItem._id] = sequenceItem

  /**
   * Maintain meta for the updated sequence item
   */
  const sequenceItemsMetaById = selectSequenceItemsMetaById(state)
  sequenceItemsMetaById[sequenceItem._id] =
    sequenceItemsMetaById[sequenceItem._id] ?? cloneDeep(sequenceItemInitialMeta)

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    sequenceItem.messages.forEach((message: MessageUI, messageIndex: number) => {
      sequenceItemsMetaById[sequenceItem._id].messages.push(cloneDeep(messageInitialMeta))
      const messageMeta = sequenceItemsMetaById[sequenceItem._id].messages[messageIndex]

      setVariablesMetaInMessageHandler(state, message, messageMeta)

      if (isMessageGenericGalleryTemplate(message)) {
        message.messengerTemplatePayload.forEach((messageItem, messageItemIndex) => {
          messageMeta.messageItems.push(cloneDeep(MessageItemInitialMeta))

          messageItem.buttons.forEach(() => {
            messageMeta.messageItems[messageItemIndex].buttons.push(cloneDeep(ButtonInitialMeta))
          })
        })
      } else if (hasMessengerButtonOnMessageType(message)) {
        message.messengerTemplatePayload.buttons.forEach(() => {
          messageMeta.buttons.push(cloneDeep(ButtonInitialMeta))
        })
      }
    })
  }

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    sequenceItem.quickReplies.forEach(() => {
      sequenceItemsMetaById[sequenceItem._id].quickReplies.push({ ...QuickReplyInitialMeta })
    })
  }

  if (isRandomSplitSequenceItemUI(sequenceItem)) {
    sequenceItem.logic.randomSplit.variants.forEach((_, splitIndex) => {
      sequenceItemsMetaById[sequenceItem._id].splits[splitIndex] = cloneDeep(splitInitialMeta)
    })
  }
}
