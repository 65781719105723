import {
  type GetFontsResponse,
  type PublicFontConfig,
  type SiteFontConfig
} from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useOptinToolFonts(): UseResource<(PublicFontConfig | SiteFontConfig)[]> {
  const queryName = [QUERY_NAME.optinToolFonts]

  const {
    data: optinToolFontsData,
    isLoading,
    error
  } = useQuery<GetFontsResponse>(queryName, api.getOptinToolFonts)

  return {
    data: optinToolFontsData?.data,
    error,
    isLoading
  }
}
