import {
  type ConditionalSplitFlowItemUI,
  type ConditionalSplitV2FlowItemUI,
  type DelayFlowItemUI,
  type FlowItemUI,
  getSMSStat,
  isSMSMessageContactCardUI,
  isSMSMessageMMSUI,
  isSMSMessageTextUI,
  type RandomSplitFlowItemUI,
  type SMSMessageFlowItemUI
} from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { useSiteSelector } from '../../../../../hooks/use-site-selector'
import { flowEditorSelectors, selectIsReadOnly } from '../../../../../store/selectors'
import {
  selectSiteName,
  selectUrlGenerationSettings,
  selectSupportEmail
} from '../../../../../store/slices/site/site.selectors'
import {
  isConditionalSplitFlowItemUI,
  isConditionalSplitV2FlowItemUI,
  isDelayFlowItemUI,
  isRandomSplitFlowItemUI,
  isSMSMessageFlowItemUI
} from '../../../../../types/guards/flow-item-ui.guards'

interface SMSSegmentCountInFlow {
  smsSegmentCount: number
  mmsSegmentCount: number
  mmsToSmsSegmentCount: number
}

export interface UseSMSSegmentCountInFlow {
  min: SMSSegmentCountInFlow
  max: SMSSegmentCountInFlow
}

interface NodeWithProbability {
  id: string
  probability: number | undefined
}

const zeroedSegmentCount: SMSSegmentCountInFlow = {
  smsSegmentCount: 0,
  mmsSegmentCount: 0,
  mmsToSmsSegmentCount: 0
}

function getNextFlowItemIdsFromSMSFlowItemUI(
  flowItem: SMSMessageFlowItemUI
): NodeWithProbability[] {
  if (flowItem.item.nextSequenceItemTrigger === undefined) {
    return []
  }
  return [
    {
      id: flowItem.item.nextSequenceItemTrigger,
      probability: 1
    }
  ]
}

function getNextFlowItemIdsFromDelayFlowItemUI(flowItem: DelayFlowItemUI): NodeWithProbability[] {
  if (!flowItem.item.logic.delay.trigger) {
    return []
  }
  return [
    {
      id: flowItem.item.logic.delay.trigger,
      probability: 1
    }
  ]
}

function getNextFlowItemIdsFromConditionalSplitFlowItemUI(
  flowItem: ConditionalSplitFlowItemUI
): NodeWithProbability[] {
  const nextFlowItemIds: NodeWithProbability[] = []
  if (flowItem.item.logic.conditional_split.default_trigger) {
    nextFlowItemIds.push({
      id: flowItem.item.logic.conditional_split.default_trigger,
      probability: undefined
    })
  }
  flowItem.item.logic.conditional_split.variants.forEach((variant) => {
    const existing = nextFlowItemIds.find((nextFlowItemId) => nextFlowItemId.id === variant.trigger)
    if (variant.trigger && !existing) {
      nextFlowItemIds.push({
        id: variant.trigger,
        probability: undefined
      })
    }
  })
  return nextFlowItemIds
}

function getNextFlowItemIdsFromConditionalSplitV2FlowItemUI(
  flowItem: ConditionalSplitV2FlowItemUI
): NodeWithProbability[] {
  const nextFlowItemIds: NodeWithProbability[] = []
  if (flowItem.item.logic.conditionalSplitV2.defaultNextFlowItemTrigger) {
    nextFlowItemIds.push({
      id: flowItem.item.logic.conditionalSplitV2.defaultNextFlowItemTrigger,
      probability: undefined
    })
  }
  flowItem.item.logic.conditionalSplitV2.cases.forEach((variant) => {
    const existing = nextFlowItemIds.find(
      (nextFlowItemId) => nextFlowItemId.id === variant.nextFlowItemTrigger
    )
    if (variant.nextFlowItemTrigger && !existing) {
      nextFlowItemIds.push({
        id: variant.nextFlowItemTrigger,
        probability: undefined
      })
    }
  })
  return nextFlowItemIds
}

function getNextFlowItemIdsFromRandomSplitFlowItemUI(
  flowItem: RandomSplitFlowItemUI
): NodeWithProbability[] {
  const nextFlowItemIds: NodeWithProbability[] = []
  flowItem.item.logic.split.variants.forEach((variant) => {
    if (variant.trigger) {
      const existing = nextFlowItemIds.find(
        (nextFlowItemId) => nextFlowItemId.id === variant.trigger
      )
      if (existing) {
        existing.probability = (existing.probability ?? 0) + variant.percentage / 100
      } else {
        nextFlowItemIds.push({
          id: variant.trigger,
          probability: variant.percentage / 100
        })
      }
    }
  })
  return nextFlowItemIds
}

function getNextFlowItemIdsWithProbability(flowItem: FlowItemUI): NodeWithProbability[] {
  if (!flowItem) {
    return []
  }

  if (isSMSMessageFlowItemUI(flowItem)) {
    return getNextFlowItemIdsFromSMSFlowItemUI(flowItem)
  }

  if (isDelayFlowItemUI(flowItem)) {
    return getNextFlowItemIdsFromDelayFlowItemUI(flowItem)
  }

  if (isConditionalSplitFlowItemUI(flowItem)) {
    return getNextFlowItemIdsFromConditionalSplitFlowItemUI(flowItem)
  }

  if (isConditionalSplitV2FlowItemUI(flowItem)) {
    return getNextFlowItemIdsFromConditionalSplitV2FlowItemUI(flowItem)
  }

  if (isRandomSplitFlowItemUI(flowItem)) {
    return getNextFlowItemIdsFromRandomSplitFlowItemUI(flowItem)
  }

  throw new Error('Unknown flow item type')
}

function updateSegmentCountAccumulatorWithProbability(
  segmentCountAccumulator: UseSMSSegmentCountInFlow,
  nextItemsSegmentCounts: UseSMSSegmentCountInFlow,
  probability: number
): void {
  const {
    min: {
      smsSegmentCount: minNextSmsSegmentCount,
      mmsSegmentCount: minNextMmsSegmentCount,
      mmsToSmsSegmentCount: minNextMmsToSmsSegmentCount
    },
    max: {
      smsSegmentCount: maxNextSmsSegmentCount,
      mmsSegmentCount: maxNextMmsSegmentCount,
      mmsToSmsSegmentCount: maxNextMmsToSmsSegmentCount
    }
  } = nextItemsSegmentCounts
  segmentCountAccumulator.min.smsSegmentCount += minNextSmsSegmentCount * probability
  segmentCountAccumulator.min.mmsSegmentCount += minNextMmsSegmentCount * probability
  segmentCountAccumulator.min.mmsToSmsSegmentCount += minNextMmsToSmsSegmentCount * probability
  segmentCountAccumulator.max.smsSegmentCount += maxNextSmsSegmentCount * probability
  segmentCountAccumulator.max.mmsSegmentCount += maxNextMmsSegmentCount * probability
  segmentCountAccumulator.max.mmsToSmsSegmentCount += maxNextMmsToSmsSegmentCount * probability
}

function updateSegmentCountAccumulatorWithRange(
  segmentCountAccumulator: UseSMSSegmentCountInFlow,
  nextItemsSegmentCounts: UseSMSSegmentCountInFlow[]
): void {
  if (nextItemsSegmentCounts.length === 0) {
    return
  }

  segmentCountAccumulator.max.smsSegmentCount += Math.max(
    ...nextItemsSegmentCounts.map((stat) => stat.max.smsSegmentCount)
  )
  segmentCountAccumulator.max.mmsSegmentCount += Math.max(
    ...nextItemsSegmentCounts.map((stat) => stat.max.mmsSegmentCount)
  )
  segmentCountAccumulator.max.mmsToSmsSegmentCount += Math.max(
    ...nextItemsSegmentCounts.map((stat) => stat.max.mmsToSmsSegmentCount)
  )

  if (nextItemsSegmentCounts.length < 2) {
    return
  }

  segmentCountAccumulator.min.smsSegmentCount += Math.min(
    ...nextItemsSegmentCounts.map((stat) => stat.min.smsSegmentCount)
  )
  segmentCountAccumulator.min.mmsSegmentCount += Math.min(
    ...nextItemsSegmentCounts.map((stat) => stat.min.mmsSegmentCount)
  )
  segmentCountAccumulator.min.mmsToSmsSegmentCount += Math.min(
    ...nextItemsSegmentCounts.map((stat) => stat.min.mmsToSmsSegmentCount)
  )
}

function addSegmentCounts(
  segmentCountsA: UseSMSSegmentCountInFlow,
  segmentCountsB: UseSMSSegmentCountInFlow
): UseSMSSegmentCountInFlow {
  const {
    min: {
      smsSegmentCount: minSmsSegmentCountA,
      mmsSegmentCount: minMmsSegmentCountA,
      mmsToSmsSegmentCount: minMmsToSmsSegmentCountA
    },
    max: {
      smsSegmentCount: maxSmsSegmentCountA,
      mmsSegmentCount: maxMmsSegmentCountA,
      mmsToSmsSegmentCount: maxMmsToSmsSegmentCountA
    }
  } = segmentCountsA
  const {
    min: {
      smsSegmentCount: minSmsSegmentCountB,
      mmsSegmentCount: minMmsSegmentCountB,
      mmsToSmsSegmentCount: minMmsToSmsSegmentCountB
    },
    max: {
      smsSegmentCount: maxSmsSegmentCountB,
      mmsSegmentCount: maxMmsSegmentCountB,
      mmsToSmsSegmentCount: maxMmsToSmsSegmentCountB
    }
  } = segmentCountsB

  return {
    min: {
      smsSegmentCount: minSmsSegmentCountA + minSmsSegmentCountB,
      mmsSegmentCount: minMmsSegmentCountA + minMmsSegmentCountB,
      mmsToSmsSegmentCount: minMmsToSmsSegmentCountA + minMmsToSmsSegmentCountB
    },
    max: {
      smsSegmentCount: maxSmsSegmentCountA + maxSmsSegmentCountB,
      mmsSegmentCount: maxMmsSegmentCountA + maxMmsSegmentCountB,
      mmsToSmsSegmentCount: maxMmsToSmsSegmentCountA + maxMmsToSmsSegmentCountB
    }
  }
}

export function useSMSSegmentCountInFlow(): UseSMSSegmentCountInFlow {
  const smsCampaign = useSelector(flowEditorSelectors.selectEditorSMSCampaign)
  const flow = useSelector(flowEditorSelectors.selectEditorFlow)
  const siteName = useSiteSelector(selectSiteName)
  const urlGenerationSettings = useSiteSelector(selectUrlGenerationSettings)
  const supportEmail = useSiteSelector(selectSupportEmail)
  const isReadOnly = useSelector(selectIsReadOnly)
  const flowItems = useSelector(flowEditorSelectors.selectFlowItems)

  if (
    !flow ||
    !smsCampaign ||
    !flowItems ||
    !flow.entrySequenceItemId ||
    !siteName ||
    !urlGenerationSettings
  ) {
    return {
      min: { ...zeroedSegmentCount },
      max: { ...zeroedSegmentCount }
    }
  }

  const statConfig = {
    isReadOnly,
    siteName,
    smsCampaign,
    supportEmail,
    urlGenerationSettings
  }

  function getSegmentCountForFlowItem(
    flowItemId: string,
    visitedItemIds: string[]
  ): UseSMSSegmentCountInFlow {
    const nextItemsSegmentCountAccumulator = {
      min: { ...zeroedSegmentCount },
      max: { ...zeroedSegmentCount }
    }

    if (visitedItemIds.includes(flowItemId)) {
      return nextItemsSegmentCountAccumulator
    }
    const nextVisitedItemIds = [...visitedItemIds, flowItemId]

    const flowItem = flowItems?.find((flowItem) => flowItem._id === flowItemId)
    if (!flowItem) {
      throw new Error('Flow item not found')
    }
    const nextItemsWithProbability = getNextFlowItemIdsWithProbability(flowItem)

    const allNextItemHasProbability = nextItemsWithProbability.every(
      (nextItem) => nextItem.probability !== undefined
    )
    if (allNextItemHasProbability) {
      for (const { id, probability = 0 } of nextItemsWithProbability) {
        const nextItemSegmentCount = getSegmentCountForFlowItem(id, nextVisitedItemIds)
        updateSegmentCountAccumulatorWithProbability(
          nextItemsSegmentCountAccumulator,
          nextItemSegmentCount,
          probability
        )
      }
    } else {
      const nextItemSegmentCount = nextItemsWithProbability.map((nextItem) =>
        getSegmentCountForFlowItem(nextItem.id, nextVisitedItemIds)
      )
      updateSegmentCountAccumulatorWithRange(nextItemsSegmentCountAccumulator, nextItemSegmentCount)
    }

    if (!isSMSMessageFlowItemUI(flowItem)) {
      return nextItemsSegmentCountAccumulator
    }

    const currentItemSegmentCount = {
      min: { ...zeroedSegmentCount },
      max: { ...zeroedSegmentCount }
    }

    for (const message of flowItem.item.messages) {
      if (isSMSMessageTextUI(message)) {
        const { segmentCount } = getSMSStat(message.text, statConfig)
        currentItemSegmentCount.min.smsSegmentCount += segmentCount
        currentItemSegmentCount.max.smsSegmentCount += segmentCount
      }
      if (isSMSMessageMMSUI(message) || isSMSMessageContactCardUI(message)) {
        const { segmentCount } = getSMSStat(message.text, statConfig)
        currentItemSegmentCount.min.mmsSegmentCount += 1
        currentItemSegmentCount.max.mmsSegmentCount += 1
        currentItemSegmentCount.min.mmsToSmsSegmentCount += segmentCount
        currentItemSegmentCount.max.mmsToSmsSegmentCount += segmentCount
      }
    }

    return addSegmentCounts(nextItemsSegmentCountAccumulator, currentItemSegmentCount)
  }

  return getSegmentCountForFlowItem(flow.entrySequenceItemId, [])
}
