import { ReactComponent as ViewOnlyIcon } from '../../../../../static/images/svg/sequence-editor/view-only.svg'
import styles from './header-view-only.component.scss'

export function HeaderViewOnly() {
  return (
    <div className='flex items-center' data-testid='header-view-only'>
      <ViewOnlyIcon className='icon-sm' />
      <p className={styles.text}>View only</p>
    </div>
  )
}
