import { isAsyncThunkAction, isRejected } from '@reduxjs/toolkit'
import { type SyncThunk, type Thunk } from '../../../store/redux-helpers'
import { selectEditorSequence } from '../../../store/selectors'

export function runThunks(thunks: Thunk[]): SyncThunk {
  return async (dispatch, getState) => {
    const state = getState()
    const sequence = selectEditorSequence(state)

    let finalActionResult
    const value = !sequence?.isEnabled

    for (const thunk of thunks) {
      if (isAsyncThunkAction(thunk)) {
        const actionResult = await dispatch(thunk(value))
        if (isRejected(actionResult)) {
          return actionResult
        }

        finalActionResult = actionResult
      } else {
        await dispatch(thunk(value))
      }
    }

    return finalActionResult
  }
}
