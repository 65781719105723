import { useState } from 'react'
import { type DiscountCode } from '@ghostmonitor/recartapis'
import { type PopoverProps } from 'antd'
import { Toast } from '../../../../components/ui-kit/toast/toast.component'
import { CreateDiscountCode } from './components/step-create/create-discount-code'
import { CreateStaticDiscountCode } from './components/step-create-static/create-static-discount-code'
import { EditStaticDiscountCode } from './components/step-edit-static/edit-static-discount-code'
import { EditUniqueDiscountCode } from './components/step-edit-unique/edit-unique-discount-code'
import styles from './popover-edit-discount-code.component.scss'

interface PopoverEditDiscountCodeUniqueProps extends PopoverProps {
  discountCodeName?: string
  discountCodePoolId?: string
  url?: string
  footer: React.FC<{ discountCodeExists: boolean }>
  isEditing: boolean
  hasDiscountPoolIdOnItem?: boolean
  onDiscountCodeName: (discountCodeName: string) => void
  onSuccess: (discountCode: DiscountCode, url?: string) => void
  onCreatePoolOnDashboard: () => void
}

type EditingDiscountCodeType = 'static' | 'unique' | undefined

export function PopoverEditDiscountCodeUnique(props: PopoverEditDiscountCodeUniqueProps) {
  const [discountCodeType, setDiscountCodeType] = useState<EditingDiscountCodeType>()

  function handleDiscountCodeAdd(discountCode: DiscountCode, url?: string) {
    props.onSuccess(discountCode, url)
    if (!props.isEditing) {
      Toast({
        type: 'success',
        content: 'Your discount code is successfully added.'
      })
    }
  }

  function handleDiscountCodeUpdate(discountCode: DiscountCode, url: string) {
    props.onSuccess(discountCode, url)
  }

  function handleAddStaticDiscountCode() {
    setDiscountCodeType('static')
  }

  function handleAddUniqeDiscountCode() {
    setDiscountCodeType('unique')
  }

  function renderContent() {
    if (props.discountCodeName !== undefined) {
      return (
        <EditStaticDiscountCode
          discountCodeName={props.discountCodeName}
          url={props.url}
          footer={props.footer}
          onAdd={handleDiscountCodeAdd}
          onUpdate={handleDiscountCodeUpdate}
          isEditing
        />
      )
    }

    if (props.discountCodePoolId !== undefined) {
      return (
        <EditUniqueDiscountCode
          discountCodePoolId={props.discountCodePoolId}
          url={props.url}
          footer={props.footer}
          onAdd={handleDiscountCodeAdd}
          onUpdate={handleDiscountCodeUpdate}
          onCreatePoolOnDashboard={props.onCreatePoolOnDashboard}
          isEditing
        />
      )
    }

    if (discountCodeType === 'static') {
      return <CreateStaticDiscountCode onNext={props.onDiscountCodeName} footer={props.footer} />
    }

    if (discountCodeType === 'unique') {
      return (
        <EditUniqueDiscountCode
          discountCodePoolId={props.discountCodePoolId}
          url={props.url}
          footer={props.footer}
          onAdd={handleDiscountCodeAdd}
          onUpdate={handleDiscountCodeUpdate}
          onCreatePoolOnDashboard={props.onCreatePoolOnDashboard}
          isEditing={false}
        />
      )
    }

    return (
      <CreateDiscountCode
        footer={props.footer}
        hasDiscountPoolIdOnItem={props.hasDiscountPoolIdOnItem}
        onAddStaticDiscountCode={handleAddStaticDiscountCode}
        onAddUniqueDiscountCode={handleAddUniqeDiscountCode}
      />
    )
  }

  return <div className={styles.discountPopoverContentContainer}>{renderContent()}</div>
}
