import { type SequenceAPI, SequenceTag, type SequenceUI } from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { handleError, request } from '../../utils/request'
import {
  type PatchResourceArgs,
  type RemoveResourceArgs,
  type ToggleResourceArgs,
  type UseResource
} from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
import { useFeatureFlag } from '../use-feature-flag'

const logger = createScope('dashboard')

async function patch({ id, payload }: PatchResourceArgs<Partial<SequenceUI>>) {
  await request.patch<SequenceUI>(`sequences/${id}`, payload)
}

async function remove({ id }: RemoveResourceArgs) {
  await request.del<SequenceUI>(`sequences/${id}`)
}

export function useSequences(
  queryParams?: Record<string, unknown>,
  enabled?: boolean
): UseResource<SequenceUI[], Partial<SequenceUI>> {
  const queryClient = useQueryClient()
  const queryName = useMemo(() => [QUERY_NAME.sequences, queryParams], [queryParams])

  const isUnsubscribeAndResubscribeKeywordHandlingEnabled = useFeatureFlag(
    'enable-unsubscribe-resubscribe-keyword-handling'
  )

  let {
    isLoading,
    data: sequences,
    isError,
    refetch
  } = useQuery(
    queryName,
    async () => {
      return request.get<SequenceUI[]>('sequences', {
        params: { ...queryParams, without_sequence_items: true }
      })
    },
    {
      enabled
    }
  )

  if (!isUnsubscribeAndResubscribeKeywordHandlingEnabled) {
    sequences = sequences?.filter(
      (sequence) =>
        !sequence.tags.includes(SequenceTag.SMS) ||
        (!sequence.tags.includes(SequenceTag.UNSUBSCRIBE) &&
          !sequence.tags.includes(SequenceTag.RESUBSCRIBE))
    )
  }

  const handlePatchMutate = useCallback(
    (patchPayload: PatchResourceArgs<SequenceUI>) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(queryName)

      const sequencesQuery = queryClient.getQueryCache().find(queryName)
      const previousSequences = cloneDeep(sequencesQuery.state.data as SequenceUI[])
      const sequences = cloneDeep(sequencesQuery.state.data as SequenceUI[])
      const previousSequence = sequences.find((sequence) => sequence._id === patchPayload.id)
      previousSequence.name = patchPayload.payload.name
      queryClient.setQueryData(queryName, sequences)

      // Return a rollback function
      return () => queryClient.setQueryData(queryName, previousSequences)
    },
    [queryClient, queryName]
  )

  const handleRemoveMutate = useCallback(
    (removePayload: RemoveResourceArgs) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(queryName)

      const sequencesQuery = queryClient.getQueryCache().find(queryName)
      const sequences = cloneDeep(sequencesQuery.state.data as SequenceUI[])
      const previousSequences = queryClient.getQueryData<SequenceUI[]>(queryName)
      const remainingSequences = sequences.filter((sequence) => sequence._id !== removePayload.id)
      queryClient.setQueryData(queryName, remainingSequences)

      // Return a rollback function
      return () => queryClient.setQueryData(queryName, previousSequences)
    },
    [queryClient, queryName]
  )

  // If success then it get's reloaded everywhere, if failed, than optimistic
  // update gets reverted
  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  const handlePatchError = useCallback((err, newSequence, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Renaming flow failed. Please try again or contact support.')
  }, [])

  const handleRemoveError = useCallback((err, newSequence, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Removing flow failed. Please try again or contact support.')
  }, [])

  const { mutateAsync: mutatePatchSequence } = useMutation<
    void,
    unknown,
    PatchResourceArgs<Partial<SequenceUI>>
  >(patch, {
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  const { mutateAsync: mutateRemoveSequence } = useMutation<void, unknown, RemoveResourceArgs>(
    remove,
    {
      onMutate: handleRemoveMutate,
      onSettled: handleSettled,
      onError: handleRemoveError
    }
  )

  async function toggleSequenceStatus({ id, isEnabled }): Promise<void> {
    await request.put<SequenceAPI>(`sequences/${id}/toggle`, { isEnabled })
  }

  const { mutateAsync: mutateToggleSequence } = useMutation<void, unknown, ToggleResourceArgs>(
    toggleSequenceStatus,
    {
      onMutate: (togglePayload) => {
        queryClient.cancelQueries(queryName)
        const previousSequences = queryClient.getQueryData(queryName)
        queryClient.setQueryData(queryName, (oldSequences: SequenceUI[]) => {
          const sequenceIndex = oldSequences.findIndex(
            (sequence) => sequence._id === togglePayload.id
          )

          oldSequences[sequenceIndex].isEnabled = togglePayload.isEnabled
          return oldSequences
        })
        return () => queryClient.setQueryData(queryName, previousSequences)
      },
      onError: handleError,
      onSettled: handleSettled
    }
  )

  return {
    data: sequences,
    isLoading,
    isError,
    refetch,
    patch: mutatePatchSequence,
    removeFromList: mutateRemoveSequence,
    toggle: mutateToggleSequence
  }
}
