// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nOqY7tPLmbebBgqoarpP{display:block;height:70px;margin:17px auto 30px}`, "",{"version":3,"sources":["webpack://./src/routes/auth/login/login.style.scss"],"names":[],"mappings":"AACE,sBAEE,aAAA,CADA,WAAA,CAEA,qBAAA","sourcesContent":[":local {\n  .logo {\n    height: 70px;\n    display: block;\n    margin: 17px auto 30px auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `nOqY7tPLmbebBgqoarpP`
};
export default ___CSS_LOADER_EXPORT___;
