// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E5cjdjRFyOKU7Xgu7nsl .ant-divider-horizontal.ant-divider-with-text{font-size:12px;font-weight:400}.E5cjdjRFyOKU7Xgu7nsl .ant-btn{width:100%}.E5cjdjRFyOKU7Xgu7nsl .ant-btn-link{margin-top:10px}.E5cjdjRFyOKU7Xgu7nsl .ant-btn-link span{text-decoration:underline}.E5cjdjRFyOKU7Xgu7nsl .ant-form-item{margin-bottom:6px}.zHjrzC6b1mfb7flSYrx1 .ant-form-item-label{margin-bottom:-5px!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-select-existing.component.scss"],"names":[],"mappings":"AAGM,oEACE,cAAA,CACA,eAAA,CAGF,+BACE,UAAA,CAGF,oCACE,eAAA,CAEA,yCACE,yBAAA,CAIJ,qCACE,iBAAA,CAOF,2CACE,4BAAA","sourcesContent":[":local {\n  .selectExistingContainer {\n    :global {\n      .ant-divider-horizontal.ant-divider-with-text {\n        font-size: 12px;\n        font-weight: 400;\n      }\n\n      .ant-btn {\n        width: 100%;\n      }\n\n      .ant-btn-link {\n        margin-top: 10px;\n\n        span {\n          text-decoration: underline;\n        }\n      }\n\n      .ant-form-item {\n        margin-bottom: 6px;\n      }\n    }\n  }\n\n  .codeInputForm {\n    :global {\n      .ant-form-item-label {\n        margin-bottom: -5px !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectExistingContainer": `E5cjdjRFyOKU7Xgu7nsl`,
	"codeInputForm": `zHjrzC6b1mfb7flSYrx1`
};
export default ___CSS_LOADER_EXPORT___;
