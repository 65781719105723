import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { api } from '../../utils/api'
import { type PatchResourceArgs, type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_NAME } from './query-names'

export function useLeasablePhoneNumber(phoneNumber: string): UseResource<SMSSettings.PhoneNumber> {
  const queryName = useMemo(() => [QUERY_NAME.leasablePhoneNumber, phoneNumber], [phoneNumber])

  const {
    data,
    error: requestError,
    isLoading
  } = useQuery<SMSSettingsService.GetLeasablePhoneNumberResponse>(queryName, () =>
    api.getLeasablePhoneNumber(phoneNumber)
  )

  const { handleSettled, handleError, handleUpdate } = useMutationHelpers(queryName)

  const error: SerializedError = requestError

  const { mutateAsync: verifyPhoneNumber } = useMutation<
    void,
    unknown,
    PatchResourceArgs<Omit<SMSSettingsService.VerifyPhoneNumberRequest, 'siteId'>>
  >(
    async ({ payload }) => {
      const { details, isReadyToSubmit, phoneNumber } = payload
      await api.verifyPhoneNumber(details, isReadyToSubmit, phoneNumber)
    },
    {
      onError: handleError,
      onSettled: handleSettled,
      onMutate: handleUpdate
    }
  )

  return {
    data: data?.data,
    isLoading,
    error,
    patch: verifyPhoneNumber
  }
}
