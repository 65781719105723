import { type ConditionalSplitConditions } from '../../../components/sequence-item-node-widget/conditional-split-node-widget/conditional-split-sequence-item-node-widget.component'

export const initialConditionalSplitConditions: ConditionalSplitConditions = [
  {
    value: 'hasItemsInCart',
    label: 'Has items in cart',
    channels: ['sms', 'messenger']
  },
  {
    value: 'hasPlacedOrderWithin30Days',
    label: 'Placed order in 30 days',
    channels: ['sms', 'messenger']
  },
  {
    value: 'male',
    label: 'Male',
    channels: ['messenger']
  },
  {
    value: 'female',
    label: 'Female',
    channels: ['messenger']
  }
]
