import {
  ButtonActionType,
  LogicType,
  type MessageMediaTemplate,
  MessageType,
  MessengerTemplateType,
  type MessengerUserInputSequenceItemUI,
  NodeType,
  SequenceItemType,
  UserInputValidationType
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { LogicPortModel } from '../../port/logic-port-model'
import { BaseSequenceItemNodeModel } from '../base-sequence-item-model'
import { LOGIC_PORT_USER_INPUT_IS_VALID, LOGIC_PORT_USER_INPUT_SKIP } from '../port-names'

type UserInputLogicDefaultText = {
  [key in UserInputValidationType]: string
}

export const quickReplyContentType: UserInputLogicDefaultText = {
  [UserInputValidationType.EMAIL]: 'user_email',
  [UserInputValidationType.PHONE]: 'user_phone_number'
}

export const defaultTextInput: UserInputLogicDefaultText = {
  [UserInputValidationType.EMAIL]: 'Please enter your email address',
  [UserInputValidationType.PHONE]:
    'Want to receive special offers on your phone? Leave your number and subscribe to our text marketing club. 😍'
}

export const defaultTextValidation: UserInputLogicDefaultText = {
  [UserInputValidationType.EMAIL]:
    'Are you sure? It doesn’t seem a correct email address. Please try again in this format: me@example.com',
  [UserInputValidationType.PHONE]:
    'Are you sure? It doesn’t look like a phone number. Please use the international format line +1-415-555-5555. Could you try again?'
}

export const tooltipInputLabel: UserInputLogicDefaultText = {
  [UserInputValidationType.EMAIL]:
    'This quick reply will contain the email address tied to the user’s Facebook account.',
  [UserInputValidationType.PHONE]:
    'This quick reply will contain the phone number tied to the user’s Facebook account.'
}

export const tooltipInvalidLabel: UserInputLogicDefaultText = {
  [UserInputValidationType.EMAIL]:
    'This quick reply will contain the email address tied to the user’s Facebook account.',
  [UserInputValidationType.PHONE]:
    'This quick reply will contain the phone number tied to the user’s Facebook account.'
}

export const quickReplyLabel: UserInputLogicDefaultText = {
  [UserInputValidationType.EMAIL]: '{{user email address}}',
  [UserInputValidationType.PHONE]: '{{user phone number}}'
}

export function getDefaultLegalDisclaimerMessage(siteUrl: string): MessageMediaTemplate {
  const legalDisclaimerImageUrl = `https://app.recart.co/images/svg/sequence-editor/phone-number-legal-disclaimer.png?r=${Math.random()}`

  return {
    type: MessageType.IMAGE,
    messengerTemplateType: MessengerTemplateType.MEDIA,
    messengerTemplatePayload: {
      buttons: [
        {
          type: ButtonActionType.web_url,
          title: 'Privacy Policy',
          url: `${siteUrl}/policies/privacy-policy`
        },
        {
          type: ButtonActionType.web_url,
          title: 'Terms & Services',
          url: `${siteUrl}/policies/terms-of-service`
        }
      ],
      media_type: 'image',
      url: legalDisclaimerImageUrl
    },
    tags: ['input']
  } as MessageMediaTemplate
}

export function getNewUserInputSequenceItem(siteId: string): MessengerUserInputSequenceItemUI {
  const sequenceItemGroupId = new ObjectId().toHexString()
  const sequenceItemId = new ObjectId().toHexString()
  const userInputSequenceItem: MessengerUserInputSequenceItemUI = {
    _id: sequenceItemId,
    siteId,
    sequenceItemGroupId,
    sequenceItemIdsMap: {
      logic: new ObjectId().toHexString(),
      messages: sequenceItemId,
      validation: new ObjectId().toHexString()
    },
    type: SequenceItemType.USER_INPUT,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    isEnabled: true,
    canConvert: false,
    channel: 'messenger',
    messages: [
      {
        type: MessageType.TEXT,
        messengerTemplateType: MessengerTemplateType.BUTTON,
        messengerTemplatePayload: {
          text: defaultTextInput[UserInputValidationType.EMAIL],
          buttons: []
        },
        tags: ['input']
      },
      {
        type: MessageType.TEXT,
        messengerTemplateType: MessengerTemplateType.BUTTON,
        messengerTemplatePayload: {
          text: defaultTextValidation[UserInputValidationType.EMAIL],
          buttons: []
        },
        tags: ['validation']
      }
    ],
    quickReplies: [
      {
        content_type: quickReplyContentType[UserInputValidationType.EMAIL],
        tags: ['input']
      },
      {
        id: LOGIC_PORT_USER_INPUT_SKIP,
        content_type: 'skip',
        title: 'Skip',
        tags: ['validation']
      },
      {
        content_type: quickReplyContentType[UserInputValidationType.EMAIL],
        tags: ['validation']
      }
    ],
    logic: {
      type: LogicType.USER_INPUT,
      userInput: {
        type: UserInputValidationType.EMAIL,
        trigger: '',
        validation_item: '',
        input_skipped_trigger: ''
      }
    },
    tags: [],
    trigger: [sequenceItemId]
  }

  return userInputSequenceItem
}

export class UserInputSequenceItemNodeModel extends BaseSequenceItemNodeModel {
  constructor(sequenceItemId?: string, id?: string) {
    super(NodeType.USER_INPUT_SEQUENCE_ITEM, sequenceItemId, id)
    this.generatePorts()
  }

  private generatePorts() {
    this.addPort(new LogicPortModel(LOGIC_PORT_USER_INPUT_IS_VALID))
    this.addPort(new LogicPortModel(LOGIC_PORT_USER_INPUT_SKIP))
  }

  public getSkipPort(): LogicPortModel {
    return Object.values(this.getPorts()).find(
      (port) => port.name === LOGIC_PORT_USER_INPUT_SKIP
    ) as LogicPortModel
  }

  public getInputIsValidPort(): LogicPortModel {
    return Object.values(this.getPorts()).find(
      (port) => port.name === LOGIC_PORT_USER_INPUT_IS_VALID
    ) as LogicPortModel
  }
}
