import {
  type MessageButtonTemplate,
  type MessengerUserInputSequenceItemUI,
  UserInputValidationType
} from '@ghostmonitor/recartapis'
import {
  defaultTextInput,
  defaultTextValidation,
  getDefaultLegalDisclaimerMessage,
  quickReplyContentType
} from '../../../../routes/SequenceEditor/models/sequence-item/user-input/user-input-sequence-item-model'
import { isMessageButtonTemplate } from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import {
  createMessage,
  removeMessage,
  type UpdateUserInputTypeAction
} from '../sequence-editor.actions'
import { selectSequenceItem } from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'
import { createMessageActionHandler } from './create-message.handler'
import { removeMessageActionHandler } from './remove-message.handler'

function getMessageRole(message: MessageButtonTemplate): 'input' | 'validation' {
  return message.tags.includes('input') ? 'input' : 'validation'
}

function getDefaultText(
  message: MessageButtonTemplate,
  userInputLogicType: UserInputValidationType
): string {
  return {
    input: defaultTextInput,
    validation: defaultTextValidation
  }[getMessageRole(message)][userInputLogicType]
}

export function updateUserInputTypeActionHandler(
  state: SequenceEditorState,
  action: UpdateUserInputTypeAction
) {
  const sequenceItem = selectSequenceItem<MessengerUserInputSequenceItemUI>(action.sequenceItemId)(
    state
  )
  const previousType = sequenceItem.logic.userInput.type

  sequenceItem.logic.userInput.type = action.userInputType

  sequenceItem.messages.forEach((message) => {
    if (isMessageButtonTemplate(message)) {
      const prevText = message.messengerTemplatePayload.text
      if (prevText === getDefaultText(message, previousType)) {
        const nextText = getDefaultText(message, action.userInputType)
        message.messengerTemplatePayload.text = nextText
      }
    }
  })

  sequenceItem.quickReplies[0].content_type = quickReplyContentType[action.userInputType]
  sequenceItem.quickReplies[2].content_type = quickReplyContentType[action.userInputType]

  if (action.userInputType === UserInputValidationType.PHONE) {
    const legalDisclaimerMessage = getDefaultLegalDisclaimerMessage(action.siteUrl)
    createMessageActionHandler(
      state,
      createMessage(action.sequenceItemId, legalDisclaimerMessage, 0)
    )
  } else {
    removeMessageActionHandler(state, removeMessage(action.sequenceItemId, 0))
  }
}
