import styles from './port.component.scss'
import { ReactComponent as BlueBoltIcon } from '../../../../static/images/svg/sequence-editor/bolt-blue.svg'
import { ReactComponent as SetBoltIcon } from '../../../../static/images/svg/sequence-editor/bolt-set.svg'
import cn from 'classnames'

export interface PortProps {
  hasHint?: boolean
  hintOrientation?: 'left' | 'right'
  doCorrectAlignment?: boolean
  isEntry?: boolean
  isLinked?: boolean
  isPulsing?: boolean
  name: string
  nodeId: string
}

export function Port(props: PortProps) {
  const {
    hasHint = true,
    hintOrientation = 'left',
    doCorrectAlignment = false,
    isEntry = false,
    isLinked = false,
    isPulsing = false,
    name,
    nodeId
  } = props

  const portStyles = cn({
    [styles['port-component']]: true,
    [styles.linked]: isLinked,
    [styles.entry]: isEntry,
    [styles.pulsing]: isPulsing,
    [styles[`correction-to-${hintOrientation}`]]: !doCorrectAlignment,
    port: true
  })

  const hintStyles = cn({
    [styles.hint]: hasHint,
    [styles[hintOrientation]]: true
  })

  const renderBoltIcon = () => {
    return isLinked ? (
      <SetBoltIcon className={styles.icon} data-testid='set-bolt-icon' />
    ) : (
      <BlueBoltIcon className={styles.icon} data-testid='blue-bolt-icon' />
    )
  }

  const renderHint = () => {
    return hasHint ? (
      <div className={hintStyles} data-testid='hint'>
        Drag
      </div>
    ) : null
  }

  return (
    <div className={portStyles} data-name={name} data-nodeid={nodeId} data-testid='port-component'>
      {renderBoltIcon()}
      {renderHint()}
    </div>
  )
}
