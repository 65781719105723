import { type CustomerChatSettings } from '@ghostmonitor/recartapis'

export const getCustomerChatTemplate = (): CustomerChatSettings => ({
  themeColor: '#0A7Cff',
  greetingMessage: 'Hi! How can we help you?',
  greetingDialogDisplay: 'hide',
  greetingDialogDelay: 0,
  // @ts-ignore
  sequenceId: null,
  customCSS: ''
})
