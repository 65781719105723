import cn from 'classnames'
import { type SequenceEditorHeaderProps } from '../../types/sequence-editor-header-props'
import { HeaderBack } from './components/header-back.component'
import { HeaderInternalDuplicateButton } from './components/header-internal-duplicate-button.component'
import { HeaderSendSMSTestButton } from './components/header-send-sms-test-button.component'
import { HeaderStatusText } from './components/header-status-text.component'
import { HeaderTimeFrameFilter } from './components/header-time-frame-filter.component'
import { JoinedASegmentActivateButton } from './components/joined-a-segment-activate-button.component'
import { JoinedASegmentSaveButton } from './components/joined-a-segment-save-button.component'
import { LastUpdated } from './components/last-updated.component'
import styles from './header.component.scss'

export function JoinedASegmentHeader(props: SequenceEditorHeaderProps) {
  return (
    <div className={styles.container} ref={props.headerRef}>
      <div className={cn('flex justify-between', styles.headerContainer)}>
        <div className='flex items-center'>
          <HeaderBack />
          <LastUpdated />
          <HeaderInternalDuplicateButton />
        </div>
        <div className='flex space-x-3 items-center'>
          <HeaderTimeFrameFilter />
          <HeaderStatusText />
          <div className='flex items-center'>
            <HeaderSendSMSTestButton />
            <JoinedASegmentSaveButton />
            <JoinedASegmentActivateButton />
          </div>
        </div>
      </div>
    </div>
  )
}
