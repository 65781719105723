import { type Blast, type BlastListResponse } from '@ghostmonitor/recartapis'
import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { getBlastUIStatus } from '../../components/sms-campaign-list/utils/get-blast-ui-status'
import { patchLocalBlastBySequenceId } from '../../routes/SequenceEditor/utils/localstorage'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { request } from '../../utils/request'
import { hooks } from '../hooks'
import {
  type PatchResourceArgs,
  type RemoveResourceArgs,
  type RenameResourceArgs,
  type UseResource
} from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
const logger = createScope('dashboard')

async function remove({ id }: RemoveResourceArgs) {
  await request.del<Blast>(`blasts/${id}`)
}

async function patch({ id, payload }: PatchResourceArgs<Blast>) {
  await request.patch<Blast>(`blasts/${id}`, payload)
}

interface BlastListQueryParams {
  segmentId: string
}

export function useBlasts(
  segmentId?: string,
  options?: UseQueryOptions<BlastListResponse>
): UseResource<Blast[], Partial<Blast>> {
  const queryClient = useQueryClient()

  const params: BlastListQueryParams = useMemo(
    () => ({
      ...(segmentId && { segmentId })
    }),
    [segmentId]
  )

  const queryName = useMemo(() => [QUERY_NAME.blasts, params], [params])

  const {
    isLoading,
    data: blasts,
    isError
  } = useQuery(
    queryName,
    async () => {
      return request.get<BlastListResponse>('blasts', {
        params
      })
    },
    options
  )

  const blastsWithUIStatus = blasts?.data.map((blast) => {
    return { ...blast, status: getBlastUIStatus(blast) }
  })

  const { patch: patchSequence } = hooks.useSequences()
  const { invalidateCache: invalidateSequencesCache } = hooks.useSequencesCache()

  const rename = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      const blast = blasts.data.find((blast) => blast._id === id)
      return patchSequence({ id: blast.sequenceId, payload: { name } })
    },
    [blasts, patchSequence]
  )

  const handlePatchMutate = useCallback(
    (patchPayload: PatchResourceArgs<Blast>) => {
      const blastsQuery = queryClient.getQueryCache().find(queryName)
      const previousBlasts = cloneDeep(blastsQuery.state.data as BlastListResponse)

      const newData: BlastListResponse = {
        ...previousBlasts,
        data: previousBlasts.data.map((item) => {
          if (item._id === patchPayload.id) {
            return { ...item, ...patchPayload.payload }
          }
          return item
        })
      }

      queryClient.setQueryData(queryName, newData)

      // Return a rollback function
      return () => queryClient.setQueryData(queryName, previousBlasts)
    },
    [queryClient, queryName]
  )

  const handleRenameMutate = useCallback(
    (renamePayload: RenameResourceArgs) => {
      const blast = blasts.data.find((blast) => blast._id === renamePayload.id)
      const blastsQuery = queryClient.getQueryCache().find(queryName)
      const previousBlasts = cloneDeep(blastsQuery.state.data as BlastListResponse)

      const newData: BlastListResponse = {
        ...previousBlasts,
        data: previousBlasts.data.map((item) => {
          if (item._id === renamePayload.id) {
            return { ...item, name: renamePayload.name }
          }
          return item
        })
      }

      queryClient.setQueryData(queryName, newData)

      patchLocalBlastBySequenceId(blast.sequenceId, { name: renamePayload.name })

      invalidateSequencesCache()

      // Return a rollback function
      return () => queryClient.setQueryData(queryName, previousBlasts)
    },
    [queryClient, queryName, invalidateSequencesCache, blasts]
  )

  const handleRemove = useCallback(
    (removePayload: RemoveResourceArgs) => {
      queryClient.cancelQueries(queryName)

      const dataQuery = queryClient.getQueryCache().find(queryName)
      const previousData = cloneDeep(dataQuery.state.data as BlastListResponse)

      const newData: BlastListResponse = {
        ...previousData,
        data: previousData.data.filter((item) => item._id !== removePayload.id)
      }

      queryClient.setQueryData(queryName, newData)

      return () => queryClient.setQueryData(queryName, previousData)
    },
    [queryClient, queryName]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  const handlePatchError = useCallback((err, newBlast, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Updating your SMS campaign failed. Please try again or contact support.')
  }, [])

  const handleError = useCallback((err, _, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Removing your SMS campaign failed. Please try again or contact support.')
  }, [])

  const { mutateAsync: mutatePatchBlast } = useMutation<void, unknown, PatchResourceArgs<Blast>>(
    patch,
    {
      onMutate: handlePatchMutate,
      onSettled: handleSettled,
      onError: handlePatchError
    }
  )

  const { mutateAsync: mutateRenameBlast } = useMutation<
    void,
    unknown,
    { id: string; name: string }
  >(rename, {
    onMutate: handleRenameMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  const { mutateAsync: mutateRemoveBlast } = useMutation<void, unknown, RemoveResourceArgs>(
    remove,
    {
      onMutate: handleRemove,
      onSettled: handleSettled,
      onError: handleError
    }
  )

  return {
    data: blastsWithUIStatus,
    isLoading,
    isError,
    removeFromList: mutateRemoveBlast,
    patch: mutatePatchBlast,
    rename: mutateRenameBlast
  }
}
