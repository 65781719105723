import cn from 'classnames'
import styles from './quick-reply-placeholder.component.scss'

export interface QuickReplyPlaceholderProps {
  onClick: () => void
  blurred?: boolean
  children?: React.ReactNode
}

export function QuickReplyPlaceholder(props: QuickReplyPlaceholderProps) {
  return (
    <div
      data-testid='add-quick-reply'
      className={cn(styles['quick-reply-port'], { [styles.blurred]: props.blurred })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}
