import { type GetRecalculationStatusResponse, type Site } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'

export function useSegmentRecalculation(): UseResource<{ status: string }> & {
  isRecalculationInProgress: boolean
} {
  function getIsRecalculationInProgress(data: Site['segmentRecalculation']): boolean {
    return data?.status === 'in_progress'
  }

  const { isLoading, data, error } = useQuery<GetRecalculationStatusResponse, AxiosError>(
    [],
    async () => {
      return request.get<GetRecalculationStatusResponse>(`site/segment-recalculation`)
    },
    {
      refetchInterval: (data) => {
        const isRecalculationInProgress = getIsRecalculationInProgress(data?.data)
        if (isRecalculationInProgress) {
          return 10000
        } else {
          return false
        }
      }
    }
  )

  return {
    isLoading,
    data: data?.data,
    isRecalculationInProgress: getIsRecalculationInProgress(data?.data),
    error
  }
}
