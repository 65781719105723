import { type Store } from '@reduxjs/toolkit'
import type { AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { selectEditorSequence } from '../../../../store/selectors'
import {
  sequenceLoadedFromDraft,
  sequenceLoadedFromServer
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { loadBlastThunk } from '../../../../store/slices/sequence-editor/thunks/load-blast.thunk'
import { validateSequenceThunk } from '../../../../store/slices/sequence-editor/thunks/validate-sequence.thunk'
import { isBlastSequence } from '../../utils/assert-sequence-type'
import { isBlastReadonly } from '../../utils/is-blast-read-only'

export function validateSequenceOnLoadMiddleware(
  store: Store<DashboardState>
): (next: any) => (action: ValidatableAction) => void {
  const dispatch: AppDispatch = store.dispatch
  return (next) => (action: ValidatableAction) => {
    next(action)
    const state = store.getState()
    if (
      action.type === sequenceLoadedFromServer.type ||
      action.type === sequenceLoadedFromDraft.type
    ) {
      const sequence = selectEditorSequence(state)
      if (!isBlastSequence(sequence)) {
        dispatch(validateSequenceThunk())
      }
    } else if (loadBlastThunk.fulfilled.match(action)) {
      if (!isBlastReadonly(action.payload)) {
        dispatch(validateSequenceThunk())
      }
    }
  }
}
