// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pKZpq1bWVVHEoKiFBWNx{font-size:14px}.uWxucMvzwcigYoL5gPkK{align-items:center;display:flex;flex-direction:column;font-size:16px;justify-content:center;margin-bottom:18px}.XPVaPIkEZexTi9yEq5HQ{margin-bottom:18px;margin-top:33px}.XPVaPIkEZexTi9yEq5HQ svg{width:69px}.fVr4lYe8NN8yLtkRbKUM{align-items:left;border-top:1px solid #d4d4d4;display:flex;flex-direction:column;justify-content:center;padding:6px 12px}.kjvq7YARfVWcoEbVljqT{font-weight:700;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/message-templates/messenger-review-template/messenger-review-template.component.scss"],"names":[],"mappings":"AACE,sBACE,cAAA,CAGF,sBAIE,kBAAA,CAHA,YAAA,CACA,qBAAA,CAIA,cAAA,CAHA,sBAAA,CAEA,kBACA,CAGF,sBAEE,kBAAA,CADA,eACA,CAEA,0BACE,UAAA,CAIJ,sBAGE,gBAAA,CAEA,4BAAA,CAJA,YAAA,CACA,qBAAA,CAEA,sBAAA,CAEA,gBAAA,CAGF,sBACE,eAAA,CACA,iBAAA","sourcesContent":[":local {\n  .container {\n    font-size: 14px;\n  }\n\n  .product {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 18px;\n    font-size: 16px;\n  }\n\n  .productPic {\n    margin-top: 33px;\n    margin-bottom: 18px;\n\n    svg {\n      width: 69px;\n    }\n  }\n\n  .item {\n    display: flex;\n    flex-direction: column;\n    align-items: left;\n    justify-content: center;\n    border-top: 1px solid #d4d4d4;\n    padding: 6px 12px;\n  }\n\n  .itemName {\n    font-weight: bold;\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pKZpq1bWVVHEoKiFBWNx`,
	"product": `uWxucMvzwcigYoL5gPkK`,
	"productPic": `XPVaPIkEZexTi9yEq5HQ`,
	"item": `fVr4lYe8NN8yLtkRbKUM`,
	"itemName": `kjvq7YARfVWcoEbVljqT`
};
export default ___CSS_LOADER_EXPORT___;
