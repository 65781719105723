import {
  getTextFromMessage,
  hasDiscountCodeOnButton,
  hasTextOnMessage,
  MessageType,
  type MessageUI,
  type SequenceItemUI
} from '@ghostmonitor/recartapis'
import { type Action, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { type AppDispatch } from '../../../store/create-store'
import { type DashboardState } from '../../../store/dashboard.state'
import { selectSequenceItem } from '../../../store/selectors'
import {
  type RemoveButtonAction,
  type RemoveMessageAction,
  REMOVE_BUTTON,
  REMOVE_MESSAGE,
  type UpdateButtonAction,
  updateDiscountCodesOnSequenceItem,
  updateDiscountPoolIdsOnSequenceItem,
  type UpdateMessageAction,
  UPDATE_BUTTON,
  UPDATE_MESSAGE
} from '../../../store/slices/sequence-editor/sequence-editor.actions'
import { hasMessengerButtonOnMessageType } from '../utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../utils/assert-sequence-item-type'
import { getDiscountCodesFromText } from '../validators/utils/get-discount-codes-from-text'
import { getDiscountPoolIdsFromText } from '../validators/utils/get-discount-pool-ids-from-text'

export type ValidableMessageAction =
  | UpdateMessageAction
  | RemoveMessageAction
  | UpdateButtonAction
  | RemoveButtonAction

function isValidableAction(action: Action): action is ValidableMessageAction {
  return [UPDATE_MESSAGE, REMOVE_MESSAGE, UPDATE_BUTTON, REMOVE_BUTTON].includes(action.type)
}

const DEBOUNCE_DELAY = 500

const getUpdateDiscountCodesOnSequenceItem = (dispatch: AppDispatch) =>
  debounce(
    (sequenceItem: SequenceItemUI) => {
      const discountCodes = new Set<string>()
      if (hasMessageOnSequenceItemType(sequenceItem)) {
        sequenceItem.messages.forEach((message: MessageUI) => {
          if (hasMessengerButtonOnMessageType(message)) {
            message.messengerTemplatePayload.buttons.forEach((button) => {
              if (hasDiscountCodeOnButton(button) && button.discount_code) {
                discountCodes.add(button.discount_code)
              }
            })
          }

          if (
            message.type === MessageType.ABANDONED_CART_CAROUSEL &&
            Array.isArray(message.messengerTemplatePayload)
          ) {
            message.messengerTemplatePayload.forEach((message) => {
              message.buttons.forEach((button) => {
                if (hasDiscountCodeOnButton(button) && button.discount_code) {
                  discountCodes.add(button.discount_code)
                }
              })
            })
          }

          if (hasTextOnMessage(message)) {
            const text = getTextFromMessage(message)
            const messageDiscountCodes = getDiscountCodesFromText(text)
            messageDiscountCodes.forEach((discountCode) => discountCodes.add(discountCode))
          }
        })
      }
      const sequenceItemDiscountCodes = Array.from(discountCodes).map((discountCode) => ({
        id: '',
        code: discountCode
      }))
      dispatch(
        updateDiscountCodesOnSequenceItem({
          sequenceItemId: sequenceItem._id,
          discounts: sequenceItemDiscountCodes
        })
      )
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )

const getUpdateDiscountPoolIdsOnSequenceItem = (dispatch: AppDispatch) =>
  debounce(
    (sequenceItem: SequenceItemUI) => {
      const discountPoolIds = new Set<string>()
      if (hasMessageOnSequenceItemType(sequenceItem)) {
        sequenceItem.messages.forEach((message: MessageUI) => {
          if (hasTextOnMessage(message)) {
            const text = getTextFromMessage(message)
            const messageDiscountPoolIds = getDiscountPoolIdsFromText(text)
            messageDiscountPoolIds.forEach((poolId) => discountPoolIds.add(poolId))
          }
        })
      }
      dispatch(
        updateDiscountPoolIdsOnSequenceItem({
          sequenceItemId: sequenceItem._id,
          poolIds: Array.from(discountPoolIds)
        })
      )
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )

export function updateDiscountCodesOnSequenceItemMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const updateDiscountCodesOnSequenceItem = getUpdateDiscountCodesOnSequenceItem(dispatch)
  const updateDiscountPoolIdsOnSequenceItem = getUpdateDiscountPoolIdsOnSequenceItem(dispatch)

  return (next) =>
    (action: ValidableMessageAction): void => {
      next(action)

      const state = store.getState()

      if (isValidableAction(action)) {
        const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
        updateDiscountCodesOnSequenceItem(sequenceItem)
        updateDiscountPoolIdsOnSequenceItem(sequenceItem)
      }
    }
}
