import { type ContactCardSettings } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import { AvatarSkeleton } from '../../../../../components/ui-kit/loader/avatar-skeleton.component'
import { TextSkeleton } from '../../../../../components/ui-kit/loader/text-skeleton.component'
import { Tooltip } from '../../../../../components/ui-kit/tooltip/tooltip.component'
import { hooks } from '../../../../../hooks/hooks'
import { ReactComponent as ArrowRightIcon } from '../../../../../static/ui-kit/icons/small/arrow-right.svg'
import styles from './sms-contact-card-template.component.scss'

export function SMSContactCardTemplate() {
  const { data: site, isLoading: isSiteDataLoading } = hooks.useSite()

  const isLoading = isSiteDataLoading || site === undefined
  const contactCardSettingsDefault: ContactCardSettings = {
    imageUrl: '',
    siteInfo: '',
    addSiteUrl: false,
    addSupportEmail: false
  }
  const contactCardSettings = site?.contactCardSettings ?? contactCardSettingsDefault

  if (isLoading) {
    return (
      <div className={styles.contactCardPreviewMini}>
        <div className='mr-2'>
          <TextSkeleton isLoading size='base' width={140} />
        </div>
        <AvatarSkeleton size='sm' />
        <ArrowRightIcon className={cn('icon-tiny ml-2', styles.iconColor)} />
      </div>
    )
  }

  return (
    <Tooltip title='You can edit contact card details on the SMS settings page.' placement='right'>
      <div className='flex p-3 items-center w-full justify-between'>
        <div className='mr-2'>
          <div
            className={cn(
              'text-sm font-bold text-ellipsis truncate w-full',
              styles.miniPreviewText
            )}
          >
            {site.name}
          </div>
          <div className={cn('text-tiny text-ellipsis truncate w-full', styles.miniPreviewText)}>
            {contactCardSettings.siteInfo}
          </div>
        </div>
        {contactCardSettings.imageUrl !== '' ? (
          <div
            className={cn(styles.previewImage, styles.mini)}
            style={{ backgroundImage: `url(${contactCardSettings.imageUrl})` }}
          />
        ) : (
          <div
            className={cn(styles.previewImage, styles.mini)}
            style={{ background: 'linear-gradient(180deg, #a5acb9 0%, #858a95 100%)' }}
          >
            {site.name.slice(0, 1)}
          </div>
        )}
        <ArrowRightIcon className={cn('icon-tiny ml-2', styles.iconColor)} />
      </div>
    </Tooltip>
  )
}
