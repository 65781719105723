import { type FlowDiagram, type FlowItemUI, type FlowUI } from '@ghostmonitor/recartapis'
import { type Action, type Store, isAnyOf } from '@reduxjs/toolkit'
import { produce } from 'immer'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { type AppDispatch } from '../../../store/create-store'
import { type DashboardState } from '../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../store/selectors'
import {
  edgeAdded,
  localDraftChanged,
  nodeRemoved,
  removeDeprecatedConditionalSplitClicked
} from '../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'
import { changeCustomIntegrationSettingsThunk } from '../../../store/slices/flow-editor/thunks/change-custom-integration.thunk'
import { changeTriggerThunk } from '../../../store/slices/flow-editor/thunks/change-trigger.thunk'
import { loadSMSCampaignThunk } from '../../../store/slices/flow-editor/thunks/load-sms-campaign.thunk'
import { saveFlowThunk } from '../../../store/slices/flow-editor/thunks/save-flow.thunk'
import { saveSMSCampaignThunk } from '../../../store/slices/flow-editor/thunks/save-sms-campaign.thunk'
import { toggleFlowEditorFlowThunk } from '../../../store/slices/flow-editor/thunks/toggle-flow-editor-flow.thunk'
import { error } from '../../../utils/logger/flow-editor.logger'
import {
  getFlowEditorLocalStorage,
  patchFlowEditorLocalStorage,
  setFlowEditorLocalStorage,
  setSMSCampaignLocalStorage,
  updatFlowDiagramLocalStorage,
  updatFlowLocalStorage,
  updateFlowItemLocalStorage,
  updateSyncKey
} from '../utils/local-storage'

const DEBOUNCE_DELAY = 500

function sanitizeNodes(nodes: FlowDiagram.Node[]): FlowDiagram.Node[] {
  return produce(nodes, (draftNodes) => {
    draftNodes.forEach((node) => {
      delete node.width
      delete node.height
      delete node.selected
    })
  })
}

function getLocalDraftChanged(dispatch: AppDispatch) {
  return debounce(
    (reason: string, flowId: string) => {
      patchFlowEditorLocalStorage(flowId, {
        unsavedChanges: true
      })
      dispatch(localDraftChanged({ reason }))
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

function getUpdateLocalFlowItem() {
  return debounce(
    (flowId: string, flowItem: FlowItemUI) => {
      updateFlowItemLocalStorage(flowId, flowItem)
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

function getUpdateLocalFlow() {
  return debounce(
    (flowId: string, flow: FlowUI) => {
      updatFlowLocalStorage(flowId, flow)
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

function getUpdateLocalFlowEditorDiagram() {
  return debounce(
    (flowId: string, flowEditorDiagram: FlowDiagram.FlowDiagram) => {
      updatFlowDiagramLocalStorage(flowId, flowEditorDiagram)
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

export function draftMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const dispatchLocalDraftChanged = getLocalDraftChanged(dispatch)
  const updateLocalFlow = getUpdateLocalFlow()
  const updateLocalFlowDiagram = getUpdateLocalFlowEditorDiagram()
  const updateLocalFlowItem = getUpdateLocalFlowItem()

  return (next) => (action: Action) => {
    next(action)

    const state = store.getState()
    const flow = flowEditorSelectors.selectEditorFlow(state)
    const flowMeta = flowEditorSelectors.selectEditorFlowMeta(state)
    const isReadOnly = flowEditorSelectors.selectIsReadOnly(state)

    if (!flow) {
      return
    }

    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      dispatchLocalDraftChanged.cancel()
      updateLocalFlow.cancel()
      updateLocalFlowDiagram.cancel()
      updateLocalFlowItem.cancel()
    }

    /****************************
     * Actions in READONLY mode
     ****************************/

    /**************************
     * Read only INDEPENDENT
     **************************/

    if (isAnyOf(saveFlowThunk.pending, saveSMSCampaignThunk.pending)(action)) {
      patchFlowEditorLocalStorage(flow._id, {
        unsavedChanges: false
      })
    }

    if (isAnyOf(saveFlowThunk.rejected, saveSMSCampaignThunk.rejected)(action)) {
      patchFlowEditorLocalStorage(flow._id, {
        unsavedChanges: true
      })
    }

    if (isAnyOf(saveFlowThunk.fulfilled)(action)) {
      patchFlowEditorLocalStorage(flow._id, {
        isEverSaved: true,
        flow: { updatedAt: action.payload.updatedAt }
      })
    }

    if (isAnyOf(saveSMSCampaignThunk.fulfilled)(action)) {
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign not found')
      }

      setSMSCampaignLocalStorage(smsCampaign)
    }

    if (toggleFlowEditorFlowThunk.fulfilled.match(action)) {
      patchFlowEditorLocalStorage(flow._id, {
        flow: {
          isEnabled: action.payload.isEnabled,
          ...(action.payload.isEnabled && { hasEverBeenEnabled: true }),
          sequenceItems: flow.sequenceItems.map((sequenceItem) => ({
            ...sequenceItem,
            isEnabled: action.payload.isEnabled
          }))
        }
      })
    }

    /**************
     * Rename flow
     /*************/
    if (flowEditorActions.flowRenamed.match(action)) {
      updateLocalFlow(flow._id, flow)
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

      if (smsCampaign !== undefined) {
        setSMSCampaignLocalStorage(smsCampaign)
      }
    }

    /********************************
     * Actions in NOT READONLY mode
     ********************************/
    if (isReadOnly) {
      return
    }

    /***************
     * Flow loaded
     ***************/
    if (flowEditorActions.flowLoaded.match(action)) {
      if (flowMeta.isEverSaved === null) {
        throw new Error('isEverSaved should be set after flow is loaded')
      }

      if (flowMeta.unsavedChanges === null) {
        throw new Error('unsavedChanges should be set after flow is loaded')
      }

      setFlowEditorLocalStorage(flow._id, {
        flow,
        isEverSaved: flowMeta.isEverSaved,
        unsavedChanges: flowMeta.unsavedChanges,
        isPreviewOn: flowMeta.isPreviewOn,
        isAITextGeneratorWizardClosed: flowMeta.isAITextGeneratorWizardClosed,
        isWelcomeFlowWarningModalClosed: flowMeta.isWelcomeFlowWarningModalClosed
      })
      return
    }

    /***********************
     * SMS Campaign loaded
     ***********************/
    if (loadSMSCampaignThunk.fulfilled.match(action)) {
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign not found')
      }

      setSMSCampaignLocalStorage(smsCampaign)
      return
    }

    /****************
     * Update flow
     ****************/
    if (
      isAnyOf(
        flowEditorActions.nodeInserted, // flow.flowEditor.nodes
        flowEditorActions.nodeDuplicated, // flow.flowEditor.nodes
        nodeRemoved, // flow.flowEditor.nodes
        removeDeprecatedConditionalSplitClicked, // flow.flowEditor.nodes
        flowEditorActions.joinedASegmentSettingsChanged, // flow.sendingFrequency
        flowEditorActions.customIntegrationSettingsChanged, // flow.sendingFrequency
        flowEditorActions.automatedFlowSendingSettingsChanged, // flow.isQuietHoursEnabled
        flowEditorActions.edgeRemoved, // flow.flowEditor.edges
        flowEditorActions.edgeAdded, // flow.flowEditor.edges,
        changeTriggerThunk.fulfilled, // flow.entrySequenceItemId
        changeCustomIntegrationSettingsThunk.fulfilled, // flow.entrySequenceItemId
        flowEditorActions.conditionV2ValueChanged,
        flowEditorActions.conditionV2ValueDeleted,
        flowEditorActions.conditionV2Added,
        flowEditorActions.updateKeywordReplyTagOnFlow
      )(action)
    ) {
      updateLocalFlow(flow._id, flow)
      dispatchLocalDraftChanged('flowChanged', flow._id)
      updateSyncKey(flow._id)
    }

    /**************
     * AI Wizard closed after inserting the generated content
     **************/
    if (isAnyOf(flowEditorActions.AIGeneratedContentGotCreated)(action)) {
      updatFlowLocalStorage(flow._id, flow)
      dispatchLocalDraftChanged('aiGeneratedContentGotCreated', flow._id)
      updateSyncKey(flow._id)
      patchFlowEditorLocalStorage(flow._id, {
        isAITextGeneratorWizardClosed: flowMeta.isAITextGeneratorWizardClosed
      })
    }

    /***************************************
     * Update smsCampaign AND Flow => draft change
     /**************************************/
    if (isAnyOf(flowEditorActions.smsCampaignSettingsChanged)(action)) {
      updateLocalFlow(flow._id, flow)
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign not found')
      }

      setSMSCampaignLocalStorage(smsCampaign)
      dispatchLocalDraftChanged('smsCampaignSettingsChanged', flow._id)
      updateSyncKey(flow._id)
    }

    /***************************************
     * Update smsCampaign => draft change
     /**************************************/
    if (
      isAnyOf(
        flowEditorActions.smsCampaignTargetRulesChanged,
        flowEditorActions.applySMSCampaignTargetRules,
        flowEditorActions.removeSMSCampaignTargetRule
      )(action)
    ) {
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign not found')
      }

      // patchFlowEditorLocalStorage(flow._id, {
      //   unsavedChanges: true
      // })

      setSMSCampaignLocalStorage(smsCampaign)
      dispatchLocalDraftChanged('smsSettingsChanged', flow._id)
      updateSyncKey(flow._id)
    }

    /***************************************
     * Update smsCampaign => no draft change
     /***************************************/
    if (isAnyOf(flowEditorActions.smsCampaignStatusChanged)(action)) {
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign not found')
      }

      setSMSCampaignLocalStorage(smsCampaign)
    }

    /**********************
     * Update one flowItem
     **********************/
    if (
      isAnyOf(
        flowEditorActions.messageChanged,
        flowEditorActions.rteInsertEmoji,
        flowEditorActions.rteInsertLink,
        flowEditorActions.rteInsertVariable,
        flowEditorActions.rteInsertDiscountCode,
        flowEditorActions.rteEditLinkVariable,
        flowEditorActions.rteEditVariable,
        flowEditorActions.rteRemoveDiscountCode,
        flowEditorActions.rteRemoveLinkVariable,
        flowEditorActions.rteRemoveVariable,
        flowEditorActions.secondaryPanelClosed,
        flowEditorActions.addItemAddImageClicked,
        flowEditorActions.addItemAddContactCardClicked,
        flowEditorActions.deleteMMSAttachmentClicked,
        flowEditorActions.mediaUploadCompleted,
        flowEditorActions.delayItemChanged,
        flowEditorActions.randomSplitElementValueChanged,
        flowEditorActions.randomSplitElementAdded,
        flowEditorActions.randomSplitElementDeleted,
        flowEditorActions.conditionAdded,
        flowEditorActions.conditionDeleted,
        flowEditorActions.conditionValueChanged,
        flowEditorActions.conditionV2ValueChanged,
        flowEditorActions.conditionV2ValueDeleted,
        flowEditorActions.conditionV2Added,
        flowEditorActions.updateDiscountCodesOnFlowItem,
        flowEditorActions.updateDiscountPoolIdsOnFlowItem,
        flowEditorActions.updateKeywordRepliesOnFlowItem,
        flowEditorActions.insertAIRephrasedText
      )(action)
    ) {
      const flowItem = flowEditorSelectors.selectFlowItem(action.payload.flowItemId)(state)
      updateLocalFlowItem(flow._id, flowItem)
      dispatchLocalDraftChanged('flowItemChanged', flow._id)
      updateSyncKey(flow._id)
    }

    // todo add flowEditorActions.AIGeneratedContentGotCreated related flowItem updates here

    /********************************
     * Update flow.flowEditorDiagram
     ********************************/
    // flowEditorActions.delayItemChanged is stored in nodeData
    if (isAnyOf(flowEditorActions.delayItemChanged)(action)) {
      const localFlowEditor = getFlowEditorLocalStorage(flow._id)
      if (localFlowEditor !== null) {
        if (
          !isEqual(
            sanitizeNodes(localFlowEditor?.flow.flowEditorDiagram.nodes),
            sanitizeNodes(flow.flowEditorDiagram.nodes)
          )
        ) {
          updateLocalFlowDiagram(flow._id, flow.flowEditorDiagram)
          dispatchLocalDraftChanged('delayItemChanged', flow._id)
          updateSyncKey(flow._id)
        }
      } else {
        error(new Error('CannotFindLocalDraft'))
      }
    }

    if (isAnyOf(flowEditorActions.autoAlign, flowEditorActions.nodesChanged)(action)) {
      const localFlowEditor = getFlowEditorLocalStorage(flow._id)
      if (localFlowEditor !== null) {
        if (
          !isEqual(
            sanitizeNodes(localFlowEditor?.flow.flowEditorDiagram.nodes),
            sanitizeNodes(flow.flowEditorDiagram.nodes)
          )
        ) {
          updateLocalFlowDiagram(flow._id, flow.flowEditorDiagram)
          dispatchLocalDraftChanged('nodesChanged', flow._id)
          updateSyncKey(flow._id)
        }
      } else {
        error(new Error('CannotFindLocalDraft'))
      }
    }

    if (isAnyOf(flowEditorActions.edgesChanged, flowEditorActions.edgeRemoved, edgeAdded)(action)) {
      updateLocalFlowDiagram(flow._id, flow.flowEditorDiagram)
      dispatchLocalDraftChanged('edgesChanged', flow._id)
      updateSyncKey(flow._id)
    }
  }
}
