import {
  AttachmentType,
  ButtonActionType,
  type MessageButtonTemplate,
  type MessageGenericGalleryTemplate,
  type MessageGenericTemplate,
  type MessageImageAttachment,
  type MessageMediaTemplate,
  type MessageReceiptTemplate,
  type MessageReviewTemplate,
  MessageType,
  MessengerTemplateType
} from '@ghostmonitor/recartapis'
import { MessageTrayItem } from '../../../types/tray-item.type'
import { trayIcons } from '../tray-icons'
import { TrayItemComponent } from '../tray-item.component'

export function TrayItemText() {
  const message: MessageButtonTemplate = {
    type: MessageType.TEXT,
    messengerTemplateType: MessengerTemplateType.BUTTON,
    messengerTemplatePayload: {
      text: '✏️ Click here to edit',
      buttons: []
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.text}
      message={message}
      title='Text'
    />
  )
}

export function TrayItemCard(props: { initialButtonUrl?: string }) {
  const message: MessageGenericTemplate = {
    type: MessageType.CARD,
    messengerTemplateType: MessengerTemplateType.GENERIC,
    messengerTemplatePayload: {
      title: 'New arrivals',
      subtitle: 'Check out our newest products',
      image_url: '',
      buttons: [
        {
          type: ButtonActionType.web_url,
          title: 'Shop Now',
          url: props.initialButtonUrl
        }
      ]
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.card}
      message={message}
      title='Card'
    />
  )
}

export function TrayItemMediaTemplateImage() {
  const message: MessageMediaTemplate = {
    type: MessageType.IMAGE,
    messengerTemplateType: MessengerTemplateType.MEDIA,
    messengerTemplatePayload: {
      media_type: 'image',
      url: '',
      buttons: []
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.image}
      message={message}
      title='Image'
    />
  )
}

export function TrayItemMediaTemplateVideo() {
  const message: MessageMediaTemplate = {
    type: MessageType.VIDEO,
    messengerTemplateType: MessengerTemplateType.MEDIA,
    messengerTemplatePayload: {
      media_type: 'video',
      url: '',
      buttons: []
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.video}
      message={message}
      title='Video'
    />
  )
}

export function TrayItemMediaTemplateAudio() {
  const message: MessageMediaTemplate = {
    type: MessageType.AUDIO,
    messengerTemplateType: MessengerTemplateType.MEDIA,
    messengerTemplatePayload: {
      media_type: 'audio',
      url: '',
      buttons: []
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.audio}
      message={message}
      title='Audio'
    />
  )
}

export function TrayItemMediaTemplateFile() {
  const message: MessageMediaTemplate = {
    type: MessageType.FILE,
    messengerTemplateType: MessengerTemplateType.MEDIA,
    messengerTemplatePayload: {
      media_type: 'file',
      url: '',
      buttons: []
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.file}
      message={message}
      title='File'
    />
  )
}

export function TrayItemAudio() {
  const message: MessageImageAttachment = {
    type: MessageType.AUDIO,
    attachmentType: AttachmentType.audio,
    attachmentPayload: {
      url: ''
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.audio}
      message={message}
      title='Audio'
    />
  )
}

export function TrayItemFile() {
  const message: MessageImageAttachment = {
    type: MessageType.FILE,
    attachmentType: AttachmentType.file,
    attachmentPayload: {
      url: ''
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.file}
      message={message}
      title='File'
    />
  )
}

export function TrayItemGallery(props: { initialButtonUrl?: string }) {
  const message: MessageGenericGalleryTemplate = {
    type: MessageType.GALLERY,
    messengerTemplateType: MessengerTemplateType.GENERIC,
    messengerTemplatePayload: [
      {
        title: 'New Arrivals',
        subtitle: 'Check out our newest products',
        image_url: '',
        buttons: [
          {
            type: ButtonActionType.web_url,
            title: 'Shop Now',
            url: props.initialButtonUrl
          }
        ]
      },
      {
        title: 'New Arrivals',
        subtitle: 'Check out our newest products',
        image_url: '',
        buttons: [
          {
            type: ButtonActionType.web_url,
            title: 'Shop Now',
            url: props.initialButtonUrl
          }
        ]
      }
    ]
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.gallery}
      message={message}
      title='Gallery'
    />
  )
}

export function TrayItemReceipt() {
  const message: MessageReceiptTemplate = {
    type: MessageType.RECEIPT,
    messengerTemplateType: MessengerTemplateType.RECEIPT,
    messengerTemplatePayload: null
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.receipt}
      message={message}
      title='Receipt'
    />
  )
}

export function TrayItemReview() {
  const message: MessageReviewTemplate = {
    type: MessageType.REVIEW,
    messengerTemplateType: MessengerTemplateType.REVIEW,
    messengerTemplatePayload: {
      buttons: [
        {
          type: ButtonActionType.review_url,
          title: 'Add Review'
        }
      ]
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.review}
      message={message}
      title='Review'
    />
  )
}

export function TrayItemFulfillment(props: { initialButtonUrl?: string }) {
  const message: MessageButtonTemplate = {
    type: MessageType.FULFILLMENT,
    messengerTemplateType: MessengerTemplateType.BUTTON,
    messengerTemplatePayload: {
      text: 'Your shipment is on its way... 📦',
      buttons: [
        {
          type: ButtonActionType.shipment_tracking_url,
          title: 'Track Shipment'
        },
        {
          type: ButtonActionType.web_url,
          title: 'Visit Store',
          url: props.initialButtonUrl
        }
      ]
    }
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.fulfillment}
      message={message}
      title='Fulfillment'
    />
  )
}

export function TrayItemAbandonedCartCarousel() {
  const message: MessageGenericGalleryTemplate = {
    type: MessageType.ABANDONED_CART_CAROUSEL,
    messengerTemplateType: MessengerTemplateType.GENERIC,
    messengerTemplatePayload: [
      {
        title: 'Demo Almanac',
        subtitle: '1x $12.99',
        image_url: '/images/sequence-editor/cart-placeholder-image-1.jpg',
        buttons: [
          {
            type: ButtonActionType.cart_url,
            title: 'Buy It Now'
          }
        ]
      },
      {
        title: 'The Cardinal of the Kremlin',
        subtitle: '1x $29.99',
        image_url: '/images/sequence-editor/cart-placeholder-image-2.jpg',
        buttons: [
          {
            type: ButtonActionType.cart_url,
            title: 'Buy It Now'
          }
        ]
      }
    ]
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.messengerMessage}
      icon={trayIcons.abandoned}
      message={message}
      title='Abandoned'
    />
  )
}
