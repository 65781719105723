import { type SubscriberDetail, type SubscriberImportColumnMapping } from '@ghostmonitor/recartapis'
import { hooks } from './hooks'

export interface SubscriberDetailImportOption {
  value: keyof SubscriberImportColumnMapping
  label: string
}

export function useAvailableSubscriberDetailsForSubscriberImport(
  defaultOptions: SubscriberDetailImportOption[]
): { availableSubscriberDetailForSubscriberImport: SubscriberDetail[]; isLoading: boolean } {
  const { data: subscriberDetails, isLoading } = hooks.useSubscriberDetails()

  const availableSubscriberDetailForSubscriberImport = subscriberDetails.filter(
    (subscriberDetail) =>
      !defaultOptions.find((option) => option.value === subscriberDetail.slug) &&
      subscriberDetail.type !== 'checkbox-group'
  )

  return {
    availableSubscriberDetailForSubscriberImport,
    isLoading
  }
}
