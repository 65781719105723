import moment from 'moment'
import { dateTimeFormat12hours } from '../../../utils/utils'
import { type BlastScheduleType } from '@ghostmonitor/recartapis'

export function getBlastSendingDate(
  scheduledFor: string,
  scheduleType: BlastScheduleType,
  timezone: string
): string {
  if (!scheduledFor) {
    return 'Start sending immediately'
  }

  let scheduleTimezoneInfo = "store's timezone"
  let sendingDate = moment.tz(scheduledFor, timezone)

  if (scheduleType === 'subscriberTimezone') {
    scheduleTimezoneInfo = "subscriber's timezone"
    sendingDate = sendingDate.utc()
  }

  return `${sendingDate.format(dateTimeFormat12hours)} (${scheduleTimezoneInfo})`
}
