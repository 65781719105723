// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wON2F47das2w0lgAyRdj{color:#212b36;font-family:Lato,sans-serif;font-size:17px;margin-bottom:8px}.DQKqsb7iCsSAqjd7JNBD{color:#979ba1;font-size:15px}.XLBNEv5LUXl2hia87V3L svg{height:100%}.XLBNEv5LUXl2hia87V3L svg path{color:#1890ff;fill:#1890ff}.XLBNEv5LUXl2hia87V3L svg circle{stroke:#1890ff}.XLBNEv5LUXl2hia87V3L .ant-modal-body{padding:32px}.XLBNEv5LUXl2hia87V3L .ant-modal-close{display:none}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/header/components/blast-header-edit-button.component.scss"],"names":[],"mappings":"AAGE,sBAGE,aAAA,CAFA,2BAAA,CACA,cAAA,CAEA,iBAAA,CAGF,sBAEE,aAAA,CADA,cACA,CAIA,0BACE,WAAA,CAEA,+BACE,aAAA,CACA,YAAA,CAGF,iCACE,cAAA,CAKF,sCACE,YAAA,CAGF,uCACE,YAAA","sourcesContent":["@use '../../../sequence-editor' as *;\n\n:local {\n  .title {\n    font-family: Lato, sans-serif;\n    font-size: 17px;\n    color: #212b36;\n    margin-bottom: 8px;\n  }\n\n  .text {\n    font-size: 15px;\n    color: #979ba1;\n  }\n\n  .modalContainer {\n    svg {\n      height: 100%;\n\n      path {\n        color: #1890ff;\n        fill: #1890ff;\n      }\n\n      circle {\n        stroke: #1890ff;\n      }\n    }\n\n    :global {\n      .ant-modal-body {\n        padding: 32px;\n      }\n\n      .ant-modal-close {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `wON2F47das2w0lgAyRdj`,
	"text": `DQKqsb7iCsSAqjd7JNBD`,
	"modalContainer": `XLBNEv5LUXl2hia87V3L`
};
export default ___CSS_LOADER_EXPORT___;
