import {
  type Variable,
  VariableParam,
  variablePattern,
  variableRawAndTagPattern
} from '@ghostmonitor/recartapis'
import { Popover } from 'antd'
import cheerio from 'cheerio'
import { useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { ReactComponent as LinkIcon } from '../../../../../../static/images/svg/sequence-editor/link-shortening-icon.svg'
import {
  getIdFromVariable,
  getIndexFromId,
  getRenderedVariable,
  getUrlFromTagId,
  getVariableFromId,
  getVariableFromRawVariableSequenceEditor,
  isUrlVariable,
  removeHTMLEntities,
  replaceVariableTagsToRawVariables
} from '../../../../../../utils/inline-editor/inline-editor-utils'

import { type InlineEditorPlugin } from '../../../../../../utils/inline-editor/types/inline-editor-plugin.type'
import {
  type InlineEditorProps,
  type VariableMouseClickEvent,
  useInlineEditorHOCControl
} from '../../use-inline-editor-hoc-control.hook'
import { LinkShorteningPopover } from './link-shortening-popover.component'
import styles from './make-link-shortening.hoc.scss'
import { ShortLinkVariable } from './short-link-variable.component'

interface MakeLinkShorteningProps {}

function replaceHtmlValueToRawValue(htmlValue: string): string {
  return replaceVariableTagsToRawVariables(htmlValue, ['url'])
}

function getHtmlFromVariable(variable: Variable, index: number, subdomain?: string): string {
  return renderToString(
    <ShortLinkVariable
      placeholder={getRenderedVariable(variable, subdomain)}
      id={getIdFromVariable(variable, index)}
    />
  )
}

function replaceRawValueToHtmlValue(rawValue: string, subdomain?: string): string {
  let newHtml = rawValue
  let match: string[]
  let index = 0
  const allVarPattern = new RegExp(variableRawAndTagPattern, 'g')
  const varPattern = new RegExp(variablePattern, 'g')
  while ((match = allVarPattern.exec(newHtml))) {
    // It's tag, already replaced
    if (!match[0].match(varPattern)) {
      index++
      continue
    }

    const variable = getVariableFromRawVariableSequenceEditor(match[0])

    if (!isUrlVariable(variable)) {
      index++
      continue
    }

    newHtml = newHtml.replace(match[0], getHtmlFromVariable(variable, index, subdomain))
    index++
  }
  return newHtml
}

export function makeLinkShortening<T extends InlineEditorProps>(
  WrappedComponent: React.FC<T>
): React.FC<MakeLinkShorteningProps & T> {
  const LinkShortening = (props: React.PropsWithChildren<MakeLinkShorteningProps & T>) => {
    const { html, setHtml, handlers, editorRef, rawValue, insertContent, caretPosition } =
      useInlineEditorHOCControl(
        'make-link-shortening',
        props,
        replaceHtmlValueToRawValue,
        replaceRawValueToHtmlValue
      )

    const [showAddLinkPopup, setShowAddLinkPopup] = useState(false)
    const [editingUrl, setEditingUrl] = useState<string>(undefined)
    const [editingUrlIndex, setEditingUrlIndex] = useState<number>(undefined)
    const [showEditLinkPopup, setShowEditLinkPopup] = useState(false)
    const [linkPopupOffset, setLinkPopupOffset] = useState([0, 0])
    const linkTriggerRef = useRef<HTMLDivElement>()
    const [tooltipOffset, setTooltipOffset] = useState([0, 0])
    const [tooltipTitle, setTooltipTitle] = useState('Shortened link')
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    function setLinkPopupPosition(targetLinkRect) {
      const editorRect = editorRef.current.getBoundingClientRect()
      const addVariableButtonRect = linkTriggerRef.current.getBoundingClientRect()
      const horizontalDiff = addVariableButtonRect.left - editorRect.left
      const editVariablePopupOffset = [
        -Math.abs(addVariableButtonRect.left - targetLinkRect.left) +
          targetLinkRect.width / 2 +
          horizontalDiff,
        -Math.abs(addVariableButtonRect.top - targetLinkRect.top) + (targetLinkRect.height + 8)
      ]
      setLinkPopupOffset(editVariablePopupOffset)
    }

    function handleClick(event: VariableMouseClickEvent): void {
      if (event.target !== editorRef?.current) {
        const tagId = event.target.id

        if (!tagId) {
          props.onClick?.(event)
          return
        }

        const variable = getVariableFromId(tagId)
        const variableIndex = getIndexFromId(tagId)

        if (isUrlVariable(variable)) {
          const url = variable.params[VariableParam.value]
          setEditingUrl(url)
          setEditingUrlIndex(Number(variableIndex))
          setLinkPopupPosition(event.target.getBoundingClientRect())
          setShowEditLinkPopup(true)
        }
      }
      props.onClick?.(event)
    }

    function handleCloseAddLinkPopOver() {
      setShowAddLinkPopup(false)
      setEditingUrlIndex(undefined)
      setEditingUrl(undefined)
    }

    function handleCloseEditLinkPopOver() {
      setShowEditLinkPopup(false)
      setEditingUrlIndex(undefined)
      setEditingUrl(undefined)
    }

    function handleAddLink(link: string) {
      const insertedVariable: Variable = {
        name: 'url',
        params: {
          value: link,
          required: undefined,
          url_add_discount: undefined,
          url_decorate: undefined,
          url_shorten: undefined
        }
      }
      const insertedHtml = getHtmlFromVariable(insertedVariable, 0)
      const newHtml = insertContent(insertedHtml, caretPosition)
      setHtml(newHtml)
      setShowAddLinkPopup(false)
      editorRef.current.focus()
    }

    function handleEditLink(newUrl: string) {
      const $html = cheerio.load(html)

      $html('span').each((index, element) => {
        const id = $html(element).attr('id')
        const variable = getVariableFromId(id)

        if (isUrlVariable(variable)) {
          const url = variable.params[VariableParam.value]
          if (url === editingUrl && index === editingUrlIndex) {
            variable.params = {
              ...variable.params,
              [VariableParam.value]: newUrl
            }
            $html(element).attr('id', getIdFromVariable(variable, index))
          }
        }
      })

      setHtml(removeHTMLEntities($html('body').html()))

      setShowEditLinkPopup(false)
      editorRef.current.focus()
    }

    function renderAddLinkPopoverContent() {
      return (
        <>
          <div
            className={styles.pickerWrapper}
            onClick={() => {
              setShowAddLinkPopup(false)
            }}
          />
          <LinkShorteningPopover
            title='Add link'
            buttonLabel='Add shortened link'
            onLinkChange={handleAddLink}
            onClose={handleCloseAddLinkPopOver}
          />
        </>
      )
    }

    function renderEditLinkPopoverContent() {
      return (
        <>
          <div
            className={styles.pickerWrapper}
            onClick={() => {
              setShowEditLinkPopup(false)
            }}
          />
          <LinkShorteningPopover
            title='Edit link'
            buttonLabel='Modify shortened link'
            defaultLink={editingUrl}
            onLinkChange={handleEditLink}
            onClose={handleCloseEditLinkPopOver}
          />
        </>
      )
    }

    function handleMouseMove(event: React.MouseEvent<HTMLDivElement>) {
      const tagId = (event.target as any).id as string
      if (!tagId) {
        setIsTooltipOpen(false)
        return
      }

      const url = getUrlFromTagId(tagId, 'url_shorten')
      if (!url) {
        setIsTooltipOpen(false)
        props.onMouseMove?.(event)
        return
      }

      const editorRect = editorRef.current.getBoundingClientRect()
      const linkRect = (event.target as any).getBoundingClientRect()
      const horizontalDiff =
        linkRect.left - editorRect.left - editorRect.width / 2 + linkRect.width / 2
      const verticalDiff = linkRect.top - editorRect.top
      setTooltipTitle(url)
      setTooltipOffset([horizontalDiff, verticalDiff])
      setIsTooltipOpen(true)
      props.onMouseMove?.(event)
    }

    function handleMouseLeave(event: React.MouseEvent<HTMLDivElement>) {
      setIsTooltipOpen(false)
      props.onMouseLeave?.(event)
    }

    const button = (
      <Tooltip placement='topLeft' title='Add link' arrowPointAtCenter>
        <div
          data-testid='link-shortening-button'
          className={styles.buttonContainer}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            setLinkPopupPosition(linkTriggerRef.current?.getBoundingClientRect())
            event.currentTarget.focus()
            setShowAddLinkPopup(true)
          }}
          ref={linkTriggerRef}
        >
          <div className={styles.icon}>
            <LinkIcon />
          </div>
        </div>
      </Tooltip>
    )

    const popover = (
      <>
        <Popover
          open={showEditLinkPopup}
          trigger='click'
          placement='bottom'
          content={renderEditLinkPopoverContent}
          align={{ offset: linkPopupOffset }}
        />
        <Popover
          open={showAddLinkPopup}
          trigger='click'
          placement='bottom'
          content={renderAddLinkPopoverContent}
          align={{ offset: linkPopupOffset }}
        />
      </>
    )

    const pluginComponent: InlineEditorPlugin = {
      name: 'link-shortening',
      button,
      popover
    }

    return (
      <Tooltip
        placement='top'
        title={tooltipTitle}
        open={isTooltipOpen}
        align={{ offset: tooltipOffset }}
      >
        <WrappedComponent
          {...props}
          {...handlers}
          editorRef={editorRef}
          onClick={handleClick}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          html={html}
          rawValue={rawValue}
          plugins={(props.plugins ?? []).concat(pluginComponent)}
        />
      </Tooltip>
    )
  }
  return LinkShortening
}
