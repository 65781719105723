import { compulsorySiteNameRegexp, type SMSMessageUI } from '@ghostmonitor/recartapis'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'
import { collectVariablesFromText } from '../../rich-text-editor/get-message-variables'
import {
  supportEmailVariableOption,
  type VariableTypeOption
} from '../../rich-text-editor/variable-type-options'

export interface SMSMessageValidationArgs {
  entryLinkTypeOptions?: VariableTypeOption[]
  entryVariableTypeOptions?: VariableTypeOption[]
  isEntryItem: boolean
  linkTypeOptions: VariableTypeOption[]
  message: SMSMessageUI
  variableTypeOptions: VariableTypeOption[]
}

const htmlTagRegexp = /<[^>]+>/g

export function validateSMSMessage(
  args: SMSMessageValidationArgs
): FlowEditorValidationError | undefined {
  const {
    entryLinkTypeOptions,
    entryVariableTypeOptions,
    isEntryItem,
    linkTypeOptions,
    message,
    variableTypeOptions
  } = args
  if (!message.text || message.text === '' || compulsorySiteNameRegexp.test(message.text.trim())) {
    return {
      message: FlowEditorError.SMSMessageEmpty,
      level: FlowEditorErrorLevel.Error
    }
  }

  if (htmlTagRegexp.test(message.text)) {
    return {
      message: FlowEditorError.MessageContainsHTMLTag,
      level: FlowEditorErrorLevel.Error
    }
  }

  const { linkVariables, generalVariables } = collectVariablesFromText(message.text)

  const hasInvalidLink = linkVariables.some((linkVariableName) => {
    const isInLinkTypeOptions = linkTypeOptions.some(
      (linkTypeOption) => linkTypeOption.value === linkVariableName.name
    )
    const isInEntryLinkTypeOptions = entryLinkTypeOptions.some(
      (linkTypeOption) => linkTypeOption.value === linkVariableName.name
    )
    return !(isInLinkTypeOptions || isInEntryLinkTypeOptions)
  })

  if (hasInvalidLink) {
    return {
      message: FlowEditorError.MessageHasInvalidLink,
      level: FlowEditorErrorLevel.Error
    }
  }

  const hasInvalidCustomLink = linkVariables.some(
    (linkVariable) => linkVariable.name === 'url' && !linkVariable.params.value
  )

  if (hasInvalidCustomLink) {
    return {
      message: FlowEditorError.MessageHasCustomLinkWithoutValue,
      level: FlowEditorErrorLevel.Error
    }
  }

  if (!isEntryItem) {
    const hasEntryLink = linkVariables.some((linkVariable) => {
      return entryLinkTypeOptions.some(
        (linkTypeOption) => linkTypeOption.value === linkVariable.name
      )
    })

    if (hasEntryLink) {
      return {
        message: FlowEditorError.MessageHasEntryLinkInNonEntryItem,
        level: FlowEditorErrorLevel.Error
      }
    }
  }

  const hasInvalidVariable = generalVariables.find((variable) => {
    const isInVariableTypeOptions = variableTypeOptions.some(
      (variableTypeOption) => variableTypeOption.value === variable.name
    )
    const isInEntryVariableTypeOptions = entryVariableTypeOptions.some(
      (variableTypeOption) => variableTypeOption.value === variable.name
    )
    return (
      (isEntryItem
        ? !(isInVariableTypeOptions || isInEntryVariableTypeOptions)
        : !isInVariableTypeOptions) && variable.name !== supportEmailVariableOption.value
    )
  })

  if (hasInvalidVariable) {
    return {
      message: FlowEditorError.MessageHasInvalidVariable,
      level: FlowEditorErrorLevel.Error
    }
  }

  // todo extend this with keyword reply validation using the keywordReplyVariables

  return undefined
}
