import { type FlowAPI } from '@ghostmonitor/recartapis'

export function checkOrderAndReceiptFlowIntegrity(flow: FlowAPI): void {
  if (flow.sendingFrequency !== undefined) {
    throw new Error(
      'sendingFrequency is defined on flow, but it only should be defined on Custom Trigger flows'
    )
  }

  if (flow.isQuietHoursEnabled !== false) {
    throw new Error('isQuietHoursEnabled should be defined and false for order & receipt flows')
  }

  if (flow.isSmartSendingEnabled !== true) {
    throw new Error('isSmartSendingEnabled should be defined and true for order & receipt flows')
  }
}
