import { type ButtonUI, SiteEngineSlug } from '@ghostmonitor/recartapis'
import { type Action, type Store } from '@reduxjs/toolkit'
import debounce from 'debounce-promise'
import type { AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { selectButton, selectSiteEngineSlug } from '../../../../store/selectors'
import {
  buttonValidationError,
  buttonValidationSuccess,
  type UpdateButtonAction,
  UPDATE_BUTTON
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { buttonValidator } from '../../validators/button.validator'

const DEBOUNCE_DELAY = 400

function getValidateButton(dispatch: AppDispatch) {
  return debounce(
    async (button: ButtonUI, action: UpdateButtonAction, validateDiscountCode: boolean) => {
      const buttonErrors = await buttonValidator(button, validateDiscountCode)
      if (buttonErrors.length > 0) {
        dispatch(
          buttonValidationError(buttonErrors[0], {
            sequenceItemId: action.sequenceItemId,
            messageIndex: action.messageIndex,
            buttonIndex: action.buttonIndex,
            messageItemIndex: action.messageItemIndex
          })
        )
      } else {
        dispatch(
          buttonValidationSuccess(null, {
            sequenceItemId: action.sequenceItemId,
            messageIndex: action.messageIndex,
            buttonIndex: action.buttonIndex,
            messageItemIndex: action.messageItemIndex
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: true }
  )
}

function isValidatableAction(action: Action): action is UpdateButtonAction {
  return action.type === UPDATE_BUTTON
}

export function validateButtonMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateButton = getValidateButton(dispatch)

  return (next) => (action: Action) => {
    next(action)

    if (isValidatableAction(action)) {
      const state = store.getState()
      const button = selectButton(
        action.sequenceItemId,
        action.messageIndex,
        action.buttonIndex,
        action.messageItemIndex
      )(state)

      const siteEngineSlug = selectSiteEngineSlug(state)
      const isShopify = siteEngineSlug === SiteEngineSlug.SHOPIFY

      validateButton(button, action, isShopify)
    }
  }
}
