import { type ExpressionAPI, type SegmentVariant } from '@ghostmonitor/recartapis'
import { type ExpressionUI, type SegmentVariantUI } from '../../condition-ui.type'
import { convertConditionAPIToUI } from './condition'

export function convertExpressionAPIToUI(expression: ExpressionAPI): ExpressionUI {
  const conditionGroups = expression?.and?.map((conditionGroup) => {
    const conditions = conditionGroup.or.map((condition) => convertConditionAPIToUI(condition))

    return { or: conditions }
  })

  return { and: conditionGroups }
}

export function convertSegmentAPIToUI(segment: SegmentVariant): SegmentVariantUI {
  if (segment.type === 'list') {
    return segment
  }

  return {
    ...segment,
    expression: convertExpressionAPIToUI(segment.expression)
  }
}
