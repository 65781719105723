import { type DiagramModel } from 'storm-react-diagrams'
import { type BaseSequenceItemNodeModel } from '../models/sequence-item/base-sequence-item-model'

export function getNodeModel<T extends BaseSequenceItemNodeModel>(
  diagramModel: DiagramModel,
  sequenceItemId: string
): T {
  return Object.values(diagramModel.nodes).find(
    (node: BaseSequenceItemNodeModel) => node.sequenceItemId === sequenceItemId
  ) as T
}
