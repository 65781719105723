import { useDispatch, useSelector } from 'react-redux'
import { hooks } from '../../../../../hooks/hooks'
import { ReactComponent as StatsIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/17-stats/graph-stats-ascend.svg'
import { selectShowSequenceItemStatistics } from '../../../../../store/selectors'
import { toggleSequenceItemStatistics } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { CanvasButtonGroup } from '../../canvas-toolbar/canvas-button-group/canvas-button-group.component'
import { CanvasButton } from '../../canvas-toolbar/canvas-button/canvas-button.component'
import { CanvasToolbarItem } from '../canvas-toolbar-item.component'

export function CanvasToolbarStatistics() {
  const dispatch = useDispatch()
  const showSequenceItemStatistics = useSelector(selectShowSequenceItemStatistics)

  const { isLoading: isSequenceItemsStatLoading, isError: isSequenceItemsStatError } =
    hooks.useEditorSequenceItemsStat()

  function handleToggleSequenceItemStatistics() {
    dispatch(toggleSequenceItemStatistics({ visible: !showSequenceItemStatistics }))
  }

  return (
    <CanvasToolbarItem>
      <CanvasButtonGroup>
        <CanvasButton
          title='Toggle Statistics'
          icon={<StatsIcon />}
          onClick={handleToggleSequenceItemStatistics}
          active={showSequenceItemStatistics}
          disabled={isSequenceItemsStatLoading || isSequenceItemsStatError}
          disabledTitle='Please save your sequence to view statistics.'
        />
        <CanvasButton
          title={showSequenceItemStatistics ? 'Hide statistics' : 'Show statistics'}
          readOnly
          onClick={handleToggleSequenceItemStatistics}
          extraStyle={{ minWidth: '110px' }}
          active={showSequenceItemStatistics}
          disabled={isSequenceItemsStatLoading || isSequenceItemsStatError}
        >
          {showSequenceItemStatistics ? 'Hide statistics' : 'Show statistics'}
        </CanvasButton>
      </CanvasButtonGroup>
    </CanvasToolbarItem>
  )
}
