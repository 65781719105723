import moment from 'moment'
import 'moment-timezone'
import { type TimeOfDay } from '../../types/time-of-day'

function getInMinute(time: TimeOfDay): number {
  return time.hour * 60 + time.minute
}

function getDiffInMinutes(start: TimeOfDay, end: TimeOfDay): number {
  if (start === undefined || end === undefined) {
    return 0
  }

  const startMin = getInMinute(start)
  const endMin = getInMinute(end)
  if (startMin > endMin) {
    return 1440 - startMin + endMin
  }

  return endMin - startMin
}

export function isInTimeRange(time: TimeOfDay, start: TimeOfDay, end: TimeOfDay): boolean {
  if (start === undefined || end === undefined) {
    return false
  }

  const diffInMinutes = getDiffInMinutes(start, end)
  const quietHoursStart = moment.tz(start, 'Etc/UTC')
  const quietHoursEnd = moment(quietHoursStart).add(diffInMinutes, 'minutes')
  const timeToCheck = moment.tz(time, 'Etc/UTC')

  if (timeToCheck.isBefore(quietHoursStart)) {
    quietHoursStart.add(-24, 'hours')
    quietHoursEnd.add(-24, 'hours')
  }

  if (timeToCheck.isAfter(quietHoursEnd)) {
    quietHoursStart.add(24, 'hours')
    quietHoursEnd.add(24, 'hours')
  }

  return timeToCheck.isAfter(quietHoursStart) && timeToCheck.isBefore(quietHoursEnd)
}

export function isNowInQuietHoursForSubscriber(
  siteTimezone: string,
  subscriberTimezone?: number,
  start?: TimeOfDay,
  end?: TimeOfDay
): boolean {
  if (start === undefined || end === undefined) {
    return false
  }

  const nowAtSubscriber =
    subscriberTimezone !== undefined
      ? moment.tz('Etc/UTC').add(subscriberTimezone, 'hours')
      : moment.tz(siteTimezone)
  const nowAtSubscriberInSettingsTime = {
    hour: nowAtSubscriber.hour(),
    minute: nowAtSubscriber.minute()
  }

  return isInTimeRange(nowAtSubscriberInSettingsTime, start, end)
}
