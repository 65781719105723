import {
  type FlowAPI,
  type MessageUI,
  type SMSMessageFlowItemAPI,
  getTextFromMessage,
  hasTextOnMessage,
  shouldBeLegalFlowItem
} from '@ghostmonitor/recartapis'
import { difference } from 'lodash'
import {
  getDiscountCodesFromText,
  getDiscountPoolIdsFromText
} from '../../rich-text-editor/get-discount-code'
import { type FlowIntegrityOptions } from '../check-flow-integrity'

export function checkSMSMessageFlowItemIntegrity(
  flow: FlowAPI,
  flowItem: SMSMessageFlowItemAPI,
  options: FlowIntegrityOptions
) {
  const edges = flow.flowEditorDiagram.edges
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)

  if (!node) {
    throw new Error(`Cannot find node with flowItemId ${flowItem._id}`)
  }

  if (
    shouldBeLegalFlowItem(flow, flowItem) &&
    !options.isLegalFlowItemDeletable &&
    flowItem.item.messages.length !== 2
  ) {
    throw new Error(
      `Legal message flow item ${flowItem._id} has ${flowItem.item.messages.length} messages, but should have 2`
    )
  }
  const discountCodes = new Set<string>()

  flowItem.item.messages.forEach((message: MessageUI) => {
    if (hasTextOnMessage(message)) {
      const text = getTextFromMessage(message)

      if (text) {
        const messageDiscountCodes = getDiscountCodesFromText(text)
        messageDiscountCodes.forEach((discountCode) => discountCodes.add(discountCode))
      }
    }
  })

  if (difference(Array.from(discountCodes), flowItem.item?.discountCodes ?? []).length !== 0) {
    throw new Error(
      `Discount codes found in message (${JSON.stringify(
        Array.from(discountCodes)
      )}) differs on flow item ${JSON.stringify(flowItem.item?.discountCodes ?? [])}`
    )
  }

  let discountCodePoolId: string
  flowItem.item.messages.forEach((message: MessageUI) => {
    if (hasTextOnMessage(message)) {
      const text = getTextFromMessage(message)
      if (text) {
        const messageDiscountPoolIds = getDiscountPoolIdsFromText(text)
        if (messageDiscountPoolIds.length > 1) {
          throw new Error(
            `There are multiple unique discount pool ids in message: ${JSON.stringify(
              messageDiscountPoolIds
            )} There should be only one`
          )
        }
        discountCodePoolId = messageDiscountPoolIds[0]
      }
    }
  })

  if (discountCodePoolId !== flowItem.item?.discountCodePoolId) {
    throw new Error(
      `Discount code pool id in message (${discountCodePoolId}) differs from the discount code pool id on flow item: ${flowItem.item?.discountCodePoolId}`
    )
  }
}
