import { type HttpResponse, type OptinToolExperiment } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useOptinToolExperiments(): UseResource<OptinToolExperiment[]> {
  const queryName = [QUERY_NAME.optinToolExperiments]

  const {
    data: optinToolExperimentsData,
    isLoading,
    error
  } = useQuery<HttpResponse<OptinToolExperiment[]>>(queryName, api.getOptinToolExperiments)

  return {
    data: optinToolExperimentsData?.data,
    error,
    isLoading
  }
}
