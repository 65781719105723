import {
  type ExpressionAPI,
  type FlowItemUI,
  getAutoAlignedFlowElements,
  getVariableFromRawVariable,
  variablePattern,
  variableRawAndTagPattern
} from '@ghostmonitor/recartapis'
import { produce } from 'immer'
import { isConditionalSplitV2FlowItemUI } from '../../../types/guards/flow-item-ui.guards'
import { convertExpressionAPIToUI } from '../../../types/segment/converters/api-to-ui/segment'
import { mapMomentToStringDate } from '../../../utils/flow-editor/expression-date-mapper'
import { FlowEditorError } from '../../../utils/flow-editor/flow-editor-errors'
import { removeVariableByIndex } from '../../../utils/inline-editor/inline-editor-utils'
import { convertHTMLtoPlainText } from '../../../utils/rich-text-editor/convert-html'
import { escapeAngleBrackets } from '../../../utils/rich-text-editor/escape-angle-brackets'
import { getIndexFromId, getVariableFromId } from '../../../utils/rich-text-editor/get-variables'
import {
  getCanvasHtmlFromVariable,
  getHtmlFromVariable
} from '../../../utils/rich-text-editor/replace-raw-value-to-html-value'
import {
  isDiscountUrlVariable,
  isGeneralVariable,
  isKeywordReplyVariable,
  isLinkVariable,
  isStaticDiscountCodeVariable,
  isUniqueDiscountCodeVariable
} from '../../../utils/rich-text-editor/variable-assertion'
import {
  compulsorySitenameVariableRTELength,
  supportEmailVariableOption,
  variableOptionNames
} from '../../../utils/rich-text-editor/variable-type-options'
import {
  selectDiscountCodePools,
  selectEditorFlow,
  selectEditorFlowMeta,
  selectEntryLinkTypeOptions,
  selectEntryVariableTypeOptions,
  selectExpiredDiscountCodes,
  selectInvalidDiscountCodes,
  selectIsReadOnly,
  selectLinkTypeOptions,
  selectMessage,
  selectMessageMeta,
  selectVariablePlaceholder,
  selectVariableTypeOptions
} from './flow-editor.selectors'
import { type FlowEditorState } from './flow-editor.state'

export function replaceRawValueToHtmlValue(
  state: FlowEditorState,
  rawValue: string,
  flowItemId: string
): string {
  let newHtml = rawValue.replace(/&(?![^&]{2,4};)/g, '&amp;')
  newHtml = escapeAngleBrackets(newHtml)
  newHtml = newHtml.replace(/<div>/g, '\n')
  newHtml = newHtml.replace(/<\/div>/g, '')
  let match: string[] | null
  let index = 0

  const allVarPattern = new RegExp(variableRawAndTagPattern, 'g')
  const varPattern = new RegExp(variablePattern, 'g')
  const flowMeta = selectEditorFlowMeta(state)
  const flow = selectEditorFlow(state)
  const isEntryItem = flow?.entrySequenceItemId === flowItemId

  const variablePlaceholderData = selectVariablePlaceholder(state)
  const discountCodePools = selectDiscountCodePools(state)
  const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings ?? {
    domain: '',
    subdomain: ''
  }

  const selectedVariableIndex = flowMeta.editingVariableData
    ? getIndexFromId(flowMeta.editingVariableData)
    : undefined
  const variableTypeOptions = selectVariableTypeOptions(state)
  const entryVariableTypeOptions = selectEntryVariableTypeOptions(state)
  const entryLinkTypeOptions = selectEntryLinkTypeOptions(state)
  const linkTypeOptions = selectLinkTypeOptions(state)

  const expiredDiscountCodes = selectExpiredDiscountCodes(state)
  const invalidDiscountCodes = selectInvalidDiscountCodes(state)

  const isReadOnly = selectIsReadOnly(state)

  while ((match = allVarPattern.exec(newHtml))) {
    // It's tag, already replaced
    if (!match[0].match(varPattern)) {
      index++
      continue
    }

    const variable = getVariableFromRawVariable(match[0])
    if (!variable) {
      index++
      continue
    }

    // currently only first position siteName variable is disabled
    const disableClick =
      new RegExp(`^${match[0]}`, 'g').test(newHtml) && variable.name === 'site.name' && index === 0

    // URL
    // if (isUrlVariable(variable)) {

    let prefix: string | undefined
    let isError: boolean | undefined

    const variableTypeOptionNames = [
      ...variableTypeOptions.map((option) => option.value),
      ...(isEntryItem ? entryVariableTypeOptions.map((option) => option.value) : [])
    ]

    if (isUniqueDiscountCodeVariable(variable)) {
      const discountPoolId = variable.params.discount_pool_id
      const discountCodePattern = discountCodePools?.find(
        (discountCodePool) => discountCodePool.id === discountPoolId
      )?.discountCodePattern

      if (discountCodePattern) {
        prefix = discountCodePattern.split('{')[0]
      }
    }

    if (isStaticDiscountCodeVariable(variable)) {
      const isExpiredDiscountCode = expiredDiscountCodes.includes(variable.params.discount_code!)
      const isInvalidDiscountCode = invalidDiscountCodes.includes(variable.params.discount_code!)
      isError = !!isExpiredDiscountCode || !!isInvalidDiscountCode
    }

    if (isLinkVariable(variable)) {
      let isExpiredDiscountCode = false
      let isInvalidDiscountCode = false

      const isInLinkTypeOptions = linkTypeOptions.some(
        (linkTypeOption) => linkTypeOption.value === variable.name
      )
      const isInEntryLinkTypeOptions = entryLinkTypeOptions.some(
        (linkTypeOption) => linkTypeOption.value === variable.name
      )
      const isInvalidLink = isEntryItem
        ? !(isInLinkTypeOptions || isInEntryLinkTypeOptions)
        : !isInLinkTypeOptions

      if (isDiscountUrlVariable(variable)) {
        isExpiredDiscountCode = expiredDiscountCodes.includes(variable.params.discount_code ?? '')
        isInvalidDiscountCode = invalidDiscountCodes.includes(variable.params.discount_code ?? '')
      }
      isError = isInvalidLink || isExpiredDiscountCode || isInvalidDiscountCode
    }

    if (isGeneralVariable(variable)) {
      const isInvalidGeneralVariable =
        ![...variableTypeOptionNames, supportEmailVariableOption.value].includes(variable.name) &&
        !isKeywordReplyVariable(variable)

      isError = !!isInvalidGeneralVariable
    }

    newHtml = newHtml.replace(
      match[0],
      getHtmlFromVariable(
        variable,
        index,
        isReadOnly ? false : (isError ?? false),
        urlGenerationSettings,
        selectedVariableIndex === index,
        prefix,
        ![...variableOptionNames, supportEmailVariableOption.value].includes(variable.name) &&
          !isStaticDiscountCodeVariable(variable) &&
          !isUniqueDiscountCodeVariable(variable) &&
          !isKeywordReplyVariable(variable),
        disableClick
      )
    )

    index++
  }

  return newHtml
}

export function resetEditingVariable(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  flowMeta.editingVariableData = undefined
}

export function resetNodeTypeSelector(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  flowMeta.insertNodePanelType = undefined
}

export function resetEditingMessage(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  flowMeta.editingMessageIndex = undefined

  resetEditingVariable(state)
}

export function resetSecondaryEditPanel(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  flowMeta.secondaryEditPanelType = undefined
  resetEditingVariable(state)
}

export function reRenderRTEContent(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  const flowItemId = flowMeta.editingFlowItemId

  // we need to update messageMeta.textHTML in order to
  // see the proper status of the variables

  if (!flowItemId) {
    throw new Error('No flowItemId exist')
  }

  if (flowMeta.editingMessageIndex === undefined) {
    throw new Error(FlowEditorError.EditingMessageIndexNotFound)
  }

  const message = selectMessage(flowItemId, flowMeta.editingMessageIndex)(state)
  const messageMeta = selectMessageMeta(flowItemId, flowMeta.editingMessageIndex)(state)

  messageMeta.textHTML = replaceRawValueToHtmlValue(state, message.text, flowItemId)
}

export function fixForcedCaretPositionAfterSiteName(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  const flowItemId = flowMeta.editingFlowItemId
  if (!flowItemId) {
    throw new Error('No flowItemId exist')
  }

  if (flowMeta.editingMessageIndex === undefined) {
    throw new Error(FlowEditorError.EditingMessageIndexNotFound)
  }

  const message = selectMessage(flowItemId, flowMeta.editingMessageIndex)(state)
  const messageMeta = selectMessageMeta(flowItemId, flowMeta.editingMessageIndex)(state)

  if (messageMeta.caretPosition === 0 && /^{{site\.name\|required}}/g.test(message.text)) {
    messageMeta.caretPosition = compulsorySitenameVariableRTELength
  }
}

export function resetEditPanel(state: FlowEditorState) {
  const flowMeta = selectEditorFlowMeta(state)
  flowMeta.editPanelType = undefined
  flowMeta.editingFlowItemId = undefined
  flowMeta.editingNodeId = undefined
  resetEditingMessage(state)
  resetSecondaryEditPanel(state)
}

export function autoAlignFlowDiagram(state: FlowEditorState) {
  const flow = selectEditorFlow(state)
  if (!flow) {
    throw new Error('Flow not found')
  }

  const { nodes, edges } = getAutoAlignedFlowElements(
    flow.flowEditorDiagram.nodes,
    flow.flowEditorDiagram.edges
  )

  flow.flowEditorDiagram.nodes = nodes
  flow.flowEditorDiagram.edges = edges
}

export function replaceRawValueToCanvasHtmlValue(state: FlowEditorState, rawValue: string): string {
  let newHtml = rawValue
  let match: string[] | null
  let index = 0
  const allVarPattern = new RegExp(variableRawAndTagPattern, 'g')
  const varPattern = new RegExp(variablePattern, 'g')
  const variablePlaceholderData = selectVariablePlaceholder(state)
  const discountCodePools = selectDiscountCodePools(state)

  while ((match = allVarPattern.exec(newHtml))) {
    // It's tag, already replaced
    if (!match[0].match(varPattern)) {
      index++
      continue
    }

    const variable = getVariableFromRawVariable(match[0])

    if (!variable) {
      index++
      continue
    }

    let prefix: string | undefined

    if (isUniqueDiscountCodeVariable(variable)) {
      const discountPoolId = variable.params.discount_pool_id
      const discountCodePattern = discountCodePools.find(
        (discountCodePool) => discountCodePool.id === discountPoolId
      )?.discountCodePattern

      if (discountCodePattern) {
        prefix = discountCodePattern.split('{')[0]
      }
    }

    newHtml = newHtml.replace(
      match[0],
      getCanvasHtmlFromVariable(variable, index, false, variablePlaceholderData, prefix)
    )

    index++
  }

  return newHtml
}

export function removeEditingVariable(
  state: FlowEditorState,
  flowItemId: string,
  messageIndex: number
) {
  const messageMeta = selectMessageMeta(flowItemId, messageIndex)(state)
  const flowMeta = selectEditorFlowMeta(state)

  if (!flowMeta.editingVariableData) {
    throw new Error('CannotFindVariableToRemove')
  }

  const variablePlaceholderData = selectVariablePlaceholder(state)
  const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings

  if (urlGenerationSettings === undefined) {
    throw new Error('CannotFindSubdomain')
  }

  const variableLinkToBeRemoved = getVariableFromId(flowMeta.editingVariableData)
  const variableLinkIndexToBeRemoved = getIndexFromId(flowMeta.editingVariableData)

  const variableLinkToBeRemovedHtml = getHtmlFromVariable(
    variableLinkToBeRemoved,
    messageIndex,
    false,
    urlGenerationSettings,
    ![...variableOptionNames, supportEmailVariableOption.value].includes(
      variableLinkToBeRemoved.name
    )
  )

  // remove the clicked variable and modify caretposition
  const startingCaretPosition = convertHTMLtoPlainText(messageMeta.textHTML!).indexOf(
    convertHTMLtoPlainText(variableLinkToBeRemovedHtml)
  )

  if (startingCaretPosition === -1) {
    throw new Error('CannotFindVariableToEdit')
  }
  messageMeta.caretPosition = startingCaretPosition

  messageMeta.textHTML = removeVariableByIndex(messageMeta.textHTML!, variableLinkIndexToBeRemoved)
}

export function handleConditionalSplitV2FlowItemLoaded(flowItem: FlowItemUI) {
  return produce(flowItem, (draft) => {
    if (isConditionalSplitV2FlowItemUI(draft)) {
      draft.item.logic.conditionalSplitV2.cases.forEach((split) => {
        split.expression = mapMomentToStringDate(
          convertExpressionAPIToUI(split.expression as ExpressionAPI)
        )
      })
    }
  })
}
