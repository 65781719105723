import { type DiagramPoint } from '@ghostmonitor/recartapis'
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

const DOWNSAMPLE_SIZE = 31
const DOWNSAMPLE_THRESHOLD = 31

export interface SalesStackedDiagramResponse {
  data?: SalesStackedDiagram
}

export interface SalesStackedDiagram {
  facebook: {
    diagram: DiagramPoint[]
    total: number
  }
  sms: {
    diagram: DiagramPoint[]
    total: number
  }
  email?: {
    diagram: DiagramPoint[]
    total: number
  }
}

export function useSalesStackedChart(): UseResource<SalesStackedDiagram> {
  const queryName = [QUERY_NAME.salesStackedChart]

  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
    targetResolution?: number
  } = {}

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
    queryName.push(dateFrom.toISOString())
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
    queryName.push(dateTo.toISOString())
  }

  if (
    dateTo === null ||
    dateFrom === null ||
    dateTo?.diff(dateFrom, 'days') > DOWNSAMPLE_THRESHOLD
  ) {
    queryParams.targetResolution = DOWNSAMPLE_SIZE
  }

  const { isInitialLoading, data } = useQuery<SalesStackedDiagramResponse>(
    queryName,
    async () => {
      return request.get<SalesStackedDiagramResponse>('statistics/sales', {
        params: queryParams
      })
    },
    {
      enabled: dateFrom !== undefined && dateTo !== undefined
    }
  )

  return {
    data: data?.data,
    isLoading: isInitialLoading
  }
}
