import { useSelector } from 'react-redux'
import { selectIsReadOnly } from '../../../../store/selectors'
import { BlastSettingsDrawerReadOnly } from './components/blast-settings-drawer-read-only/blast-settings-drawer-read-only.component'
import { BlastSettingsDrawer } from './components/blast-settings-drawer/blast-settings-drawer.component'

export function SettingsDrawer() {
  const isReadOnlyMode = useSelector(selectIsReadOnly)

  if (isReadOnlyMode) {
    return <BlastSettingsDrawerReadOnly />
  }

  return <BlastSettingsDrawer />
}
