import {
  type BaseSequenceItemAPI,
  convertSequenceItemAPIToSequenceItemUI,
  type SequenceItemUI
} from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSequenceItem(sequenceItemId?: string | null): UseResource<SequenceItemUI> {
  const queryName = [QUERY_NAME.sequenceItem, sequenceItemId]

  const { isInitialLoading, data: sequenceItem } = useQuery<BaseSequenceItemAPI>(
    queryName,
    async () => {
      return request.get<BaseSequenceItemAPI>(`sequence-item/${sequenceItemId}`)
    },
    {
      enabled: sequenceItemId !== null && sequenceItemId !== undefined
    }
  )

  return {
    data: sequenceItem && convertSequenceItemAPIToSequenceItemUI(sequenceItem),
    isLoading: isInitialLoading
  }
}
