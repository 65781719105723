import {
  FlowTemplateSlug,
  SequenceTag,
  SequenceTemplateSlug,
  SiteEngineSlug
} from '@ghostmonitor/recartapis'
import { type PlaceholderSequence } from '../../../../components/sequences-list/types/placeholder-sequence.type'

export const messengerWelcomePlaceholderSequences: PlaceholderSequence[] = [
  {
    name: 'Add to Cart Popup',
    description:
      'Send a friendly message or even a discount code to customers who subscribe using the Messenger Add to Cart Popup.',
    source: {
      templateSlug: SequenceTemplateSlug.MESSENGER_ATC_POPOUP_OPTIN
    },
    tags: [SequenceTag.MESSENGER_ATC_POPOUP_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Discount Widget',
    description: 'Send a discount code to visitors who subscribe using the Discount Widget.',
    source: {
      templateSlug: SequenceTemplateSlug.DISCOUNT_WIDGET_OPTIN
    },
    tags: [SequenceTag.DISCOUNT_WIDGET_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Customer Chat',
    description: 'Encourage your customers to ask questions using the Customer Chat.',
    source: {
      templateSlug: SequenceTemplateSlug.CUSTOMER_CHAT_OPTIN
    },
    tags: [SequenceTag.CUSTOMER_CHAT_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Sticky Discount Box',
    description: 'Welcome your visitors who subscribe via the Sticky Discount Box.',
    source: { templateSlug: SequenceTemplateSlug.STICKY_DISCOUNT_BOX_OPTIN },
    tags: [SequenceTag.STICKY_DISCOUNT_BOX_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Wheelio',
    description: 'Send a discount code to visitors who just spinned the Wheelio wheel.',
    source: { templateSlug: SequenceTemplateSlug.WHEELIO_OPTIN },
    tags: [SequenceTag.WHEELIO_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Optimonk',
    description: 'Match your brand with your Optimonk popup and a special welcome message.',
    source: { templateSlug: SequenceTemplateSlug.OPTIMONK_OPTIN },
    tags: [SequenceTag.OPTIMONK_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'WooHoo',
    description: 'Boost your conversion rate with an automated discount message.',
    source: { templateSlug: SequenceTemplateSlug.WOOHOO_OPTIN },
    tags: [SequenceTag.WOOHOO_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Order Page Subscription',
    description: 'Welcome your customers who subscribe on the order page.',
    source: { templateSlug: SequenceTemplateSlug.ORDER_PAGE_WIDGET_OPTIN },
    tags: [SequenceTag.ORDER_PAGE_WIDGET_OPTIN, SequenceTag.WELCOME, SequenceTag.FBMESSAGE],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY],
    isEnabled: false
  }
]

export const smsWelcomePlaceholderSequences: PlaceholderSequence[] = [
  {
    name: 'Save User Input',
    source: { templateSlug: FlowTemplateSlug.SMS_WELCOME_SAVE_USER_INPUT },
    tags: [
      SequenceTag.WELCOME,
      SequenceTag.SMS,
      SequenceTag.SAVE_USER_INPUT,
      SequenceTag.DEFAULT_WELCOME
    ],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Checkout Subscription',
    source: { templateSlug: FlowTemplateSlug.SMS_WELCOME_CHECKOUT_SUBSCRIPTION },
    tags: [
      SequenceTag.ORDER_PAGE_WIDGET_OPTIN,
      SequenceTag.WELCOME,
      SequenceTag.SMS,
      SequenceTag.DEFAULT_WELCOME
    ],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  }
]
