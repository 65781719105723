import {
  type EmbeddedFormDesktopAdditionalImageSettings,
  type EmbeddedFormDisplayRules,
  type EmbeddedFormMobileAdditionalImageSettings,
  type ExitIntentInteractions,
  type FormField,
  type ImageField,
  type InHouseABTesting,
  type LandingPageDesktopAdditionalImageSettings,
  type LandingPageDisplayRules,
  type LandingPageMobileAdditionalImageSettings,
  type NetworkOptin,
  type OptinToolListView,
  type PopupAdditionalImageSettings,
  type PopupDisplayRules,
  type ThirdPartyABTesting,
  type URLRules
} from '@ghostmonitor/recartapis'
import { type RuleObject } from 'antd/lib/form'
import intersection from 'lodash/intersection'
import isURL from 'validator/lib/isURL'
import { type GeotargetingCountry } from '../../../hooks/use-geotargeting-countries'
import { type OptinToolMetaErrorField } from '../../../store/slices/optin-tool-editor/optin-tool-editor.reducer'

function getOtherRules(urlRules: URLRules, type: keyof URLRules): string[] {
  if (!urlRules) {
    return []
  }

  const rules: string[] = []

  for (const [key, value] of Object.entries(urlRules)) {
    if (key === type) {
      continue
    }

    // only same targeting types are considered
    if (value.match === urlRules[type]?.match) {
      rules.push(...value.values)
    }
  }

  return rules
}

export function validateURLRules(urlRules: URLRules | undefined): OptinToolMetaErrorField {
  if (!urlRules) {
    return { errorMessage: '' }
  }

  const error = {
    errorMessage: '',
    fieldErrors: {},
    errorValues: {}
  } satisfies OptinToolMetaErrorField
  for (const [key, value] of Object.entries(urlRules)) {
    if (value.values.length === 0) {
      error.errorMessage = 'Please enter text or URL'
      error.fieldErrors[key] = 'Please enter text or URL'
    }
  }

  for (const [key, value] of Object.entries(urlRules)) {
    if (value.values.length === 0) {
      continue
    }
    const rules = getOtherRules(urlRules, key as keyof URLRules)

    const duplicateRules = intersection(value.values, rules)
    if (duplicateRules.length > 0) {
      error.errorMessage = 'Resolve conflicting conditions'
      error.errorValues[key] = duplicateRules
      error.fieldErrors[key] = 'Resolve conflicting conditions'
    }
  }

  return error
}

export function validateEmptyExitIntentUserAction(values: ExitIntentInteractions[]) {
  return values.length === 0
    ? { errorMessage: 'Please select at least one user interaction' }
    : { errorMessage: '' }
}

export function validateAdditionalImage(
  additionalImage:
    | PopupAdditionalImageSettings
    | LandingPageDesktopAdditionalImageSettings
    | LandingPageMobileAdditionalImageSettings
    | EmbeddedFormDesktopAdditionalImageSettings
    | EmbeddedFormMobileAdditionalImageSettings
    | null
): OptinToolMetaErrorField {
  if (additionalImage !== null && !(additionalImage.image as ImageField)?.source) {
    return { errorMessage: 'Please upload an image' }
  }

  return { errorMessage: '' }
}

export function validateSequenceId(sequenceId: string): OptinToolMetaErrorField {
  if (!sequenceId) {
    return { errorMessage: 'Please select a flow' }
  }

  return { errorMessage: '' }
}

export function validateCustomerChatGreeting(greeting: string | null): OptinToolMetaErrorField {
  if (greeting === null) {
    return { errorMessage: '' }
  }
  if (greeting.trim() === '') {
    return { errorMessage: 'Please enter a greeting' }
  }
  if (greeting.length > 80) {
    return { errorMessage: 'Maximum 80 characters' }
  }

  return { errorMessage: '' }
}

export function validateField(inputField: FormField): OptinToolMetaErrorField['fieldErrors'] {
  const fieldErrors: OptinToolMetaErrorField['fieldErrors'] = {}

  switch (inputField.type) {
    case 'checkbox-group':
    case 'radio-group':
    case 'dropdown': {
      const isEmpty = inputField.options?.length === 0
      fieldErrors.options = isEmpty ? 'Minimum one item is mandatory' : ''
      fieldErrors.label = inputField.label.text.length > 100 ? 'Maximum 100 characters' : ''
      break
    }
    case 'text-input':
    case 'number-input':
    case 'date-input': {
      fieldErrors.label = inputField.label.text.length > 100 ? 'Maximum 100 characters' : ''
      fieldErrors.placeholderText =
        inputField.placeholderText.length > 35 ? 'Maximum 35 characters' : ''
      break
    }
  }

  return fieldErrors
}

export function validateFormItems(items: FormField[]): OptinToolMetaErrorField[] {
  return items.map((item) => {
    const fieldErrors = validateField(item)
    const errorMessage =
      fieldErrors && Object.values(fieldErrors).some((value) => value !== '')
        ? 'Form item is invalid'
        : ''
    return { fieldErrors, errorMessage }
  })
}

export function validatePageTitle(pageTitle: string): OptinToolMetaErrorField {
  if (pageTitle === '') {
    return { errorMessage: 'Please enter a page title' }
  }

  if (pageTitle.length > 255) {
    return { errorMessage: 'Page title cannot be more than 255 characters' }
  }

  return { errorMessage: '' }
}

export function validateShopifyLink(link: string): OptinToolMetaErrorField {
  if (link === '') {
    return { errorMessage: 'Please enter a link' }
  }

  if (link.length > 255) {
    return { errorMessage: 'Link cannot be more than 255 characters' }
  }

  if (/[^a-zA-Z0-9-]/.test(link)) {
    return { errorMessage: 'You cannot add special characters to the link' }
  }

  return { errorMessage: '' }
}

export function validateURL(url?: string): OptinToolMetaErrorField {
  if (url === undefined) {
    return { errorMessage: '' }
  }

  if (url === '') {
    return { errorMessage: 'Please enter a link' }
  }

  if (!isURL(url, { require_protocol: true })) {
    return { errorMessage: 'URL format is invalid' }
  }

  return { errorMessage: '' }
}

export function validateGeotargeting(
  location:
    | LandingPageDisplayRules['location']
    | PopupDisplayRules['location']
    | EmbeddedFormDisplayRules['location'],
  availableCountries: GeotargetingCountry[]
) {
  if (!location) {
    return { errorMessage: '' }
  }

  if (location.values.length === 0) {
    return { errorMessage: 'Please select a country' }
  }

  const invalidCountries = location.values.filter((value) => {
    const availableCountry = availableCountries.find((country) => value === country.countryCode)
    return !availableCountry?.canSend
  })

  if (invalidCountries.length > 0) {
    return {
      errorMessage: 'Countries highlighted with red are unavailable, please remove them',
      errorValues: invalidCountries
    }
  }

  return { errorMessage: '' }
}

export function validateABTesting(
  abTesting: ThirdPartyABTesting | InHouseABTesting | null | undefined
) {
  if (abTesting === undefined) {
    return {
      errorMessage: ''
    }
  }

  if (abTesting === null) {
    return {
      errorMessage: 'Please select an environment'
    }
  }

  if (abTesting.type === 'in-house') {
    return {
      errorMessage: abTesting.experimentId ? '' : 'Please select an experiment'
    }
  }
  const hasError = ['evaluationPath', 'variant'].some((property) => abTesting[property] === '')

  return { errorMessage: hasError ? 'invalid abTesting values' : '' }
}

export function isUniqueKeywordOptinToolName(optinTools: OptinToolListView[]) {
  return {
    validator(_: RuleObject, value: string) {
      const optinTool = optinTools.find(
        (optinTool) => optinTool.name.toLowerCase() === value.toLowerCase().trim()
      )
      if (optinTool) {
        return Promise.reject(
          new Error(
            `An opt-in tool already has this name. Please rename it if you'd like to use '${value}' as a keyword.`
          )
        )
      }
      return Promise.resolve()
    }
  }
}

export function validateNetworkOptin(
  networkOptin: NetworkOptin | undefined
): OptinToolMetaErrorField {
  if (networkOptin === undefined) {
    return { errorMessage: '' }
  }

  if (networkOptin.label.text === '') {
    return { errorMessage: 'Text is required' }
  }

  return { errorMessage: '' }
}
