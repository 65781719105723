import cn from 'classnames'
import { type BaseEmoji, type EmojiData } from 'emoji-mart'
import { useRef, useState } from 'react'
import { EmojiMartPicker } from '../../../../../../components/ui-kit/emoji-picker/emoji-picker.component'
import emojiPickerUiKitStyles from '../../../../../../components/ui-kit/emoji-picker/emoji-picker.component.scss'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { ReactComponent as EmojiIcon } from '../../../../../../static/images/svg/sequence-editor/smiley-icon.svg'
import { type InlineEditorPlugin } from '../../../../../../utils/inline-editor/types/inline-editor-plugin.type'
import {
  type InlineEditorProps,
  useInlineEditorHOCControl
} from '../../use-inline-editor-hoc-control.hook'
import styles from './make-emoji-picker.scss'

interface MakeEmojiPickerProps {}

interface MakeEmojiPickerOptions {
  showCharacterCounter?: boolean
}

function replaceHtmlValueToRawValue(htmlValue: string): string {
  return htmlValue
}

export function makeEmojiPicker<T extends InlineEditorProps>(
  WrappedComponent: React.FC<T>,
  options: MakeEmojiPickerOptions = {}
): React.FC<MakeEmojiPickerProps & T> {
  const EmojiPicker = (props: React.PropsWithChildren<MakeEmojiPickerProps & T>) => {
    const {
      html,
      setHtml,
      handlers,
      editorRef,
      rawValue,
      isFocus,
      caretPosition,
      charCount,
      insertContent
    } = useInlineEditorHOCControl(
      'make-emoji',
      props,
      replaceHtmlValueToRawValue,
      (rawValue: string) => rawValue
    )

    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const emojiToggleRef = useRef<HTMLDivElement>()

    function toggleEmojiPicker(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      e.stopPropagation()
      setShowEmojiPicker(!showEmojiPicker)
    }

    function handleAddEmoji(emoji: EmojiData, e: React.MouseEvent<HTMLElement>) {
      const newHtml = insertContent((emoji as BaseEmoji).native, caretPosition)
      setHtml(newHtml)
      setShowEmojiPicker(false)
      editorRef.current.focus()
    }

    function handleClick(event) {
      props.onClick?.(event)
    }

    function handleMouseMove(event: React.MouseEvent<HTMLDivElement>) {
      props.onMouseMove?.(event)
    }

    function handleMouseLeave(event: React.MouseEvent<HTMLDivElement>) {
      props.onMouseLeave?.(event)
    }

    const button = (
      <Tooltip placement='topLeft' title='Add emoji' arrowPointAtCenter>
        <div
          className={cn(styles.addonContainer, {
            [styles.hasFocus]: isFocus,
            [styles.isEditable]: true
          })}
          data-testid='add-emoji-button'
          ref={emojiToggleRef}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClick={toggleEmojiPicker}
        >
          <div className={styles.emojiPickerIcon}>
            <EmojiIcon />
          </div>
          {options.showCharacterCounter && <div className={styles.characterCount}>{charCount}</div>}
        </div>
      </Tooltip>
    )

    const popover = (
      <>
        {showEmojiPicker && (
          <div className={emojiPickerUiKitStyles.pickerContainer}>
            <div
              className={styles.pickerMask}
              onClick={() => {
                setShowEmojiPicker(false)
              }}
            />
            <EmojiMartPicker
              onEmojiSelect={handleAddEmoji}
              theme='light'
              emojiButtonSize={40}
              emojiSize={24}
            />
          </div>
        )}
      </>
    )

    const pluginComponent: InlineEditorPlugin = {
      name: 'emoji-picker',
      button,
      popover
    }

    return (
      <WrappedComponent
        {...props}
        {...handlers}
        editorRef={editorRef}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        html={html}
        rawValue={rawValue}
        plugins={(props.plugins ?? []).concat(pluginComponent)}
      />
    )
  }
  return EmojiPicker
}
