import { type Blast, type SegmentListView, type TargetRulesBlast } from '@ghostmonitor/recartapis'
import * as antd from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import cn from 'classnames'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../../../../../../components/ui-kit/button/button.component'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { ReactComponent as InfoIcon } from '../../../../../../static/images/svg/sequence-editor/info-icon.svg'
import {
  removeBlastTargetRule,
  updateBlastTargetRules
} from '../../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { type SubscribersCountData } from '../../../../../../types/subscribers-count.type'
import { formatters } from '../../../../../../utils/formatters/formatters'
import styles from './segment-section.component.scss'

export interface SegmentSectionProps {
  segments: SegmentListView[]
  blast: Blast
  targetType: keyof TargetRulesBlast
  title: string
  allSubscribersCount: SubscribersCountData
  showToolTip?: boolean
}

const MAX_SEGMENT_COUNT = 15
const MAX_SEGMENT_NAME_LENGTH = 25

export function Select(props: antd.SelectProps<any>) {
  return (
    <antd.Select className={styles.select} {...props}>
      {props.children}
    </antd.Select>
  )
}

export function SegmentSection({
  segments,
  blast,
  targetType,
  title,
  allSubscribersCount,
  showToolTip
}: SegmentSectionProps) {
  const dispatch = useDispatch()
  const [searchMultiSegment, setSearchMultiSegment] = useState({
    includeSegmentIds: '',
    excludeSegmentIds: ''
  })
  const complementType =
    targetType === 'includeSegmentIds' ? 'excludeSegmentIds' : 'includeSegmentIds'
  const segmentsToList =
    segments?.filter((segment) => !blast.targetRules?.[complementType]?.includes(segment.id)) ?? []

  const isEmptyTargetRules =
    !blast.targetRules?.[targetType] || blast.targetRules?.[targetType]?.length === 0
  const includePlaceholderText = isEmptyTargetRules
    ? `All subscribers (${formatters.number(allSubscribersCount?.count ?? 0)})`
    : 'Select segments or lists'
  const excludePlaceholderText = 'Select segments or lists'
  const isAllSubscribers =
    targetType === 'includeSegmentIds' && blast.targetRules?.[targetType]?.length === 0

  const segmentsSelected =
    segments?.filter((segment) => blast.targetRules?.[targetType]?.includes(segment.id)) ?? []

  function handleSelectMultipleSegments(targetType: keyof TargetRulesBlast, value: string[]) {
    let segmentIds = value
    if (value.includes('all')) {
      if (value[value.length - 1] === 'all') {
        segmentIds = []
      } else {
        segmentIds = value.filter((segmentId) => segmentId !== 'all')
      }
    }
    dispatch(updateBlastTargetRules({ targetType, segmentIds }))
  }

  function handleRemoveFromMultipleSegments(targetType: keyof TargetRulesBlast, segmentId: string) {
    dispatch(removeBlastTargetRule({ targetType, segmentId }))
  }

  function handleMultipleSegmentSearch(targetType: keyof TargetRulesBlast, value: string) {
    setSearchMultiSegment({ ...searchMultiSegment, [targetType]: value })
  }

  function getLabel() {
    if (showToolTip) {
      return (
        <div className='flex items-center'>
          <div className='font-semibold text-sm text-primary mr-1'>{title}</div>
          <Tooltip
            placement='bottom'
            title='Subscribers outside of US and Canada will only receive the text part of the message without attachments (as an SMS).'
            arrowPointAtCenter
          >
            <InfoIcon className='icon-tiny text-secondary' />
          </Tooltip>
        </div>
      )
    }
    return <div className='font-semibold text-sm text-primary'>{title}</div>
  }

  const limitExceeded =
    blast.targetRules?.excludeSegmentIds?.length + blast.targetRules?.includeSegmentIds?.length ===
    MAX_SEGMENT_COUNT

  return (
    <FormItem
      className={cn(styles[`${targetType}FormItem`], styles.segmentSection)}
      label={getLabel()}
    >
      <Select
        className={styles.selectMulti}
        mode='multiple'
        tagRender={(props) => undefined}
        value={blast.targetRules?.[targetType] ?? ['all']}
        onChange={(value) => {
          handleSelectMultipleSegments(targetType, value)
        }}
        showSearch
        optionFilterProp='children'
        showArrow
        onSearch={(value) => {
          handleMultipleSegmentSearch(targetType, value)
        }}
        onBlur={() => {
          handleMultipleSegmentSearch(targetType, '')
        }}
        size='large'
        popupClassName={styles.dropdown}
        data-testid={`select-${targetType}`}
      >
        {targetType === 'includeSegmentIds' && (
          <antd.Select.Option value='all' className={styles.allSubscribers}>
            <antd.Checkbox checked={isEmptyTargetRules} className={styles.checkbox} />
            {`All subscribers (${formatters.number(allSubscribersCount?.count ?? 0)})`}
          </antd.Select.Option>
        )}
        {segmentsToList.map((segment) => {
          const max = MAX_SEGMENT_NAME_LENGTH - String(segment.size).length
          const name = segment.name.length > max ? `${segment.name.slice(0, max)}...` : segment.name
          return (
            <antd.Select.Option
              value={segment.id}
              key={segment.id}
              title={segment.name}
              disabled={
                segment.creationStatus === 'calculating' ||
                (!blast?.targetRules?.[targetType]?.includes(segment.id) && limitExceeded)
              }
            >
              <antd.Checkbox
                checked={blast?.targetRules?.[targetType]?.includes(segment.id)}
                className={styles.checkbox}
              />
              {`${name} (${formatters.number(segment.size)})`}
            </antd.Select.Option>
          )
        })}
      </Select>
      {!searchMultiSegment[targetType] && (
        <div
          className={cn('absolute top-0 m-2 z-0', {
            'text-grey-5': !isAllSubscribers,
            'text-black': isAllSubscribers
          })}
        >
          {targetType === 'includeSegmentIds' ? includePlaceholderText : excludePlaceholderText}
        </div>
      )}

      <ul className='mt-3'>
        {segmentsSelected.map((segment) => {
          const max = MAX_SEGMENT_NAME_LENGTH - String(segment.size).length
          const name = segment.name.length > max ? `${segment.name.slice(0, max)}...` : segment.name
          return (
            <li className='my-1 text-sm' key={segment.id} title={segment.name}>
              {`${name} (${formatters.number(segment.size)})`}
              <Button
                className={cn(styles.removeSegmentButton, 'text-sm')}
                type='link'
                onClick={() => {
                  handleRemoveFromMultipleSegments(targetType, segment.id)
                }}
              >
                Remove
              </Button>
            </li>
          )
        })}
      </ul>
    </FormItem>
  )
}
