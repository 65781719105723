import {
  BlastStatus,
  getTriggerForPostId,
  isMessengerMessageSequenceItemUI,
  isRandomSplitSequenceItemUI,
  isSMSSequenceItemUI,
  isTriggerWithPostId,
  MessageType,
  type MessageUI,
  replacePostIdInTrigger,
  type SMSMessageContactCardUI,
  type SMSMessageMMSUI,
  type SMSMessageSequenceItemUI
} from '@ghostmonitor/recartapis'
import { createSlice, type PayloadAction, type SerializedError } from '@reduxjs/toolkit'
import { ObjectId } from 'bson'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { SequenceEditorError } from '../../../routes/SequenceEditor/types/sequence-editor-errors'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { patchLocalDraftSequenceEditorInstance } from '../../../routes/SequenceEditor/utils/draft'
import { patchSequence } from '../../modules/sequence/sequence.actions'
import { updateLocation } from '../location/location.reducer'
import {
  updateQuietHours,
  updateSegmentId,
  updateSendingFrequency,
  updateSequenceName
} from '../sequence/sequence.actions'
import { addConditionActionHandler } from './action-handlers/add-condition.handler'
import { createButtonActionHandler } from './action-handlers/create-button.handler'
import { createFollowUpPortActionHandler } from './action-handlers/create-followup-port.handler'
import { createMessageItemActionHandler } from './action-handlers/create-message-item.handler'
import { createMessageActionHandler } from './action-handlers/create-message.handler'
import { createQuickReplyActionHandler } from './action-handlers/create-quick-reply.handler'
import { createSequenceItemActionHandler } from './action-handlers/create-sequence-item.handler'
import { createSplitActionHandler } from './action-handlers/create-split.handler'
import { finishCustomConversationWizardHandler } from './action-handlers/finish-custom-conversation-wizard.handler'
import { inlineDiscountCodeValidationErrorHandler } from './action-handlers/inline-discount-code-validation-error.handler'
import { removeButtonActionHandler } from './action-handlers/remove-button.handler'
import { removeConditionActionHandler } from './action-handlers/remove-condition.handler'
import { removeMessageItemActionHandler } from './action-handlers/remove-message-item.handler'
import { removeMessageActionHandler } from './action-handlers/remove-message.handler'
import { removeQuickReplyActionHandler } from './action-handlers/remove-quick-reply.handler'
import { removeSequenceItemActionHandler } from './action-handlers/remove-sequence-item.handler'
import { removeSplitActionHandler } from './action-handlers/remove-split.handler'
import { reorderMessageActionHandler } from './action-handlers/reorder-message.handler'
import { reorderQuickReplyActionHandler } from './action-handlers/reorder-quick-reply.handler'
import { setVariablesMetaHandler } from './action-handlers/set-variables-meta.handler'
import { updateButtonActionHandler } from './action-handlers/update-button.handler'
import { updateConditionActionHandler } from './action-handlers/update-condition.handler'
import { updateDelayValueHandler } from './action-handlers/update-delay-value.handler'
import { updateDisplayMetaHandler } from './action-handlers/update-display-meta.handler'
import { updateMessageItemActionHandler } from './action-handlers/update-message-item.handler'
import { updateMessageActionHandler } from './action-handlers/update-message.handler'
import { updateModelOnSequenceActionHandler } from './action-handlers/update-model-on-sequence.handler'
import { updateQuickReplyActionHandler } from './action-handlers/update-quick-reply.handler'
import { updateSequenceItemActionHandler } from './action-handlers/update-sequence-item.handler'
import { updateSplitActionHandler } from './action-handlers/update-split.handler'
import { updateUserInputTypeActionHandler } from './action-handlers/update-user-input-type.handler'
import {
  blastScheduledChanged,
  blastValidationError,
  blastValidationSuccess,
  buttonValidationError,
  buttonValidationSuccess,
  clearSelection,
  CREATE_BUTTON,
  CREATE_FOLLOW_UP_PORT,
  CREATE_MESSAGE,
  CREATE_MESSAGE_ITEM,
  CREATE_QUICK_REPLY,
  CREATE_SEQUENCE_ITEM,
  CREATE_SPLIT,
  discountCodeTourFinished,
  discountCodeTourStarted,
  dropSMSContactCard,
  dropSMSMedia,
  facebookCommentsPostIdChanged,
  finishCustomConversationWizard,
  focusButton,
  focusMessage,
  galleryStepLeft,
  galleryStepRight,
  galleryStepToIndex,
  initializeSetting,
  inlineDiscountCodeValidationErrorExpired,
  inlineDiscountCodeValidationErrorNotExists,
  inlineDiscountCodeValidationSuccess,
  inlineVariableManualReviewAborted,
  inlineVariableMarkedForManualReview,
  leaveReadOnlyMode,
  localDraftChanged,
  mediaUploadCompleted,
  mediaUploadStarted,
  messageItemValidationError,
  messageItemValidationSuccess,
  messageValidationError,
  messageValidationSuccess,
  quickReplyValidationError,
  quickReplyValidationSuccess,
  readOnlyMode,
  REMOVE_BUTTON,
  REMOVE_MESSAGE,
  REMOVE_MESSAGE_ITEM,
  REMOVE_QUICK_REPLY,
  REMOVE_SEQUENCE_ITEM,
  REMOVE_SPLIT,
  removeBlastTargetRule,
  removeSequence,
  renameBlast,
  REORDER_MESSAGE,
  REORDER_QUICK_REPLY,
  saveSequencePartialFailure,
  scheduleBlastForLater,
  scheduleBlastForNow,
  sequenceCreated,
  sequenceExistsOnServer,
  sequenceItemValidationError,
  sequenceItemValidationSuccess,
  sequenceLoadedFromDraft,
  sequenceLoadedFromServer,
  setBlastScheduleType,
  setBlastStatus,
  setBlastSubdomain,
  settingsInitialized,
  splitValidationError,
  splitValidationSuccess,
  startDragging,
  startLinking,
  stopDragging,
  stopLinking,
  toggleBlastQuietHours,
  toggleBlastSmartSending,
  toggleDebugView,
  toggleSequenceItemStatistics,
  toggleSettingsDrawer,
  touchAllBlastFormItem,
  touchAllQuickReplies,
  touchAllSequenceItems,
  touchAllSplits,
  UPDATE_BUTTON,
  UPDATE_DELAY_VALUE,
  UPDATE_DISPLAY_META,
  UPDATE_LOGIC_ADD_CONDITION,
  UPDATE_LOGIC_REMOVE_CONDITION,
  UPDATE_LOGIC_UPDATE_CONDITION,
  UPDATE_LOGIC_USER_INPUT_TYPE,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_ITEM,
  UPDATE_MODEL_ON_SEQUENCE,
  UPDATE_QUICK_REPLY,
  UPDATE_SPLIT,
  updateBlast,
  updateBlastTargetRules,
  updateDiscountCodesOnSequenceItem,
  updateDiscountPoolIdsOnSequenceItem,
  updateSequence,
  updateSequenceCanConvert,
  updateSequenceItem
} from './sequence-editor.actions'
import {
  selectButtonMeta,
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectEntrySequenceItem,
  selectMessageItemMeta,
  selectMessageMeta,
  selectQuickReplyMeta,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectSplitMeta,
  selectVariableMeta
} from './sequence-editor.selectors'
import {
  blastInitialMeta,
  sequenceEditorInitialState,
  sequenceInitialMeta
} from './sequence-editor.state'
import { loadBlastThunk } from './thunks/load-blast.thunk'
import { loadEditorThunk } from './thunks/load-editor.thunk'
import { saveSequenceThunk } from './thunks/save-sequence.thunk'
import { sendTestThunk } from './thunks/send-test.thunk'
import { toggleSequenceThunk } from './thunks/toggle-sequence.thunk'
import { validateSequenceThunk } from './thunks/validate-sequence.thunk'

const sequenceEditorSlice = createSlice({
  name: 'sequenceEditor',
  initialState: sequenceEditorInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeSequence, (state) => {
      state.sequence = null
      state.sequenceMeta = null
      state.sequenceItemsById = {}
      state.sequenceItemsMetaById = {}
    })
    builder.addCase(touchAllSequenceItems, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.sequenceItemsTouched = true

      const sequence = selectEditorSequence(state)
      sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
        sequenceItemMeta.edited = true

        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        if (hasMessageOnSequenceItemType(sequenceItem)) {
          sequenceItem.messages.forEach((message, messageIndex) => {
            const messageMeta = selectMessageMeta(sequenceItemId, messageIndex)(state)
            messageMeta.edited = true
          })
        }
      })
    })
    builder.addCase(touchAllQuickReplies, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.quickRepliesTouched = true

      const sequence = selectEditorSequence(state)
      sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        if (isMessengerMessageSequenceItemUI(sequenceItem)) {
          Object.values(sequenceItem.quickReplies).forEach((quickReply, quickReplyIndex) => {
            const quickReplyMeta = selectQuickReplyMeta(sequenceItemId, quickReplyIndex)(state)
            quickReplyMeta.edited = true
          })
        } else {
          return state
        }
      })
    })
    builder.addCase(touchAllSplits, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.splitsTouched = true

      const sequence = selectEditorSequence(state)
      sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        if (isRandomSplitSequenceItemUI(sequenceItem)) {
          Object.values(sequenceItem.logic.randomSplit.variants).forEach((_, index) => {
            const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
            sequenceItemMeta.splits[index].edited = true
          })
        } else {
          return state
        }
      })
    })
    builder.addCase(facebookCommentsPostIdChanged, (state, action) => {
      const entrySequenceItem = selectEntrySequenceItem(state)
      let updatedTrigger: string[]
      if (entrySequenceItem.trigger.find(isTriggerWithPostId)) {
        updatedTrigger = entrySequenceItem.trigger.map((trigger) =>
          replacePostIdInTrigger(trigger, action.payload.postId)
        )
      } else {
        updatedTrigger = [
          ...entrySequenceItem.trigger,
          getTriggerForPostId(action.payload.pageId, action.payload.postId)
        ]
      }
      entrySequenceItem.trigger = updatedTrigger
    })
    builder.addCase(sequenceExistsOnServer, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.isEverSaved = true
    })
    builder.addCase(sequenceLoadedFromServer, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.isEverSaved = true
      sequenceMeta.unsavedChanges = false
    })
    builder.addCase(sequenceLoadedFromDraft, (state, action) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.isEverSaved = action.payload.isEverSaved
      sequenceMeta.unsavedChanges = action.payload.unsavedChanges
    })
    builder.addCase(sequenceCreated, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.isEverSaved = false
      sequenceMeta.unsavedChanges = true
    })
    builder.addCase(UPDATE_MODEL_ON_SEQUENCE, updateModelOnSequenceActionHandler)
    builder.addCase(updateSequence, (state, action) => {
      state.sequence = action.payload.sequence
      state.sequenceMeta ??= cloneDeep(sequenceInitialMeta)
    })

    builder.addCase(updateSequenceCanConvert, (state, action) => {
      state.sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        sequenceItem.canConvert = action.payload.canConvert
      })
    })

    builder.addCase(CREATE_SEQUENCE_ITEM, createSequenceItemActionHandler)
    builder.addCase(REMOVE_SEQUENCE_ITEM, removeSequenceItemActionHandler)
    builder.addCase(updateSequenceItem, updateSequenceItemActionHandler)

    builder.addCase(CREATE_MESSAGE, createMessageActionHandler)
    builder.addCase(UPDATE_MESSAGE, updateMessageActionHandler)
    builder.addCase(REMOVE_MESSAGE, removeMessageActionHandler)
    builder.addCase(REORDER_MESSAGE, reorderMessageActionHandler)

    builder.addCase(CREATE_MESSAGE_ITEM, createMessageItemActionHandler)
    builder.addCase(REMOVE_MESSAGE_ITEM, removeMessageItemActionHandler)
    builder.addCase(UPDATE_MESSAGE_ITEM, updateMessageItemActionHandler)

    builder.addCase(CREATE_BUTTON, createButtonActionHandler)
    builder.addCase(REMOVE_BUTTON, removeButtonActionHandler)
    builder.addCase(UPDATE_BUTTON, updateButtonActionHandler)

    builder.addCase(CREATE_QUICK_REPLY, createQuickReplyActionHandler)
    builder.addCase(UPDATE_QUICK_REPLY, updateQuickReplyActionHandler)
    builder.addCase(REORDER_QUICK_REPLY, reorderQuickReplyActionHandler)
    builder.addCase(REMOVE_QUICK_REPLY, removeQuickReplyActionHandler)

    builder.addCase(CREATE_FOLLOW_UP_PORT, createFollowUpPortActionHandler)

    builder.addCase(UPDATE_DELAY_VALUE, updateDelayValueHandler)
    builder.addCase(UPDATE_DISPLAY_META, updateDisplayMetaHandler)
    builder.addCase(UPDATE_LOGIC_ADD_CONDITION, addConditionActionHandler)
    builder.addCase(UPDATE_LOGIC_REMOVE_CONDITION, removeConditionActionHandler)
    builder.addCase(UPDATE_LOGIC_UPDATE_CONDITION, updateConditionActionHandler)
    builder.addCase(UPDATE_LOGIC_USER_INPUT_TYPE, updateUserInputTypeActionHandler)

    builder.addCase(updateDiscountCodesOnSequenceItem, (state, action) => {
      const sequenceItem = selectSequenceItem(action.payload.sequenceItemId)(state)

      if (isMessengerMessageSequenceItemUI(sequenceItem) || isSMSSequenceItemUI(sequenceItem)) {
        sequenceItem.discountCodes = action.payload.discounts.map((discount) => discount.code)
        sequenceItem.discounts = action.payload.discounts
      }
    })

    builder.addCase(updateDiscountPoolIdsOnSequenceItem, (state, action) => {
      const sequenceItem = selectSequenceItem(action.payload.sequenceItemId)(state)

      if (isSMSSequenceItemUI(sequenceItem)) {
        sequenceItem.discountCodePoolId = action.payload.poolIds[0] ?? ''
      }
    })

    builder.addCase(discountCodeTourStarted, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.discountCodeTour = true
    })
    builder.addCase(discountCodeTourFinished, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.discountCodeTour = false
    })
    builder.addCase(inlineVariableManualReviewAborted, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.discountCodeTour = false
    })

    builder.addCase(inlineVariableMarkedForManualReview, (state, action) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.waitForMessageChange = true

      const variableMeta = selectVariableMeta(
        action.payload.sequenceItemId,
        action.payload.messageIndex,
        action.payload.variableIndex
      )(state)
      variableMeta.manualReview = true
    })

    builder.addCase(inlineDiscountCodeValidationSuccess, (state, action) => {
      const sequenceMeta = selectEditorSequenceMeta(state)

      const invalidDiscountCodes = new Set(sequenceMeta.invalidDiscountCodes)
      invalidDiscountCodes.delete(action.payload.discountCode)
      sequenceMeta.invalidDiscountCodes = Array.from(invalidDiscountCodes)

      const expiredDiscountCodes = new Set(sequenceMeta.expiredDiscountCodes)
      expiredDiscountCodes.delete(action.payload.discountCode)
      sequenceMeta.expiredDiscountCodes = Array.from(expiredDiscountCodes)

      const validDiscountCodes = new Set(sequenceMeta.validDiscountCodes)
      validDiscountCodes.add(action.payload.discountCode)
      sequenceMeta.validDiscountCodes = Array.from(validDiscountCodes)

      setVariablesMetaHandler(state)
    })
    builder.addCase(
      inlineDiscountCodeValidationErrorExpired,
      inlineDiscountCodeValidationErrorHandler
    )
    builder.addCase(
      inlineDiscountCodeValidationErrorNotExists,
      inlineDiscountCodeValidationErrorHandler
    )
    builder.addCase(CREATE_SPLIT, createSplitActionHandler)
    builder.addCase(UPDATE_SPLIT, updateSplitActionHandler)
    builder.addCase(REMOVE_SPLIT, removeSplitActionHandler)

    builder.addCase(finishCustomConversationWizard, finishCustomConversationWizardHandler)

    builder.addCase(localDraftChanged, (state) => {
      const sequence = selectEditorSequence(state)
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.unsavedChanges = true

      // https://app.clubhouse.io/recart/story/14805/move-local-draft-update-logic-from-localdraftchanged-reducer
      patchLocalDraftSequenceEditorInstance(sequence._id, {
        unsavedChanges: true
      })
    })
    builder.addCase(saveSequencePartialFailure, (state, action: PayloadAction<SerializedError>) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.error = action.payload
    })

    builder.addCase(loadBlastThunk.fulfilled, (state, action) => {
      const isEverSaved = state.sequenceMeta.isEverSaved
      if (action.payload === null) {
        state.blast = {
          _id: new ObjectId().toHexString(),
          siteId: action.meta.arg.siteId,
          sequenceId: state.sequence._id,
          name: state.sequence.name,
          scheduledFor: null,
          scheduleType: 'siteTimezone',
          isQuietHoursEnabled: true,
          status: BlastStatus.DRAFT,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      } else {
        state.blast = { ...action.payload }
      }

      if (!isEverSaved) {
        state.sequence.name = ''
        state.blast.name = ''
        state.blastMeta = { ...blastInitialMeta }
      }
    })

    builder.addCase(updateBlast, (state, action) => {
      state.blast = action.payload.blast
      state.blastMeta = { ...blastInitialMeta }
    })

    builder.addCase(loadEditorThunk.fulfilled, (state) => {
      state.errorCount = 0
      state.error = null

      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.toggleClicked = false
    })

    builder.addCase(saveSequenceThunk.pending, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.saving = true
      sequenceMeta.error = null
    })
    builder.addCase(saveSequenceThunk.fulfilled, (state, action) => {
      // TODO rework once partial success response is available
      const sequenceFromResponse = action.payload
      const sequence = selectEditorSequence(state)
      sequence.updatedAt = sequenceFromResponse.updatedAt

      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.saving = false
      sequenceMeta.isEverSaved = true
      sequenceMeta.unsavedChanges = false
    })
    builder.addCase(saveSequenceThunk.rejected, (state, action) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.saving = false
      sequenceMeta.error = action.error
    })

    builder.addCase(validateSequenceThunk.pending, (state) => {
      state.errorCount = 0

      const sequence = selectEditorSequence(state)

      sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
        sequenceItemMeta.error = null

        if (isMessengerMessageSequenceItemUI(sequenceItem)) {
          sequenceItem.quickReplies.forEach((quickReply, quickReplyIndex) => {
            const quickReplyMeta = selectQuickReplyMeta(sequenceItemId, quickReplyIndex)(state)
            quickReplyMeta.error = null
          })
        }

        if (hasMessageOnSequenceItemType(sequenceItem)) {
          sequenceItem.messages.forEach((message: MessageUI, messageIndex: number) => {
            const messageMeta = selectMessageMeta(sequenceItemId, messageIndex)(state)
            messageMeta.errors = []

            if (hasMessengerButtonOnMessageType(message)) {
              message.messengerTemplatePayload.buttons.forEach((button, buttonIndex) => {
                const buttonMeta = selectButtonMeta(
                  sequenceItemId,
                  messageIndex,
                  buttonIndex
                )(state)
                buttonMeta.error = null
              })
            }

            if (isMessageGenericGalleryTemplate(message)) {
              message.messengerTemplatePayload.forEach((messageItem, messageItemIndex) => {
                const messageItemMeta = selectMessageItemMeta(
                  sequenceItemId,
                  messageIndex,
                  messageItemIndex
                )(state)
                messageItemMeta.errors.length = 0

                messageItem.buttons.forEach((button, buttonIndex) => {
                  const buttonMeta = selectButtonMeta(
                    sequenceItemId,
                    messageIndex,
                    buttonIndex,
                    messageItemIndex
                  )(state)
                  buttonMeta.error = null
                })
              })
            }
          })
        }

        if (isRandomSplitSequenceItemUI(sequenceItem)) {
          Object.values(sequenceItem.logic.randomSplit.variants).forEach((_, index) => {
            const splitMeta = selectSplitMeta(sequenceItemId, index)(state)
            splitMeta.error = null
          })
        }
      })
    })

    builder.addCase(validateSequenceThunk.rejected, (state) => {
      if (state.errorCount > 0) {
        const sequence = selectEditorSequence(state)
        sequence.sequenceItemIds.forEach((sequenceItemId) => {
          const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
          sequenceItemMeta.messages.forEach((messageMeta) => {
            const errorIndex = messageMeta.messageItems.findIndex(
              (messageItemMeta) => messageItemMeta.errors.length > 0
            )
            if (errorIndex !== -1) {
              messageMeta.activeIndex = errorIndex
            }
          })
        })
      }
    })
    builder.addCase(setBlastStatus, (state, action) => {
      state.blast.status = action.payload.status
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(setBlastSubdomain, (state, action) => {
      state.blast.subdomain = action.payload.subdomain
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(toggleBlastQuietHours, (state, action) => {
      state.blast.isQuietHoursEnabled = action.payload.isEnabled
      state.sequence.isQuietHoursEnabled = action.payload.isEnabled
      state.sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        sequenceItem.isQuietHoursEnabled = action.payload.isEnabled
      })
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(toggleBlastSmartSending, (state, action) => {
      state.sequence.isSmartSendingEnabled = action.payload.isEnabled
      state.sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        sequenceItem.isSmartSendingEnabled = action.payload.isEnabled
      })
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(renameBlast, (state, action) => {
      state.blast.name = action.payload.name
      state.blast.updatedAt = new Date().toISOString()
      state.sequence.name = action.payload.name
    })
    builder.addCase(scheduleBlastForNow, (state) => {
      state.blast.scheduledFor = null
      state.blast.scheduleType = 'siteTimezone'
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(scheduleBlastForLater, (state, action) => {
      state.blast.scheduledFor = action.payload.scheduledFor
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(blastScheduledChanged, (state, action) => {
      if (state.blast?.scheduledFor) {
        state.blast.scheduledFor = action.payload.scheduledFor
        state.blast.updatedAt = new Date().toISOString()
      }
    })

    builder.addCase(setBlastScheduleType, (state, action) => {
      state.blast.scheduleType = action.payload.scheduleType
    })

    builder.addCase(updateBlastTargetRules, (state, action) => {
      if (!state.blast.targetRules) {
        state.blast.targetRules = {}
      }
      state.blast.targetRules[action.payload.targetType] = action.payload.segmentIds
    })
    builder.addCase(removeBlastTargetRule, (state, action) => {
      const { targetType, segmentId } = action.payload
      state.blast.targetRules[targetType] = state.blast.targetRules[targetType].filter(
        (id) => id !== segmentId
      )
    })
    builder.addCase(blastValidationSuccess, (state, action) => {
      if (state.blastMeta[action.meta.key].error) {
        state.errorCount -= 1
      }
      state.blastMeta[action.meta.key].error = null
    })
    builder.addCase(blastValidationError, (state, action) => {
      if (state.blastMeta[action.meta.key].error === null) {
        state.errorCount += 1
      }
      state.blastMeta[action.meta.key].error = action.payload.message
    })

    builder.addCase(touchAllBlastFormItem, (state) => {
      Object.keys(state.blastMeta).forEach((key) => {
        state.blastMeta[key].edited = true
      })
    })

    builder.addCase(updateQuietHours, (state, action) => {
      const { isQuietHoursEnabled } = action.payload

      const sequence = selectEditorSequence(state)
      sequence.isQuietHoursEnabled = isQuietHoursEnabled
      sequence.sequenceItemIds.forEach((itemId) => {
        const sequenceItem = selectSequenceItem(itemId)(state)
        if (sequenceItem) {
          sequenceItem.isQuietHoursEnabled = isQuietHoursEnabled
        }
      })
    })

    builder.addCase(updateSegmentId, (state, action) => {
      const { segmentId } = action.payload
      const entrySequenceItem = selectEntrySequenceItem(state)
      const entryTrigger = entrySequenceItem.trigger.filter(
        (trigger) => !trigger.startsWith('joined_segment_')
      )
      delete state.sequence.segmentId
      if (segmentId) {
        entryTrigger.push(`joined_segment_${segmentId}`)
        state.sequence.segmentId = segmentId
      }
      entrySequenceItem.trigger = entryTrigger
    })

    builder.addCase(updateSendingFrequency, (state, action) => {
      const { sendingFrequency } = action.payload

      const sequence = selectEditorSequence(state)
      sequence.sendingFrequency = sendingFrequency
      sequence.sequenceItemIds.forEach((itemId) => {
        const sequenceItem = selectSequenceItem(itemId)(state)
        if (sequenceItem) {
          sequenceItem.sendingFrequency = sendingFrequency
        }
      })
    })

    builder.addCase(updateSequenceName, (state, action) => {
      const { name } = action.payload
      state.sequence.name = name
    })

    builder.addCase(readOnlyMode, (state) => {
      state.isReadOnly = true
    })
    builder.addCase(leaveReadOnlyMode, (state) => {
      state.isReadOnly = false
      state.blast.status = BlastStatus.DRAFT
      state.blast.updatedAt = new Date().toISOString()
    })
    builder.addCase(toggleSettingsDrawer, (state, action) => {
      state.isSettingsDrawerVisible = action.payload.isVisible
    })
    builder.addCase(toggleSequenceThunk.pending, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.toggling = true
      sequenceMeta.toggleClicked = true
    })
    builder.addCase(toggleSequenceThunk.fulfilled, (state) => {
      const sequence = selectEditorSequence(state)
      sequence.isEnabled = !sequence.isEnabled

      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.toggling = false
    })
    builder.addCase(toggleSequenceThunk.rejected, (state, action) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.toggling = false
      sequenceMeta.error = action.error
    })

    builder.addCase(sendTestThunk.pending, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.sendingTest = true
      sequenceMeta.error = null
    })
    builder.addCase(sendTestThunk.fulfilled, (state) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.sendingTest = false
    })
    builder.addCase(sendTestThunk.rejected, (state, action) => {
      const sequenceMeta = selectEditorSequenceMeta(state)
      sequenceMeta.sendingTest = false
      sequenceMeta.error = action.error
    })

    builder.addCase(sequenceItemValidationSuccess, (state, action) => {
      const sequenceItemMeta = selectSequenceItemMeta(action.meta.sequenceItemId)(state)
      if (sequenceItemMeta.error !== null) {
        state.errorCount -= 1
      }
      sequenceItemMeta.error = null
    })
    builder.addCase(sequenceItemValidationError, (state, action) => {
      const sequenceItemMeta = selectSequenceItemMeta(action.meta.sequenceItemId)(state)
      if (sequenceItemMeta.error === null) {
        state.errorCount += 1
      }
      sequenceItemMeta.error = action.payload.message
    })
    builder.addCase(buttonValidationSuccess, (state, action) => {
      const buttonMeta = selectButtonMeta(
        action.meta.sequenceItemId,
        action.meta.messageIndex,
        action.meta.buttonIndex,
        action.meta.messageItemIndex
      )(state)
      if (buttonMeta.error !== null) {
        state.errorCount -= 1
      }
      buttonMeta.error = null
    })
    builder.addCase(buttonValidationError, (state, action) => {
      const buttonMeta = selectButtonMeta(
        action.meta.sequenceItemId,
        action.meta.messageIndex,
        action.meta.buttonIndex,
        action.meta.messageItemIndex
      )(state)
      if (buttonMeta.error === null) {
        state.errorCount += 1
      }
      buttonMeta.error = action.payload.message
    })

    builder.addCase(messageValidationSuccess, (state, action) => {
      const messageMeta = selectMessageMeta(
        action.meta.sequenceItemId,
        action.meta.messageIndex
      )(state)
      state.errorCount -= messageMeta.errors.length
      messageMeta.errors.length = 0
    })
    builder.addCase(messageValidationError, (state, action) => {
      const messageMeta = selectMessageMeta(
        action.meta.sequenceItemId,
        action.meta.messageIndex
      )(state)

      if (messageMeta.errors.length !== action.payload.length) {
        state.errorCount += action.payload.length - messageMeta.errors.length
      }
      messageMeta.errors = action.payload
    })

    builder.addCase(messageItemValidationSuccess, (state, action) => {
      const messageItemMeta = selectMessageItemMeta(
        action.meta.sequenceItemId,
        action.meta.messageIndex,
        action.meta.messageItemIndex
      )(state)
      if (messageItemMeta.errors.length > 0) {
        state.errorCount -= messageItemMeta.errors.length
      }
      messageItemMeta.errors.length = 0
    })
    builder.addCase(messageItemValidationError, (state, action) => {
      const messageItemMeta = selectMessageItemMeta(
        action.meta.sequenceItemId,
        action.meta.messageIndex,
        action.meta.messageItemIndex
      )(state)
      if (messageItemMeta.errors.length === 0) {
        state.errorCount += 1
      }
      messageItemMeta.errors = [action.payload]
    })

    builder.addCase(quickReplyValidationSuccess, (state, action) => {
      const quickReplyMeta = selectQuickReplyMeta(
        action.meta.sequenceItemId,
        action.meta.quickReplyIndex
      )(state)
      if (quickReplyMeta.error !== null) {
        state.errorCount -= 1
      }
      quickReplyMeta.error = null
    })
    builder.addCase(quickReplyValidationError, (state, action) => {
      const quickReplyMeta = selectQuickReplyMeta(
        action.meta.sequenceItemId,
        action.meta.quickReplyIndex
      )(state)
      if (quickReplyMeta.error === null) {
        state.errorCount += 1
      }
      quickReplyMeta.error = action.payload.message
    })
    builder.addCase(splitValidationSuccess, (state, action) => {
      const splitMeta = selectSplitMeta(action.meta.sequenceItemId, action.meta.splitIndex)(state)
      if (splitMeta.error !== null) {
        state.errorCount -= 1
      }
      splitMeta.error = null
    })
    builder.addCase(splitValidationError, (state, action) => {
      const splitMeta = selectSplitMeta(action.meta.sequenceItemId, action.meta.splitIndex)(state)
      if (splitMeta.error === null) {
        state.errorCount += 1
      }
      splitMeta.error = action.payload.message
    })
    builder.addCase(clearSelection, (state) => {
      const sequence = selectEditorSequence(state)
      sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
        sequenceItemMeta.selected = false

        sequenceItemMeta.messages.forEach((messageMeta) => {
          messageMeta.selected = false

          messageMeta.buttons.forEach((buttonMeta) => {
            buttonMeta.selected = false
          })

          messageMeta.messageItems.forEach((messageItemMeta) => {
            messageItemMeta.selected = false

            messageItemMeta.buttons.forEach((buttonMeta) => {
              buttonMeta.selected = false
            })
          })
        })
      })
    })
    builder.addCase(focusMessage, (state, action) => {
      const sequenceItemMeta = selectSequenceItemMeta(action.payload.sequenceItemId)(state)
      sequenceItemMeta.selected = true
      if (action.payload.messageItemIndex !== undefined) {
        const messageItemMeta = selectMessageItemMeta(
          action.payload.sequenceItemId,
          action.payload.messageIndex,
          action.payload.messageItemIndex
        )(state)
        messageItemMeta.selected = true
      } else {
        const messageMeta = selectMessageMeta(
          action.payload.sequenceItemId,
          action.payload.messageIndex
        )(state)
        messageMeta.selected = true
      }
    })
    builder.addCase(focusButton, (state, action) => {
      const sequenceItemMeta = selectSequenceItemMeta(action.payload.sequenceItemId)(state)
      sequenceItemMeta.selected = true
      if (action.payload.messageItemIndex !== undefined) {
        const messageItemMeta = selectMessageItemMeta(
          action.payload.sequenceItemId,
          action.payload.messageIndex,
          action.payload.messageItemIndex
        )(state)
        messageItemMeta.selected = true
      } else {
        const messageMeta = selectMessageMeta(
          action.payload.sequenceItemId,
          action.payload.messageIndex
        )(state)
        messageMeta.selected = true
      }
      const buttonMeta = selectButtonMeta(
        action.payload.sequenceItemId,
        action.payload.messageIndex,
        action.payload.buttonIndex,
        action.payload.messageItemIndex
      )(state)
      buttonMeta.selected = true
    })

    builder.addCase(patchSequence, (state, action) => {
      const sequence = selectEditorSequence(state)
      merge(sequence, action.payload)
    })
    builder.addCase(startDragging, (state, action) => {
      state.draggedItemType = action.payload.itemType
    })
    builder.addCase(stopDragging, (state) => {
      state.draggedItemType = null
    })
    builder.addCase(startLinking, (state, action) => {
      state.linking = true
      state.linkingMeta = {
        portType: action.payload.portType,
        portParentSequenceItemId: action.payload.portParentSequenceItemId
      }
    })
    builder.addCase(stopLinking, (state) => {
      state.linking = false
      state.linkingMeta = {
        portType: null,
        portParentSequenceItemId: null
      }
    })

    builder.addCase(toggleSequenceItemStatistics, (state, action) => {
      state.showSequenceItemStatistics = action.payload.visible
    })
    builder.addCase(toggleDebugView, (state, action) => {
      state.showDebugView = action.payload.visible
    })
    builder.addCase(galleryStepRight, (state, action) => {
      const { messageIndex, sequenceItemId } = action.payload
      const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
      sequenceItemMeta.messages[messageIndex].activeIndex += 1
    })
    builder.addCase(galleryStepLeft, (state, action) => {
      const { messageIndex, sequenceItemId } = action.payload
      const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
      sequenceItemMeta.messages[messageIndex].activeIndex -= 1
    })
    builder.addCase(galleryStepToIndex, (state, action) => {
      const { messageIndex, sequenceItemId, index } = action.payload
      const sequenceItemMeta = selectSequenceItemMeta(sequenceItemId)(state)
      sequenceItemMeta.messages[messageIndex].activeIndex = index
    })
    builder.addCase(updateLocation, (state, action) => {
      if (!action.payload.pathname.match(/^\/sequence-editor/)) {
        state.sequence = null
        state.sequenceMeta = null
        state.sequenceItemsById = {}
        state.sequenceItemsMetaById = {}
        state.blast = null
      }
      state.initializedSettings.length = 0
      state.isReadOnly = false
      state.isSettingsDrawerVisible = false
      state.isSettingsInitialized = false
    })
    builder.addCase(initializeSetting, (state, action) => {
      const settingsSet = new Set(state.initializedSettings)
      settingsSet.add(action.payload.name)
      state.initializedSettings = Array.from(settingsSet)
    })
    builder.addCase(settingsInitialized, (state) => {
      state.isSettingsInitialized = true
    })
    builder.addCase(dropSMSMedia, (state, action) => {
      const sequenceItem = selectSequenceItem<SMSMessageSequenceItemUI>(action.meta.sequenceItemId)(
        state
      )
      const message: SMSMessageMMSUI = sequenceItem.messages[0] as any
      message.type = MessageType.SMS_MMS
      message.attachments = []
    })
    builder.addCase(dropSMSContactCard, (state, action) => {
      const sequenceItem = selectSequenceItem<SMSMessageSequenceItemUI>(action.meta.sequenceItemId)(
        state
      )
      const message: SMSMessageContactCardUI = sequenceItem.messages[0] as any
      message.type = MessageType.SMS_CONTACT_CARD
    })

    builder.addCase(mediaUploadCompleted, (state, action) => {
      const sequenceItem = selectSequenceItem<SMSMessageSequenceItemUI>(
        action.payload.sequenceItemId
      )(state)

      const message: SMSMessageMMSUI = sequenceItem.messages[action.payload.messageIndex] as any
      message.attachments = [action.payload.url]
      const messageMeta = selectMessageMeta(
        action.payload.sequenceItemId,
        action.payload.messageIndex
      )(state)
      messageMeta.edited = true

      const emptyMediaErrorIndex = messageMeta.errors.findIndex(
        (error) => error.name === SequenceEditorError.MessageEmptyMediaAttachment
      )
      if (emptyMediaErrorIndex !== -1) {
        state.errorCount -= 1
        messageMeta.errors.splice(emptyMediaErrorIndex, 1)
      }
    })

    builder.addCase(mediaUploadStarted, (state, action) => {
      const sequenceItemMeta = selectSequenceItemMeta(action.payload.sequenceItemId)(state)
      sequenceItemMeta.edited = true
    })
  }
})

export const { reducer: sequenceEditorReducer } = sequenceEditorSlice
