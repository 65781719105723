import { MessageType, type SMSMessageContactCardUI } from '@ghostmonitor/recartapis'
import { MessageTrayItem } from '../../../types/tray-item.type'
import { trayIcons } from '../tray-icons'
import { TrayItemComponent } from '../tray-item.component'

export function TrayItemSMSContactCard() {
  const message: SMSMessageContactCardUI = {
    type: MessageType.SMS_CONTACT_CARD,
    text: ''
  }
  return (
    <TrayItemComponent
      itemType={MessageTrayItem.smsMedia}
      icon={trayIcons.contactCard}
      doubleWidth
      message={message}
      title='+ Contact card'
    />
  )
}
