import { SequenceTag, type SequenceUI } from '@ghostmonitor/recartapis'
import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { hooks } from '../../../hooks/hooks'
import type { AppDispatch } from '../../../store/create-store'
import { selectEditorSequence } from '../../../store/selectors'
import { toggleSequenceThunk } from '../../../store/slices/sequence-editor/thunks/toggle-sequence.thunk'
import { Notification } from '../../../utils/notification/notification.util'
import { runThunks } from '../settings/run-thunks'
import { getErrorMessage, SequenceEditorErrorMessage } from '../types/sequence-editor-errors'
import { useSequenceEditorSettings } from './use-sequence-editor-settings'

function isAchievementSequence(sequence: SequenceUI) {
  return [
    SequenceTag.RECEIPT,
    SequenceTag.WELCOME_POPUP,
    SequenceTag.ABANDONMENT,
    SequenceTag.FULFILLMENT
  ].some((tag) => sequence.tags.includes(tag))
}

export function useToggleSequence(): { toggleSequence: () => Promise<void> } {
  const dispatch: AppDispatch = useDispatch()
  const editorSettings = useSequenceEditorSettings()
  const sequence = useSelector(selectEditorSequence)

  const { invalidateCache: invalidateSequencesCache } = hooks.useSequencesCache()
  const { invalidateCache: invalidateMeCache } = hooks.useMeCache()

  async function toggleSequence() {
    const toggleThunksResult = await dispatch(runThunks(editorSettings.toggleThunks))

    if (isFulfilled(toggleThunksResult)) {
      invalidateSequencesCache()

      // TODO remove business logic
      if (isAchievementSequence(sequence)) {
        invalidateMeCache()
      }
    } else if (isRejected(toggleThunksResult)) {
      if (toggleSequenceThunk.rejected.match(toggleThunksResult)) {
        const errorMessage = getErrorMessage(
          toggleThunksResult.error,
          SequenceEditorErrorMessage.SAVE_ERROR
        )
        Notification.error(errorMessage)
      } else {
        Notification.error(SequenceEditorErrorMessage.SAVE_ERROR)
      }
    }
  }

  return {
    toggleSequence
  }
}
