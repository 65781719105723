import { type SMSCampaign, SMSCampaignStatus } from '@ghostmonitor/recartapis'
import { cloneDeep } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ObjectId } from 'bson'
import { setSMSCampaignLocalStorage } from '../../../routes/flow-editor/utils/local-storage'
import { duplicateFlowThunk } from '../flow/duplicate-flow.thunk'
import { api } from '../../../utils/api'

export const duplicateSMSCampaignThunk = createAsyncThunk(
  'flow/duplicateSMSCampaign',
  async ({ flowId, smsCampaign }: { flowId: string; smsCampaign: SMSCampaign }, store) => {
    const resultAction = await store.dispatch(duplicateFlowThunk(flowId))
    if (duplicateFlowThunk.rejected.match(resultAction)) {
      throw resultAction.error
    }

    const targetRules = cloneDeep(smsCampaign.targetRules ?? {})
    // filter out deleted segments from targetRules
    if (targetRules) {
      const ids = [
        ...(targetRules.includeSegmentIds ?? []),
        ...(targetRules.excludeSegmentIds ?? [])
      ]

      const { data: segments } = await api.getSegments(ids, ['removedAt'])
      if (targetRules && segments?.length) {
        if (targetRules.includeSegmentIds) {
          targetRules.includeSegmentIds = targetRules.includeSegmentIds.filter((id) => {
            const segment = segments.find((segment) => segment.id === id)
            return segment && !segment?.removedAt
          })
        }

        if (targetRules.excludeSegmentIds) {
          targetRules.excludeSegmentIds = targetRules.excludeSegmentIds.filter((id) => {
            const segment = segments.find((segment) => segment.id === id)
            return segment && !segment?.removedAt
          })
        }

        if (!targetRules.includeSegmentIds?.length) {
          delete targetRules.includeSegmentIds
        }

        if (!targetRules.excludeSegmentIds?.length) {
          delete targetRules.excludeSegmentIds
        }
      }
    }

    if (duplicateFlowThunk.fulfilled.match(resultAction)) {
      const newFlowId = resultAction.payload
      const duplicatedSMSCampaign: SMSCampaign = {
        _id: new ObjectId().toHexString(),
        siteId: smsCampaign.siteId,
        sequenceId: newFlowId,
        name: '',
        scheduledFor: null,
        scheduleType: 'siteTimezone',
        isQuietHoursEnabled: true,
        status: SMSCampaignStatus.DRAFT,
        targetRules: Object.keys(targetRules).length > 0 ? targetRules : undefined,

        // On save, it will be updated
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }

      setSMSCampaignLocalStorage(duplicatedSMSCampaign)

      return newFlowId
    }
  }
)
