import { type ConditionalSplitSequenceItemUI } from '@ghostmonitor/recartapis'
import { type Action } from '@reduxjs/toolkit'
import { selectSequenceItem } from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'

export interface RemoveConditionAction extends Action {
  sequenceItemId: string
  index: number
}

export function removeConditionActionHandler(
  state: SequenceEditorState,
  action: RemoveConditionAction
) {
  const sequenceItem = selectSequenceItem<ConditionalSplitSequenceItemUI>(action.sequenceItemId)(
    state
  )
  sequenceItem.logic.conditionalSplit.variants[0].conditions.splice(action.index, 1)
}
