import {
  type CreateSegmentListRequest,
  type CreateSegmentRequest,
  type CreateSegmentResponse,
  type SegmentListResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type ExpressionUI } from '../../types/segment/condition-ui.type'
import { convertExpressionUIToAPI } from '../../types/segment/converters/ui-to-api/segment'
import { api } from '../../utils/api'
import { QUERY_NAME } from './query-names'

type CreateSegmentRequestUI = Omit<CreateSegmentRequest, 'expression'> & {
  expression?: ExpressionUI
}

export function useSegmentCreate() {
  const queryClient = useQueryClient()

  const { mutateAsync: createSegment, isLoading: isCreateSegmentLoading } = useMutation<
    CreateSegmentResponse,
    unknown,
    CreateSegmentRequestUI | CreateSegmentListRequest
  >(
    (body) => {
      let segment: CreateSegmentRequest | CreateSegmentListRequest
      if (body.type === 'list') {
        segment = {
          name: body.name,
          type: body.type
        }
      }

      if (body.type === 'segment') {
        segment = {
          name: body.name,
          type: body.type,
          expression: convertExpressionUIToAPI(body.expression)
        }
      }

      return api.createSegment(segment)
    },
    {
      onSuccess: (createSegmentResponse: CreateSegmentResponse) => {
        const listQueryName = [QUERY_NAME.segmentsList, null]
        const segmentsList: SegmentListResponse = queryClient.getQueryData(listQueryName)
        if (segmentsList) {
          const newSegmentsList = {
            data: [...segmentsList.data, createSegmentResponse.data]
          }

          queryClient.setQueryData(listQueryName, newSegmentsList)
          queryClient.invalidateQueries(listQueryName)
        }
      }
    }
  )

  return { createSegment, isCreateSegmentLoading }
}
