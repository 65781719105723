import { type LinkingMeta, PortType } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { ReactComponent as MenuIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/03-Menu/navigation-menu.svg'
import { QUICK_REPLY_MAX_LENGTH } from '../../config'
import { type QuickReplyPortModel } from '../../models/port/quick-reply-port-model'
import { type BaseSequenceItemNodeModel } from '../../models/sequence-item/base-sequence-item-model'
import { ThrottledInlineEditor } from '../inline-editor/throttled-inline-editor.component'
import { Port } from '../port/port.component'
import { RemovableElement } from '../removable-element/removable-element.component'
import './quick-reply-editor.scss'
import styles from './quick-reply-port-widget.component.scss'

const DragHandle = SortableHandle(() => {
  return (
    <div
      className={styles['sort-handle']}
      onMouseDown={(e) => {
        e.stopPropagation()
      }}
    >
      <MenuIcon />
    </div>
  )
})

interface ContentProps {
  title: string
  onChange: (newValue: string) => void
  isEditable: boolean
}

const Content = (props: ContentProps) => {
  return (
    <ThrottledInlineEditor
      placeholder='Click here to edit'
      multiline={false}
      defaultValue={props.title}
      onChange={props.onChange}
      isEditable={props.isEditable}
      characterLimit={QUICK_REPLY_MAX_LENGTH}
      type='basic'
    />
  )
}

export interface QuicReplyPortWidgetProps {
  title: string
  isEditable?: boolean
  isDragDisabled?: boolean
  isRemoveDisabled?: boolean
  port: QuickReplyPortModel
  sequenceItemNode: BaseSequenceItemNodeModel

  onPortFocus: (quickReplyId: string) => void

  onPortBlur: (quickReplyId: string) => void

  onUpdateQuickReply: (newTitle: string) => void
  onRemoveQuickReply: () => void
  blurred?: boolean
  sorting: boolean
  linking: boolean
  linkingMeta?: LinkingMeta
  error: string
  className?: string
}

export function QuickReplyPortWidget(props: QuicReplyPortWidgetProps) {
  const [focus, setFocus] = useState(false)
  const containerRef = useRef(null)
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const { name: portName } = props.port ? props.port : { name: '' }

  useEffect(() => {
    requestAnimationFrame(() => {
      // JSDOM eg.
      if (props.sorting || !containerRef.current) {
        return
      }
      const newOffset = containerRef.current.getBoundingClientRect()
      if (offset.x !== newOffset.x || offset.y !== newOffset.y) {
        setOffset(newOffset)
      }
    })
  }, [props.sorting])

  function handleTitleChange(newValue: string) {
    if (props.sorting) {
      return false
    }
    props.onUpdateQuickReply(newValue)
  }

  function handleRemove() {
    props.onRemoveQuickReply()
  }

  const links = Object.values(props.port.getLinks())
  const isConnected = links?.[0]?.getTargetPort() !== undefined
  const shouldPulse =
    props.linking &&
    props.linkingMeta.portType === PortType.SEQUENCE_ITEM &&
    props.sequenceItemNode.sequenceItemId !== props.linkingMeta.portParentSequenceItemId &&
    !isConnected

  const componentClasses = cn(styles['quick-reply-port'], {
    [styles.error]: !!props.error,
    [styles.blurred]: props.blurred,
    [props.className]: props.className
  })

  return (
    <RemovableElement
      onRemove={handleRemove}
      visible={!props.isRemoveDisabled}
      dataTestId='remove-quick-reply'
    >
      <div
        ref={containerRef}
        className={componentClasses}
        onMouseOver={() => {
          setFocus(true)
        }}
        onMouseOut={() => {
          setFocus(false)
        }}
      >
        <div className={styles['error-message-wrapper']}>
          <div className={styles['error-message']}>{props.error}</div>
        </div>
        <div className={`${styles.content} quick-reply-editor ${focus ? 'focused' : ''}`}>
          {!props.isDragDisabled && <DragHandle />}
          <div className={styles.editable}>
            <Content
              title={props.title}
              onChange={handleTitleChange}
              isEditable={props.isEditable !== false}
            />
          </div>
          <div className={styles['port-container']}>
            <Port
              name={portName}
              nodeId={props.sequenceItemNode.getID()}
              isLinked={isConnected}
              isPulsing={shouldPulse}
              hintOrientation='right'
              hasHint
            />
          </div>
        </div>
      </div>
    </RemovableElement>
  )
}
