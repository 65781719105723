import { type Variable } from '@ghostmonitor/recartapis'
import {
  cartUrlVariableOption,
  checkoutRecoveryLinkOption,
  fulfillmentUrlVariableOption,
  loopworkCustomerPortalLink,
  loopworkQuickActionAddProduct,
  loopworkQuickActionReactivateSubscription,
  loopworkQuickActionResumeSubscription,
  loopworkQuickActionUpdatePaymentMethod,
  orderStatusUrlVariableOption,
  productUrl,
  rechargeCustomerPortalLink,
  restoreLink,
  siteDomainVariableOption,
  unsubscribeLink,
  urlVariableOption,
  wondermentTrackingUrlOption,
  wondermentWondermentTrackingUrlOption,
  integrationUpdatePaymentMethodURL,
  boldCustomerPortalLink
} from './variable-type-options'

export const linkVariableNames = [
  cartUrlVariableOption.value,
  fulfillmentUrlVariableOption.value,
  loopworkCustomerPortalLink.value,
  loopworkQuickActionReactivateSubscription.value,
  loopworkQuickActionUpdatePaymentMethod.value,
  loopworkQuickActionResumeSubscription.value,
  loopworkQuickActionAddProduct.value,
  rechargeCustomerPortalLink.value,
  orderStatusUrlVariableOption.value,
  productUrl.value,
  restoreLink.value,
  siteDomainVariableOption.value,
  unsubscribeLink.value,
  urlVariableOption.value,
  wondermentTrackingUrlOption.value,
  wondermentWondermentTrackingUrlOption.value,
  checkoutRecoveryLinkOption.value,
  integrationUpdatePaymentMethodURL.value,
  boldCustomerPortalLink.value
]

export const linkWithoutDiscountCodeVariableNames = [
  unsubscribeLink.value,
  loopworkCustomerPortalLink.value,
  loopworkQuickActionReactivateSubscription.value,
  loopworkQuickActionUpdatePaymentMethod.value,
  loopworkQuickActionResumeSubscription.value,
  loopworkQuickActionAddProduct.value,
  rechargeCustomerPortalLink.value,
  wondermentTrackingUrlOption.value,
  wondermentWondermentTrackingUrlOption.value,
  integrationUpdatePaymentMethodURL.value
]

export function isKeywordReplyVariable(variable: Variable): boolean {
  return variable.params.keyword_reply !== undefined
}
export function isStaticDiscountCodeVariable(variable: Variable): boolean {
  return variable.params.discount_code !== undefined
}

export function isUniqueDiscountCodeVariable(variable: Variable): boolean {
  return variable.params.discount_pool_id !== undefined
}

export function isDiscountCodeVariable(variable: Variable): boolean {
  return isStaticDiscountCodeVariable(variable) || isUniqueDiscountCodeVariable(variable)
}

export function isDiscountUrlVariable(variable: Variable): boolean {
  return isDiscountCodeVariable(variable) && linkVariableNames.includes(variable.name)
}

export function isUrlVariable(variable: Variable): boolean {
  return variable.name === 'url' && !isDiscountCodeVariable(variable)
}

export function isLinkVariable(variable: Variable): boolean {
  return (
    (linkVariableNames.includes(variable.name) && !isDiscountCodeVariable(variable)) ||
    isDiscountUrlVariable(variable)
  )
}

export function isSiteDomainVariable(variable: Variable): boolean {
  return variable.name === 'site.domain'
}

export function isGeneralVariable(variable: Variable): boolean {
  return (
    !isLinkVariable(variable) &&
    !isDiscountCodeVariable(variable) &&
    !isKeywordReplyVariable(variable)
  )
}

export function canVariableTypeHaveDiscountCode(variableName: string): boolean {
  return (
    linkVariableNames.includes(variableName) &&
    !linkWithoutDiscountCodeVariableNames.includes(variableName)
  )
}
