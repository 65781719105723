import {
  type FlowEditorError,
  FlowEditorErrorLevel,
  validationErrors
} from '../flow-editor/flow-editor-errors'
import { createScope } from './logger'

export const { error: errorLogger, info } = createScope('flow-editor')

export function isCausedByValidationError(error: any): boolean {
  if (error?.message !== undefined && validationErrors.includes(error.message as FlowEditorError)) {
    return true
  }

  if (error?.cause !== undefined) {
    return isCausedByValidationError(error.cause)
  }

  return false
}

export function getErrorLevel(error: any): FlowEditorErrorLevel | undefined {
  if (error?.isError) {
    return FlowEditorErrorLevel.Error
  }

  if (error?.isWarning) {
    return FlowEditorErrorLevel.Warning
  }

  if (error?.cause !== undefined) {
    return getErrorLevel(error.cause)
  }

  return undefined
}

export function error(err: Error | string, context?: Record<string, unknown>): void {
  if (!isCausedByValidationError(err)) {
    errorLogger(err, context)
  }
}
