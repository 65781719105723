import { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { AppLoader } from '../../components/app-loader/app-loader.component'
import { PageError } from '../../components/ui-kit/page-error/page-error.component'
import { createScope } from '../../utils/logger/logger'
import { getComponentDisplayName } from '../../utils/utils'

function withSuspenseAndErrorBoundaryHOC(LazyComponent, featureTag?: string) {
  function WithSuspenseAndErrorBoundaryHOC(props) {
    const logger = createScope(featureTag ?? 'dashboard')

    function ErrorFallback({ resetErrorBoundary }) {
      return <PageError onReset={resetErrorBoundary} />
    }

    function handleError(error: Error, info: { componentStack: string }) {
      logger.error('CannotRenderComponent', { error, info })
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Suspense fallback={<AppLoader />}>
          <LazyComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    )
  }

  WithSuspenseAndErrorBoundaryHOC.displayName = `WithSuspenseAndErrorBoundaryHOC(${getComponentDisplayName(
    LazyComponent
  )})`

  return WithSuspenseAndErrorBoundaryHOC
}

export const LazyAuthRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "auth-layout" */ '../../routes/auth/auth.route').then((module) => ({
      default: module.Auth
    }))
  ),
  'auth'
)

export const LazyResetPasswordRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "resetpassword" */ '../../routes/reset-password/reset-password.component'
    ).then((module) => ({ default: module.ResetPassword }))
  ),
  'auth'
)

export const LazyErrorRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "error" */ '../../routes/error-page/error-page.component').then(
      (module) => ({ default: module.ErrorPage })
    )
  )
)

export const LazyAppLayoutRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "app-layout" */ '../../layouts/app-layout/app-layout.component'
    ).then((module) => ({ default: module.AppLayout }))
  )
)

export const LazyOnboardingRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "onboarding" */ '../../routes/onboarding/onboarding.route').then(
      (module) => ({ default: module.Onboarding })
    )
  ),
  'onboarding'
)

export const LazySettingsLayoutRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "settings" */ '../../routes/settings/settings.route').then(
      (module) => ({ default: module.Settings })
    )
  ),
  'settings'
)

export const LazyBillingRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "billing" */ '../../routes/billing/billing.route').then(
      (module) => ({ default: module.Billing })
    )
  ),
  'billing'
)

export const LazyReportsRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "reports" */ '../../routes/reports/reports.route').then(
      (module) => ({ default: module.Reports })
    )
  ),
  'reports'
)

export const LazyDiscountsRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "discounts" */ '../../routes/discounts/discounts.route').then(
      (module) => ({ default: module.Discounts })
    )
  ),
  'discounts'
)

export const LazyExtendYourTrialRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "extend-your-trial" */ '../../routes/extend-your-trial/extend-your-trial.route'
    ).then((module) => ({ default: module.ExtendYourTrial }))
  ),
  'achievements'
)

export const LazyOptinToolsRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "optin-tools" */ '../../routes/optin-tools/optin-tools.route').then(
      (module) => ({ default: module.OptinTools })
    )
  ),
  'optin-tools'
)

export const LazyOptinToolEditor = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "optin-tools" */ '../../routes/optin-tools/optin-tool-editor.component'
    ).then((module) => ({ default: module.OptinToolEditor }))
  ),
  'optin-tool-editor'
)

export const LazySequenceEditorRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "sequence-editor" */ '../../routes/SequenceEditor/sequence-editor.route'
    ).then((module) => ({ default: module.SequenceEditorRoute }))
  ),
  'sequence-editor'
)

export const LazyFlowEditorRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "flow-editor" */ '../../routes/flow-editor/flow-editor.route').then(
      (module) => ({ default: module.FlowEditorRoute })
    )
  ),
  'flow-editor'
)

export const LazyMessengerConnectRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "messenger-connect" */ '../../routes/facebook-account-connect/facebook-account-connect.component'
    ).then((module) => ({ default: module.FacebookAccountConnect }))
  ),
  'facebook-account-connection'
)

export const LazyTemplatesRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "templates" */ '../../routes/templates/templates.route').then(
      (module) => ({ default: module.Templates })
    )
  ),
  'templates'
)
export const LazySubscribersRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "subscribers" */ '../../routes/subscribers/subscribers.route').then(
      (module) => ({ default: module.Subscribers })
    )
  ),
  'subscribers'
)

export const LazySegmentsRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "segments" */ '../../routes/segments/segments.route').then(
      (module) => ({ default: module.Segments })
    )
  ),
  'segments'
)

export const LazyIntegrationsRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(
      /* webpackChunkName: "Integrations" */ '../../routes/integrations/integrations.route'
    ).then((module) => ({ default: module.Integrations }))
  ),
  'integrations'
)

export const LazyInboxRoute = withSuspenseAndErrorBoundaryHOC(
  lazy(() =>
    import(/* webpackChunkName: "Inbox" */ '../../routes/inbox/inbox.route').then((module) => ({
      default: module.Inbox
    }))
  ),
  'inbox'
)
