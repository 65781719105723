import { type Blast } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../../../utils/api'
import type { AppDispatch } from '../../../create-store'
import { type DashboardState } from '../../../dashboard.state'
import { selectEditorBlast } from '../../../selectors'

export const patchBlastThunk = createAsyncThunk<
  Blast,
  Partial<Blast>,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/patchBlast', async (patchPayload, store) => {
  const state = store.getState()
  const blast = selectEditorBlast(state)

  const response = await api.patchBlast(blast._id, patchPayload)

  return response.data
})
