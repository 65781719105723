import {
  type DiscountCodePool,
  type FlowDiagram,
  type FlowItemUI,
  type FlowUI,
  type MessengerMessageUI,
  type SMSCampaign,
  type SMSMessageFlowItemUI,
  type SMSMessageTextUI
} from '@ghostmonitor/recartapis'
import memoize from 'lodash/memoize'
import { createSelector } from 'reselect'
import { hasMessageOnFlowItemType } from '../../../types/guards/flow-item-ui.guards'
import { isTriggerNode } from '../../../utils/flow-editor/is-trigger-node'
import {
  type FlowEditorState,
  type FlowMeta,
  type MessageMeta,
  type SMSCampaignMeta
} from './flow-editor.state'

export const selectEditorFlow = (state: FlowEditorState): FlowUI | undefined => state.flow
export const selectEditorFlowItems = (state: FlowEditorState): FlowItemUI[] | undefined =>
  state.flow?.sequenceItems

export const selectEditorFlowMeta = (state: FlowEditorState): FlowMeta => state.flowMeta

export const selectEditorSMSCampaign = (state: FlowEditorState): SMSCampaign | undefined =>
  state.smsCampaign
export const selectEditorSMSCampaignMeta = (state: FlowEditorState): SMSCampaignMeta | undefined =>
  state.smsCampaignMeta

export const selectNodes = (state: FlowEditorState) => {
  return state.flow?.flowEditorDiagram.nodes
}
export const selectTriggerNode = (state: FlowEditorState) => {
  return state.flow?.flowEditorDiagram.nodes.find(isTriggerNode)
}

export const selectNodeById = memoize(
  (nodeId: string | null, allowUndefined = false) =>
    (state: FlowEditorState) => {
      if (!nodeId) {
        return
      }
      const node = state.flow?.flowEditorDiagram.nodes.find((node) => node.id === nodeId)
      if (!node && !allowUndefined) {
        throw new Error(`Node with id ${nodeId} not found`)
      }
      return node
    },
  (...args) => JSON.stringify(args)
)

export const selectNodeByFlowItemId = memoize(
  (flowItemId: string | null) => (state: FlowEditorState) => {
    if (!flowItemId) {
      return
    }
    return state.flow?.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItemId)
  }
)

export const selectEdges = (state: FlowEditorState) => {
  return state.flow?.flowEditorDiagram.edges
}

export const selectEdgeById = memoize(
  (edgeId: string) =>
    (state: FlowEditorState): FlowDiagram.Edge | undefined => {
      return state.flow?.flowEditorDiagram.edges.find((edge) => edge.id === edgeId)
    }
)

export const selectFlowItems = (state: FlowEditorState) => {
  return state.flow?.sequenceItems
}

export const selectFlowItemsById = (state: FlowEditorState) => {
  return state.flow?.sequenceItems.reduce((acc, flowItem) => {
    acc[flowItem._id] = flowItem
    return acc
  }, {})
}

export const selectVariablePlaceholder = (state: FlowEditorState) => {
  return state.variablePlaceholders
}

export const selectFlowItemsMetaById = (state: FlowEditorState) => {
  return state.flowItemsMetaById
}

export const selectErrorCount = (state: FlowEditorState) => {
  return state.flowMeta.errorCount
}

export const selectErrors = (state: FlowEditorState) => {
  return state.flowMeta.errors
}

export const selectVariableTypeOptions = createSelector(
  selectEditorFlowMeta,
  (flowMeta) => flowMeta.settings.variableTypeOptions ?? []
)

export const selectEntryVariableTypeOptions = createSelector(
  selectEditorFlowMeta,
  (flowMeta) => flowMeta.settings.entryVariableTypeOptions ?? []
)

export const selectLinkTypeOptions = createSelector(
  selectEditorFlowMeta,
  (flowMeta) => flowMeta.settings.linkTypeOptions ?? []
)

export const selectEntryLinkTypeOptions = createSelector(
  selectEditorFlowMeta,
  (flowMeta) => flowMeta.settings.entryLinkTypeOptions ?? []
)

export const selectInvalidDiscountCodes = createSelector(
  selectEditorFlowMeta,
  (flowMeta) => flowMeta.invalidDiscountCodes
)

export const selectExpiredDiscountCodes = createSelector(
  selectEditorFlowMeta,
  (flowMeta) => flowMeta.expiredDiscountCodes
)

export const selectIsReadOnly = (state: FlowEditorState): boolean => state.isReadOnly
export const selectDiscountCodePools = (state: FlowEditorState): DiscountCodePool[] =>
  state.discountCodePools

export const selectIsEverSaved = (state: FlowEditorState): boolean =>
  state.flowMeta.isEverSaved === true

export const selectIsScheduledDelayEnabled = (state: FlowEditorState): boolean =>
  state.flowMeta.settings?.isScheduledDelayEnabled === true

export const selectFlowItem = memoize(
  <TFlowItem extends FlowItemUI = SMSMessageFlowItemUI>(flowItemId: string) =>
    (state: FlowEditorState) => {
      return state.flow?.sequenceItems.find(
        (sequenceItem) => sequenceItem._id === flowItemId
      ) as TFlowItem
    }
)

export const selectFlowItemMeta = memoize((flowItemId: string) => (state: FlowEditorState) => {
  return selectFlowItemsMetaById(state)[flowItemId]
})

export const selectFlowItemErrors = memoize((flowItemId: string) => (state: FlowEditorState) => {
  return selectFlowItemMeta(flowItemId)(state).errors
})

export const selectFlowItemIsInQuietHours = memoize(
  (flowItemId: string) => (state: FlowEditorState) => {
    return selectFlowItemMeta(flowItemId)(state).isInQuietHours
  }
)

export const selectMessage = memoize(
  <TMessage extends MessengerMessageUI | SMSMessageTextUI = SMSMessageTextUI>(
    flowItemId: string,
    messageIndex: number
  ) =>
    (state: FlowEditorState): TMessage => {
      const flowItem = selectFlowItem(flowItemId)(state)
      if (hasMessageOnFlowItemType(flowItem)) {
        return flowItem.item.messages[messageIndex] as TMessage
      }

      throw new Error('Flow item does not have messages')
    },
  (...args) => JSON.stringify(args)
)

export const selectMessageMeta = memoize(
  (flowItemId: string, messageIndex: number) =>
    (state: FlowEditorState): MessageMeta => {
      const flowItem = selectFlowItem(flowItemId)(state)
      const flowItemMeta = selectFlowItemMeta(flowItemId)(state)

      if (hasMessageOnFlowItemType(flowItem)) {
        return flowItemMeta.messageMeta[messageIndex]
      }

      throw new Error('Flow item does not have messages')
    },
  (...args) => JSON.stringify(args)
)

export const selectSettings = (state: FlowEditorState) => {
  return state.flowMeta.settings
}

export const selectSite = (state: FlowEditorState) => {
  return state.site
}

export const selectSiteId = (state: FlowEditorState) => {
  return state.site?.id
}

export const selectQuietHoursConfig = (state: FlowEditorState) => {
  return state.quietHoursConfig
}
