import { Link } from 'react-router-dom-v5-compat'
import { ReactComponent as SomethingWentWrongIcon } from '../../../static/ui-kit/illustrations/normal/something-went-wrong.svg'
import { Button } from '../button/button.component'
import styles from './page-error.component.scss'

interface PageErrorProps {
  onReset?: VoidFunction
}

export function PageError(props: PageErrorProps) {
  function handleReload() {
    location.reload()
  }

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <SomethingWentWrongIcon className={styles.errorIcon} data-testid='routing-error-image' />
      <div className='text-center mt-3 mb-4'>
        <h2 className='text-h2 text-primary font-semibold mb-2 p-0'>Page can't be loaded</h2>
        <p className='text-secondary'>
          It looks like there was an error on our side, please try loading the page again.
        </p>
      </div>
      <div className='flex flex-col items-center space-y-2'>
        <Button type='primary' onClick={handleReload} data-testid='reload-button'>
          Try again
        </Button>
        <Link to='/'>
          <Button type='link' onClick={props.onReset} data-testid='home-button'>
            Go to Home
          </Button>
        </Link>
      </div>
    </div>
  )
}
