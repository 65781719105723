import Upload, { type UploadProps } from 'antd/lib/upload'
import { useState } from 'react'
import { config } from '../../../../config'
import { hooks } from '../../../../hooks/hooks'
import { ImageUploadButton } from './image-upload-button.component'
import styles from './image-upload.component.scss'

export interface ImageFormat {
  mimeType: string
  allowedSize: number
  allowedManualSize: number
  isResizeDisabled?: boolean
}

interface ImageUploadByDragProps extends UploadProps {
  apiRelativePath: string
  imageUrl?: string
  allowedImageFormats: ImageFormat[]
  onSuccess: (imageUrl: string) => void
  onError: (errorMessage: string) => void // server errorok
  onFormatError: () => void
  onSizeError: (type: string) => void
  onUploadStarted?: () => void
}

export function ImageUploadByDrag(props: ImageUploadByDragProps) {
  const { Dragger } = Upload
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(props.imageUrl ?? null)
  const [imageHovered, setImageHovered] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const authToken = localStorage.getItem('x-auth-token')
  const isMMSImageResizingEnabled = hooks.useIsMMSImageResizingEnabled()

  function handleOnMouseEnter(_event: React.MouseEvent) {
    setImageHovered(true)
  }

  function handleOnMouseLeave(_event: React.MouseEvent) {
    setImageHovered(false)
  }

  const defaultSettings: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: `${config.UPLOAD_API_URL}${props.apiRelativePath}`,
    headers: { 'X-Auth-Token': authToken! },
    showUploadList: props.showUploadList ?? false,
    beforeUpload: (file) => {
      props.onUploadStarted?.()

      const imageFormat = props.allowedImageFormats.find((format) => format.mimeType === file.type)

      if (!imageFormat) {
        props.onFormatError()
        return Upload.LIST_IGNORE
      }

      if (
        (!isMMSImageResizingEnabled || imageFormat.isResizeDisabled) &&
        file.size > imageFormat.allowedManualSize
      ) {
        props.onSizeError(file.type)
        return Upload.LIST_IGNORE
      }

      if (isMMSImageResizingEnabled && file.size > imageFormat.allowedSize) {
        props.onSizeError(file.type)
        return Upload.LIST_IGNORE
      }

      return true
    },

    onChange(info) {
      const { status, response, percent } = info.file
      setLoading(status === 'uploading')
      setUploadPercent(percent ?? 0)

      if (status === 'done') {
        setImageUrl(response.url)
        props.onSuccess(response.url)
      }

      if (status === 'error') {
        props.onError(response)
      }
    }
  }

  return (
    <div className={styles.container}>
      <Dragger {...defaultSettings}>
        {imageUrl ? (
          <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            <div>
              <img
                className='w-full h-full object-contain'
                src={imageUrl}
                alt='Uploaded image thumbnail'
              />
            </div>
            <div className={imageHovered || loading ? styles.hoveredBg : styles.transparentBg} />
            {(imageHovered || loading) && (
              <ImageUploadButton loading={loading} percentage={uploadPercent} isHovered />
            )}
          </div>
        ) : (
          <ImageUploadButton loading={loading} percentage={uploadPercent} />
        )}
      </Dragger>
    </div>
  )
}
