import { MessageType, type MessengerGenericTemplatePayload } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import {
  ButtonInitialMeta,
  type ButtonMeta,
  messageInitialMeta,
  type MessageMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { getErrorMessage } from '../../../types/sequence-editor-errors'
import { isMessengerGenericGalleryTemplatePayload } from '../../../utils/assert-message-template-type'
import { isAnyButtonSelected } from '../../../utils/is-any-button-selected'
import { FileEditor } from '../../file-editor/file-editor.component'
import { GalleryCarousel } from '../../gallery-carousel/gallery-carousel.component'
import { ThrottledInlineEditor } from '../../inline-editor/throttled-inline-editor.component'
import {
  type InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import {
  type ButtonProps,
  MessengerButton
} from '../../messenger-button/messenger-button.component'
import { type MessengerTemplateProps } from '../messenger-template-props.type'
import styles from './messenger-generic-template.component.scss'

interface GalleryProps {
  messageItemIndex?: number
  isGallery: boolean
  disableControls?: boolean
}

const InteractiveContent = makeInteractive<
  MessengerTemplateProps<MessengerGenericTemplatePayload> & InteractiveComponentProps & GalleryProps
>((props) => {
  return (
    <>
      <FileEditor
        contentEditable={!props.disableControls}
        attachmentType='image'
        input={{ name: '', value: props.messengerTemplatePayload.image_url }}
        change={(value: string) => {
          props.onMessageChange({ image_url: value }, props.messageItemIndex)
        }}
        messageItemIndex={props.messageItemIndex}
        isGallery={props.isGallery}
        siteId={props.siteId}
      />
      <div
        className={cn(styles.title, {
          [styles.blurred]: props.isBlurred,
          [styles['has-error']]: props.hasError
        })}
      >
        <ThrottledInlineEditor
          placeholder=''
          isEditable
          multiline={false}
          defaultValue={props.messengerTemplatePayload.title}
          onFocus={props.onMessageFocus}
          onBlur={props.onMessageBlur}
          onChange={(value: string) => {
            props.onMessageChange({ title: value }, props.messageItemIndex)
          }}
          characterLimit={80}
          type={props.hasVariable ? 'messenger' : 'basic'}
        />
      </div>
      <div
        className={cn(styles.subtitle, {
          [styles.blurred]: props.isBlurred,
          [styles['has-error']]: props.hasError
        })}
      >
        <ThrottledInlineEditor
          placeholder=''
          isEditable
          multiline
          defaultValue={props.messengerTemplatePayload.subtitle}
          onFocus={props.onMessageFocus}
          onBlur={props.onMessageBlur}
          onChange={(value: string) => {
            props.onMessageChange({ subtitle: value }, props.messageItemIndex)
          }}
          characterLimit={80}
          type={props.hasVariable ? 'messenger' : 'basic'}
        />
      </div>
    </>
  )
})
const InteractiveButton = makeInteractive<ButtonProps>(MessengerButton)

function MessengerGenericTemplateItem(
  props: MessengerTemplateProps<MessengerGenericTemplatePayload> & GalleryProps
) {
  function handleAddButton(buttonIndex: number, messageItemIndex: number) {
    if (props.messengerTemplatePayload.buttons.length >= 3) {
      return false
    }
    props.onAddButton(buttonIndex, messageItemIndex)
    props.clearSelection()
  }

  const isDragAndDropEnabled = useSequenceEditorSettings().dragAndDrop

  return (
    <div className={styles.container}>
      <InteractiveContent
        // MessengerTemplateProps
        {...props}
        // InteractiveComponentProps
        errorMessage={getErrorMessage(props.messageMeta.errors[0])}
        isFirst
        isLast={!props.messengerTemplatePayload.buttons.length}
        isSelected={props.messageMeta.selected && !isAnyButtonSelected(props.messageMeta)}
        isBlurred={
          (props.sequenceItemMeta.selected && !props.messageMeta.selected) ||
          (props.messageMeta.selected && isAnyButtonSelected(props.messageMeta))
        }
        hasAddButton={props.messengerTemplatePayload.buttons.length < 3}
        isDraggable={isDragAndDropEnabled}
        isGallery={props.isGallery}
        disableControls={props.disableControls}
        onRemoveElement={props.onRemove}
        onAddButtonClick={() => handleAddButton(0, props.messageItemIndex)}
        onMessageFocus={() => {
          props.onMessageFocus(props.messageItemIndex)
        }}
      />
      {props.messengerTemplatePayload.buttons.length > 0 && (
        <div className={styles.buttons}>
          {props.messengerTemplatePayload.buttons.map((buttonPayload, buttonIndex) => {
            const buttonMeta: ButtonMeta = props.messageMeta.buttons[buttonIndex]
              ? props.messageMeta.buttons[buttonIndex]
              : cloneDeep(ButtonInitialMeta)
            return (
              <InteractiveButton
                key={buttonIndex}
                // ButtonProps
                payload={buttonPayload}
                buttonTypeOptions={props.buttonTypeOptions}
                sequenceItemId={props.sequenceItemNodeModel.sequenceItemId}
                sequenceItemNodeId={props.sequenceItemNodeModel.getID()}
                port={props.sequenceItemNodeModel.getButtonPort(
                  props.sequenceItemNodeModel.sequenceItemId,
                  props.messageIndex,
                  buttonIndex,
                  props.messageItemIndex
                )}
                linking={props.linking}
                linkingMeta={props.linkingMeta}
                disableTypeChange={props.disableButtonTypeChange}
                onChange={(buttonChange) => {
                  props.onButtonChange(buttonIndex, buttonChange, props.messageItemIndex)
                }}
                onFocus={() => {
                  props.onButtonFocus(buttonIndex, props.messageItemIndex)
                }}
                onBlur={props.onButtonBlur}
                clearSelection={props.clearSelection}
                // InteractiveComponentProps
                errorMessage={buttonMeta.error}
                isLast={buttonIndex === props.messengerTemplatePayload.buttons.length - 1}
                isSelected={buttonMeta.selected}
                isBlurred={!buttonMeta.selected && props.sequenceItemMeta.selected}
                onRemoveElement={() => {
                  props.onRemoveButton(buttonIndex, props.messageItemIndex)
                }}
                onAddButtonClick={() => handleAddButton(buttonIndex + 1, props.messageItemIndex)}
                hasAddButton={props.messengerTemplatePayload.buttons.length < 3}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export function MessengerGenericTemplate(
  props: MessengerTemplateProps<MessengerGenericTemplatePayload | MessengerGenericTemplatePayload[]>
) {
  const { messengerTemplatePayload, ...messengerGenericTemplateProps } = props

  // Gallery
  if (isMessengerGenericGalleryTemplatePayload(messengerTemplatePayload)) {
    const disableControls = props.messageType === MessageType.ABANDONED_CART_CAROUSEL
    const genericTemplateMessageItems = messengerTemplatePayload.map(
      (messengerTemplatePayloadItem, messageItemIndex) => {
        const messageMeta: MessageMeta = props.messageMeta.messageItems[messageItemIndex]
          ? { ...messageInitialMeta, ...props.messageMeta.messageItems[messageItemIndex] }
          : { ...messageInitialMeta }

        return (
          <div key={`generic-message-item-${messageItemIndex}`}>
            <MessengerGenericTemplateItem
              messengerTemplatePayload={messengerTemplatePayloadItem}
              {...messengerGenericTemplateProps}
              messageMeta={messageMeta}
              messageItemIndex={messageItemIndex}
              isGallery
              disableControls={disableControls}
            />
          </div>
        )
      }
    )
    return (
      <div className={styles['gallery-container']}>
        <GalleryCarousel
          sequenceItemId={props.sequenceItemId}
          items={genericTemplateMessageItems}
          onAddCard={(jumpToIndex) => {
            props.onAddMessageItem(genericTemplateMessageItems.length, jumpToIndex)
          }}
          onRemoveCard={props.onRemoveMessageItem}
          disableControls={disableControls}
          messageIndex={props.messageIndex}
        />
      </div>
    )
  }

  // Single item
  return (
    <MessengerGenericTemplateItem
      messengerTemplatePayload={messengerTemplatePayload}
      {...messengerGenericTemplateProps}
      messageMeta={props.messageMeta ? props.messageMeta : cloneDeep(messageInitialMeta)}
      isGallery={false}
    />
  )
}
