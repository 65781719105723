interface LabelProps {
  title: string
  children: React.ReactNode
}

export function Label(props: LabelProps) {
  return (
    <>
      <label className='text-sm font-semibold mt-6 mb-3'>{props.title}</label>
      {props.children}
    </>
  )
}
