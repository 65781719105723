// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fy9JSgji4Z8oX9TCjK_J h2{font-family:Lato,sans-serif}.lXLf2gs2b0xBgZsxReJm{color:#595959;font-family:Lato,sans-serif;line-height:14px!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/popover-edit-discount-code-unique/components/step-create/create-discount-code.scss"],"names":[],"mappings":"AAII,yBACE,2BAAA,CAIJ,sBAEE,aAAA,CADA,2BAAA,CAEA,0BAAA","sourcesContent":["@use '../../../../sequence-editor' as *;\n\n:local {\n  .container {\n    h2 {\n      font-family: Lato, sans-serif;\n    }\n  }\n\n  .text {\n    font-family: Lato, sans-serif;\n    color: #595959;\n    line-height: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Fy9JSgji4Z8oX9TCjK_J`,
	"text": `lXLf2gs2b0xBgZsxReJm`
};
export default ___CSS_LOADER_EXPORT___;
