import { type MeState } from './me.types'

export const SET_USER = 'me/SET_USER'

export const setUser = (data: Partial<MeState>) => {
  return {
    type: SET_USER,
    data
  }
}
