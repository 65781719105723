import {
  getCustomIntegrationCategoryTag,
  SequenceTag,
  SiteEngineSlug
} from '@ghostmonitor/recartapis'
import { cloneDeep, upperFirst } from 'lodash'
import { useFeatureFlag } from '../../hooks/use-feature-flag'
import { ReactComponent as AbandonmentFlowsIcon } from '../../static/ui-kit/illustrations/small/abandoned-cart-flows.svg'
import { ReactComponent as CustomConversationFlowsIcon } from '../../static/ui-kit/illustrations/small/custom-conversation-flows.svg'
import { ReactComponent as CustomIntegrationIcon } from '../../static/ui-kit/illustrations/small/custom-integration.svg'
import { ReactComponent as CustomTriggersIcon } from '../../static/ui-kit/illustrations/small/custom-triggers.svg'
import { ReactComponent as FacebookCommentFlowsIcon } from '../../static/ui-kit/illustrations/small/facebook-comments-flows.svg'
import { ReactComponent as FulfillmentFlowsIcon } from '../../static/ui-kit/illustrations/small/fulfillment-flows.svg'
import { ReactComponent as HelpFlowsIcon } from '../../static/ui-kit/illustrations/small/help-flows.svg'
import { ReactComponent as IntegrationIcon } from '../../static/ui-kit/illustrations/small/integration.svg'
import { ReactComponent as LoopWorkIcon } from '../../static/ui-kit/illustrations/small/loopwork.svg'
import { ReactComponent as ReceiptFlowsIcon } from '../../static/ui-kit/illustrations/small/receipt-flows.svg'
import { ReactComponent as RechargeIcon } from '../../static/ui-kit/illustrations/small/recharge.svg'
import { ReactComponent as SMSCampaignsIcon } from '../../static/ui-kit/illustrations/small/sms-campaigns.svg'
import { ReactComponent as TypeformIcon } from '../../static/ui-kit/illustrations/small/typeform.svg'
import { ReactComponent as UnsubscribeIcon } from '../../static/ui-kit/illustrations/small/unsubscribe-flows.svg'
import { ReactComponent as WelcomeFlowsIcon } from '../../static/ui-kit/illustrations/small/welcome-flows.svg'
import { ReactComponent as WheelioIcon } from '../../static/ui-kit/illustrations/small/wheelio.svg'
import { ReactComponent as WondermentIcon } from '../../static/ui-kit/illustrations/small/wonderment.svg'
import { ReactComponent as YotpoIcon } from '../../static/ui-kit/illustrations/small/yotpo.svg'
import { ReactComponent as BoldIcon } from '../../static/ui-kit/illustrations/small/bold.svg'
import { ReactComponent as LoyaltyLionIcon } from '../../static/ui-kit/illustrations/small/loyalty-lion.svg'
import type { SequenceGroup } from '../../types/sequence-group'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

const sequenceGroups: SequenceGroup[] = [
  {
    name: 'Welcome',
    groupPageTitle: 'Messenger Welcome',
    description: 'A warm welcome for your new Messenger subscribers.',
    slug: 'welcome',
    tags: [SequenceTag.WELCOME, SequenceTag.FBMESSAGE, SequenceTag.AUTOMATED],
    icon: WelcomeFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Welcome',
    groupPageTitle: 'SMS Welcome',
    description: 'A warm welcome for your new SMS subscribers.',
    slug: 'sms-welcome',
    statisticsSlug: 'sms-default-welcome',
    tags: [SequenceTag.DEFAULT_WELCOME, SequenceTag.SMS, SequenceTag.AUTOMATED],
    icon: WelcomeFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Custom Triggers',
    groupPageTitle: 'SMS Custom Triggers',
    description: 'Create personalized flows based on subscriber actions & events.',
    slug: 'sms-custom-triggers',
    statisticsSlug: 'sms-joined-a-segment',
    tags: [SequenceTag.JOINED_A_SEGMENT, SequenceTag.SMS, SequenceTag.AUTOMATED],
    icon: CustomTriggersIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Abandonment',
    groupPageTitle: 'SMS Abandonment',
    statisticsSlug: 'sms-abandonment',
    description: 'Follow up subscribers based on abandoned products, carts or checkouts.',
    slug: 'sms-abandonments',
    tags: [SequenceTag.ABANDONMENT, SequenceTag.SMS, SequenceTag.AUTOMATED],
    icon: AbandonmentFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Abandoned Cart',
    groupPageTitle: 'Messenger Abandoned Cart',
    description: 'One or more friendly Messenger reminders for unfinished orders.',
    slug: 'cart-abandonment',
    tags: [
      SequenceTag.ABANDONMENT,
      SequenceTag.CART_ABANDONMENT,
      SequenceTag.FBMESSAGE,
      SequenceTag.AUTOMATED
    ],
    icon: AbandonmentFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Receipt',
    groupPageTitle: 'Messenger Receipt',
    description:
      'Send receipts in Messenger to engage your users to make customers purchase again.',
    slug: 'receipt',
    tags: [SequenceTag.RECEIPT, SequenceTag.FBMESSAGE, SequenceTag.AUTOMATED],
    icon: ReceiptFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
  },
  {
    name: 'Order & Receipt',
    groupPageTitle: 'SMS Order & Receipt',
    description:
      'Send order info, receipts and optional discount code to encourage repeat purchases.',
    slug: 'sms-receipt',
    tags: [SequenceTag.RECEIPT, SequenceTag.SMS, SequenceTag.AUTOMATED],
    icon: ReceiptFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
  },
  {
    name: 'Fulfillment Notification',
    groupPageTitle: 'Messenger Fulfillment Notification',
    description: 'Keep customers updated about their shipping status via Messenger.',
    slug: 'fulfillment',
    tags: [SequenceTag.FULFILLMENT, SequenceTag.FBMESSAGE, SequenceTag.AUTOMATED],
    icon: FulfillmentFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
  },
  {
    name: 'Fulfillment Notification',
    groupPageTitle: 'SMS Fulfillment Notification',
    description: 'Keep customers updated about their shipping status in SMS.',
    slug: 'sms-fulfillment',
    tags: [SequenceTag.FULFILLMENT, SequenceTag.SMS, SequenceTag.AUTOMATED],
    icon: FulfillmentFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
  },
  {
    name: 'Facebook Comments',
    description: 'Send automated messages to people who comment on a selected Facebook post.',
    slug: 'facebook-comments',
    tags: [SequenceTag.FACEBOOK_COMMENTS, SequenceTag.FBMESSAGE, SequenceTag.AUTOMATED],
    icon: FacebookCommentFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Unsubscribe',
    groupPageTitle: 'Messenger Unsubscribe',
    description:
      'Notify your customers when they have successfully unsubscribed from your Messenger messages.',
    slug: 'unsubscribe',
    tags: [SequenceTag.UNSUBSCRIBE, SequenceTag.FBMESSAGE, SequenceTag.AUTOMATED],
    icon: UnsubscribeIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Custom Conversation',
    groupPageTitle: 'Messenger Custom Conversation',
    description:
      'Create your own automated conversations that people can start by clicking an m.me link.',
    slug: 'custom-conversation-flow',
    tags: [SequenceTag.CUSTOM_CONVERSATION_FLOW, SequenceTag.FBMESSAGE, SequenceTag.AUTOMATED],
    icon: CustomConversationFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Integrations',
    groupPageTitle: 'SMS Integrations',
    description: 'Send automated messages based on integration events.',
    slug: 'sms-integrations',
    statisticsSlug: 'sms-integration',
    tags: [SequenceTag.INTEGRATION, SequenceTag.SMS, SequenceTag.AUTOMATED],
    icon: IntegrationIcon,
    // this is a temp workaround for divbrands bc they are marked as woocommerce site in the db
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    subgroups: [
      {
        name: 'Loop Subscriptions',
        groupPageTitle: 'Loop Subscriptions',
        description:
          'Automate the process of managing recurring payments and subscriptions with Loop.',
        slug: 'sms-loopwork',
        tags: [
          SequenceTag.LOOPWORK,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: LoopWorkIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Recharge',
        groupPageTitle: 'Recharge',
        description: 'Send subscription updates based on Recharge.',
        slug: 'sms-recharge',
        tags: [
          SequenceTag.RECHARGE,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: RechargeIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Typeform',
        groupPageTitle: 'Typeform',
        description: 'Welcome new SMS subscribers who opted-in through Typeform.',
        slug: 'sms-typeform',
        tags: [
          SequenceTag.TYPEFORM,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: TypeformIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Wonderment',
        groupPageTitle: 'Wonderment',
        description: 'Send live package tracking updates based on Wonderment.',
        slug: 'sms-wonderment',
        tags: [
          SequenceTag.WONDERMENT,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: WondermentIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Wheelio',
        groupPageTitle: 'Wheelio',
        description: 'Welcome new SMS subscribers who opted-in through Wheelio.',
        slug: 'sms-wheelio-optin',
        tags: [
          SequenceTag.WHEELIO_OPTIN,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: WheelioIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Yotpo: Loyalty & Rewards',
        groupPageTitle: 'Yotpo',
        description:
          'Inform your subscribers about their loyalty points, rewards and send updates based on their actions and purchases.',
        slug: 'sms-yotpo',
        tags: [SequenceTag.YOTPO, SequenceTag.SMS, SequenceTag.AUTOMATED, SequenceTag.INTEGRATION],
        icon: YotpoIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Yotpo: Product Reviews',
        groupPageTitle: 'Yotpo Reviews',
        description: 'Gather product reviews after purchase and send them to Yotpo for display.',
        slug: 'sms-yotpo-review',
        tags: [
          SequenceTag.YOTPO_REVIEW,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: YotpoIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'Loyalty Lion',
        groupPageTitle: 'Loyalty Lion',
        description:
          'Inform your subscribers about their loyalty points, rewards and send updates based on their actions and purchases.',
        slug: 'sms-loyalty-lion',
        tags: [
          SequenceTag.LOYALTY_LION,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: LoyaltyLionIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      },
      {
        name: 'BOLD',
        groupPageTitle: 'BOLD',
        description:
          'Automate the process of managing recurring payments and subscriptions with BOLD.',
        slug: 'sms-bold-subscriptions',
        tags: [
          SequenceTag.BOLD_SUBSCRIPTIONS,
          SequenceTag.SMS,
          SequenceTag.AUTOMATED,
          SequenceTag.INTEGRATION
        ],
        icon: BoldIcon,
        supportedSiteEngines: [SiteEngineSlug.SHOPIFY]
      }
    ]
  },
  {
    name: 'SMS Campaigns',
    groupPageTitle: 'SMS Campaigns',
    description:
      'Send campaigns to your SMS subscribers to keep them engaged and provide special deals and promotions.',
    slug: 'sms-campaigns',
    statisticsSlug: 'sms-blast',
    tags: [SequenceTag.SMS, SequenceTag.CAMPAIGN],
    icon: SMSCampaignsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  },
  {
    name: 'Subscriber Support',
    groupPageTitle: 'SMS Subscriber Support',
    description: 'Help, resubscribe and unsubscribe flows.',
    slug: 'sms-subscriber-support',
    tags: [SequenceTag.SMS, SequenceTag.SUBSCRIBER_SUPPORT, SequenceTag.AUTOMATED],
    icon: HelpFlowsIcon,
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
  }
]

export function useSequenceGroups(): UseResource<SequenceGroup[]> {
  const { getCategories, isLoading, isError } = hooks.useCustomIntegrations()

  const isJoinedASegmentEnabled = useFeatureFlag('joined-a-segment')
  const isWondermentIntegrationEnabled = useFeatureFlag('wonderment-integration')
  const isLoopWorkIntegrationEnabled = useFeatureFlag('loopwork-integration')
  const isTypeformIntegrationEnabled = useFeatureFlag('typeform-integration')
  const isWheelioIntegrationEnabled = useFeatureFlag('wheelio-integration')
  const isRechargeIntegrationEnabled = useFeatureFlag('enable-recharge-integration')
  const isYotpoIntegrationEnabled = useFeatureFlag('enable-yotpo-integration')
  const isYotpoReviewIntegrationEnabled = useFeatureFlag('enable-yotpo-review-integration')
  const isLoyaltyLionIntegrationEnabled = useFeatureFlag('enable-loyalty-lion-integration')
  const isBoldIntegrationsEnabled = useFeatureFlag('enable-bold-integration')

  let groups = cloneDeep(sequenceGroups)

  groups = groups.filter(
    (group) => !['sponsored-message', 'click-to-messenger'].includes(group.slug)
  )

  if (!isJoinedASegmentEnabled) {
    groups = groups.filter((group) => group.slug !== 'sms-custom-triggers')
  }

  let integrationGroups = groups.find((group) => group.slug === 'sms-integrations').subgroups
  if (!isWondermentIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-wonderment')
  }

  if (!isLoopWorkIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-loopwork')
  }

  if (!isTypeformIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-typeform')
  }

  if (!isWheelioIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-wheelio-optin')
  }

  if (!isRechargeIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-recharge')
  }

  if (!isYotpoIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-yotpo')
  }

  if (!isYotpoReviewIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-yotpo-review')
  }

  if (!isLoyaltyLionIntegrationEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-loyalty-lion')
  }

  if (!isBoldIntegrationsEnabled) {
    integrationGroups = integrationGroups.filter((group) => group.slug !== 'sms-bold-subscriptions')
  }

  for (const integrationCategory of getCategories()) {
    const integrationCategoryName = upperFirst(integrationCategory)

    integrationGroups.push({
      name: integrationCategoryName,
      groupPageTitle: integrationCategoryName,
      description: `Trigger automated messages based on ${integrationCategoryName} events.`,
      slug: `sms-integration-${integrationCategory}`,
      tags: [
        SequenceTag.INTEGRATION,
        SequenceTag.SMS,
        SequenceTag.AUTOMATED,
        getCustomIntegrationCategoryTag(integrationCategory) as SequenceTag // temporary solution
      ],
      statisticsSlug: `sms-${getCustomIntegrationCategoryTag(integrationCategory)}`,
      icon: CustomIntegrationIcon,
      // this is a temp workaround for divbrands bc they are marked as woocommerce site in the db
      supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE]
    })
  }

  groups.find((group) => group.slug === 'sms-integrations').subgroups = integrationGroups

  return {
    data: groups,
    isLoading,
    isError
  }
}
