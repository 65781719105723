import { type AnyAction, type Store } from '@reduxjs/toolkit'
import { history } from '../../../routing-utilities/history/history'
import { type AppDispatch } from '../../../store/create-store'
import { type DashboardState } from '../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../store/selectors'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'
import { loadSMSCampaignThunk } from '../../../store/slices/flow-editor/thunks/load-sms-campaign.thunk'
import { isSMSCampaignFlowUI } from '../../../types/guards/flow-ui.guards'
import { isSMSCampaignReadonly } from '../utils/is-sms-campaign-read-only'

export function updateSMSCampaignTargetRulesMiddleware(
  store: Store<DashboardState>
): (next: any) => (action: AnyAction) => void {
  const dispatch: AppDispatch = store.dispatch
  return (next) => (action: AnyAction) => {
    next(action)

    const state = store.getState()

    const searchParams = new URLSearchParams(state.location.currentLocation.search)
    const segmentId = searchParams.get('segmentId') ?? undefined

    if (!segmentId || !loadSMSCampaignThunk.fulfilled.match(action)) {
      return
    }

    const flow = flowEditorSelectors.selectEditorFlow(state)
    if (!flow) {
      throw new Error('Flow not found')
    }

    // It's a brand new flow, no need to update target rules
    if (action.payload === null) {
      return
    }

    if (isSMSCampaignFlowUI(flow) && !isSMSCampaignReadonly(action.payload)) {
      dispatch(
        flowEditorActions.smsCampaignTargetRulesChanged({
          targetType: 'includeSegmentIds',
          segmentIds: [segmentId]
        })
      )
      history.replace(state.location.currentLocation.pathname)
    }
  }
}
