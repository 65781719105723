import difference from 'lodash/difference'
import { useMemo } from 'react'
import { type SequenceGroup } from '../../types/sequence-group'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useSequenceGroupsByTags(tags: string[]): UseResource<SequenceGroup[]> {
  const { data: sequenceGroups, isLoading } = hooks.useSequenceGroups()

  const tagFilteredSequenceGroups = useMemo(() => {
    return sequenceGroups?.filter((sequenceGroup) => {
      return difference(tags, sequenceGroup.tags).length === 0
    })
  }, [tags, sequenceGroups])

  return {
    data: tagFilteredSequenceGroups,
    isLoading
  }
}
