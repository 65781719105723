import cn from 'classnames'
import { EditorButton } from '../../../../../../components/ui-kit/editor-button/editor-button.component'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { DiscountCodeLogos } from '../discount-code-logos/discount-code-logos.component'
import styles from './create-discount-code.scss'

interface CreateDiscountCodeProps {
  footer: React.FC<{ discountCodeExists: boolean }>
  hasDiscountPoolIdOnItem: boolean
  onAddStaticDiscountCode: () => void
  onAddUniqueDiscountCode: () => void
}

function withTooltip(wrappedElement: JSX.Element): JSX.Element {
  return (
    <Tooltip
      placement='top'
      title='You can add only one unique discount code to a text message.'
      arrowPointAtCenter
    >
      <div>{wrappedElement}</div>
    </Tooltip>
  )
}

function getAddUniqueDiscountCodeButton(onClick: () => void, isDisabled: boolean): JSX.Element {
  return (
    <EditorButton
      type='primary'
      className='w-full'
      onClick={onClick}
      disabled={isDisabled}
      data-testid='add-unique-discount-code'
    >
      Add unique discount code
    </EditorButton>
  )
}

export const CreateDiscountCode: React.FC<CreateDiscountCodeProps> = (props) => {
  return (
    <div data-testid='create-discount-code-form' className={styles.container}>
      <h2 className='px-0 pt-2 pb-1 text-sm font-bold'>Add discount code</h2>
      <div className={cn(styles.text, 'text-tiny')}>
        Select the type of discount code you want to add to the message.
      </div>
      <DiscountCodeLogos showShopify={false} />
      <EditorButton
        type='primary'
        className='w-full mt-2 mb-3'
        onClick={props.onAddStaticDiscountCode}
        data-testid='add-general-discount-code'
      >
        Add general discount code
      </EditorButton>
      {props.hasDiscountPoolIdOnItem
        ? withTooltip(getAddUniqueDiscountCodeButton(props.onAddUniqueDiscountCode, true))
        : getAddUniqueDiscountCodeButton(props.onAddUniqueDiscountCode, false)}
      {props.footer && <props.footer discountCodeExists={false} />}
    </div>
  )
}
