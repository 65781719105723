import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { batch } from 'react-redux'
import {
  blastNameValidator,
  blastScheduledForValidator
} from '../../../../routes/SequenceEditor/validators/blast.validator'
import type { AppDispatch } from '../../../create-store'
import { type DashboardState } from '../../../dashboard.state'
import { selectEditorBlast, selectEditorBlastMeta, selectSite } from '../../../selectors'
import {
  blastValidationError,
  blastValidationSuccess,
  touchAllBlastFormItem
} from '../sequence-editor.actions'
import { selectTimeZone } from '../../site/site.selectors'

export const validateBlastThunk = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/validateBlast', async (args, store) => {
  const state = store.getState()

  const dispatch = store.dispatch
  const blast = cloneDeep(selectEditorBlast(state))
  const blastMeta = selectEditorBlastMeta(state)
  const timezone = selectTimeZone(selectSite(state))

  if (!blastMeta.name.edited || !blastMeta.scheduledFor.edited) {
    dispatch(touchAllBlastFormItem())
  }

  const actions = []
  let hasError = false
  const blastNameError = blastNameValidator(blast)
  const blastScheduledForError = blastScheduledForValidator(blast, timezone)

  if (blastNameError) {
    actions.push(blastValidationError(blastNameError, { key: 'name' }))
    hasError = true
  } else {
    actions.push(blastValidationSuccess(null, { key: 'name' }))
  }

  if (blastScheduledForError) {
    actions.push(blastValidationError(blastScheduledForError, { key: 'scheduledFor' }))
    hasError = true
  } else {
    actions.push(blastValidationSuccess(null, { key: 'scheduledFor' }))
  }

  batch(() => {
    actions.forEach(dispatch)
  })

  if (hasError) {
    throw new Error('Blast validation failed')
  }
})
