import { type SMSThreadService, type SMSUsageStat } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export enum SMSUsageType {
  AUTOMATED = 'automated',
  CAMPAIGN = 'campaign',
  INBOX_BY_DIRECTION = 'inboxByDirection',
  OTHER = 'other'
}

export interface SMSUsageTypeTitle {
  title: string
  info?: string
}

export const smsUsageTypeTitles: Record<SMSUsageType, SMSUsageTypeTitle> = {
  [SMSUsageType.AUTOMATED]: {
    title: 'Automated Flows'
  },
  [SMSUsageType.CAMPAIGN]: {
    title: 'Campaigns'
  },
  [SMSUsageType.INBOX_BY_DIRECTION]: {
    title: 'Inbox'
  },
  [SMSUsageType.OTHER]: {
    title: 'Other',
    info: 'Includes all incoming keywords and opt-in requests.'
  }
}

export function useSMSUsage(): UseResource<SMSUsageStat.UsageStatInBillingCycle[]> {
  const queryName = [QUERY_NAME.smsUsage]

  const {
    isLoading,
    data: smsUsage,
    isError,
    refetch
  } = useQuery<SMSThreadService.GetSMSUsageResponse>(queryName, async () => {
    return request.get<SMSThreadService.GetSMSUsageResponse>('sms-usage')
  })

  return {
    data: smsUsage?.data,
    isLoading,
    isError,
    refetch: refetch as any
  }
}
