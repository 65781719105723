import { type SiteAPI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { useEstimatedCost } from '../../../../../hooks/resources/use-estimated-cost'
import { useSegmentsList } from '../../../../../hooks/resources/use-segments-list'
import { flowEditorSelectors } from '../../../../../store/selectors'
import { useSMSSegmentCountInFlow } from './use-sms-segment-count-in-flow'

interface Estimation {
  numberOfSubscribers: number
  numberOfSMSs: number
  numberOfMMSs: number
  totalNumber: number
  estimatedCost: number
}

export interface UseSMSEstimation {
  isLoading: boolean
  min: Estimation
  max: Estimation
  excludedSegments: string[]
  includedSegments: string[]
}

export function useSMSEstimation(isEnabled: boolean): UseSMSEstimation {
  const { min: minEstimation, max: maxEstimation } = useSMSSegmentCountInFlow()

  const {
    smsSegmentCount: minSmsSegmentCount,
    mmsSegmentCount: minMmsSegmentCount,
    mmsToSmsSegmentCount: minMmsToSmsSegmentCount
  } = minEstimation

  const {
    smsSegmentCount: maxSmsSegmentCount,
    mmsSegmentCount: maxMmsSegmentCount,
    mmsToSmsSegmentCount: maxMmsToSmsSegmentCount
  } = maxEstimation
  const flow = useSelector(flowEditorSelectors.selectEditorFlow)

  const smsCampaign = useSelector(flowEditorSelectors.selectEditorSMSCampaign)
  const includeSegmentIds = smsCampaign?.targetRules?.includeSegmentIds ?? []
  const excludeSegmentIds = smsCampaign?.targetRules?.excludeSegmentIds ?? []

  if (!flow || !smsCampaign) {
    throw new Error('Flow or SMS campaign is not defined')
  }

  const { data: minEstimatedCost, isLoading: isMinEstimatedCostLoading } = useEstimatedCost(
    smsCampaign._id,
    minSmsSegmentCount,
    minMmsSegmentCount,
    minMmsToSmsSegmentCount,
    includeSegmentIds,
    excludeSegmentIds,
    'min',
    isEnabled
  )

  const { data: maxEstimatedCost, isLoading: isMaxEstimatedCostLoading } = useEstimatedCost(
    smsCampaign._id,
    maxSmsSegmentCount,
    maxMmsSegmentCount,
    maxMmsToSmsSegmentCount,
    includeSegmentIds,
    excludeSegmentIds,
    'max',
    isEnabled
  )

  const { data: segments } = useSegmentsList()
  const includedSegments =
    segments
      ?.filter((segment) => includeSegmentIds.includes(segment.id))
      .map((segment) => segment.name) ?? []
  const excludedSegments =
    segments
      ?.filter((segment) => excludeSegmentIds.includes(segment.id))
      .map((segment) => segment.name) ?? []

  const flowItems = useSelector(flowEditorSelectors.selectFlowItems)
  if (!flowItems) {
    throw new Error('Flow items are not defined')
  }

  function getEstimationFromSMSCampaignEstimatedResponse(
    response: SiteAPI.SMSCampaignEstimatedCost | undefined
  ): Estimation {
    const numberOfSMSs = response?.segmentsToSend?.sms ? Math.ceil(response.segmentsToSend.sms) : 0
    const numberOfMMSs = response?.segmentsToSend?.mms ? Math.ceil(response.segmentsToSend.mms) : 0
    return {
      numberOfSubscribers: response?.subscribersToSendTo ?? 0,
      numberOfMMSs,
      numberOfSMSs,
      totalNumber: numberOfSMSs + numberOfMMSs,
      estimatedCost: response?.total ?? 0
    }
  }

  const min = getEstimationFromSMSCampaignEstimatedResponse(minEstimatedCost)
  const max = getEstimationFromSMSCampaignEstimatedResponse(maxEstimatedCost)

  return {
    isLoading: (isMinEstimatedCostLoading || isMaxEstimatedCostLoading) ?? false,
    min,
    max,
    excludedSegments,
    includedSegments
  }
}
