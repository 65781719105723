import { type DelaySequenceItemUI, DelayUnit, type DisplayMeta } from '@ghostmonitor/recartapis'
import { Form, InputNumber, Select } from 'antd'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { hooks } from '../../../../../hooks/hooks'
import { ReactComponent as DelayIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/18-Time/time-stopwatch-3-quarters.svg'
import {
  selectCurrencySlug,
  selectDraggedItemType,
  selectEditorSequence,
  selectIsReadOnly,
  selectLinking,
  selectLinkingMeta,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectShowDebugView
} from '../../../../../store/selectors'
import {
  createSequenceItem,
  removeSequenceItem,
  updateDelayValue,
  updateDisplayMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { type SequenceItemMeta } from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import {
  convertNanosecToUnit,
  convertUnitToNanosec,
  oneHourInNanosecs
} from '../../../../../utils/time-conversion'
import {
  type SequenceItemNodeWidgetBaseProps,
  makeSequenceItem
} from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc'
import hocStyles from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc.scss'
import { type LogicPortModel } from '../../../models/port/logic-port-model'
import { type SequenceItemPortModel } from '../../../models/port/sequence-item-port-model'
import { type DelaySequenceItemNodeModel } from '../../../models/sequence-item/delay/delay-sequence-item-model'
import { DEFAULT_SEQUENCE_ITEM_OFFSET } from '../../../types/sequence-editor-constants'
import { LogicSequenceItemPortWidget } from '../../logic-sequence-item-port-widget/logic-sequence-item-port-widget.component'
import { SequenceItemWidgetTitle } from '../sequence-item-widget-title.component'

import { ObjectId } from 'bson'
import styles from './delay-sequence-item-node-widget.component.scss'

export interface DelaySequenceItemNodeWidgetComponentProps extends SequenceItemNodeWidgetBaseProps {
  sequenceItemNode: DelaySequenceItemNodeModel
  sequenceItem: DelaySequenceItemUI
  sequenceItemMeta: SequenceItemMeta
  isEntry: boolean

  inputPort: SequenceItemPortModel
  outputPort: LogicPortModel
  onUpdate: any // (delayValue: number) => void
  onUpdateDisplayMeta: (displayMeta: DisplayMeta) => void

  repaintCanvas: VoidFunction
  displayMeta: DisplayMeta
}

export function DelayComponent(props: DelaySequenceItemNodeWidgetComponentProps) {
  const delayProp = props.sequenceItem?.logic.delay.delayDuration ?? oneHourInNanosecs
  const delayUnit = props.displayMeta?.delayUnit ?? DelayUnit.HOURS
  const delayValue = convertNanosecToUnit(delayProp, delayUnit)
  const editorSettings = hooks.useSequenceEditorSettings()
  const isReadOnly = useSelector(selectIsReadOnly)

  function handleValueChange(value: number | string) {
    let numberValue = Number(value)
    if (value === null || isNaN(numberValue)) {
      numberValue = 1
    }

    updateSequenceItemDelay(numberValue, props.displayMeta.delayUnit)
  }

  function handleUnitChange(unit: DelayUnit) {
    props.sequenceItemNode.setDisplayMeta({ delayUnit: unit })
    props.onUpdateDisplayMeta({ delayUnit: unit })
    updateSequenceItemDelay(delayValue, unit)
  }

  function updateSequenceItemDelay(value: number, unit: DelayUnit) {
    const newDelay = convertUnitToNanosec(Math.round(value), unit)
    props.onUpdate(newDelay)
  }

  function getDelayUnitLabel(value: number, unit: string): string {
    return value > 1 ? `${unit}s` : unit
  }

  const delayUnitOptions = [
    { value: DelayUnit.MINUTES, label: getDelayUnitLabel(delayValue, 'Minute') },
    { value: DelayUnit.HOURS, label: getDelayUnitLabel(delayValue, 'Hour') },
    ...(editorSettings.hasDaysDelay
      ? [{ value: DelayUnit.DAYS, label: getDelayUnitLabel(delayValue, 'Day') }]
      : [])
  ]

  let maxValue: number

  switch (delayUnit) {
    case DelayUnit.DAYS:
      maxValue = Math.floor(editorSettings.maxDelay / 24)
      break
    case DelayUnit.HOURS:
      maxValue = editorSettings.maxDelay
      break
    case DelayUnit.MINUTES:
      maxValue = editorSettings.maxDelay * 60
      break
    default:
      break
  }

  const hasError = props.sequenceItemMeta.error !== null

  return (
    <>
      <div>
        <p className={cn(hocStyles.title, styles.title)}>Delay duration</p>
        <div className={styles.inputWrapper}>
          <div className={styles.input}>
            <Form.Item validateStatus={hasError ? 'error' : 'success'}>
              <InputNumber
                size='large'
                min={1}
                max={maxValue}
                value={delayValue}
                onChange={handleValueChange}
                precision={0}
                style={{ width: 100 }}
                data-testid='delay-value'
                disabled={isReadOnly}
              />
            </Form.Item>
          </div>
          <div
            className={styles.input}
            onMouseDown={(e: React.MouseEvent) => {
              e.stopPropagation()
            }}
          >
            <Form.Item validateStatus={hasError ? 'error' : 'success'}>
              <Select
                size='large'
                value={delayUnit}
                onChange={handleUnitChange}
                style={{ width: 100 }}
                data-testid='delay-unit'
                aria-controls='test-uuid'
                disabled={isReadOnly}
              >
                {delayUnitOptions.map((option) => (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    data-testid={`delay-unit-${option.value}`}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        {props.outputPort && (
          <LogicSequenceItemPortWidget
            node={props.sequenceItemNode}
            name={props.outputPort.name}
            port={props.outputPort}
            isEntry={props.isEntry}
            linking={props.linking}
            linkingMeta={props.linkingMeta}
          />
        )}
      </div>
    </>
  )
}

export const DelaySequenceItemNodeWidgetComponent = makeSequenceItem(
  DelayComponent,
  [hocStyles['delay-wrapper']],
  <SequenceItemWidgetTitle wrapperClasses={[]} icon={<DelayIcon />} text='Delay' />
)

export interface DelaySequenceItemNodeWidgetProps {
  sequenceItemNode: DelaySequenceItemNodeModel
  repaintCanvas: VoidFunction
}

export function DelaySequenceItemNodeWidget(props: DelaySequenceItemNodeWidgetProps) {
  const stateSelector = useCallback(
    createStructuredSelector({
      sequence: selectEditorSequence,
      sequenceItem: selectSequenceItem<DelaySequenceItemUI>(props.sequenceItemNode.sequenceItemId),
      sequenceItemMeta: selectSequenceItemMeta(props.sequenceItemNode.sequenceItemId),
      showDebugView: selectShowDebugView,
      linking: selectLinking,
      linkingMeta: selectLinkingMeta,
      draggedItemType: selectDraggedItemType,
      currencySlug: selectCurrencySlug
    }),
    [props.sequenceItemNode.sequenceItemId]
  )

  const state = useSelector(stateSelector)
  const dispatch = useDispatch()

  const [removing, setRemoving] = useState(false)

  function handleRepaintCanvas() {
    if (!removing) {
      props.repaintCanvas()
    }
  }

  function handleRemoveSequenceItem() {
    setRemoving(true)
    dispatch(removeSequenceItem(state.sequenceItem._id))
  }

  function handleUpdateDelay(value: number) {
    dispatch(updateDelayValue(state.sequenceItem._id, value))
  }

  function handleUpdateDisplayMeta(displayMeta: DisplayMeta) {
    dispatch(updateDisplayMeta(state.sequenceItem._id, displayMeta))
  }

  function handleCloneSequenceItem() {
    const newSequenceItem = cloneDeep(state.sequenceItem)
    newSequenceItem._id = new ObjectId().toHexString()
    newSequenceItem.trigger = [newSequenceItem._id]
    newSequenceItem.tags = [...state.sequence.tags]
    newSequenceItem.logic.delay.trigger = null

    dispatch(
      createSequenceItem(
        newSequenceItem,
        {
          x: props.sequenceItemNode.x + DEFAULT_SEQUENCE_ITEM_OFFSET.x,
          y: props.sequenceItemNode.y + DEFAULT_SEQUENCE_ITEM_OFFSET.y
        },
        displayMeta
      )
    )
  }

  const isEntry = props.sequenceItemNode.sequenceItemId === state.sequence?.entrySequenceItemId
  const inputPort = props.sequenceItemNode.getInputPort()
  const outputPort = props.sequenceItemNode.getOutputPort()
  const displayMeta = props.sequenceItemNode.getDisplayMeta()

  return (
    <DelaySequenceItemNodeWidgetComponent
      currencySlug={state.currencySlug}
      faded={!!state.draggedItemType}
      inputPort={inputPort}
      isEntry={isEntry}
      linking={state.linking}
      linkingMeta={state.linkingMeta}
      outputPort={outputPort}
      sequenceItem={state.sequenceItem}
      sequenceItemMeta={state.sequenceItemMeta}
      sequenceItemNode={props.sequenceItemNode}
      sequenceName={state.sequence?.name}
      showDropZone={false}
      showSequenceItemStatistics={false}
      showDebugView={state.showDebugView}
      repaintCanvas={handleRepaintCanvas}
      displayMeta={displayMeta}
      onClone={handleCloneSequenceItem}
      onRemove={handleRemoveSequenceItem}
      onUpdate={handleUpdateDelay}
      onUpdateDisplayMeta={handleUpdateDisplayMeta}
    />
  )
}
