import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { type UseResourceCache } from '../types/use-resource-cache.type'
import { QUERY_NAME } from './query-names'

export function useBlastsCache(): UseResourceCache {
  const queryClient = useQueryClient()
  const queryName = [QUERY_NAME.blasts]

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  return {
    invalidateCache
  }
}
