import { useSelector } from 'react-redux'
import { hooks } from '../../../../../hooks/hooks'
import { selectIsReadOnly } from '../../../../../store/selectors'
import { SequenceEditorTray } from '../../sequence-editor-tray/sequence-editor-tray.component'
import {
  TrayItemConditionalSplitSequenceItem,
  TrayItemDelaySequenceItem,
  TrayItemRandomSplitSequenceItem,
  TrayItemSMSSequenceItem
} from '../../tray-item/sequence-item/tray-item-sequence-item.component'
import { TrayItemSMSContactCard } from '../../tray-item/sms/tray-item-sms-contact-card.component'
import { TrayItemSMSMedia } from '../../tray-item/sms/tray-item-sms-media.component'
import styles from '../tray.scss'

export function SMSTray() {
  const isReadOnly = useSelector(selectIsReadOnly)
  const editorSettings = hooks.useSequenceEditorSettings()

  if (isReadOnly) {
    return null
  }

  return (
    <SequenceEditorTray>
      <TrayItemSMSSequenceItem />
      {editorSettings.isConditionalSplitEnabled && <TrayItemConditionalSplitSequenceItem />}
      <TrayItemDelaySequenceItem />
      <TrayItemRandomSplitSequenceItem />
      <>
        <div className={styles.line} />
        <div className={styles.trayitems}>
          <TrayItemSMSMedia />
        </div>
        <div className={styles.trayitems}>
          <TrayItemSMSContactCard />
        </div>
      </>
    </SequenceEditorTray>
  )
}
