import {
  DelayUnit,
  FlowDiagram,
  LOGIC_PORT_CONDITION_MET,
  LOGIC_PORT_CONDITION_UNMET,
  NodeType,
  PortType
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { addEdge } from 'reactflow'
import { generateLogicHandleName } from '../../../../routes/flow-editor/utils/generate-logic-handle-name'
import { selectEditorFlow, selectEditorFlowMeta } from '../flow-editor.selectors'
import { type FlowEditorState } from '../flow-editor.state'
import { reactFlowInternals } from './react-flow-internals'

// eslint-disable-next-line @typescript-eslint/max-params
export function insertNodeBetweenNodes(
  state: FlowEditorState,
  sourceNodeId: string,
  sourceHandleId: string,
  targetNodeId: string,
  targetHandleId: string,
  node: FlowDiagram.Node,
  // The new node is inserted between two nodes, so it will have two edges
  // The first edge is the connectionBefore, the second edge is the connectionAfter
  undeletableConnectionBefore = false
) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  // Existing connection between the two nodes
  const connectedEdge = flow.flowEditorDiagram.edges.find(
    (edge) => edge.source === sourceNodeId && edge.sourceHandle === sourceHandleId
  )

  if (!connectedEdge) {
    throw new Error('There is no connection between these two nodes')
  }

  const inputHandle = node.data.inputHandle

  if (!inputHandle) {
    throw new Error('Input handle not found')
  }

  // 1: The already existing connection is now connected to the new node
  connectedEdge.type = undeletableConnectionBefore
    ? FlowDiagram.EdgeType.undeletable
    : FlowDiagram.EdgeType.custom
  connectedEdge.source = sourceNodeId
  connectedEdge.sourceHandle = sourceHandleId
  connectedEdge.target = node.id
  connectedEdge.targetHandle = inputHandle.id

  // 2: The new node is connected to the target node
  let newEdgeSource: string
  let newEdgeSourceHandle: string

  if (node.type === NodeType.SMS_FLOW_ITEM) {
    if (!node.data.followUpHandle?.id) {
      throw new Error('Follow up handle not found on the new node')
    }

    newEdgeSource = node.id
    newEdgeSourceHandle = node.data.followUpHandle?.id
  } else if (
    node.type === NodeType.RANDOM_SPLIT_FLOW_ITEM ||
    node.type === NodeType.DELAY_FLOW_ITEM
  ) {
    if (!node.data.logicHandles?.[0]?.id) {
      throw new Error('Logic handle doesnt exist on new node')
    }
    newEdgeSource = node.id
    newEdgeSourceHandle = node.data.logicHandles?.[0]?.id
    // this is the current / old logic of conditional item, it will be refactored later so that is why we separate it from other logic items
  } else if (node.type === NodeType.CONDITIONAL_SPLIT_FLOW_ITEM) {
    if (!node.data.logicHandles?.[0]?.id) {
      throw new Error('Logic handle doesnt exist on new node')
    }

    newEdgeSource = node.id
    newEdgeSourceHandle = node.data.logicHandles?.[0]?.id
  } else if (node.type === NodeType.CONDITIONAL_SPLIT_FLOW_ITEM_V2) {
    newEdgeSource = ''
    newEdgeSourceHandle = ''
  } else {
    throw new Error(`Node type not supported: ${node.type}`)
  }

  // create new edge which target will be this info and source is the new node (if it is not a conditional split)
  flow.flowEditorDiagram.edges = addEdge(
    {
      type: FlowDiagram.EdgeType.custom,
      target: targetNodeId,
      targetHandle: targetHandleId,
      source: newEdgeSource,
      sourceHandle: newEdgeSourceHandle
    },
    flow.flowEditorDiagram.edges
  )
}

// eslint-disable-next-line @typescript-eslint/max-params
export function insertNodeAfterNode(
  state: FlowEditorState,
  sourceNodeId: string,
  sourceHandleId: string,
  node: FlowDiagram.Node,
  // The new node is inserted after an existing node
  undeletableConnectionBefore = false
) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const inputHandle = node.data.inputHandle

  if (!inputHandle) {
    throw new Error('Input handle not found')
  }

  const targetHandleId = node.data.inputHandle?.id
  const targetNodeId = node.id

  if (!targetHandleId) {
    throw new Error('Target handle not found')
  }

  // create new edge which target will be the new node and source is the node to which the new item is attached to
  flow.flowEditorDiagram.edges = addEdge(
    {
      type: FlowDiagram.EdgeType.custom,
      target: targetNodeId,
      targetHandle: targetHandleId,
      source: sourceNodeId,
      sourceHandle: sourceHandleId
    },
    flow.flowEditorDiagram.edges
  )
}

// eslint-disable-next-line @typescript-eslint/max-params
export function insertNode(
  state: FlowEditorState,
  flowItemId: string,
  nodeType: NodeType,
  delayUnit: string | undefined = undefined,
  logicHandlesLength: number | undefined = undefined,
  undeletable = false,
  uneditable = false
): FlowDiagram.Node {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const flowMeta = selectEditorFlowMeta(state)

  if (reactFlowInternals.reactFlowInstance === null) {
    throw new Error('reactFlowInstance not yet set')
  }

  const { x: initialX, y: initialY } = reactFlowInternals.reactFlowInstance.screenToFlowPosition({
    x: 100,
    y: 100
  })

  const newNodePosition = flowMeta.targetPosition ?? { x: initialX, y: initialY }

  const inputHandle: FlowDiagram.Handle = {
    id: new ObjectId().toHexString(),
    type: 'target',
    portType: PortType.SEQUENCE_ITEM,
    name: 'input'
  }

  const nodeData: FlowDiagram.NodeData = {
    nodeType,
    inputHandle,
    flowItemId
  }

  const keywordReplyHandles: FlowDiagram.Handle[] = []

  if ([NodeType.SMS_FLOW_ITEM, NodeType.TRIGGER].includes(nodeType)) {
    nodeData.followUpHandle = {
      id: new ObjectId().toHexString(),
      type: 'source',
      portType: PortType.FOLLOW_UP,
      name: 'follow-up-port'
    }
  }

  if (nodeType === NodeType.SMS_FLOW_ITEM) {
    nodeData.keywordReplyHandles = keywordReplyHandles
  }

  const logicHandles: FlowDiagram.Handle[] = []

  if (nodeType === NodeType.RANDOM_SPLIT_FLOW_ITEM) {
    const logicHandlesNumber = logicHandlesLength || 2
    for (let index = 0; index < logicHandlesNumber; index++) {
      logicHandles.push({
        id: new ObjectId().toHexString(),
        type: 'source',
        portType: PortType.LOGIC,
        name: generateLogicHandleName(flowItemId, index)
      })
    }
    nodeData.logicHandles = logicHandles
  }

  if (nodeType === NodeType.CONDITIONAL_SPLIT_FLOW_ITEM) {
    logicHandles.push({
      id: new ObjectId().toHexString(),
      type: 'source',
      portType: PortType.LOGIC,
      name: LOGIC_PORT_CONDITION_MET
    })
    logicHandles.push({
      id: new ObjectId().toHexString(),
      type: 'source',
      portType: PortType.LOGIC,
      name: LOGIC_PORT_CONDITION_UNMET
    })

    nodeData.logicHandles = logicHandles
  }

  if (nodeType === NodeType.CONDITIONAL_SPLIT_FLOW_ITEM_V2) {
    const logicHandlesNumber = logicHandlesLength ? logicHandlesLength - 1 : 0
    for (let index = 0; index < logicHandlesNumber; index++) {
      logicHandles.push({
        id: new ObjectId().toHexString(),
        expressionIndex: index,
        type: 'source',
        portType: PortType.LOGIC,
        name: LOGIC_PORT_CONDITION_MET
      })
    }
    if (logicHandlesLength) {
      logicHandles.push({
        id: new ObjectId().toHexString(),
        type: 'source',
        portType: PortType.LOGIC,
        name: LOGIC_PORT_CONDITION_UNMET
      })
    }
    nodeData.logicHandles = logicHandles
  }

  if (nodeType === NodeType.DELAY_FLOW_ITEM) {
    logicHandles.push({
      id: new ObjectId().toHexString(),
      type: 'source',
      portType: PortType.LOGIC,
      name: 'logic-port'
    })

    nodeData.logicHandles = logicHandles
    nodeData.delayUnit = delayUnit ?? DelayUnit.HOURS
  }

  nodeData.undeletable = undeletable
  nodeData.uneditable = uneditable

  const newNode: FlowDiagram.Node = {
    id: new ObjectId().toHexString(),
    type: nodeType,
    position: {
      x: newNodePosition.x,
      y: newNodePosition.y
    },
    data: nodeData
  }

  flow.flowEditorDiagram.nodes.push(newNode)

  return newNode
}
