import {
  type ButtonActionType,
  type ButtonUI,
  type CartUrlButton,
  isSequenceItemLinkButton,
  type LinkingMeta,
  type PhoneNumberButton,
  PortType,
  type ReviewButton,
  type SequenceItemLinkButton,
  type ShipmentTrackingButton,
  SiteEngineSlug,
  type WebUrlButton
} from '@ghostmonitor/recartapis'
import cn from 'classnames'
import { hooks } from '../../../../hooks/hooks'
import { ReactComponent as ConnectedBoltIcon } from '../../../../static/images/svg/sequence-editor/bolt-set.svg'
import { ReactComponent as DisconnectedBoltIcon } from '../../../../static/images/svg/sequence-editor/bolt-unset.svg'
import { ReactComponent as LinkIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/27-Link-Unlink/hyperlink-3.svg'
import { ReactComponent as CartIcon } from '../../../../static/images/svg/streamline/stroke/17-Users/13-Geomertic-Close Up-Single User Actions-Neutral/single-neutral-actions-cart.svg'
import { ReactComponent as PhoneIcon } from '../../../../static/images/svg/streamline/stroke/20-Phones-Mobile-Devices/01-Phone/phone.svg'
import { selectSiteEngineSlug } from '../../../../store/slices/site/site.selectors'
import { type ButtonPortModel } from '../../models/port/button-port-model'
import { ThrottledInlineEditor } from '../inline-editor/throttled-inline-editor.component'
import { type InteractiveComponentProps } from '../interactive-component/make-interactive.hoc'
import { ButtonPopover } from './button-popover.component'
import styles from './messenger-button.component.scss'
import {
  isCartUrlButtonProps,
  isPhoneNumberButtonProps,
  isReviewButtonProps,
  isSequenceItemLinkButtonProps,
  isShipmentTrackingButtonProps,
  isWebUrlButtonProps
} from './messenger-button.types'
import { type ButtonTypeOption } from './types/button-type-option.type'

export interface ButtonProps<T extends ButtonUI = ButtonUI> {
  payload: T
  buttonTypeOptions: ButtonTypeOption[]
  sequenceItemId: string
  sequenceItemNodeId?: string
  port?: ButtonPortModel
  linking: boolean
  linkingMeta: LinkingMeta
  disableTypeChange?: boolean

  disableEdit?: boolean
  onChange: (buttonChange: Partial<T>) => void
  onFocus: () => void
  onBlur: () => void
  clearSelection?: () => void
}

export function MessengerButton(props: ButtonProps & InteractiveComponentProps) {
  const siteEngineSlug = hooks.useSiteSelector(selectSiteEngineSlug)
  const isShopify = siteEngineSlug === SiteEngineSlug.SHOPIFY

  function renderPort() {
    let isConnected = false

    const links = Object.values(props.port.getLinks())

    if (links.length > 0 && links[0].getTargetPort()) {
      isConnected = true
    }

    const shouldPulse =
      props.linking &&
      props.linkingMeta.portType === PortType.SEQUENCE_ITEM &&
      props.linkingMeta.portParentSequenceItemId !== props.sequenceItemId

    const iconClasses = cn('port', styles['button-icon'], styles['port-wrapper'], {
      [styles.connected]: isConnected,
      [styles.pulse]: shouldPulse && !isConnected
    })

    // cant use conditional rendering cause the diagram needs to find the DOM element
    // that has the 'port' class in order to work properly
    const visible = isSequenceItemLinkButton(props.payload)

    return (
      <div
        style={{ display: visible ? 'flex' : 'none' }}
        className={iconClasses}
        data-name={props.port.name}
        data-nodeid={props.sequenceItemNodeId}
      >
        {isConnected ? <ConnectedBoltIcon /> : <DisconnectedBoltIcon />}
      </div>
    )
  }

  let button: JSX.Element
  if (isWebUrlButtonProps(props)) {
    button = renderWebUrlButton(props)
  } else if (isShipmentTrackingButtonProps(props)) {
    button = renderShipmentTrackingUrlButton(props)
  } else if (isPhoneNumberButtonProps(props)) {
    button = renderPhoneNumberButton(props)
  } else if (isReviewButtonProps(props)) {
    button = renderReviewUrl(props)
  } else if (isCartUrlButtonProps(props)) {
    button = renderCartUrl(props)
  } else if (isSequenceItemLinkButtonProps(props)) {
    button = renderSequenceItemLink(props)
  }

  function handleValueChange(payload: Partial<ButtonUI>) {
    props.onChange(payload)
  }

  function handleTypeChange(type: ButtonActionType) {
    props.onChange({ type })
  }

  if (siteEngineSlug === undefined) {
    return null
  }

  return (
    <>
      <div onFocus={props.onFocus} className={styles['button-wrapper']}>
        <ButtonPopover
          type={props.payload.type}
          payload={props.payload}
          onHide={() => props.clearSelection}
          buttonTypeOptions={props.buttonTypeOptions}
          onValueChange={handleValueChange}
          onBlur={props.onBlur}
          onTypeChange={handleTypeChange}
          disableTypeChange={props.disableTypeChange}
          enableCreateDiscount={isShopify}
          errorMessage={props.errorMessage}
        >
          {button}
        </ButtonPopover>
        {props.port && renderPort()}
      </div>
    </>
  )
}

function renderWebUrlButton(props: ButtonProps<WebUrlButton> & InteractiveComponentProps) {
  function handleChange(value: string) {
    props.onChange({ title: value })
  }

  return (
    <div
      data-testid='messenger-button'
      className={cn(styles.button, {
        [styles.blurred]: props.isBlurred,
        [styles.selected]: props.isSelected,
        [styles['has-error']]: props.hasError
      })}
    >
      <ThrottledInlineEditor
        placeholder={props.payload.title}
        multiline={false}
        defaultValue={props.payload.title}
        onFocus={props.onFocus}
        onChange={handleChange}
        onBlur={props.onBlur}
        isEditable={!props.disableEdit}
        characterLimit={20}
        type='basic'
      />
      <span className={cn(styles['button-icon'], styles['dark-icon'])}>
        <LinkIcon />
      </span>
    </div>
  )
}

function renderShipmentTrackingUrlButton(
  props: ButtonProps<ShipmentTrackingButton> & InteractiveComponentProps
) {
  function handleChange(value: string) {
    props.onChange({ title: value })
  }

  return (
    <button
      className={cn(styles.button, {
        [styles.blurred]: props.isBlurred,
        [styles.selected]: props.isSelected,
        [styles['has-error']]: props.hasError
      })}
    >
      <ThrottledInlineEditor
        placeholder={props.payload.title}
        multiline={false}
        defaultValue={props.payload.title}
        onFocus={props.onFocus}
        onChange={handleChange}
        onBlur={props.onBlur}
        isEditable={!props.disableEdit}
        characterLimit={20}
        type='basic'
      />
    </button>
  )
}

function renderPhoneNumberButton(
  props: ButtonProps<PhoneNumberButton> & InteractiveComponentProps
) {
  function handleChange(value: string) {
    props.onChange({ title: value })
  }

  return (
    <button
      className={cn(styles.button, {
        [styles.blurred]: props.isBlurred,
        [styles.selected]: props.isSelected,
        [styles['has-error']]: props.hasError
      })}
    >
      <ThrottledInlineEditor
        placeholder={props.payload.title}
        multiline={false}
        defaultValue={props.payload.title}
        onFocus={props.onFocus}
        onChange={handleChange}
        onBlur={props.onBlur}
        isEditable={!props.disableEdit}
        characterLimit={20}
        type='basic'
      />
      <span className={cn(styles['button-icon'], styles['dark-icon'])}>
        <PhoneIcon />
      </span>
    </button>
  )
}

function renderReviewUrl(props: ButtonProps<ReviewButton> & InteractiveComponentProps) {
  function handleChange(value: string) {
    props.onChange({ title: value })
  }

  return (
    <button
      className={cn(styles.button, {
        [styles.blurred]: props.isBlurred,
        [styles.selected]: props.isSelected,
        [styles['has-error']]: props.hasError
      })}
    >
      <ThrottledInlineEditor
        placeholder={props.payload.title}
        multiline={false}
        defaultValue={props.payload.title}
        onFocus={props.onFocus}
        onChange={handleChange}
        onBlur={props.onBlur}
        isEditable={!props.disableEdit}
        characterLimit={20}
        type='basic'
      />
    </button>
  )
}

function renderCartUrl(props: ButtonProps<CartUrlButton> & InteractiveComponentProps) {
  function handleChange(value: string) {
    props.onChange({ title: value })
  }

  return (
    <button
      className={cn(styles.button, {
        [styles.blurred]: props.isBlurred,
        [styles.selected]: props.isSelected,
        [styles['has-error']]: props.hasError
      })}
    >
      <ThrottledInlineEditor
        placeholder={props.payload.title}
        multiline={false}
        defaultValue={props.payload.title}
        onFocus={props.onFocus}
        onChange={handleChange}
        onBlur={props.onBlur}
        isEditable={!props.disableEdit}
        characterLimit={20}
        type='basic'
      />
      <span className={cn(styles['button-icon'], styles['dark-icon'])}>
        <CartIcon />
      </span>
    </button>
  )
}

function renderSequenceItemLink(
  props: ButtonProps<SequenceItemLinkButton> & InteractiveComponentProps
) {
  function handleChange(value: string) {
    props.onChange({ title: value })
  }

  return (
    <button
      className={cn(styles.button, {
        [styles.blurred]: props.isBlurred,
        [styles.selected]: props.isSelected,
        [styles['has-error']]: props.hasError
      })}
    >
      <ThrottledInlineEditor
        placeholder={props.payload.title}
        multiline={false}
        defaultValue={props.payload.title}
        onFocus={props.onFocus}
        onChange={handleChange}
        onBlur={props.onBlur}
        isEditable={!props.disableEdit}
        characterLimit={20}
        type='basic'
      />
    </button>
  )
}
