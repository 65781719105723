import { type GetStaticDiscountCodeResponse } from '@ghostmonitor/recartapis'
import { type AxiosError } from 'axios'
import { api } from '../../api'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'

export async function validateDiscountCode(
  discountCode: string
): Promise<FlowEditorValidationError | undefined> {
  try {
    const discountCodeResponse = await api.getDiscountCode(discountCode)

    if (discountCodeResponse.data) {
      if (discountCodeResponse.data.expired) {
        return {
          message: FlowEditorError.DiscountCodeExpired,
          level: FlowEditorErrorLevel.Error
        }
      }
    }
  } catch (error: any) {
    const axiosError: AxiosError<GetStaticDiscountCodeResponse> = error

    if (axiosError?.response?.status === 404) {
      return {
        message: FlowEditorError.DiscountCodeNotExists,
        level: FlowEditorErrorLevel.Error
      }
    }
  }
}
