import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { showConfirmModal } from '../../../../../components/ui-kit/modals/confirm-modal/confirm-modal.component'
import { ReactComponent as ArrowLeftIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/40-Keyboard/keyboard-arrow-left.svg'
import { type AppDispatch } from '../../../../../store/create-store'
import { type DashboardState } from '../../../../../store/dashboard.state'
import { selectEditorSequence, selectEditorSequenceMeta } from '../../../../../store/selectors'
import { removeSequence } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import styles from '../header.component.scss'

export function HeaderBack() {
  const homePage = '/'
  const dispatch: AppDispatch = useDispatch()
  const editorSettings = useSequenceEditorSettings()
  const sequence = useSelector(selectEditorSequence)
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const previousLocation = useSelector((state: DashboardState) => state.location.previousLocation)

  const searchParams = new URLSearchParams(location.search)
  const backTo = searchParams.get('backTo')

  const locationFrom = {
    fromSlug: pathname,
    id: sequence._id
  }

  function handleClickBack() {
    if (backTo && typeof backTo === 'string') {
      if (sequenceMeta.isEverSaved) {
        navigate(backTo, { state: locationFrom })
      } else {
        navigate(backTo)
      }
    } else {
      navigate(previousLocation?.pathname?.length > 0 ? previousLocation.pathname : homePage)
    }
  }

  function handleEditorLeave() {
    if (sequenceMeta.saving || sequenceMeta.toggling) {
      return
    }
    if (sequenceMeta.isEverSaved) {
      dispatch(removeSequence())
      handleClickBack()
    } else {
      showConfirmModal({
        ...editorSettings.leaveEditorModal,
        onOk: () => {
          dispatch(removeSequence())
          handleClickBack()
        },
        centered: true
      })
    }
  }

  return (
    <div className={styles.back} onClick={handleEditorLeave}>
      <ArrowLeftIcon />
      Back
    </div>
  )
}
