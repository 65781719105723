import { getPostIdFromTrigger, type SequenceItemUI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { selectEntrySequenceItem } from '../../../store/selectors'

export function getFacebookCommentPostIdFromSequenceItem(sequenceItem: SequenceItemUI): string {
  const trigger = sequenceItem?.trigger
  const triggerWithPostId = trigger?.find((trg) => getPostIdFromTrigger(trg) !== undefined)
  return getPostIdFromTrigger(triggerWithPostId)
}

export function useEditorFacebookCommentPostId(): string {
  const entrySequenceItem = useSelector(selectEntrySequenceItem)
  return getFacebookCommentPostIdFromSequenceItem(entrySequenceItem)
}
