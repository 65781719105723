import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMessageMeta } from '../../../../store/selectors'
import { removeMessage } from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { getComponentDisplayName } from '../../../../utils/utils'
import { type MessageTemplateProps } from '../../types/message-template-props.type'
import { getErrorMessage } from '../../types/sequence-editor-errors'
import styles from './make-interactive-message.hoc.scss'

interface MakeInteractiveMessageProps {
  isRemovable: boolean
  width?: number
}

export function makeInteractiveMessage<T extends MessageTemplateProps>(
  WrappedComponent: React.FC<T>
): React.FC<MakeInteractiveMessageProps & T> {
  const InteractiveMessage = (props: React.PropsWithChildren<MakeInteractiveMessageProps & T>) => {
    const dispatch = useDispatch()
    const messageMeta = useSelector(selectMessageMeta(props.sequenceItemId, props.messageIndex))

    const [hovered, setHovered] = useState(false)

    useEffect(() => {
      if (!messageMeta.selected) {
        setHovered(false)
      }
    }, [messageMeta.selected])

    function onMouseOver(event: React.MouseEvent) {
      event.stopPropagation()
      setHovered(true)
      return false
    }

    function onMouseLeave(event: React.MouseEvent) {
      event.stopPropagation()
      setHovered(false)
      return false
    }

    function handleRemove() {
      dispatch(removeMessage(props.sequenceItemId, props.messageIndex))
    }

    return (
      <div
        className={cn(styles.interactive, {
          [styles['has-error']]: messageMeta.errors.length > 0,
          [styles.hovered]: hovered,
          // TODO
          [styles.last]: true,
          // TODO
          [styles.first]: true,
          [styles.selected]: messageMeta.selected,
          // TODO
          [styles.blurred]: false
        })}
        style={props.width ? { width: `${props.width}px` } : null}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        data-testid={
          messageMeta.errors.length > 0 ? 'interactive-component-error' : 'interactive-component'
        }
      >
        <div className={styles['remove-button-wrapper']}>
          {props.isRemovable && (
            <div
              data-testid='remove-element'
              className={styles['remove-button']}
              onClick={handleRemove}
            >
              ×
            </div>
          )}
          <div className={styles['error-message-wrapper']}>
            <div className={styles['error-message']}>{getErrorMessage(messageMeta.errors[0])}</div>
          </div>
        </div>

        <WrappedComponent {...props} />
      </div>
    )
  }

  InteractiveMessage.displayName = `makeInteractiveMessage(${getComponentDisplayName(
    WrappedComponent
  )})`
  return InteractiveMessage
}
