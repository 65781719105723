import { type HttpResponse, type OptinTool } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

// NOTE: prefer using useOptinToolsList
export function useOptinTools(): UseResource<OptinTool[]> {
  const queryName = [QUERY_NAME.optinTool]

  const { isLoading, data } = useQuery<HttpResponse<OptinTool[]>, AxiosError>(queryName, async () =>
    request.get<HttpResponse<OptinTool[]>>(`optin-tools`)
  )

  return {
    isLoading,
    data: data?.data
  }
}
