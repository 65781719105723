import {
  type BillingSubscription,
  type GetBillingSubscriptionsResponse
} from '@ghostmonitor/recartapis'
import { type UseQueryOptions } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { hasShopifyShowcase } from '../utils/site/shopify-showcase'
import { hooks } from './hooks'

export type UseBillingSubscriptionsOptions = UseQueryOptions<GetBillingSubscriptionsResponse>

export interface UseBillingInfo {
  activeSubscriptionChannels?: string[]
  hasActiveSubscription?: boolean
  hasStripeCustomer?: boolean
  isError: boolean
  isLoading: boolean
  isShopifyShowcase: boolean
  isShopifyShowcaseTrial?: boolean
  nextBillingCycleStartsAt?: string
  subscriptionData: BillingSubscription[]
}

const SUBSCRIPTION_REFETCH_INTERVAL = 2000
const MAX_NUMBER_OF_REFETCH = 10

function getNextBillingCycleStartsAt(subscriptionData: BillingSubscription[]): string {
  return subscriptionData?.reduce((acc, curr) => {
    if (curr.type === 'sms') {
      return curr.billingCycleEndsAt
    }
    if (acc === '') {
      return curr.billingCycleEndsAt
    }
    return acc
  }, '')
}

export function useBillingInfo(options?: UseBillingSubscriptionsOptions): UseBillingInfo {
  const {
    data: subscriptionData,
    isLoading: isBillingSubscriptionLoading,
    isError: isBillingSubscriptionError,
    refetch: refetchSubscription
  } = hooks.useBillingSubscriptions(options)
  const {
    data: siteData,
    isLoading: isSiteLoading,
    isError: isSiteError,
    refetch: refetchSite
  } = hooks.useSite()

  const activeChannels = subscriptionData?.map((subscription) => subscription.type)
  const numberOfActiveChannels = activeChannels?.length ?? 0
  const searchParams = new URLSearchParams(location.search)
  const [refetchCount, setRefetchCount] = useState(0)
  const hasActiveSubscription = siteData?.billingInfo.hasActiveSubscription
  const hasStripeCustomer =
    siteData?.stripe?.customer !== undefined && siteData.stripe.customer !== ''
  const isShopifyShowcase = hasShopifyShowcase(siteData)
  const nextBillingCycleStartsAt = getNextBillingCycleStartsAt(subscriptionData)

  const isShopifyShowcaseTrial =
    siteData?.stripe?.subscriptions?.length === 0 && hasActiveSubscription

  const isLoading =
    (subscriptionData === undefined && isBillingSubscriptionLoading) ||
    (siteData === undefined && isSiteLoading)
  const isError = isBillingSubscriptionError || isSiteError

  const waitForWebhookCall = searchParams.get('waitForHook')
  const [isWaitingForWebhookCall, setIsWaitingForWebhookCall] = useState(waitForWebhookCall === '1')

  useEffect(() => {
    if (isWaitingForWebhookCall && numberOfActiveChannels > 0 && hasActiveSubscription) {
      setIsWaitingForWebhookCall(false)
    }
  }, [isWaitingForWebhookCall, numberOfActiveChannels, hasActiveSubscription])

  useEffect(() => {
    if (!isWaitingForWebhookCall || !refetchSubscription || !refetchSite) {
      return
    }

    const timer = setInterval(() => {
      if (refetchCount >= MAX_NUMBER_OF_REFETCH || !isWaitingForWebhookCall) {
        clearInterval(timer)
        setIsWaitingForWebhookCall(false)
        return
      }
      setRefetchCount((prevCount) => prevCount + 1)
      refetchSubscription()
      refetchSite()
    }, SUBSCRIPTION_REFETCH_INTERVAL)

    return () => {
      clearInterval(timer)
    }
  }, [refetchCount, refetchSubscription, refetchSite, isWaitingForWebhookCall])

  return {
    activeSubscriptionChannels: subscriptionData?.map((subscription) => subscription.type),
    hasActiveSubscription,
    hasStripeCustomer,
    isError,
    isLoading,
    isShopifyShowcase,
    isShopifyShowcaseTrial,
    nextBillingCycleStartsAt,
    subscriptionData
  }
}
