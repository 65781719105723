import { type HttpResponse, type OrderService } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useOrderTags(
  subscriberId: string
): UseResource<OrderService.GetTagsResponse['data']> {
  const queryName = [QUERY_NAME.orderTags, subscriberId]

  const { isLoading, data } = useQuery<
    HttpResponse<OrderService.GetTagsResponse['data']>,
    AxiosError
  >(queryName, async () =>
    request.get<HttpResponse<OrderService.GetTagsResponse['data']>>(
      `subscribers/${subscriberId}/order-tags`
    )
  )

  return {
    isLoading,
    data: data?.data
  }
}
