import {
  BillingStatus,
  Channel,
  type OptinToolListView,
  type SiteEngineSlug,
  type SMSSettings,
  StripeSubscription
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment/moment'
import siteEngines from './siteengines.json'

export function classify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/\./g, '-')
}

export function slugify(text = ''): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function isExternalUrl(path: string): boolean {
  try {
    const url = new URL(path)
    return window.location.origin !== url.origin
  } catch {
    return false
  }
}

export const dateFormatURL = 'YYYY-MM-DDTHH_mm'
export const dateFormatDatePicker = 'MM/DD/YYYY'
export const dateFormat = 'MMM D, YYYY'
export const dateFormatShort = 'MMM D'
export const dateTimeFormat = 'MMM D, YYYY HH:mm'
export const dateTimeFormat12hours = 'MMM D, YYYY h:mm A'
export const timeFormat = 'h:mm a'
export const quietHoursTimeFormat = 'h:mm A'

export function getUsedSiteEngines(): { id: string; slug: SiteEngineSlug }[] {
  const neededEngines = ['shopify', 'woocommerce']
  const engineProps = {
    shopify: {
      name: 'Shopify',
      slug: 'shopify'
    },
    woocommerce: {
      url: 'https://wordpress.org/plugins/ghostmonitor/',
      name: 'WooCommerce',
      slug: 'woocommerce'
    }
  }
  return siteEngines
    .filter((obj) => neededEngines.includes(obj.slug))
    .sort((e1, e2) => neededEngines.indexOf(e1.slug) - neededEngines.indexOf(e2.slug))
    .map((e) => ({ slug: e.slug, id: e._id, ...engineProps[e.slug] }))
}

export function getComponentDisplayName(component: React.ComponentType): string {
  return component.displayName || component.name || 'Component'
}

export function convertObjectToFilterValues(data: Record<string, string>) {
  return Object.entries(data).map(([key, label]) => ({ value: key, label }))
}

export function isLightColor(hexCode: string) {
  const c = hexCode.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  return luma > 240
}

export function getOptinToolsBySequenceId(
  optinTools: OptinToolListView[] | undefined,
  sequenceId: string | null
): OptinToolListView[] {
  return optinTools?.filter((optinTool) => sequenceId && optinTool.sequenceId === sequenceId) ?? []
}

export function getDisplayedCountryCode(countryCode: string): string {
  if (countryCode === 'GB') {
    return 'UK'
  }

  return countryCode
}

export function getSortedPhoneNumbers(numbers: SMSSettings.PhoneNumberConfig[] | undefined) {
  if (!numbers) {
    return []
  }

  return cloneDeep(numbers).sort((a, b) => {
    if (a.type === 'short-code' && b.type !== 'short-code') {
      return -1
    }

    if (a.type !== 'short-code' && b.type === 'short-code') {
      return 1
    }

    const countryCodeA = a.supportedCountries[0].countryCode
    const countryCodeB = b.supportedCountries[0].countryCode

    return getDisplayedCountryCode(countryCodeA).localeCompare(
      getDisplayedCountryCode(countryCodeB)
    )
  })
}

export function getTimezoneOffset(timezone: string | undefined): string {
  if (!timezone) {
    return ''
  }

  return moment.tz(timezone).format('Z')
}
