import { hooks } from '../../../../hooks/hooks'
import { useUniqueCustomWelcomeFlowNameValidator } from '../../../../hooks/use-unique-welcome-flow-validator'
import { SaveModal } from '../save-modal/save-modal.component'

export function CustomWelcomeSaveModal() {
  const { validate: validateUniqueCustomWelcomeFlowName } =
    useUniqueCustomWelcomeFlowNameValidator()

  const hasMMS = hooks.useHasMMS()
  const hasInternationalPhoneNumber = hooks.useHasInternationPhoneNumber()
  return (
    <SaveModal
      validator={validateUniqueCustomWelcomeFlowName}
      title='Please add a name to your flow'
      note={
        hasMMS && hasInternationalPhoneNumber
          ? 'Subscribers outside of US and Canada will only receive the text part of the message without attachments (as an SMS).'
          : undefined
      }
    />
  )
}
