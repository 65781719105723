import cn from 'classnames'
import truncate from 'lodash/truncate'
import { useState } from 'react'
import { ReactComponent as TagCloseIcon } from '../../../static/images/svg/ui-kit/tag-close-icon.svg'
import { Tooltip } from '../tooltip/tooltip.component'
import styles from './tag.component.scss'

export interface TagProps {
  closable?: boolean
  onClose?: React.MouseEventHandler
  className?: string
  maxLength?: number
  type?: 'error' | 'default' | 'warning'
  size?: 'normal' | 'large'
  children: React.ReactNode
}

export function Tag(props: TagProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  function handleTooltipVisible(visible: boolean): void {
    const label = props.children as string

    if (props.maxLength && label.length > props.maxLength) {
      setIsTooltipVisible(visible)
    }
  }

  return (
    <Tooltip
      title={props.children}
      placement='bottom'
      onOpenChange={handleTooltipVisible}
      open={isTooltipVisible}
    >
      <span
        className={cn(
          styles.container,
          styles[props.size ?? 'normal'],
          'flex items-center justify-between text-sm font-semibold whitespace-nowrap rounded-sm px-2',
          props.className,
          {
            'bg-grey-2 text-secondary': props.type === 'default' || !props.type,
            'bg-light-red-2 text-red-2': props.type === 'error',
            'bg-light-yellow-1 text-yellow-1': props.type === 'warning',
            'text-primary': props.type === 'default' && props.size === 'large'
          }
        )}
        data-testid={props['data-testid'] ? `${props['data-testid']}-tag` : 'tag'}
      >
        {props.maxLength !== undefined
          ? truncate(props.children as string, { length: props.maxLength })
          : props.children}
        {props.closable && (
          <TagCloseIcon
            className={cn('icon-tiny ml-2 cursor-pointer shrink-0', {
              'text-secondary': props.type === 'default' || !props.type,
              'text-red-2': props.type === 'error',
              'text-yellow-1': props.type === 'warning',
              'text-primary': props.type === 'default' && props.size === 'large'
            })}
            onClick={props.onClose}
            data-testid={props['data-testid'] ? `${props['data-testid']}-tag-close` : 'tag-close'}
          />
        )}
      </span>
    </Tooltip>
  )
}
