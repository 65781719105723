import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type ModalType } from './modal.type'

export interface ModalState {
  type: ModalType | null
}

export const modalInitialState: ModalState = {
  type: null
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: modalInitialState,
  reducers: {
    showModal(state, action: PayloadAction<{ modalType: ModalType }>) {
      state.type = action.payload.modalType
    },
    hideModal(state, action: PayloadAction<{ modalType: ModalType }>) {
      if (state.type === action.payload.modalType) {
        state.type = null
      }
    }
  }
})

export const { showModal, hideModal } = modalSlice.actions

export const { reducer: modalReducer } = modalSlice
