import {
  CUSTOM_INTEGRATION_SLUG_PREFIX,
  getCustomIntegrationCategoryTag,
  getCustomIntegrationSlugTag,
  getEntryFlowItemTrigger,
  getLegalFlowItemTags,
  getNonEntryFlowItemTags,
  getNonEntryFlowItemTrigger,
  isCustomIntegrationTag,
  Trigger
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors } from '../../../selectors'
import { type ThunkAPI } from '../../../types/thunk-api.type'
import { flowEditorActions } from '../flow-editor.reducer'
import { cascadeFlowProperty } from './change-trigger.thunk'

export const changeCustomIntegrationSettingsThunk = createAsyncThunk(
  'flowEditor/changeCustomIntegrationSettings',
  async (
    args: {
      customIntegrationCategory?: string
      customIntegrationEventType?: string
      customIntegrationSlug?: string
      isQuietHoursEnabled: boolean
    },
    thunkAPI: any
  ) => {
    const { getState, dispatch }: ThunkAPI = thunkAPI

    dispatch(
      flowEditorActions.customIntegrationSettingsChanged({
        isQuietHoursEnabled: args.isQuietHoursEnabled,
        customIntegrationSlug: args.customIntegrationSlug,
        customIntegrationCategory: args.customIntegrationCategory,
        customIntegrationEventType: args.customIntegrationEventType
      })
    )

    const state = getState()
    const flow = cloneDeep(flowEditorSelectors.selectEditorFlow(state))
    const flowMeta = flowEditorSelectors.selectEditorFlowMeta(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    const entryFlowItem = flow.sequenceItems.find(
      (flowItem) => flowItem._id === flow.entrySequenceItemId
    )

    const tags = [...flow.tags.filter((tag) => !isCustomIntegrationTag(tag))]

    if (args.customIntegrationCategory) {
      tags.push(getCustomIntegrationCategoryTag(args.customIntegrationCategory))
    }

    if (args.customIntegrationSlug) {
      tags.push(getCustomIntegrationSlugTag(args.customIntegrationSlug))
    }

    flow.tags = tags
    flow.isQuietHoursEnabled = args.isQuietHoursEnabled

    flow.sequenceItems.forEach((flowItem) => {
      flowItem.trigger = getNonEntryFlowItemTrigger(flowItem._id)
      flowItem.tags = getNonEntryFlowItemTags(tags)
    })

    if (entryFlowItem) {
      if (args.customIntegrationEventType === 'optin') {
        entryFlowItem.tags = [
          ...new Set([...entryFlowItem.tags, ...getLegalFlowItemTags(flow.tags)])
        ]
      }

      const entryFlowItemTriggers = getEntryFlowItemTrigger(
        Trigger.customIntegration,
        flow._id,
        entryFlowItem._id
      )

      let entryTriggers: string[] = []
      if (args.customIntegrationSlug) {
        entryTriggers = entryFlowItemTriggers.filter(
          (trigger) => !trigger.startsWith(CUSTOM_INTEGRATION_SLUG_PREFIX)
        )
        entryTriggers.push(getCustomIntegrationSlugTag(args.customIntegrationSlug))
      }
      entryFlowItem.trigger = entryTriggers
    }

    cascadeFlowProperty(flow, 'isQuietHoursEnabled', args.isQuietHoursEnabled)

    dispatch(
      flowEditorActions.flowLoaded({
        flow,
        // TODO figure out
        source: 'server',
        isEverSaved: false,
        unsavedChanges: true,
        isPreviewOn: flowMeta.isPreviewOn,
        isAITextGeneratorWizardClosed: flowMeta.isAITextGeneratorWizardClosed,
        isLegalFlowItemDeletable: flowMeta.isLegalFlowItemDeletable,
        isWelcomeFlowWarningModalClosed: flowMeta.isWelcomeFlowWarningModalClosed
      })
    )
  },
  { serializeError }
)
