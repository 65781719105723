import { useSMSEstimation as useSMSEstimationSE } from '../routes/SequenceEditor/components/blast-save-and-activate-modal/hooks/use-sms-estimation.hook'
import { useEditorSequenceItemStat } from '../routes/SequenceEditor/hooks/resource/use-editor-sequence-item-stat'
import {
  useEditorSequenceItemsStat,
  useEditorSequenceItemsStatCache
} from '../routes/SequenceEditor/hooks/resource/use-editor-sequence-items-stat'
import { useEditorFacebookCommentPostId } from '../routes/SequenceEditor/hooks/use-editor-post-id'
import { useSequenceEditorSettings } from '../routes/SequenceEditor/hooks/use-sequence-editor-settings'
import { useSMSCampaignStatus } from '../routes/flow-editor/canvas/hooks/use-sms-campaign-status'
import { useSMSEstimation as useSMSEstimationFER } from '../routes/flow-editor/general/sms-campaign-save-and-activate-modal/hooks/use-sms-estimation.hook'
import { useDiscountCodePoolValidation } from '../routes/flow-editor/hooks/use-discount-code-pool-validation'
import { useFeatureFlagByFlowId } from '../routes/flow-editor/hooks/use-feature-flag-by-flow-id'
import { useFlowEditorFlowItemStat } from '../routes/flow-editor/hooks/use-flow-editor-flow-item-stat'
import { useFlowIsLoaded } from '../routes/flow-editor/hooks/use-flow-is-loaded'
import { useFlowLatest } from '../routes/flow-editor/hooks/use-flow-latest.hook'
import { useFlowTemplate } from '../routes/flow-editor/hooks/use-flow-template.hook'
import { useAchievements } from './resources/use-achievements'
import { useAggregatedSubscribersChart } from './resources/use-aggregated-subscribers-chart'
import { useAISegmentIds } from './resources/use-ai-segment-ids'
import { useAISegments } from './resources/use-ai-segments-list'
import { useApiKey } from './resources/use-api-key-by-id'
import { useApiKeys } from './resources/use-api-keys'
import { useAuth } from './resources/use-auth'
import { useAutomatedSMSStat } from './resources/use-automated-sms-stat'
import { useAvailableCountries } from './resources/use-available-countries'
import { useBackfill } from './resources/use-backfill'
import { useBillingHistory } from './resources/use-billing-history'
import { useBillingSubscriptions } from './resources/use-billing-subscriptions'
import { useBlastCache } from './resources/use-blast-cache'
import { useBlastStatus } from './resources/use-blast-status'
import { useBlasts } from './resources/use-blasts'
import { useBlastsCache } from './resources/use-blasts-cache'
import { useBlastsStat } from './resources/use-blasts-stat'
import { useCurrentBillingPeriods } from './resources/use-current-billing-periods'
import { useCustomIntegrations } from './resources/use-custom-integrations'
import { useDiscountCode } from './resources/use-discount-code'
import { useDiscountCodePool } from './resources/use-discount-code-pool'
import { useDiscountCodePools } from './resources/use-discount-code-pools'
import { useDiscountCodes } from './resources/use-discount-codes'
import { useEmailStatWithoutContext } from './resources/use-email-stat-without-context'
import { useFacebookPage } from './resources/use-facebook-page'
import { useFacebookPost } from './resources/use-facebook-post'
import { useFlow } from './resources/use-flow'
import { useFlows } from './resources/use-flows'
import { useFlowsByTags } from './resources/use-flows-by-tag'
import { useFlowsCache } from './resources/use-flows-cache'
import { useHasUsedFacebookPage } from './resources/use-has-used-facebook-page'
import { useIntegration } from './resources/use-integration'
import { useLeasablePhoneNumber } from './resources/use-leasable-phone-number'
import { useLeasablePhoneNumbers } from './resources/use-leasable-phone-numbers'
import { useLeasablePhoneNumbersCount } from './resources/use-leasable-phone-numbers-count'
import { useMe } from './resources/use-me'
import { useMeCache } from './resources/use-me-cache'
import { useMediaUploads } from './resources/use-media-uploads'
import { useMessengerStat } from './resources/use-messenger-stat'
import { useMessengerSubscription } from './resources/use-messenger-subscription'
import { useOptinTool } from './resources/use-optin-tool'
import { useOptinToolEditor } from './resources/use-optin-tool-editor'
import { useOptinToolExperimentCreate } from './resources/use-optin-tool-experiment-create'
import { useOptinToolExperiments } from './resources/use-optin-tool-experiments'
import { useOptinToolFontCreate } from './resources/use-optin-tool-font-create'
import { useOptinToolFonts } from './resources/use-optin-tool-fonts'
import { useOptinTools } from './resources/use-optin-tools'
import { useOptinToolsByFlowId } from './resources/use-optin-tools-by-flow-id'
import { useOptinToolsList } from './resources/use-optin-tools-list'
import { useOptinToolsStat } from './resources/use-optin-tools-stat'
import { useOrderTags } from './resources/use-order-tags'
import { usePhoneNumbersCurrentPrices } from './resources/use-phone-numbers-current-prices'
import { usePollBillingStatus } from './resources/use-poll-billing-status'
import { usePollBillingSubscriptions } from './resources/use-poll-billing-subscriptions'
import { usePollSMSPhoneNumbers } from './resources/use-poll-sms-phone-numbers'
import { useProductImages } from './resources/use-product-images'
import { useProductTags } from './resources/use-product-tags'
import { useProductTypes } from './resources/use-product-types'
import { useProductVariants } from './resources/use-product-variants'
import { useProductVariantsById } from './resources/use-product-variants-by-id'
import { useProducts } from './resources/use-products'
import { useProductsById } from './resources/use-products-by-id'
import { useRecentCampaignFlowsList } from './resources/use-recent-campaign-flows-list'
import { useSalesStackedChart } from './resources/use-sales-stacked-chart'
import { useSiteTotalSalesStatistics } from './resources/use-sales-total-alltime'
import { useSegment } from './resources/use-segment'
import { useSegmentCreate } from './resources/use-segment-create'
import { useSegmentDelete } from './resources/use-segment-delete'
import { useSegmentListDuplicate } from './resources/use-segment-list-duplicate'
import { useSegmentListUpdateSubscribers } from './resources/use-segment-list-update-subscribers'
import { useSegmentRecalculation } from './resources/use-segment-recalculation'
import { useSegmentsList } from './resources/use-segments-list'
import { useSequence } from './resources/use-sequence'
import { useSequenceGroupByTags } from './resources/use-sequence-group-by-tags'
import { useSequenceGroups } from './resources/use-sequence-groups'
import { useSequenceGroupsByTags } from './resources/use-sequence-groups-by-tags'
import { useSequenceGroupStats } from './resources/use-sequence-groups-stat'
import { useSequenceItem } from './resources/use-sequence-item'
import {
  useSequenceItemsStat,
  useSequenceItemsStatCache
} from './resources/use-sequence-items-stat'
import { useSequenceStat } from './resources/use-sequence-stat'
import { useSequences } from './resources/use-sequences'
import { useSequencesByTags } from './resources/use-sequences-by-tags'
import { useSequencesCache } from './resources/use-sequences-cache'
import { useSequencesStat } from './resources/use-sequences-stat'
import { useSite } from './resources/use-site'
import { useSmsBalance } from './resources/use-sms-balance'
import { useSMSCampaignCache } from './resources/use-sms-campaign-cache'
import { useSMSCampaigns } from './resources/use-sms-campaigns'
import { useSMSConversation } from './resources/use-sms-conversation'
import { useSMSConversationWithOptimisticSending } from './resources/use-sms-conversation-with-optimistic-sending'
import { useSMSConversations } from './resources/use-sms-conversations'
import { useSMSConversationsWithDraft } from './resources/use-sms-conversations-with-draft'
import { useSMSPhoneNumber } from './resources/use-sms-phone-number'
import { useSMSPhoneNumbers } from './resources/use-sms-phone-numbers'
import { useSMSQuietHoursSettings } from './resources/use-sms-quiet-hours-settings'
import { useSmsRollingBalance } from './resources/use-sms-rolling-balance'
import { useSMSSettings } from './resources/use-sms-settings'
import { useSMSSmartSendingSettings } from './resources/use-sms-smart-sending-settings'
import { useSMSStat } from './resources/use-sms-stat'
import { useSMSSubscription } from './resources/use-sms-subscription'
import { useSMSTestPhoneNumbers } from './resources/use-sms-test-phone-numbers'
import { useSMSTimezoneDeviation } from './resources/use-sms-timezone-deviation'
import { useSMSUsage } from './resources/use-sms-usage'
import { useSMSWelcomeFlowStatus } from './resources/use-sms-welcome-flow-status'
import { useSubdomains } from './resources/use-subdomains'
import { useSubdomainAvailability } from './resources/use-subdomains-availability'
import { useSubscriber } from './resources/use-subscriber'
import { useSubscriberCleanup } from './resources/use-subscriber-cleanup'
import { useSubscriberDetails } from './resources/use-subscriber-details'
import { useSubscriberDetailsCreate } from './resources/use-subscriber-details-create'
import { useSubscriberImport } from './resources/use-subscriber-import'
import { useSubscriberImportList } from './resources/use-subscriber-import-list'
import { useSubscriberImportSample } from './resources/use-subscriber-import-sample'
import { useSubscribersCount } from './resources/use-subscribers-count'
import { useSubscribersList } from './resources/use-subscribers-list'
import { useSubscribersOverview } from './resources/use-subscribers-overview'
import { useSubscribersStackedChart } from './resources/use-subscribers-stacked-chart'
import { useSubscriptionSourcesStat } from './resources/use-subscription-sources-stat'
import { useTemplatesPopularity } from './resources/use-templates-popularity'
import { useThumbnail } from './resources/use-thumbnail'
import { useUser } from './resources/use-user'
import { useWebhookSecrets } from './resources/use-webhook-secrets'
import { useBillingInfo } from './use-billing-info'
import { useCustomWelcomeSequences } from './use-custom-welcome-sequences'
import { useDiscountCodesAttachedToFlow } from './use-discount-codes-attached-to-flow.hook'
import { useDiscountPoolAttachedToSequence } from './use-discount-pool-attached-to-sequence.hook'
import { useAttachableDiscountCodePoolsToFlow } from './use-discount-pools-to-link.hook'
import { useFacebookSelector } from './use-facebook-selector'
import { useFeatureFlag } from './use-feature-flag'
import { useFlowTrigger } from './use-flow-trigger'
import { useHasInternationPhoneNumber } from './use-has-international-phone-numbers'
import { useHasKeywordReplies } from './use-has-keyword-replies'
import { useHasMMSFER } from './use-has-mms-fer.hook'
import { useHasMMS } from './use-has-mms.hook'
import { useIsLeasePossible } from './use-is-lease-possible'
import { useIsMMSImageResizingEnabled } from './use-is-mms-image-resizin-enabled'
import { useJoinedASegmentFlowCheck } from './use-joined-a-segment-flow-check'
import { useLandingPageCode } from './use-landing-page-code'
import { useOptinToolsListData } from './use-optin-tools-list-data'
import { useOptinToolsListWithStats } from './use-optin-tools-list-with-stats'
import { usePhoneNumberTypes } from './use-phone-number-types'
import { useUsedKeywordNameValidator } from './use-reserved-keyword-name-validator'
import { useSiteSelector } from './use-site-selector'
import { useAvailableSubscriberDetailsForSubscriberImport } from './use-subscriber-details-for-import'
import { useSupportedSMSCountryCodes } from './use-supported-country-codes'
import { useSupportedSiteEngines } from './use-supported-site-engines'
import { useDiscountPoolSizeAndAvailability } from './use-unique-discount-pool-size.hook'
import { useWindowDimensions } from './use-window-dimensions'
import { useCampaignTargetAudience } from './use-campaign-target-audience'
import { useCallForwarding } from './resources/use-call-forwarding'

export const hooks = {
  useAchievements,
  useApiKeys,
  useApiKey,
  useAuth,
  useAvailableCountries,
  useAvailableSubscriberDetailsForSubscriberImport,
  useSupportedSMSCountryCodes,
  useBillingInfo,
  useBillingSubscriptions,
  useBillingHistory,
  useCallForwarding,
  useSmsBalance,
  useSmsRollingBalance,
  useBlastCache,
  useBlastStatus,
  useBlasts,
  useBlastsCache,
  useBlastsStat,
  useLeasablePhoneNumber,
  usePhoneNumbersCurrentPrices,
  useCustomWelcomeSequences,
  useDiscountCodes,
  useDiscountCode,
  useDiscountCodePools,
  useDiscountCodePool,
  useDiscountPoolSizeAndAvailability,
  useDiscountCodePoolValidation,
  useDiscountPoolAttachedToSequence,
  useDiscountCodesAttachedToFlow,
  useAttachableDiscountCodePoolsToFlow,
  useEditorFacebookCommentPostId,
  useEditorSequenceItemsStat,
  useEditorSequenceItemsStatCache,
  useEditorSequenceItemStat,
  useEmailStatWithoutContext,
  useFacebookSelector,
  useFacebookPost,
  useFeatureFlag,
  useFeatureFlagByFlowId,
  useFacebookPage,
  useFlowEditorFlowItemStat,
  useFlow,
  useFlows,
  useFlowsCache,
  useFlowTemplate,
  useFlowTrigger,
  useFlowLatest,
  useFlowIsLoaded,
  useFlowsByTags,
  useHasUsedFacebookPage,
  useHasInternationPhoneNumber,
  useHasMMS,
  useHasMMSFER,
  useHasKeywordReplies,
  useIntegration,
  useIsMMSImageResizingEnabled,
  useJoinedASegmentFlowCheck,
  useLandingPageCode,
  useLeasablePhoneNumbers,
  useLeasablePhoneNumbersCount,
  useMe,
  useMediaUploads,
  useMeCache,
  useMessengerStat,
  useMessengerSubscription,
  useOptinToolsList,
  useOptinToolsListData,
  useOptinToolsListWithStats,
  useOptinTools,
  useOptinToolsStat,
  useOptinToolEditor,
  useOptinToolExperiments,
  useOptinToolExperimentCreate,
  useOptinToolFonts,
  useOptinToolFontCreate,
  useOptinTool,
  useOptinToolsByFlowId,
  usePollBillingSubscriptions,
  usePollBillingStatus,
  usePollSMSPhoneNumbers,
  useProductTags,
  useProductTypes,
  useProducts,
  useProductsById,
  useProductVariants,
  useProductVariantsById,
  useProductImages,
  useRecentCampaignFlowsList,
  useSalesStackedChart,
  useAISegments,
  useAISegmentIds,
  useSegment,
  useSegmentCreate,
  useSegmentListDuplicate,
  useSegmentListUpdateSubscribers,
  useSegmentsList,
  useSegmentDelete,
  useSequenceEditorSettings,
  useSite,
  useCustomIntegrations,
  useSiteSelector,
  useSequence,
  useSequenceItem,
  useSequenceItemsStat,
  useSequenceItemsStatCache,
  useSequences,
  useSequencesByTags,
  useSequencesCache,
  useSequenceStat,
  useSequencesStat,
  useSequenceGroups,
  useSequenceGroupStats,
  useSequenceGroupsByTags,
  useSequenceGroupByTags,
  useSMSCampaignStatus,
  useSMSCampaignCache,
  useSMSCampaigns,
  useSMSConversations,
  useSMSConversationsWithDraft,
  useSMSConversation,
  useSMSConversationWithOptimisticSending,
  useSMSEstimationSE,
  useSMSEstimationFER,
  useSMSTestPhoneNumbers,
  useSMSTimezoneDeviation,
  useSMSPhoneNumbers,
  useSMSPhoneNumber,
  usePhoneNumberTypes,
  useSMSSettings,
  useSMSSubscription,
  useSMSStat,
  useAutomatedSMSStat,
  useSMSUsage,
  useSubdomains,
  useSubdomainAvailability,
  useSubscriber,
  useSubscriberCleanup,
  useSubscriberDetails,
  useSubscriberDetailsCreate,
  useSubscribersStackedChart,
  useSubscriberImport,
  useSubscriberImportList,
  useSubscriberImportSample,
  useSubscribersCount,
  useSubscribersList,
  useSubscribersOverview,
  useSubscriptionSourcesStat,
  useAggregatedSubscribersChart,
  useSupportedSiteEngines,
  useCampaignTargetAudience,
  useTemplatesPopularity,
  useThumbnail,
  useUser,
  useBackfill,
  useSegmentRecalculation,
  useSMSQuietHoursSettings,
  useSMSSmartSendingSettings,
  useWindowDimensions,
  useSMSWelcomeFlowStatus,
  useOrderTags,
  useCurrentBillingPeriods,
  useWebhookSecrets,
  useUsedKeywordNameValidator,
  useSiteTotalSalesStatistics,
  useIsLeasePossible
}
