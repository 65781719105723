import styles from '../facebook-account-list.component.scss'

export function ShimmerRow() {
  return (
    <div className={styles['shimmer-row-container']}>
      <div className={styles['shimmer-row-account']}>
        <div className={styles['shimmer-row-name']} />
      </div>
    </div>
  )
}
