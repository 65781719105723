import {
  type SubscriberImport,
  type SubscriberImportListResponse,
  SubscriberImportStatus
} from '@ghostmonitor/recartapis'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSubscriberImportList(segmentId?: string): UseResource<SubscriberImport[]> {
  const queryName = [QUERY_NAME.subscriberImportList, segmentId]
  const queryClient = useQueryClient()

  function getHasImportInProgress(data: SubscriberImport[]) {
    return data?.some((item) => item.status === SubscriberImportStatus.IMPORTING)
  }

  const { isLoading, data } = useQuery(
    queryName,
    async () => {
      return request.get<SubscriberImportListResponse>('subscriber-imports', {
        params: { segmentId }
      })
    },
    {
      onSuccess: (data) => {
        const hasImportInProgress = getHasImportInProgress(data?.data)
        if (!hasImportInProgress) {
          queryClient.invalidateQueries([QUERY_NAME.subscribersOverview])
          queryClient.invalidateQueries([QUERY_NAME.subscribersStackedChart])
          queryClient.invalidateQueries([QUERY_NAME.subscribersList])
          if (segmentId) {
            queryClient.invalidateQueries([QUERY_NAME.segment, segmentId])
          }
        }
      },
      refetchInterval: (data) => {
        const hasImportInProgress = getHasImportInProgress(data?.data)
        if (hasImportInProgress) {
          return 5000
        } else {
          return false
        }
      }
    }
  )

  return {
    isLoading,
    data: data?.data
  }
}
