import {
  type BlastScheduleType,
  type ConditionalSplitFlowItemUI,
  type ConditionalSplitV2FlowItemUI,
  type DelayFlowItemUI,
  DelayType,
  type DelayUnit,
  type DiscountCodePool,
  type ExpressionUI,
  type FlowDiagram,
  type FlowItemUI,
  type FlowUI,
  getFlowTrigger,
  getIsQuietHoursEnabledByFlowItemId,
  getNonEntryFlowItemTags,
  getNonEntryFlowItemTrigger,
  isFulfillmentTrigger,
  isLegalFlowItem,
  isLoopWorkTrigger,
  isRechargeTrigger,
  isWondermentTrigger,
  isYotpoTrigger,
  LOGIC_PORT_CONDITION_MET,
  LOGIC_PORT_CONDITION_UNMET,
  type LogicConditionalSplitConditionAPI,
  type LogicConditionalSplitV2API,
  type LogicSplitVariantAPI,
  MessageType,
  NodeType,
  type OptinTool,
  PortType,
  type RandomSplitFlowItemUI,
  type SendingFrequency,
  SequenceTag,
  SMSCampaignStatus,
  type SMSMessageContactCardUI,
  type SMSMessageFlowItemUI,
  type SMSMessageMMSUI,
  type SMSMessageTextUI,
  type TargetRulesSMSCampaign,
  Trigger,
  type Variable
} from '@ghostmonitor/recartapis'
import { createSlice, type DeepPartial, isAnyOf, type PayloadAction } from '@reduxjs/toolkit'
import { type BaseEmoji, type EmojiData } from 'emoji-mart'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { addEdge, applyEdgeChanges, type Connection } from 'reactflow'
import {
  DEFAULT_CONDITION,
  DEFAULT_EXPRESSION,
  InsertType
} from '../../../routes/flow-editor/flow-editor-config'
import { type SegmentCampaignText } from '../../../routes/flow-editor/types/ai-text-generation-types'

import { generateLogicHandleName } from '../../../routes/flow-editor/utils/generate-logic-handle-name'
import { getNewFlowItem } from '../../../routes/flow-editor/utils/get-new-flow-item'
import { patchLocalOptinTool } from '../../../routes/optin-tools/utils/localstorage'
import { type FlowEditorSettingsContextType } from '../../../types/flow-editor/flow-editor-settings.context.type'
import {
  discountCodeValidationErrors,
  FlowEditorError,
  FlowEditorErrorLevel,
  flowItemValidationErrors,
  flowValidationErrors,
  messageValidationErrors,
  smsCampaignValidationErrors
} from '../../../utils/flow-editor/flow-editor-errors'
import { flowItemHasDiscountCode } from '../../../utils/flow-item-has-discount-code'
import { getNewSMSCampaign } from '../../../utils/get-new-sms-campaign'
import {
  isStaticDiscountCodeVariable,
  isUniqueDiscountCodeVariable
} from '../../../utils/inline-editor/inline-editor-utils'
import {
  convertHTMLtoPlainText,
  removeHTMLEntities
} from '../../../utils/rich-text-editor/convert-html'
import {
  getIndexFromId,
  getMessageVariablesCaretPosition,
  getVariableFromId
} from '../../../utils/rich-text-editor/get-variables'
import { insertContentAtCaretPosition } from '../../../utils/rich-text-editor/insert-content'
import { removeVariableByIndex } from '../../../utils/rich-text-editor/remove-variable'
import { replaceHtmlValueToRawValue } from '../../../utils/rich-text-editor/replace-html-value-to-raw-value'
import { getHtmlFromVariable } from '../../../utils/rich-text-editor/replace-raw-value-to-html-value'
import {
  isDiscountUrlVariable,
  isKeywordReplyVariable
} from '../../../utils/rich-text-editor/variable-assertion'
import {
  supportEmailVariableOption,
  variableOptionNames
} from '../../../utils/rich-text-editor/variable-type-options'
import { meLoaded } from '../me/me.reducer'
import { type OptinToolMeta } from '../optin-tool-editor/optin-tool-editor.reducer'
import {
  discountCodeValidationFailed,
  discountCodeValidationSucceeded,
  flowItemValidationFailed,
  flowItemValidationSucceeded,
  flowValidationFailed,
  flowValidationSucceeded,
  localDraftChanged,
  messageValidationFailed,
  messageValidationSucceeded,
  nodeRemoved,
  type PayloadDiscountCodeValidationFailed,
  type PayloadFlowItemValidationFailed,
  type PayloadFlowValidationFailed,
  type PayloadMessageValidationFailed,
  type PayloadSmsCampaignValidationFailed,
  isInQuietHoursByFlowItemIdCalculated,
  quietHoursSettingsLoaded,
  removeDeprecatedConditionalSplitClicked,
  settingsReady,
  smsCampaignValidationFailed,
  smsCampaignValidationSucceeded
} from './flow-editor.actions'
import {
  selectEditorFlow,
  selectEditorFlowMeta,
  selectEditorSMSCampaign,
  selectEditorSMSCampaignMeta,
  selectFlowItem,
  selectFlowItemMeta,
  selectMessage,
  selectMessageMeta,
  selectNodeByFlowItemId,
  selectNodeById,
  selectSettings,
  selectSite,
  selectTriggerNode,
  selectVariablePlaceholder
} from './flow-editor.selectors'
import {
  AutoAlignOnNodesChangeStatus,
  EditPanelType,
  flowEditorInitialState,
  type FlowError,
  InsertNodePanelType,
  SecondaryEditPanelType,
  smsCampaignInitialMeta
} from './flow-editor.state'
import {
  autoAlignFlowDiagram,
  fixForcedCaretPositionAfterSiteName,
  handleConditionalSplitV2FlowItemLoaded,
  removeEditingVariable,
  replaceRawValueToCanvasHtmlValue,
  replaceRawValueToHtmlValue,
  reRenderRTEContent,
  resetEditingVariable,
  resetEditPanel,
  resetNodeTypeSelector,
  resetSecondaryEditPanel
} from './helpers'
import { deleteNode } from './helpers/delete-node'
import { attachEntryFlowItem, detachEntryFlowItem } from './helpers/entry-flow-item'
import { initFlowItemMeta, insertFlowItem } from './helpers/insert-flow-item'
import { insertNode, insertNodeAfterNode, insertNodeBetweenNodes } from './helpers/insert-node'
import { reactFlowInternals } from './helpers/react-flow-internals'
import {
  addSMSWelcomeLegalMessage,
  removeSMSWelcomeLegalMessage
} from './helpers/sms-welcome-legal-message'
import { updateFlowItemMessageMeta } from './helpers/update-flow-item-messageMeta'
import { updateFlowItemsTrigger } from './helpers/update-flow-item-trigger'
import { loadSMSCampaignThunk } from './thunks/load-sms-campaign.thunk'

import { ObjectId } from 'bson'
import { isSMSCampaignReadonly } from '../../../routes/flow-editor/utils/is-sms-campaign-read-only'
import { convertFlowItemUIToFlowItem } from '../../../types/flow-editor/convert-flow-item-ui-to-flow-item'
import {
  hasKeywordRepliesOnFlowItem,
  isConditionalSplitV2FlowItemUI,
  isDelayFlowItemUI,
  isSMSMessageFlowItemUI
} from '../../../types/guards/flow-item-ui.guards'
import {
  loadDelayScheduledTo,
  saveDelayScheduledTo
} from '../../../utils/delay-scheduled-date-handling'
import { oneHourInNanosecs } from '../../../utils/time-conversion'
import { duplicateSMSCampaignThunk } from '../../modules/sms-campaign/duplicate-sms-campaign.thunk'
import { hideModal } from '../modal/modal.reducer'
import { ModalType } from '../modal/modal.type'
import { addAITags } from './helpers/add-AI-tags'
import {
  addAIGeneratedSMSMessageFlowItem,
  addConditionalSpLitV2FlowItemWithMemberOfSegmentContent
} from './helpers/insert-ai-generated-flow-items'
import { setScheduledDelayFlowItemsToDefault } from './helpers/set-delay-flow-items-to-default'
import {
  updateFlowItemDiscountCodes,
  updateFlowItemDiscountPools,
  updateFlowItemsDiscountCode
} from './helpers/update-flow-item-discount-code'
import { updateFlowItemsIsQuietHoursEnabled } from './helpers/update-flow-item-is-quiet-hours-enabled'
import { updateKeywordRepliesOnFlowItem } from './helpers/update-flow-item-keyword-replies'
import { updateKeywordRepliesHandles } from './helpers/update-flow-item-keyword-reply-handles'
import { updateKeywordReplyTagOnFlowItem } from './helpers/update-keyword-reply-tag-on-flow-item'
import { saveFlowThunk } from './thunks/save-flow.thunk'
import { saveSMSCampaignThunk } from './thunks/save-sms-campaign.thunk'
import { toggleFlowEditorFlowThunk } from './thunks/toggle-flow-editor-flow.thunk'
import { IsYotpoReviewTrigger } from '@ghostmonitor/recartapis/cjs/typescript-types/guards/trigger.guards'

export const flowEditorSlice = createSlice({
  name: 'flowEditor',
  initialState: cloneDeep(flowEditorInitialState),
  reducers: {
    autoAlign: (state) => {
      autoAlignFlowDiagram(state)
    },
    messageChanged: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        textHTML: string
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)
      const flowEditorSettings = selectSettings(state)

      // TODO refactor this logic somewhere else
      const shouldPreventContentBeforeSiteName =
        !flowEditorSettings.isCustomMessageHeaderEnabled &&
        /^{{site\.name\|required}}/g.test(message.text) &&
        messageMeta.caretPosition === 1
      if (shouldPreventContentBeforeSiteName) {
        messageMeta.caretPosition = 0
        return
      }

      const actionTextHtml = action.payload.textHTML
      message.text = replaceHtmlValueToRawValue(actionTextHtml)

      // TODO refactor this logic somewhere else
      if (
        !flowEditorSettings.isCustomMessageHeaderEnabled &&
        !/^{{site\.name\|required}}/g.test(message.text)
      ) {
        message.text = `{{site.name|required}}${message.text}`
        messageMeta.caretPosition = 2
      }

      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    caretPositionChanged: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        caretPosition: number
      }>
    ) => {
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      messageMeta.caretPosition = action.payload.caretPosition
    },
    delayItemChanged: (
      state,
      action: PayloadAction<{
        flowItemId: string
        type: `${DelayType}`
        delayUnit?: DelayUnit
        delayValue?: number
        scheduledDate?: string
        scheduleType?: BlastScheduleType
      }>
    ) => {
      const flowItem = selectFlowItem<DelayFlowItemUI>(action.payload.flowItemId)(state)
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)

      if (!flowItem || !node) {
        return
      }

      if (action.payload.type === DelayType.delayUntil) {
        throw new Error('Delay until is not supported on the frontend')
      }

      if (action.payload.type === DelayType.delay) {
        if (!action.payload.delayValue) {
          throw new Error('delayValue is not defined')
        }
        flowItem.item.logic.delay.delay_duration = action.payload.delayValue
        node.data.delayUnit = action.payload.delayUnit
        flowItem.item.logic.delay.type = DelayType.delay
        delete flowItem.item.logic.delay.scheduled_date
        delete flowItem.item.logic.delay.schedule_type
      }

      if (action.payload.type === DelayType.delayScheduled) {
        if (!action.payload.scheduledDate) {
          throw new Error('Scheduled date is not defined')
        }
        flowItem.item.logic.delay.scheduled_date = action.payload.scheduledDate
        flowItem.item.logic.delay.type = DelayType.delayScheduled
        flowItem.item.logic.delay.schedule_type = action.payload.scheduleType
        // todo: delete flowItem.item.logic.delay.delay_duration when the type refactor is done
        // delete flowItem.item.logic.delay.delay_duration
        // we set it to default value as a temporary solution
        flowItem.item.logic.delay.delay_duration = oneHourInNanosecs
        delete node.data.delayUnit
      }
    },
    randomSplitElementValueChanged: (
      state,
      action: PayloadAction<{
        variants: LogicSplitVariantAPI[]
        flowItemId: string
      }>
    ) => {
      const flowItem = selectFlowItem<RandomSplitFlowItemUI>(action.payload.flowItemId)(state)

      if (!flowItem) {
        return
      }

      flowItem.item.logic.split.variants = action.payload.variants
    },
    randomSplitElementDeleted: (
      state,
      action: PayloadAction<{
        variants: LogicSplitVariantAPI[]
        flowItemId: string
        splitIndex: number
      }>
    ) => {
      const flowItem = selectFlowItem<RandomSplitFlowItemUI>(action.payload.flowItemId)(state)
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)
      const flow = selectEditorFlow(state)

      if (!flowItem || !node || !flow) {
        throw new Error('Flow item not found')
      }

      if (node.data.logicHandles === undefined) {
        throw new Error('Node data logicHandles is undefined')
      }

      // flowItem.item.logic.split.variants.splice(action.payload.splitIndex, 1)
      flowItem.item.logic.split.variants = action.payload.variants

      const deletedSourceHandle = node.data.logicHandles.find((handle) => {
        // TODO make this explicitly index based
        return handle.name === `${flowItem._id}-${action.payload.splitIndex}`
      })

      if (!deletedSourceHandle) {
        throw new Error('Deleted source handle not found')
      }

      /**********************************
       * Delete the handle from the node
       **********************************/
      node.data.logicHandles = node.data.logicHandles.filter(
        (handle) => handle.name !== `${flowItem._id}-${action.payload.splitIndex}`
      )

      /*********************************
       * Reindex the handles
       *********************************/
      node.data.logicHandles = node.data.logicHandles.map((handle, index) => ({
        ...handle,
        name: `${flowItem._id}-${index}`
      }))

      /****************************************************************
       * Delete the edges that may are connected to the deleted handle
       ****************************************************************/
      flow.flowEditorDiagram.edges = flow.flowEditorDiagram.edges.filter(
        (edge) => edge.sourceHandle !== deletedSourceHandle.id
      )
    },
    randomSplitElementAdded: (
      state,
      action: PayloadAction<{
        variants: LogicSplitVariantAPI[]
        flowItemId: string
      }>
    ) => {
      const flowItem = selectFlowItem<RandomSplitFlowItemUI>(action.payload.flowItemId)(state)
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)

      if (!flowItem || !node) {
        return
      }

      flowItem.item.logic.split.variants = action.payload.variants
      const newHandleName = generateLogicHandleName(
        action.payload.flowItemId,
        node.data.logicHandles?.length || 0
      )
      const newLogicHandle: FlowDiagram.Handle = {
        id: new ObjectId().toHexString(),
        type: 'source',
        portType: PortType.LOGIC,
        name: newHandleName
      }
      node.data.logicHandles?.push(newLogicHandle)
    },
    conditionAdded: (
      state,
      action: PayloadAction<{
        // conditions are in the payload for further validation purposes
        conditions: LogicConditionalSplitConditionAPI[]
        flowItemId: string
      }>
    ) => {
      const flowItem = selectFlowItem<ConditionalSplitFlowItemUI>(action.payload.flowItemId)(state)

      if (!flowItem) {
        return
      }

      flowItem.item.logic.conditional_split.variants[0].conditions.push(DEFAULT_CONDITION)
    },
    conditionV2Added: (
      state,
      action: PayloadAction<{
        // conditions are in the payload for further validation purposes
        conditions: LogicConditionalSplitConditionAPI[]
        flowItemId: string
      }>
    ) => {
      const flowItem = selectFlowItem<ConditionalSplitV2FlowItemUI>(action.payload.flowItemId)(
        state
      )

      if (!flowItem) {
        throw new Error('Flow item not found')
      }

      flowItem.item.logic.conditionalSplitV2 = DEFAULT_EXPRESSION
    },
    conditionDeleted: (
      state,
      action: PayloadAction<{
        index: number
        flowItemId: string
      }>
    ) => {
      const flowItem = selectFlowItem<ConditionalSplitFlowItemUI>(action.payload.flowItemId)(state)
      const flow = selectEditorFlow(state)

      if (!flowItem || !flow) {
        return
      }

      flowItem.item.logic.conditional_split.variants[0].conditions =
        flowItem.item.logic.conditional_split.variants[0].conditions.filter(
          (condition, index) => index !== action.payload.index
        )
    },
    conditionValueChanged: (
      state,
      action: PayloadAction<{
        conditions: LogicConditionalSplitConditionAPI[]
        flowItemId: string
      }>
    ) => {
      const flowItem = selectFlowItem<ConditionalSplitFlowItemUI>(action.payload.flowItemId)(state)

      if (!flowItem) {
        return
      }

      flowItem.item.logic.conditional_split.variants[0].conditions = action.payload.conditions
    },
    parsedExpressionChanged: (
      state,
      action: PayloadAction<{ flowItemId: string; handleId: string; expressionDescription: string }>
    ) => {
      const flowItemMeta = selectFlowItemMeta(action.payload.flowItemId)(state)
      const handleId = action.payload.handleId
      const logicMeta = flowItemMeta.logicMeta

      if (!flowItemMeta) {
        throw new Error('flowItemMeta not found')
      }

      const newLogicMeta = {
        ...cloneDeep(logicMeta),
        [handleId]: action.payload.expressionDescription
      }

      flowItemMeta.logicMeta = newLogicMeta
    },
    conditionV2ValueChanged: (
      state,
      action: PayloadAction<{
        expression: LogicConditionalSplitV2API['cases'][0]['expression']
        flowItemId: string
        expressionIndex: number
        shouldValidate: boolean
      }>
    ) => {
      const flowItem = selectFlowItem<ConditionalSplitV2FlowItemUI>(action.payload.flowItemId)(
        state
      )
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)

      if (!flowItem) {
        throw new Error('Flow item not found')
      }

      if (!node) {
        throw new Error('Node not found')
      }

      const conditionalSplitCase =
        flowItem.item.logic.conditionalSplitV2.cases[action.payload.expressionIndex]
      if (!conditionalSplitCase) {
        flowItem.item.logic.conditionalSplitV2.cases.push(DEFAULT_EXPRESSION.cases[0] as any)
      } else {
        conditionalSplitCase.expression = cloneDeep(action.payload.expression) as ExpressionUI
      }

      // Add logic handle if doesn't exist
      const hasLogicHandle =
        node?.data.logicHandles?.find(
          (handle) => handle.expressionIndex === action.payload.expressionIndex
        ) !== undefined

      if (!hasLogicHandle) {
        node.data.logicHandles!.push({
          id: new ObjectId().toHexString(),
          expressionIndex: action.payload.expressionIndex,
          type: 'source',
          portType: PortType.LOGIC,
          name: LOGIC_PORT_CONDITION_MET
        })
      }

      // Add else handle if it doesn't exist
      const hasElseLogicHandle =
        node.data.logicHandles?.find((handle) => handle.name === LOGIC_PORT_CONDITION_UNMET) !==
        undefined

      if (!hasElseLogicHandle) {
        node.data.logicHandles!.push({
          id: new ObjectId().toHexString(),
          type: 'source',
          portType: PortType.LOGIC,
          name: LOGIC_PORT_CONDITION_UNMET
        })
      }
    },
    conditionV2ValueDeleted: (
      state,
      action: PayloadAction<{
        flowItemId: string
        expressionIndex: number
        shouldValidate: boolean
      }>
    ) => {
      const flow = selectEditorFlow(state)
      const flowItem = selectFlowItem<ConditionalSplitV2FlowItemUI>(action.payload.flowItemId)(
        state
      )
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)
      if (!flowItem || !node || !flow) {
        throw new Error('Flow item / node / flow not found')
      }

      if (node.data.logicHandles === undefined) {
        throw new Error('Node data logicHandles is undefined')
      }

      // Delete case
      flowItem.item.logic.conditionalSplitV2.cases.splice(action.payload.expressionIndex, 1)

      // Delete handle
      const logicHandleToDelete = node.data.logicHandles.find((handle) => {
        return handle.expressionIndex === action.payload.expressionIndex
      })

      if (!logicHandleToDelete) {
        throw new Error('Deleted source handle not found')
      }

      node.data.logicHandles = node.data.logicHandles.filter((handle) => {
        if (handle.name === LOGIC_PORT_CONDITION_UNMET) {
          return true
        }
        return handle.id !== logicHandleToDelete.id
      })

      // move else back to last position
      const elseHandle = node.data.logicHandles.find(
        (handle) => handle.name === LOGIC_PORT_CONDITION_UNMET
      )
      if (elseHandle) {
        const index = node.data.logicHandles.indexOf(elseHandle)
        node.data.logicHandles.splice(index, 1)
        node.data.logicHandles.push(elseHandle)
      }

      // reindex
      node.data.logicHandles = node.data.logicHandles.map((handle, index) => {
        return {
          ...handle,
          ...(handle.name !== LOGIC_PORT_CONDITION_UNMET && { expressionIndex: index })
        }
      })

      // Delete edges
      flow.flowEditorDiagram.edges = flow.flowEditorDiagram.edges.filter(
        (edge) => edge.sourceHandle !== logicHandleToDelete.id
      )
    },
    deleteMMSAttachmentClicked: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItem = selectFlowItem<SMSMessageFlowItemUI>(action.payload.flowItemId)(state)
      const message: SMSMessageTextUI = flowItem.item.messages[0] as any
      // @ts-expect-error SMSMessageTextUI doesn't contain attachments prop
      delete message.attachments
      message.type = MessageType.SMS_TEXT
      resetSecondaryEditPanel(state)
    },
    addItemAddImageClicked: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItem = selectFlowItem<SMSMessageFlowItemUI>(action.payload.flowItemId)(state)
      const message: SMSMessageMMSUI = flowItem.item.messages[0] as any
      message.type = MessageType.SMS_MMS
      message.attachments = []
    },
    addItemAddContactCardClicked: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItem = selectFlowItem<SMSMessageFlowItemUI>(action.payload.flowItemId)(state)
      const message: SMSMessageContactCardUI = flowItem.item.messages[0] as any
      message.type = MessageType.SMS_CONTACT_CARD
    },
    mediaUploadCompleted: (
      state,
      action: PayloadAction<{ flowItemId: string; messageIndex: number; url: string }>
    ) => {
      const flowItem = selectFlowItem<SMSMessageFlowItemUI>(action.payload.flowItemId)(state)
      const flowItemMeta = selectFlowItemMeta(action.payload.flowItemId)(state)
      flowItemMeta.edited = false

      const message: SMSMessageMMSUI = flowItem.item.messages[action.payload.messageIndex] as any
      message.type = MessageType.SMS_MMS
      message.attachments = [action.payload.url]
      resetSecondaryEditPanel(state)
    },
    mediaUploadFailed: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItemMeta = selectFlowItemMeta(action.payload.flowItemId)(state)
      flowItemMeta.edited = false
    },
    mediaUploadStarted: (
      state,
      action: PayloadAction<{ flowItemId: string; messageIndex: number }>
    ) => {
      const flowItemMeta = selectFlowItemMeta(action.payload.flowItemId)(state)
      flowItemMeta.edited = true
    },
    nodesChanged: (state, action: PayloadAction<{ nodes: FlowDiagram.Node[] }>) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)
      if (!flow) {
        throw new Error('Flow not found')
      }
      if (!flowMeta) {
        throw new Error('flowMeta not found')
      }

      const nodes = cloneDeep(action.payload.nodes)
      flow.flowEditorDiagram.nodes = nodes

      if (flowMeta.autoAlignOnNodesChange === AutoAlignOnNodesChangeStatus.ALIGN) {
        autoAlignFlowDiagram(state)
        flowMeta.autoAlignOnNodesChange = AutoAlignOnNodesChangeStatus.FITVIEW
      }
    },
    edgesChanged: (state, action: PayloadAction<{ edges: FlowDiagram.Edge[] }>) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        throw new Error('Flow not found')
      }
      flow.flowEditorDiagram.edges = action.payload.edges
      updateFlowItemsIsQuietHoursEnabled(state)
    },
    edgeRemoved: (state, action: PayloadAction<{ edgeId: string }>) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        throw new Error('Flow not found')
      }

      const edge = flow.flowEditorDiagram.edges.find((edge) => edge.id === action.payload.edgeId)

      if (!edge) {
        throw new Error('Edge not found')
      }

      const sourceNode = selectNodeById(edge.source)(state)

      if (!sourceNode) {
        throw new Error('Source node is not defined')
      }

      if (sourceNode.type === NodeType.TRIGGER) {
        detachEntryFlowItem(state)
      }

      flow.flowEditorDiagram.edges = applyEdgeChanges(
        [{ id: action.payload.edgeId, type: 'remove' }],
        flow.flowEditorDiagram.edges
      )

      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
    },
    edgeAdded: (state, action: PayloadAction<{ connection: Connection }>) => {
      const flow = selectEditorFlow(state)

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      const sourceNode = selectNodeById(action.payload.connection.source)(state)
      const targetNode = selectNodeById(action.payload.connection.target)(state)

      // TODO selectNodeById should assure that node is not undefined
      if (!sourceNode || !targetNode) {
        throw new Error('Source or target node is not defined')
      }

      if (!targetNode.data.flowItemId) {
        throw new Error('Target node does not have flowItemId')
      }

      if (sourceNode.type === NodeType.TRIGGER) {
        attachEntryFlowItem(state, targetNode.data.flowItemId)
      }

      flow.flowEditorDiagram.edges = addEdge(
        action.payload.connection,
        flow.flowEditorDiagram.edges
      )

      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
    },
    rteInsertLink: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        linkVariable: Variable
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const variablePlaceholderData = selectVariablePlaceholder(state)
      const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings ?? {
        domain: '',
        subdomain: ''
      }

      const linkVariable: Variable = action.payload.linkVariable

      const linkHTML = getHtmlFromVariable(
        linkVariable,
        action.payload.messageIndex,
        false,
        urlGenerationSettings,
        ![...variableOptionNames, supportEmailVariableOption.value].includes(linkVariable.name)
      )

      fixForcedCaretPositionAfterSiteName(state)

      messageMeta.textHTML = insertContentAtCaretPosition(
        messageMeta.textHTML ?? '',
        // We insert a space automatically, becase cursor cannot be
        // placed the inserted tag, because it disappears in Chrome
        `&nbsp;${linkHTML}&nbsp;`,
        messageMeta.caretPosition
      )

      // Cannot place cursor exactly after the inserted node
      messageMeta.caretPosition =
        messageMeta.caretPosition + convertHTMLtoPlainText(`&nbsp;${linkHTML}&nbsp;`).length

      resetSecondaryEditPanel(state)

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)
      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteEditLinkVariable: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        linkVariable: Variable
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)

      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const variablePlaceholderData = selectVariablePlaceholder(state)
      const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings

      if (urlGenerationSettings === undefined || urlGenerationSettings === null) {
        throw new Error('CannotFindUrlGenerationSettings')
      }

      removeEditingVariable(state, action.payload.flowItemId, action.payload.messageIndex)
      // insert new variable according to the modified form data
      const variableLinkToInsert: Variable = action.payload.linkVariable

      const variableHtml = getHtmlFromVariable(
        variableLinkToInsert,
        action.payload.messageIndex,
        false,
        urlGenerationSettings,
        ![...variableOptionNames, supportEmailVariableOption.value].includes(
          variableLinkToInsert.name
        )
      )

      messageMeta.textHTML = insertContentAtCaretPosition(
        messageMeta.textHTML ?? '',
        // We insert a space automatically, becase cursor cannot be
        // placed the inserted tag, because it disappears in Chrome
        `&nbsp;${variableHtml}&nbsp;`,
        messageMeta.caretPosition
      )
      // Cannot place cursor exactly after the inserted node
      messageMeta.caretPosition =
        messageMeta.caretPosition + convertHTMLtoPlainText(`&nbsp;${variableHtml}&nbsp;`).length

      resetSecondaryEditPanel(state)

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)
      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteRemoveLinkVariable: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      removeEditingVariable(state, action.payload.flowItemId, action.payload.messageIndex)

      resetSecondaryEditPanel(state)

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML ?? '')
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteRemoveVariable: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      removeEditingVariable(state, action.payload.flowItemId, action.payload.messageIndex)

      resetSecondaryEditPanel(state)

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML ?? '')
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteInsertVariable: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        variable: Variable
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const variablePlaceholderData = selectVariablePlaceholder(state)

      const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings ?? {
        domain: '',
        subdomain: ''
      }
      const variable: Variable = action.payload.variable

      const variableHtml = getHtmlFromVariable(
        variable,
        action.payload.messageIndex,
        false,
        urlGenerationSettings,
        ![...variableOptionNames, supportEmailVariableOption.value].includes(variable.name)
      )

      fixForcedCaretPositionAfterSiteName(state)

      messageMeta.textHTML = insertContentAtCaretPosition(
        messageMeta.textHTML ?? '',
        // We insert a space automatically, becase cursor cannot be
        // placed the inserted tag, because it disappears in Chrome
        `&nbsp;${variableHtml}&nbsp;`,
        messageMeta.caretPosition
      )

      // Cannot place cursor exactly after the inserted node
      messageMeta.caretPosition =
        messageMeta.caretPosition + convertHTMLtoPlainText(`&nbsp;${variableHtml}&nbsp;`).length

      resetSecondaryEditPanel(state)

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)
      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteInsertDiscountCode: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        variable: Variable
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const variablePlaceholderData = selectVariablePlaceholder(state)
      const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings ?? {
        domain: '',
        subdomain: ''
      }

      const variable: Variable = action.payload.variable
      if (!isStaticDiscountCodeVariable(variable) && !isUniqueDiscountCodeVariable(variable)) {
        throw new Error('Variable is not a discount code')
      }

      const discountCodeVariable: Variable = action.payload.variable

      const variableHtml = getHtmlFromVariable(
        discountCodeVariable,
        action.payload.messageIndex,
        false,
        urlGenerationSettings,
        ![...variableOptionNames, supportEmailVariableOption.value].includes(
          discountCodeVariable.name
        )
      )

      fixForcedCaretPositionAfterSiteName(state)

      messageMeta.textHTML = insertContentAtCaretPosition(
        messageMeta.textHTML ?? '',
        // We insert a space automatically, becase cursor cannot be
        // placed the inserted tag, because it disappears in Chrome
        `&nbsp;${variableHtml}&nbsp;`,
        messageMeta.caretPosition
      )

      // Cannot place cursor exactly after the inserted node
      messageMeta.caretPosition =
        messageMeta.caretPosition + convertHTMLtoPlainText(`&nbsp;${variableHtml}&nbsp;`).length

      resetSecondaryEditPanel(state)

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)
      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },

    rteRemoveDiscountCode: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        variable: Variable
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      removeEditingVariable(state, action.payload.flowItemId, action.payload.messageIndex)

      resetSecondaryEditPanel(state)

      if (messageMeta.textHTML === undefined) {
        return
      }

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteEditVariable: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        variable: Variable
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const flowMeta = selectEditorFlowMeta(state)

      if (!flowMeta.editingVariableData) {
        throw new Error('CannotFindVariableToEdit')
      }

      const variablePlaceholderData = selectVariablePlaceholder(state)
      const urlGenerationSettings = variablePlaceholderData?.urlGenerationSettings ?? {
        domain: '',
        subdomain: ''
      }
      const variableIndexToBeRemoved = getIndexFromId(flowMeta.editingVariableData)
      const plainText = convertHTMLtoPlainText(messageMeta.textHTML ?? '')
      const variableCaretPositions = getMessageVariablesCaretPosition(plainText)
      const startingCaretPosition = variableCaretPositions[variableIndexToBeRemoved]

      if (startingCaretPosition === -1) {
        throw new Error('CannotFindVariableToEdit')
      }

      messageMeta.caretPosition = startingCaretPosition

      messageMeta.textHTML = removeVariableByIndex(
        messageMeta.textHTML ?? '',
        variableIndexToBeRemoved
      )

      // insert new variable according to the modified form data
      const variableToInsert: Variable = action.payload.variable

      const variableHtml = getHtmlFromVariable(
        variableToInsert,
        action.payload.messageIndex,
        false,
        urlGenerationSettings,
        ![...variableOptionNames, supportEmailVariableOption.value].includes(variableToInsert.name)
      )
      messageMeta.textHTML = insertContentAtCaretPosition(
        messageMeta.textHTML ?? '',
        // We insert a space automatically, becase cursor cannot be
        // placed the inserted tag, because it disappears in Chrome
        `&nbsp;${variableHtml}&nbsp;`,
        messageMeta.caretPosition
      )

      // Cannot place cursor exactly after the inserted node
      messageMeta.caretPosition =
        messageMeta.caretPosition + convertHTMLtoPlainText(`&nbsp;${variableHtml}&nbsp;`).length

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)

      resetSecondaryEditPanel(state)

      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteVariableClicked: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        editingVariableData: string
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const flowMeta = selectEditorFlowMeta(state)
      const flowEditorSettings = selectSettings(state)
      const { supportEmail } = selectVariablePlaceholder(state) ?? {}

      const variable = getVariableFromId(action.payload.editingVariableData)

      const variableTypeOptions = [
        ...(flowEditorSettings?.variableTypeOptions ?? []),
        ...(flowEditorSettings?.entryVariableTypeOptions ?? [])
      ]
      const variableNames = [
        ...variableTypeOptions.map((option) => option.value),
        ...(supportEmailVariableOption ? [supportEmailVariableOption.value] : [])
      ]

      const linkTypeOptions = [
        ...(flowEditorSettings?.linkTypeOptions ?? []),
        ...(flowEditorSettings?.entryLinkTypeOptions ?? [])
      ]
      const linkVariableNames = linkTypeOptions.map((option) => option.value)

      // TODO refactor this huge logic into a unit that can be unit tested individually
      if (
        (isStaticDiscountCodeVariable(variable) || isUniqueDiscountCodeVariable(variable)) &&
        !isDiscountUrlVariable(variable)
      ) {
        flowMeta.secondaryEditPanelType = SecondaryEditPanelType.DISCOUNT_CODE
      } else if (isKeywordReplyVariable(variable)) {
        flowMeta.secondaryEditPanelType = SecondaryEditPanelType.KEYWORD_REPLY
      } else if (
        !variableNames.includes(variable.name) &&
        !linkVariableNames.includes(variable.name)
      ) {
        if (
          messageMeta.errors.some(
            (error) => error.message === FlowEditorError.MessageHasInvalidLink
          )
        ) {
          flowMeta.secondaryEditPanelType = SecondaryEditPanelType.LINK
        } else if (
          messageMeta.errors.some(
            (error) => error.message === FlowEditorError.MessageHasInvalidVariable
          )
        ) {
          // invalid variable can be a recognized but can not be attached variable
          if (variableOptionNames.includes(variable.name)) {
            flowMeta.secondaryEditPanelType = SecondaryEditPanelType.VARIABLE
          } else {
            // invalid variable can be a user variable
            flowMeta.secondaryEditPanelType = SecondaryEditPanelType.USER_VARIABLE
          }
        }
      } else {
        const name: string = getVariableFromId(action.payload.editingVariableData).name

        if (linkTypeOptions.find((option) => option.value === name) !== undefined) {
          flowMeta.secondaryEditPanelType = SecondaryEditPanelType.LINK
        }
        if (
          find(
            [
              ...new Set(flowEditorSettings.variableTypeOptions),
              ...new Set(flowEditorSettings.entryVariableTypeOptions),
              ...(supportEmail ? [supportEmailVariableOption] : [])
            ],
            (option) => option.value === name
          ) !== undefined
        ) {
          flowMeta.secondaryEditPanelType = SecondaryEditPanelType.VARIABLE
        }
      }

      flowMeta.editingMessageIndex = action.payload.messageIndex
      flowMeta.editingVariableData = action.payload.editingVariableData

      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
    },
    rteInsertEmoji: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        emoji: EmojiData
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)

      const emoji = (action.payload.emoji as BaseEmoji).native
      fixForcedCaretPositionAfterSiteName(state)

      messageMeta.textHTML = insertContentAtCaretPosition(
        messageMeta.textHTML ?? '',
        `${emoji}&nbsp;`,
        messageMeta.caretPosition
      )

      messageMeta.textHTML = removeHTMLEntities(messageMeta.textHTML)

      messageMeta.caretPosition =
        messageMeta.caretPosition + convertHTMLtoPlainText(`${emoji}&nbsp;`).length

      message.text = replaceHtmlValueToRawValue(messageMeta.textHTML)

      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )

      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)
    },
    rteFooterItemClicked: (
      state,
      action: PayloadAction<{
        insertType: InsertType
        flowItemId: string
      }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)

      flowMeta.secondaryEditPanelType = {
        [InsertType.LINK]: SecondaryEditPanelType.LINK,
        [InsertType.VARIABLE]: SecondaryEditPanelType.VARIABLE,
        [InsertType.DISCOUNT_CODE]: SecondaryEditPanelType.DISCOUNT_CODE,
        [InsertType.USER_VARIABLE]: SecondaryEditPanelType.USER_VARIABLE,
        [InsertType.EMOJI]: SecondaryEditPanelType.EMOJI,
        [InsertType.KEYWORD_REPLY]: SecondaryEditPanelType.KEYWORD_REPLY
      }[action.payload.insertType]

      flowMeta.editingMessageIndex = 0

      resetEditingVariable(state)
      if (flowMeta.insertNodePanelType) {
        resetNodeTypeSelector(state)
      }
    },
    editorPanelSendTestClicked: (state) => {
      const flowMeta = selectEditorFlowMeta(state)

      flowMeta.secondaryEditPanelType = SecondaryEditPanelType.SEND_TEST
      resetEditingVariable(state)
    },
    sendTestPanelClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.secondaryEditPanelType = undefined
    },
    editorPanelAIRephraseClicked: (state) => {
      const flowMeta = selectEditorFlowMeta(state)

      flowMeta.secondaryEditPanelType = SecondaryEditPanelType.AI_REPHRASE
      resetEditingVariable(state)
    },
    insertAIRephrasedText: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        rephrasedText: string
      }>
    ) => {
      const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex
      )(state)
      const flowEditorSettings = selectSettings(state)

      message.text = action.payload.rephrasedText
      // TODO refactor this logic somewhere else
      if (
        !flowEditorSettings.isCustomMessageHeaderEnabled &&
        !/^{{site\.name\|required}}/g.test(message.text)
      ) {
        message.text = `{{site.name|required}} ${message.text}`
        messageMeta.caretPosition = 2
      }
      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
      messageMeta.canvasHTML = replaceRawValueToCanvasHtmlValue(state, message.text)

      const flowItem = selectFlowItem(action.payload.flowItemId)(state)
      const flow = selectEditorFlow(state)

      if (!flow) {
        throw new Error('CannotFindFlow')
      }

      flowItem.tags = Array.from(
        new Set([...flowItem.tags, ...[SequenceTag.AI_TEXT_GENERATED, SequenceTag.AI]])
      )
      flow.tags = Array.from(
        new Set([...flow.tags, ...[SequenceTag.AI_TEXT_GENERATED, SequenceTag.AI]])
      )

      resetSecondaryEditPanel(state)
    },
    AIRephrasePanelClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.secondaryEditPanelType = undefined
    },
    editorPanelAddImageToMessageClicked: (state) => {
      const flowMeta = selectEditorFlowMeta(state)

      flowMeta.secondaryEditPanelType = SecondaryEditPanelType.IMAGE_EDITOR
      resetEditingVariable(state)
    },
    imageEditorPanelClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.secondaryEditPanelType = undefined
    },
    discountTypeSelectorItemClicked: (
      state,
      action: PayloadAction<{
        flowItemId: string
        type: ''
      }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)

      // todo handle message index when messenger will be added to FER
      const messageIndex = 0
      flowMeta.editingMessageIndex = messageIndex

      resetEditingVariable(state)
    },
    secondaryPanelClosed: (
      state,
      action: PayloadAction<{
        flowItemId: string
      }>
    ) => {
      resetSecondaryEditPanel(state)
      const flowMeta = selectEditorFlowMeta(state)

      if (flowMeta.editingMessageIndex === undefined) {
        throw new Error('CannotFindEditingMessageIndex')
      }

      const message = selectMessage(action.payload.flowItemId, flowMeta.editingMessageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        flowMeta.editingMessageIndex
      )(state)

      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )
    },
    sendTestSecondaryPanelClosed: (state) => {
      resetSecondaryEditPanel(state)
    },
    rteClicked: (
      state,
      action: PayloadAction<{
        flowItemId: string
      }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)

      if (flowMeta.editingMessageIndex === undefined) {
        throw new Error('CannotFindEditingMessageIndex')
      }

      const message = selectMessage(action.payload.flowItemId, flowMeta.editingMessageIndex)(state)
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        flowMeta.editingMessageIndex
      )(state)

      messageMeta.textHTML = replaceRawValueToHtmlValue(
        state,
        message.text,
        action.payload.flowItemId
      )

      // reinsert variable is only an option for general discount codes,
      // we need to keep the secondary panel open only for that case
      const editingVariableIsStaticDiscountCode =
        flowMeta.editingVariableData &&
        isStaticDiscountCodeVariable(getVariableFromId(flowMeta.editingVariableData)) &&
        !isDiscountUrlVariable(getVariableFromId(flowMeta.editingVariableData))

      if (!editingVariableIsStaticDiscountCode) {
        resetSecondaryEditPanel(state)
        reRenderRTEContent(state)
      }
    },
    emojiPickerPanelClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.secondaryEditPanelType = undefined
    },
    flowPreviewToggled: (state) => {
      const flowMeta = selectEditorFlowMeta(state)

      if (!flowMeta) {
        throw new Error('Cannot find flowMeta')
      }

      const previewCurrentState = flowMeta.isPreviewOn

      flowMeta.isPreviewOn = !previewCurrentState
      flowMeta.autoAlignOnNodesChange = AutoAlignOnNodesChangeStatus.ALIGN
    },
    welomeFlowWarningModalVisibilityChanged: (
      state,
      action: PayloadAction<{
        isModalShouldBeHidden: boolean
      }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)

      if (!flowMeta) {
        throw new Error('Cannot find flowMeta')
      }

      flowMeta.isWelcomeFlowWarningModalClosed = action.payload.isModalShouldBeHidden
    },
    autoAlignOnNodesChangeStatusChanged: (
      state,
      action: PayloadAction<{
        status: AutoAlignOnNodesChangeStatus
      }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)

      if (!flowMeta) {
        throw new Error('Cannot find flowMeta')
      }

      flowMeta.autoAlignOnNodesChange = action.payload.status
    },
    nodeDuplicated: (state, action: PayloadAction<{ nodeId: string; newFlowItemId: string }>) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)

      if (!flow) {
        throw new Error('Cannot find flow')
      }

      const node = flow.flowEditorDiagram.nodes.find((node) => node.id === action.payload.nodeId)

      if (!node) {
        throw new Error('Cannot find node')
      }

      if (!node.data.flowItemId) {
        throw new Error('Cannot find flow item id')
      }
      flowMeta.targetPosition = { x: node.position.x + 100, y: node.position.y + 100 }

      const flowItemId = node.data.flowItemId
      const flowItem = selectFlowItem(flowItemId)(state)

      const newFlowItem = cloneDeep(flowItem)
      newFlowItem._id = action.payload.newFlowItemId
      newFlowItem.trigger = getNonEntryFlowItemTrigger(newFlowItem._id)
      newFlowItem.tags = getNonEntryFlowItemTags(flow.tags)

      insertFlowItem(state, newFlowItem)
      insertNode(
        state,
        newFlowItem._id,
        node.data.nodeType,
        node.data.delayUnit,
        node.data.logicHandles?.length
      )
      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
    },
    cleanFlowMetaConnectionDroppedOnCanvas(state) {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.connectionDroppedOnCanvas = undefined
    },
    nodeInserted: (
      state,
      action: PayloadAction<{
        nodeType: NodeType
        populatedValues?: DeepPartial<FlowItemUI>
        isAIGenerated?: boolean
      }>
    ) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)
      const flowEditorSettings = selectSettings(state)

      if (!flow) {
        throw new Error('CannotFindFlow')
      }

      if (!reactFlowInternals.reactFlowInstance) {
        throw new Error('CannotFindReactFlowInstance')
      }

      const flowItemId = new ObjectId().toHexString()
      const nodeType = action.payload.nodeType

      const { source, sourceHandle, target, targetHandle } = flowMeta.insertNodeConnection

      const isInsertAfterNode =
        source !== null && sourceHandle !== null && target === null && targetHandle === null

      const isInsertBetweenNodes =
        source !== null && sourceHandle !== null && target !== null && targetHandle !== null

      let targetNode: FlowDiagram.Node | undefined
      let sourceNode: FlowDiagram.Node | undefined
      let sourceNodeIsTriggerNode: boolean | undefined

      if (isInsertBetweenNodes) {
        targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === target)
        if (!targetNode) {
          throw new Error('Cannot find target node')
        }
      }

      if (isInsertAfterNode) {
        sourceNode = flow.flowEditorDiagram.nodes.find((node) => node.id === source)
        if (!sourceNode) {
          throw new Error('Cannot find source node')
        }
        sourceNodeIsTriggerNode = sourceNode.type === NodeType.TRIGGER
      }

      const isEntry =
        (isInsertBetweenNodes && flow.entrySequenceItemId === targetNode?.data.flowItemId) ||
        (isInsertAfterNode && sourceNodeIsTriggerNode)

      const populatedValues = action.payload.populatedValues
      const newFlowItem = getNewFlowItem<FlowItemUI>({
        nodeType,
        flowItemId,
        flow,
        isEntry,
        populatedValues,
        isCustomMessageHeaderEnabled: flowEditorSettings.isCustomMessageHeaderEnabled,
        isAIGenerated: !!action.payload.isAIGenerated
      })

      if (!newFlowItem) {
        throw new Error('Can not create new flow item')
      }

      insertFlowItem(state, newFlowItem)

      // todo when delay or/end randomsplit ai generated version will be ready we need to refactor this part
      let logicHandlesLength: number | undefined

      if (isConditionalSplitV2FlowItemUI(newFlowItem)) {
        logicHandlesLength = newFlowItem.item.logic.conditionalSplitV2.cases.length + 1
      }
      const newNode = insertNode(state, flowItemId, nodeType, undefined, logicHandlesLength)

      if (isInsertAfterNode) {
        targetNode = newNode
        if (!targetNode) {
          throw new Error('Cannot find target node')
        }
      }

      /************************
       * Insert between nodes
       ************************/
      if (isInsertBetweenNodes) {
        if (isEntry) {
          detachEntryFlowItem(state)
        }

        insertNodeBetweenNodes(state, source, sourceHandle, target, targetHandle, newNode)

        if (isEntry) {
          attachEntryFlowItem(state, newFlowItem._id)
        }
      }

      /************************
       * Insert after node
       ************************/
      if (isInsertAfterNode) {
        insertNodeAfterNode(state, source, sourceHandle, newNode)

        if (isEntry) {
          attachEntryFlowItem(state, newFlowItem._id)
        }
      }

      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
      if (action.payload.populatedValues !== undefined) {
        updateFlowItemsDiscountCode(state)
      }
    },
    messageTextGeneratedByAI: (
      state,
      action: PayloadAction<{
        flowItemId: string
        messageIndex: number
        AIGeneratedText: string
      }>
    ) => {
      const messageMeta = selectMessageMeta(
        action.payload.flowItemId,
        action.payload.messageIndex ?? 0
      )(state)

      messageMeta.AIGeneratedTextHTML = replaceRawValueToHtmlValue(
        state,
        action.payload.AIGeneratedText,
        action.payload.flowItemId
      )
    },
    AIGeneratedContentGotCreated: (
      state,
      action: PayloadAction<{
        content: SegmentCampaignText[]
      }>
    ) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)
      const flowEditorSettings = selectSettings(state)

      if (!flow) {
        throw new Error('CannotFindFlow')
      }
      if (!flowMeta) {
        throw new Error('CannotFindFlowMeta')
      }

      if (!reactFlowInternals.reactFlowInstance) {
        throw new Error('CannotFindReactFlowInstance')
      }
      const triggerNode = selectTriggerNode(state)

      // remove all nodes from the originally loaded template except trigger node
      const nodesToBeRemoved = flow.flowEditorDiagram.nodes
        .filter((node) => node.id !== triggerNode?.id)
        .map((node) => node.id)

      if (nodesToBeRemoved && nodesToBeRemoved.length > 0) {
        nodesToBeRemoved.forEach((nodeId) => {
          deleteNode(state, nodeId)
        })
      }

      const campaignTextBySegment = action.payload.content

      if (!campaignTextBySegment || campaignTextBySegment.length === 0) {
        throw new Error('CannotFindCampaignContent')
      }
      const segmentIds = campaignTextBySegment
        .filter((campaignText) => campaignText.segmentId !== 'else')
        .map((campaignText) => campaignText.segmentId)

      addConditionalSpLitV2FlowItemWithMemberOfSegmentContent(state, segmentIds)

      campaignTextBySegment.forEach((campaignText, index) => {
        addAIGeneratedSMSMessageFlowItem(
          state,
          campaignText.segmentId,
          campaignText.text,
          index,
          flowEditorSettings.isCustomMessageHeaderEnabled
        )
      })
      updateFlowItemsDiscountCode(state)
      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
      addAITags(state)
      flowMeta.isAITextGeneratorWizardClosed = true
      flowMeta.autoAlignOnNodesChange = AutoAlignOnNodesChangeStatus.ALIGN
    },
    discountCodePoolValidated: (
      state,
      action: PayloadAction<{ isPoolSizeEnough; isAvailableCountEnough }>
    ) => {
      const smsCampaignMeta = selectEditorSMSCampaignMeta(state)

      if (!smsCampaignMeta) {
        throw new Error('smsCampaignMeta is not defined')
      }

      smsCampaignMeta.isPoolSizeEnough = action.payload.isPoolSizeEnough
      smsCampaignMeta.isAvailableCountEnough = action.payload.isAvailableCountEnough
    },
    flowLoaded: (
      state,
      action: PayloadAction<{
        flow: FlowUI
        source: 'localstorage' | 'server'
        unsavedChanges: boolean
        isEverSaved: boolean
        isPreviewOn: boolean
        isAITextGeneratorWizardClosed: boolean
        isLegalFlowItemDeletable: boolean
        isWelcomeFlowWarningModalClosed: boolean
      }>
    ) => {
      state.flow = cloneDeep(action.payload.flow)

      if (action.payload.source === 'server') {
        state.flow.sequenceItems = state.flow.sequenceItems.map((flowItem) => {
          return handleConditionalSplitV2FlowItemLoaded(flowItem)
        })
      }

      if (action.payload.isEverSaved === false) {
        state.flow.hasEverBeenEnabled = false
      }
      state.flowMeta.unsavedChanges = action.payload.unsavedChanges
      state.flowMeta.isEverSaved = action.payload.isEverSaved
      state.flowMeta.isPreviewOn = action.payload.isPreviewOn
      state.flowMeta.isAITextGeneratorWizardClosed = action.payload.isAITextGeneratorWizardClosed
      state.flowMeta.isLegalFlowItemDeletable = action.payload.isLegalFlowItemDeletable
      state.flowMeta.isWelcomeFlowWarningModalClosed =
        action.payload.isWelcomeFlowWarningModalClosed

      action.payload.flow.sequenceItems.forEach((flowItem) => {
        initFlowItemMeta(state, flowItem)
      })
      state.flow.flowEditorDiagram.nodes.forEach((node) => {
        node.selected = false
      })
    },
    flowUnloaded: (state) => {
      return cloneDeep(flowEditorInitialState)
    },
    insertNodeBetweenNodesClicked: (
      state,
      action: PayloadAction<{
        source: string
        sourceHandle: string
        target: string
        targetHandle: string
        edgeId: string
        edgeCenter: { x: number; y: number }
      }>
    ) => {
      const insertNodeConnectionData: Connection = {
        source: action.payload.source,
        sourceHandle: action.payload.sourceHandle,
        target: action.payload.target,
        targetHandle: action.payload.targetHandle
      }
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.insertNodeConnection = insertNodeConnectionData
      flowMeta.targetPosition = action.payload.edgeCenter
      flowMeta.insertNodePanelType = `${InsertNodePanelType.INSERT_NODE_BETWEEN_NODES}-${action.payload.edgeId}`

      updateFlowItemsTrigger(state)
    },
    insertNodeToPortClicked: (
      state,
      action: PayloadAction<{
        source: string
        sourceHandle: string
        targetPosition: { x: number; y: number }
        byDroppingOnCanvas?: boolean
      }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)
      const insertNodeConnectionData: Connection = {
        source: action.payload.source,
        sourceHandle: action.payload.sourceHandle,
        target: null,
        targetHandle: null
      }
      flowMeta.insertNodeConnection = insertNodeConnectionData
      flowMeta.insertNodePanelType = `${InsertNodePanelType.INSERT_NODE_AFTER_SOURCE_HANDLE}-${action.payload.sourceHandle}`
      flowMeta.targetPosition = action.payload.targetPosition

      if (action.payload.byDroppingOnCanvas) {
        flowMeta.connectionDroppedOnCanvas = true
      }

      resetEditPanel(state)
    },
    insertNodeOnCanvasClicked: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.insertNodeConnection = {
        source: null,
        sourceHandle: null,
        target: null,
        targetHandle: null
      }
      flowMeta.insertNodePanelType = InsertNodePanelType.INSERT_NODE_ON_CANVAS
    },
    insertNodeToPortClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.insertNodePanelType = undefined
      flowMeta.connectionDroppedOnCanvas = undefined
    },
    insertNodeBetweenNodesClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.insertNodePanelType = undefined
    },
    insertNodeOnCanvasClosed: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.insertNodePanelType = undefined
    },
    editPanelClosed: (state) => {
      resetEditPanel(state)
    },
    canvasClicked: (state) => {
      const flowMeta = selectEditorFlowMeta(state)

      if (!flowMeta.connectionDroppedOnCanvas) {
        if (flowMeta.editPanelType || flowMeta.secondaryEditPanelType) {
          resetEditPanel(state)
        }
        if (flowMeta.insertNodePanelType) {
          resetNodeTypeSelector(state)
        }
      }
      if (
        flowMeta.connectionDroppedOnCanvas &&
        flowMeta.insertNodePanelType ===
          `${InsertNodePanelType.INSERT_NODE_AFTER_SOURCE_HANDLE}-${flowMeta.insertNodeConnection?.sourceHandle}`
      ) {
        flowMeta.connectionDroppedOnCanvas = undefined
      }
    },
    triggerEditPanelOpenedInReadOnlyMode: (state, action: PayloadAction<{ nodeId: string }>) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)
      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      flowMeta.editPanelType = EditPanelType.TRIGGER
      flowMeta.editingNodeId = action.payload.nodeId
    },

    nodeClicked: (state, action: PayloadAction<{ nodeId: string }>) => {
      const node = selectNodeById(action.payload.nodeId)(state)
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }
      // we need to close the secondary edit panel if it is open and node is clicked
      resetSecondaryEditPanel(state)

      if (node?.type === NodeType.TRIGGER) {
        flowMeta.editPanelType = EditPanelType.TRIGGER
      } else {
        const flowItemId = node?.data.flowItemId
        if (!flowItemId) {
          throw new Error('Flow item id is not found')
        }

        flowMeta.editPanelType = EditPanelType.FLOW_ITEM
        flowMeta.editingFlowItemId = flowItemId
        flowMeta.editingNodeId = action.payload.nodeId
        if (node?.type === NodeType.SMS_FLOW_ITEM) {
          const messageMeta = selectMessageMeta(flowItemId, 0)(state)
          const message = selectMessage(flowItemId, 0)(state)

          flowMeta.editingMessageIndex = 0

          // siteName variable length is 12 and when node is clicked we want to put the caret at the end of the text
          messageMeta.caretPosition = message?.text.length + 13
        }
      }
      if (flowMeta.insertNodePanelType) {
        resetNodeTypeSelector(state)
      }
    },
    updateDiscountCodesOnFlowItem: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItem = selectFlowItem(action.payload.flowItemId)(state)
      updateFlowItemDiscountCodes(state, flowItem)
    },
    updateDiscountPoolIdsOnFlowItem: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItem = selectFlowItem(action.payload.flowItemId)(state)
      updateFlowItemDiscountPools(state, flowItem)
    },
    updateKeywordRepliesOnFlowItem: (state, action: PayloadAction<{ flowItemId: string }>) => {
      const flowItem = selectFlowItem(action.payload.flowItemId)(state)

      updateKeywordRepliesOnFlowItem(flowItem)
      updateKeywordRepliesHandles(state, flowItem)
      updateKeywordReplyTagOnFlowItem(flowItem)
      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
    },
    updateKeywordReplyTagOnFlow: (state) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        return
      }

      flow.tags = flow.tags.filter((tag) => tag !== SequenceTag.KEYWORD_REPLY)
      if (!flow.sequenceItems.some(hasKeywordRepliesOnFlowItem)) {
        return
      }
      flow.tags = [...new Set([...flow.tags, SequenceTag.KEYWORD_REPLY])]
    },
    discountCodePoolsLoaded: (
      state,
      action: PayloadAction<{ discountCodePools: DiscountCodePool[] }>
    ) => {
      state.discountCodePools = action.payload.discountCodePools
    },
    readOnlyMode: (state) => {
      state.isReadOnly = true
    },
    leaveReadOnlyMode: (state) => {
      state.isReadOnly = false
      const smsCampaign = selectEditorSMSCampaign(state)
      if (!smsCampaign) {
        throw new Error('SMS campaign is not loaded')
      }
      smsCampaign.status = SMSCampaignStatus.DRAFT
      smsCampaign.updatedAt = new Date().toISOString()
    },
    smsCampaignStatusChanged: (state, action: PayloadAction<{ status: SMSCampaignStatus }>) => {
      const smsCampaign = selectEditorSMSCampaign(state)
      if (!smsCampaign) {
        throw new Error('SMS campaign is not loaded')
      }
      smsCampaign.status = action.payload.status
      smsCampaign.updatedAt = new Date().toISOString()
    },
    smsCampaignTargetRulesChanged: (
      state,
      action: PayloadAction<{ targetType: keyof TargetRulesSMSCampaign; segmentIds: string[] }>
    ) => {
      const smsCampaign = selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign is not loaded')
      }

      if (!smsCampaign.targetRules) {
        smsCampaign.targetRules = {}
      }

      smsCampaign.targetRules[action.payload.targetType] = action.payload.segmentIds
    },
    applySMSCampaignTargetRules: (state, action: PayloadAction<TargetRulesSMSCampaign>) => {
      const smsCampaign = selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign is not loaded')
      }

      smsCampaign.targetRules = action.payload
    },
    smsCampaignSettingsChanged: (
      state,
      action: PayloadAction<{
        isQuietHoursEnabled: boolean
        isSmartSendingEnabled: boolean
        scheduledFor: string | null
        scheduleType: BlastScheduleType
      }>
    ) => {
      const smsCampaign = selectEditorSMSCampaign(state)
      const flow = selectEditorFlow(state)
      const site = selectSite(state)
      if (!smsCampaign || !flow || !site) {
        throw new Error('SMS campaign is not loaded')
      }

      smsCampaign.isQuietHoursEnabled = action.payload.isQuietHoursEnabled
      smsCampaign.scheduledFor = action.payload.scheduledFor
      smsCampaign.scheduleType = action.payload.scheduleType
      flow.isSmartSendingEnabled = action.payload.isSmartSendingEnabled
      flow.isQuietHoursEnabled = action.payload.isQuietHoursEnabled

      const flowItemsAsAPIType = flow.sequenceItems.map((flowItem) =>
        convertFlowItemUIToFlowItem(flowItem, flow)
      )

      if (!flow.entrySequenceItemId) {
        throw new Error('Entry sequence item is not found')
      }

      const isQuietHoursEnabledByFlowItemId = getIsQuietHoursEnabledByFlowItemId(
        flowItemsAsAPIType,
        {
          isQuietHoursEnabled: action.payload.isQuietHoursEnabled,
          entrySequenceItemId: flow.entrySequenceItemId
        }
      )

      flow.sequenceItems.forEach((sequenceItem) => {
        sequenceItem.isSmartSendingEnabled = action.payload.isSmartSendingEnabled
        sequenceItem.isQuietHoursEnabled = isQuietHoursEnabledByFlowItemId[sequenceItem._id]

        const isScheduledDelayFlowItem =
          isDelayFlowItemUI(sequenceItem) &&
          sequenceItem.item.logic.delay.type === DelayType.delayScheduled

        if (!isScheduledDelayFlowItem) {
          return
        }

        const loaded = loadDelayScheduledTo(
          sequenceItem.item.logic.delay.scheduled_date,
          sequenceItem.item.logic.delay.schedule_type,
          site.timeZone ?? ''
        )
        const converted = saveDelayScheduledTo(
          loaded.toISOString(),
          action.payload.scheduleType,
          site.timeZone
        ).toISOString()

        sequenceItem.item.logic.delay.schedule_type = action.payload.scheduleType
        sequenceItem.item.logic.delay.scheduled_date = converted
      })
    },
    joinedASegmentSettingsChanged: (
      state,
      action: PayloadAction<{
        isQuietHoursEnabled: boolean
        sendingFrequency: SendingFrequency
        sendToSegment: string | undefined
      }>
    ) => {
      const flow = selectEditorFlow(state)

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      flow.isQuietHoursEnabled = action.payload.isQuietHoursEnabled
      flow.sendingFrequency = action.payload.sendingFrequency

      if (action.payload.sendToSegment === undefined) {
        delete flow.segmentId
      } else {
        flow.segmentId = action.payload.sendToSegment
      }

      const entryFlowItem = flow.sequenceItems.find((item) => item._id === flow.entrySequenceItemId)
      if (!entryFlowItem) {
        throw new Error('Entry flow item is not found')
      }
      const entryTriggers = entryFlowItem.trigger.filter(
        (trigger) => !trigger.startsWith('joined_segment_')
      )
      if (action.payload.sendToSegment) {
        entryTriggers.push(`joined_segment_${action.payload.sendToSegment}`)
      }
      entryFlowItem.trigger = entryTriggers

      const flowItemsAsAPIType = flow.sequenceItems.map((flowItem) =>
        convertFlowItemUIToFlowItem(flowItem, flow)
      )

      if (!flow.entrySequenceItemId) {
        throw new Error('Entry sequence item is not found')
      }

      const isQuietHoursEnabledByFlowItemId = getIsQuietHoursEnabledByFlowItemId(
        flowItemsAsAPIType,
        {
          isQuietHoursEnabled: action.payload.isQuietHoursEnabled,
          entrySequenceItemId: flow.entrySequenceItemId
        }
      )

      flow.sequenceItems.forEach((sequenceItem) => {
        sequenceItem.isQuietHoursEnabled = isQuietHoursEnabledByFlowItemId[sequenceItem._id]
        sequenceItem.sendingFrequency = action.payload.sendingFrequency
      })
    },
    customIntegrationSettingsChanged: (
      state,
      action: PayloadAction<{
        isQuietHoursEnabled: boolean
        customIntegrationSlug?: string
        customIntegrationCategory?: string
        customIntegrationEventType?: string
      }>
    ) => {
      const flow = selectEditorFlow(state)

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      const entryFlowItem = flow.sequenceItems.find((item) => item._id === flow.entrySequenceItemId)

      if (action.payload.customIntegrationEventType) {
        if (
          action.payload.customIntegrationEventType === 'optin' &&
          !isLegalFlowItem(entryFlowItem)
        ) {
          addSMSWelcomeLegalMessage(state)
        } else if (
          action.payload.customIntegrationEventType !== 'optin' &&
          isLegalFlowItem(entryFlowItem)
        ) {
          removeSMSWelcomeLegalMessage(state)
        }
      } else if (isLegalFlowItem(entryFlowItem)) {
        removeSMSWelcomeLegalMessage(state)
      }

      updateFlowItemsTrigger(state)
      autoAlignFlowDiagram(state)

      updateFlowItemsIsQuietHoursEnabled(state)
    },
    automatedFlowSendingSettingsChanged: (
      state,
      action: PayloadAction<{
        isQuietHoursEnabled: boolean
      }>
    ) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      const trigger = getFlowTrigger(flow.tags)

      if (
        !isLoopWorkTrigger(trigger) &&
        !isWondermentTrigger(trigger) &&
        !isFulfillmentTrigger(trigger) &&
        !isRechargeTrigger(trigger) &&
        !isYotpoTrigger(trigger) &&
        !IsYotpoReviewTrigger(trigger)
      ) {
        throw new Error('Trigger is not allowed to have sending settings to be changed')
      }

      flow.isQuietHoursEnabled = action.payload.isQuietHoursEnabled
      updateFlowItemsIsQuietHoursEnabled(state)
    },
    flowRenamed: (
      state,
      action: PayloadAction<{
        name: string
      }>
    ) => {
      const smsCampaign = selectEditorSMSCampaign(state)
      const flow = selectEditorFlow(state)

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }
      flow.name = action.payload.name

      if (smsCampaign) {
        smsCampaign.name = action.payload.name
      }
    },
    removeSMSCampaignTargetRule: (
      state,
      action: PayloadAction<{ targetType: keyof TargetRulesSMSCampaign; segmentId: string }>
    ) => {
      const smsCampaign = selectEditorSMSCampaign(state)

      if (!smsCampaign) {
        throw new Error('SMS campaign is not loaded')
      }

      const { targetType, segmentId } = action.payload

      if (smsCampaign.targetRules?.[targetType] === undefined) {
        throw new Error('No rules from to remove')
      }

      smsCampaign.targetRules[targetType] = smsCampaign.targetRules[targetType]?.filter(
        (id) => id !== segmentId
      )
    },
    setFlowEditorSetting: (
      state,
      action: PayloadAction<{ key: keyof FlowEditorSettingsContextType; val: any }>
    ) => {
      state.flowMeta.settings[action.payload.key] = action.payload.val
    },
    headerErrorClicked: (state, action: PayloadAction<{ error: FlowError }>) => {
      const { error } = action.payload

      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      if (error.flowItemId) {
        const node = selectNodeByFlowItemId(error.flowItemId)(state)

        if (!node) {
          throw new Error('Node not found')
        }

        if (node.type === NodeType.TRIGGER) {
          flowMeta.editPanelType = EditPanelType.TRIGGER
        } else {
          flowMeta.editPanelType = EditPanelType.FLOW_ITEM
        }

        flowMeta.editingFlowItemId = error.flowItemId
        flowMeta.editingNodeId = node.id
        if (error.messageIndex) {
          flowMeta.editingMessageIndex = error.messageIndex
        }
      } else if (error.nodeId) {
        const node = selectNodeById(error.nodeId)(state)
        if (node?.type === NodeType.TRIGGER) {
          flowMeta.editPanelType = EditPanelType.TRIGGER
          flowMeta.editingNodeId = error.nodeId
        }
      }
    },
    // At this point the changes are not yet populated in the state, because
    // changeTriggerThunk initiates a new flow load
    triggerChanged: (
      state,
      action: PayloadAction<{ trigger: Trigger; isLegalOptional: boolean }>
    ) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      if (action.payload.trigger === Trigger.welcome && action.payload.isLegalOptional !== true) {
        addSMSWelcomeLegalMessage(state)

        // Previous state is welcome, remove legal message
      } else if (getFlowTrigger(flow.tags) === Trigger.welcome) {
        removeSMSWelcomeLegalMessage(state)
      }

      // ALL CHANGES THAT ARE MADE ON THE FLOW HERE ARE NOT POPULATED TO THE STATE
      // SEE changeTriggerThunk

      if (action.payload.trigger !== Trigger.smsCampaign) {
        delete state.smsCampaign
        delete state.smsCampaignMeta
      }
      updateFlowItemsTrigger(state)
      updateFlowItemsIsQuietHoursEnabled(state)
      setScheduledDelayFlowItemsToDefault(state)
      autoAlignFlowDiagram(state)
    },
    connectionInProgressStateChanged: (
      state,
      action: PayloadAction<{ sourceHandle: string | null }>
    ) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.connectionInProgressFromSourceHandle = action.payload.sourceHandle ?? undefined
    },
    optinToolIdInQuery: (state, action: PayloadAction<{ optinToolId: string | undefined }>) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.optinToolId = action.payload.optinToolId ?? undefined
    },
    flowEditorReady: (state) => {},
    openTargetAudiencePanel: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.secondaryEditPanelType = SecondaryEditPanelType.TARGET_AUDIENCE
    },
    closeTargetAudiencePanel: (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.secondaryEditPanelType = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(hideModal, (state, action) => {
      if (action.payload.modalType === ModalType.ADD_AI_CAMPAIGN_WIZARD_MODAL) {
        const flowMeta = selectEditorFlowMeta(state)

        if (!flowMeta) {
          throw new Error('Cannot find flowMeta')
        }

        flowMeta.isAITextGeneratorWizardClosed = true
      }
    })

    builder.addCase(meLoaded, (state, action) => {
      state.site = action.payload.site

      const isCampaignReadOnly = state.smsCampaign
        ? isSMSCampaignReadonly(state.smsCampaign)
        : false

      const urlGenerationSettings = isCampaignReadOnly
        ? (state.variablePlaceholders?.urlGenerationSettings ?? {
            domain: action.payload.site.urlGenerationSettings?.domain ?? '',
            subdomain: action.payload.site.urlGenerationSettings?.subdomain.current ?? ''
          })
        : {
            domain: action.payload.site.urlGenerationSettings?.domain ?? '',
            subdomain: action.payload.site.urlGenerationSettings?.subdomain.current ?? ''
          }

      state.variablePlaceholders = {
        urlGenerationSettings,
        supportEmail: action.payload.site.supportEmail || '',
        siteName: action.payload.site.name || '',
        siteUrl: action.payload.site.domain || ''
      }
    })

    // It's a separate action because of circular dependency
    builder.addCase(
      discountCodeValidationFailed,
      (state, action: PayloadAction<PayloadDiscountCodeValidationFailed>) => {
        const flow = selectEditorFlow(state)
        const flowMeta = selectEditorFlowMeta(state)

        if (!flow) {
          throw new Error(FlowEditorError.FlowIsNotLoaded)
        }

        const validDiscountCodes = new Set<string>(flowMeta.validDiscountCodes)
        const invalidDiscountCodes = new Set<string>(flowMeta.invalidDiscountCodes)
        const expiredDiscountCodes = new Set<string>(flowMeta.expiredDiscountCodes)

        validDiscountCodes.delete(action.payload.discountCodeName)

        if (action.payload.error.message === FlowEditorError.DiscountCodeExpired) {
          invalidDiscountCodes.delete(action.payload.discountCodeName)
          expiredDiscountCodes.add(action.payload.discountCodeName)
        }

        if (action.payload.error.message === FlowEditorError.DiscountCodeNotExists) {
          expiredDiscountCodes.delete(action.payload.discountCodeName)
          invalidDiscountCodes.add(action.payload.discountCodeName)
        }

        flowMeta.validDiscountCodes = Array.from(validDiscountCodes)
        flowMeta.invalidDiscountCodes = Array.from(invalidDiscountCodes)
        flowMeta.expiredDiscountCodes = Array.from(expiredDiscountCodes)

        flow.sequenceItems.forEach((flowItem) => {
          if (flowItemHasDiscountCode(flowItem, action.payload.discountCodeName)) {
            const flowItemMeta = selectFlowItemMeta(flowItem._id)(state)

            flowItemMeta.discountCodesToValidate = flowItemMeta.discountCodesToValidate.filter(
              (discountCode) => discountCode !== action.payload.discountCodeName
            )

            // Delete all discount code errors for this  discount code
            flowItemMeta.errors = flowItemMeta.errors.filter(
              (error) =>
                !discountCodeValidationErrors.includes(error.message as FlowEditorError) ||
                error.discountCode !== action.payload.discountCodeName
            )
            flowItemMeta.errors.push({
              ...action.payload.error,
              discountCode: action.payload.discountCodeName
            })

            if (isSMSMessageFlowItemUI(flowItem)) {
              flowItem.item.messages.forEach((message, messageIndex) => {
                const messageMeta = selectMessageMeta(flowItem._id, messageIndex)(state)

                // Delete all discount code errors for this  discount code
                messageMeta.errors = messageMeta.errors.filter(
                  (error) =>
                    !discountCodeValidationErrors.includes(error.message as FlowEditorError) ||
                    error.discountCode !== action.payload.discountCodeName
                )
                messageMeta.errors.push({
                  ...action.payload.error,
                  discountCode: action.payload.discountCodeName
                })

                messageMeta.textHTML = replaceRawValueToHtmlValue(state, message.text, flowItem._id)
              })
            }
          }
        })
      }
    )

    builder.addCase(
      discountCodeValidationSucceeded,
      (state, action: PayloadAction<{ discountCodeName: string }>) => {
        const flow = selectEditorFlow(state)
        const flowMeta = selectEditorFlowMeta(state)

        if (!flow) {
          throw new Error(FlowEditorError.FlowIsNotLoaded)
        }

        if (!flowMeta) {
          throw new Error('Flow meta is not found')
        }

        const validDiscountCodes = new Set<string>(flowMeta.validDiscountCodes)
        const invalidDiscountCodes = new Set<string>(flowMeta.invalidDiscountCodes)
        const expiredDiscountCodes = new Set<string>(flowMeta.expiredDiscountCodes)

        invalidDiscountCodes.delete(action.payload.discountCodeName)
        expiredDiscountCodes.delete(action.payload.discountCodeName)
        validDiscountCodes.add(action.payload.discountCodeName)

        flowMeta.validDiscountCodes = Array.from(validDiscountCodes)
        flowMeta.invalidDiscountCodes = Array.from(invalidDiscountCodes)
        flowMeta.expiredDiscountCodes = Array.from(expiredDiscountCodes)

        flow.sequenceItems.forEach((flowItem) => {
          if (flowItemHasDiscountCode(flowItem, action.payload.discountCodeName)) {
            const flowItemMeta = selectFlowItemMeta(flowItem._id)(state)

            flowItemMeta.discountCodesToValidate = flowItemMeta.discountCodesToValidate.filter(
              (discountCode) => discountCode !== action.payload.discountCodeName
            )

            // Delete all discount code errors for this  discount code
            flowItemMeta.errors = flowItemMeta.errors.filter(
              (error) =>
                !discountCodeValidationErrors.includes(error.message as FlowEditorError) ||
                error.discountCode !== action.payload.discountCodeName
            )
          }

          if (isSMSMessageFlowItemUI(flowItem)) {
            flowItem.item.messages.forEach((message, messageIndex) => {
              const messageMeta = selectMessageMeta(flowItem._id, messageIndex)(state)

              // Delete all discount code errors for this  discount code
              messageMeta.errors = messageMeta.errors.filter(
                (error) =>
                  !discountCodeValidationErrors.includes(error.message as FlowEditorError) ||
                  error.discountCode !== action.payload.discountCodeName
              )

              messageMeta.textHTML = replaceRawValueToHtmlValue(state, message.text, flowItem._id)
            })
          }
        })
      }
    )

    builder.addCase(nodeRemoved, (state, action) => {
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)

      if (!node) {
        throw new Error('Node is not found')
      }

      if (!node.data.flowItemId) {
        throw new Error('Cannot find flow item id on node')
      }

      const flowMeta = selectEditorFlowMeta(state)

      if (flowMeta.editingFlowItemId === node.data.flowItemId) {
        resetEditPanel(state)
      }

      deleteNode(state, node.id)
      updateFlowItemsTrigger(state)
    })

    builder.addCase(removeDeprecatedConditionalSplitClicked, (state, action) => {
      const node = selectNodeByFlowItemId(action.payload.flowItemId)(state)

      if (!node) {
        throw new Error('Node is not found')
      }

      const flowMeta = selectEditorFlowMeta(state)

      if (flowMeta.editingFlowItemId === action.payload.flowItemId) {
        resetEditPanel(state)
      }

      deleteNode(state, node.id)
      updateFlowItemsTrigger(state)
    })

    builder.addCase(localDraftChanged, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.unsavedChanges = true
    })

    builder.addCase(saveFlowThunk.pending, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isFlowSaving = true
      flowMeta.unsavedChanges = false
    })

    builder.addCase(saveFlowThunk.fulfilled, (state, action) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)
      flow!.updatedAt = action.payload.updatedAt
      flowMeta.isFlowSaving = false
      flowMeta.isEverSaved = true

      if (flowMeta.optinToolId) {
        const patchOptinTool: DeepPartial<OptinTool> = {
          settings: {
            sequenceId: flow?._id
          }
        }
        const patchOptinToolMeta: DeepPartial<OptinToolMeta> = {
          saved: false
        }
        patchLocalOptinTool(flowMeta.optinToolId, patchOptinTool, patchOptinToolMeta)
      }
    })

    builder.addCase(saveFlowThunk.rejected, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isFlowSaving = false
      flowMeta.unsavedChanges = true
    })

    builder.addCase(toggleFlowEditorFlowThunk.pending, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isToggling = true
    })

    builder.addCase(toggleFlowEditorFlowThunk.fulfilled, (state, action) => {
      const flow = selectEditorFlow(state)
      const flowMeta = selectEditorFlowMeta(state)
      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }
      flow.isEnabled = action.payload.isEnabled
      if (action.payload.isEnabled) {
        flow.hasEverBeenEnabled = true
      }
      flow.sequenceItems.forEach((flowItem) => {
        flowItem.isEnabled = action.payload.isEnabled
      })
      flowMeta.isToggling = false
      flowMeta.isEverSaved = true
    })

    builder.addCase(toggleFlowEditorFlowThunk.rejected, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isToggling = false
    })

    builder.addCase(saveSMSCampaignThunk.pending, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isSMSCampaignSaving = true
      flowMeta.unsavedChanges = false
    })

    builder.addCase(saveSMSCampaignThunk.fulfilled, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isSMSCampaignSaving = false
    })

    builder.addCase(saveSMSCampaignThunk.rejected, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.isSMSCampaignSaving = false
      flowMeta.unsavedChanges = true
    })

    builder.addCase(duplicateSMSCampaignThunk.pending, (state, action) => {
      const site = selectSite(state)

      if (state.variablePlaceholders) {
        state.variablePlaceholders = {
          ...state.variablePlaceholders,
          urlGenerationSettings: {
            domain: site?.urlGenerationSettings?.domain ?? '',
            subdomain: site?.urlGenerationSettings?.subdomain.current ?? ''
          }
        }
      }
    })

    builder.addCase(loadSMSCampaignThunk.fulfilled, (state, action) => {
      const flow = selectEditorFlow(state)
      const site = selectSite(state)

      if (!site?.id) {
        throw new Error('Site id is not loaded')
      }

      if (!flow) {
        throw new Error(FlowEditorError.FlowIsNotLoaded)
      }

      let smsCampaign = action.payload
      if (!smsCampaign) {
        const urlGenerationSettings = site.urlGenerationSettings
          ? {
              domain: site.urlGenerationSettings.domain,
              subdomain: site.urlGenerationSettings.subdomain.current
            }
          : undefined

        smsCampaign = getNewSMSCampaign({
          flow,
          siteId: site.id,
          urlGenerationSettings
        })

        // https://www.notion.so/recart/isQuietHoursEnabled-and-isSmartSendingEnabled-default-values-9281f483b42144b283e45dfc353a043e
        flow.isQuietHoursEnabled = true
        flow.isSmartSendingEnabled = false

        flow.sequenceItems.forEach((flowItem) => {
          flowItem.isQuietHoursEnabled = true
          flowItem.isSmartSendingEnabled = false
        })
      }

      if (state.variablePlaceholders && isSMSCampaignReadonly(smsCampaign)) {
        if (smsCampaign.domain) {
          state.variablePlaceholders.urlGenerationSettings.domain = smsCampaign.domain
        }

        if (smsCampaign.subdomain) {
          state.variablePlaceholders.urlGenerationSettings.subdomain = smsCampaign.subdomain
        }
      }

      state.smsCampaign = cloneDeep(smsCampaign)
      state.smsCampaignMeta = cloneDeep(smsCampaignInitialMeta)
    })

    builder.addCase(
      smsCampaignValidationFailed,
      (state, action: PayloadAction<PayloadSmsCampaignValidationFailed>) => {
        const flowMeta = selectEditorFlowMeta(state)
        flowMeta.errors = flowMeta.errors.filter(
          (error) => !smsCampaignValidationErrors.includes(error.message as FlowEditorError)
        )

        flowMeta.errors.push(...action.payload.errors)
      }
    )

    builder.addCase(smsCampaignValidationSucceeded, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.errors = flowMeta.errors.filter(
        (error) => !smsCampaignValidationErrors.includes(error.message as FlowEditorError)
      )
    })

    builder.addCase(
      flowValidationFailed,
      (state, action: PayloadAction<PayloadFlowValidationFailed>) => {
        const flowMeta = selectEditorFlowMeta(state)
        flowMeta.errors = flowMeta.errors.filter(
          (error) => !flowValidationErrors.includes(error.message as FlowEditorError)
        )

        flowMeta.errors.push(...action.payload.errors)
      }
    )

    builder.addCase(flowValidationSucceeded, (state) => {
      const flowMeta = selectEditorFlowMeta(state)
      flowMeta.errors = flowMeta.errors.filter(
        (error) => !flowValidationErrors.includes(error.message as FlowEditorError)
      )
    })

    builder.addCase(
      flowItemValidationFailed,
      (state, action: PayloadAction<PayloadFlowItemValidationFailed>) => {
        const flowItemMeta = selectFlowItemMeta(action.payload.flowItemId)(state)

        flowItemMeta.errors = flowItemMeta.errors.filter(
          (error) => !flowItemValidationErrors.includes(error.message as FlowEditorError)
        )

        action.payload.errors.forEach((error) => {
          flowItemMeta.errors.push(error)
        })
      }
    )

    builder.addCase(
      flowItemValidationSucceeded,
      (state, action: PayloadAction<{ flowItemId: string }>) => {
        const flowItemMeta = selectFlowItemMeta(action.payload.flowItemId)(state)

        flowItemMeta.errors = flowItemMeta.errors.filter(
          (error) => !flowItemValidationErrors.includes(error.message as FlowEditorError)
        )
      }
    )

    builder.addCase(
      messageValidationFailed,
      (state, action: PayloadAction<PayloadMessageValidationFailed>) => {
        const messageMeta = selectMessageMeta(
          action.payload.flowItemId,
          action.payload.messageIndex
        )(state)

        const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)

        messageMeta.errors = messageMeta.errors.filter(
          (error) => !messageValidationErrors.includes(error.message as FlowEditorError)
        )

        action.payload.errors.forEach((error) => {
          messageMeta.errors.push(error)
        })

        messageMeta.textHTML = replaceRawValueToHtmlValue(
          state,
          message.text,
          action.payload.flowItemId
        )
      }
    )

    builder.addCase(
      messageValidationSucceeded,
      (state, action: PayloadAction<{ flowItemId: string; messageIndex: number }>) => {
        const messageMeta = selectMessageMeta(
          action.payload.flowItemId,
          action.payload.messageIndex
        )(state)

        messageMeta.errors = messageMeta.errors.filter(
          (error) => !messageValidationErrors.includes(error.message as FlowEditorError)
        )

        const message = selectMessage(action.payload.flowItemId, action.payload.messageIndex)(state)

        messageMeta.textHTML = replaceRawValueToHtmlValue(
          state,
          message.text,
          action.payload.flowItemId
        )
      }
    )

    builder.addCase(quietHoursSettingsLoaded, (state, action) => {
      state.quietHoursConfig = action.payload
    })

    builder.addCase(isInQuietHoursByFlowItemIdCalculated, (state, action) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        return
      }

      flow.sequenceItems.forEach((flowItem) => {
        const flowItemMeta = selectFlowItemMeta(flowItem._id)(state)
        flowItemMeta.isInQuietHours = action.payload[flowItem._id]
      })
    })

    builder.addMatcher(isAnyOf(settingsReady), (state) => {
      const flow = selectEditorFlow(state)
      if (!flow) {
        return
      }
      flow.sequenceItems.forEach((flowItem) => {
        updateFlowItemMessageMeta(state, flowItem)
      })
    })

    /*************************************
     * Recalculating error count in editor
     *
     * There are three places where an error can occur:
     * 1. Flow level
     * 2. FlowItem level
     * 3. Message level
     * (4): discount code releted errors can occur on every level
     **************************************/
    builder.addMatcher(
      isAnyOf(
        nodeRemoved,
        discountCodeValidationFailed,
        discountCodeValidationSucceeded,
        flowValidationFailed,
        flowValidationSucceeded,
        smsCampaignValidationFailed,
        smsCampaignValidationSucceeded,
        messageValidationFailed,
        messageValidationSucceeded,
        flowItemValidationFailed,
        flowItemValidationSucceeded
      ),
      (state, action) => {
        const flow = selectEditorFlow(state)

        if (!flow) {
          return
        }

        /************************************
         * Counting Flow specific errors
         ***********************************/
        const flowMeta = selectEditorFlowMeta(state)
        // Only keep flowValidationErrors
        flowMeta.errors = flowMeta.errors.filter((error) =>
          [...flowValidationErrors, ...smsCampaignValidationErrors].includes(
            error.message as FlowEditorError
          )
        )
        flowMeta.errorCount = flowMeta.errors.filter(
          (error) => error.level === FlowEditorErrorLevel.Error
        ).length
        flowMeta.warningCount = flowMeta.errors.filter(
          (error) => error.level === FlowEditorErrorLevel.Warning
        ).length

        flow.sequenceItems.forEach((flowItem) => {
          const flowItemMeta = selectFlowItemMeta(flowItem._id)(state)
          const node = selectNodeByFlowItemId(flowItem._id)(state)

          if (!node) {
            throw new Error(`Cannot find node for flowItemId: ${flowItem._id}`)
          }

          /************************************
           * Counting Flow Item specific errors
           ***********************************/
          // Flow Items are containing message specific errors too, so for counting we
          // only want keep errors that are specific to the flow item, becase message
          // specific errors will be counted anyway
          const flowItemErrors = flowItemMeta.errors.filter((error) =>
            flowItemValidationErrors.includes(error.message as FlowEditorError)
          )

          flowMeta.errorCount += flowItemErrors.filter(
            (error) => error.level === FlowEditorErrorLevel.Error
          ).length
          flowMeta.warningCount += flowItemErrors.filter(
            (error) => error.level === FlowEditorErrorLevel.Warning
          ).length
          flowMeta.errors.push(
            ...flowItemErrors.map((error) => ({
              message: error.message,
              level: error.level,
              flowItemId: flowItem._id,
              nodeId: node.id
            }))
          )

          if (isSMSMessageFlowItemUI(flowItem)) {
            flowItem.item.messages.forEach((_, messageIndex) => {
              const messageMeta = selectMessageMeta(flowItem._id, messageIndex)(state)

              /************************************
               * Counting Message specific errors
               ***********************************/
              flowMeta.errorCount += messageMeta.errors.filter(
                (error) => error.level === FlowEditorErrorLevel.Error
              ).length
              flowMeta.warningCount += messageMeta.errors.filter(
                (error) => error.level === FlowEditorErrorLevel.Warning
              ).length
              flowMeta.errors.push(
                ...messageMeta.errors.map((error) => ({
                  message: error.message,
                  level: error.level,
                  flowItemId: flowItem._id,
                  nodeId: node.id,
                  messageIndex
                }))
              )
            })
          }
        })
      }
    )
  }
})

export const flowEditorActions = flowEditorSlice.actions
