import { type GetProductByProductIdResponse, type ProductVariant } from '@ghostmonitor/recartapis'
import { useQueries } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useProductsById(productIds: string[]): UseResource<ProductVariant.Product[]> {
  const productQueries = useQueries({
    queries: productIds?.map((productId: string) => {
      return {
        queryKey: [QUERY_NAME.product, productId],
        queryFn: () => request.get<GetProductByProductIdResponse>(`products/${productId}`)
      }
    })
  })

  const result: ProductVariant.Product[] = productQueries
    .filter((productQuery) => productQuery.data?.data !== undefined)
    .map((productQuery) => productQuery.data?.data)

  const isLoading = productQueries.some((productQuery) => productQuery.isLoading)

  return {
    data: result,
    isLoading
  }
}
