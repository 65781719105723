import { type Blast, BlastStatus } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLocalBlastBySequenceId } from '../../../../routes/SequenceEditor/utils/localstorage'
import { api } from '../../../../utils/api'
import type { AppDispatch } from '../../../create-store'
import { type DashboardState } from '../../../dashboard.state'

export interface LoadBlastArgs {
  siteId: string
  sequenceId: string
}

export const loadBlastThunk = createAsyncThunk<
  Blast | null,
  LoadBlastArgs,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/loadBlast', async (args, store) => {
  const { sequenceId } = args

  const sequenceExists = await api.sequenceExistsById(sequenceId)

  if (!sequenceExists) {
    return getLocalBlastBySequenceId(sequenceId)
  }

  const blastOnServer = await api.getBlastBySequenceId(sequenceId)
  const blastLocal = getLocalBlastBySequenceId(sequenceId)

  let blast: Blast = blastLocal
  if (blastOnServer) {
    blast = blastOnServer
    if ([BlastStatus.DRAFT].includes(blastOnServer.status)) {
      if (blastLocal !== null && blastLocal.updatedAt > blastOnServer.updatedAt) {
        blast = blastLocal
      }
    }
  }

  return blast
})
