import styles from './sequence-variable.style.scss'

interface SequenceVariableProps {
  placeholder: string
  id: string
  isFallbackEmpty: boolean
}

export function SequenceVariable(props: SequenceVariableProps) {
  const [, fallback] = props.id.split('|')
  const isIgnore = fallback === 'ignore'

  if (isIgnore) {
    return (
      <div
        data-testid='variable-ignored'
        className={styles.ignoredSequenceVariable}
        contentEditable
        id={props.id}
      >
        {props.placeholder}
      </div>
    )
  }

  return (
    <>
      {props.isFallbackEmpty ? (
        <span
          className={styles.customSequenceVariable}
          contentEditable={false}
          id={props.id}
          data-testid='variable-without-fallback'
        >
          {props.placeholder}
          <div contentEditable={false} className={styles.questionMark} id={props.id}>
            ?
          </div>
        </span>
      ) : (
        <span
          className={styles.sequenceVariable}
          contentEditable={false}
          id={props.id}
          data-testid='variable-with-fallback'
        >
          {props.placeholder}
        </span>
      )}
    </>
  )
}
