import {
  type SMSCampaign,
  type SMSCampaignStatus,
  type SMSCampaignUpdateResponse
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { api } from '../../../../utils/api'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors, selectLaunchDarklyFlag } from '../../../selectors'
import { type ThunkAPI } from '../../../types/thunk-api.type'
import { selectSite } from '../../me/me.selectors'
import { saveFlowThunk } from './save-flow.thunk'
import { toggleFlowEditorFlowThunk } from './toggle-flow-editor-flow.thunk'
import { validateSMSCampaignThunk } from './validate-sms-campaign.thunk'
import { type ValidateFlowThunkBypassWarnings } from './validate-flow.thunk'

interface SaveSMSCampaignThunkArgs extends ValidateFlowThunkBypassWarnings {
  status?: SMSCampaignStatus
}

export const saveSMSCampaignThunk = createAsyncThunk(
  'flowEditor/saveSMSCampaign',
  async (args: SaveSMSCampaignThunkArgs = {}, thunkAPI: any) => {
    const { getState, dispatch }: ThunkAPI = thunkAPI
    const state = getState()
    const smsCampaign: Partial<SMSCampaign> | undefined = cloneDeep(
      flowEditorSelectors.selectEditorSMSCampaign(state)
    )
    const isMultipleDomainEnabled = selectLaunchDarklyFlag('multiple-domains')(state)

    if (!smsCampaign) {
      throw new Error(FlowEditorError.SMSCampaignIsNotLoaded)
    }

    if (args.status) {
      smsCampaign.status = args.status
    }

    const validationResult = await dispatch(
      validateSMSCampaignThunk({
        validateDiscountCodePool: false,
        bypassWarnings: args.bypassWarnings
      })
    )

    if (validateSMSCampaignThunk.rejected.match(validationResult)) {
      throw new Error(FlowEditorError.SMSCampaignValidationFailed, {
        cause: validationResult.error
      })
    }

    const saveFlowResult = await dispatch(
      saveFlowThunk({
        bypassWarnings: args.bypassWarnings
      })
    )

    if (saveFlowThunk.rejected.match(saveFlowResult)) {
      throw new Error(FlowEditorError.SaveFlowFailed, { cause: saveFlowResult.error })
    }

    /*****************************************************************
     * It's a current architecture limitation that all flows that
     * belong to an SMS campaign must be active. Also, after saving
     * an SMS campaign Flow, trigger cannot be changed.
     * https://recartapp.slack.com/archives/C02LD5JDHNV/p1676386445313649
     ******************************************************************/
    const toggleFlowResult = await dispatch(
      toggleFlowEditorFlowThunk({
        isEnabled: true,
        bypassWarnings: args.bypassWarnings
      })
    )
    if (toggleFlowEditorFlowThunk.rejected.match(toggleFlowResult)) {
      throw new Error(FlowEditorError.ToggleFlowFailed, { cause: toggleFlowResult.error })
    }

    // SMS campaign doesn't have a name, it has the Flow's name
    delete smsCampaign.name
    delete smsCampaign.createdAt
    delete smsCampaign.updatedAt

    const site = selectSite(state.me)
    const currentSubdomain = site?.urlGenerationSettings?.subdomain?.current
    if (currentSubdomain) {
      smsCampaign.subdomain = currentSubdomain
    }

    const currentDomain = site?.urlGenerationSettings?.domain
    if (isMultipleDomainEnabled && currentDomain) {
      smsCampaign.domain = currentDomain
    }

    let response: SMSCampaignUpdateResponse
    try {
      response = await api.saveSMSCampaign(smsCampaign as SMSCampaign)
    } catch (err) {
      throw new Error(FlowEditorError.SaveSMSCampaignFailed, { cause: err })
    }

    return response.data
  },
  { serializeError }
)
