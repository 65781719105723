import {
  type LogicConditionalSplitCondition,
  type LogicConditionalSplitConditionAPI,
  type LogicConditionalSplitV2UI,
  LogicConditionOperator,
  type LogicRandomSplitVariant
} from '@ghostmonitor/recartapis'
import { type NullConditionUI } from '../../types/segment/condition-ui.type'

export const DEFAULT_SPLIT_VALUE: LogicRandomSplitVariant = { percentage: 50, trigger: null }

export const conditionList: { [idx: string]: LogicConditionalSplitCondition & { name: string } } = {
  hasItemsInCart: {
    name: 'hasItemsInCart',
    variable: 'shopper.hasItemsInCart',
    operation: LogicConditionOperator.EQ,
    value: 'true'
  },
  hasPlacedOrderWithin30Days: {
    name: 'hasPlacedOrderWithin30Days',
    variable: 'shopper.hasPlacedOrderWithin30Days',
    operation: LogicConditionOperator.EQ,
    value: 'true'
  },
  male: {
    name: 'male',
    variable: 'user_variables.gender',
    operation: LogicConditionOperator.EQ,
    value: 'male'
  },
  female: {
    name: 'female',
    variable: 'user_variables.gender',
    operation: LogicConditionOperator.EQ,
    value: 'female'
  }
}

// TODO! to meet the requirements of this ticket, the two gender based options were removed
// assuming that this is for SMS only flows
// https://app.shortcut.com/recart/story/32632/remove-gender-based-conditions-from-sms-sequence-editor
export const conditionalSplitSelector = [
  { value: 'hasItemsInCart', label: 'Has items in cart' },
  { value: 'hasPlacedOrderWithin30Days', label: 'Placed order in 30 days' }
]

export const conditionalSplitConditions = [
  {
    name: 'hasItemsInCart',
    variable: 'shopper.hasItemsInCart',
    value: 'true',
    label: 'Has items in cart'
  },
  {
    name: 'hasPlacedOrderWithin30Days',
    variable: 'shopper.hasPlacedOrderWithin30Days',
    value: 'true',
    label: 'Placed order in 30 days'
  },
  {
    name: 'male',
    variable: 'user_variables.gender',
    value: 'male',
    label: 'Male'
  },
  {
    name: 'female',
    variable: 'user_variables.gender',
    value: 'female',
    label: 'Female'
  }
]

export const DEFAULT_CONDITION: LogicConditionalSplitConditionAPI = {
  variable: conditionList.hasItemsInCart.variable,
  operation: conditionList.hasItemsInCart.operation,
  value: conditionList.hasItemsInCart.value
}

export const DEFAULT_EXPRESSION: LogicConditionalSplitV2UI = {
  cases: [
    {
      expression: {
        and: [
          {
            or: [
              {
                slug: null,
                type: null,
                operator: null,
                value: null
              } as NullConditionUI
            ]
          }
        ]
      },
      nextFlowItemTrigger: ''
    }
  ],
  defaultNextFlowItemTrigger: ''
}

export enum InsertType {
  LINK = 'link',
  EMOJI = 'emoji',
  DISCOUNT_CODE = 'discount-code',
  VARIABLE = 'variable',
  USER_VARIABLE = 'user-variable',
  KEYWORD_REPLY = 'keyword-reply'
}
