import { SequenceTag } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { showConfirmModal } from '../../../../../components/ui-kit/modals/confirm-modal/confirm-modal.component'
import { hooks } from '../../../../../hooks/hooks'
import { useSequencesByTags } from '../../../../../hooks/resources/use-sequences-by-tags'
import { useJoinedASegmentFlowCheck } from '../../../../../hooks/use-joined-a-segment-flow-check'
import { ReactComponent as ExclamationIcon } from '../../../../../static/ui-kit/icons/normal/exclamation.svg'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectErrorCount
} from '../../../../../store/selectors'
import { useSaveSequenceEditor } from '../../../hooks/use-save-sequence'
import { isSMSSequence } from '../../../utils/assert-sequence-type'

export function JoinedASegmentSaveButton() {
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const errorCount = useSelector(selectErrorCount)
  const sequence = useSelector(selectEditorSequence)
  const { data: joinedASegmentSequences } = useSequencesByTags([SequenceTag.JOINED_A_SEGMENT])
  const { isValidSequenceToSave } = useJoinedASegmentFlowCheck(sequence, joinedASegmentSequences)
  const { saveSequenceEditor } = useSaveSequenceEditor()
  const hasMMS = hooks.useHasMMS()
  const hasInternationalPhoneNumber = hooks.useHasInternationPhoneNumber()

  async function handleSave() {
    await saveSequenceEditor()
  }

  async function handleClickOnSaveButton() {
    if (
      sequence.isEnabled &&
      sequence.tags.includes(SequenceTag.AUTOMATED) &&
      isSMSSequence(sequence) &&
      hasMMS &&
      hasInternationalPhoneNumber
    ) {
      showConfirmModal({
        icon: <ExclamationIcon className='icon-sm' />,
        title: 'Messages are SMS only in some countries',
        cancelText: 'Back to editing',
        okText: 'Save',
        onOk: handleSave,
        content:
          'Subscribers outside of US and Canada will only receive the text part of the message without attachments (as an SMS).'
      })
    } else {
      await handleSave()
    }
  }

  const isDisabled =
    errorCount > 0 || !sequenceMeta.unsavedChanges || sequenceMeta.saving || !isValidSequenceToSave

  return (
    <div className='flex items-center'>
      <EditorButton
        disabled={isDisabled}
        onClick={handleClickOnSaveButton}
        type={sequence.isEnabled ? 'primary' : 'default'}
        data-testid='header-joined-a-segment-save-button'
      >
        {sequence.isEnabled ? 'Save' : 'Save as draft'}
      </EditorButton>
    </div>
  )
}
