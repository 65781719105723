import { SMSCounterBox } from '../sms-counter-box copy/sms-counter-box.component'

export interface SMSCounterRowProps {
  unit?: string
  messageText: string
  isCharCountHidden?: boolean
}

export function SMSCounterRow(props: SMSCounterRowProps) {
  return (
    <div className='mt-4 mb-3 flex justify-end'>
      <SMSCounterBox {...props} />
    </div>
  )
}
