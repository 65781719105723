import { Button } from 'antd'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Tooltip } from '../../../../components/ui-kit/tooltip/tooltip.component'
import checkmarkBlueIconSrc from '../../../../static/images/svg/check-mark-blue.svg'
import {
  hideOverlay,
  showOverlay
} from '../sequence-editor-overlay/sequence-editor-overlay.component'
import styles from './copy-button.component.scss'

export interface CopyButtonProps {
  copyText: string
  buttonText: string
  disabled?: boolean
}

function CopiedOverlayContent() {
  return (
    <div className={styles.copied} onClick={hideOverlay}>
      <div className={styles.content}>
        <img src={checkmarkBlueIconSrc} alt='Checkmark' />
        <p>Copied</p>
      </div>
    </div>
  )
}

export function CopyButton(props: CopyButtonProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  function handleClick() {
    showOverlay(CopiedOverlayContent, 64)
    setTimeout(hideOverlay, 1000)
  }

  function handleTooltipVisible(visible: boolean): void {
    if (props.disabled) {
      setIsTooltipVisible(visible)
    }
  }

  return (
    <div className={styles.copyButton}>
      <CopyToClipboard text={props.copyText}>
        <Tooltip
          title='Please save the flow before copying the link.'
          placement='bottomLeft'
          onOpenChange={handleTooltipVisible}
          open={isTooltipVisible}
          arrowPointAtCenter
        >
          <Button onClick={handleClick} disabled={props.disabled}>
            {props.buttonText}
          </Button>
        </Tooltip>
      </CopyToClipboard>
    </div>
  )
}
