import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  type AppIntegration,
  type IntegrationSlug
} from '../../../routes/integrations/integrations.type'
import { request } from '../../../utils/request'

export const fetchAppIntegrationThunk = createAsyncThunk(
  'integrations/fetchAppIntegration',
  async (payload: { integrationSlug: IntegrationSlug }) => {
    return request.get<AppIntegration>(`integrations/${payload.integrationSlug}`)
  }
)

export const connectAppIntegrationThunk = createAsyncThunk(
  'integrations/connectAppIntegration',
  async (payload: { integrationSlug: IntegrationSlug; data: Partial<AppIntegration> }) => {
    const params: Partial<AppIntegration> = {
      integrationName: payload.integrationSlug,
      isEnabled: true,
      ...payload.data
    }

    return request.post('integrations', params)
  }
)

export const updateAppIntegrationThunk = createAsyncThunk(
  'integrations/updateAppIntegration',
  async (payload: { integrationSlug: IntegrationSlug; data: Partial<AppIntegration> }) => {
    return request.patch<AppIntegration>(`integrations/${payload.integrationSlug}`, payload.data)
  }
)

export const deleteAppIntegrationThunk = createAsyncThunk(
  'integrations/deleteAppIntegrationThunk',
  async (payload: { integrationSlug: IntegrationSlug }) => {
    const data = {
      isEnabled: false,
      integrationConfig: null,
      apiKey: null
    }

    return request.patch<AppIntegration>(`integrations/${payload.integrationSlug}`, data)
  }
)
