import { createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../../../utils/flow-editor/flow-editor-errors'
import { loadScheduledFor } from '../../../../utils/scheduled-for-date-handling'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors, selectSiteTimezone } from '../../../selectors'
import { type ThunkAPI } from '../../../types/thunk-api.type'
import { smsCampaignValidationFailed, smsCampaignValidationSucceeded } from '../flow-editor.actions'
import { isInTimeRange } from '../../../../utils/quiet-hours/quiet-hours-status'
import { emptyQuietHoursConfig } from './utils/empty-quiet-hours-config'

interface ValidateSMSCampaignThunkArgs {
  validateDiscountCodePool?: boolean
  bypassWarnings?: {
    isScheduledInQuietHours?: boolean
  }
}

export const validateSMSCampaignThunk = createAsyncThunk<unknown, ValidateSMSCampaignThunkArgs>(
  'flowEditor/validateSMSCampaign',
  async (args: ValidateSMSCampaignThunkArgs, thunkAPI: any) => {
    const { validateDiscountCodePool = true }: ValidateSMSCampaignThunkArgs = args
    const { dispatch, getState }: ThunkAPI = thunkAPI
    const state = getState()

    const flow = flowEditorSelectors.selectEditorFlow(state)
    const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)
    const smsCampaignMeta = flowEditorSelectors.selectEditorSMSCampaignMeta(state)
    const quietHoursConfig = flowEditorSelectors.selectQuietHoursConfig(state)

    let timezone = selectSiteTimezone(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    if (!smsCampaign) {
      throw new Error(FlowEditorError.SMSCampaignIsNotLoaded)
    }

    if (!smsCampaignMeta) {
      throw new Error(FlowEditorError.SMSCampaignMetaIsNotLoaded)
    }

    const smsCampaignValidationErrors: FlowEditorValidationError[] = []

    const { isPoolSizeEnough, isAvailableCountEnough } = smsCampaignMeta

    if (isPoolSizeEnough === undefined || isAvailableCountEnough === undefined) {
      throw new Error(FlowEditorError.FlowMetaAnomaly, {
        cause: 'isPoolSizeEnough or isAvailableCountEnough is undefined'
      })
    }

    // TODO move it from here
    if (!timezone) {
      timezone = moment.tz.guess()
    }

    if (!isPoolSizeEnough && validateDiscountCodePool) {
      smsCampaignValidationErrors.push({
        message: FlowEditorError.DiscountCodePoolSizeNotEnough,
        level: FlowEditorErrorLevel.Error
      })
    }

    if (!smsCampaign.scheduledFor && !isAvailableCountEnough && validateDiscountCodePool) {
      smsCampaignValidationErrors.push({
        message: FlowEditorError.DiscountCodePoolNotEnoughAvailable,
        level: FlowEditorErrorLevel.Error
      })
    }

    if (smsCampaign.scheduledFor) {
      const scheduledFor = loadScheduledFor(
        smsCampaign.scheduledFor,
        smsCampaign.scheduleType,
        timezone
      )
      const now = moment.tz(timezone)

      const isInThePast = scheduledFor.isBefore(now)
      if (isInThePast) {
        smsCampaignValidationErrors.push({
          message: FlowEditorError.CampaignSchedulingInvalid,
          level: FlowEditorErrorLevel.Error
        })
      }

      if (quietHoursConfig && !args.bypassWarnings?.isScheduledInQuietHours) {
        const loadedDateTimeOfDay = {
          hour: scheduledFor.hour(),
          minute: scheduledFor.minute()
        }
        const { start: quietHoursStart, end: quietHoursEnd } = quietHoursConfig
        const { start: emptyQuietHoursStart, end: emptyQuietHoursEnd } = emptyQuietHoursConfig

        if (
          isInTimeRange(
            loadedDateTimeOfDay,
            quietHoursStart ?? emptyQuietHoursStart,
            quietHoursEnd ?? emptyQuietHoursEnd
          )
        ) {
          smsCampaignValidationErrors.push({
            message: FlowEditorError.CampaignScheduledToQuietHours,
            level: FlowEditorErrorLevel.Warning
          })
        }
      }
    }

    const hasError = smsCampaignValidationErrors.length > 0

    if (hasError) {
      dispatch(smsCampaignValidationFailed({ errors: smsCampaignValidationErrors }))

      const isError = smsCampaignValidationErrors.some(
        (error) => error.level === FlowEditorErrorLevel.Error
      )
      const isWarning =
        !isError &&
        smsCampaignValidationErrors.some((error) => error.level === FlowEditorErrorLevel.Warning)

      throw new Error(FlowEditorError.SMSCampaignValidationFailed, {
        cause: { message: smsCampaignValidationErrors[0].message, isError, isWarning }
      })
    }

    dispatch(smsCampaignValidationSucceeded())
  },
  { serializeError }
)
