import { NodeType } from '@ghostmonitor/recartapis'
import { AbstractNodeFactory, type DiagramEngine } from 'storm-react-diagrams'
import { SMSMessageSequenceItemNodeWidget } from '../../../components/sequence-item-node-widget/sms-message-node-widget/sms-sequence-item-node-widget.component'
import { SMSMessageSequenceItemNodeModel } from './sms-message-sequence-item-model'

export class SMSMessageSequenceItemNodeFactory extends AbstractNodeFactory {
  constructor(private readonly repaintCanvas: VoidFunction = () => undefined) {
    super(NodeType.SMS_SEQUENCE_ITEM)
  }

  public generateReactWidget(
    diagramEngine: DiagramEngine,
    sequenceItemNode: SMSMessageSequenceItemNodeModel
  ): JSX.Element {
    return <SMSMessageSequenceItemNodeWidget sequenceItemNode={sequenceItemNode} />
  }

  public getNewInstance(): SMSMessageSequenceItemNodeModel {
    return new SMSMessageSequenceItemNodeModel()
  }
}
