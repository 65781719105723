import { type ConditionalSplitFlowItemUI } from '@ghostmonitor/recartapis'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'

export function validateConditionalSplitFlowItemMustHaveConnection(
  flowItem: ConditionalSplitFlowItemUI
): FlowEditorValidationError | undefined {
  if (
    flowItem.item.logic.conditional_split.default_trigger === undefined &&
    flowItem.item.logic.conditional_split.variants[0].trigger === undefined
  ) {
    return {
      message: FlowEditorError.ConditionalSplitFlowItemMustHaveConnection,
      level: FlowEditorErrorLevel.Error
    }
  }

  return undefined
}
