/* global __DEV__ */
import '@babel/polyfill'
import storage from 'local-storage-fallback'
import ReactDOM from 'react-dom'
import { App } from './components/app/app.component'

if (!('localStorage' in window)) {
  window.localStorage = storage
}

if (__DEV__) {
  if (module.hot) {
    module.hot.accept()
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
