// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IbjgewCTe09GQbzESZaV .ant-skeleton{width:100%!important}.IbjgewCTe09GQbzESZaV .ant-skeleton li{width:95%!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/settings-drawer/components/blast-settings-drawer-read-only/blast-settings-drawer-read-only.component.scss"],"names":[],"mappings":"AAEI,oCACE,oBAAA,CAEA,uCACE,mBAAA","sourcesContent":[":local {\n  .loader {\n    :global(.ant-skeleton) {\n      width: 100% !important;\n\n      li {\n        width: 95% !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `IbjgewCTe09GQbzESZaV`
};
export default ___CSS_LOADER_EXPORT___;
