import { type SMSCampaign, SMSCampaignStatus } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSMSCampaignLocalStorage } from '../../../../routes/flow-editor/utils/local-storage'
import { api } from '../../../../utils/api'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors } from '../../../selectors'
import { type ThunkAPI } from '../../../types/thunk-api.type'
import { quietHoursSettingsLoaded } from '../flow-editor.actions'
import { emptyQuietHoursConfig } from './utils/empty-quiet-hours-config'

export interface LoadSMSCampaignArgs {
  siteId: string
  flowId: string
}

export const loadSMSCampaignThunk = createAsyncThunk(
  'flowEditor/loadSMSCampaign',
  async (args: LoadSMSCampaignArgs, thunkAPI: any) => {
    const { flowId } = args
    const { getState, dispatch }: ThunkAPI = thunkAPI
    const state = getState()
    const flow = flowEditorSelectors.selectEditorFlow(state)
    const isEverSaved = flowEditorSelectors.selectIsEverSaved(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    const quietHoursSettings = await api.getSMSQuietHoursSettings()
    const quietHoursConfig = quietHoursSettings.data ?? emptyQuietHoursConfig
    dispatch(quietHoursSettingsLoaded(quietHoursConfig))

    const smsCampaignLocal = getSMSCampaignLocalStorage(flowId)

    /************************************************
     * CASE 1: New SMS campaign for an unsaved flow
     ************************************************/
    if (!isEverSaved && smsCampaignLocal === null) {
      // Reducer will know that in this case, we need to create a new SMS campaign
      return null
    }

    let smsCampaignServer: SMSCampaign | null = null
    try {
      smsCampaignServer = await api.getSMSCampaignByFlowId(flowId)
    } catch (err) {
      throw new Error(FlowEditorError.GetSMSCampaignByFlowIdFailed, { cause: err })
    }

    if (smsCampaignServer) {
      if ([SMSCampaignStatus.DRAFT].includes(smsCampaignServer.status)) {
        // If the local version is newer than the server version, use the local version
        if (smsCampaignLocal !== null && smsCampaignLocal.updatedAt > smsCampaignServer.updatedAt) {
          /*****************************************************
           * CASE 2: Server version is older than local version
           *****************************************************/
          return smsCampaignLocal
        }
      }

      /*****************************************************
       * CASE 3: Server version is newer than local version
       *****************************************************/
      return smsCampaignServer
    }

    if (smsCampaignLocal !== null) {
      /********************************************
       * CASE 4: Only browser draft version exists
       ********************************************/
      return smsCampaignLocal
    }

    // Anomaly
    throw new Error(FlowEditorError.SMSCampaignIsNotLoaded)
  },
  { serializeError }
)
