import { type RecentCampaignFlowsListItem } from '../../../../../../types/recent-campaign-flows-list-item/recent-campaign-flows-list-item.type'

export function sortRecentCampaignFlowsItemByDate(
  itemA: RecentCampaignFlowsListItem,
  itemB: RecentCampaignFlowsListItem
): number {
  if (itemA.startDate === undefined && itemB.startDate === undefined) {
    return itemA.createdAt! < itemB.createdAt! ? 1 : -1
  }

  if (itemA.startDate === undefined) {
    return 1
  }

  if (itemB.startDate === undefined) {
    return -1
  }

  return itemA.startDate! < itemB.startDate! ? 1 : -1
}
