import { type Action, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import type { AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { duplicateBlastThunk } from '../../../../store/modules/blast/blast.thunks'
import { patchSequence } from '../../../../store/modules/sequence/sequence.actions'
import {
  selectEditorBlast,
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectSequenceItem
} from '../../../../store/selectors'
import {
  dropSMSContactCard,
  dropSMSMedia,
  finishCustomConversationWizard,
  localDraftChanged,
  mediaUploadCompleted,
  renameBlast,
  sequenceCreated,
  sequenceLoadedFromServer,
  updateSequenceItem
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { loadBlastThunk } from '../../../../store/slices/sequence-editor/thunks/load-blast.thunk'
import { saveSequenceThunk } from '../../../../store/slices/sequence-editor/thunks/save-sequence.thunk'
import {
  isBlastUpdateAction,
  isChangeSequenceItemAction,
  isCreateSequenceItemAction,
  isDiagramUpdateAction,
  isRemoveSequenceItemAction,
  isSequenceItemSyncedWithSequenceAction,
  isSequenceUpdateAction,
  isUpdateModelOnSequenceAction
} from '../../utils/assert-action-type'
import {
  createLocalDraftSequenceEditorInstance,
  getLocalDraftSequenceEditorInstance,
  patchLocalDraftSequenceEditorInstance,
  removeLocalDraftSequenceItem,
  updateLocalDraftSequence,
  updateLocalDraftSequenceItem
} from '../../utils/draft'
import { setLocalBlast } from '../../utils/localstorage'
import { sanitizeSerializedDiagram } from '../../utils/sanitize-serialized-diagram'

const DEBOUNCE_DELAY = 500

function getLocalDraftChanged(dispatch: AppDispatch) {
  return debounce(
    () => {
      dispatch(localDraftChanged())
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

export function draftMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const dispatchLocalDraftChanged = getLocalDraftChanged(dispatch)

  return (next) => (action: Action) => {
    next(action)

    const state = store.getState()
    const sequence = selectEditorSequence(state)
    const sequenceMeta = selectEditorSequenceMeta(state)
    const blast = selectEditorBlast(state)

    function handleUpdateSequence() {
      const sequenceEditorInstance = getLocalDraftSequenceEditorInstance(sequence._id)
      if (sequenceEditorInstance === null) {
        createLocalDraftSequenceEditorInstance(sequence, sequenceMeta)
      } else {
        const currentSerializedDiagram = sequenceEditorInstance.sequence.serializedDiagram
        const newSerializedDiagram = sequence?.serializedDiagram

        if (
          currentSerializedDiagram &&
          newSerializedDiagram &&
          !isEqual(
            sanitizeSerializedDiagram(currentSerializedDiagram),
            sanitizeSerializedDiagram(newSerializedDiagram)
          )
        ) {
          dispatchLocalDraftChanged()
        }

        // The assumption here is, that only actions that changed the diagram can cause unsaved change
        // so we don't have to check if sequence has changed compared to the previous state
        updateLocalDraftSequence(sequence._id, sequence)
      }
    }

    if (isUpdateModelOnSequenceAction(action) || isSequenceItemSyncedWithSequenceAction(action)) {
      const sequenceEditorInstance = getLocalDraftSequenceEditorInstance(sequence._id)
      // Wait for the create actions to initialize the instance
      if (sequenceEditorInstance !== null) {
        handleUpdateSequence()
        sequence.sequenceItemIds.forEach((sequenceItemId) => {
          const sequenceItem = selectSequenceItem(sequenceItemId)(state)
          updateLocalDraftSequenceItem(sequenceItem)
        })
      }
    }

    if (isDiagramUpdateAction(action)) {
      const sequenceEditorInstance = getLocalDraftSequenceEditorInstance(sequence._id)
      // wait for the create actions to initialize the instance
      if (sequenceEditorInstance !== null) {
        handleUpdateSequence()
      }
    }

    if (isCreateSequenceItemAction(action)) {
      updateLocalDraftSequenceItem(action.sequenceItem)
      handleUpdateSequence()
      dispatchLocalDraftChanged()
    }

    if (updateSequenceItem.match(action)) {
      const sequenceItem = selectSequenceItem(action.payload.sequenceItem._id)(state)
      updateLocalDraftSequenceItem(sequenceItem)
    }

    if (isChangeSequenceItemAction(action)) {
      const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
      updateLocalDraftSequenceItem(sequenceItem)
      dispatchLocalDraftChanged()
    }

    if (dropSMSMedia.match(action)) {
      const sequenceItem = selectSequenceItem(action.meta.sequenceItemId)(state)
      updateLocalDraftSequenceItem(sequenceItem)
      dispatchLocalDraftChanged()
    }

    if (dropSMSContactCard.match(action)) {
      const sequenceItem = selectSequenceItem(action.meta.sequenceItemId)(state)
      updateLocalDraftSequenceItem(sequenceItem)
      dispatchLocalDraftChanged()
    }

    if (mediaUploadCompleted.match(action)) {
      const sequenceItem = selectSequenceItem(action.payload.sequenceItemId)(state)
      updateLocalDraftSequenceItem(sequenceItem)
      dispatchLocalDraftChanged()
    }

    if (isRemoveSequenceItemAction(action)) {
      removeLocalDraftSequenceItem(action.sequenceItemId)
      handleUpdateSequence()
      dispatchLocalDraftChanged()
    }

    if (saveSequenceThunk.fulfilled.match(action)) {
      // TODO rework once partial success response is available
      const sequenceFromResponse = action.payload

      patchLocalDraftSequenceEditorInstance(sequence._id, {
        isEverSaved: true,
        unsavedChanges: false,
        serverUpdatedAt: sequenceFromResponse.updatedAt
      })
    }

    if (sequenceLoadedFromServer.match(action)) {
      const sequence = selectEditorSequence(state)
      createLocalDraftSequenceEditorInstance(sequence, {
        isEverSaved: true,
        unsavedChanges: false
      })
    }

    if (sequenceCreated.match(action)) {
      const sequence = selectEditorSequence(state)
      createLocalDraftSequenceEditorInstance(sequence, {
        isEverSaved: false,
        unsavedChanges: true
      })
    }

    if (patchSequence.match(action)) {
      // Sequence is already patched in store
      const sequence = selectEditorSequence(state)
      updateLocalDraftSequence(action.meta.sequenceId, sequence)
    }

    if (isSequenceUpdateAction(action)) {
      updateLocalDraftSequence(sequence._id, sequence)
      dispatchLocalDraftChanged()
    }

    if (finishCustomConversationWizard.match(action)) {
      // Sequence is already patched in store
      const sequence = selectEditorSequence(state)
      updateLocalDraftSequence(action.payload.sequenceId, sequence)

      const sequenceItem = selectSequenceItem(sequence.entrySequenceItemId)(state)
      updateLocalDraftSequenceItem(sequenceItem)
      dispatchLocalDraftChanged()
    }

    if (loadBlastThunk.fulfilled.match(action) || duplicateBlastThunk.fulfilled.match(action)) {
      setLocalBlast(blast)
    }

    if (renameBlast.match(action)) {
      updateLocalDraftSequence(sequence._id, sequence)
    }

    if (isBlastUpdateAction(action)) {
      setLocalBlast(blast)
      dispatchLocalDraftChanged()
    }
  }
}
