import {
  isSMSMessageContactCardUI,
  isSMSMessageMMSUI,
  isSMSMessageTextUI,
  isSMSSequenceItemUI
} from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { useSiteSelector } from '../../../../../hooks/use-site-selector'
import {
  selectEditorBlast,
  selectEditorSequence,
  selectIsReadOnly,
  selectSequenceItemsById
} from '../../../../../store/selectors'
import {
  selectSiteName,
  selectUrlGenerationSettings,
  selectSupportEmail
} from '../../../../../store/slices/site/site.selectors'
import { getSequenceEditorSMSStat } from '../../../utils/get-sms-stat'
import { isSequenceItemLinked } from '../../../utils/is-sequence-item-linked'

export interface UseSMSSegmentCountInSequence {
  smsSegmentCount: number
  mmsSegmentCount: number
  mmsToSmsSegmentCount: number
}

export function useSMSSegmentCountInSequence() {
  const blast = useSelector(selectEditorBlast)
  const sequence = useSelector(selectEditorSequence)
  const urlGenerationSettings = useSiteSelector(selectUrlGenerationSettings)
  const supportEmail = useSiteSelector(selectSupportEmail)
  const isReadOnly = useSelector(selectIsReadOnly)
  const siteName = useSiteSelector(selectSiteName)
  const zeroedSegmentCount: UseSMSSegmentCountInSequence = {
    smsSegmentCount: 0,
    mmsSegmentCount: 0,
    mmsToSmsSegmentCount: 0
  }
  const sequenceItemsById = useSelector(selectSequenceItemsById)

  if (!blast || !sequence?.sequenceItemIds || siteName === undefined) {
    return zeroedSegmentCount
  }

  const sequenceItems = sequence.sequenceItemIds.map(
    (sequenceItemId) => sequenceItemsById[sequenceItemId]
  )

  const statConfig = {
    siteName,
    urlGenerationSettings,
    supportEmail,
    blast,
    isReadOnly
  }

  return sequenceItems.reduce<UseSMSSegmentCountInSequence>((itemAccumulator, sequenceItem) => {
    if (!sequence.serializedDiagram) {
      return itemAccumulator
    }

    const isEntryOrLinkedItem =
      sequenceItem._id === sequence.entrySequenceItemId ||
      isSequenceItemLinked(sequenceItem._id, sequence.serializedDiagram)

    if (!isSMSSequenceItemUI(sequenceItem) || !isEntryOrLinkedItem) {
      return itemAccumulator
    }

    const { smsSegmentCount, mmsSegmentCount, mmsToSmsSegmentCount } =
      sequenceItem.messages.reduce<UseSMSSegmentCountInSequence>((messageAccumulator, message) => {
        if (isSMSMessageTextUI(message)) {
          const { segmentCount } = getSequenceEditorSMSStat(message.text, statConfig)
          return {
            ...messageAccumulator,
            smsSegmentCount: messageAccumulator.smsSegmentCount + segmentCount
          }
        }
        if (isSMSMessageMMSUI(message) || isSMSMessageContactCardUI(message)) {
          const { segmentCount } = getSequenceEditorSMSStat(message.text, statConfig)
          return {
            ...messageAccumulator,
            mmsSegmentCount: messageAccumulator.mmsSegmentCount + 1,
            mmsToSmsSegmentCount: messageAccumulator.mmsToSmsSegmentCount + segmentCount
          }
        }
        return messageAccumulator
      }, zeroedSegmentCount)

    return {
      smsSegmentCount: itemAccumulator.smsSegmentCount + smsSegmentCount,
      mmsSegmentCount: itemAccumulator.mmsSegmentCount + mmsSegmentCount,
      mmsToSmsSegmentCount: itemAccumulator.mmsToSmsSegmentCount + mmsToSmsSegmentCount
    }
  }, zeroedSegmentCount)
}
