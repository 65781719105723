import { NodeType } from '@ghostmonitor/recartapis'
import { AbstractNodeFactory, type DiagramEngine } from 'storm-react-diagrams'
import { RandomSplitSequenceItemNodeWidget } from '../../../components/sequence-item-node-widget/random-split-node-widget/random-split-node-widget.component'
import { RandomSplitSequenceItemNodeModel } from './random-split-sequence-item-model'

export class RandomSplitSequenceItemNodeFactory extends AbstractNodeFactory {
  constructor(private readonly repaintCanvas: VoidFunction = () => undefined) {
    super(NodeType.RANDOM_SPLIT_SEQUENCE_ITEM)
  }

  public generateReactWidget(
    diagramEngine: DiagramEngine,
    sequenceItemNode: RandomSplitSequenceItemNodeModel
  ): JSX.Element {
    return (
      <RandomSplitSequenceItemNodeWidget
        sequenceItemNode={sequenceItemNode}
        repaintCanvas={this.repaintCanvas}
      />
    )
  }

  public getNewInstance(...args) {
    return new RandomSplitSequenceItemNodeModel()
  }
}
