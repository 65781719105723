import { type FlowAPI } from '@ghostmonitor/recartapis'

export function checkSMSAbandonmentFlowIntegrity(flow: FlowAPI): void {
  if (flow.sendingFrequency !== undefined) {
    throw new Error(
      'sendingFrequency is defined on flow, but it only should be defined on Custom Trigger flows'
    )
  }

  if (flow.isQuietHoursEnabled !== true) {
    throw new Error(`isQuietHoursEnabled should be true for all SMS Abandonment flows`)
  }

  if (flow.isSmartSendingEnabled !== true) {
    throw new Error(`isSmartSendingEnabled should be true for all SMS Abandonment flows`)
  }
}
