import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CogIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/12-Settings/cog.svg'
import { selectIsSettingsDrawerVisible } from '../../../../../store/selectors'
import { toggleSettingsDrawer } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { CanvasButtonGroup } from '../../canvas-toolbar/canvas-button-group/canvas-button-group.component'
import { CanvasButton } from '../../canvas-toolbar/canvas-button/canvas-button.component'
import { CanvasToolbarItem } from '../canvas-toolbar-item.component'

export function CanvasToolbarSettings() {
  const dispatch = useDispatch()
  const isSettingsDrawerVisible = useSelector(selectIsSettingsDrawerVisible)

  function handleClick() {
    dispatch(toggleSettingsDrawer({ isVisible: !isSettingsDrawerVisible }))
  }

  return (
    <CanvasToolbarItem>
      <CanvasButtonGroup>
        <CanvasButton
          title='Toggle settings'
          icon={<CogIcon />}
          active={isSettingsDrawerVisible}
          onClick={handleClick}
        />
        <CanvasButton
          title={isSettingsDrawerVisible ? 'Hide settings' : 'Show settings'}
          readOnly
          onClick={handleClick}
          extraStyle={{ minWidth: '110px' }}
        >
          {isSettingsDrawerVisible ? 'Hide settings' : 'Show settings'}
        </CanvasButton>
      </CanvasButtonGroup>
    </CanvasToolbarItem>
  )
}
