import {
  type PatchSubscriberImportRequest,
  type PatchSubscriberImportResponse,
  type SubscriberImportCreateRequest,
  type SubscriberImportCreateResponse
} from '@ghostmonitor/recartapis'
import { useMutation } from '@tanstack/react-query'
import { request } from '../../utils/request'

export function useSubscriberImport() {
  const { mutateAsync: createSubscriberImport, isLoading: isCreateSubscriberImportLoading } =
    useMutation<SubscriberImportCreateResponse, unknown, SubscriberImportCreateRequest>(
      (subscriberImport) => {
        return request.post<SubscriberImportCreateResponse>('subscriber-imports', subscriberImport)
      }
    )

  const { mutateAsync: patchSubscriberImport, isLoading: isPatchSubscriberImportLoading } =
    useMutation<
      PatchSubscriberImportResponse,
      unknown,
      { importId: string; payload: PatchSubscriberImportRequest }
    >(({ importId, payload }) => {
      return request.patch<PatchSubscriberImportResponse>(`subscriber-imports/${importId}`, payload)
    })

  return {
    createSubscriberImport,
    isCreateSubscriberImportLoading,
    patchSubscriberImport,
    isPatchSubscriberImportLoading
  }
}
