import { type ConditionalSplitV2FlowItemUI } from '@ghostmonitor/recartapis'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'

export function validateConditionalSplitV2FlowItemMustHaveConnection(
  flowItem: ConditionalSplitV2FlowItemUI
): FlowEditorValidationError | undefined {
  const allConditionsConnected = flowItem.item.logic.conditionalSplitV2.cases.every(
    (c) => !!c.nextFlowItemTrigger
  )
  const elseConnected = !!flowItem.item.logic.conditionalSplitV2.defaultNextFlowItemTrigger

  if ((allConditionsConnected && !elseConnected) || elseConnected) {
    return undefined
  }
  return {
    message: FlowEditorError.ConditionalSplitFlowItemV2MustHaveConnection,
    level: FlowEditorErrorLevel.Error
  }
}
