import { type SMSSettingsService, type SMSSettings } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSMSTimezoneDeviation(): UseResource<SMSSettings.TimezoneDeviation> {
  const queryName = [QUERY_NAME.smsTimezoneDeviation]

  const {
    isLoading,
    data: timezoneDeviation,
    isError
  } = useQuery<SMSSettingsService.GetTimezoneDeviationResponse>(queryName, async () => {
    return request.get<SMSSettingsService.GetTimezoneDeviationResponse>(
      'sms-settings/timezone-deviation'
    )
  })

  return {
    data: timezoneDeviation?.data,
    isLoading,
    isError
  }
}
