import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageError } from '../../components/ui-kit/page-error/page-error.component'
import { type DashboardState } from '../../store/dashboard.state'
import {
  type NotificationConfig,
  NotificationType
} from '../../store/slices/notification/notification.reducer'

interface FullPageNotificationProps {
  children?: React.ReactNode
}

export function FullPageNotification(props: FullPageNotificationProps) {
  const notifications = useSelector((state: DashboardState) => state.notification.notifications)

  const [criticalError, setCriticalError] = useState<null | NotificationConfig>(null)

  useEffect(() => {
    let hasCritical = false
    notifications.forEach((notification) => {
      if (notification.type === NotificationType.critical && notification.visible) {
        setCriticalError(notification)
        hasCritical = true
      }
    })
    if (!hasCritical) {
      setCriticalError(null)
    }
  }, [notifications])

  if (criticalError !== null) {
    return (
      <div data-testid='notification-critical'>
        <PageError />
      </div>
    )
  }

  return <>{props.children}</>
}
