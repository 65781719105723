import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSMSTestPhoneNumbers(): UseResource<string[]> & {
  patch: (patchPayload: SMSSettings.TestPhoneNumbers) => Promise<string[]>
} {
  const queryName = [QUERY_NAME.testPhoneNumbers]

  const {
    isLoading,
    data,
    error: requestError
  } = useQuery<SMSSettingsService.GetTestPhoneNumbersResponse>(
    queryName,
    api.getSMSTestPhoneNumbers
  )

  const error: SerializedError = requestError
  const queryClient = useQueryClient()

  async function putSMSTestPhoneNumbers(
    patchPayload: SMSSettings.TestPhoneNumbers
  ): Promise<string[]> {
    try {
      await api.putSMSTestPhoneNumbers(patchPayload)
      queryClient.invalidateQueries(queryName)
      return []
    } catch (error) {
      if (error.response.data.error?.inputErrors) {
        return error.response.data.error?.inputErrors
      } else {
        throw error
      }
    }
  }

  return {
    patch: putSMSTestPhoneNumbers,
    data: data?.data?.phoneNumbers,
    error,
    isLoading
  }
}
