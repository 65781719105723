import { Modal, notification as antdNotification } from 'antd'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { type DashboardState } from '../../store/dashboard.state'
import {
  hideNotification,
  NotificationType
} from '../../store/slices/notification/notification.reducer'

const modalReferences = {}

export function NotificationContainer() {
  const stateSelector = useCallback(
    createStructuredSelector({
      notifications: (state: DashboardState) => state.notification.notifications
    }),
    []
  )

  const state = useSelector(stateSelector)
  const dispatch = useDispatch()

  function handleErrorOk(notificationId: string) {
    dispatch(hideNotification(null, { notificationId }))
  }

  function handleCloseNotification(notificationId: string) {
    dispatch(hideNotification(null, { notificationId }))
  }

  useEffect(() => {
    state.notifications.forEach((notification) => {
      if (notification.type === NotificationType.actionInfo) {
        // Show & Update
        if (notification.visible) {
          antdNotification.info({
            message: notification.title,
            description: notification.content,
            key: notification.id,
            duration: 0,
            onClose: () => {
              handleCloseNotification(notification.id)
            }
          })
          // Destroy
        } else {
          antdNotification.close(notification.id)
        }
      }

      if (notification.type === NotificationType.actionWarn) {
        // Show & Update
        if (notification.visible) {
          antdNotification.warn({
            message: notification.title,
            description: notification.content,
            key: notification.id,
            duration: 0,
            onClose: () => {
              handleCloseNotification(notification.id)
            }
          })
          // Destroy
        } else {
          antdNotification.close(notification.id)
        }
      }

      if (notification.type === NotificationType.actionError) {
        // Show
        if (notification.visible && !(notification.id in modalReferences)) {
          modalReferences[notification.id] = Modal.error({
            centered: true,
            zIndex: 10000,
            title: notification.title,
            content: notification.content,
            onOk: () => {
              handleErrorOk(notification.id)
            },
            onCancel: () => {
              handleErrorOk(notification.id)
            }
          })

          // Update
        } else if (notification.visible) {
          modalReferences[notification.id].update({
            title: notification.title,
            content: notification.content
          })

          // Destroy
        } else if (notification.id in modalReferences) {
          modalReferences[notification.id].destroy()
          delete modalReferences[notification.id]
        }
      }
    })
  }, [state.notifications])

  return null
}
