import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useHasUsedFacebookPage(): UseResource<any> {
  const { data, isLoading, isError } = useQuery(
    [QUERY_NAME.hasUsedFacebookPage],
    api.hasUsedFacebookPage
  )

  return {
    data,
    isLoading,
    isError
  }
}
