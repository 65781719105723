import {
  type FlowUI,
  type HttpResponse,
  type Site,
  type SiteIntegrationEvent,
  getCustomIntegrationSlug,
  type SequenceUI
} from '@ghostmonitor/recartapis'
import { type UseQueryOptions } from '@tanstack/react-query'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useCustomIntegrations(options?: {
  site?: UseQueryOptions<HttpResponse<Site>>
  flow?: FlowUI
}): UseResource<SiteIntegrationEvent[]> & {
  getBySlug: (slug: string | undefined) => SiteIntegrationEvent | undefined
  getByCategory: (category: string | undefined) => SiteIntegrationEvent[]
  getCategories: () => string[]
  getIntegrationEvent: (category: string, name: string) => SiteIntegrationEvent | undefined
  getIntegrationEvents: () => SiteIntegrationEvent[]
  selectedIntegrationEvent: SiteIntegrationEvent | undefined
} {
  const { data: site, isLoading, isError } = hooks.useSite(options?.site)

  const integrationEvents = site?.integrationEvents ?? []

  const flowCustomIntegrationSlug = getCustomIntegrationSlug(
    options?.flow.sequenceItems[0]?.tags ?? []
  )

  const flowCustomIntegrationCategory =
    options?.flow.sequenceItems[0]?.tags
      ?.find((tag) => tag.startsWith('integration-event-category'))
      ?.split('-')[3] ??
    options?.flow.tags.find((tag) => tag.startsWith('integration-event-category'))?.split('-')[3]
  const selectedIntegrationEvent = getBySlug(flowCustomIntegrationSlug)

  function getBySlug(slug: string | undefined) {
    return integrationEvents?.find((integrationEvent) => integrationEvent.slug === slug)
  }

  function getByCategory(category: string | undefined) {
    return integrationEvents?.filter(
      (integrationEvent) => integrationEvent.source.category === category
    )
  }

  function getCategories() {
    return [
      ...new Set(
        site?.integrationEvents?.map((integrationEvent) => integrationEvent.source.category)
      )
    ]
  }

  function getIntegrationEvent(category: string, name: string): SiteIntegrationEvent | undefined {
    return integrationEvents?.find(
      (integrationEvent) =>
        integrationEvent.source.category === category && integrationEvent.source.name === name
    )
  }

  function getIntegrationEvents(): SiteIntegrationEvent[] {
    return integrationEvents
  }

  return {
    data: integrationEvents,
    isError,
    isLoading,
    selectedIntegrationEvent,
    flowCustomIntegrationCategory,
    getCategories,
    getBySlug,
    getByCategory,
    getIntegrationEvent,
    getIntegrationEvents
  }
}

export function getEventName(sequence: SequenceUI) {
  const { getBySlug } = hooks.useCustomIntegrations()

  const customIntegrationSlug = getCustomIntegrationSlug(sequence.tags ?? [])

  const integrationEvent = getBySlug(customIntegrationSlug)

  if (!integrationEvent) {
    return ''
  }

  return integrationEvent.type === 'optin'
    ? `Opted-in via ${integrationEvent.source.name}`
    : integrationEvent.source.name
}
