import { useDispatch, useSelector } from 'react-redux'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { hooks } from '../../../../../hooks/hooks'
import { type AppDispatch } from '../../../../../store/create-store'
import {
  selectEditorBlast,
  selectEditorSequenceMeta,
  selectErrorCount
} from '../../../../../store/selectors'
import { showModal } from '../../../../../store/slices/modal/modal.reducer'
import { ModalType } from '../../../../../store/slices/modal/modal.type'
import { validateBlastThunk } from '../../../../../store/slices/sequence-editor/thunks/validate-blast.thunk'

export function BlastHeaderSaveAndActivateButton() {
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const errorCount = useSelector(selectErrorCount)
  const dispatch: AppDispatch = useDispatch()
  const blast = useSelector(selectEditorBlast)
  const { poolSizeEnough, availableCountEnough } = hooks.useDiscountPoolSizeAndAvailability()

  async function handleClick() {
    const validateResult = await dispatch(validateBlastThunk())
    if (validateBlastThunk.fulfilled.match(validateResult)) {
      if (!poolSizeEnough) {
        dispatch(showModal({ modalType: ModalType.DISCOUNT_POOL_SIZE }))
      } else if (!blast?.scheduledFor && !availableCountEnough) {
        dispatch(showModal({ modalType: ModalType.DISCOUNT_POOL_AVAILABLE_COUNT }))
      } else {
        dispatch(showModal({ modalType: ModalType.BLAST_ACTIVATION }))
      }
    }
  }

  function renderLabel() {
    return !blast?.scheduledFor ? 'Review & Send' : 'Review & Schedule'
  }

  return (
    <div className='flex items-center'>
      <EditorButton
        type='primary'
        disabled={errorCount > 0 || sequenceMeta.saving}
        onClick={handleClick}
        data-testid='header-save-and-activate-button'
      >
        {renderLabel()}
      </EditorButton>
    </div>
  )
}
