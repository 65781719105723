import {
  type FlowDiagram,
  type FlowItemUI,
  type FlowUI,
  type SMSCampaign
} from '@ghostmonitor/recartapis'
import { type DeepPartial } from '@reduxjs/toolkit'
import { produce } from 'immer'
import merge from 'lodash/merge'
import LogRocket from 'logrocket'
import { type FlowEditorLocalStorage } from '../../../store/slices/flow-editor/flow-editor.state'
import { createScope } from '../../../utils/logger/logger'
import { setLocalStorageItem } from '../../../utils/set-local-storage-item'
const { error } = createScope('flow-editor')

const SMS_CAMPAIGN_LOCALSTORAGE_KEY = 'sms-campaign-flow-id'

// We store this key to sessionStorage and localStorage, and by regularly
// comparing these values, we can detect if the localStorage has been changed by another tab.
export const FLOW_EDITOR_SYNC_KEY = 'fer-sync'

export function getSyncKey(id: string): string {
  return `${FLOW_EDITOR_SYNC_KEY}-${id}`
}

// ISRELOADNEEDED
export function updateSyncKey(id: string) {
  const syncValue = Date.now().toString()
  const key = getSyncKey(id)
  localStorage.setItem(key, syncValue)
  sessionStorage.setItem(key, syncValue)
}

// This abstraction is only for better readability at the usage place
export function setFlowEditorReloadNeededOnAllTabs(flowId: string): void {
  updateSyncKey(flowId)
}

export function setSMSCampaignLocalStorage(smsCampaign: SMSCampaign): void {
  try {
    setLocalStorageItem(
      `${SMS_CAMPAIGN_LOCALSTORAGE_KEY}:${smsCampaign.sequenceId}`,
      JSON.stringify(smsCampaign)
    )
    LogRocket.log('setSMSCampaignLocalStorage')
  } catch (err: any) {
    error(err, { smsCampaign })
  }
}

export function getSMSCampaignLocalStorage(flowId: string): SMSCampaign | null {
  const smsCampaignInstance = localStorage.getItem(`${SMS_CAMPAIGN_LOCALSTORAGE_KEY}:${flowId}`)
  if (smsCampaignInstance === null) {
    return null
  }
  return JSON.parse(smsCampaignInstance)
}

export function removeSMSCampaignLocalStorage(flowId: string): void {
  localStorage.removeItem(`${SMS_CAMPAIGN_LOCALSTORAGE_KEY}:${flowId}`)
  LogRocket.log('removeSMSCampaignLocalStorage')
}

export function patchSMSCampaignLocalStorage(flowId: string, patch: Partial<SMSCampaign>): void {
  const localSMSCampaign = getSMSCampaignLocalStorage(flowId)
  if (localSMSCampaign === null) {
    return
  }
  const newLocalSMSCampaign = produce(
    localSMSCampaign,
    (draft) => {
      merge(draft, patch)
    },
    (patches) => {
      LogRocket.log('patchSMSCampaignLocalStorage', { patches })
    }
  )

  setSMSCampaignLocalStorage(newLocalSMSCampaign)
}

export function setFlowEditorLocalStorage(
  flowId: string,
  flowEditorLocalStorage: FlowEditorLocalStorage
): void {
  try {
    setLocalStorageItem(`flowEditor-${flowId}`, JSON.stringify(flowEditorLocalStorage))
    LogRocket.log('setFlowEditorLocalStorage')
  } catch (err: any) {
    error(err, { flowId })
  }
}

export function getFlowEditorLocalStorage(flowId?: string): FlowEditorLocalStorage | null {
  if (!flowId) {
    return null
  }

  const flowEditorSerialized = localStorage.getItem(`flowEditor-${flowId}`)
  if (flowEditorSerialized === null) {
    return null
  }

  try {
    return JSON.parse(flowEditorSerialized)
  } catch (err: any) {
    error(err, { flowId })
  }

  return null
}

export function updateFlowItemLocalStorage(flowId: string, flowItem: FlowItemUI) {
  try {
    const localFlowEditor = getFlowEditorLocalStorage(flowId)

    if (localFlowEditor === null) {
      throw new Error('updateFlowItemLocalStorage:CannotFindFlowInLocalStorage')
    }

    localFlowEditor.flow.sequenceItems = produce(
      localFlowEditor.flow.sequenceItems,
      (draftSequenceItems) => {
        const index = draftSequenceItems.findIndex((item) => item._id === flowItem._id)
        if (index === -1) {
          throw new Error('updateFlowItemLocalStorage:CannotFindFlowItemInLocalStorage')
        }
        draftSequenceItems[index] = flowItem
      },
      (patches) => {
        LogRocket.log('updateFlowItemLocalStorage', { patches })
      }
    )

    setFlowEditorLocalStorage(flowId, localFlowEditor)
  } catch (err: any) {
    error(err, { flowId, flowItem })
  }
}

export function updatFlowDiagramLocalStorage(
  flowId: string,
  flowEditorDiagram: FlowDiagram.FlowDiagram
) {
  try {
    const flowEditor = getFlowEditorLocalStorage(flowId)

    if (flowEditor === null) {
      throw new Error('updatFlowLocalStorage:CannotFindFlowInLocalStorage')
    }

    setFlowEditorLocalStorage(
      flowId,
      produce(
        flowEditor,
        (draftFlowEditor) => {
          draftFlowEditor.flow.flowEditorDiagram = flowEditorDiagram
        },
        (patches) => {
          LogRocket.log('updatFlowDiagramLocalStorage', { patches })
        }
      )
    )
  } catch (err: any) {
    error(err, { flowId })
  }
}

export function removeFlowLocalStorage(flowId: string): void {
  localStorage.removeItem(`flowEditor-${flowId}`)
  removeSMSCampaignLocalStorage(flowId)
}

export function updatFlowLocalStorage(flowId: string, flow: FlowUI) {
  try {
    const flowEditor = getFlowEditorLocalStorage(flowId)

    if (flowEditor === null) {
      throw new Error('updatFlowLocalStorage:CannotFindFlowInLocalStorage')
    }

    setFlowEditorLocalStorage(
      flowId,
      produce(
        flowEditor,
        (draftFlowEditor) => {
          draftFlowEditor.flow = flow
        },
        (patches) => {
          LogRocket.log('updatFlowLocalStorage', { patches })
        }
      )
    )
  } catch (err: any) {
    error(err, { flowId })
  }
}

export function patchFlowEditorLocalStorage(
  flowId: string,
  payload: DeepPartial<FlowEditorLocalStorage>
) {
  try {
    const flowEditor = getFlowEditorLocalStorage(flowId)

    if (flowEditor === null) {
      throw new Error('patchFlowEditorLocalStorage:CannotFindFlowInLocalStorage')
    }

    const newFlowEditor = produce(
      flowEditor,
      (draftFlowEditor) => {
        merge(draftFlowEditor, payload)
      },
      (patches) => {
        LogRocket.log('patchFlowEditorLocalStorage', { patches })
      }
    )

    setFlowEditorLocalStorage(flowId, newFlowEditor)
  } catch (err: any) {
    error(err, { flowId })
  }
}
