import { type FlowDiagram, type FlowItemUI, PortType } from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import {
  generateKeywordReplyHandleName,
  parseReplyKeywordFromHandleName
} from '../../../../routes/flow-editor/utils/generate-keyword-reply-handle-name'
import { isSMSMessageFlowItemUI } from '../../../../types/guards/flow-item-ui.guards'
import { selectEditorFlow, selectNodeByFlowItemId } from '../flow-editor.selectors'
import { type FlowEditorState } from '../flow-editor.state'

export function updateKeywordRepliesHandles(state: FlowEditorState, flowItem: FlowItemUI) {
  const flow = selectEditorFlow(state)
  const node = selectNodeByFlowItemId(flowItem._id)(state)
  const followUpHandle = node?.data.followUpHandle

  if (!flow) {
    throw new Error('CannotFindFlow')
  }

  if (!node) {
    return
  }

  if (!isSMSMessageFlowItemUI(flowItem)) {
    return
  }

  const keywordReplies = flowItem.item.replyKeywordSettings?.keywords

  if (keywordReplies === undefined) {
    return
  }

  const keywordReplyHandlesName = node.data.keywordReplyHandles?.map(
    (keywordReplyHandle) => keywordReplyHandle.name
  )

  /****************************************************************
   * Delete the ones that are not in the flow item keywords list
   ****************************************************************/
  const deletedKeywordReplyHandle = node.data.keywordReplyHandles?.find((handle) => {
    const replyKeyword = parseReplyKeywordFromHandleName(handle.name)
    return !keywordReplies.some((keyword) => keyword.keyword === replyKeyword)
  })
  if (deletedKeywordReplyHandle) {
    flow.flowEditorDiagram.edges = flow.flowEditorDiagram.edges.filter(
      (edge) => edge.sourceHandle !== deletedKeywordReplyHandle.id
    )

    node.data.keywordReplyHandles = node.data.keywordReplyHandles?.filter(
      (handle) => handle.id !== deletedKeywordReplyHandle.id
    )
  }

  /****************************************************************
   * Delete the edges that may are connected to the followup handle
   ****************************************************************/

  if (keywordReplies.length !== 0) {
    flow.flowEditorDiagram.edges = flow.flowEditorDiagram.edges.filter(
      (edge) => edge.sourceHandle !== followUpHandle?.id
    )
  }

  /****************************************************************
   * Delete the followup handle if still exist on node-data
   ****************************************************************/
  if (keywordReplies.length !== 0) {
    delete node.data.followUpHandle
  }

  /****************************************************************
   * Add the followup handle if all keyword replies are deleted
   ****************************************************************/
  if (keywordReplies.length === 0 && !node.data.followUpHandle) {
    node.data.followUpHandle = {
      id: new ObjectId().toHexString(),
      type: 'source',
      portType: PortType.FOLLOW_UP,
      name: 'follow-up-port'
    }
  }

  /****************************************************************
   * Add the ones that are in the flow item keywords list but does not have a handle
   ****************************************************************/

  keywordReplies.forEach((keyword) => {
    const handleName = generateKeywordReplyHandleName(flowItem._id, keyword.keyword)
    if (keywordReplyHandlesName?.includes(handleName)) {
      return
    }
    const newKeywordReplyHandle: FlowDiagram.Handle = {
      id: new ObjectId().toHexString(),
      type: 'source',
      portType: PortType.KEYWORD_REPLY,
      name: handleName
    }

    node.data.keywordReplyHandles = (node.data.keywordReplyHandles ?? []).concat(
      newKeywordReplyHandle
    )
  })
}
