import { type OptinToolStep } from '@ghostmonitor/recartapis'

// Could be connected to the popup menu items in the future
export const previewLabels: Record<OptinToolStep, string> = {
  teaser: 'Teaser',
  email: 'Email opt-in',
  messenger: 'Messenger opt-in',
  sms: 'SMS opt-in',
  smsWithEmail: 'Email and SMS opt-in',
  oneTimePassword: 'SMS consent',
  oneClickVerification: 'Verification',
  oneClickOptin: 'Email & SMS opt-in',
  success: 'Success'
}

export const accordionLabels: Record<string, string> = {
  logo: 'Image',
  heading: 'Heading',
  headingTop: 'Heading top',
  headingMiddle: 'Heading bottom',
  headingBottom: 'Subheading',
  input: 'Input',
  emailInput: 'Email input',
  phoneInput: 'Phone number input',
  confirmButton: 'Confirm button',
  declineButton: 'Decline button',
  button: 'CTA button',
  success: 'Success message',
  paragraph: 'Subheading',
  description: 'Legal text',
  resend: 'Resend'
}
