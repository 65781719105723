import { CustomError } from '@ghostmonitor/lib-error'
import { type FlowUI, type SMSCampaign, SMSCampaignStatus } from '@ghostmonitor/recartapis'
import { produce } from 'immer'
import { diff } from 'jest-diff'
import isEqual from 'lodash/isEqual'
import { getFlowEditorLocalStorage } from '../../routes/flow-editor/utils/local-storage'
import { type FlowMeta } from '../../store/slices/flow-editor/flow-editor.state'
import { updateFlowItemTrigger } from '../../store/slices/flow-editor/helpers/update-flow-item-trigger'
import { convertFlowUIToFlow } from '../../types/flow-editor/convert-flow-ui-to-flow'
import { type FlowIntegrityOptions, checkFlowIntegrity } from './check-flow-integrity'
import { sanitizeFlow } from './sanitize-flow'

/*************************************
 * Checks that operate with UI types
 * or only make sense to check on UI
 ************************************/
export function checkFlowEditorIntegrity({
  flowUI,
  flowMeta,
  isReadOnly,
  smsCampaign,
  siteIdInFlowEditor,
  siteId
}: {
  flowUI: FlowUI
  smsCampaign?: SMSCampaign
  flowMeta: FlowMeta
  isReadOnly: boolean
  siteIdInFlowEditor?: string
  siteId: string
}): void {
  /**************************************
   * Check if localStorage is in sync
   * ************************************/
  const flowEditorLocalStorage = getFlowEditorLocalStorage(flowUI._id)
  if (
    !isEqual(
      sanitizeFlow(flowEditorLocalStorage.flow, isReadOnly),
      sanitizeFlow(flowUI, isReadOnly)
    )
  ) {
    const changes = diff(
      sanitizeFlow(flowEditorLocalStorage.flow, isReadOnly),
      sanitizeFlow(flowUI, isReadOnly),
      { contextLines: 3, aAnnotation: 'localStorage', bAnnotation: 'store', expand: false }
    )

    console.log(changes)

    throw new CustomError('Flow stored in localStorage is not equal to the flow in store', {
      diff: changes
    })
  }
  if (flowEditorLocalStorage.unsavedChanges !== flowMeta.unsavedChanges) {
    throw new Error(
      `unsavedChanges in localStorage and in flowMeta should be the same. Now its localstorage:${flowEditorLocalStorage.unsavedChanges} and flowMeta:${flowMeta.unsavedChanges}`
    )
  }

  if (smsCampaign !== undefined) {
    if (
      flowEditorLocalStorage.isEverSaved &&
      flowUI.isEnabled !== true &&
      smsCampaign.status !== SMSCampaignStatus.DRAFT &&
      smsCampaign.status !== SMSCampaignStatus.CANCELLED
    ) {
      throw new Error(
        'An SMS Campaign that has been ever saved and not Draft or Cancelled should have an isEnabled===true flow'
      )
    }
  }

  /*************************
   * Check siteId integrity
   * **********************/
  if (siteIdInFlowEditor !== siteId) {
    throw new Error(
      `the siteId saved in Flow Editor state ${siteIdInFlowEditor} should be the same as the siteId ${siteId} `
    )
  }
  if (flowUI.siteId !== siteId) {
    throw new Error(
      `the siteId on the flow ${flowUI.siteId} should be the same as the siteId ${siteId} `
    )
  }

  /*************************
   * updateFlowItemTrigger
   * **********************/
  const flowItems = flowUI.sequenceItems
  flowItems.forEach((flowItem) => {
    /******************************************
     * Flow item triggers should be up to date
     * ****************************************/
    const updatedFlowItem = produce(flowItem, (draftFlowItem) => {
      updateFlowItemTrigger(draftFlowItem, flowUI)
    })

    if (!isEqual(flowItem, updatedFlowItem)) {
      console.log(diff(updatedFlowItem, flowItem))
      throw new Error(`FlowItem ${flowItem._id} differs after updateFlowItemTrigger`)
    }
  })

  const options: FlowIntegrityOptions = {
    isLegalFlowItemDeletable: flowMeta.isLegalFlowItemDeletable
  }

  checkFlowIntegrity(convertFlowUIToFlow(flowUI), options, smsCampaign)
}
