import {
  getTextFromMessage,
  hasTextOnMessage,
  type MessageUI,
  type Variable
} from '@ghostmonitor/recartapis'
import {
  getVariablesFromRawValue,
  isDiscountCodeVariable,
  isStaticDiscountCodeVariable
} from '../../../../utils/inline-editor/inline-editor-utils'
import { selectEditorSequenceMeta } from '../sequence-editor.selectors'
import {
  type DiscountCodePartialDeletionType,
  type MessageMeta,
  type SequenceEditorState,
  variableInitialMeta
} from '../sequence-editor.state'

interface FrequencyTable {
  [key: string]: {
    plain: number
    url: number
  }
}

function getDiscountFrequencyTable(variables: Variable[]): FrequencyTable {
  const frequencyTable = {}
  variables.forEach((variable) => {
    if (isDiscountCodeVariable(variable)) {
      const key = isStaticDiscountCodeVariable(variable)
        ? variable.params.discount_code
        : variable.params.discount_pool_id
      frequencyTable[key] = frequencyTable[key] ?? { plain: 0, url: 0 }
      if (variable.name === 'url') {
        frequencyTable[key].url += 1
      } else {
        frequencyTable[key].plain += 1
      }
    }
  })
  return frequencyTable
}

export function setVariablesMetaInMessageHandler(
  state: SequenceEditorState,
  message: MessageUI,
  messageMeta: MessageMeta,
  nextText?: string
): void {
  const sequenceMeta = selectEditorSequenceMeta(state)
  if (hasTextOnMessage(message)) {
    const text = getTextFromMessage(message)
    messageMeta.variables = []

    const currentVariables = getVariablesFromRawValue(text)
    currentVariables.forEach((variable, variableIndex) => {
      messageMeta.variables[variableIndex] = {
        ...variableInitialMeta,
        error:
          isStaticDiscountCodeVariable(variable) &&
          sequenceMeta.invalidDiscountCodes.includes(variable.params.discount_code)
      }
    })

    if (nextText !== undefined) {
      const nextVariables = getVariablesFromRawValue(nextText)
      const nextDiscountFrequencyMap = getDiscountFrequencyTable(nextVariables)
      const currentDiscountFrequencyMap = getDiscountFrequencyTable(currentVariables)
      nextVariables.forEach((variable, variableIndex) => {
        let discountCodePartlyDeleted: DiscountCodePartialDeletionType = 'none'
        if (nextText !== undefined && isDiscountCodeVariable(variable)) {
          const discountName = variable.params.discount_code ?? variable.params.discount_pool_id
          const currentDiscountFrequency = currentDiscountFrequencyMap[discountName]
          const nextDiscountFrequency = nextDiscountFrequencyMap[discountName]
          if (currentDiscountFrequency && nextDiscountFrequency) {
            if (currentDiscountFrequency.plain > 0 && nextDiscountFrequency.plain === 0) {
              discountCodePartlyDeleted = 'plain'
            }
            if (currentDiscountFrequency.url > 0 && nextDiscountFrequency.url === 0) {
              discountCodePartlyDeleted = 'url'
            }
          }
        }
        messageMeta.variables[variableIndex] = {
          ...(messageMeta.variables[variableIndex] ?? variableInitialMeta),
          discountCodePartiallyDeleted: discountCodePartlyDeleted
        }
      })
    }
  }
}
