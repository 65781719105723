import { type UseResourceCache } from '../types/use-resource-cache.type'
import { useSMSCampaignsCache } from './use-sms-campaigns-cache'

export function useSMSCampaignCache(_: string): UseResourceCache {
  const { invalidateCache } = useSMSCampaignsCache()

  return {
    invalidateCache
  }
}
