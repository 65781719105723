import { type MessengerMessageSequenceItemUI } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'

export const facebookCommentsValidationErrors = {
  INVALID_FACEBOOK_COMMENTS_ITEM:
    'Only one item and one quick reply can be added to this message due to Facebook Comments limitations.'
}

export function facebookCommentsValidator(
  sequenceItem: MessengerMessageSequenceItemUI
): SerializedError[] {
  const validationErrors = []

  if (sequenceItem.messages.length !== 1 || sequenceItem.quickReplies.length !== 1) {
    validationErrors.push(
      new Error(facebookCommentsValidationErrors.INVALID_FACEBOOK_COMMENTS_ITEM)
    )
  }

  return validationErrors
}
