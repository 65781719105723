import { type FlowItemUI } from '@ghostmonitor/recartapis'
import { type Action, isAnyOf, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { type AppDispatch } from '../../../store/create-store'
import { type DashboardState } from '../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../store/selectors'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'

const DEBOUNCE_DELAY = 500

const getUpdateDiscountCodesOnFlowItem = (dispatch: AppDispatch) =>
  debounce(
    (flowItem: FlowItemUI) => {
      dispatch(
        flowEditorActions.updateDiscountCodesOnFlowItem({
          flowItemId: flowItem._id
        })
      )
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )

const getUpdateDiscountPoolIdsOnFlowItem = (dispatch: AppDispatch) =>
  debounce(
    (flowItem: FlowItemUI) => {
      dispatch(
        flowEditorActions.updateDiscountPoolIdsOnFlowItem({
          flowItemId: flowItem._id
        })
      )
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )

export function updateDiscountCodesOnFlowItemMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  const updateDiscountCodesOnFlowItem = getUpdateDiscountCodesOnFlowItem(dispatch)
  const updateDiscountPoolIdsOnFlowItem = getUpdateDiscountPoolIdsOnFlowItem(dispatch)

  return (next) => (action: Action) => {
    next(action)

    const state = store.getState()

    if (
      isAnyOf(
        flowEditorActions.messageChanged,
        flowEditorActions.rteInsertDiscountCode,
        flowEditorActions.rteInsertLink,
        flowEditorActions.rteEditLinkVariable,
        flowEditorActions.rteRemoveDiscountCode,
        flowEditorActions.rteRemoveLinkVariable
      )(action)
    ) {
      const flowItem = flowEditorSelectors.selectFlowItem(action.payload.flowItemId)(state)
      updateDiscountCodesOnFlowItem(flowItem)
      updateDiscountPoolIdsOnFlowItem(flowItem)
    }
    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      updateDiscountCodesOnFlowItem.cancel()
      updateDiscountPoolIdsOnFlowItem.cancel()
    }
  }
}
