import { Skeleton } from 'antd'
import cn from 'classnames'
import styles from './avatar-skeleton.style.scss'

interface AvatarSkeletonProps {
  size: 'sm' | 'md' | 'base' | 'lg' | 'xl'
  className?: string
  isInactive?: boolean
}

export function AvatarSkeleton(props: AvatarSkeletonProps) {
  return (
    <Skeleton.Avatar
      shape='square'
      className={cn(styles[props.size], styles.loader, props.className)}
      active={!props.isInactive}
    />
  )
}
