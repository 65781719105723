import {
  type CreateSubscriberDetailPayload,
  type HttpResponse,
  type PatchSubscriberDetailPayload,
  type SubscriberDetail
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { QUERY_NAME } from './query-names'

export function useSubscriberDetailsCreate() {
  const queryName = useMemo(() => [QUERY_NAME.subscriberDetails], [])
  const queryClient = useQueryClient()

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  // CREATE
  const {
    mutateAsync: createSubscriberDetail,
    isLoading: isCreatingSubscriberDetail,
    error: createSubscriberDetailError
  } = useMutation<HttpResponse<SubscriberDetail>, unknown, CreateSubscriberDetailPayload>(
    api.createSubscriberDetail,
    {
      onSettled: handleSettled
    }
  )

  // ADD OPTION
  const {
    mutateAsync: updateSubscriberDetail,
    isLoading: isUpdatingSubscriberDetail,
    error: updateSubscriberDetailError
  } = useMutation<HttpResponse<SubscriberDetail>, unknown, PatchSubscriberDetailPayload>(
    api.updateSubscriberDetail,
    {
      onSettled: handleSettled
    }
  )

  return {
    createSubscriberDetail,
    isCreatingSubscriberDetail,
    createSubscriberDetailError,
    updateSubscriberDetail,
    isUpdatingSubscriberDetail,
    updateSubscriberDetailError
  }
}
