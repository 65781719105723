// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S0uJQJstFlPvH9WUvBAs{height:200px;width:200px}.S0uJQJstFlPvH9WUvBAs path{stroke-width:1}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/page-error/page-error.component.scss"],"names":[],"mappings":"AACE,sBAEE,YAAA,CADA,WACA,CAEA,2BACE,cAAA","sourcesContent":[":local {\n  .errorIcon {\n    width: 200px;\n    height: 200px;\n\n    path {\n      stroke-width: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorIcon": `S0uJQJstFlPvH9WUvBAs`
};
export default ___CSS_LOADER_EXPORT___;
