import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import {
  type SubscriptionSourcesStatResponse,
  type SubscriptionSourcesStat
} from '@ghostmonitor/recartapis'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSubscriptionSourcesStat(): UseResource<SubscriptionSourcesStat> {
  const queryName = [QUERY_NAME.subscriptionSourcesStat]

  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
  } = {}

  if (dateFrom) {
    const startDate = dateFrom.toISOString()
    queryParams.startDate = startDate
    queryName.push(startDate)
  }

  if (dateTo) {
    const endDate = dateTo.toISOString()
    queryParams.endDate = endDate
    queryName.push(endDate)
  }

  const { isInitialLoading, data } = useQuery<SubscriptionSourcesStatResponse>(
    queryName,
    () =>
      request.get<SubscriptionSourcesStatResponse>('statistics/subscription-sources', {
        params: queryParams
      }),
    {
      enabled: dateFrom !== undefined && dateTo !== undefined
    }
  )

  return {
    data: data?.data,
    isLoading: isInitialLoading
  }
}
