// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rAb6CqSWSUT2cWljB1q0{cursor:text!important;display:inline-block;min-height:30px;user-select:text;white-space:pre-line;width:240px}.rAb6CqSWSUT2cWljB1q0.nklhuie81quxtd6KU4Pd{color:#979ba1;cursor:default!important}.rAb6CqSWSUT2cWljB1q0.nklhuie81quxtd6KU4Pd>*{cursor:default!important}.KpgwmXaNSFjq_DwmQsOV{bottom:-12px;display:none;flex-direction:row;position:absolute;right:20px;z-index:99}.KpgwmXaNSFjq_DwmQsOV.oDdMx1eUcgXLqSRRtrxM{display:flex;pointer-events:all}.KpgwmXaNSFjq_DwmQsOV.oDdMx1eUcgXLqSRRtrxM{animation:fadeIn .1s cubic-bezier(.175,.885,.32,1)}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/pluginable-inline-editor/pluginable-inline-editor.style.scss"],"names":[],"mappings":"AAGE,sBACE,qBAAA,CAEA,oBAAA,CACA,eAAA,CAEA,gBAAA,CAJA,oBAAA,CAGA,WACA,CAEA,2CAEE,aAAA,CADA,wBACA,CAEA,6CACE,wBAAA,CAKN,sBAKE,YAAA,CAHA,YAAA,CACA,kBAAA,CAFA,iBAAA,CAGA,UAAA,CAEA,UAAA,CAEA,2CACE,YAAA,CACA,kBAAA,CAEA,2CACE,kDAAA","sourcesContent":["@use '../../../../sequence-editor' as *;\n\n:local {\n  .editorContainer {\n    cursor: text !important;\n    white-space: pre-line;\n    display: inline-block;\n    min-height: 30px;\n    width: 240px;\n    user-select: text;\n\n    &.disabled {\n      cursor: default !important;\n      color: #979ba1;\n\n      & > * {\n        cursor: default !important;\n      }\n    }\n  }\n\n  .addonContainer {\n    position: absolute;\n    display: none;\n    flex-direction: row;\n    right: 20px;\n    bottom: -12px;\n    z-index: 99;\n\n    &.showAddonContainer {\n      display: flex;\n      pointer-events: all;\n\n      :global {\n        animation: fadeIn cubic-bezier(0.175, 0.885, 0.32, 1) 100ms;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorContainer": `rAb6CqSWSUT2cWljB1q0`,
	"disabled": `nklhuie81quxtd6KU4Pd`,
	"addonContainer": `KpgwmXaNSFjq_DwmQsOV`,
	"showAddonContainer": `oDdMx1eUcgXLqSRRtrxM`
};
export default ___CSS_LOADER_EXPORT___;
