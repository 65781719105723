import { type DiscountType, DiscountTypes } from '@ghostmonitor/recartapis'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import { useState } from 'react'
import styles from './discount-create.component.scss'
import { DiscountTypeLabels } from './types/discount-type-labels'

const MAX_PERCENTAGE = 100

export interface DiscountCreateProps {
  onBack: () => void
  onCreate: (code: string, type: DiscountType, amount?: number) => void
}

const fullWidthLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  }
}

export function DiscountCreate(props: DiscountCreateProps) {
  const [discountCodeInputValue, setDiscountCodeInputValue] = useState('')
  const [discountType, setDiscountType] = useState(DiscountTypes.PERCENTAGE)
  const [discountAmount, setDiscountAmount] = useState(0)

  function handleMouseDown(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    event.stopPropagation()
  }

  function handleCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setDiscountCodeInputValue(value)
  }

  function handleTypeChange(value: DiscountType) {
    setDiscountType(DiscountTypes[value])
  }

  function handleDiscountValueChange(value: React.ReactText) {
    setDiscountAmount(+value)
  }

  function getConfirmDisable(): boolean {
    if (discountCodeInputValue?.length < 1) {
      return true
    }
    const isInvalidAmount = isNaN(discountAmount) || discountAmount <= 0
    if (isInvalidAmount && discountType !== DiscountTypes.FREE_SHIPPING) {
      return true
    }
    if (discountType === DiscountTypes.PERCENTAGE && discountAmount > MAX_PERCENTAGE) {
      return true
    }
    return false
  }

  function handleAddDiscountClick() {
    props.onCreate(discountCodeInputValue, discountType, discountAmount)
  }

  function handleBackClick() {
    props.onBack()
  }

  return (
    <div className={styles.container}>
      <Form className={styles.form}>
        <Form.Item
          {...fullWidthLayout}
          label='Discount code'
          labelAlign='left'
          colon={false}
          data-testid='discount-code-input'
        >
          <Input
            defaultValue=''
            onChange={handleCodeChange}
            onMouseDown={handleMouseDown}
            size='middle'
            placeholder='Discount code'
            data-testid='discount-code-input'
          />
        </Form.Item>
        <Form.Item
          {...fullWidthLayout}
          label='Discount type'
          labelAlign='left'
          colon={false}
          data-testid='discount-type-input'
        >
          <Select defaultValue={DiscountTypes.PERCENTAGE} onChange={handleTypeChange}>
            {Object.keys(DiscountTypeLabels).map((value) => {
              return (
                <Select.Option key={value} value={value}>
                  {DiscountTypeLabels[value]}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        {discountType !== DiscountTypes.FREE_SHIPPING && (
          <Form.Item
            {...fullWidthLayout}
            label='Discount value'
            tooltip={
              discountType === DiscountTypes.FIXED_AMOUNT
                ? 'Discount value in your store currency'
                : ''
            }
            labelAlign='left'
            colon={false}
            data-testid='discount-code-value'
          >
            <InputNumber
              type='number'
              defaultValue={0}
              onChange={handleDiscountValueChange}
              onMouseDown={handleMouseDown}
              size='middle'
              placeholder='Discount value'
              data-testid='discount-code-value'
            />
          </Form.Item>
        )}
        <Button type='primary' disabled={getConfirmDisable()} onClick={handleAddDiscountClick}>
          Add discount
        </Button>
        <Button type='link' onClick={handleBackClick}>
          Back
        </Button>
      </Form>
    </div>
  )
}
