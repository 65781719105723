// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VEwczhSv5vLIvNDj31RX{color:var(--recart-red-1)!important}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/modals/error-modal/error-modal.component.scss"],"names":[],"mappings":"AACE,sBACE,mCAAA","sourcesContent":[":local {\n  .icon {\n    color: var(--recart-red-1) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `VEwczhSv5vLIvNDj31RX`
};
export default ___CSS_LOADER_EXPORT___;
