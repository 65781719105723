import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from '../../../../../components/ui-kit/tooltip/tooltip.component'
import { useSequence } from '../../../../../hooks/resources/use-sequence'
import { selectEditorSequence } from '../../../../../store/selectors'
import { formatters } from '../../../../../utils/formatters/formatters'
import { dateTimeFormat } from '../../../../../utils/utils'

export function LastUpdated() {
  const editorSequence = useSelector(selectEditorSequence)
  const { data: sequence } = useSequence(editorSequence._id)
  const sequenceName = sequence?.name
  const updatedAt = formatters.date(sequence?.updatedAt, dateTimeFormat)

  const sequenceNameRef = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(() => {
    const element = sequenceNameRef.current
    setIsOverflown(element.scrollWidth > element.clientWidth)
  }, [])

  function handleTooltipOpenChange(visible: boolean): void {
    if (visible && isOverflown) {
      setIsTooltipOpen(true)
    } else {
      setIsTooltipOpen(false)
    }
  }

  return (
    <div className='flex flex-col ml-1 mr-3 max-w-xs'>
      <Tooltip title={sequenceName} open={isTooltipOpen} onOpenChange={handleTooltipOpenChange}>
        <div className='font-semibold text-lg truncate' ref={sequenceNameRef}>
          {sequenceName}
        </div>
      </Tooltip>
      <div className='text-sm text-grey-5 whitespace-nowrap'>Last edited on {updatedAt}</div>
    </div>
  )
}
