// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MsHwDUQAURaxvB8qK9YX{margin:8px 0}.duYdCeXSQjv_Bz0l1NS0{min-width:30px}.duYdCeXSQjv_Bz0l1NS0 svg{width:20px}.gingKD5pLf3iywLmonvU{font-size:12px;letter-spacing:0;line-height:14px!important;padding-bottom:12px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-code-logos.component.scss"],"names":[],"mappings":"AACE,sBACE,YAAA,CAGF,sBACE,cAAA,CAEA,0BACE,UAAA,CAIJ,sBACE,cAAA,CACA,gBAAA,CACA,0BAAA,CACA,mBAAA","sourcesContent":[":local {\n  .container {\n    margin: 8px 0;\n  }\n\n  .logo {\n    min-width: 30px;\n\n    svg {\n      width: 20px;\n    }\n  }\n\n  .optionContainer {\n    font-size: 12px;\n    letter-spacing: 0;\n    line-height: 14px !important;\n    padding-bottom: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MsHwDUQAURaxvB8qK9YX`,
	"logo": `duYdCeXSQjv_Bz0l1NS0`,
	"optionContainer": `gingKD5pLf3iywLmonvU`
};
export default ___CSS_LOADER_EXPORT___;
