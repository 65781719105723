import { Button } from 'antd'
import styles from './discount-buttons.component.scss'

interface DiscountButtonsProps {
  onCreateNew: () => void
  onSelectExisting: () => void
  onRemove?: () => void
}

export function DiscountButtons(props: DiscountButtonsProps) {
  function handleRemove() {
    props.onRemove?.()
  }

  return (
    <div className={styles.container}>
      <Button
        type='primary'
        className={styles.button}
        onClick={props.onCreateNew}
        data-testid='create-new-button'
      >
        Create new
      </Button>
      <Button
        type='default'
        className={styles.button}
        onClick={props.onSelectExisting}
        data-testid='select-existing'
      >
        Select existing
      </Button>
      {props.onRemove && (
        <Button type='link' onClick={handleRemove}>
          Remove
        </Button>
      )}
    </div>
  )
}
