import { NodeType } from '@ghostmonitor/recartapis'
import { Divider, Skeleton } from 'antd'
import { ObjectId } from 'bson'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { useRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../../../components/ui-kit/button/button.component'
import { ReactComponent as CopyIcon } from '../../../../../static/images/svg/iconmonstr-copy-thin.svg'
import { ReactComponent as DeleteIcon } from '../../../../../static/images/svg/iconmonstr-trash-can-thin.svg'
import {
  selectDraggedItemType,
  selectEditorSequence,
  selectIsEntrySequenceItem,
  selectIsReadOnly,
  selectLinking,
  selectLinkingMeta,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectShowDebugView,
  selectShowSequenceItemStatistics
} from '../../../../../store/selectors'
import {
  createFollowUpPort,
  createSequenceItem,
  removeSequenceItem
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { Notification } from '../../../../../utils/notification/notification.util'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { type SMSMessageSequenceItemNodeModel } from '../../../models/sequence-item/sms-message/sms-message-sequence-item-model'
import { DEFAULT_SEQUENCE_ITEM_OFFSET } from '../../../types/sequence-editor-constants'
import { MessageTrayItem, type TrayItem, isMessageTrayItem } from '../../../types/tray-item.type'
import { hasFollowUpButtonOnSequenceItemType } from '../../../utils/assert-sequence-item-type'
import {
  AddElementButton,
  AddElementButtonType
} from '../../add-element-button/add-element-button.component'
import { EntryTag } from '../../entry-tag/entry-tag.component'
import { LogicSequenceItemPortWidget } from '../../logic-sequence-item-port-widget/logic-sequence-item-port-widget.component'
import { Port } from '../../port/port.component'
import { SequenceItemButton } from '../../sequence-item-button/sequence-item-button.component'
import { VariableInfoPopover } from './components/variable-info.component'
import styles from './make-sequence-item-widget.hoc.scss'

interface MakeSequenceItemWidgetProps {}

interface SequenceItemWidgetProps {
  // Currently it only handles SMS
  sequenceItemNode: SMSMessageSequenceItemNodeModel
}

function canDropOnNode(trayItem: TrayItem, nodeType: NodeType): boolean {
  if (isMessageTrayItem(trayItem)) {
    if (nodeType === NodeType.MESSAGE_SEQUENCE_ITEM) {
      return trayItem === MessageTrayItem.messengerMessage
    }
    if (nodeType === NodeType.SMS_SEQUENCE_ITEM) {
      return [MessageTrayItem.smsTextMessage, MessageTrayItem.smsMedia].includes(trayItem)
    }
  }
  return false
}

export function makeSequenceItemWidget<T extends SequenceItemWidgetProps>(
  WrappedComponent: React.FC<T>
): React.FC<MakeSequenceItemWidgetProps & T> {
  const SequenceItemWidget = (props: React.PropsWithChildren<MakeSequenceItemWidgetProps & T>) => {
    const nodeRef = useRef<HTMLDivElement>()
    const dispatch = useDispatch()

    const sequenceItemId = props.sequenceItemNode.sequenceItemId
    const sequenceItem = useSelector(selectSequenceItem(sequenceItemId))
    const sequenceItemMeta = useSelector(selectSequenceItemMeta(sequenceItemId))
    const sequence = useSelector(selectEditorSequence)
    const showSequenceItemStatistics = useSelector(selectShowSequenceItemStatistics)
    const showDebugView = useSelector(selectShowDebugView)
    const linking = useSelector(selectLinking)
    const linkingMeta = useSelector(selectLinkingMeta)
    const isEntry = useSelector(selectIsEntrySequenceItem(sequenceItemId))
    const draggedItemType = useSelector(selectDraggedItemType)

    const inputPort = props.sequenceItemNode.getInputPort()
    const followUpPort = props.sequenceItemNode.getFollowUpPort()
    const editorSettings = useSequenceEditorSettings()
    const SequenceItemStatsBar = editorSettings.sequenceItemStatsBarComponent
    const EntrySequenceItemStatsBar = editorSettings.entrySequenceItemStatsBarComponent
    const isReadOnly = useSelector(selectIsReadOnly)

    const isSequenceItemStatisticsAvailable = [
      NodeType.MESSAGE_SEQUENCE_ITEM.toString(),
      NodeType.SMS_SEQUENCE_ITEM.toString()
    ].includes(props.sequenceItemNode.type)

    const isDropActive = canDropOnNode(draggedItemType, props.sequenceItemNode.type as NodeType)

    let isLinked = false
    let isMoving = false

    const links = inputPort && Object.values(inputPort.getLinks())
    if (links.length > 0 && links[0].getTargetPort()) {
      isLinked = true
    }

    isMoving =
      !linking ||
      linkingMeta.portType === inputPort.type ||
      linkingMeta.portParentSequenceItemId === props.sequenceItemNode.sequenceItemId

    function setZIndex(value: number) {
      nodeRef.current.parentElement.style.zIndex = value.toString()
    }

    function handleCloneSequenceItem() {
      const newSequenceItem = cloneDeep(sequenceItem)

      if (hasFollowUpButtonOnSequenceItemType(newSequenceItem)) {
        delete newSequenceItem.nextSequenceItemTrigger
      }

      newSequenceItem._id = new ObjectId().toHexString()
      newSequenceItem.trigger = [newSequenceItem._id]
      newSequenceItem.tags = [...sequence.tags]

      dispatch(
        createSequenceItem(newSequenceItem, {
          x: props.sequenceItemNode.x + DEFAULT_SEQUENCE_ITEM_OFFSET.x,
          y: props.sequenceItemNode.y + DEFAULT_SEQUENCE_ITEM_OFFSET.y
        })
      )
    }

    function handleRemoveSequenceItem() {
      if (sequence.entrySequenceItemId === sequenceItemId) {
        return false
      }
      dispatch(removeSequenceItem(sequenceItemId))
    }

    function handleCreateFollowUpPort() {
      dispatch(createFollowUpPort(sequenceItemId))
    }

    function renderSequenceItemStats() {
      return (
        <div className={cn({ [styles['extra-bar']]: showSequenceItemStatistics })}>
          {showSequenceItemStatistics &&
            isSequenceItemStatisticsAvailable &&
            (isEntry ? (
              <EntrySequenceItemStatsBar sequenceItemNode={props.sequenceItemNode} />
            ) : (
              <SequenceItemStatsBar sequenceItemNode={props.sequenceItemNode} />
            ))}
        </div>
      )
    }

    return (
      <div
        className={cn(styles['node-wrapper'], {
          [styles.dragging]: isDropActive,
          [styles.faded]: !isDropActive && draggedItemType
        })}
        ref={nodeRef}
      >
        {isReadOnly && (
          <VariableInfoPopover sequenceItemId={props.sequenceItemNode.sequenceItemId}>
            <div className={styles['drag-only']} />
          </VariableInfoPopover>
        )}
        {isDropActive && <div className={styles['drop-label']}>Drop here</div>}
        <div
          onMouseDown={() => {
            setZIndex(4)
          }}
          onMouseUp={() => {
            setZIndex(0)
          }}
          className={cn(styles['sequence-item-node-widget'], {
            [styles.selected]: sequenceItemMeta.selected
          })}
          data-testid={sequenceItem && `node-${sequenceItemId}`}
        >
          <div className={styles.header}>
            <div
              className={cn(styles['port-container'], {
                [styles.disabled]: isReadOnly
              })}
            >
              <Port
                name={inputPort.name}
                nodeId={props.sequenceItemNode.getID()}
                isEntry={isEntry}
                isLinked={isLinked}
                isPulsing={!isMoving}
                doCorrectAlignment
              />
            </div>
            {isEntry && !sequenceItemMeta.error && (
              <div title={sequence.name} className={styles['entry-label']}>
                <EntryTag label={sequence.name} />
              </div>
            )}
            {sequenceItemMeta.error && (
              <div className={styles['error-container']}>
                <div className={styles['error-message']}>{sequenceItemMeta.error}</div>
              </div>
            )}
            <div className={styles['button-bar']}>
              {renderSequenceItemStats()}
              <div className={styles['icon-container']}>
                <SequenceItemButton
                  title='Clone'
                  icon={<CopyIcon />}
                  onClick={handleCloneSequenceItem}
                  disabled={isReadOnly}
                />
                <SequenceItemButton
                  title='Delete'
                  icon={<DeleteIcon />}
                  onClick={handleRemoveSequenceItem}
                  disabled={isEntry || isReadOnly}
                />
              </div>
            </div>
          </div>
          <div className={styles['content-wrapper']}>
            {
              // It's possible that a sequence item model still present in
              // the diagram, but it's already removed from redux
              !sequenceItem ? (
                <div className='p-2'>
                  <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
              ) : (
                <WrappedComponent {...props} />
              )
            }
            <div className={styles['follow-up-wrapper']}>
              <Divider className={styles.divider} />
              {followUpPort ? (
                <LogicSequenceItemPortWidget
                  linking={linking}
                  linkingMeta={linkingMeta}
                  node={props.sequenceItemNode}
                  port={followUpPort}
                />
              ) : (
                <AddElementButton
                  buttonType={AddElementButtonType.FOLLOW_UP}
                  label='Add follow-up'
                  onClick={() => {
                    handleCreateFollowUpPort()
                  }}
                />
              )}
            </div>
          </div>
          {showDebugView && (
            <div className={styles['debug-payload']}>
              <textarea readOnly value={JSON.stringify(sequenceItem, undefined, 4)} />
              <CopyToClipboard text={JSON.stringify(sequenceItem)}>
                <Button onClick={() => Notification.info('Copied')}>Copy payload</Button>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
    )
  }
  return SequenceItemWidget
}
