import { type Variable, VariableParam, variableRawAndTagPattern } from '@ghostmonitor/recartapis'
import { Popover } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useSelector } from 'react-redux'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { hooks } from '../../../../../../hooks/hooks'
import { selectEditorSequence } from '../../../../../../store/selectors'
import {
  convertUserVariables,
  getIdFromVariable,
  getIndexFromId,
  getRawVariableFromVariable,
  getRenderedVariable,
  getVariableFromId,
  getVariableFromRawVariableSequenceEditor,
  isDiscountCodeVariable,
  isUrlVariable,
  replaceUserVariablesToRawVariables,
  replaceVariableTagsToRawVariables,
  specialPlaceholderVariables
} from '../../../../../../utils/inline-editor/inline-editor-utils'
import { type InlineEditorPlugin } from '../../../../../../utils/inline-editor/types/inline-editor-plugin.type'
import { useSequenceEditorSettings } from '../../../../hooks/use-sequence-editor-settings'
import {
  type InlineEditorProps,
  type VariableMouseClickEvent,
  useInlineEditorHOCControl
} from '../../use-inline-editor-hoc-control.hook'
import styles from './make-variable-input.scss'
import { SequenceVariable } from './sequence-variable.component'
import { VariablePopover } from './variable-popover.component'
import {
  type VariableTypeOption,
  entryItemOnlyVariableValues,
  supportEmail,
  variableNames
} from './variable-type-options'

function replaceHtmlValueToRawValue(htmlValue: string): string {
  let newRawValue = convertUserVariables(htmlValue)
  newRawValue = replaceVariableTagsToRawVariables(newRawValue, variableNames)
  newRawValue = replaceUserVariablesToRawVariables(newRawValue)
  return newRawValue
}

function getHtmlFromVariable(variable: Variable, index: number): string {
  const isFallbackEmpty =
    (!Object.keys(specialPlaceholderVariables).includes(variable.name) &&
      variable.params?.default === undefined) ||
    variable.params?.default === ''
  return renderToString(
    <SequenceVariable
      placeholder={getRenderedVariable(variable)}
      id={getIdFromVariable(variable, index)}
      isFallbackEmpty={isFallbackEmpty}
    />
  )
}

function replaceRawValueToHtmlValue(rawValue: string): string {
  let newHtml = rawValue
  let match: string[]
  let index = 0
  const allVarPattern = new RegExp(variableRawAndTagPattern, 'g')

  while ((match = allVarPattern.exec(newHtml))) {
    const variable = getVariableFromRawVariableSequenceEditor(match[0])

    if (!variable) {
      index++
      continue
    }

    // TODO make it run explicitly for the own variables
    if (variable.name === 'url') {
      index++
      continue
    }

    if (isDiscountCodeVariable(variable)) {
      index++
      continue
    }

    newHtml = newHtml.replace(
      getRawVariableFromVariable(variable),
      getHtmlFromVariable(variable, index)
    )
    index++
  }
  return newHtml
}

interface MakeVariableInputProps {}

export function makeVariableInput<T extends InlineEditorProps>(
  WrappedComponent: React.FC<T>
): React.FC<MakeVariableInputProps & T> {
  const VariableInput = (props: React.PropsWithChildren<MakeVariableInputProps & T>) => {
    const {
      html,
      setHtml,
      handlers,
      editorRef,
      rawValue,
      caretPosition,
      insertContent,
      currentNodeIndex
    } = useInlineEditorHOCControl(
      'make-variable',
      props,
      replaceHtmlValueToRawValue,
      replaceRawValueToHtmlValue
    )

    const [showAddVariable, setShowAddVariable] = useState(false)
    const [selectedVariableType, setSelectedVariableType] = useState('')
    const [variableFallback, setVariableFallback] = useState('')
    const [selectedTagId, setSelectedTagId] = useState('')
    const [isUserVariable, setIsUserVariable] = useState(false)
    const [variablePopupOffset, setVariablePopupOffset] = useState([0, 0])
    const [nodeIndexOnAdded, setNodeIndexOnAdded] = useState(-1)
    const variableTriggerRef = useRef<HTMLDivElement>()
    const showEditVariable = useRef(false)
    const editorSettings = useSequenceEditorSettings()
    const { data: site } = hooks.useSite()
    const editorSequence = useSelector(selectEditorSequence)
    const isEntryItem = props.sequenceItemId === editorSequence?.entrySequenceItemId

    useEffect(() => {
      if (nodeIndexOnAdded !== -1) {
        setNodeIndexOnAdded(-1)
      }
    }, [rawValue])

    function isURLVariableName(variableName: string): boolean {
      return variableName.match(/url/) !== null || variableName === 'site.domain'
    }

    function canContainDiscountCode(variableName: string): boolean {
      return ['site.domain', 'cart.url', 'subscriber.optin_source_url'].includes(variableName)
    }

    function getURLVariable(variableName: string): Variable {
      return {
        name: variableName,
        params: {
          [VariableParam.required]: undefined,
          [VariableParam.url_decorate]: undefined,
          [VariableParam.url_shorten]: undefined
        }
      }
    }

    function getVariable(variableName: string): Variable {
      let variable: Variable = {
        name: variableName,
        params: {}
      }

      if (isURLVariableName(variableName)) {
        variable = getURLVariable(variableName)
      }

      if (canContainDiscountCode(variableName)) {
        variable.params[VariableParam.url_add_discount] = undefined
      }

      if (variableFallback) {
        variable.params[VariableParam.default] = variableFallback
      } else {
        variable.params[VariableParam.required] = undefined
      }

      return variable
    }

    function handleAddVariable(variableName: string) {
      const variable = getVariable(variableName)

      const insertedHtml = getHtmlFromVariable(variable, 0)
      const newHtml = insertContent(insertedHtml, caretPosition)

      setHtml(newHtml)
      setNodeIndexOnAdded(currentNodeIndex)
      setShowAddVariable(false)
      editorRef.current.focus()
    }

    function handleFocus() {
      props.onFocus?.()
    }

    function handleMouseMove(event: React.MouseEvent<HTMLDivElement>) {
      props.onMouseMove?.(event)
    }

    function handleMouseLeave(event: React.MouseEvent<HTMLDivElement>) {
      props.onMouseLeave?.(event)
    }

    function getVariableTypeOptions(): VariableTypeOption[] {
      const variableTypeOptions = editorSettings.variableTypeOptions?.filter((option) => {
        return isEntryItem || !entryItemOnlyVariableValues.includes(option.value)
      })
      if (site.supportEmail) {
        return [...new Set(variableTypeOptions), supportEmail]
      }
      return variableTypeOptions
    }

    function renderVariablePopoverContent() {
      return (
        <VariablePopover
          selectedVariableType={selectedVariableType}
          onVariableTypeChange={setSelectedVariableType}
          variableTypeOptions={getVariableTypeOptions()}
          variableFallback={variableFallback}
          onVariableFallbackChange={setVariableFallback}
          onAddVariable={handleAddVariable}
          onFocus={handleFocus}
          onShow={setShowAddVariable}
        />
      )
    }

    function handleChangeTag(isIgnored?: boolean) {
      if (selectedTagId) {
        const variable = getVariableFromId(selectedTagId)
        const selectedIndex = getIndexFromId(selectedTagId)
        const holderElement = document.createElement('div')
        holderElement.innerHTML = html
        for (const node of holderElement.children) {
          if (node.tagName !== 'SPAN') {
            continue
          }
          const id = node.getAttribute('id')
          if (!id) {
            continue
          }
          const index = getIndexFromId(id)
          if (id && index === selectedIndex) {
            if (isIgnored) {
              node.setAttribute(
                'id',
                getIdFromVariable(
                  {
                    name: variable.name,
                    params: {
                      [VariableParam.ignore]: 'ignore'
                    }
                  },
                  selectedIndex
                )
              )
            } else {
              const variable = getVariable(selectedVariableType)
              node.setAttribute('id', getIdFromVariable(variable, selectedIndex))
            }
          }
        }
        const newHtml = holderElement.innerHTML
        setHtml(newHtml)
      }
      showEditVariable.current = false
      setSelectedTagId(undefined)
      setIsUserVariable(false)
    }

    function setVariablePopupPosition(targetVariableRect) {
      const editorRect = editorRef.current.getBoundingClientRect()
      const addVariableButtonRect = variableTriggerRef.current.getBoundingClientRect()
      const horizontalDiff = addVariableButtonRect.left - editorRect.left
      const editVariablePopupOffset = [
        -Math.abs(addVariableButtonRect.left - targetVariableRect.left) +
          targetVariableRect.width / 2 +
          horizontalDiff,
        -Math.abs(addVariableButtonRect.top - targetVariableRect.top) +
          (targetVariableRect.height + 8)
      ]

      setVariablePopupOffset(editVariablePopupOffset)
    }

    function getDefaultVariableName(): string {
      return editorSettings.variableTypeOptions?.[0].value ?? ''
    }

    function toggleVariable(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      setVariablePopupPosition(variableTriggerRef.current?.getBoundingClientRect())
      e.stopPropagation()
      if (!showAddVariable) {
        setSelectedVariableType(getDefaultVariableName())
        setVariableFallback('')
      }
      setShowAddVariable(!showAddVariable)
    }

    function handleClick(event: VariableMouseClickEvent): void {
      if (event.target !== editorRef?.current && props.isEditable) {
        const tagId = event.target.id
        if (tagId) {
          if (showEditVariable.current) {
            setSelectedTagId(undefined)
            showEditVariable.current = false
            props.onClick?.(event)
            return
          }
          const variable = getVariableFromId(tagId)
          if (
            isUrlVariable(variable) ||
            isDiscountCodeVariable(variable) ||
            variable.params[VariableParam.ignore] !== undefined
          ) {
            props.onClick?.(event)
            return
          }

          if (!variableNames.includes(variable.name)) {
            setIsUserVariable(true)
          }

          setSelectedTagId(tagId)
          showEditVariable.current = true
          setShowAddVariable(false)
          setSelectedVariableType(
            variableNames.includes(variable.name) ? variable.name : getDefaultVariableName()
          )
          setVariableFallback(variable.params[VariableParam.default] || '')
          setVariablePopupPosition(event.target.getBoundingClientRect())
        }
      }
      props.onClick?.(event)
    }

    function handlePopoverClose(isIgnored?: boolean) {
      if (isIgnored) {
        handleChangeTag(true)
      }
      showEditVariable.current = false
      setSelectedTagId(undefined)
      setIsUserVariable(false)
    }

    function renderEditVariablePopoverContent() {
      return (
        <>
          <div
            className={styles.pickerWrapper}
            onClick={() => (showEditVariable.current = false)}
          />
          <VariablePopover
            selectedVariableType={selectedVariableType}
            onVariableTypeChange={setSelectedVariableType}
            variableTypeOptions={editorSettings.variableTypeOptions}
            variableFallback={variableFallback}
            onVariableFallbackChange={setVariableFallback}
            onChangeVariable={handleChangeTag}
            onFocus={handleFocus}
            onShow={setShowAddVariable}
            onClose={handlePopoverClose}
            isUserVariable={isUserVariable}
          />
        </>
      )
    }

    const button = (
      <Tooltip placement='topLeft' title='Add variable' arrowPointAtCenter>
        <div
          className={styles.addonContainer}
          data-testid='add-variable-button'
          ref={variableTriggerRef}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClick={toggleVariable}
        >
          <div className={styles.variablePickerIcon}>{`{...}`}</div>
        </div>
      </Tooltip>
    )

    const popover = (
      <>
        <Popover
          trigger='click'
          open={showEditVariable.current}
          onOpenChange={(visible) => {
            if (!visible) {
              setSelectedTagId(undefined)
              showEditVariable.current = false
              setIsUserVariable(false)
            }
          }}
          content={renderEditVariablePopoverContent}
          align={{ offset: variablePopupOffset }}
          placement='bottom'
          autoAdjustOverflow={false}
        />
        <Popover
          open={showAddVariable}
          onOpenChange={(visible) => {
            if (visible) {
              setSelectedVariableType(getDefaultVariableName())
              setVariableFallback('')
              showEditVariable.current = false
            }
            setShowAddVariable(visible)
          }}
          trigger='click'
          placement='bottom'
          autoAdjustOverflow={false}
          content={renderVariablePopoverContent}
          align={{ offset: variablePopupOffset }}
        />
      </>
    )

    const pluginComponent: InlineEditorPlugin = {
      name: 'variable-input',
      button,
      popover
    }

    if (site === undefined) {
      return null
    }

    return (
      <WrappedComponent
        {...props}
        {...handlers}
        editorRef={editorRef}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        html={html}
        rawValue={rawValue}
        plugins={(props.plugins ?? []).concat(pluginComponent)}
      />
    )
  }
  return VariableInput
}
