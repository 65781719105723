import { type SequenceItemUI, type SequenceUI } from '@ghostmonitor/recartapis'
import {
  type SequenceEditorInstance,
  SequenceEditorInstanceInitialState,
  type SequenceEditorInstancePatch,
  type SequenceMeta
} from '../../../store/slices/sequence-editor/sequence-editor.state'
import {
  getLocalSequenceEditorInstance,
  patchLocalSequenceEditorInstance,
  removeLocalSeqenceItem,
  setLocalSequence,
  setLocalSequenceEditorInstance,
  setLocalSequenceItem
} from './localstorage'

export function createLocalDraftSequenceEditorInstance(
  sequence: SequenceUI,
  sequenceMeta: Partial<SequenceMeta>
): void {
  setLocalSequenceEditorInstance(sequence._id, {
    ...SequenceEditorInstanceInitialState,
    sequence,
    serverUpdatedAt: sequence.updatedAt,
    isEverSaved: sequenceMeta.isEverSaved,
    unsavedChanges: sequenceMeta.unsavedChanges
  })
}

export function getLocalDraftSequenceEditorInstance(sequenceId: string): SequenceEditorInstance {
  return getLocalSequenceEditorInstance(sequenceId)
}

export function patchLocalDraftSequenceEditorInstance(
  sequenceId: string,
  patch: Omit<SequenceEditorInstancePatch, 'sequence'>
): void {
  patchLocalSequenceEditorInstance(sequenceId, patch)
}

export function updateLocalDraftSequence(sequenceId: string, sequence: SequenceUI): void {
  setLocalSequence(sequenceId, sequence)
}

export function updateLocalDraftSequenceItem(sequenceItem: SequenceItemUI): void {
  setLocalSequenceItem(sequenceItem)
}

export function removeLocalDraftSequenceItem(sequenceItemId: string): void {
  removeLocalSeqenceItem(sequenceItemId)
}
