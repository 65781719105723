import {
  type GetProductVariantByVariantIdResponse,
  type ProductVariant
} from '@ghostmonitor/recartapis'
import { useQueries } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useProductVariantsById(
  variantIds: string[]
): UseResource<ProductVariant.ProductVariantResp[]> {
  const productQueries = useQueries({
    queries: variantIds?.map((variantId: string) => {
      return {
        queryKey: [QUERY_NAME.productVariantsById, variantId],
        queryFn: () =>
          request.get<GetProductVariantByVariantIdResponse>(`product-variants/${variantId}`)
      }
    })
  })

  const result = productQueries
    .filter((productQuery) => productQuery.data?.data !== undefined)
    .map((productQuery) => productQuery.data?.data)

  const isLoading = productQueries.some((productQuery) => productQuery.isLoading)

  return {
    data: result,
    isLoading
  }
}
