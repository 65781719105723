/* global LAUNCHDARKLY_KEY */

import { type Site, type User } from '@ghostmonitor/recartapis'
import * as LDClient from 'launchdarkly-js-client-sdk'

let launchDarklyClient: Promise<LDClient.LDClient>

function getLaunchDarklyUser(user: User, site: Site) {
  return {
    kind: 'user',
    key: site.id,
    email: user.email,
    custom: {
      createdAt: new Date(site.createdAt).getTime()
    }
  }
}

export async function getLaunchDarklyClient(user: User, site: Site): Promise<LDClient.LDClient> {
  if (launchDarklyClient !== undefined) {
    return launchDarklyClient
  }

  const launchDarklyUser = getLaunchDarklyUser(user, site)
  launchDarklyClient = new Promise((resolve) => {
    // @ts-expect-error global
    const client = LDClient.initialize(LAUNCHDARKLY_KEY, launchDarklyUser)
    client.on('ready', () => {
      resolve(client)
    })
  })

  return launchDarklyClient
}
