import {
  type ButtonUI,
  type LinkingMeta,
  type MessengerReviewTemplatePayload,
  type SequenceItemUI
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { ReactComponent as IconBag } from '../../../../../static/images/svg/icon-bag.svg'
import {
  ButtonInitialMeta,
  type ButtonMeta,
  type MessageMeta,
  type SequenceItemMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import { type MessageSequenceItemNodeModel } from '../../../models/sequence-item/message/message-sequence-item-model'
import { getErrorMessage } from '../../../types/sequence-editor-errors'
import {
  type InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import {
  type ButtonProps,
  MessengerButton
} from '../../messenger-button/messenger-button.component'
import { type MessengerTemplateProps } from '../messenger-template-props.type'
import styles from './messenger-review-template.component.scss'

export interface MessengeReviewTemplateProps {
  messengerTemplatePayload: MessengerReviewTemplatePayload
}

interface ControlProps extends InteractiveComponentProps {
  selectButton: (sequeceItem: SequenceItemUI) => void
  onMessageFocus: () => void
  onMessageBlur: () => void
  onButtonChange: (
    buttonIndex: number,
    buttonChange: Partial<ButtonUI>,
    messageItemIndex?: number
  ) => void
  onButtonFocus: (buttonIndex: number, messageItemIndex?: number) => void
  onButtonBlur: () => void
  onRemove: () => void
  onAddButton: (buttonIndex: number, messageItemIndex?: number) => void
  onRemoveButton: (buttonIndex: number, messageItemIndex?: number) => void
  clearSelection?: () => void
  messageMeta: MessageMeta
  sequenceItemMeta: SequenceItemMeta
  sequenceItemNodeModel: MessageSequenceItemNodeModel
  sequenceItemId: string
  messageIndex: number
  messageItemIndex?: number
  linking: boolean
  linkingMeta: LinkingMeta
  hasReviewUrl?: boolean
}

const InteractiveButton = makeInteractive<ButtonProps>(MessengerButton)

const InteractiveContent = makeInteractive<
  MessengerTemplateProps<MessengerReviewTemplatePayload> & InteractiveComponentProps
>((props) => {
  return (
    <div className={styles.container}>
      <div className={styles.product}>
        <div className={styles.productPic}>
          <IconBag />
        </div>
        <div>Your product picture</div>
      </div>
      <div className={styles.item}>
        <span className={styles.itemName}>Demo Almanac</span>
        <span>1 x $ 12.99</span>
      </div>
    </div>
  )
})

export function MessengerReviewTemplate(
  props: MessengerTemplateProps<MessengerReviewTemplatePayload>
) {
  const buttons = props.messengerTemplatePayload.buttons

  function handleAddButton(buttonIndex: number) {
    if (buttons.length >= 3) {
      return false
    }
    props.onAddButton(buttonIndex)
    props.clearSelection()
  }

  return (
    <div>
      <InteractiveContent
        // MessengerTemplateProps
        {...props}
        // InteractiveComponentProps
        errorMessage={getErrorMessage(props.messageMeta.errors[0])}
        isFirst
        isLast={!buttons.length}
        isSelected={false}
        isBlurred={false}
        hasAddButton={buttons.length < 3}
        isDraggable
        onRemoveElement={props.onRemove}
        onAddButtonClick={() => handleAddButton(0)}
        onMessageFocus={props.onMessageFocus}
      />
      {buttons.length > 0 && (
        <div className={styles.buttons}>
          {buttons.map((buttonPayload, buttonIndex) => {
            const buttonMeta: ButtonMeta = props.messageMeta.buttons[buttonIndex]
              ? props.messageMeta.buttons[buttonIndex]
              : cloneDeep(ButtonInitialMeta)
            return (
              <InteractiveButton
                key={buttonIndex}
                // ButtonProps
                payload={buttonPayload}
                buttonTypeOptions={props.buttonTypeOptions}
                sequenceItemId={props.sequenceItemNodeModel.sequenceItemId}
                sequenceItemNodeId={props.sequenceItemNodeModel.getID()}
                port={props.sequenceItemNodeModel.getButtonPort(
                  props.sequenceItemNodeModel.sequenceItemId,
                  props.messageIndex,
                  buttonIndex
                )}
                linking={props.linking}
                linkingMeta={props.linkingMeta}
                disableTypeChange={props.disableButtonTypeChange}
                onChange={(buttonChange) => {
                  props.onButtonChange(buttonIndex, buttonChange)
                }}
                onFocus={() => {
                  props.onButtonFocus(buttonIndex)
                }}
                onBlur={() => {
                  props.onButtonBlur()
                }}
                clearSelection={props.clearSelection}
                // InteractiveComponentProps
                errorMessage={buttonMeta.error}
                isLast={buttonIndex === buttons.length - 1}
                isSelected={buttonMeta.selected}
                isBlurred={!buttonMeta.selected && props.sequenceItemMeta.selected}
                onRemoveElement={() => {
                  props.onRemoveButton(buttonIndex)
                }}
                onAddButtonClick={() => handleAddButton(buttonIndex + 1)}
                hasAddButton={buttons.length < 3}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
