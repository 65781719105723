import {
  type ButtonUI,
  isMessengerMessageSequenceItemUI,
  type MessengerGenericTemplatePayload
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { isMessageGenericGalleryTemplate } from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { createScope } from '../../../../utils/logger/logger'
import { type CreateMessageItemAction, CREATE_BUTTON } from '../sequence-editor.actions'
import { selectSequenceItem, selectSequenceItemMeta } from '../sequence-editor.selectors'
import { MessageItemInitialMeta, type SequenceEditorState } from '../sequence-editor.state'
import { createButtonActionHandler } from './create-button.handler'

const { info } = createScope('sequence-editor')

export function createMessageItemActionHandler(
  state: SequenceEditorState,
  action: CreateMessageItemAction
) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  const messageItemPayload: MessengerGenericTemplatePayload = action.messageItemPayload ?? {
    title: 'New arrivals',
    subtitle: 'Check out our newest products',
    buttons: [],
    image_url: ''
  }

  function createButton(button: ButtonUI, messageItemIndex: number) {
    createButtonActionHandler(state, {
      type: CREATE_BUTTON,
      sequenceItemId: action.sequenceItemId,
      messageIndex: action.messageIndex,
      messageItemIndex,
      button
    })
  }

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]
    if (isMessageGenericGalleryTemplate(message)) {
      const messageItemIndex = action.messageItemIndex ?? message.messengerTemplatePayload.length

      message.messengerTemplatePayload.splice(messageItemIndex, 0, cloneDeep(messageItemPayload))
      sequenceItemMeta.messages[action.messageIndex].messageItems.splice(
        messageItemIndex,
        0,
        cloneDeep(MessageItemInitialMeta)
      )

      message.messengerTemplatePayload[messageItemIndex].buttons = []
      messageItemPayload.buttons.forEach((button) => {
        createButton(button, messageItemIndex)
      })
    } else {
      info('createMessageItemActionHandler:1', { action, sequenceItem })
    }
  } else {
    info('createMessageItemActionHandler:2', { action, sequenceItem })
  }
}
