// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SX0cFCRBi7uwAZ4mv6wf{background-color:#e9ebf0;display:flex;flex:0 0 164px;flex-direction:column;padding:10px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-editor-tray/sequence-editor-tray.component.scss"],"names":[],"mappings":"AACE,sBACE,wBAAA,CAEA,YAAA,CADA,cAAA,CAEA,qBAAA,CACA,YAAA","sourcesContent":[":local {\n  .tray {\n    background-color: #e9ebf0;\n    flex: 0 0 164px;\n    display: flex;\n    flex-direction: column;\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tray": `SX0cFCRBi7uwAZ4mv6wf`
};
export default ___CSS_LOADER_EXPORT___;
