import { Button } from 'antd'
import { ObjectId } from 'bson'
import cn from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Tooltip } from '../../../../../components/ui-kit/tooltip/tooltip.component'
import { ReactComponent as CopyIcon } from '../../../../../static/images/svg/copy.svg'
import { ReactComponent as Spinner } from '../../../../../static/images/svg/ui-kit/spinner.svg'
import type { AppDispatch } from '../../../../../store/create-store'
import {
  selectEditorSequence,
  selectErrorCount,
  selectFacebookPageId,
  selectLaunchDarklyFlag,
  selectSaving,
  selectUnsavedChanges
} from '../../../../../store/selectors'
import { sendTestThunk } from '../../../../../store/slices/sequence-editor/thunks/send-test.thunk'
import { initFacebookSdk } from '../../../../../utils/fb'
import { Notification } from '../../../../../utils/notification/notification.util'
import { useSaveSequenceEditor } from '../../../hooks/use-save-sequence'
import { SequenceEditorErrorMessage } from '../../../types/sequence-editor-errors'
import styles from './header-send-test.component.scss'

declare global {
  interface Window {
    FB: any
    fbAsyncInit: any
  }
  interface Global {
    confirmOptIn: any
  }
  const MESSENGER_APP_ID: string
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    origin?: string
    page_id?: string
    messenger_app_id?: string
    user_ref?: string
    allow_login?: string
    center_align?: string
    size?: string
  }
}

export function HeaderSendTest() {
  const dispatch: AppDispatch = useDispatch()
  const stateSelector = useCallback(
    createStructuredSelector({
      sequence: selectEditorSequence,
      facebookPageId: selectFacebookPageId,
      saving: selectSaving,
      unsavedChanges: selectUnsavedChanges,
      errorCount: selectErrorCount
    }),
    []
  )

  const state = useSelector(stateSelector)

  const [popOverOpen, setPopOverOpen] = useState(false)
  const [loadingFbCheckbox, setLoadingFbCheckbox] = useState(false)
  const [fbUserRef, setFbUserRef] = useState(null)
  const [checkboxOn, setCheckboxOn] = useState(false)
  const [isCheckboxRendered, setIsCheckboxRendered] = useState(true)
  const [isCopiedVisible, setIsCopiedVisible] = useState(false)
  const isNewSendTestFlow = useSelector(selectLaunchDarklyFlag('new-send-test-flow'))
  const { saveSequenceEditor } = useSaveSequenceEditor()

  useEffect(() => {
    if (fbUserRef) {
      initFbCheckbox()
    }
  }, [fbUserRef])

  async function initFbCheckbox() {
    setLoadingFbCheckbox(true)
    await initFacebookSdk()

    window.FB.Event.subscribe('messenger_checkbox', function (e) {
      if (e.event === 'rendered') {
        setIsCheckboxRendered(true)
        setLoadingFbCheckbox(false)
      }
      if (e.event === 'hidden') {
        setIsCheckboxRendered(false)
        setLoadingFbCheckbox(false)
      }
      if (e.event === 'checkbox') {
        const checkboxState = e.state
        setCheckboxOn(checkboxState === 'checked')
        if (checkboxState === 'checked') {
          window.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
            app_id: MESSENGER_APP_ID,
            page_id: state.facebookPageId,
            ref: e.ref,
            user_ref: e.user_ref
          })
        }
      }
    })
  }

  async function handleClickPopupSendTest() {
    setLoadingFbCheckbox(true)

    const sendTestResult = await dispatch(
      sendTestThunk({
        sequenceId: state.sequence._id,
        sequenceItemId: state.sequence.entrySequenceItemId,
        recipient: { recipient: { user_ref: fbUserRef } }
      })
    )
    if (sendTestThunk.fulfilled.match(sendTestResult)) {
      Notification.info('Test message sent.')
    }
    if (sendTestThunk.rejected.match(sendTestResult)) {
      Notification.error(SequenceEditorErrorMessage.SEND_TEST_ERROR)
    }

    setLoadingFbCheckbox(false)
    setPopOverOpen(false)
  }

  async function handleClickSendTest() {
    setPopOverOpen(true)
    if (state.unsavedChanges) {
      try {
        await saveSequenceEditor()
        setFbUserRef(`${new ObjectId().toHexString()}-${new Date().getTime()}`)
      } catch {}
    } else {
      setFbUserRef(`${new ObjectId().toHexString()}-${new Date().getTime()}`)
    }
  }

  function handleCopy() {
    setIsCopiedVisible(true)
    setTimeout(() => {
      setIsCopiedVisible(false)
    }, 2000)
  }

  function renderInstructions() {
    return (
      <div className={styles.instructonsContainer}>
        <div className={styles.title}>Test in Facebook Messenger</div>
        <ul className={styles.insctructions}>
          <li>
            Copy the following code: <br />
            <CopyToClipboard text={`sequence_${state.sequence._id}`} onCopy={handleCopy}>
              <span className={styles.sequenceCode}>
                sequence_{state.sequence._id}
                <Tooltip open={isCopiedVisible} title='Copied'>
                  <CopyIcon />
                </Tooltip>
              </span>
            </CopyToClipboard>
          </li>
          <li>Open Messenger, paste and send the code to your Facebook Page</li>
        </ul>

        <div className='flex items-center justify-center p-3'>
          <a
            href='https://help.recart.com/en/articles/4733079-how-can-i-test-my-conversation-flows'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className={styles.link} type='link'>
              Learn more
            </Button>
          </a>
          <a
            href={`https://m.me/${state.facebookPageId}`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <Button type='primary'>Open Messenger</Button>
          </a>
        </div>
      </div>
    )
  }

  /* eslint-disable react/no-unknown-property */
  function renderCheckbox() {
    return (
      <>
        {!loadingFbCheckbox &&
          (checkboxOn ? null : <div className={styles.title}>Please check the box below</div>)}
        <div
          className='fb-messenger-checkbox'
          origin={document.location.origin}
          page_id={state.facebookPageId}
          messenger_app_id={MESSENGER_APP_ID}
          user_ref={fbUserRef}
          allow_login='true'
          center_align='true'
          size='large'
        />
        {loadingFbCheckbox ? (
          <div className={styles.loaderContainer}>
            <Spinner className={styles.loader} />
          </div>
        ) : (
          <div className='flex justify-center'>
            <Button
              type='primary'
              disabled={!checkboxOn || state.unsavedChanges || state.saving}
              onClick={handleClickPopupSendTest}
            >
              Send
            </Button>
          </div>
        )}
      </>
    )
  }
  /* eslint-enable react/no-unknown-property */

  return (
    <div className={styles.sendTestContainer}>
      <div className={cn(styles.popover, { [styles.popOverOpen]: popOverOpen })}>
        <div
          className={styles.close}
          onClick={() => {
            setPopOverOpen(false)
          }}
        >
          ×
        </div>
        {isNewSendTestFlow
          ? isCheckboxRendered
            ? renderCheckbox()
            : renderInstructions()
          : renderCheckbox()}
      </div>
      <Button
        size='large'
        onClick={handleClickSendTest}
        className={styles.button}
        disabled={state.saving || state.errorCount > 0}
        data-testid='send-test-button'
      >
        Send test
      </Button>
      {popOverOpen && (
        <div
          className={styles.popoverWrapper}
          onClick={() => {
            setPopOverOpen(false)
          }}
        />
      )}
    </div>
  )
}
