import { type URLGenerationSettingsInUse, type Variable } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import { renderToString } from 'react-dom/server'
import { getIdFromVariable, getRenderedVariable } from './get-variables'
import {
  isDiscountCodeVariable,
  isDiscountUrlVariable,
  isKeywordReplyVariable,
  isLinkVariable
} from './variable-assertion'

import { type VariablePlaceholderData } from '../../store/slices/flow-editor/flow-editor.state'
import styles from './replace-raw-value-to-html-value.scss'
interface RichTextTagProps {
  placeholder: string
  id: string
  isUrl: boolean
  isError: boolean
  selected?: boolean
  isUserVariable?: boolean
  disableClick?: boolean
  isDiscountCode?: boolean
}
interface CanvasVariableTagProps {
  placeholder: string
  id: string
  isUrl: boolean
  isError: boolean
  isDiscountCode?: boolean
  isKeywordReply?: boolean
}

function RichTextTag(props: RichTextTagProps) {
  return (
    <span
      contentEditable={false}
      id={props.id}
      data-is-error={props.isError}
      data-is-url={props.isUrl}
      className={cn(props.isUrl ? styles.richTextTagLink : styles.richTextTag, 'group', {
        [styles.selectedTag]: props.selected,
        [styles.userVariable]: props.isUserVariable,
        [styles.invalidVariable]: props.isError && !props.isUrl,
        [styles.invalidLink]: props.isError && props.isUrl,
        [styles.invalidDiscountCode]: props.isError && props.isDiscountCode,
        [styles.disableClick]: props.disableClick,
        uppercase: props.isDiscountCode
      })}
      style={{
        userSelect: 'none'
      }}
    >
      {props.placeholder}
    </span>
  )
}

function CanvasVariableTag(props: CanvasVariableTagProps) {
  return (
    <span
      contentEditable={false}
      id={props.id}
      data-is-error={props.isError}
      data-is-url={props.isUrl}
      className={cn(props.isUrl ? styles.canvasVariableTagLink : styles.canvasVariableTag, {
        uppercase: props.isDiscountCode || props.isKeywordReply
      })}
    >
      {props.placeholder}
    </span>
  )
}

// eslint-disable-next-line @typescript-eslint/max-params
export function getHtmlFromVariable(
  variable: Variable,
  index: number,
  isError: boolean,
  urlGenerationSettings: URLGenerationSettingsInUse,
  selected?: boolean,
  prefix?: string,
  isUserVariable?: boolean,
  disableClick?: boolean
): string {
  return renderToString(
    <RichTextTag
      placeholder={getRenderedVariable(variable, urlGenerationSettings, prefix)}
      id={getIdFromVariable(variable, index)}
      isUrl={isLinkVariable(variable)}
      isError={isError}
      selected={selected}
      isUserVariable={isUserVariable}
      disableClick={disableClick}
      isDiscountCode={isDiscountCodeVariable(variable) && !isDiscountUrlVariable(variable)}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/max-params
export function getCanvasHtmlFromVariable(
  variable: Variable,
  index: number,
  isError: boolean,
  placeholderData: VariablePlaceholderData | null,
  prefix?: string
): string {
  if (!placeholderData) {
    return ''
  }

  let placeholder: string
  // todo extend this with future variables
  switch (variable.name) {
    case 'site.name':
      placeholder = placeholderData.siteName
      break
    case 'site.email':
      placeholder = placeholderData.supportEmail
      break

    default:
      placeholder = getRenderedVariable(variable, placeholderData.urlGenerationSettings, prefix)
  }

  const isLinkOnCanvas = isLinkVariable(variable) || variable.name === 'site.email'

  return renderToString(
    <CanvasVariableTag
      placeholder={placeholder}
      id={getIdFromVariable(variable, index)}
      isUrl={isLinkOnCanvas}
      isError={isError}
      isDiscountCode={isDiscountCodeVariable(variable) && !isDiscountUrlVariable(variable)}
      isKeywordReply={isKeywordReplyVariable(variable)}
    />
  )
}
