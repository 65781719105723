import { type SerializedError } from '@reduxjs/toolkit'

export enum ApiErrorMessages {
  ENDLESS_LOOP = 'Infinite loop in sequence',
  INVALID_CONNECTED_FACEBOOK_PAGE = 'InvalidConnectedFacebookPage',
  FACEBOOK_REQUEST_ERROR = 'FacebookRequestError'
}

export enum SequenceEditorErrorMessage {
  ENDLESS_LOOP = 'Your flow contains and endless loop. Please rearrange your messages and avoid loopbacks.',
  INVALID_CONNECTED_FACEBOOK_PAGE = 'Incorrect Facebook Page set in ad set. Please verify that selected ad set promotes the Facebook Page connected to Recart.',

  SAVE_ERROR = 'Could not save changes. Please try again or contact support.',
  LOAD_ERROR = "Couldn't load the flow. Please try again or contact support.",
  SEND_TEST_ERROR = 'Couldn’t send test message. Please try again or contact support.',
  MESSAGE_CANNOT_BE_ADDED = 'Message cannot be added due to Facebook limitations.',
  QUICK_REPLY_TEXT_TOO_LONG = 'Text is too long',
  SHOPIFY_DISCOUNT_CODE_ERROR = 'We could not add your discount code to your message. Try again later!',
  FLOW_NOT_COMPATIBLE = 'This flow is not compatible with the current editor version. Please contact support.'
}

export enum SequenceEditorError {
  DiscountCodeNotExists = 'DiscountCodeNotExists',
  DiscountCodeExpired = 'DiscountCodeExpired',
  DiscountCodeError = 'DiscountCodeError',
  MMSImageUploadError = 'MMSImageUploadError',
  MessageInvalidDiscountCode = 'MessageInvalidDiscountCode',
  MessageEmptyMessage = 'MessageEmptyMessage',
  MessageEmptyTitle = 'MessageEmptyTitle',
  MessageEmptySubtitle = 'MessageEmptySubtitle',
  MessageEmptyMediaAttachment = 'MessageEmptyMediaAttachment',
  MessageInvalidImageAttachment = 'MessageInvalidImageAttachment',
  MessageInvalidVideoAttachment = 'MessageInvalidVideoAttachment',
  MessageInvalidAudioAttachment = 'MessageInvalidAudioAttachment',
  MessageInvalidFileAttachment = 'MessageInvalidFileAttachment',
  MessageExceedingMaxCharCount = 'MessageExceedingMaxCharCount',
  FlowNotCompatible = 'FlowNotCompatible'
}

export const errorNameToMessage = {
  [ApiErrorMessages.ENDLESS_LOOP]: SequenceEditorErrorMessage.ENDLESS_LOOP,
  [ApiErrorMessages.INVALID_CONNECTED_FACEBOOK_PAGE]:
    SequenceEditorErrorMessage.INVALID_CONNECTED_FACEBOOK_PAGE,

  [SequenceEditorError.MessageInvalidDiscountCode]: 'Invalid discount code',
  [SequenceEditorError.MessageEmptyMessage]: 'Message cannot be empty',
  [SequenceEditorError.MessageEmptyTitle]: 'Message title cannot be empty',
  [SequenceEditorError.MessageEmptySubtitle]: 'Message subtitle cannot be empty',
  [SequenceEditorError.MessageEmptyMediaAttachment]: 'Please upload a media file',
  [SequenceEditorError.MessageInvalidImageAttachment]: 'Please upload an image',
  [SequenceEditorError.MessageInvalidVideoAttachment]: 'Please upload a video',
  [SequenceEditorError.MessageInvalidAudioAttachment]: 'Please upload an audio',
  [SequenceEditorError.MessageInvalidFileAttachment]: 'Please upload a file',
  [SequenceEditorError.MessageExceedingMaxCharCount]: 'You reached the character limit',
  [SequenceEditorError.MMSImageUploadError]: 'File upload error',
  [SequenceEditorError.DiscountCodeError]: SequenceEditorErrorMessage.SHOPIFY_DISCOUNT_CODE_ERROR,
  [SequenceEditorError.FlowNotCompatible]: SequenceEditorErrorMessage.FLOW_NOT_COMPATIBLE
}

const proxyErrors: string[] = [ApiErrorMessages.FACEBOOK_REQUEST_ERROR]

export function getErrorMessage(error: SerializedError, defaultMessage?: string): string {
  if (!error) {
    return undefined
  }

  if (proxyErrors.includes(error.name)) {
    return error.message
  }

  return errorNameToMessage[error.name] ?? defaultMessage ?? error.name ?? error.message
}
