import { Select as AntSelect } from 'antd'
import type { SelectProps as AntSelectProps } from 'antd/lib/select'
import cn from 'classnames'
import { isValidElement } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../static/ui-kit/icons/small/arrow-down.svg'
import { ReactComponent as CloseIcon } from '../../../static/ui-kit/icons/small/close-with-bg.svg'
import { Tag } from '../tag/tag.component'
import { Tooltip } from '../tooltip/tooltip.component'
import styles from './select.component.scss'

export function getLabelFromDropdownElement(dropdownElement: JSX.Element | string) {
  let label: string

  if (isValidElement<any>(dropdownElement)) {
    label = getLabelFromDropdownElement(dropdownElement.props.children)
  } else if (Array.isArray(dropdownElement)) {
    const element = dropdownElement.find(
      (currentLabel) =>
        ['span', 'p', 'div'].includes(currentLabel.type) || typeof currentLabel === 'string'
    )
    label = typeof element === 'string' ? element : element.props.children
  } else {
    label = dropdownElement as string
  }

  return label
}

export type SelectProps = AntSelectProps<any> & {
  isError?: boolean
  invalidTags?: string[]
}

export function Select(props: SelectProps) {
  const { className, popupClassName, isError, invalidTags, ...restProps } = props

  function renderTag(tagProps) {
    return (
      <Tag
        closable
        onClose={tagProps.onClose}
        className='m-1'
        maxLength={30}
        type={invalidTags?.includes(tagProps.value) ? 'error' : 'default'}
      >
        {getLabelFromDropdownElement(tagProps.label)}
      </Tag>
    )
  }

  function renderMaxTagPlaceholder(omittedValues: any[]) {
    const labels = omittedValues.map((value) => getLabelFromDropdownElement(value.label))

    return (
      <Tooltip
        title={labels.map((label, index) => (
          <div key={`${label}-${index}`}>{label}</div>
        ))}
        placement='bottom'
      >
        <span className='font-semibold'>+ {omittedValues.length} ...</span>
      </Tooltip>
    )
  }

  let multiSelectProps = {}
  if (props.mode === 'multiple' || props.mode === 'tags') {
    multiSelectProps = {
      showArrow: true,
      tagRender: renderTag,
      maxTagPlaceholder: renderMaxTagPlaceholder
    }
  }

  return (
    <AntSelect
      data-testid={props['data-testid'] ?? 'select'}
      className={cn(styles.select, className, {
        [styles.selectError]: isError
      })}
      size='large'
      suffixIcon={<ArrowDownIcon />}
      clearIcon={<CloseIcon />}
      popupClassName={cn(styles.dropdown, popupClassName)}
      listItemHeight={props.listItemHeight || 40}
      {...multiSelectProps}
      {...restProps}
    >
      {props.children}
    </AntSelect>
  )
}

export const { Option, OptGroup } = AntSelect
