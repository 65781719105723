import { type Action, isAnyOf, type Store } from '@reduxjs/toolkit'
import { setLocalStorageItem } from '../../../../utils/set-local-storage-item'
import { type AppDispatch } from '../../../create-store'
import { type DashboardState } from '../../../dashboard.state'
import { selectRecentlyUsedFontTypes, selectSiteId } from '../../../selectors'
import {
  createOptinTool,
  fontTypeSelected,
  loadRecentlyUsedFonts
} from '../optin-tool-editor.reducer'
import { loadEditorThunk } from '../thunks/load-editor.thunk'

export function optinToolEditorFontsMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  return (next) =>
    (action: Action): void => {
      next(action)

      const state = store.getState()
      const siteId = selectSiteId(state)

      if (isAnyOf(createOptinTool, loadEditorThunk.fulfilled)(action)) {
        const recentlyUsedFonts = localStorage.getItem(
          `optinToolEditor-recentlyUsedFonts-${siteId}`
        )
        const fonts = recentlyUsedFonts === null ? [] : JSON.parse(recentlyUsedFonts)

        dispatch(loadRecentlyUsedFonts({ fonts: fonts.filter((font) => font.includes('_')) })) // TODO: [custom-fonts] can be removed later, to make sure only the new format is stored in localstorage
      }

      if (fontTypeSelected.match(action)) {
        const recentlyUsedFontTypes = selectRecentlyUsedFontTypes(state)
        setLocalStorageItem(
          `optinToolEditor-recentlyUsedFonts-${siteId}`,
          JSON.stringify(recentlyUsedFontTypes)
        )
      }
    }
}
