import { type SerializedDiagram } from '@ghostmonitor/recartapis'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type DiagramEngine, DiagramModel } from 'storm-react-diagrams'
import {
  selectEditorSequence,
  selectSequenceItemsById,
  selectSequenceItemsMetaById
} from '../../../store/selectors'
import { updateModelOnSequence } from '../../../store/slices/sequence-editor/sequence-editor.actions'
import { getDiagramEngine } from '../components/sequence-editor/get-diagram-engine'
import { useRepaintCanvas } from './use-repaint-canvas'

export function useDiagramEngine(): { diagramEngine: DiagramEngine; repaintCanvas: () => void } {
  const dispatch = useDispatch()
  const repaintCanvas = useRef<() => void>()
  const sequence = useSelector(selectEditorSequence)
  const sequenceItemsById = useSelector(selectSequenceItemsById)
  const sequenceItemsMetaById = useSelector(selectSequenceItemsMetaById)

  const diagramEngine = useMemo(() => {
    function handleModelChange(serializedDiagram: SerializedDiagram) {
      dispatch(updateModelOnSequence(serializedDiagram))
    }

    return getDiagramEngine(handleModelChange, repaintCanvas.current)
  }, [dispatch])

  // TODO refactor from here
  useEffect(() => {
    repaintCanvas.current()
  }, [sequence.quickReplyIndexToId, sequenceItemsById, sequenceItemsMetaById])

  // TODO refactor from here
  useEffect(() => {
    if (diagramEngine.serialize() !== sequence.serializedDiagram) {
      const model = new DiagramModel()
      model.deSerializeDiagram(sequence.serializedDiagram, diagramEngine)
      Object.values(model.nodes).forEach((node) => {
        node.selected = false
      })

      diagramEngine.setDiagramModel(model)
      repaintCanvas.current()
    }
  }, [sequence.serializedDiagram, diagramEngine])

  repaintCanvas.current = useRepaintCanvas(diagramEngine)

  return {
    diagramEngine,
    repaintCanvas: repaintCanvas.current
  }
}
