import { type BillingSubscription } from '@ghostmonitor/recartapis'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useSMSSubscription(): UseResource<BillingSubscription> {
  const { data: billingSubscriptions, isLoading } = hooks.useBillingSubscriptions()

  const smsSubscription = billingSubscriptions?.find((subscription) => subscription.type === 'sms')

  return {
    data: smsSubscription,
    isLoading
  }
}
