import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat'
import {
  getLocalOptinTool,
  removeLocalOptinTool
} from '../../routes/optin-tools/utils/localstorage'
import { type AppDispatch } from '../../store/create-store'
import { selectOptinTool, selectOptinToolMeta } from '../../store/selectors'
import { loadOptinTool } from '../../store/slices/optin-tool-editor/optin-tool-editor.reducer'
import { loadEditorThunk } from '../../store/slices/optin-tool-editor/thunks/load-editor.thunk'
import { validateOptinTool } from '../../store/slices/optin-tool-editor/thunks/validate-optin-tool.thunk'
import { useAvailableGeotargetingCountries } from '../use-available-geotargeting-countries'

export function useOptinToolEditor() {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isLoadedRef = useRef(false)
  const [isValidated, setIsValidated] = useState(false)
  const { optinToolId } = useParams<{ optinToolId: string }>()
  const isDuplicate = location.pathname.includes('duplicate')
  const optinTool = useSelector(selectOptinTool, (prevState, nextState) =>
    isEqual(omit(prevState, 'settings'), omit(nextState, 'settings'))
  )
  const optinToolMeta = useSelector(selectOptinToolMeta)
  const { availableGeotargetingCountries, isLoading: isAvailableGeotargetingLoading } =
    useAvailableGeotargetingCountries(optinTool)

  useEffect(() => {
    async function loadEditor() {
      const localOptinToolEditor = getLocalOptinTool(optinToolId)
      if (localOptinToolEditor) {
        dispatch(
          loadOptinTool({
            optinTool: localOptinToolEditor.optinTool,
            optinToolMeta: localOptinToolEditor.optinToolMeta
          })
        )
      } else {
        const loadEditorResult = await dispatch(loadEditorThunk({ optinToolId }))

        if (loadEditorThunk.rejected.match(loadEditorResult) && !optinTool) {
          navigate('/optin-tools')
        }
      }
    }

    const isNewOptinTool = !optinTool || optinTool.id !== optinToolId

    if (!isLoadedRef.current && isNewOptinTool) {
      isLoadedRef.current = true
      loadEditor()
    }
  }, [optinToolId, optinTool, navigate, dispatch, isDuplicate])

  useEffect(() => {
    const canValidate =
      optinTool && optinToolMeta.saved && !isAvailableGeotargetingLoading && !isValidated
    const redirectFromFlowEditor = !!getLocalOptinTool(optinToolId) && optinTool

    if (canValidate || redirectFromFlowEditor) {
      dispatch(validateOptinTool(availableGeotargetingCountries))
      setIsValidated(true)

      if (redirectFromFlowEditor) {
        removeLocalOptinTool(optinToolId)
      }
    }
  }, [optinTool, availableGeotargetingCountries, isAvailableGeotargetingLoading])
}
