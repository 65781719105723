import { type LogicSplitVariantAPI } from '@ghostmonitor/recartapis'
import { type AnyAction, isAnyOf, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { type AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import {
  flowItemValidationFailed,
  flowItemValidationSucceeded
} from '../../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../../store/slices/flow-editor/flow-editor.reducer'
import { validateRandomSplit } from '../../../../utils/validators/flow/validate-random-split'

const DEBOUNCE_DELAY = 500

function getValidateRandomSplitFlowItem(dispatch: AppDispatch) {
  return debounce(
    (flowItemId: string, variants: LogicSplitVariantAPI[]) => {
      const error = validateRandomSplit(variants)
      if (error) {
        dispatch(
          flowItemValidationFailed({
            flowItemId,
            errors: [error]
          })
        )
      } else {
        dispatch(
          flowItemValidationSucceeded({
            flowItemId
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

export const getValidateRandomSplitFlowItemChangedAction = (store: Store<DashboardState>) => {
  const dispatch: AppDispatch = store.dispatch
  const validateRandomSplitElement = getValidateRandomSplitFlowItem(dispatch)

  return (store: Store<DashboardState>, action: AnyAction) => {
    if (
      isAnyOf(
        flowEditorActions.randomSplitElementValueChanged,
        flowEditorActions.randomSplitElementAdded,
        flowEditorActions.randomSplitElementDeleted
      )(action)
    ) {
      validateRandomSplitElement(action.payload.flowItemId, action.payload.variants)
    }

    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      validateRandomSplitElement.cancel()
    }
  }
}
