import {
  type FlowItemUI,
  type MessageUI,
  getTextFromMessage,
  hasTextOnMessage
} from '@ghostmonitor/recartapis'
import {
  hasMessageOnFlowItemType,
  isSMSMessageFlowItemUI
} from '../../../../types/guards/flow-item-ui.guards'
import {
  getDiscountCodesFromText,
  getDiscountPoolIdsFromText
} from '../../../../utils/rich-text-editor/get-discount-code'
import {
  selectEditorFlow,
  selectFlowItem,
  selectFlowItemMeta,
  selectFlowItemsById,
  selectMessageMeta
} from '../flow-editor.selectors'
import { type FlowEditorState } from '../flow-editor.state'
import { replaceRawValueToHtmlValue } from '../helpers'

export function updateFlowItemDiscountCodes(state: FlowEditorState, flowItem: FlowItemUI) {
  const flowItemMeta = selectFlowItemMeta(flowItem._id)(state)
  const discountCodes = new Set<string>()
  if (hasMessageOnFlowItemType(flowItem)) {
    flowItem.item.messages.forEach((message: MessageUI) => {
      if (hasTextOnMessage(message)) {
        const text = getTextFromMessage(message)

        if (text) {
          const messageDiscountCodes = getDiscountCodesFromText(text)
          messageDiscountCodes.forEach((discountCode) => discountCodes.add(discountCode))
        }
      }
    })
  }
  const flowItemDiscountCodes = Array.from(discountCodes).map((discountCode) => ({
    id: '',
    code: discountCode
  }))

  if (isSMSMessageFlowItemUI(flowItem)) {
    const discountCodes = flowItemDiscountCodes.map((discount) => discount.code)
    flowItem.item.discountCodes = flowItem.item.discountCodes ?? []
    // If new discount codes are added, only that case we need to validate them
    flowItemMeta.discountCodesToValidate = discountCodes.filter(
      (discountCode) => !flowItem.item.discountCodes!.includes(discountCode)
    )
    flowItem.item.discountCodes = discountCodes
    flowItem.item.discounts = flowItemDiscountCodes

    // Only keep errors for discount codes that are still in the flow item
    flowItemMeta.errors = flowItemMeta.errors.filter(
      (error) =>
        error.discountCode === undefined ||
        flowItem.item.discountCodes?.includes(error.discountCode)
    )
  }

  if (isSMSMessageFlowItemUI(flowItem)) {
    flowItem.item.messages.forEach((message, messageIndex) => {
      const messageMeta = selectMessageMeta(flowItem._id, messageIndex)(state)
      // Only keep errors for discount codes that are still in the flow item
      messageMeta.errors = messageMeta.errors.filter(
        (error) =>
          error.discountCode === undefined ||
          flowItem.item.discountCodes?.includes(error.discountCode)
      )

      // WHY IS IT HERE?
      messageMeta.textHTML = replaceRawValueToHtmlValue(state, message.text, flowItem._id)
    })
  }
}

export function updateFlowItemDiscountPools(state: FlowEditorState, flowItem: FlowItemUI) {
  const discountPoolIds = new Set<string>()
  if (hasMessageOnFlowItemType(flowItem)) {
    flowItem.item.messages.forEach((message: MessageUI) => {
      if (hasTextOnMessage(message)) {
        const text = getTextFromMessage(message)
        if (text) {
          const messageDiscountPoolIds = getDiscountPoolIdsFromText(text)
          messageDiscountPoolIds.forEach((poolId) => discountPoolIds.add(poolId))
        }
      }
    })
  }

  const poolIds = Array.from(discountPoolIds)

  if (isSMSMessageFlowItemUI(flowItem)) {
    if (poolIds[0] === undefined) {
      delete flowItem.item.discountCodePoolId
    } else {
      flowItem.item.discountCodePoolId = poolIds[0]
    }
  }
}

export function updateFlowItemsDiscountCode(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('No flow found')
  }

  const flowItems = selectFlowItemsById(state)

  if (!flowItems) {
    throw new Error('No flow items found')
  }

  Object.keys(flowItems).forEach((flowItemId) => {
    const flowItem = selectFlowItem<FlowItemUI>(flowItemId)(state)
    updateFlowItemDiscountCodes(state, flowItem)
    updateFlowItemDiscountPools(state, flowItem)
  })
}
