import { type OptinTool } from '@ghostmonitor/recartapis'
import {
  getOptinToolMainChannel,
  getOptinToolMessagingType
} from '../routes/optin-tools/utils/helpers'
import { hooks } from './hooks'
import { type GeotargetingCountry, useGeotargetingCountries } from './use-geotargeting-countries'

export function useAvailableGeotargetingCountries(optinTool?: OptinTool): {
  availableGeotargetingCountries: GeotargetingCountry[]
  isLoading: boolean
} {
  const isInternationalOptinToolGeotargetingEnabled = hooks.useFeatureFlag(
    'enable-international-optin-tool-geotargeting'
  )
  const channel = getOptinToolMainChannel(optinTool)
  const messagingType = getOptinToolMessagingType(optinTool)
  const { geotargetingCountries, isLoading: isGeotargetingCountriesLoading } =
    useGeotargetingCountries(channel, messagingType)

  if (!optinTool) {
    return {
      availableGeotargetingCountries: [],
      isLoading: isGeotargetingCountriesLoading
    }
  }

  const availableGeotargetingCountries = isInternationalOptinToolGeotargetingEnabled
    ? geotargetingCountries
    : geotargetingCountries.filter((country) => country.canSend)
  return {
    availableGeotargetingCountries,
    isLoading: isGeotargetingCountriesLoading
  }
}
