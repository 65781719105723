import {
  type SearchProductImagesRequest,
  type SearchProductImagesResponse
} from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_NAME } from './query-names'

export function useProductImages(
  queryParams?: Omit<
    SearchProductImagesRequest,
    | 'siteId'
    | ('offset' & {
        offset?: number
      })
  >
) {
  async function fetchProductImages({ pageParam = 0 }): Promise<SearchProductImagesResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.post<SearchProductImagesResponse>('product-images/search', { ...params })
  }

  function getNextPageParam(lastPage, pages) {
    if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
      return undefined
    }

    return pages.flatMap((page) => page.data).length
  }

  const queryName = [QUERY_NAME.productImages, queryParams?.name]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery<SearchProductImagesResponse>(queryName, fetchProductImages, {
      getNextPageParam
    })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
