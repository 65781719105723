import { type FlowAPI } from '@ghostmonitor/recartapis'

export function checkSmsUnsubscribeFlowIntegrity(flow: FlowAPI): void {
  if (flow.isQuietHoursEnabled !== false) {
    throw new Error(`isQuietHoursEnabled should be defined and false for help flows`)
  }

  if (flow.isSmartSendingEnabled !== false) {
    throw new Error(`isSmartSendingEnabled should be defined and false for help flows`)
  }
}
