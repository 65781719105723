import cn from 'classnames'
import styles from './sequence-discount.style.scss'

interface SequenceDiscountProps {
  placeholder: string
  id: string
  isUrl: boolean
  isError?: boolean
}

export function SequenceDiscount(props: SequenceDiscountProps) {
  return (
    <span
      className={cn({
        [styles.sequenceDiscount]: !props.isUrl,
        [styles.sequenceDiscountUrl]: props.isUrl,
        [styles.error]: props.isError
      })}
      contentEditable={false}
      id={props.id}
      data-testid='discount-code'
    >
      {props.placeholder}
    </span>
  )
}
