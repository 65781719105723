import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import { type AppDispatch } from '../../../../../store/create-store'
import { type DashboardState } from '../../../../../store/dashboard.state'
import { duplicateSequenceThunk } from '../../../../../store/modules/sequence/sequence.thunks'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectLaunchDarklyFlag
} from '../../../../../store/selectors'
import { Notification } from '../../../../../utils/notification/notification.util'

export function HeaderInternalDuplicateButton() {
  const dispatch: AppDispatch = useDispatch()
  const sequence = useSelector(selectEditorSequence)
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const isDuplicateSequenceInternalEnabled = useSelector<DashboardState, boolean>(
    selectLaunchDarklyFlag('duplicate-sequence-internal')
  )
  const navigate = useNavigate()

  async function handleDuplicateSequence() {
    const resultAction = await dispatch(duplicateSequenceThunk(sequence._id))
    if (duplicateSequenceThunk.fulfilled.match(resultAction)) {
      navigate(`/sequence-editor/${resultAction.payload}?showWizard=true`)
    }

    if (duplicateSequenceThunk.rejected.match(resultAction)) {
      Notification.error(
        'Something went wrong',
        'Your request cannot be completed right now. Please try again later.'
      )
    }
  }

  if (isDuplicateSequenceInternalEnabled) {
    return (
      <Button onClick={handleDuplicateSequence} disabled={sequenceMeta.unsavedChanges}>
        Create CCF from this sequence
      </Button>
    )
  }

  return null
}
