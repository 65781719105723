import { type FlowAPI, type FlowUI } from '@ghostmonitor/recartapis'
import { produce } from 'immer'
import { convertFlowItemUIToFlowItem } from './convert-flow-item-ui-to-flow-item'

export function convertFlowUIToFlow(flowUI: FlowUI): FlowAPI {
  return produce(flowUI as FlowAPI, (draft) => {
    if (draft.sequenceItems?.length > 0) {
      draft.sequenceItems = flowUI.sequenceItems.map((flowItem) =>
        convertFlowItemUIToFlowItem(flowItem, flowUI)
      )
    }
  })
}
