// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aMenMb8yb3VkmGb76NgG svg{height:100%}.aMenMb8yb3VkmGb76NgG svg path{color:var(--recart-yellow-1);fill:var(--recart-yellow-1)}.aMenMb8yb3VkmGb76NgG svg circle{stroke:var(--recart-yellow-1)}.pzoL5Ol83t0I_dMtIgVg{color:#212b36;font-family:Lato,sans-serif;font-size:17px;margin-bottom:8px}.PVLWeCMxRQfM3gP1Kpef{color:#979ba1;font-size:15px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/create-pool-on-dashboard-modal/create-pool-on-dashboard-modal.scss"],"names":[],"mappings":"AAEI,0BACE,WAAA,CAEA,+BACE,4BAAA,CACA,2BAAA,CAGF,iCACE,6BAAA,CAKN,sBAGE,aAAA,CAFA,2BAAA,CACA,cAAA,CAEA,iBAAA,CAGF,sBAEE,aAAA,CADA,cACA","sourcesContent":[":local {\n  .modalContainer {\n    svg {\n      height: 100%;\n\n      path {\n        color: var(--recart-yellow-1);\n        fill: var(--recart-yellow-1);\n      }\n\n      circle {\n        stroke: var(--recart-yellow-1);\n      }\n    }\n  }\n\n  .modalTitle {\n    font-family: Lato, sans-serif;\n    font-size: 17px;\n    color: #212b36;\n    margin-bottom: 8px;\n  }\n\n  .modalText {\n    font-size: 15px;\n    color: #979ba1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `aMenMb8yb3VkmGb76NgG`,
	"modalTitle": `pzoL5Ol83t0I_dMtIgVg`,
	"modalText": `PVLWeCMxRQfM3gP1Kpef`
};
export default ___CSS_LOADER_EXPORT___;
