import { type MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { type ReorderMessageAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')

export function reorderMessageActionHandler(
  state: SequenceEditorState,
  action: ReorderMessageAction
) {
  if (action.newIndex === action.oldIndex) {
    return
  }

  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const message = sequenceItem.messages[action.oldIndex]
    sequenceItem.messages.splice(action.oldIndex, 1)
    sequenceItem.messages.splice(action.newIndex, 0, message)

    const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)
    const messageMeta = sequenceItemMeta.messages[action.oldIndex]
    sequenceItemMeta.messages.splice(action.oldIndex, 1)
    sequenceItemMeta.messages.splice(action.newIndex, 0, messageMeta)

    const sequence = selectEditorSequence(state)

    const diagramModel = getDiagramModel(sequence.serializedDiagram)
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)

    node.reorderMessage(action.oldIndex, action.newIndex)

    sequence.serializedDiagram = diagramModel.serializeDiagram() as any
  } else {
    info('reorderMessageActionHandler:1', { action, sequenceItem })
  }
}
