import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { type UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo } from 'react'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export interface PhoneNumberConfigWithVerification extends SMSSettings.PhoneNumberConfig {
  verification?: SMSSettings.Verification
}

export function useSMSPhoneNumbers(
  options?: UseQueryOptions<SMSSettingsService.GetPhoneNumbersResponse>
): UseResource<PhoneNumberConfigWithVerification[]> {
  const queryNamePhoneNumbers = useMemo(() => [QUERY_NAME.phoneNumbers], [])
  const queryClient = useQueryClient()

  const {
    isLoading,
    data: phoneNumbersData,
    error: requestError
  } = useQuery<SMSSettingsService.GetPhoneNumbersResponse>(
    queryNamePhoneNumbers,
    api.getSMSPhoneNumbers,
    options
  )
  const error: SerializedError = requestError

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries(queryNamePhoneNumbers),
    [queryClient, queryNamePhoneNumbers]
  )

  const queryNameVerificationData = useMemo(() => [QUERY_NAME.leasablePhoneNumber], [])

  async function getLeasablePhoneNumberVerificationData(
    phoneNumber: SMSSettings.PhoneNumberConfig
  ): Promise<PhoneNumberConfigWithVerification> {
    if (
      phoneNumber.type === 'toll-free-number' &&
      ['completed', 'pending'].includes(phoneNumber.status)
    ) {
      const phoneNumberWithVerification = await api.getLeasablePhoneNumber(phoneNumber.number)
      const verification = phoneNumberWithVerification.data.verification
      return {
        ...phoneNumber,
        verification
      }
    }
    return phoneNumber
  }

  async function getLeasablePhoneNumbersVerificationData(
    phoneNumbers: SMSSettings.PhoneNumberConfig[]
  ) {
    const promises = phoneNumbers.map(getLeasablePhoneNumberVerificationData)
    return Promise.all(promises)
  }

  const {
    isLoading: isVerificationDataLoading,
    data: phoneNumbersWithVerificationData,
    error: verificationDataError,
    refetch: refetchVerificationData
  } = useQuery(
    queryNameVerificationData,
    () => getLeasablePhoneNumbersVerificationData(phoneNumbersData?.data || []),
    {
      enabled: !!phoneNumbersData?.data,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (phoneNumbersData?.data) {
      refetchVerificationData()
    }
  }, [phoneNumbersData?.data, refetchVerificationData])

  return {
    invalidateCache,
    data: phoneNumbersWithVerificationData,
    isLoading: isLoading || isVerificationDataLoading,
    error: error || verificationDataError,
    refetchVerificationData
  }
}
