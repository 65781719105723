import {
  type OptinToolStatisticsById,
  type OptinToolStatisticsResponse
} from '@ghostmonitor/recartapis'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

function getOptinToolStatistics(queryParams) {
  return request.get<OptinToolStatisticsResponse>('statistics/optin-tools', {
    params: queryParams
  })
}

export function useOptinToolsStat(): UseResource<OptinToolStatisticsById> & {
  prefetch: () => void
} {
  const queryClient = useQueryClient()
  const queryName = [QUERY_NAME.optinToolsStat]

  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
  } = {}

  if (dateFrom) {
    const startDate = dateFrom.toISOString()
    queryParams.startDate = startDate
    queryName.push(startDate)
  }

  if (dateTo) {
    const endDate = dateTo.toISOString()
    queryParams.endDate = endDate
    queryName.push(endDate)
  }

  const { isInitialLoading, data } = useQuery<OptinToolStatisticsResponse>(
    queryName,
    () => getOptinToolStatistics(queryParams),
    {
      enabled: dateFrom !== undefined && dateTo !== undefined
    }
  )

  function prefetchOptinToolStats() {
    queryClient.prefetchQuery({
      queryKey: queryName,
      queryFn: () => getOptinToolStatistics(queryParams)
    })
  }

  return {
    data: data?.data,
    isLoading: isInitialLoading,
    prefetch: prefetchOptinToolStats
  }
}
