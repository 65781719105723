import { type SMSSettings } from '@ghostmonitor/recartapis'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export interface PhoneNumberConfigWithVerification extends SMSSettings.PhoneNumberConfig {
  verification?: SMSSettings.Verification
}

export function useSMSPhoneNumber(
  number: string
): UseResource<PhoneNumberConfigWithVerification[]> {
  const { data: phoneNumbers, isLoading: isPhoneNumbersLoading } = hooks.useSMSPhoneNumbers()
  const phoneNumber = phoneNumbers?.filter((phoneNumber) => phoneNumber.number === number)
  return {
    data: phoneNumber,
    isLoading: isPhoneNumbersLoading || phoneNumber === undefined
  }
}
