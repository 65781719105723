import {
  type DelayFlowItemUI,
  DelayType,
  DelayUnit,
  type FlowUI,
  getEarliestDelayScheduledDate
} from '@ghostmonitor/recartapis'
import { convertFlowItemUIToFlowItem } from '../../../types/flow-editor/convert-flow-item-ui-to-flow-item'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'
import { convertUnitToNanosec } from '../../time-conversion'

const SMS_MAX_DELAY_DAYS = 365
const SMS_MAX_DELAY_NANO_SEC = convertUnitToNanosec(SMS_MAX_DELAY_DAYS, DelayUnit.DAYS)

export function validateDelay(delayValue: number): FlowEditorValidationError | undefined {
  if (delayValue && SMS_MAX_DELAY_NANO_SEC < delayValue) {
    return {
      message: FlowEditorError.DelayValueExceedMaximum,
      level: FlowEditorErrorLevel.Error
    }
  }

  return undefined
}

export function validateScheduledDelay(
  flowItem: DelayFlowItemUI,
  flow: FlowUI,
  scheduledFor: string | undefined | null
): FlowEditorValidationError | undefined {
  if (flowItem.item.logic.delay.type !== DelayType.delayScheduled) {
    return
  }

  if (flowItem.item.logic.delay.scheduled_date === undefined) {
    return {
      message: FlowEditorError.ScheduledDelayDateIsUndefined,
      level: FlowEditorErrorLevel.Error
    }
  }

  const smsCampaignScheduledFor = new Date(scheduledFor)

  const flowItemsAsAPIType = flow.sequenceItems.map((flowItem) =>
    convertFlowItemUIToFlowItem(flowItem, flow)
  )

  const precedingDelayItemDate = getEarliestDelayScheduledDate(
    flowItem,
    flowItemsAsAPIType,
    smsCampaignScheduledFor
  )

  const delayDateToValidate = new Date(flowItem.item.logic.delay.scheduled_date)
  const now = new Date(Date.now())

  const isInThePast = delayDateToValidate < now
  if (isInThePast) {
    return {
      message: FlowEditorError.ScheduledDelayDateIsInThePast,
      level: FlowEditorErrorLevel.Error
    }
  }

  if (delayDateToValidate < smsCampaignScheduledFor) {
    return {
      message: FlowEditorError.ScheduledDelayDateBeforeCampaignStartingDate,
      level: FlowEditorErrorLevel.Error
    }
  }

  if (delayDateToValidate < precedingDelayItemDate) {
    return {
      message: FlowEditorError.ScheduledDelayDateBeforePrecedingDelayScheduledDate,
      level: FlowEditorErrorLevel.Error
    }
  }
}

export function validateDelayFlowItemMustHaveConnection(
  flowItem: DelayFlowItemUI
): FlowEditorValidationError | undefined {
  if (flowItem.item.logic.delay.trigger === '') {
    return {
      message: FlowEditorError.DelayFlowItemMustHaveConnection,
      level: FlowEditorErrorLevel.Error
    }
  }

  return undefined
}
