import { type BlastScheduleType, type SMSCampaignScheduleType } from '@ghostmonitor/recartapis'
import moment, { type Moment } from 'moment'
import * as momentTimeZone from 'moment-timezone'

export function loadDelayScheduledTo(
  delayScheduledTo: string | null | undefined,
  scheduleType: SMSCampaignScheduleType | BlastScheduleType | undefined,
  timezone: string
): Moment {
  if (!delayScheduledTo) {
    return momentTimeZone.tz(new Date(), timezone)
  }

  let delayScheduledToMoment = moment.tz(delayScheduledTo, timezone)

  if (scheduleType === 'subscriberTimezone') {
    delayScheduledToMoment = delayScheduledToMoment
      .utc()
      .subtract(moment.tz(timezone).utcOffset(), 'minutes')
      .tz(timezone)
  }

  return delayScheduledToMoment
}

export function saveDelayScheduledTo(
  delayScheduledTo: string,
  scheduleType: SMSCampaignScheduleType | BlastScheduleType,
  timezone?: string
): Moment {
  if (!timezone) {
    timezone = moment.tz.guess()
  }

  if (!delayScheduledTo) {
    return momentTimeZone.tz(new Date(), timezone)
  }

  let delayScheduledToMoment = moment.tz(delayScheduledTo, timezone)

  if (scheduleType === 'subscriberTimezone') {
    delayScheduledToMoment = delayScheduledToMoment
      .utc()
      .add(moment.tz(timezone).utcOffset(), 'minutes')
  }

  return delayScheduledToMoment
}
