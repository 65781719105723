import { type FlowItemUI, SequenceTag } from '@ghostmonitor/recartapis'
import { selectEditorFlow, selectFlowItem, selectFlowItemsById } from '../flow-editor.selectors'
import { type FlowEditorState } from '../flow-editor.state'

export function addAITags(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('No flow found')
  }

  const flowItems = selectFlowItemsById(state)

  if (!flowItems) {
    throw new Error('No flow items found')
  }

  flow.tags = Array.from(
    new Set([...flow.tags, ...[SequenceTag.AI_TEXT_GENERATED, SequenceTag.AI]])
  )

  Object.keys(flowItems).forEach((flowItemId) => {
    const flowItem = selectFlowItem<FlowItemUI>(flowItemId)(state)
    flowItem.tags = Array.from(
      new Set([...flowItem.tags, ...[SequenceTag.AI_TEXT_GENERATED, SequenceTag.AI]])
    )
  })
}
