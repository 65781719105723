import { type FlowAPI, type RandomSplitFlowItemAPI } from '@ghostmonitor/recartapis'

export function checkRandomSplitFlowItemIntegrity(flow: FlowAPI, flowItem: RandomSplitFlowItemAPI) {
  const edges = flow.flowEditorDiagram.edges
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)

  if (!node) {
    throw new Error(`Cannot find node with flowItemId ${flowItem._id}`)
  }

  if (flowItem.item.logic.split.variants.length !== node.data.logicHandles.length) {
    throw new Error(
      `Length of random split variants and logicHandles should be equal. Variants: ${flowItem.item.logic.split.variants.length}, logicHandles: ${node.data.logicHandles.length}`
    )
  }

  for (let i = 0; i < flowItem.item.logic.split.variants.length; i++) {
    const variant = flowItem.item.logic.split.variants[i]
    const logicHandle = node.data.logicHandles[i]
    const outgoingEdge = edges.find((edge) => edge.sourceHandle === logicHandle.id)

    /**************************************
     * Connected from trigger perspective
     *************************************/
    if (variant.trigger !== null) {
      if (outgoingEdge === undefined) {
        throw new Error('There is variant with trigger, but there is no outgoing edge')
      }

      const targetNode = flow.flowEditorDiagram.nodes.find(
        (node) => node.data.flowItemId === variant.trigger
      )

      if (targetNode === undefined) {
        throw new Error(`Cannot find target node for variant ${i}`)
      }

      if (targetNode.id !== outgoingEdge.target) {
        throw new Error(`Target node id does not match for variant ${i}`)
      }
    }

    /*****************************************
     * Connected from connection perspective
     ****************************************/
    if (outgoingEdge !== undefined) {
      if (variant.trigger === null) {
        throw new Error('There is outgoing edge, but there is no variant with trigger')
      }

      const targetNode = flow.flowEditorDiagram.nodes.find(
        (node) => node.id === outgoingEdge.target
      )

      if (targetNode === undefined) {
        throw new Error(`Cannot find target node for variant ${i}`)
      }

      if (targetNode.id !== outgoingEdge.target) {
        throw new Error(`Target node id does not match for variant ${i}`)
      }
    }
  }
}
