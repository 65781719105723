import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
import { type SiteAPI } from '@ghostmonitor/recartapis'

// eslint-disable-next-line @typescript-eslint/max-params
export function useEstimatedCost(
  campaignId: string,
  smsSegmentCount: number,
  mmsSegmentCount: number,
  mmsToSmsSegmentCount: number,
  includeSegmentIds: string[],
  excludeSegmentIds: string[],
  variation: 'min' | 'max',
  isEnabled: boolean
): UseResource<SiteAPI.SMSCampaignEstimatedCost> {
  const params = {
    smsSegmentCount,
    mmsSegmentCount,
    mmsToSmsSegmentCount,
    includeSegmentIds,
    excludeSegmentIds
  }
  const queryName = [QUERY_NAME.estimatedCost, ...Object.keys(params), variation]
  const isQueryComplete = Object.values(params).every((param) => param !== undefined)

  const {
    isInitialLoading,
    isFetching,
    data: esitematedCost
  } = useQuery<SiteAPI.GetSMSCampaignEstimatedCostResponse>(
    queryName,
    async () => {
      return request.get<SiteAPI.GetSMSCampaignEstimatedCostResponse>(
        `/sms-campaigns/${campaignId}/estimated-cost`,
        { params }
      )
    },
    {
      enabled: isEnabled && isQueryComplete
    }
  )

  return {
    data: esitematedCost?.data,
    isLoading: isInitialLoading || isFetching
  }
}
