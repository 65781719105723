import { type FlowAPI } from '@ghostmonitor/recartapis'

export function checkCustomTriggerFlowIntegrity(flow: FlowAPI): void {
  if (flow.sendingFrequency === undefined) {
    throw new Error('Trigger is Custom Trigger, but sendingFrequency is undefined')
  }

  if (flow.sendingFrequency.type !== 'lockAfterMessageCount') {
    throw new Error(
      'Trigger is Custom Trigger, but sendingFrequency.type is not lockAfterMessageCount'
    )
  }

  if (![0, 1].includes(flow.sendingFrequency.numberOfSentMessages)) {
    throw new Error(
      'Trigger is Custom Trigger, but sendingFrequency.numberOfSentMessages is not 0 or 1'
    )
  }
  if (flow.isQuietHoursEnabled === undefined) {
    throw new Error('isQuietHoursEnabled should be defined for custom trigger flows')
  }
  if (flow.isSmartSendingEnabled !== false) {
    throw new Error('isSmartSendingEnabled should be defined and false for custom trigger flows')
  }
}
