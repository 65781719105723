import { type QuickReply } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'

export const quickReplyValidationErrors = {
  EMPTY_TITLE: 'QuickReply cannot be empty'
}

export function quickReplyValidator(quickReply: QuickReply): SerializedError[] {
  const errors = []

  if (!quickReply) {
    return []
  }

  if (quickReply.title === '') {
    errors.push(new Error(quickReplyValidationErrors.EMPTY_TITLE))
  }

  return errors
}
