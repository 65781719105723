import {
  type Channel,
  type DelaySequenceItemUI,
  DelayUnit,
  type DisplayMeta,
  LogicType,
  NodeType,
  PortType,
  SequenceItemType
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import merge from 'lodash/merge'
import { type DiagramEngine } from 'storm-react-diagrams'
import { LogicPortModel } from '../../port/logic-port-model'
import { BaseSequenceItemNodeModel } from '../base-sequence-item-model'
import { LOGIC_PORT_DELAY_TIMEOUT_REACHED } from '../port-names'

export function getNewDelaySequenceItem(siteId: string, channel: Channel): DelaySequenceItemUI {
  const sequenceItemId = new ObjectId().toHexString()
  const now = new Date().toISOString()

  return {
    _id: sequenceItemId,
    type: SequenceItemType.LOGIC,
    siteId,
    trigger: [sequenceItemId],
    createdAt: now,
    updatedAt: now,
    canConvert: false,
    channel,
    tags: [],
    logic: {
      type: LogicType.DELAY,
      delay: {
        delayDuration: 3600000000000,
        trigger: ''
      }
    }
  }
}

export class DelaySequenceItemNodeModel extends BaseSequenceItemNodeModel {
  public displayMeta: DisplayMeta
  constructor(sequenceItemId?: string, displayMeta?: DisplayMeta, id?: string) {
    super(NodeType.DELAY_SEQUENCE_ITEM, sequenceItemId, id)
    this.displayMeta = displayMeta ?? { delayUnit: DelayUnit.HOURS }
    this.generatePorts()
  }

  public serialize() {
    return merge(super.serialize(), {
      displayMeta: this.displayMeta
    })
  }

  public deSerialize(serializedDiagram, engine: DiagramEngine) {
    super.deSerialize(serializedDiagram, engine)
    this.displayMeta = serializedDiagram.displayMeta
  }

  public getDisplayMeta() {
    return this.displayMeta
  }

  public setDisplayMeta(newDisplayMeta: DisplayMeta) {
    this.displayMeta = newDisplayMeta
  }

  private generatePorts() {
    this.addPort(new LogicPortModel(LOGIC_PORT_DELAY_TIMEOUT_REACHED))
  }

  public getOutputPort(): LogicPortModel {
    const ports = this.getPortsByType(PortType.LOGIC)
    return ports[0] as LogicPortModel
  }
}
