import { type FacebookPage } from '@ghostmonitor/recartapis'
import { createSelector } from '@reduxjs/toolkit'
import { type FacebookPageMeta, type FacebookState } from './facebook.reducer'

export const selectFacebookPageConfig = (state: FacebookState) => state.fbMessengerConfig
export const selectFacebookPageConfigMeta = (state: FacebookState) => state.fbMessengerConfigMeta

export const selectFacebookPageId = (state: FacebookState) => state.fbMessengerConfig?.pageId
export const selectFacebookPageUserId = (state: FacebookState) => state.fbMessengerConfig?.userId

export const selectIsFacebookPageConnected = createSelector(
  selectFacebookPageConfig,
  (facebookMessengerConfig): boolean => {
    return facebookMessengerConfig === null
      ? false
      : facebookMessengerConfig.isEnabled && facebookMessengerConfig.isTokenValid
  }
)

export const selectFacebookPages = (state: FacebookState): FacebookPage.Page[] =>
  state.facebookPages
export const selectFacebookPagesMeta = (state: FacebookState): FacebookPageMeta =>
  state.facebookPagesMeta

export const selectFacebookUserId = (state: FacebookState) => state.messenger.userId

export const selectAuthorizationToken = (state: FacebookState) => state.authorizationToken
