import { type Blast } from '@ghostmonitor/recartapis'
import { type Action, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import type { AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { selectEditorBlast, selectEditorBlastMeta, selectSite } from '../../../../store/selectors'
import {
  type BlastScheduledChangedAction,
  blastValidationError,
  blastValidationSuccess,
  BLAST_SCHEDULED_CHANGED,
  type RenameBlastAction,
  RENAME_BLAST,
  type ScheduleBlastForLaterAction,
  SCHEDULE_BLAST_FOR_LATER,
  SCHEDULE_BLAST_FOR_NOW
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { loadBlastThunk } from '../../../../store/slices/sequence-editor/thunks/load-blast.thunk'
import { blastNameValidator, blastScheduledForValidator } from '../../validators/blast.validator'
import { selectTimeZone } from '../../../../store/slices/site/site.selectors'

const DEBOUNCE_DELAY = 400

function getValidateBlastName(dispatch: AppDispatch) {
  return debounce(
    (blast: Blast) => {
      const blastNameError = blastNameValidator(blast)

      if (blastNameError) {
        dispatch(blastValidationError(blastNameError, { key: 'name' }))
      } else {
        dispatch(blastValidationSuccess(null, { key: 'name' }))
      }
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

function getValidateBlastScheduledFor(dispatch: AppDispatch) {
  return debounce(
    (blast: Blast, timezone: string) => {
      const blastScheduledForError = blastScheduledForValidator(blast, timezone)

      if (blastScheduledForError) {
        dispatch(blastValidationError(blastScheduledForError, { key: 'scheduledFor' }))
      } else {
        dispatch(blastValidationSuccess(null, { key: 'scheduledFor' }))
      }
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

type ValidableBlastAction =
  | RenameBlastAction
  | ScheduleBlastForLaterAction
  | BlastScheduledChangedAction

function isValidableAction(action: Action): action is ValidableBlastAction {
  return [
    loadBlastThunk.fulfilled.type,
    RENAME_BLAST,
    SCHEDULE_BLAST_FOR_NOW,
    SCHEDULE_BLAST_FOR_LATER,
    BLAST_SCHEDULED_CHANGED
  ].includes(action.type)
}

export function validateBlastMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateBlastName = getValidateBlastName(dispatch)
  const validateBlastScheduledFor = getValidateBlastScheduledFor(dispatch)

  return (next) => (action: ValidatableAction) => {
    next(action)
    const state = store.getState()
    const blast = selectEditorBlast(state)
    const blastMeta = selectEditorBlastMeta(state)
    const timezone = selectTimeZone(selectSite(state))

    if (isValidableAction(action)) {
      if (blastMeta.name.edited) {
        validateBlastName(blast)
      }
      validateBlastScheduledFor(blast, timezone)
    }
  }
}
