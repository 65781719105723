import { type Device, type OptinToolStep } from '@ghostmonitor/recartapis'
import { CustomerChatMenuItemSlug } from '../../../../routes/optin-tools/customer-chat/editor/utils/menu-items'
import { EmbeddedFormMenuItemSlug } from '../../../../routes/optin-tools/embedded-form/editor/menu-items'
import {
  LandingPageMenuItemSlug,
  getMenuSlugForDevice
} from '../../../../routes/optin-tools/landing-page/editor/menu-items'
import { isOneClickOptinTool } from '../../../../routes/optin-tools/utils/helpers'
import { type OptinToolMetaErrors } from '../optin-tool-editor.reducer'

export type ChannelStep = Extract<
  OptinToolStep,
  'email' | 'sms' | 'smsWithEmail' | 'messenger' | 'oneClickOptin'
>

export function getMainChannelStep(steps: OptinToolStep[]): ChannelStep {
  if (steps.includes('sms')) {
    return 'sms'
  }

  if (steps.includes('smsWithEmail')) {
    return 'smsWithEmail'
  }

  if (steps.includes('messenger')) {
    return 'messenger'
  }

  if (isOneClickOptinTool(steps)) {
    return 'oneClickOptin'
  }

  // this should be only in case of email only
  return 'email'
}

export function initPopupErrorMeta(steps: OptinToolStep[]): OptinToolMetaErrors {
  const mainStep = getMainChannelStep(steps)

  const errorMeta: OptinToolMetaErrors = {
    settings: {
      displayRules: {
        url: { errorMessage: '' },
        location: { errorMessage: '' },
        abTesting: {
          errorMessage: ''
        }
      },
      welcomeFlow: {
        sequenceId: { errorMessage: '' }
      }
    },
    layout: {
      additionalImage: {
        additionalImage: { errorMessage: '' }
      }
    },
    [mainStep]: {
      form: {
        items: []
      }
    }
  }

  if (steps.includes('email')) {
    errorMeta.email = {
      form: {
        items: []
      }
    }
  }

  return errorMeta
}

function getLandingPageSMSErrorMeta(device: Device, steps: OptinToolStep[]): OptinToolMetaErrors {
  if (steps.includes('messenger')) {
    return
  }

  const smsMenuItemSlug = steps.includes('smsWithEmail')
    ? LandingPageMenuItemSlug.SMS_WITH_EMAIL
    : LandingPageMenuItemSlug.SMS

  const errorMeta: OptinToolMetaErrors = {
    [getMenuSlugForDevice(device, smsMenuItemSlug)]: {
      form: {
        items: []
      }
    },
    [getMenuSlugForDevice(device, LandingPageMenuItemSlug.SMS_SUCCESS)]: {
      button: {
        link: { errorMessage: '' }
      }
    }
  }

  if (steps.includes('email')) {
    errorMeta[getMenuSlugForDevice(device, LandingPageMenuItemSlug.EMAIL)] = {
      form: {
        items: []
      }
    }
  }

  return errorMeta
}

export function initLandingPageErrorMeta(steps: OptinToolStep[]): OptinToolMetaErrors {
  return {
    [LandingPageMenuItemSlug.SETTINGS]: {
      displayRules: {
        pageTitle: { errorMessage: '' },
        link: { errorMessage: '' },
        sequenceId: { errorMessage: '' },
        location: { errorMessage: '' }
      }
    },
    [getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.LAYOUT)]: {
      additionalImage: {
        additionalImage: { errorMessage: '' }
      }
    },
    ...getLandingPageSMSErrorMeta('desktop', steps),
    [getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.GEOTARGETING_ERROR)]: {
      button: {
        link: { errorMessage: '' }
      }
    },
    [getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.LAYOUT)]: {
      additionalImage: {
        additionalImage: { errorMessage: '' }
      }
    },
    ...getLandingPageSMSErrorMeta('mobile', steps),
    [getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.GEOTARGETING_ERROR)]: {
      button: {
        link: { errorMessage: '' }
      }
    }
  }
}

function getEmbeddedFormSMSErrorMeta(device: Device, steps: OptinToolStep[]): OptinToolMetaErrors {
  if (steps.includes('messenger')) {
    return
  }

  const smsMenuItemSlug = steps.includes('smsWithEmail')
    ? LandingPageMenuItemSlug.SMS_WITH_EMAIL
    : LandingPageMenuItemSlug.SMS

  const errorMeta: OptinToolMetaErrors = {
    [getMenuSlugForDevice(device, smsMenuItemSlug)]: {
      form: {
        items: []
      }
    },
    [getMenuSlugForDevice(device, LandingPageMenuItemSlug.SMS_SUCCESS)]: {
      button: {
        link: { errorMessage: '' }
      }
    }
  }

  if (steps.includes('email')) {
    errorMeta[getMenuSlugForDevice(device, LandingPageMenuItemSlug.EMAIL)] = {
      form: {
        items: []
      }
    }
  }

  return errorMeta
}

export function initEmbeddedFormErrorMeta(steps: OptinToolStep[]): OptinToolMetaErrors {
  return {
    [EmbeddedFormMenuItemSlug.SETTINGS]: {
      displayRules: {
        pageTitle: { errorMessage: '' },
        link: { errorMessage: '' },
        sequenceId: { errorMessage: '' },
        location: { errorMessage: '' }
      }
    },
    [getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.LAYOUT)]: {
      additionalImage: {
        additionalImage: { errorMessage: '' }
      }
    },
    ...getEmbeddedFormSMSErrorMeta('desktop', steps),
    [getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.GEOTARGETING_ERROR)]: {
      button: {
        link: { errorMessage: '' }
      }
    },
    [getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.LAYOUT)]: {
      additionalImage: {
        additionalImage: { errorMessage: '' }
      }
    },
    ...getEmbeddedFormSMSErrorMeta('mobile', steps),
    [getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.GEOTARGETING_ERROR)]: {
      button: {
        link: { errorMessage: '' }
      }
    }
  }
}

export function initCustomerChatErrorMeta(): OptinToolMetaErrors {
  return {
    [CustomerChatMenuItemSlug.GENERAL]: {
      welcomeFlow: {
        sequenceId: { errorMessage: '' }
      }
    },
    [CustomerChatMenuItemSlug.LAYOUT_BEHAVIOR]: {
      greeting: {
        greetingMessage: { errorMessage: '' }
      }
    }
  }
}
