import { type ConditionalSplitFlowItemAPI, type FlowAPI } from '@ghostmonitor/recartapis'

export function checkConditionalSplitFlowItemIntegrity(
  flow: FlowAPI,
  flowItem: ConditionalSplitFlowItemAPI
) {
  const edges = flow.flowEditorDiagram.edges
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)

  if (!node) {
    throw new Error(`Cannot find node with flowItemId ${flowItem._id}`)
  }

  // There is always one logic handle for the default variant (False)
  if (flowItem.item.logic.conditional_split.variants.length !== node.data.logicHandles.length - 1) {
    throw new Error(
      `Length of conditional split variants and logicHandles should be equal. Variants: ${flowItem.item.logic.conditional_split.variants.length}, logicHandles: ${node.data.logicHandles.length}`
    )
  }

  if (node.data.logicHandles.length !== 2) {
    throw new Error('Conditional split node should have 2 logic handles')
  }

  if (flowItem.item.logic.conditional_split.variants.length !== 1) {
    throw new Error('There should be exactly one conditional split variant')
  }

  const conditionMetVariant = flowItem.item.logic.conditional_split.variants[0]
  const conditionMetlogicHandle = node.data.logicHandles[0]
  const conditionMetOutgoingEdge = edges.find(
    (edge) => edge.sourceHandle === conditionMetlogicHandle.id
  )

  /**************************************
   * Connected from trigger perspective
   *************************************/
  if (conditionMetVariant.trigger !== undefined) {
    if (conditionMetOutgoingEdge === undefined) {
      throw new Error('There is variant with trigger, but there is no outgoing edge')
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.data.flowItemId === conditionMetVariant.trigger
    )

    if (targetNode === undefined) {
      throw new Error(`Cannot find target node for variant`)
    }

    if (targetNode.id !== conditionMetOutgoingEdge.target) {
      throw new Error(`Target node id does not match for variant`)
    }
  }

  /*****************************************
   * Connected from connection perspective
   ****************************************/
  if (conditionMetOutgoingEdge !== undefined) {
    if (conditionMetVariant.trigger === undefined) {
      throw new Error('There is outgoing edge, but there is no variant with trigger')
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.id === conditionMetOutgoingEdge.target
    )

    if (targetNode === undefined) {
      throw new Error(`Cannot find target node for variant`)
    }

    if (targetNode.id !== conditionMetOutgoingEdge.target) {
      throw new Error(`Target node id does not match for variant`)
    }
  }

  const conditionUnmetVariantTrigger = flowItem.item.logic.conditional_split.default_trigger
  const conditionUnmetLogicHandle = node.data.logicHandles[1]
  const conditionUnmetOutgoingEdge = edges.find(
    (edge) => edge.sourceHandle === conditionUnmetLogicHandle.id
  )

  /**************************************
   * Connected from trigger perspective
   *************************************/
  if (conditionUnmetVariantTrigger !== undefined) {
    if (conditionUnmetOutgoingEdge === undefined) {
      throw new Error('There is variant with trigger, but there is no outgoing edge')
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.data.flowItemId === conditionUnmetVariantTrigger
    )

    if (targetNode === undefined) {
      throw new Error(`Cannot find target node for variant`)
    }

    if (targetNode.id !== conditionUnmetOutgoingEdge.target) {
      throw new Error(`Target node id does not match for variant`)
    }
  }

  /*****************************************
   * Connected from connection perspective
   ****************************************/
  if (conditionUnmetOutgoingEdge !== undefined) {
    if (conditionUnmetVariantTrigger === undefined) {
      throw new Error('There is outgoing edge, but there is no variant with trigger')
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.id === conditionUnmetOutgoingEdge.target
    )

    if (targetNode === undefined) {
      throw new Error(`Cannot find target node for variant}`)
    }

    if (targetNode.id !== conditionUnmetOutgoingEdge.target) {
      throw new Error(`Target node id does not match for variant`)
    }
  }
}
