import { useSelector } from 'react-redux'
import { flowEditorSelectors } from '../store/selectors'

export type SegmentSelectorType = 'include' | 'exclude'

export interface SelectedSegment {
  id: string
  type: SegmentSelectorType
}

export function useCampaignTargetAudience() {
  const smsCampaign = useSelector(flowEditorSelectors.selectEditorSMSCampaign)
  const campaignIncludeSegments =
    smsCampaign?.targetRules?.includeSegmentIds?.map<SelectedSegment>((segmentId) => {
      return {
        id: segmentId,
        type: 'include'
      }
    }) ?? []

  const campaignExcludeSegments =
    smsCampaign?.targetRules?.excludeSegmentIds?.map<SelectedSegment>((segmentId) => {
      return {
        id: segmentId,
        type: 'exclude'
      }
    }) ?? []

  return {
    campaignIncludeSegments,
    campaignExcludeSegments
  }
}
