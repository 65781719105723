import { type SequenceEditorSettingsContextType } from './settings/context/sequence-editor-settings.context.type'

export const MESSENGER_MESSAGE_MAX_LENGTH = 640
export const SMS_MESSAGE_MAX_LENGTH = 1280
export const MMS_MESSAGE_MAX_LENGTH = 1000
export const QUICK_REPLY_MAX_LENGTH = 20
export const SMS_MAX_DELAY_DAYS = 365

export const sequenceEditorGlobalSettings: Partial<
  Record<keyof SequenceEditorSettingsContextType, any>
> = {}
