import { type HttpResponse } from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { type FbMessengerConfig } from '../../types/facebook/facebook-messenger-config.type'
import { api } from '../../utils/api'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
const logger = createScope('dashboard')

async function remove() {
  await api.removeFacebookPage()
}

export function useFacebookPage(): UseResource<FbMessengerConfig | null> {
  const queryName = useMemo(() => [QUERY_NAME.facebookPage], [])
  const { data, isLoading, isError } = useQuery(queryName, api.getFacebookPage)
  const queryClient = useQueryClient()

  const handleRemove = useCallback(() => {
    queryClient.cancelQueries(queryName)
    const dataQuery = queryClient.getQueryCache().find(queryName)!
    const previousData = cloneDeep(dataQuery.state.data as HttpResponse<FbMessengerConfig>)
    queryClient.setQueryData(queryName, null)
    return () => queryClient.setQueryData(queryName, previousData)
  }, [queryClient, queryName])

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
    queryClient.invalidateQueries([QUERY_NAME.me])
  }, [queryClient, queryName])

  const handleError = useCallback((err, _, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Disconnecting facebook page failed. Please try again or contact support.')
  }, [])

  const { mutateAsync: removeFacebookPage } = useMutation<void, unknown, unknown>(remove, {
    onMutate: handleRemove,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: data?.data,
    isLoading,
    isError,
    removeFromList: removeFacebookPage
  }
}
