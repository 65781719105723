import styles from './discount-added.component.scss'
import cn from 'classnames'
import { DiscountTypeLabels } from './types/discount-type-labels'

export interface DiscountErrorProps {
  code: string
  type?: string
}

export function DiscountError(props: DiscountErrorProps) {
  return (
    <div className={cn(styles.codeContainer, 'flex flex-col items-center')}>
      <span className={cn(styles.codeBox, styles.codeBoxError)}>{props.code}</span>
      {props.type && <div className={styles.codeType}>{DiscountTypeLabels[props.type]}</div>}
      <div className={styles.info}>
        The selected discount code is unavailable. Please select an existing discount code or create
        a new one.
      </div>
    </div>
  )
}
