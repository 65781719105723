import { QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { CoreLayout } from '../../layouts/core-layout/core-layout.component'
import { history } from '../../routing-utilities/history/history'
import { store } from '../../store/create-store'
import { NotificationContainer } from '../../utils/notification/notification.component'
import { FullPageNotification } from '../../utils/notification/notification.full-page.component'
import { queryClient } from './query-client'

export function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <NotificationContainer />
        <div style={{ height: '100%' }}>
          <Router history={history}>
            <CompatRouter>
              <FullPageNotification>
                {/* eslint-disable-next-line react/no-children-prop */}
                <Route path='/' children={<CoreLayout />} />
              </FullPageNotification>
            </CompatRouter>
          </Router>
        </div>
      </QueryClientProvider>
    </Provider>
  )
}
