import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { FacebookPostIdBar } from './facebook-comments-post-id-bar.component'

export function NotificationBar() {
  const editorSettings = useSequenceEditorSettings()

  if (editorSettings.isFacebookComment) {
    return <FacebookPostIdBar />
  }

  return null
}
