import { type LogicRandomSplitVariant } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'

export const splitValidationErrors = {
  INVALID_INPUT: 'Invalid input',
  MISSING_TRIGGER: 'Missing connection'
}

export function splitValidator(split: LogicRandomSplitVariant): SerializedError[] {
  const errors: SerializedError[] = []

  if (!split) {
    return []
  }

  if (split.percentage <= 0 || split.percentage >= 100) {
    errors.push(new Error(splitValidationErrors.INVALID_INPUT))
  }

  if (!split.trigger) {
    errors.push(new Error(splitValidationErrors.MISSING_TRIGGER))
  }

  return errors
}
