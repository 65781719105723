import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
import { useCallback, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Notification } from '../../utils/notification/notification.util'

export function useCallForwarding(): UseResource<
  SMSSettings.CallForwarding,
  Partial<SMSSettings.CallForwarding>
> {
  const queryName = useMemo(() => [QUERY_NAME.callForwarding], [])
  const queryClient = useQueryClient()

  const {
    isLoading,
    data: callForwarding,
    isError
  } = useQuery<SMSSettingsService.GetCallForwardingResponse>(queryName, async () => {
    return request.get<SMSSettingsService.GetCallForwardingResponse>(
      '/sms-settings/call-forwarding'
    )
  })

  async function patch(payload: Partial<SMSSettings.CallForwarding>) {
    return await request.patch<SMSSettingsService.UpdateCallForwardingResponse>(
      'sms-settings/call-forwarding',
      payload
    )
  }

  const handlePatchMutate = useCallback(
    async (patchPayload: SMSSettingsService.UpdateCallForwardingRequest) => {
      await queryClient.cancelQueries(queryName)

      const callForwardingQuery = queryClient
        .getQueryCache()
        .find<SMSSettings.CallForwarding>(queryName)
      const previousCallForwardingData = cloneDeep(callForwardingQuery.state.data)
      const updatedData = {
        ...previousCallForwardingData,
        ...{ data: patchPayload }
      }
      queryClient.setQueryData(queryName, updatedData)

      return () => {
        queryClient.setQueryData(queryName, { data: previousCallForwardingData })
      }
    },
    [queryClient, queryName]
  )

  const handleSettled = useCallback(async () => {
    await queryClient.invalidateQueries(queryName)
    await queryClient.invalidateQueries([QUERY_NAME.smsSettings])
  }, [queryClient, queryName])

  const handlePatchError = useCallback((err, _updatedCallForwarding, rollback: () => void) => {
    console.error(err)
    rollback()
    Notification.error(
      'Updating call forwarding settings failed. Please try again or contact support.'
    )
  }, [])

  const { mutateAsync: updateCallForwarding } = useMutation<
    SMSSettingsService.UpdateCallForwardingResponse,
    unknown,
    Partial<SMSSettings.CallForwarding>
  >(patch, {
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryName, data)
    }
  })

  return {
    data: callForwarding?.data,
    isLoading,
    isError,
    patch: updateCallForwarding
  }
}
