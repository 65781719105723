import { type FlowItemUI, getIsQuietHoursEnabledByFlowItemId } from '@ghostmonitor/recartapis'
import { selectEditorFlow, selectFlowItem, selectFlowItemsById } from '../flow-editor.selectors'
import { type FlowEditorState } from '../flow-editor.state'
import { convertFlowItemUIToFlowItem } from '../../../../types/flow-editor/convert-flow-item-ui-to-flow-item'

export function updateFlowItemsIsQuietHoursEnabled(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('No flow found')
  }

  const flowItems = selectFlowItemsById(state)

  if (!flowItems) {
    throw new Error('No flow items found')
  }

  const flowItemsAsAPIType = flow.sequenceItems.map((flowItem) =>
    convertFlowItemUIToFlowItem(flowItem, flow)
  )

  if (!flowItemsAsAPIType) {
    throw new Error('flowItemsAsAPIType conversion failed')
  }

  if (flow.entrySequenceItemId === undefined) {
    return
  }

  const isQuietHoursEnabledByFlowItemId = getIsQuietHoursEnabledByFlowItemId(flowItemsAsAPIType, {
    isQuietHoursEnabled: flow.isQuietHoursEnabled,
    entrySequenceItemId: flow.entrySequenceItemId
  })

  Object.keys(flowItems).forEach((flowItemId) => {
    const flowItemUI: FlowItemUI = selectFlowItem(flowItemId)(state)
    flowItemUI.isQuietHoursEnabled = isQuietHoursEnabledByFlowItemId[flowItemId]
  })
}
