import {
  type PatchSegmentRequest,
  type Segment,
  type SegmentListResponse,
  type SegmentListView
} from '@ghostmonitor/recartapis'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient, type UseQueryOptions } from '@tanstack/react-query'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { request } from '../../utils/request'
import { type PatchResourceArgs } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

const logger = createScope('dashboard')

async function patch({ id, payload }: PatchResourceArgs<PatchSegmentRequest>) {
  await request.patch<void>(`segments/${id}`, payload)
}

export function useSegmentsList(
  queryParams?: Record<string, unknown>,
  options?: UseQueryOptions<SegmentListResponse>
) {
  const queryClient = useQueryClient()
  const queryName = useMemo(() => [QUERY_NAME.segmentsList, queryParams], [queryParams])

  const { isLoading, data, isError, refetch } = useQuery<SegmentListResponse>(
    queryName,
    async () => {
      return request.get<SegmentListResponse>('segments', {
        params: queryParams
      })
    },
    options
  )

  const handlePatchMutate = useCallback(
    async (patchPayload: PatchResourceArgs<Segment>) => {
      await queryClient.cancelQueries(queryName)

      const previousSegmentsList: SegmentListResponse = queryClient.getQueryData(queryName)
      const segmentsList = previousSegmentsList.data.map((segment) =>
        segment.id === patchPayload.id
          ? {
              ...segment,
              ...patchPayload.payload
            }
          : segment
      )

      queryClient.setQueryData(queryName, { data: segmentsList })

      return { previousSegmentsList }
    },
    [queryClient, queryName]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  const handlePatchError = useCallback(
    (err, newSegment, context) => {
      logger.error(err)
      queryClient.setQueryData(queryName, context.previousSegmentsList)
      Notification.error('Failed to update segment. Please try again or contact support.')
    },
    [queryClient, queryName]
  )

  const { mutateAsync: mutatePatchSegment } = useMutation<
    void,
    unknown,
    PatchResourceArgs<Partial<SegmentListView>>
  >(patch, {
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  return {
    isLoading,
    data: data?.data,
    isError,
    refetch,
    patch: mutatePatchSegment
  }
}
