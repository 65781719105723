import debounce from 'lodash/debounce'
import { memo, useMemo } from 'react'
import { InlineEditor, type InlineEditorWrapperProps } from './inline-editor.component'

export const ThrottledInlineEditor = memo(function ThrottledInlineEditor(
  props: InlineEditorWrapperProps
) {
  const propagateChange = (value: string) => {
    props.onChange?.(value)
  }

  const handleChange = useMemo(() => {
    return debounce(propagateChange, 750, { leading: true, trailing: true })
  }, [])

  return <InlineEditor {...props} onChange={handleChange} />
})
