import { type SMSMessageTextUI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { Tooltip } from '../../../../../components/ui-kit/tooltip/tooltip.component'
import { hooks } from '../../../../../hooks/hooks'
import { selectEditorBlast, selectIsReadOnly, selectMessage } from '../../../../../store/selectors'
import {
  selectSiteId,
  selectSiteName,
  selectUrlGenerationSettings,
  selectSupportEmail
} from '../../../../../store/slices/site/site.selectors'
import { SMS_MESSAGE_MAX_LENGTH } from '../../../config'
import { type MessageTemplateProps } from '../../../types/message-template-props.type'
import { getSequenceEditorSMSStat } from '../../../utils/get-sms-stat'
import { InlineEditor } from '../../inline-editor/inline-editor.component'

export function SMSTextLegalMessage(props: MessageTemplateProps) {
  const siteName = hooks.useSiteSelector(selectSiteName)
  const siteId = hooks.useSiteSelector(selectSiteId)
  const urlGenerationSettings = hooks.useSiteSelector(selectUrlGenerationSettings)
  const supportEmail = hooks.useSiteSelector(selectSupportEmail)
  const blast = useSelector(selectEditorBlast)
  const isReadOnly = useSelector(selectIsReadOnly)

  const message = useSelector(
    selectMessage<SMSMessageTextUI>(props.sequenceItemId, props.messageIndex)
  )

  function getCharacterCount(rawValue: string) {
    return getSequenceEditorSMSStat(rawValue, {
      urlGenerationSettings,
      siteName: siteName ?? '',
      supportEmail,
      blast,
      isReadOnly
    }).characterCount
  }

  if (siteName === undefined || siteId === undefined) {
    return null
  }

  return (
    <Tooltip
      placement='right'
      title='This is a mandatory message before your welcome text. You cannot edit or delete it due to legal reasons.'
      arrowPointAtCenter
    >
      {' '}
      <div className='px-3 py-2'>
        <InlineEditor
          defaultValue={message.text}
          characterLimit={SMS_MESSAGE_MAX_LENGTH}
          getCharacterCount={getCharacterCount}
          isEditable={false}
          type='sms-legal'
        />
      </div>
    </Tooltip>
  )
}
