import {
  type ConditionalSplitSequenceItemUI,
  type DelaySequenceItemUI,
  getTextFromMessage,
  hasTextOnMessage,
  isConditionalSplitSequenceItemUI,
  isDelaySequenceItemUI,
  isMessengerMessageSequenceItemUI,
  isMessengerUserInputSequenceItemUI,
  isRandomSplitSequenceItemUI,
  isSMSSequenceItemUI,
  type MessageUI,
  type MessengerMessageSequenceItemUI,
  type MessengerUserInputSequenceItemUI,
  type RandomSplitSequenceItemUI,
  type SequenceItemUI,
  type SequenceUI,
  type SMSSequenceItemUI
} from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { roundToDecimals } from '../../../utils/formatters/formatters'
import { hasMessageOnSequenceItemType } from '../utils/assert-sequence-item-type'
import { getDiscountPoolIdsFromText } from './utils/get-discount-pool-ids-from-text'

export enum SequenceItemValidationErrors {
  EMPTY_MESSAGES = 'Sequence item needs at least one message',
  DELAY_OVER_LIMIT = 'Due to Facebook policy changes, setting ‘Delay’ block over 23 hours is not possible anymore. Please change it accordingly.',
  DELAY_INVALID_VALUE = 'Invalid delay value',
  SPLIT_INVALID_INPUT = 'Invalid input',
  TOTAL_PERCENTAGE_INVALID = 'The sum of the variables must equal 100%',
  MULTIPLE_UNIQUE_DISCOUNT_CODE = 'Multiple unique discount codes added to item'
}

function validateMessageSequenceItem(
  sequenceItem: MessengerMessageSequenceItemUI
): SerializedError[] {
  const errors = []

  if (!sequenceItem.messages || sequenceItem.messages.length === 0) {
    errors.push(new Error(SequenceItemValidationErrors.EMPTY_MESSAGES))
  }

  return errors
}

function validateRandomSplitSequenceItem(
  sequenceItem: RandomSplitSequenceItemUI
): SerializedError[] {
  const errors = []

  const totalPercentage = sequenceItem.logic.randomSplit.variants.reduce((sum, variant) => {
    return sum + variant.percentage
  }, 0)

  if (roundToDecimals(totalPercentage, 1) !== 100) {
    errors.push(new Error(SequenceItemValidationErrors.TOTAL_PERCENTAGE_INVALID))
  }

  return errors
}

function validateDelaySequenceItem(sequenceItem: DelaySequenceItemUI): SerializedError[] {
  const errors = []

  return errors
}

function validateConditionalSplitSequenceItem(
  sequenceItem: ConditionalSplitSequenceItemUI
): SerializedError[] {
  const errors = []

  return errors
}

function validateUserInputSequenceItem(
  sequenceItem: MessengerUserInputSequenceItemUI
): SerializedError[] {
  const errors = []

  return errors
}

function validateSMSSequenceItem(sequenceItem: SMSSequenceItemUI): SerializedError[] {
  const errors = []

  const discountPoolIds = new Set<string>()
  if (hasMessageOnSequenceItemType(sequenceItem)) {
    sequenceItem.messages.forEach((message: MessageUI) => {
      if (hasTextOnMessage(message)) {
        const text = getTextFromMessage(message)
        const messageDiscountPoolIds = getDiscountPoolIdsFromText(text)
        messageDiscountPoolIds.forEach((poolId) => discountPoolIds.add(poolId))
      }
    })
  }

  if (discountPoolIds.size > 1) {
    errors.push(SequenceItemValidationErrors.MULTIPLE_UNIQUE_DISCOUNT_CODE)
  }

  return errors
}

export function sequenceItemValidator(
  sequence: SequenceUI,
  sequenceItem: SequenceItemUI
): SerializedError[] {
  const errors = []

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    errors.push(...validateMessageSequenceItem(sequenceItem))
  } else if (isMessengerUserInputSequenceItemUI(sequenceItem)) {
    errors.push(...validateUserInputSequenceItem(sequenceItem))
  } else if (isDelaySequenceItemUI(sequenceItem)) {
    errors.push(...validateDelaySequenceItem(sequenceItem))
  } else if (isConditionalSplitSequenceItemUI(sequenceItem)) {
    errors.push(...validateConditionalSplitSequenceItem(sequenceItem))
  } else if (isRandomSplitSequenceItemUI(sequenceItem)) {
    errors.push(...validateRandomSplitSequenceItem(sequenceItem))
  } else if (isSMSSequenceItemUI(sequenceItem)) {
    errors.push(...validateSMSSequenceItem(sequenceItem))
  }

  return errors
}
