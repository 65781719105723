// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EMlbEGPO4KsKhpyqFQ1B{height:22px;padding-top:var(--recart-spacing-1)}.EDmDVBLZ88E0lhRu3fG7{margin-left:var(--recart-spacing-1)}.EDmDVBLZ88E0lhRu3fG7 svg{display:block}`, "",{"version":3,"sources":["webpack://./src/components/editor-drawer/components/toggle/toggle.component.scss"],"names":[],"mappings":"AACE,sBACE,WAAA,CACA,mCAAA,CAGF,sBACE,mCAAA,CAEA,0BACE,aAAA","sourcesContent":[":local {\n  .title {\n    height: 22px;\n    padding-top: var(--recart-spacing-1);\n  }\n\n  .tooltipIcon {\n    margin-left: var(--recart-spacing-1);\n\n    svg {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `EMlbEGPO4KsKhpyqFQ1B`,
	"tooltipIcon": `EDmDVBLZ88E0lhRu3fG7`
};
export default ___CSS_LOADER_EXPORT___;
