import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'

export enum NotificationType {
  critical = 'NotificationLevel/critical',
  actionError = 'NotificationLevel/actionError',
  staticError = 'NotificationLevel/staticError',
  actionWarn = 'NotificationLevel/actionWarn',
  staticWarn = 'NotificationLevel/staticWarn',
  actionInfo = 'NotificationLevel/actionInfo',
  staticInfo = 'NotificationLevel/staticInfo'
}

export interface NotificationConfig {
  id: string
  visible: boolean
  type: NotificationType
  title: React.ReactNode
  content?: React.ReactNode
}

export interface NotificationPayload {
  type: NotificationType
  title: React.ReactNode
  content?: React.ReactNode
}
export type NotificationUpdatePayload = Partial<NotificationPayload>

export interface NotificationState {
  notifications: NotificationConfig[]
}

export const notificationInitialState: NotificationState = {
  notifications: []
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    showNotification: (state, action: PayloadAction<NotificationConfig>) => {
      if ([NotificationType.critical, NotificationType.actionError].includes(action.payload.type)) {
        state.notifications = state.notifications.filter(
          (_notification) =>
            ![NotificationType.critical, NotificationType.actionError].includes(_notification.type)
        )
      }
      state.notifications.push(action.payload)
    },
    hideNotification: {
      reducer: (state, action: PayloadAction<null, string, { notificationId: string }>) => {
        const notification = state.notifications.find(
          (_notification) => _notification.id === action.meta.notificationId
        )!
        notification.visible = false

        if (
          [
            NotificationType.staticError,
            NotificationType.staticWarn,
            NotificationType.staticInfo
          ].includes(notification.type)
        ) {
          state.notifications = state.notifications.filter(
            (_notification) => _notification.id !== action.meta.notificationId
          )
        }
      },
      prepare: (payload: null, meta: { notificationId: string }) => ({
        payload,
        meta
      })
    },
    updateNotification: {
      reducer: (
        state,
        action: PayloadAction<NotificationUpdatePayload, string, { notificationId: string }>
      ) => {
        const notification = state.notifications.find(
          (_notification) => _notification.id === action.meta.notificationId
        )
        merge(notification, action.payload)
      },
      prepare: (payload: NotificationUpdatePayload, meta: { notificationId: string }) => ({
        payload,
        meta
      })
    },
    resetNotifications: (state) => {
      state.notifications.length = 0
    }
  }
})

export const { showNotification, hideNotification, updateNotification, resetNotifications } =
  notificationSlice.actions

export const { reducer: notificationReducer } = notificationSlice
