import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { selectFacebookPageConfig } from '../../store/selectors'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useFacebookPost(postId: string, enabled?: boolean): UseResource<any> {
  const fbPageConfig = useSelector(selectFacebookPageConfig)
  const queryName = [QUERY_NAME.facebookPost, postId]

  const { isLoading, data, isError } = useQuery(
    queryName,
    async () => {
      return request.get(`https://graph.facebook.com/v18.0/${fbPageConfig.pageId}_${postId}`, {
        params: {
          access_token: fbPageConfig.accessToken
        }
      })
    },
    {
      enabled
    }
  )

  return {
    data,
    isLoading,
    isError
  }
}
