import { CustomError } from '@ghostmonitor/lib-error'
import { createAsyncThunk, type Dispatch } from '@reduxjs/toolkit'
import { config } from '../../../config'
import { DashboardErrors } from '../../../types/dashboard-errors'
import { type FbMessengerConfig } from '../../../types/facebook/facebook-messenger-config.type'
import { api } from '../../../utils/api'
import { createScope } from '../../../utils/logger/logger'
import { request } from '../../../utils/request'
import { type DashboardState } from '../../dashboard.state'
import { selectSiteId, selectSiteUrl } from '../../selectors'
import {
  authFacebookFailure,
  authFacebookSuccess,
  clearFacebookAuth,
  loadFacebookPagesFailure,
  loadFacebookPagesSuccess
} from '../../slices/facebook/facebook.actions'

const { error } = createScope('dashboard')

export function loadFacebookPages({ siteId }: { siteId: string }) {
  return async function (dispatch: Dispatch, getState: () => DashboardState) {
    const state = getState()
    const authorizationCode = state.facebook.authorizationToken
    if (authorizationCode === null) {
      const err = new CustomError('MissingAuthorizationCode')
      error(err, { siteId })
      throw err
    }

    try {
      const pages = await api.exchangeFacebookAuthorizationToken(authorizationCode)
      if (pages.length === 0) {
        dispatch(
          loadFacebookPagesFailure({
            error: 'There are no Facebook pages in response'
          })
        )
        return
      }

      dispatch(
        loadFacebookPagesSuccess({
          pages
        })
      )
    } catch (error) {
      dispatch(
        loadFacebookPagesFailure({
          error: error.messages || error
        })
      )
    }

    return true
  }
}

export async function logout(): Promise<void> {
  return new Promise((resolve) => {
    if (window.FB === undefined) {
      throw new Error('FacebookSDKisNotLoaded')
    }

    window.FB.getLoginStatus((response: facebook.StatusResponse) => {
      if (response.status === 'connected') {
        window.FB.logout(() => {
          resolve()
        })
      } else {
        resolve()
      }
    })
  })
}

async function login(): Promise<facebook.StatusResponse> {
  async function getLoginState(): Promise<facebook.StatusResponse> {
    return new Promise((resolve) => {
      if (window.FB === undefined) {
        throw new Error('FacebookSDKisNotLoaded')
      }

      window.FB.getLoginStatus((response: facebook.StatusResponse) => {
        resolve(response)
      })
    })
  }

  return new Promise(async (resolve, reject) => {
    if (window.FB === undefined) {
      throw new Error('FacebookSDKisNotLoaded')
    }

    window.FB.login(
      (response: facebook.StatusResponse) => {
        if (response.status === 'connected') {
          resolve(response)
        } else {
          reject(response)
        }
      },
      {
        config_id: config.FACEBOOK_LOGIN_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true
      }
    )
  })
}

export const authFacebookThunk = createAsyncThunk('facebook/authFacebook', async (args, store) => {
  const state = store.getState() as DashboardState
  const siteId = selectSiteId(state)

  store.dispatch(clearFacebookAuth())
  if (window.FB === undefined) {
    error('FacebookSDKIsNotLoaded', { siteId })
    throw new Error(
      'Unable to establish a connection with Meta. Please reload the page and try again.'
    )
  }

  let statusResponse: facebook.StatusResponse
  try {
    statusResponse = await login()

    if (statusResponse === undefined) {
      throw new Error('EmptyStatusResponse')
    }

    store.dispatch(
      authFacebookSuccess({
        userId: statusResponse.authResponse.userID,
        authorizationToken: statusResponse.authResponse.code
      })
    )
  } catch (err) {
    error(new CustomError('FacebookConnectionError'), { cause: err, siteId })
    store.dispatch(
      authFacebookFailure({
        error: DashboardErrors.FACEBOOK_CONNECT_ERROR_NO_RESPONSE
      })
    )
    throw new Error(
      'There might be a problem with the connection to Meta, possibly due to missing permissions. Please reload the page, and grant the necessary permissions when the connection popup appears, then try again.'
    )
  }
})

export interface ConnectFacebookPageArgs {
  pageId: string
  userId: string
  accessToken: string
}

export const connectFacebookPageThunk = createAsyncThunk(
  'facebook/connectFacebookPage',
  async (args: ConnectFacebookPageArgs, store: any) => {
    const state = store.getState() as DashboardState
    const messengerConfig = await request.post<{ data: FbMessengerConfig; result: string }>(
      'facebook-pages/connect',
      {
        user_id: args.userId,
        page_id: args.pageId,
        access_token: args.accessToken,
        domain: selectSiteUrl(state)
      }
    )

    return messengerConfig.data
  }
)

export const disconnectFacebookPageThunk = createAsyncThunk(
  'facebook/disconnectFacebookPage',
  async () => {
    return request.post('facebook-pages/disconnect')
  }
)
