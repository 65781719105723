import {
  type PopupEmailSettings,
  type PopupSettings,
  type PopupSMSSettings,
  type PopupSMSWithEmailSettings,
  type PopupSuccessSettings
} from '@ghostmonitor/recartapis'

export function isNetworkOptinEnabledOnStep(
  step:
    | PopupSMSSettings
    | PopupEmailSettings
    | PopupSMSWithEmailSettings
    | PopupSuccessSettings
    | undefined
): boolean {
  if (!step?.networkOptin) {
    return false
  }
  return step.networkOptin.isEnabled
}

export function isNetworkOptinEnabledOnPopup(settings: PopupSettings): boolean {
  const enabledOnEmail = isNetworkOptinEnabledOnStep(settings.email)
  const enabledOnSMS = isNetworkOptinEnabledOnStep(settings.sms)
  const enabledOnSMSWithEmail = isNetworkOptinEnabledOnStep(settings.smsWithEmail)
  const enabledOnSuccess = isNetworkOptinEnabledOnStep(settings.success)

  return enabledOnEmail || enabledOnSMS || enabledOnSMSWithEmail || enabledOnSuccess
}

export function getAudienceNetworkLegalText(): string {
  return 'By saving your contacts, next time you will be able to sign up faster on other stores powered by Recart.'
}
