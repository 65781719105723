import { NodeType } from '@ghostmonitor/recartapis'
import { AbstractNodeFactory, type DiagramEngine } from 'storm-react-diagrams'
import { MessageSequenceItemNodeWidget } from '../../../components/sequence-item-node-widget/message-node-widget/message-sequence-item-node-widget.component'
import { MessageSequenceItemNodeModel } from './message-sequence-item-model'

export class MessageSequenceItemNodeFactory extends AbstractNodeFactory {
  constructor(private readonly repaintCanvas: VoidFunction = () => undefined) {
    super(NodeType.MESSAGE_SEQUENCE_ITEM)
  }

  public generateReactWidget(
    diagramEngine: DiagramEngine,
    sequenceItemNode: MessageSequenceItemNodeModel
  ): JSX.Element {
    return (
      <MessageSequenceItemNodeWidget
        sequenceItemNode={sequenceItemNode}
        repaintCanvas={this.repaintCanvas}
      />
    )
  }

  public getNewInstance(...args) {
    return new MessageSequenceItemNodeModel()
  }
}
