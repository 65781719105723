import { type HttpResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export interface RollingBalance {
  spendings: number
  payments: number
  rollingBalance: number
  rolledBalance: number
  availableBalance: number
  currentMonthBalance: number
}
export interface GetBalanceResponse extends HttpResponse {
  data: RollingBalance
}

export function useSmsRollingBalance(): UseResource<RollingBalance> {
  const queryName = [QUERY_NAME.smsBalance]

  const {
    isLoading,
    data: balance,
    isError
  } = useQuery<GetBalanceResponse>(queryName, async () => {
    return request.get<GetBalanceResponse>('billing/sms-balance')
  })

  return {
    data: balance?.data,
    isLoading,
    isError
  }
}
