import { useCallback, useEffect, useState } from 'react'
import { type DiagramEngine } from 'storm-react-diagrams'
import { useImagesLoaded } from '../../../hooks/use-images-loaded'

export function useRepaintCanvas(diagramEngine: DiagramEngine): () => void {
  const [repaint, setRepaint] = useState(false)
  const imagesLoaded = useImagesLoaded()

  useEffect(() => {
    if (repaint) {
      setRepaint(false)
      diagramEngine.recalculatePortsVisually()
      requestAnimationFrame(() => {
        diagramEngine.repaintCanvas()
      })
    }
  }, [repaint, diagramEngine])

  useEffect(() => {
    if (imagesLoaded) {
      setRepaint(true)
    }
  }, [imagesLoaded])

  const repaintCanvas = useCallback(() => {
    setRepaint(true)
  }, [])

  return repaintCanvas
}
