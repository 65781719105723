import {
  type ButtonField,
  type Channel,
  type CheckboxGroupField,
  type DateInputField,
  type Device,
  type DropdownField,
  type FormItemLabel,
  type ImageField,
  type InputField,
  type LinkButtonField,
  type MinimizedField,
  type MultiChoiceField,
  type NetworkOptin,
  type NumberInputField,
  type OptinToolFont,
  type OptinToolShadow,
  type OptionalInputField,
  type PopupAdditionalImageSettings,
  type PopupBackgroundSettings,
  type PopupButtonField,
  type PopupCloseIconSettings,
  type PopupImageField,
  type PopupSettings,
  type RadioGroupField,
  type ResendButtonField,
  type TextAreaField,
  type TextField,
  type TextInputField,
  type TextLocationInputField
} from '@ghostmonitor/recartapis'
import isEqual from 'lodash/isEqual'
import type React from 'react'
import { type InputState } from '../../../../../../../store/slices/optin-tool-editor/optin-tool-editor.reducer'

export function getFontFamily(font: Pick<OptinToolFont, 'family' | 'fallback'>): string {
  return `${font.family}, ${font.fallback}`
}

export function getFontWeight(fontVariant: string): string {
  return fontVariant.replace('italic', '')
}

export function getFontStyle(fontVariant: string): string {
  return fontVariant.includes('italic') ? 'italic' : 'normal'
}

function getBorder(button: ButtonField | LinkButtonField | PopupButtonField): string {
  const width = button.borderWidth ?? '0px'
  const color = button.borderColor ?? 'transparent'
  const style = 'solid'
  return `${width} ${style} ${color}`
}

export function getShadow(shadow: OptinToolShadow) {
  return `${shadow.offsetX} ${shadow.offsetY} ${shadow.blur} ${shadow.color}`
}

function hasRequiredFields(
  button: ButtonField | LinkButtonField | PopupButtonField
): button is ButtonField | LinkButtonField {
  const requiredButtonFields = ['backgroundColor', 'textColor', 'font', 'size'] as const
  return requiredButtonFields.every((field) => !!button[field])
}

export function getStylesForButton(
  button: ButtonField | LinkButtonField | PopupButtonField
): React.CSSProperties {
  if (!hasRequiredFields(button)) {
    throw new Error('Required fields are missing')
  }

  return {
    backgroundColor: button.backgroundColor,
    color: button.textColor,
    fontSize: button.font.size,
    lineHeight: button.font.size,
    fontFamily: getFontFamily(button.font),
    fontWeight: getFontWeight(button.font.variant),
    fontStyle: getFontStyle(button.font.variant),
    border: getBorder(button),
    paddingTop: button.size,
    paddingBottom: button.size,
    ...(button.shadow && { boxShadow: getShadow(button.shadow) }),
    ...(button.textDecoration && { textDecoration: button.textDecoration })
  }
}

export function getStylesForInputWrapper(
  input: InputField | OptionalInputField
): React.CSSProperties {
  if (input.shadow) {
    return {
      boxShadow: getShadow(input.shadow)
    }
  }
  return {}
}

export function getStylesForInput(
  input: InputField | OptionalInputField,
  state: InputState = 'defaultState'
): React.CSSProperties {
  const color =
    state === 'errorState'
      ? input.errorState.filledTextColor
      : state === 'activeState'
        ? input.activeState.filledTextColor
        : input.defaultState.placeholderColor
  return {
    color,
    fontFamily: input.defaultState.font.family,
    fontSize: input.defaultState.font.size,
    fontWeight: getFontWeight(input.defaultState.font.variant),
    fontStyle: getFontStyle(input.defaultState.font.variant),
    backgroundColor: input[state].backgroundColor,
    borderColor: input[state].borderColor
  }
}

export function getStylesForInputErrorMessage(
  input: InputField | OptionalInputField
): React.CSSProperties {
  return {
    backgroundColor: input.errorState.textBackgroundColor,
    color: input.errorState.textColor,
    fontFamily: input.defaultState.font.family,
    fontWeight: getFontWeight(input.defaultState.font.variant),
    fontStyle: getFontStyle(input.defaultState.font.variant)
    // fontSize is removed and it's a fixed 13px in CSS
  }
}

export function getStylesForFormInput(
  input: TextInputField | DropdownField | NumberInputField | DateInputField | TextLocationInputField
): React.CSSProperties {
  return {
    color: input.textColor,
    backgroundColor: input.backgroundColor,
    ...(input.shadow && { boxShadow: getShadow(input.shadow) })
  }
}

export function updateInputPlaceholderColor(
  color: string,
  type: Channel | 'form' | 'one-time-password' | 'one-click-verification'
) {
  document.documentElement.style.setProperty(`--${type}-input-placeholder-color`, color)
}

export function getStylesForText(
  text: TextField | TextAreaField | FormItemLabel | MultiChoiceField['optionLabel']
): React.CSSProperties {
  return {
    color: text.color,
    fontSize: text.font.size,
    lineHeight: text.font.size,
    fontFamily: getFontFamily(text.font),
    fontWeight: getFontWeight(text.font.variant),
    fontStyle: getFontStyle(text.font.variant),
    ...(text.shadow && { textShadow: getShadow(text.shadow) }),
    ...(text.textDecoration && { textDecoration: text.textDecoration })
  }
}

export function getStylesForImageField(image: ImageField | PopupImageField): React.CSSProperties {
  const { width, height, shadow } = image

  const styles: React.CSSProperties = {}

  if (shadow) {
    styles.filter = `drop-shadow(${getShadow(shadow)})`
  }

  if (width && height) {
    styles.width = width === 'auto' ? '100%' : width
    styles.height = height === 'auto' ? '100%' : height
  }

  return styles
}

export function getStylesForHyperLinkText(
  text: ResendButtonField,
  active: boolean
): React.CSSProperties {
  return {
    color: active ? text.activeTextColor : text.inactiveTextColor,
    cursor: active ? 'pointer' : 'not-allowed',
    fontSize: text.font.size,
    lineHeight: text.font.size,
    fontFamily: getFontFamily(text.font),
    fontWeight: getFontWeight(text.font.variant),
    fontStyle: getFontStyle(text.font.variant),
    ...(text.shadow && { textShadow: getShadow(text.shadow) }),
    ...(text.textDecoration && { textDecoration: text.textDecoration })
  }
}

export function getStylesForMultilineText(
  text: TextField | TextAreaField | FormItemLabel
): React.CSSProperties {
  return {
    ...getStylesForText(text),
    lineHeight: 1.5
  }
}

export function getStylesForOverlay(
  background: PopupBackgroundSettings,
  position: 'bottom' | 'middle' | 'top'
): React.CSSProperties {
  const flexPosition = getStylesForPopupContentPosition(position)
  return {
    ...flexPosition,
    backgroundColor: background.overlayColor
  }
}

export function updateStylesForCloseButton(closeIcon: PopupCloseIconSettings) {
  document.documentElement.style.setProperty('--close-icon-color', closeIcon.iconColor)
}

export function getStylesForMinimizedButton(button: MinimizedField): React.CSSProperties {
  return {
    backgroundColor: button.backgroundColor,
    color: button.textColor,
    fontSize: button.font.size,
    fontFamily: getFontFamily(button.font),
    fontWeight: getFontWeight(button.font.variant),
    fontStyle: getFontStyle(button.font.variant),
    ...(button.shadow && { boxShadow: getShadow(button.shadow) }),
    ...(button.textDecoration && { textDecoration: button.textDecoration })
  }
}

export function getStylesForPopupContentPosition(
  position: 'bottom' | 'middle' | 'top'
): React.CSSProperties {
  let flexPosition: string

  switch (position) {
    case 'top':
      flexPosition = 'flex-start'
      break
    case 'middle':
      flexPosition = 'center'
      break
    case 'bottom':
      flexPosition = 'flex-end'
      break
  }

  return {
    justifyContent: flexPosition
  }
}

export function getClassesForPopupContainer(
  devices: Device[],
  size: PopupSettings['size'],
  additionalImage: PopupAdditionalImageSettings | null
): string {
  let classes: string
  if (size === 'fullscreen') {
    classes = 'recart-popup-container-fullscreen'
  } else {
    if (isEqual(devices, ['mobile'])) {
      classes = 'recart-popup-container-lightbox-mobile'
    } else {
      classes = 'recart-popup-container-lightbox-desktop'
    }
  }

  if (additionalImage) {
    classes += ` with-additional-image ${additionalImage.position}`
  }

  return classes
}

export function getStylesForOptionsWrapper(
  orientation: MultiChoiceField['layout']
): React.CSSProperties {
  const isVertical = orientation === 'vertical'
  return {
    flexDirection: isVertical ? 'column' : 'row',
    alignItems: isVertical ? 'normal' : 'center',
    justifyContent: isVertical ? 'normal' : 'center'
  }
}

export function getStylesForMultichoiceItem(
  formItem: CheckboxGroupField | RadioGroupField | NetworkOptin,
  isChecked: boolean
): React.CSSProperties {
  const {
    borderColor,
    selectedBorderColor,
    backgroundColor,
    selectedBackgroundColor,
    selectedColor
  } = formItem

  return {
    borderColor: isChecked ? selectedBorderColor : borderColor,
    backgroundColor: isChecked ? selectedBackgroundColor : backgroundColor,
    color: selectedColor,
    ...(formItem.shadow && { boxShadow: getShadow(formItem.shadow) })
  }
}
