import { SequenceTag } from '@ghostmonitor/recartapis'
import { isBlastSequence } from '../../routes/SequenceEditor/utils/assert-sequence-type'
import { sortRecentCampaignFlowsItemByDate } from '../../routes/home/components/recent-campaign-flows/components/utils/sort-campaign-flow-items-by-date'
import { selectCurrencySlug } from '../../store/slices/site/site.selectors'
import { type RecentCampaignFlowsListItem } from '../../types/recent-campaign-flows-list-item/recent-campaign-flows-list-item.type'
import { SMSCampaignStatusLabel } from '../../types/sms-campaign/sms-campaign-status-label'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useRecentCampaignFlowsList(): UseResource<RecentCampaignFlowsListItem[]> {
  const recentCampaignFlowTags = [SequenceTag.CAMPAIGN, SequenceTag.SMS]
  const { isLoading: sequencesIsLoading, data: sequences } =
    hooks.useSequencesByTags(recentCampaignFlowTags)
  const { data: blasts, isLoading: blastIsLoading } = hooks.useBlasts()
  const currencySlug = hooks.useSiteSelector(selectCurrencySlug)

  const { data: sequencesStat, isLoading: sequencesStatsIsLoading } = hooks.useSequencesStat({
    isEnabled: true,
    tags: recentCampaignFlowTags
  })

  let recentCampaignFlowListItems: RecentCampaignFlowsListItem[] = []

  if (
    !sequencesIsLoading &&
    !blastIsLoading &&
    !sequencesStatsIsLoading &&
    currencySlug !== undefined
  ) {
    sequences.forEach((sequence) => {
      const sequenceStat = sequencesStat[sequence._id]
      if (!sequenceStat) {
        return
      }

      if (isBlastSequence(sequence)) {
        const blast = blasts.find((blast) => blast.sequenceId === sequence._id)
        const shouldShowCampaign = blast?.status === 'sent' || blast?.status === 'sending'
        if (!shouldShowCampaign) {
          return
        }
        recentCampaignFlowListItems.push({
          _id: blast.sequenceId,
          name: sequence.name,
          channel: 'sms',
          scheduleType: blast.scheduleType,
          tags: sequence.tags,
          createdAt: sequence.createdAt,
          updatedAt: sequence.updatedAt,
          status: SMSCampaignStatusLabel[blast.status],
          startDate: blast.scheduledFor,
          endDate: null,
          stats: {
            spend: sequenceStat.cost.sms[currencySlug] ?? 0,
            roi: sequenceStat.roi ?? 0,
            sent: sequenceStat.sent ?? 0,
            sales: sequenceStat?.salesTotal[currencySlug] ?? 0,
            recipient: sequenceStat?.recipient ?? 0
          }
        })
      }
    })

    recentCampaignFlowListItems.sort(sortRecentCampaignFlowsItemByDate)

    recentCampaignFlowListItems = recentCampaignFlowListItems.slice(0, 3)
  }

  return {
    data: sequencesStat && recentCampaignFlowListItems,
    isLoading: sequencesIsLoading || sequencesStatsIsLoading || blastIsLoading
  }
}
