import { type FlowUI, type SequenceTag } from '@ghostmonitor/recartapis'
import difference from 'lodash/difference'
import { useMemo } from 'react'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useFlowsByTags(
  tags: SequenceTag[],
  excludeTags: SequenceTag[] = []
): UseResource<FlowUI[], Partial<FlowUI>> {
  const { isLoading, data: flows, refetch, isError } = hooks.useFlows()

  const tagFilteredFlows = useMemo(() => {
    const flowsWithTags = flows?.filter((flow) => difference(tags, flow.tags).length === 0)

    return flowsWithTags?.filter((flow) =>
      flow.tags.every((tag: SequenceTag) => !excludeTags.includes(tag))
    )
  }, [flows, tags, excludeTags])

  return {
    data: flows && tagFilteredFlows,
    isLoading,
    isError,
    refetch: refetch as any
  }
}
