import { MessageType, type SMSMessageMMSUI } from '@ghostmonitor/recartapis'
import { MessageTrayItem } from '../../../types/tray-item.type'
import { trayIcons } from '../tray-icons'
import { TrayItemComponent } from '../tray-item.component'

export function TrayItemSMSMedia() {
  const message: SMSMessageMMSUI = {
    type: MessageType.SMS_MMS,
    text: '',
    attachments: []
  }

  return (
    <TrayItemComponent
      itemType={MessageTrayItem.smsMedia}
      icon={trayIcons.image}
      doubleWidth
      message={message}
      title='+ Image'
    />
  )
}
