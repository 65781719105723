// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eQQTbwihODtdQk88KKwH{border:1px solid #476dff;border-radius:4px;position:relative}.eQQTbwihODtdQk88KKwH .M3gkBZNaXIGwJ7mHQs6a{display:block;font-size:16px;height:36px;line-height:36px;text-align:center;width:100%}.eQQTbwihODtdQk88KKwH .u63DfivQR4khJP7xbW7y{position:absolute;right:10px;top:50%;transform:translateY(-50%)}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/logic-sequence-item-port-widget/logic-sequence-item-port-widget.component.scss"],"names":[],"mappings":"AAGE,sBAEE,wBAAA,CACA,iBAAA,CAFA,iBAEA,CAEA,4CACE,aAAA,CAKA,cAAA,CAFA,WAAA,CACA,gBAAA,CAHA,iBAAA,CACA,UAGA,CAGF,4CACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,0BAAA","sourcesContent":["@use '../../sequence-editor' as *;\n\n:local {\n  .logic-sequence-item-port-wrapper {\n    position: relative;\n    border: 1px solid $lightish-blue;\n    border-radius: 4px;\n\n    .title {\n      display: block;\n      text-align: center;\n      width: 100%;\n      height: 36px;\n      line-height: 36px;\n      font-size: 16px;\n    }\n\n    .port-container {\n      position: absolute;\n      right: 10px;\n      top: 50%;\n      transform: translate(0, -50%);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logic-sequence-item-port-wrapper": `eQQTbwihODtdQk88KKwH`,
	"title": `M3gkBZNaXIGwJ7mHQs6a`,
	"port-container": `u63DfivQR4khJP7xbW7y`
};
export default ___CSS_LOADER_EXPORT___;
