import { type FlowItemUI, type FlowUI, isSMSMessageUI } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { produce } from 'immer'
import {
  hasMessageOnFlowItemType,
  isConditionalSplitV2FlowItemUI
} from '../../../../types/guards/flow-item-ui.guards'
import { type ExpressionUI } from '../../../../types/segment/condition-ui.type'
import { convertExpressionUIToAPI } from '../../../../types/segment/converters/ui-to-api/segment'
import { api } from '../../../../utils/api'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors, selectLaunchDarklyFlag } from '../../../selectors'
import { type ThunkAPI } from '../../../types/thunk-api.type'
import { validateDiscountCodesThunk } from './validate-discount-codes.thunk'
import { type ValidateFlowThunkBypassWarnings, validateFlowThunk } from './validate-flow.thunk'

type SaveFlowThunkPayload = ValidateFlowThunkBypassWarnings | undefined

export const saveFlowThunk = createAsyncThunk<FlowUI, SaveFlowThunkPayload>(
  'flowEditor/saveFlow',
  async (payload: SaveFlowThunkPayload, thunkAPI: any) => {
    const { getState, dispatch }: ThunkAPI = thunkAPI
    const state = getState()
    const flow = flowEditorSelectors.selectEditorFlow(state)
    const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)
    const isReadOnly = flowEditorSelectors.selectIsReadOnly(state)
    const isConditionalSplitV1SunsetEnabled = selectLaunchDarklyFlag(
      'conditional-split-v-1-sunset'
    )(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    const [flowValidationResult, discountCodeValidationResult] = await Promise.all([
      dispatch(
        validateFlowThunk({
          infiniteLoop: true,
          flowItemContent: true,
          logicFlowItemConnections: true,
          triggerMustHaveConnection: true,
          joinedASegmentMustHaveSegment: true,
          customIntegrationMustHaveCategory: true,
          customIntegrationMustHaveEvent: true,
          conditionExpression: true,
          conditionalSplitV1Deprecated: isConditionalSplitV1SunsetEnabled,
          bypassWarnings: payload?.bypassWarnings,
          keywordReplyConnections: true
        })
      ),
      dispatch(validateDiscountCodesThunk())
    ])

    if (validateFlowThunk.rejected.match(flowValidationResult)) {
      throw new Error(FlowEditorError.FlowValidationFailed, { cause: flowValidationResult.error })
    }

    if (validateDiscountCodesThunk.rejected.match(discountCodeValidationResult) && !isReadOnly) {
      throw new Error(FlowEditorError.FlowValidationFailed, {
        cause: discountCodeValidationResult.error
      })
    }

    const {
      // @ts-expect-error Shouldn't remove these
      deletedSequenceItemIds,
      serializedDiagram,
      isEnabled,
      hasEverBeenEnabled,
      ...flowToSave
    } = flow

    flowToSave.quickReplyIndexToId = flow.sequenceItems.reduce(
      (quickReplyIndexToId, sequenceItem) => {
        quickReplyIndexToId[sequenceItem._id] = {}
        return quickReplyIndexToId
      },
      {}
    )

    // Replace all non-breaking spaces with regular spaces
    flowToSave.sequenceItems = flowToSave.sequenceItems.map((flowItem) => {
      return produce<FlowItemUI>(flowItem, (draft) => {
        // It's only modifyable by the /toggle endpoint
        delete draft.isEnabled

        if (hasMessageOnFlowItemType(draft)) {
          draft.item.messages.forEach((message) => {
            if (isSMSMessageUI(message)) {
              message.text = message.text.replace(
                /[\u00A0\u180E\u2000\u200B\u202F\u205F\u3000\uFEFF]/g,
                ' '
              )
            }
          })
        }

        // convert ExpressionUI to ExpressionAPI types
        if (isConditionalSplitV2FlowItemUI(draft)) {
          draft.item.logic.conditionalSplitV2.cases.forEach((conditionalSplitCase) => {
            conditionalSplitCase.expression = convertExpressionUIToAPI(
              conditionalSplitCase.expression as any
            ) as ExpressionUI
          })
        }
      })
    })

    let response: FlowUI | undefined
    try {
      const updateFlowBody = {
        ...flowToSave,
        ...(smsCampaign && {
          smsCampaign: {
            scheduledFor: smsCampaign.scheduledFor,
            status: smsCampaign.status
          }
        })
      }

      response = await api.updateFlow(updateFlowBody)
    } catch (err) {
      throw new Error(FlowEditorError.SaveFlowFailed, { cause: err })
    }

    return response
  },
  { serializeError }
)
