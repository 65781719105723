import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'
import { useEffect, useRef } from 'react'

// todo when https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/emoji-mart will be updated for the v5.0.0. version we should add PickerProptypes

type EmojiMartPickerProps = any

export function EmojiMartPicker(props: EmojiMartPickerProps) {
  const ref = useRef() as React.MutableRefObject<any>

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Picker({ ...props, data, ref })
  }, [])

  return <div ref={ref} />
}
