import { type MessengerMessageUI } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDraggedItemType } from '../../../../store/selectors'
import {
  startDragging,
  stopDragging
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { MessageTrayItem, type TrayItem } from '../../types/tray-item.type'
import styles from './tray-item.component.scss'

export interface TrayItemProps {
  itemType: TrayItem
  icon: React.ReactElement
  iconClassName?: string
  message?: MessengerMessageUI
  sequenceItem?: boolean
  title?: string
  doubleWidth?: boolean
}

export function TrayItemComponent(props: TrayItemProps) {
  const [dragging, setDragging] = useState(false)
  const draggedItemType = useSelector(selectDraggedItemType)
  const dispatch = useDispatch()

  function dragStart(event: React.DragEvent<HTMLDivElement>) {
    dispatch(startDragging({ itemType: props.itemType }))
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy'
      event.dataTransfer.setData(
        'text/plain',
        JSON.stringify({
          itemType: props.itemType,
          message: props.message
        })
      )
    }
    setDragging(true)
  }

  function dragEnd() {
    dispatch(stopDragging())
    setDragging(false)
  }

  return (
    <div
      className={cn(styles.trayItemSlot, {
        [styles.dragging]: dragging,
        [styles.inactive]: draggedItemType,
        [styles.sequenceItem]: props.sequenceItem,
        [styles.double]: props.doubleWidth
      })}
    >
      <div
        className={cn(styles.trayItem, {
          [styles.dragging]: dragging,
          [styles.inactive]: draggedItemType,
          [styles.sequenceItem]: props.sequenceItem
        })}
        draggable
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        data-testid='tray-item'
      >
        <div className={props.iconClassName || styles.iconDefault}>{props.icon}</div>
        <p
          className={cn(styles.title, {
            [styles['title-sm']]: props.itemType === MessageTrayItem.messengerMessage
          })}
        >
          {props.title ?? ''}
        </p>
      </div>
    </div>
  )
}
