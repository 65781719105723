import {
  type DiscountCode,
  isSMSSequenceItemUI,
  isStaticDiscountCode,
  isUniqueDiscountCode,
  type Variable,
  variableRawAndTagPattern
} from '@ghostmonitor/recartapis'
import { Popover } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import { EditorButton } from '../../../../../../components/ui-kit/editor-button/editor-button.component'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { hooks } from '../../../../../../hooks/hooks'
import {
  selectEditorSequenceMeta,
  selectExpiredDiscountCodes,
  selectSequenceItem
} from '../../../../../../store/selectors'
import { discountCodeTourFinished } from '../../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { type DiscountCodePartialDeletionType } from '../../../../../../store/slices/sequence-editor/sequence-editor.state'
import {
  convertUserVariables,
  getElementByVariableIndex,
  getIdFromVariable,
  getIndexFromId,
  getRawVariableFromVariable,
  getRenderedVariable,
  getUrlFromTagId,
  getVariableFromId,
  getVariableFromRawVariableSequenceEditor,
  getVariablesFromRawValue,
  isDiscountCodeVariable,
  isStaticDiscountCodeVariable,
  isUniqueDiscountCodeVariable,
  removeDiscountCodeByName,
  removeDiscountCodeByPoolId,
  removeVariableByIndex,
  replaceUserVariablesToRawVariables,
  replaceVariableTagsToRawVariables,
  setIdAtIndex
} from '../../../../../../utils/inline-editor/inline-editor-utils'
import { type InlineEditorPlugin } from '../../../../../../utils/inline-editor/types/inline-editor-plugin.type'
import { CreatePoolOnDashboardModal } from '../../../create-pool-on-dashboard-modal/create-pool-on-dashboard-modal'
import { DiscountCodeDeletedModal } from '../../../discount-code-deleted-modal/discount-code-deleted-modal'
import { PopoverDiscountCodeExpired } from '../../../popover-discount-code-expired/popover-discount-code-expired.component'
import { PopoverEditDiscountCodeUnique } from '../../../popover-edit-discount-code-unique/popover-edit-discount-code.component'
import {
  type InlineEditorProps,
  useInlineEditorHOCControl,
  type VariableMouseClickEvent
} from '../../use-inline-editor-hoc-control.hook'
import styles from './make-discount-input.scss'
import { SequenceDiscount } from './sequence-discount.component'

interface PartiallyDeletedDiscountCode {
  discountVariable: Variable
  deletionType: DiscountCodePartialDeletionType
}

function replaceHtmlValueToRawValue(htmlValue: string): string {
  let newRawValue = convertUserVariables(htmlValue)
  newRawValue = replaceVariableTagsToRawVariables(newRawValue, [])
  newRawValue = replaceUserVariablesToRawVariables(newRawValue)
  return newRawValue
}

function getHtmlFromVariable(
  variable: Variable,
  index: number,
  isError: boolean,
  subdomain: string
): string {
  return renderToString(
    <SequenceDiscount
      placeholder={getRenderedVariable(variable, subdomain)}
      id={getIdFromVariable(variable, index)}
      isUrl={variable.name === 'url'}
      isError={isError}
    />
  )
}

interface MakeDiscountCodeUniqueProps {}

export function makeDiscountCodeUnique<T extends InlineEditorProps>(
  WrappedComponent: React.FC<T>
): React.FC<MakeDiscountCodeUniqueProps & T> {
  const MakeDiscountCodeUnique = (
    props: React.PropsWithChildren<MakeDiscountCodeUniqueProps & T>
  ) => {
    const [variableErrors, setVariableErrors] = useState<boolean[]>([])
    const navigate = useNavigate()
    const sequenceItem = useSelector(selectSequenceItem(props.sequenceItemId))
    const hasDiscountPoolIdOnItem =
      isSMSSequenceItemUI(sequenceItem) &&
      sequenceItem.discountCodePoolId !== undefined &&
      sequenceItem.discountCodePoolId !== ''
    const { data: discountCodePools } = hooks.useDiscountCodePools()

    const replaceRawValueToHtmlValue = useCallback(
      (rawValue: string): string => {
        let newHtml = rawValue
        let match: string[]
        let index = 0
        const allVarPattern = new RegExp(variableRawAndTagPattern, 'g')

        while ((match = allVarPattern.exec(newHtml))) {
          const variable = getVariableFromRawVariableSequenceEditor(match[0])

          if (!isDiscountCodeVariable(variable)) {
            index++
            continue
          }

          newHtml = newHtml.replace(
            getRawVariableFromVariable(variable),
            getHtmlFromVariable(variable, index, variableErrors[index], props.subdomain ?? '')
          )
          index++
        }
        return newHtml
      },
      [variableErrors]
    )

    const { html, setHtml, handlers, editorRef, rawValue, caretPosition, insertContent } =
      useInlineEditorHOCControl(
        'make-variable',
        props,
        replaceHtmlValueToRawValue,
        replaceRawValueToHtmlValue
      )

    const [showDiscountCodeFormPopover, setShowDiscountCodeFormPopover] = useState(false)
    const [discountPopupOffset, setDiscountPopupOffset] = useState([0, 0])
    const [nodeIndexOnAdded, setNodeIndexOnAdded] = useState(-1)
    const discountTriggerRef = useRef<HTMLDivElement>()
    const [discountCodeNameManualReview, setDiscountCodeNameManualReview] = useState<string>()
    const expiredDiscountCodes = useSelector(selectExpiredDiscountCodes)
    const [tooltipOffset, setTooltipOffset] = useState([0, 0])
    const [tooltipUrl, setTooltipUrl] = useState('')
    const [tooltipDiscountCode, setTooltipDiscountCode] = useState('')
    const [tooltipDiscountPoolName, setTooltipDiscountPoolName] = useState('')
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const [isEditingInlineDiscountCode, setIsEditingInlineDiscountCode] = useState<boolean>(false)
    const [editedDiscountCodeName, setEditedDiscountCodeName] = useState<string>()
    const [editedDiscountCodePoolId, setEditedDiscountPoolId] = useState<string>()
    const [editedDiscountUrl, setEditedDiscountUrl] = useState<string>()
    const [editedDiscountCodeIndex, setEditedDiscountCodeIndex] = useState<number>()
    const [createPoolModalVisible, setCreatePoolModalVisible] = useState(false)

    const [partiallyDeletedDiscountCode, setPartiallyDeletedDiscountCode] =
      useState<PartiallyDeletedDiscountCode>()
    const dispatch = useDispatch()
    const sequenceMeta = useSelector(selectEditorSequenceMeta)

    useEffect(() => {
      if (nodeIndexOnAdded !== -1) {
        setNodeIndexOnAdded(-1)
      }
      setPartiallyDeletedDiscountCode(undefined)
    }, [rawValue, nodeIndexOnAdded])

    useEffect(() => {
      if (rawValue !== null) {
        setHtml(replaceRawValueToHtmlValue(rawValue))
      }
    }, [rawValue, variableErrors, setHtml, replaceRawValueToHtmlValue])

    function resetDiscountCodeForm() {
      setIsEditingInlineDiscountCode(false)
      setDiscountCodeNameManualReview(undefined)
      setEditedDiscountCodeIndex(undefined)
      setEditedDiscountCodeName(undefined)
      setEditedDiscountPoolId(undefined)
      setEditedDiscountUrl(undefined)
    }

    function closeDiscountCodeForm() {
      setShowDiscountCodeFormPopover(false)
      resetDiscountCodeForm()
      if (sequenceMeta.discountCodeTour) {
        dispatch(discountCodeTourFinished())
      }
    }

    function getDiscountCodeVariable(discountCode: DiscountCode): Variable {
      if (isStaticDiscountCode(discountCode)) {
        const { title } = discountCode
        return {
          name: title,
          params: {
            discount_code: title
          }
        }
      }
      if (isUniqueDiscountCode(discountCode)) {
        const { poolId } = discountCode
        return {
          name: 'discount.unique',
          params: {
            discount_pool_id: poolId
          }
        }
      }
    }

    function getUrlDiscountCodeVariable(discountCode: DiscountCode, url: string): Variable {
      const baseParams = {
        value: url,
        url_add_discount: undefined,
        url_shorten: undefined,
        url_decorate: undefined
      }
      if (isStaticDiscountCode(discountCode)) {
        const { title } = discountCode
        return {
          name: 'url',
          params: {
            ...baseParams,
            discount_code: title
          }
        }
      }
      if (isUniqueDiscountCode(discountCode)) {
        const { poolId } = discountCode
        return {
          name: 'url',
          params: {
            ...baseParams,
            discount_pool_id: poolId
          }
        }
      }
    }

    function handleAddDiscount(discountCode: DiscountCode, url?: string) {
      if (discountCodeNameManualReview !== discountCode.discountCode) {
        const discountCodeVariable = getDiscountCodeVariable(discountCode)
        const urlDiscountCodeVariable = getUrlDiscountCodeVariable(discountCode, url)
        const insertedHtml =
          url !== undefined && url !== ''
            ? `${getHtmlFromVariable(
                discountCodeVariable,
                0,
                false,
                props.subdomain ?? ''
              )} ${getHtmlFromVariable(urlDiscountCodeVariable, 0, false, props.subdomain ?? '')}`
            : getHtmlFromVariable(discountCodeVariable, 0, false, props.subdomain ?? '')
        const newHtml = insertContent(insertedHtml, caretPosition)
        setHtml(newHtml)
      }
      setShowDiscountCodeFormPopover(false)
    }

    function handleUpdateDiscountUrl(discountCode: DiscountCode, url: string) {
      closeDiscountCodeForm()
      const variable = getUrlDiscountCodeVariable(discountCode, url)
      const updatedId = getIdFromVariable(variable, editedDiscountCodeIndex)
      const newHtml = setIdAtIndex(html, editedDiscountCodeIndex, updatedId)
      setHtml(newHtml)
    }

    function handleEditRemoveDiscount() {
      closeDiscountCodeForm()
      const newHtml = removeVariableByIndex(html, editedDiscountCodeIndex)
      setHtml(newHtml)
    }

    function removeDiscountCodeFromTextByName(discountCodeName: string) {
      const newHtml = removeDiscountCodeByName(html, discountCodeName)
      setHtml(newHtml)
    }

    function removeDiscountCodeFromTextByPoolId(discountPoolId: string) {
      const newHtml = removeDiscountCodeByPoolId(html, discountPoolId)
      setHtml(newHtml)
    }

    function handleRemoveDiscountCode(discountVariable: Variable) {
      if (isStaticDiscountCodeVariable(discountVariable)) {
        removeDiscountCodeFromTextByName(discountVariable.params.discount_code)
      }
      if (isUniqueDiscountCodeVariable(discountVariable)) {
        removeDiscountCodeFromTextByPoolId(discountVariable.params.discount_pool_id)
      }
    }

    function handleRemoveExpiredDiscountCode(discountCodeName: string) {
      resetDiscountCodeForm()
      removeDiscountCodeFromTextByName(discountCodeName)
    }

    function handleMouseMove(event: React.MouseEvent<HTMLDivElement>) {
      const tagId = (event.target as any).id as string
      if (!tagId) {
        setIsTooltipOpen(false)
        return
      }

      const url = getUrlFromTagId(tagId, 'url_add_discount')
      const name = getVariableFromId(tagId)?.params.discount_code
      const poolId = getVariableFromId(tagId)?.params.discount_pool_id

      if (!url || (!name && !poolId)) {
        setIsTooltipOpen(false)
        props.onMouseMove?.(event)
        return
      }

      const poolName = discountCodePools?.find((pool) => pool.id === poolId)?.name

      const editorRect = editorRef.current.getBoundingClientRect()
      const linkRect = (event.target as any).getBoundingClientRect()
      const horizontalDiff =
        linkRect.left - editorRect.left - editorRect.width / 2 + linkRect.width / 2
      const verticalDiff = linkRect.top - editorRect.top

      setTooltipUrl(url)
      setTooltipDiscountCode(name ?? '')
      setTooltipDiscountPoolName(poolName ?? '')
      setTooltipOffset([horizontalDiff, verticalDiff])
      setIsTooltipOpen(true)
      props.onMouseMove?.(event)
    }

    function handleMouseLeave(event: React.MouseEvent<HTMLDivElement>) {
      setIsTooltipOpen(false)
      props.onMouseLeave?.(event)
    }

    const setDiscountPopupPosition = useCallback(
      (targetVariableRect: DOMRect) => {
        const editorRect = editorRef.current.getBoundingClientRect()
        const addVariableButtonRect = discountTriggerRef.current.getBoundingClientRect()
        const horizontalDiff = addVariableButtonRect.left - editorRect.left
        const discountPopupOffset = [
          -Math.abs(addVariableButtonRect.left - targetVariableRect.left) +
            targetVariableRect.width / 2 +
            horizontalDiff,
          -Math.abs(addVariableButtonRect.top - targetVariableRect.top) +
            (targetVariableRect.height + 8)
        ]

        setDiscountPopupOffset(discountPopupOffset)
      },
      [editorRef]
    )

    const setInlineDiscountPopupPosition = useCallback(
      (targetVariableRect: DOMRect) => {
        const editorRect = editorRef.current.getBoundingClientRect()
        const discountPopupOffset = [
          Math.abs(editorRect.left - targetVariableRect.left) + targetVariableRect.width / 2,
          -Math.abs(editorRect.bottom - targetVariableRect.bottom)
        ]
        setDiscountPopupOffset(discountPopupOffset)
      },
      [editorRef]
    )

    function toggleDiscount() {
      setDiscountPopupPosition(discountTriggerRef.current?.getBoundingClientRect())
      setShowDiscountCodeFormPopover(!showDiscountCodeFormPopover)
    }

    function handleClick(event: VariableMouseClickEvent): void {
      closeDiscountCodeForm()

      if (event.target !== editorRef?.current && props.isEditable) {
        const tagId = event.target.id
        if (tagId) {
          const variable = getVariableFromId(tagId)
          if (!isDiscountCodeVariable(variable) || variable.name !== 'url') {
            props.onClick?.(event)
            return
          }

          const url = getUrlFromTagId(tagId, 'url_add_discount')

          const { discount_code: discountCodeName } = variable.params
          const { discount_pool_id: discountPoolId } = variable.params

          setInlineDiscountPopupPosition(event.target.getBoundingClientRect())
          setIsEditingInlineDiscountCode(true)
          setEditedDiscountCodeName(discountCodeName)
          setEditedDiscountPoolId(discountPoolId)
          setEditedDiscountUrl(url)
          setEditedDiscountCodeIndex(getIndexFromId(tagId))
          setShowDiscountCodeFormPopover(true)
        }
      }
      props.onClick?.(event)
    }

    function handleDiscountCodeFormVisibilityChange(visible: boolean) {
      if (!visible) {
        closeDiscountCodeForm()
      }
    }

    function handleExpiredDiscountCodeVisibilityChange(visible: boolean) {
      if (!visible) {
        closeDiscountCodeForm()
      }
    }

    function handleDiscountCodeNameChoosen(discountCodeName: string) {
      setEditedDiscountCodeName(discountCodeName)
    }

    function handleCreatePoolModalFinished(goToDashboard: boolean) {
      setCreatePoolModalVisible(false)
      if (goToDashboard) {
        navigate('/discounts/create')
      }
    }

    function handleCreatePoolOnDashboard() {
      setShowDiscountCodeFormPopover(false)
      setCreatePoolModalVisible(true)
    }

    const PopoverFooter: React.FC<{ discountCodeExists: boolean }> = useCallback(
      (props) => {
        if (editedDiscountCodeName === undefined && discountCodeNameManualReview === undefined) {
          return (
            <EditorButton
              type='link'
              className='w-full mt-2'
              onClick={closeDiscountCodeForm}
              data-testid='button-back-close'
            >
              Back
            </EditorButton>
          )
        }

        if (
          (editedDiscountCodeName !== undefined || discountCodeNameManualReview !== undefined) &&
          !props.discountCodeExists &&
          isEditingInlineDiscountCode
        ) {
          return (
            <EditorButton
              type='link'
              className='w-full mt-2'
              onClick={closeDiscountCodeForm}
              data-testid='button-back-edit-close'
            >
              Back
            </EditorButton>
          )
        }

        if (props.discountCodeExists && isEditingInlineDiscountCode) {
          return (
            <EditorButton
              type='link'
              className='w-full mt-2'
              onClick={handleEditRemoveDiscount}
              data-testid='button-back-remove'
            >
              Remove discount code
            </EditorButton>
          )
        }

        return (
          <EditorButton type='link' className='w-full mt-2' onClick={resetDiscountCodeForm}>
            Back
          </EditorButton>
        )
      },
      [editedDiscountCodeName, discountCodeNameManualReview, isEditingInlineDiscountCode]
    )

    function renderDiscountCodeFormContent(): JSX.Element {
      return (
        <PopoverEditDiscountCodeUnique
          discountCodeName={editedDiscountCodeName || discountCodeNameManualReview}
          discountCodePoolId={editedDiscountCodePoolId}
          url={editedDiscountUrl}
          isEditing={isEditingInlineDiscountCode}
          footer={PopoverFooter}
          onDiscountCodeName={handleDiscountCodeNameChoosen}
          hasDiscountPoolIdOnItem={hasDiscountPoolIdOnItem}
          onSuccess={isEditingInlineDiscountCode ? handleUpdateDiscountUrl : handleAddDiscount}
          onCreatePoolOnDashboard={handleCreatePoolOnDashboard}
        />
      )
    }

    function renderDiscountCodeExpiredContent(): JSX.Element {
      return (
        <PopoverDiscountCodeExpired
          discountCodeName={discountCodeNameManualReview}
          onRemoveDiscountCode={handleRemoveExpiredDiscountCode}
        />
      )
    }

    function renderTooltip() {
      return (
        <div className='p-1'>
          <p>{tooltipUrl}</p>
          {tooltipDiscountCode && <p>{`Applied discount code: ${tooltipDiscountCode}`} </p>}
          {tooltipDiscountPoolName && (
            <p>{`Selected unique discount code pool: ${tooltipDiscountPoolName}`} </p>
          )}
        </div>
      )
    }

    const button = (
      <Tooltip placement='topLeft' title='Add discount code' arrowPointAtCenter>
        <div
          className={styles.addonContainer}
          data-testid='add-discount-button'
          ref={discountTriggerRef}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClick={toggleDiscount}
        >
          <div className={styles.discountCodeIcon}>%</div>
        </div>
      </Tooltip>
    )

    const popover = (
      <>
        <Popover
          destroyTooltipOnHide
          align={{ offset: discountPopupOffset }}
          open={expiredDiscountCodes.includes(discountCodeNameManualReview)}
          placement='bottom'
          autoAdjustOverflow={false}
          trigger='click'
          content={renderDiscountCodeExpiredContent}
          onOpenChange={handleExpiredDiscountCodeVisibilityChange}
        />
        <Popover
          open={showDiscountCodeFormPopover}
          align={{ offset: discountPopupOffset }}
          destroyTooltipOnHide
          placement='bottom'
          autoAdjustOverflow={false}
          trigger='click'
          content={renderDiscountCodeFormContent}
          onOpenChange={handleDiscountCodeFormVisibilityChange}
        />
      </>
    )

    const pluginComponent: InlineEditorPlugin = {
      name: 'discount-input',
      button,
      popover
    }

    useEffect(() => {
      if (props.messageMeta === undefined) {
        return
      }
      const variables = getVariablesFromRawValue(rawValue)
      for (let variableIndex = 0; variableIndex < variables.length; variableIndex++) {
        const variable = variables[variableIndex]
        const variableMeta = props.messageMeta.variables[variableIndex]
        if (variableMeta?.manualReview) {
          setDiscountCodeNameManualReview(variable.params.discount_code)
          setIsEditingInlineDiscountCode(true)
          if (!expiredDiscountCodes.includes(variable.params.discount_code)) {
            setShowDiscountCodeFormPopover(true)
          }
          const element = getElementByVariableIndex(html, variableIndex)
          if (element === null || element === undefined) {
            continue
          }
          setInlineDiscountPopupPosition(element.getBoundingClientRect())
          return
        }
        if (
          (variableMeta?.discountCodePartiallyDeleted ?? 'none') !== 'none' &&
          partiallyDeletedDiscountCode === undefined
        ) {
          setPartiallyDeletedDiscountCode({
            discountVariable: variable,
            deletionType: variableMeta?.discountCodePartiallyDeleted
          })
        }
      }
      setDiscountCodeNameManualReview(undefined)
    }, [rawValue, props.messageMeta?.variables, setInlineDiscountPopupPosition, html])

    function handleDeletedModalOk(discountCodeVariable: Variable) {
      closeDiscountCodeForm()
      handleRemoveDiscountCode(discountCodeVariable)
    }

    function handleDeletedModalCancel() {
      setPartiallyDeletedDiscountCode(undefined)
    }

    useEffect(() => {
      if (props.messageMeta !== undefined) {
        const variables = getVariablesFromRawValue(rawValue)
        const variableErrors = variables.map((_, variableIndex) => {
          const variableMeta = props.messageMeta?.variables[variableIndex]
          return variableMeta?.error === true
        })
        setVariableErrors(variableErrors)
      }
    }, [rawValue, props.messageMeta?.variables])

    return (
      <Tooltip
        placement='top'
        title={renderTooltip}
        open={isTooltipOpen}
        align={{ offset: tooltipOffset }}
      >
        <DiscountCodeDeletedModal
          open={partiallyDeletedDiscountCode !== undefined}
          onOk={handleDeletedModalOk}
          onCancel={handleDeletedModalCancel}
          discountVariable={partiallyDeletedDiscountCode?.discountVariable}
          deletionType={partiallyDeletedDiscountCode?.deletionType}
        />
        <CreatePoolOnDashboardModal
          open={createPoolModalVisible}
          onFinished={handleCreatePoolModalFinished}
        />
        <WrappedComponent
          {...props}
          {...handlers}
          editorRef={editorRef}
          onClick={handleClick}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          html={html}
          rawValue={rawValue}
          plugins={(props.plugins ?? []).concat(pluginComponent)}
        />
      </Tooltip>
    )
  }

  return MakeDiscountCodeUnique
}
