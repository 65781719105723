import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as ExclamationIcon } from '../../../../static/ui-kit/icons/normal/exclamation.svg'
import { selectErrorCount } from '../../../../store/selectors'
import { useSaveSequenceEditor } from '../../hooks/use-save-sequence'
import { Toast } from '../../../../components/ui-kit/toast/toast.component'
import styles from './create-pool-on-dashboard-modal.scss'
import { EditorButton } from '../../../../components/ui-kit/editor-button/editor-button.component'
import { Modal } from '../../../../components/ui-kit/modal/modal.component'

interface CreatePoolOnDashboardModalProps {
  open: boolean
  onFinished: (goToDashboard: boolean) => void
}

export function CreatePoolOnDashboardModal(props: CreatePoolOnDashboardModalProps) {
  const errorCount = useSelector(selectErrorCount)
  const { saveSequenceEditor } = useSaveSequenceEditor()
  const [isSaving, setIsSaving] = useState(false)

  function handleCancel() {
    props.onFinished(false)
  }

  function handleClose() {
    props.onFinished(false)
  }

  async function handleOk() {
    setIsSaving(true)
    try {
      await saveSequenceEditor()
      Toast({
        type: 'success',
        key: `toast-draft-saved`,
        content: 'Your campaign is saved as draft.'
      })
      props.onFinished(true)
    } catch (err) {
      setIsSaving(false)
      props.onFinished(false)
    }
  }

  function renderModalContent() {
    return (
      <div data-testid='editor-blast-exit-modal' className={styles.modalContainer}>
        <div className='flex flex-row'>
          <div className='mr-3 icon-sm'>
            <ExclamationIcon />
          </div>
          <div>
            <div className={styles.modalTitle}>You are leaving the editor</div>
            <span className={styles.modalText}>
              You will be redirected to the Discount page to create a new discount code pool.
            </span>
          </div>
        </div>
        <div className='flex justify-end mt-4 items-center'>
          <EditorButton onClick={handleCancel} type='text'>
            Back to editor
          </EditorButton>
          <EditorButton type='primary' onClick={handleOk} disabled={errorCount > 0 || isSaving}>
            Save and create pool
          </EditorButton>
        </div>
      </div>
    )
  }

  return (
    <Modal
      open={props.open}
      onCancel={handleClose}
      width={580}
      className={styles.blastModalContainer}
      centered
      maskClosable
      closable
      footer={null}
    >
      {renderModalContent()}
    </Modal>
  )
}
