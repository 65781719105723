// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oLGaFxU3pKuESgUXEi3Q{display:flex;justify-content:center}.oLGaFxU3pKuESgUXEi3Q .glcbnaLmFZ3NfmQ58bdE{box-sizing:border-box;margin:0 10px}.oLGaFxU3pKuESgUXEi3Q .glcbnaLmFZ3NfmQ58bdE input[type=number]::-webkit-inner-spin-button,.oLGaFxU3pKuESgUXEi3Q .glcbnaLmFZ3NfmQ58bdE input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}.oLGaFxU3pKuESgUXEi3Q .glcbnaLmFZ3NfmQ58bdE .ant-form-item{margin-bottom:14px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-item-node-widget/delay-node-widget/delay-sequence-item-node-widget.component.scss"],"names":[],"mappings":"AAGE,sBACE,YAAA,CACA,sBAAA,CAEA,4CACE,qBAAA,CACA,aAAA,CAEA,oLAGE,uBAAA,CACA,QAAA,CAGF,2DACE,kBAAA","sourcesContent":["@use '../../../sequence-editor' as *;\n\n:local {\n  .inputWrapper {\n    display: flex;\n    justify-content: center;\n\n    .input {\n      box-sizing: border-box;\n      margin: 0 10px;\n\n      input[type='number']::-webkit-inner-spin-button,\n      input[type='number']::-webkit-outer-spin-button {\n        /* stylelint-disable-next-line property-no-vendor-prefix */\n        -webkit-appearance: none;\n        margin: 0;\n      }\n\n      :global(.ant-form-item) {\n        margin-bottom: 14px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `oLGaFxU3pKuESgUXEi3Q`,
	"input": `glcbnaLmFZ3NfmQ58bdE`
};
export default ___CSS_LOADER_EXPORT___;
