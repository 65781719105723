import { Form, Space } from 'antd'
import type { ValidateStatus } from 'antd/lib/form/FormItem'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type SequenceValidator } from '../../../../components/sequences-list/cols/types/sequence-validator.type'
import { Button } from '../../../../components/ui-kit/button/button.component'
import { Input } from '../../../../components/ui-kit/input/input.component'
import { ReactComponent as ExclamationIcon } from '../../../../static/ui-kit/icons/normal/exclamation.svg'
import { type AppDispatch } from '../../../../store/create-store'
import { updateSequenceName } from '../../../../store/modules/sequence/sequence.thunks'
import { selectEditorSequence, selectEditorSequenceMeta } from '../../../../store/selectors'
import { useSaveModal } from '../../hooks/use-save-modal'
import { useSaveSequenceEditor } from '../../hooks/use-save-sequence'
import { useSequenceEditorSettings } from '../../hooks/use-sequence-editor-settings'
import { useToggleSequence } from '../../hooks/use-toggle-sequence'
import styles from './save-modal.component.scss'
import { Modal } from '../../../../components/ui-kit/modal/modal.component'

export interface SaveModalProps {
  title?: string
  headerText?: string
  inputDescription?: string
  inputComment?: string
  note?: string
  disableMaskCloseable?: boolean
  disableSaveAndEnable?: boolean
  validator?: SequenceValidator
}

export function SaveModal(props: SaveModalProps) {
  const sequence = useSelector(selectEditorSequence)
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const { isSaveModalOpen, hideSaveModal } = useSaveModal()
  const { saveSequenceEditor } = useSaveSequenceEditor()
  const { toggleSequence } = useToggleSequence()
  const editorSettings = useSequenceEditorSettings()

  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  interface InputField {
    value: string
    validateStatus: ValidateStatus
    errorMessage: string
  }

  const [inputField, setInputField] = useState<InputField>({
    value: sequence.name.startsWith('copy_') ? sequence.name : '',
    validateStatus: '',
    errorMessage: null
  })

  useEffect(() => {
    if (sequenceMeta.error) {
      hideSaveModal()
    }
  }, [sequenceMeta.error, hideSaveModal])

  useEffect(() => {
    const isEmptyOrInvalid = inputField.value === '' || inputField.errorMessage !== null
    setIsSaveDisabled(isEmptyOrInvalid)
  }, [inputField])

  const fullWidthLayout = {
    labelCol: {
      xs: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 }
    }
  }

  async function handleSaveDraft() {
    dispatch(updateSequenceName(sequence._id, inputField.value))
    try {
      await saveSequenceEditor()
    } catch {
    } finally {
      hideSaveModal()
    }
  }

  function handleSaveAndEnable() {
    dispatch(updateSequenceName(sequence._id, inputField.value))
    toggleSequence()
    hideSaveModal()
  }

  function handleCancel() {
    hideSaveModal()
  }

  function validateSequenceName(sequenceName: string): Omit<InputField, 'value'> {
    const renamedSequence = {
      ...sequence,
      name: sequenceName
    }

    if (props.validator) {
      const errorMessage = props.validator(renamedSequence)
      if (errorMessage !== null) {
        return {
          validateStatus: 'error',
          errorMessage
        }
      }
    }

    if (sequenceName.trim() === '') {
      return {
        validateStatus: 'error',
        errorMessage: `It's not a valid flow name!`
      }
    }
    return {
      validateStatus: 'success',
      errorMessage: null
    }
  }

  function handleSaveModalInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setInputField({
      ...validateSequenceName(value),
      value
    })
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter' || isSaveDisabled) {
      return
    }
    if (!props.disableSaveAndEnable) {
      handleSaveAndEnable()
    }
  }

  function renderModalContent() {
    return (
      <div data-testid='se-save-modal' className='p-2'>
        {props.title && (
          <Space size={16} direction='horizontal' className='w-full mb-4'>
            <ExclamationIcon className='icon-sm text-primary-blue-1' />
            <div className='text-lg mb-2'>{props.title}</div>
          </Space>
        )}
        {props.headerText && <div>{props.headerText}</div>}
        <Form>
          <Form.Item
            {...fullWidthLayout}
            labelAlign='left'
            colon={false}
            validateStatus={inputField.validateStatus}
            help={inputField.errorMessage || ''}
            hasFeedback
            className='wrapped-form-item-no-margin'
          >
            {props.inputDescription && <span>{props.inputDescription}</span>}
            <Input
              defaultValue={sequence.name.startsWith('copy_') ? sequence.name : ''}
              onChange={handleSaveModalInputChange}
              onKeyPress={handleKeyPress}
              size='large'
            />
            {props.inputComment && (
              <span className={styles.inputComment}>{props.inputComment}</span>
            )}
          </Form.Item>
        </Form>
        {props.note && (
          <div className='text-sm mb-4'>
            <span className={styles.noteLabel}>Note:</span>{' '}
            <span className={styles.note}>{props.note}</span>
          </div>
        )}
        <Space size={16} direction='horizontal' className='w-full flex justify-end items-center'>
          <Button
            disabled={sequenceMeta.savingMode !== undefined}
            onClick={handleCancel}
            type='link'
          >
            Back to editing
          </Button>
          {editorSettings.showSaveDraftButton && (
            <Button
              disabled={isSaveDisabled || sequenceMeta.savingMode !== undefined}
              className={styles['button-margin']}
              onClick={handleSaveDraft}
              loading={sequenceMeta.savingMode === 'draft'}
              data-testid='sequence-editor-save-as-draft'
              type='default'
            >
              Save as draft
            </Button>
          )}
          <Button
            type='primary'
            disabled={
              props.disableSaveAndEnable || isSaveDisabled || sequenceMeta.savingMode !== undefined
            }
            onClick={handleSaveAndEnable}
            loading={sequenceMeta.savingMode === 'active'}
          >
            {editorSettings.showSaveDraftButton ? 'Save & Activate' : 'Save'}
          </Button>
        </Space>
      </div>
    )
  }

  return (
    <Modal
      open={isSaveModalOpen}
      footer={null}
      centered
      onCancel={handleCancel}
      maskClosable={!props.disableMaskCloseable}
      data-testid='sequence-editor-save-modal'
      closable={false}
    >
      {renderModalContent()}
    </Modal>
  )
}
