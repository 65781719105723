import { Modal, notification } from 'antd'
import type { DashboardState } from '../../store/dashboard.state'
import { getGlobalStore } from '../../store/global-store'
import {
  type NotificationType,
  resetNotifications
} from '../../store/slices/notification/notification.reducer'
import {
  critical,
  error,
  info,
  staticError,
  staticInfo,
  staticWarn,
  warn
} from '../../store/slices/notification/notification.thunks'

type NotificationThunk = (...args: any[]) => any

function wrapThunk<T extends NotificationThunk>(thunk: T) {
  return (...args: Parameters<T>): ReturnType<ReturnType<T>> => {
    const store = getGlobalStore()
    return store.dispatch(thunk(...args))
  }
}

function reset(): void {
  const store = getGlobalStore()
  Modal.destroyAll()
  notification.destroy()
  store.dispatch(resetNotifications())
}

function isVisibleNotification(type: NotificationType): boolean {
  const store = getGlobalStore()
  const state: DashboardState = store.getState()
  return state.notification.notifications.some(
    (_notification) => _notification.type === type && _notification.visible
  )
}

export const Notification = {
  critical: wrapThunk(critical),
  error: wrapThunk(error),
  warn: wrapThunk(warn),
  info: wrapThunk(info),
  staticError: wrapThunk(staticError),
  staticWarn: wrapThunk(staticWarn),
  staticInfo: wrapThunk(staticInfo),
  reset,
  isVisibleNotification
}
