import { type DelayFlowItemUI } from '@ghostmonitor/recartapis/cjs/typescript-types/flow-item-ui.type'
import {
  validateDelay,
  validateDelayFlowItemMustHaveConnection,
  validateScheduledDelay
} from '../../../../../../utils/validators/flow/validate-delay'
import { type DashboardState } from '../../../../../dashboard.state'
import { flowEditorSelectors } from '../../../../../selectors'
import { type ItemValidationRunner } from '../validator-factory'

export function delayValidator(
  flowItem: DelayFlowItemUI,
  state: DashboardState
): ItemValidationRunner {
  return {
    getConfiguredValidators: () => ({
      flowItemContent: {
        validate: async () => {
          const error = validateDelay(flowItem.item.logic.delay.delay_duration)
          return error !== undefined ? [error] : []
        }
      },
      logicFlowItemConnections: {
        validate: async () => {
          const error = validateDelayFlowItemMustHaveConnection(flowItem)
          return error !== undefined ? [error] : []
        }
      },
      scheduledDelay: {
        validate: async () => {
          const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)
          const flow = flowEditorSelectors.selectEditorFlow(state)

          if (flow === undefined) {
            throw new Error('Flow are required')
          }

          const smsCampaignScheduledFor = smsCampaign?.scheduledFor ?? new Date().toISOString()

          const error = validateScheduledDelay(flowItem, flow, smsCampaignScheduledFor)

          return error !== undefined ? [error] : []
        }
      }
    })
  }
}
