import { type FlowItemAPI, type FlowItemUI, type FlowUI, LogicType } from '@ghostmonitor/recartapis'
import {
  isConditionalSplitFlowItemUI,
  isConditionalSplitV2FlowItemUI,
  isDelayFlowItemUI,
  isLogicFlowItemUI,
  isRandomSplitFlowItemUI,
  isSMSMessageFlowItemUI
} from '../guards/flow-item-ui.guards'
import { convertExpressionUIToAPI } from '../segment/converters/ui-to-api/segment'

export function convertFlowItemUIToFlowItem(flowItemUI: FlowItemUI, flow: FlowUI): FlowItemAPI {
  if (isLogicFlowItemUI(flowItemUI)) {
    if (isRandomSplitFlowItemUI(flowItemUI)) {
      return {
        ...flowItemUI,
        isEnabled: flow.isEnabled
      }
    }

    if (isDelayFlowItemUI(flowItemUI)) {
      return {
        ...flowItemUI,
        isEnabled: flow.isEnabled
      }
    }

    if (isConditionalSplitFlowItemUI(flowItemUI)) {
      return {
        ...flowItemUI,
        isEnabled: flow.isEnabled
      }
    }

    if (isConditionalSplitV2FlowItemUI(flowItemUI)) {
      return {
        ...flowItemUI,
        isEnabled: flowItemUI.isEnabled,
        item: {
          ...flowItemUI.item,
          logic: {
            ...flowItemUI.item.logic,
            conditionalSplitV2: {
              ...flowItemUI.item.logic.conditionalSplitV2,
              cases: flowItemUI.item.logic.conditionalSplitV2.cases.map((thisCase) => ({
                ...thisCase,
                expression: convertExpressionUIToAPI(thisCase.expression)
              }))
            },
            type: LogicType.CONDITIONAL_SPLIT_V2
          }
        }
      }
    }
  } else if (isSMSMessageFlowItemUI(flowItemUI)) {
    return {
      ...flowItemUI,
      isEnabled: flow.isEnabled
    }
  }

  throw new Error(`Unknown flow item type`)
}
