export enum ModalType {
  SAVE = 'save',
  BLAST_ACTIVATION = 'BLAST_ACTIVATION',
  SMS_CAMPAIGN_ACTIVATION = 'SMS_CAMPAIGN_ACTIVATION',

  SEND_SMS_TEST = 'SEND_SMS_TEST',
  DISCOUNT_POOL_SIZE = 'DISCOUNT_POOL_SIZE',
  DISCOUNT_POOL_AVAILABLE_COUNT = 'DISCOUNT_POOL_AVAILABLE_COUNT',
  CREATE_DISCOUNT_POOL_AND_SAVE_FLOW = 'CREATE_DISCOUNT_POOL_AND_SAVE_FLOW',
  BLOCK_CAMPAIGN_ON_TEMPORARY_TFN = 'BLOCK_CAMPAIGN_ON_TEMPORARY_TFN',

  EXPIRED_DISCOUNT_CODE = 'EXPIRED_DISCOUNT_CODE',

  EDIT_FLOW_NAME = 'EDIT_FLOW_NAME',
  SMS_CAMPAIGN_EDIT = 'SMS_CAMPAIGN_EDIT',

  LEAVE_EDITOR_MODAL = 'LEAVE_EDITOR_MODAL',

  SMS_PRICE_PER_COUNTRY_MODAL = 'SMS_PRICE_PER_COUNTRY_MODAL',

  SMS_USAGE_EXPORT_MODAL = 'SMS_USAGE_EXPORT_MODAL',
  SMS_TRIAL_LIMIT_REACHED_MODAL = 'SMS_TRIAL_LIMIT_REACHED_MODAL',

  ADD_AI_CAMPAIGN_WIZARD_MODAL = 'ADD_AI_CAMPAIGN_WIZARD_MODAL',
  AI_INSERT_SMS_FLOW_ITEM = 'AI_INSERT_SMS_FLOW_ITEM',

  AI_LOADING = 'AI_LOADING',

  ADD_PHONE_NUMBER = 'ADD_PHONE_NUMBER',
  WELCOME_FLOW_SAVE_WARNING_MODAL = 'WELCOME_FLOW_SAVE_WARNING_MODAL'
}
