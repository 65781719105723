import { createSelector, type Selector } from '@reduxjs/toolkit'
import type { AppIntegration } from '../../../routes/integrations/integrations.type'
import { type IntegrationsState } from './integrations.reducer'

export const selectIntegrationsBySlug = (state: IntegrationsState) => state.integrationsBySlug
export const selectIntegrationsMetaBySlug = (state: IntegrationsState) =>
  state.integrationsMetaBySlug

export const selectIntegration = <T extends AppIntegration>(
  integrationName: string
): Selector<IntegrationsState, T> =>
  createSelector(
    selectIntegrationsBySlug,
    (integrationsBySlug) => integrationsBySlug[integrationName]
  ) as any

export const selectIntegrationMeta = (integrationName: string) =>
  createSelector(
    selectIntegrationsMetaBySlug,
    (integrationsMetaBySlug) => integrationsMetaBySlug[integrationName]
  )
