import {
  type Blast,
  type LinkingMeta,
  type SequenceItemUI,
  type SequenceUI,
  type Stat
} from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { type SequenceEditorSettings } from '../../../routes/SequenceEditor/settings/context/sequence-editor-settings.type'
import { type TrayItem } from '../../../routes/SequenceEditor/types/tray-item.type'
import { type DashboardError } from '../../../types/errors'

export interface SequenceEditorInstance {
  sequence: SequenceUI
  unsavedChanges: boolean
  isEverSaved: boolean
  serverUpdatedAt: string
}

export const SequenceEditorInstanceInitialState: SequenceEditorInstance = {
  sequence: {
    _id: null,
    siteId: null,
    isEnabled: false,
    serializedDiagram: null,
    sequenceItemIds: [],
    name: 'New Sequence',
    tags: [],
    entrySequenceItemId: null,
    quickReplyIndexToId: {}
  },
  unsavedChanges: false,
  isEverSaved: false,
  serverUpdatedAt: null
}

export interface SequenceEditorInstancePatch {
  sequence?: Partial<SequenceUI>
  saved?: boolean
  showSaved?: boolean
  unsavedChanges?: boolean
  isEverSaved?: boolean
  serverUpdatedAt?: string
}

export const QuickReplyInitialMeta: QuickReplyMeta = {
  error: null,
  selected: false,
  edited: false
}

export interface QuickReplyMeta {
  error: string | null
  selected: boolean
  edited: boolean
}

export const ButtonInitialMeta: ButtonMeta = {
  error: null,
  selected: false
}

export interface ButtonMeta {
  error: string | null
  selected: boolean
}

export interface ButtonMetaDelta {
  error: string | null
  selected?: boolean
}

export const MessageItemInitialMeta: MessageItemMeta = {
  errors: [],
  selected: false,
  buttons: []
}

export interface MessageItemMeta {
  errors: SerializedError[]
  selected: boolean
  buttons: ButtonMeta[]
}

export interface MessageItemMetaDelta {
  error?: string
  selected?: boolean
  buttons?: ButtonMetaDelta[]
}

export const messageInitialMeta: MessageMeta = {
  activeIndex: 0,
  errors: [],
  selected: false,
  messageItems: [],
  buttons: [],
  variables: []
}

export interface MessageMeta {
  activeIndex: number
  errors: SerializedError[]
  selected?: boolean
  edited?: boolean
  messageItems: MessageItemMeta[]
  buttons: ButtonMeta[]
  variables: VariableMeta[]
}

export const variableInitialMeta: VariableMeta = {
  error: false,
  manualReview: false,
  discountCodePartiallyDeleted: 'none'
}

export interface VariableMeta {
  error: boolean
  manualReview: boolean
  discountCodePartiallyDeleted: DiscountCodePartialDeletionType
}

export interface MessageMetaDelta {
  error?: string
  selected?: boolean
  messageItems?: MessageItemMetaDelta[]
  buttons?: ButtonMetaDelta[]
}

export interface SplitMeta {
  error: string | null
  edited: boolean
}

export const splitInitialMeta: SplitMeta = {
  error: null,
  edited: false
}

export interface SequenceItemMeta {
  error: string | null
  errorCount: number
  saved: boolean
  saving?: boolean
  selected?: boolean
  edited?: boolean
  messages: MessageMeta[]
  quickReplies: QuickReplyMeta[]
  splits: SplitMeta[]
}

export const sequenceItemInitialMeta: SequenceItemMeta = {
  error: null,
  errorCount: 0,
  saved: false,
  saving: false,
  selected: false,
  edited: false,
  messages: [],
  quickReplies: [],
  splits: []
}

export type DiscountCodePartialDeletionType = 'none' | 'plain' | 'url'

export interface SequenceMeta {
  error?: SerializedError
  errors: DashboardError[]
  unsavedChanges: boolean
  isEverSaved: boolean
  // Before save, all quick replies get touched, so validation will run on them, even if user didn't edit
  quickRepliesTouched: boolean
  sequenceItemsTouched: boolean
  splitsTouched: boolean
  saving: boolean
  sendingTest: boolean
  toggling: boolean
  toggleClicked: boolean
  sequenceItemCountToValidate: number
  stats: Stat
  statsLoading: boolean
  savingMode?: 'active' | 'draft'
  invalidDiscountCodes: string[]
  validDiscountCodes: string[]
  expiredDiscountCodes: string[]
  discountCodeTour: boolean | null
  waitForMessageChange: boolean
}

export interface FormMeta {
  error: SerializedError | null
  edited: boolean | null
}

export interface BlastMeta {
  name: FormMeta
  scheduledFor: FormMeta
}

export const blastInitialMeta: BlastMeta = {
  name: {
    error: null,
    edited: null
  },
  scheduledFor: {
    error: null,
    edited: null
  }
}

export const sequenceInitialStat: Stat = {
  clicked: 0,
  clickRate: 0,
  converted: 0,
  cost: null,
  facebook: null,
  opened: 0,
  openRate: 0,
  optOutRate: {
    sms: 0
  },
  spamRate: {
    sms: 0
  },
  roi: 0,
  salesTotal: {
    USD: 0
  },
  sales: {
    totalUSD: 0
  },
  sent: 0,
  sentToPSID: 0,
  sentToUserRef: 0
}

export const sequenceInitialMeta: SequenceMeta = {
  errors: [],
  unsavedChanges: false,
  isEverSaved: false,
  quickRepliesTouched: false,
  sequenceItemsTouched: false,
  splitsTouched: false,
  saving: false,
  sendingTest: false,
  toggling: false,
  toggleClicked: false,
  sequenceItemCountToValidate: 0,
  stats: sequenceInitialStat,
  statsLoading: false,
  invalidDiscountCodes: [],
  validDiscountCodes: [],
  expiredDiscountCodes: [],
  discountCodeTour: null,
  waitForMessageChange: false
}

export interface SequenceEditorState {
  sequence: SequenceUI | null
  blast?: Blast | null
  blastMeta?: BlastMeta | null
  initializedSettings: SequenceEditorSettings[]
  isSettingsInitialized: boolean
  sequenceMeta: SequenceMeta | null
  sequenceItemsById: {
    [sequenceItemId: string]: SequenceItemUI
  }
  sequenceItemsMetaById: {
    [sequenceItemId: string]: SequenceItemMeta
  }
  quickRepliesMetaById: {
    [quickReplyId: string]: QuickReplyMeta
  }
  isReadOnly: boolean
  error: string | null
  errorCount: number
  linking: boolean
  linkingMeta: LinkingMeta
  draggedItemType: TrayItem | null
  showSequenceItemStatistics: boolean
  showDebugView?: boolean | null
  isSettingsDrawerVisible: boolean
}

export const sequenceEditorInitialState: SequenceEditorState = {
  sequence: null,
  blast: null,
  blastMeta: blastInitialMeta,
  initializedSettings: [],
  isSettingsInitialized: false,
  sequenceMeta: null,
  sequenceItemsById: {},
  sequenceItemsMetaById: {},
  quickRepliesMetaById: {},
  isReadOnly: false,
  error: null,
  errorCount: 0,
  linking: false,
  linkingMeta: {
    portType: null,
    portParentSequenceItemId: null
  },
  draggedItemType: null,
  showSequenceItemStatistics: true,
  showDebugView: null,
  isSettingsDrawerVisible: false
}
