import { type PhoneNumberButton } from '@ghostmonitor/recartapis'
import { ControlledTextField } from '../controlled-text-field.component'
import { type ButtonPopoverFormProps } from '../types/button-popover-form.type'

export function PhoneNumberPopoverForm(props: ButtonPopoverFormProps<PhoneNumberButton>) {
  return (
    <ControlledTextField
      label='Button phone number'
      type='tel'
      placeholder='+15105559999'
      value={props.payload.phone_number}
      onChange={(num) => {
        props.onChange({ phone_number: num })
      }}
      onBlur={props.onBlur}
    />
  )
}
