import { type ConditionalSplitV2FlowItemUI, type ExpressionUI } from '@ghostmonitor/recartapis'
import { type ItemValidationRunner } from '../validator-factory'
import { validateExpression } from '../../../../../../utils/validators/flow/validate-expression'
import { type FlowEditorValidationError } from '../../../../../../utils/flow-editor/flow-editor-errors'
import { validateConditionalSplitV2FlowItemMustHaveConnection } from '../../../../../../utils/validators/flow/validate-conditional-split-v2'

export function conditionalSplitV2Validator(
  flowItem: ConditionalSplitV2FlowItemUI
): ItemValidationRunner {
  return {
    getConfiguredValidators: () => ({
      conditionExpression: {
        validate: async () => {
          const errors: FlowEditorValidationError[] = []
          for (const split of flowItem.item.logic.conditionalSplitV2.cases) {
            const error = await validateExpression(split.expression as ExpressionUI)
            if (error !== undefined) {
              errors.push(error)
            }
          }

          return errors
        }
      },
      logicFlowItemConnections: {
        validate: async () => {
          const error = validateConditionalSplitV2FlowItemMustHaveConnection(flowItem)
          return error !== undefined ? [error] : []
        }
      }
    })
  }
}
