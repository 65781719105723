export const QUERY_NAME = {
  achievements: 'achievements',
  apiKeys: 'apiKeys',
  availableCountries: 'availableCountries',
  backfills: 'backfills',
  billingSubscriptions: 'billingSubscriptions',
  billingHistory: 'billingHistory',
  blasts: 'blasts',
  callForwarding: 'callForwarding',
  currentSmsPrice: 'currentSmsPrice',
  currentPhoneNumbersPrices: 'currentPhoneNumbersPrices',
  countries: 'countries',
  contactCardSettings: 'contactCardSettings',
  currentBillingPeriods: 'currentBillingPeriods',
  discountCodes: 'discountCodes',
  discountCodePools: 'discountCodePools',
  discountCodePool: 'discountCodePool',
  discountCodeExists: 'discountCodeExists',
  emailStat: 'emailStat',
  emailStatNoContext: 'emailStatNoContext',
  estimatedCost: 'estimatedCost',
  facebookPage: 'facebookPage',
  facebookPost: 'facebookPost',
  flows: 'flows',
  flow: 'flow',
  hasUsedFacebookPage: 'hasUsedFacebookPage',
  integration: 'integration',
  integrations: 'integrations',
  landingPageCode: 'landing-page-code',
  leasablePhoneNumber: 'leasablePhoneNumber',
  leasablePhoneNumbers: 'leasablePhoneNumbers',
  leasablePhoneNumbersCount: 'leasablePhoneNumbersCount',
  me: 'me',
  mediaUploads: 'mediaUploads',
  messengerStat: 'messengerStat',
  optinTool: 'optinTool',
  optinToolExperiments: 'optinToolExperiments',
  optinToolFonts: 'optinToolFonts',
  optinToolsList: 'optinToolsList',
  optinToolsStat: 'optinToolsStat',
  orderTags: 'orderTags',
  phoneNumbers: 'phoneNumbers',
  product: 'product',
  products: 'products',
  productsById: 'productsById',
  productTags: 'productTags',
  productTypes: 'productTypes',
  productVariants: 'productVariants',
  productVariantsById: 'productVariantsById',
  productImages: 'productImages',
  reservedKeywords: 'reservedKeywords',
  responseHeaders: 'responseHeaders',
  rotateWebhookSecret: 'rotateWebhookSecret',
  salesStackedChart: 'salesStackedChart',
  segment: 'segment',
  segmentsList: 'segmentsList',
  AIsegmentsList: 'AIsegmentsList',
  sequenceGroupsStat: 'sequenceGroupsStat',
  sequenceItem: 'sequenceItem',
  sequenceItemsStat: 'sequenceItemsStat',
  sequences: 'sequences',
  sequencesStat: 'sequencesStat',
  site: 'site',
  smsSettings: 'smsSettings',
  smsCampaign: 'smsCampaign',
  smsQuietHoursSettings: 'smsQuietHoursSettings',
  smsSmartSendingSettings: 'smsSmartSendingSettings',
  smsStat: 'smsStat',
  smsConversations: 'smsConversations',
  smsBalance: 'smsBalance',
  smsUsage: 'smsUsage',
  smsTimezoneDeviation: 'smsTimezoneDeviation',
  subdomains: 'subdomains',
  subdomainsAvailability: 'subdomainsAvailability',
  subscriber: 'subscriber',
  subscriberImportList: 'subscriberImportList',
  subscriberImportSample: 'subscriberImportSample',
  subscribersStackedChart: 'subscribersStackedChart',
  subscribersList: 'subscribersList',
  subscribersOverview: 'subscribersOverview',
  subscribersTotal: 'subscribersTotal',
  subscribersCount: 'subscribersCount',
  subscriptionSourcesStat: 'subscriptionSourcesStat',
  subscriberCleanups: 'subscriberCleanups',
  subscriberDetails: 'subscriberDetails',
  templatesPopularity: 'templatesPopularity',
  testPhoneNumbers: 'testPhoneNumbers',
  user: 'user',
  isSpmValid: 'isSpmValid',
  isTosAccepted: 'isTosAccepted',
  pricingLevels: 'pricingLevels',
  shopifyThemeExtension: 'shopifyThemeExtension'
}
