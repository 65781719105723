import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useMutation, useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_NAME } from './query-names'

export type PatchSMSQuietHoursSettingRequest = Omit<
  SMSSettingsService.UpsertQuietHoursSettingsRequest,
  'siteId'
>

async function patchSMSQuietHoursSettings(
  patchPayload: PatchSMSQuietHoursSettingRequest
): Promise<void> {
  await api.patchSMSQuietHoursSettings(patchPayload)
}

export function useSMSQuietHoursSettings(
  options?: UseQueryOptions<SMSSettingsService.GetQuietHoursSettingsResponse>
): UseResource<SMSSettings.QuietHoursConfig> {
  const queryName = [QUERY_NAME.smsQuietHoursSettings]
  const { handleError, handlePatch, handleSettled } =
    useMutationHelpers<PatchSMSQuietHoursSettingRequest>(queryName)
  const {
    isLoading,
    data,
    error: requestError
  } = useQuery<SMSSettingsService.GetQuietHoursSettingsResponse>(
    queryName,
    api.getSMSQuietHoursSettings,
    options
  )
  const error: SerializedError = requestError

  const { mutateAsync: mutatePatchSMSQuietHoursSettings } = useMutation<
    void,
    unknown,
    PatchSMSQuietHoursSettingRequest
  >(patchSMSQuietHoursSettings, {
    onMutate: handlePatch,
    onError: handleError,
    onSettled: handleSettled
  })

  return {
    data: data?.data,
    patch: mutatePatchSMSQuietHoursSettings,
    error,
    isLoading
  }
}
