import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsModalOpen } from '../../../store/selectors'
import { hideModal, showModal } from '../../../store/slices/modal/modal.reducer'
import { ModalType } from '../../../store/slices/modal/modal.type'

const selectIsSaveModalOpen = selectIsModalOpen(ModalType.SAVE)

export function useSaveModal() {
  const isSaveModalOpen = useSelector(selectIsSaveModalOpen)
  const dispatch = useDispatch()

  const showSaveModal = useCallback(
    () => dispatch(showModal({ modalType: ModalType.SAVE })),
    [dispatch]
  )

  const hideSaveModal = useCallback(
    () => dispatch(hideModal({ modalType: ModalType.SAVE })),
    [dispatch]
  )

  return {
    isSaveModalOpen,
    showSaveModal,
    hideSaveModal
  }
}
