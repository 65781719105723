import { Button, Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { webURLRegexp } from '../../../../../../utils/regexps/web-url-regexp'
import styles from './link-shortening-popover.component.scss'

export interface LinkShorteningPopoverProps {
  title: string
  buttonLabel: string
  defaultLink?: string
  onLinkChange: (link: string) => void
  onClose: () => void
}

export function LinkShorteningPopover(props: LinkShorteningPopoverProps) {
  const [link, setLink] = useState<string>(props.defaultLink)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  const [form] = Form.useForm()

  useEffect(() => {
    setLink(props.defaultLink)
  }, [props.defaultLink])

  useEffect(() => {
    if (props.defaultLink === link) setSaveButtonDisabled(true)
  }, [props.defaultLink, link])

  function handleClose() {
    props.onClose()
  }

  async function handleFinish(values: { link: string }) {
    props.onLinkChange(values.link.trim())
    setLink(values.link.trim())
  }

  function handleFinishFailed(errorInfo) {
    if (errorInfo.errorFields.length === 0) {
      setSaveButtonDisabled(false)
    } else {
      setSaveButtonDisabled(true)
    }
  }

  function handleOnFieldsChange(changedFields: any[], allFields: any[]) {
    const hasTouchedField = allFields.some((field) => field.touched)
    const hasErrors = allFields.some((field) => field.errors.length)
    setSaveButtonDisabled(hasErrors || !hasTouchedField)
  }

  return (
    <div
      data-testid='link-shortening-popover-container'
      className={styles.container}
      onMouseDown={(event) => {
        event.stopPropagation()
      }}
    >
      <div className='font-bold mt-1'>{props.title}</div>
      <div className='text-tiny mt-1 mb-3'>Link shortening saves characters and money for you.</div>
      <div className='text-tiny mb-3'>Paste your link here</div>
      <Form
        layout='vertical'
        form={form}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onFieldsChange={handleOnFieldsChange}
        requiredMark={false}
        validateTrigger={['onBlur', 'onChange']}
        colon={false}
        labelAlign='left'
        initialValues={{
          link
        }}
      >
        <Form.Item
          labelAlign='left'
          colon={false}
          data-testid='link-shortening-input-label'
          name='link'
          rules={[
            {
              validator: (_, value) => {
                const trimmedValue = value.trim()
                return webURLRegexp.test(trimmedValue)
                  ? Promise.resolve()
                  : Promise.reject(new Error('Please enter a valid URL.'))
              }
            }
          ]}
        >
          <Input data-testid='link-shortening-input' size='middle' />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.saveButton}
            data-testid='link-shortening-popover-add-button'
            type='primary'
            htmlType='submit'
            disabled={saveButtonDisabled}
          >
            {props.buttonLabel}
          </Button>
        </Form.Item>
      </Form>
      <div className='flex text-center'>
        <Button
          className={styles.backButton}
          type='link'
          block
          onClick={handleClose}
          data-testid='link-shortening-popover-cancel-button'
        >
          Back
        </Button>
      </div>
    </div>
  )
}
