import {
  type ConditionalSplitFlowItemUI,
  type ConditionalSplitV2FlowItemUI,
  type DelayFlowItemUI,
  FlowItemType,
  type FlowItemUI,
  type LogicFlowItemUI,
  LogicType,
  type RandomSplitFlowItemUI,
  type SMSMessageFlowItemUI
} from '@ghostmonitor/recartapis'
import isPlainObject from 'lodash/isPlainObject'

export function isSMSMessageFlowItemUI(FlowItem: FlowItemUI): FlowItem is SMSMessageFlowItemUI {
  return isPlainObject(FlowItem) && FlowItem.itemType === FlowItemType.SMS
}

export function hasMessageOnFlowItemType(flowItem: FlowItemUI): flowItem is SMSMessageFlowItemUI {
  return isSMSMessageFlowItemUI(flowItem)
}

export function hasKeywordRepliesOnFlowItem(
  flowItem: FlowItemUI
): flowItem is SMSMessageFlowItemUI {
  return (
    isSMSMessageFlowItemUI(flowItem) &&
    flowItem.item.replyKeywordSettings &&
    flowItem.item.replyKeywordSettings.keywords.length > 0
  )
}

export function isLogicFlowItemUI(FlowItem: FlowItemUI): FlowItem is LogicFlowItemUI {
  return isPlainObject(FlowItem) && FlowItem.itemType === FlowItemType.LOGIC
}

export function isDelayFlowItemUI(FlowItem: FlowItemUI): FlowItem is DelayFlowItemUI {
  return (
    isPlainObject(FlowItem) &&
    isLogicFlowItemUI(FlowItem) &&
    FlowItem.item?.logic?.type === LogicType.DELAY
  )
}

export function isConditionalSplitFlowItemUI(
  FlowItem: FlowItemUI
): FlowItem is ConditionalSplitFlowItemUI {
  return (
    isPlainObject(FlowItem) &&
    isLogicFlowItemUI(FlowItem) &&
    FlowItem.item?.logic?.type === LogicType.CONDITIONAL_SPLIT
  )
}

export function isConditionalSplitV2FlowItemUI(
  FlowItem: FlowItemUI
): FlowItem is ConditionalSplitV2FlowItemUI {
  return (
    isPlainObject(FlowItem) &&
    isLogicFlowItemUI(FlowItem) &&
    FlowItem.item?.logic?.type === LogicType.CONDITIONAL_SPLIT_V2
  )
}

export function isRandomSplitFlowItemUI(FlowItem: FlowItemUI): FlowItem is RandomSplitFlowItemUI {
  return (
    isPlainObject(FlowItem) &&
    isLogicFlowItemUI(FlowItem) &&
    FlowItem.item?.logic?.type === LogicType.RANDOM_SPLIT
  )
}
