import { CheckOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectEditorSequenceMeta, selectErrorCount } from '../../../../../store/selectors'
import styles from './header-status-text.component.scss'

export function HeaderStatusText() {
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const errorCount = useSelector(selectErrorCount)

  function renderHeaderStatusText() {
    if (errorCount > 0) {
      return (
        <div className={styles.unsaved}>{`You have ${errorCount} error${
          errorCount > 1 ? 's' : ''
        } in your editor.`}</div>
      )
    }

    if (sequenceMeta.unsavedChanges) {
      return <div className={styles.unsaved}>You have unsaved changes.</div>
    }

    return (
      <div className={styles.saved}>
        <CheckOutlined />
        <span className={styles.savedLabel}>Everything is saved</span>
      </div>
    )
  }

  return (
    <div className='flex items-center' data-testid='header-status-text'>
      {renderHeaderStatusText()}
    </div>
  )
}
