import { type Stat } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import {
  useSequenceItemsStat,
  useSequenceItemsStatCache
} from '../../../hooks/resources/use-sequence-items-stat'
import { type UseResourceCache } from '../../../hooks/types/use-resource-cache.type'
import { type UseResource } from '../../../hooks/types/use-resource.type'
import { flowEditorSelectors } from '../../../store/selectors'

export interface FlowItemsStatsById {
  [flowItemId: string]: Stat
}

export function useFlowEditorFlowItemsStat(): UseResource<FlowItemsStatsById> {
  const flow = useSelector(flowEditorSelectors.selectEditorFlow)
  const flowMeta = useSelector(flowEditorSelectors.selectEditorFlowMeta)

  if (!flow) {
    throw new Error('No flow can be found in the editor')
  }

  const { data, isLoading, isError } = useSequenceItemsStat(
    flow?._id,
    flowMeta?.isEverSaved === true
  )

  return {
    data,
    isLoading,
    isError
  }
}
export function useFlowEditorFlowItemsStatCache(): UseResourceCache {
  const flow = useSelector(flowEditorSelectors.selectEditorFlow)
  if (!flow) {
    throw new Error('No flow can be found in the editor')
  }

  const { invalidateCache } = useSequenceItemsStatCache(flow?._id)
  return {
    invalidateCache
  }
}
