import { type Action } from '@reduxjs/toolkit'
import {
  blastScheduledChanged,
  type CreateSequenceItemAction,
  CREATE_BUTTON,
  CREATE_FOLLOW_UP_PORT,
  CREATE_MESSAGE,
  CREATE_MESSAGE_ITEM,
  CREATE_QUICK_REPLY,
  CREATE_SEQUENCE_ITEM,
  CREATE_SPLIT,
  leaveReadOnlyMode,
  mediaUploadCompleted,
  removeBlastTargetRule,
  type RemoveSequenceItemAction,
  REMOVE_BUTTON,
  REMOVE_MESSAGE,
  REMOVE_MESSAGE_ITEM,
  REMOVE_QUICK_REPLY,
  REMOVE_SEQUENCE_ITEM,
  REMOVE_SPLIT,
  renameBlast,
  REORDER_MESSAGE,
  REORDER_QUICK_REPLY,
  scheduleBlastForLater,
  scheduleBlastForNow,
  setBlastStatus,
  toggleBlastQuietHours,
  toggleBlastSmartSending,
  updateBlastTargetRules,
  type UpdateModelOnSequenceAction,
  UPDATE_BUTTON,
  UPDATE_DELAY_VALUE,
  UPDATE_DISPLAY_META,
  UPDATE_LOGIC_ADD_CONDITION,
  UPDATE_LOGIC_REMOVE_CONDITION,
  UPDATE_LOGIC_UPDATE_CONDITION,
  UPDATE_LOGIC_USER_INPUT_TYPE,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_ITEM,
  UPDATE_MODEL_ON_SEQUENCE,
  UPDATE_QUICK_REPLY,
  UPDATE_SEQUENCE_ITEM_TRIGGER,
  UPDATE_SPLIT
} from '../../../store/slices/sequence-editor/sequence-editor.actions'
import {
  updateQuietHours,
  updateSegmentId,
  updateSendingFrequency,
  updateSequenceName
} from '../../../store/slices/sequence/sequence.actions'

interface DiagramUpdateAction {
  type: string
}

interface SequenceItemDraftAction {
  type: string
  sequenceItemId: string
}

export function isDiagramUpdateAction(action: Action): action is DiagramUpdateAction {
  return [
    CREATE_SEQUENCE_ITEM,
    REMOVE_SEQUENCE_ITEM,

    CREATE_MESSAGE,
    REMOVE_MESSAGE,
    REORDER_MESSAGE,

    CREATE_MESSAGE_ITEM,
    REMOVE_MESSAGE_ITEM,

    CREATE_BUTTON,
    UPDATE_BUTTON,
    REMOVE_BUTTON,

    CREATE_QUICK_REPLY,
    REMOVE_QUICK_REPLY,
    REORDER_QUICK_REPLY,

    CREATE_FOLLOW_UP_PORT,

    UPDATE_DISPLAY_META,
    CREATE_SPLIT,
    REMOVE_SPLIT
  ].includes(action.type)
}

export function isUpdateModelOnSequenceAction(
  action: Action
): action is UpdateModelOnSequenceAction {
  return action.type === UPDATE_MODEL_ON_SEQUENCE
}

export function isCreateSequenceItemAction(action: Action): action is CreateSequenceItemAction {
  return action.type === CREATE_SEQUENCE_ITEM
}

export function isRemoveSequenceItemAction(action: Action): action is RemoveSequenceItemAction {
  return action.type === REMOVE_SEQUENCE_ITEM
}

export function isChangeSequenceItemAction(action: Action): action is SequenceItemDraftAction {
  return [
    CREATE_MESSAGE,
    REMOVE_MESSAGE,
    UPDATE_MESSAGE,
    REORDER_MESSAGE,

    CREATE_MESSAGE_ITEM,
    UPDATE_MESSAGE_ITEM,
    REMOVE_MESSAGE_ITEM,

    CREATE_BUTTON,
    UPDATE_BUTTON,
    REMOVE_BUTTON,

    CREATE_QUICK_REPLY,
    UPDATE_QUICK_REPLY,
    REMOVE_QUICK_REPLY,
    REORDER_QUICK_REPLY,

    CREATE_FOLLOW_UP_PORT,

    UPDATE_DELAY_VALUE,

    UPDATE_LOGIC_ADD_CONDITION,
    UPDATE_LOGIC_REMOVE_CONDITION,
    UPDATE_LOGIC_UPDATE_CONDITION,

    UPDATE_LOGIC_USER_INPUT_TYPE,
    UPDATE_SEQUENCE_ITEM_TRIGGER,

    CREATE_SPLIT,
    REMOVE_SPLIT,
    UPDATE_SPLIT,

    mediaUploadCompleted
  ].includes(action.type)
}

export function isSequenceItemSyncedWithSequenceAction(action: Action): boolean {
  return (
    updateQuietHours.match(action) ||
    updateSegmentId.match(action) ||
    updateSendingFrequency.match(action) ||
    toggleBlastQuietHours.match(action) ||
    toggleBlastSmartSending.match(action)
  )
}

export function isSequenceUpdateAction(action: Action): boolean {
  return (
    toggleBlastSmartSending.match(action) ||
    updateBlastTargetRules.match(action) ||
    removeBlastTargetRule.match(action) ||
    updateQuietHours.match(action) ||
    updateSegmentId.match(action) ||
    updateSendingFrequency.match(action) ||
    updateSequenceName.match(action)
  )
}

export function isBlastUpdateAction(action: Action): boolean {
  return (
    setBlastStatus.match(action) ||
    toggleBlastQuietHours.match(action) ||
    renameBlast.match(action) ||
    scheduleBlastForLater.match(action) ||
    scheduleBlastForNow.match(action) ||
    blastScheduledChanged.match(action) ||
    leaveReadOnlyMode.match(action)
  )
}
