import {
  type Device,
  type EmbeddedForm,
  type LandingPage,
  type OptinToolStep
} from '@ghostmonitor/recartapis'
import intersection from 'lodash/intersection'
import upperFirst from 'lodash/upperFirst'
import { type OptinToolMenuItem } from '../../components/editor/left-panel/left-panel.component'
import { type EmbeddedFormMenuItemSlug } from '../../embedded-form/editor/menu-items'

export enum LandingPageMenuItemSlug {
  LANDING_PAGE = 'landingPage',
  SETTINGS = 'settings',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  LAYOUT = 'layout',
  EMAIL = 'email',
  EMAIL_FORM_ERROR = 'emailFormError',
  SMS = 'sms',
  SMS_WITH_EMAIL = 'smsWithEmail',
  SMS_SUCCESS = 'smsSuccess',
  SMS_FORM_ERROR = 'smsFormError',
  SMS_WITH_EMAIL_FORM_ERROR = 'smsWithEmailFormError',
  GEOTARGETING_ERROR = 'geotargetingError'
}

export function getMenuSlugForDevice(
  device: Device,
  slug: LandingPageMenuItemSlug | EmbeddedFormMenuItemSlug
) {
  return `${device}${upperFirst(slug)}`
}

export function getLandingPageMenuItems(landingPage: LandingPage): OptinToolMenuItem[] {
  const hasMoreSteps = landingPage.steps.length > 1

  const menuItems: OptinToolMenuItem[] = [
    { slug: LandingPageMenuItemSlug.DESKTOP, label: 'Desktop', type: 'divider' },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.LAYOUT),
      device: 'desktop',
      label: 'Layout',
      items: ['additionalImage', 'background', 'margin']
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.EMAIL),
      steps: ['email'],
      device: 'desktop',
      label: 'Email opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.EMAIL_FORM_ERROR),
      steps: ['email'],
      device: 'desktop',
      label: 'Form item errors (email)',
      items: [...getErrorAccordionFirstItem(landingPage, 'desktop', 'email'), 'emailError']
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.SMS),
      steps: ['sms'],
      device: 'desktop',
      label: 'SMS opt-in',
      items: ['logo', 'heading', 'emailSuccess', 'smsInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.SMS_WITH_EMAIL),
      steps: ['smsWithEmail'],
      device: 'desktop',
      label: 'Email and SMS opt-in',
      items: ['logo', 'heading', 'emailInput', 'smsInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.SMS_FORM_ERROR),
      steps: ['sms'],
      device: 'desktop',
      label: `Form item errors ${hasMoreSteps ? '(SMS)' : ''}`,
      items: [...getErrorAccordionFirstItem(landingPage, 'desktop', 'sms')]
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.SMS_WITH_EMAIL_FORM_ERROR),
      steps: ['smsWithEmail'],
      device: 'desktop',
      label: 'Errors',
      items: [...getErrorAccordionFirstItem(landingPage, 'desktop', 'smsWithEmail')]
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.SMS_SUCCESS),
      steps: ['sms', 'smsWithEmail'],
      device: 'desktop',
      label: 'SMS success',
      items: ['logo', 'heading', 'description', 'button']
    },
    {
      slug: getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.GEOTARGETING_ERROR),
      device: 'desktop',
      label: 'Geotargeting error',
      items: ['logo', 'heading', 'description', 'button']
    },
    { slug: LandingPageMenuItemSlug.MOBILE, label: 'Mobile', type: 'divider' },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.LAYOUT),
      device: 'mobile',
      label: 'Layout',
      items: ['additionalImage', 'background', 'margin']
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.EMAIL),
      steps: ['email'],
      device: 'mobile',
      label: 'Email opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.EMAIL_FORM_ERROR),
      steps: ['email'],
      device: 'mobile',
      label: 'Form item errors (email)',
      items: [...getErrorAccordionFirstItem(landingPage, 'mobile', 'email')]
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS),
      steps: ['sms'],
      device: 'mobile',
      label: 'SMS opt-in',
      items: ['logo', 'heading', 'emailSuccess', 'smsInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS_FORM_ERROR),
      steps: ['sms'],
      device: 'mobile',
      label: `Form item errors  ${hasMoreSteps ? '(SMS)' : ''}`,
      items: [...getErrorAccordionFirstItem(landingPage, 'mobile', 'sms')]
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS_WITH_EMAIL),
      steps: ['smsWithEmail'],
      device: 'mobile',
      label: 'Email and SMS opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS_WITH_EMAIL_FORM_ERROR),
      steps: ['smsWithEmail'],
      device: 'mobile',
      label: getMobileSMSWithEmailErrorLabel(landingPage.messagingType!, hasMoreSteps),
      items:
        landingPage.messagingType === 'one-way'
          ? [...getErrorAccordionFirstItem(landingPage, 'mobile', 'smsWithEmail')]
          : [...getErrorAccordionFirstItem(landingPage, 'mobile', 'smsWithEmail')]
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS_SUCCESS),
      steps: ['sms', 'smsWithEmail'],
      device: 'mobile',
      label: 'SMS success',
      items: ['logo', 'heading', 'description', 'button']
    },
    {
      slug: getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.GEOTARGETING_ERROR),
      device: 'mobile',
      label: 'Geotargeting error',
      items: ['logo', 'heading', 'description', 'button']
    },
    { slug: LandingPageMenuItemSlug.LANDING_PAGE, label: 'Landing page', type: 'divider' },
    { slug: LandingPageMenuItemSlug.SETTINGS, label: 'Settings' }
  ]

  return menuItems.filter(
    (menuItem) =>
      (!menuItem.steps || intersection(landingPage.steps, menuItem.steps).length > 0) &&
      (!menuItem.device || shouldShowMenuItemForDevice(landingPage, menuItem))
  )
}

export function getErrorAccordionFirstItem(
  optinTool: LandingPage | EmbeddedForm,
  device: Device,
  step: OptinToolStep
): string[] {
  const formItems = optinTool.settings[device][step]?.form?.items ?? []
  return formItems.length > 0 ? ['formErrors'] : []
}

export function shouldShowMenuItemForDevice(
  optinTool: LandingPage | EmbeddedForm,
  menuItem: OptinToolMenuItem
): boolean {
  // for mobile sms error can only appear for one-way messaging type OR when a form item is added
  if (
    menuItem.device === 'mobile' &&
    optinTool.messagingType === 'two-way' &&
    menuItem.slug === getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS_FORM_ERROR)
  ) {
    const mobileSMSFormItems = optinTool.settings.mobile.sms?.form?.items ?? []
    return mobileSMSFormItems.length > 0
  } else {
    return optinTool.devices.includes(menuItem.device!)
  }
}

export function getMobileSMSWithEmailErrorLabel(
  messagingType: 'one-way' | 'two-way',
  hasMoreSteps: boolean
): string {
  if (messagingType === 'one-way') {
    return 'Form item errors'
  }

  return `Form item errors ${hasMoreSteps ? '(email)' : ''}`
}
