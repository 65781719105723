import type { TooltipProps as AntTooltipProps } from 'antd'
import { Tooltip as AntTooltip } from 'antd'
import cn from 'classnames'
import styles from './tooltip.component.scss'

type ToolTipProps = AntTooltipProps

export function Tooltip(props: ToolTipProps) {
  const { className, ...restProps } = props

  return (
    <AntTooltip
      overlayClassName={cn(styles.tooltip, className)}
      data-testid='tooltip'
      {...restProps}
    >
      {props.children}
    </AntTooltip>
  )
}
