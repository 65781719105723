import {
  type FlowUI,
  type SegmentListResponse,
  type SegmentListView
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { createScope } from '../../utils/logger/logger'
import { request } from '../../utils/request'
import { Notification } from '../../utils/notification/notification.util'
import { hooks } from '../hooks'
import { type RemoveResourceArgs } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

const logger = createScope('dashboard')

async function deleteSegment({ id }: RemoveResourceArgs) {
  await request.del<void>(`segments/${id}`)
}

export function useSegmentDelete(queryParams?: Record<string, unknown>) {
  const queryClient = useQueryClient()
  const queryName = useMemo(() => [QUERY_NAME.segmentsList, queryParams], [queryParams])
  const { data: flows } = hooks.useFlows()
  const { data: segments } = hooks.useSegmentsList()

  const handleSettled = useCallback(
    (_, __, { id }) => {
      queryClient.invalidateQueries(queryName)
      queryClient.invalidateQueries([QUERY_NAME.segment, id])
    },
    [queryClient, queryName]
  )

  const handleDeleteMutate = useCallback(
    async (deletePayload: RemoveResourceArgs) => {
      await queryClient.cancelQueries(queryName)

      const previousSegmentsList: SegmentListResponse = queryClient.getQueryData(queryName)
      const segmentsList = previousSegmentsList.data.filter(
        (segment) => segment.id !== deletePayload.id
      )

      queryClient.setQueryData(queryName, { data: segmentsList })
      return { segmentsList }
    },
    [queryClient, queryName]
  )

  const handleDeleteError = (err, _, context) => {
    logger.error(err)
    queryClient.setQueryData(queryName, context.previousSegmentsList)

    if (err?.response?.data?.error?.title !== 'Segment is in use') {
      Notification.error('Something went wrong, please try again later')
      return
    }
    const errorData = extractSegmentDeleteError(err)
    const segmentIds: string[] = errorData?.segmentIds ?? []
    const flowIds: string[] = errorData?.flowIds ?? []

    const segmentIdsBlockingDelete = mapIdsToNames(segmentIds, segments, 'id')
    const flowIdsBlockingDelete = mapIdsToNames(flowIds, flows, '_id')

    throw { segmentIdsBlockingDelete, flowIdsBlockingDelete }
  }

  const { mutateAsync: mutateDeleteSegment } = useMutation<void, unknown, RemoveResourceArgs>(
    deleteSegment,
    {
      onMutate: handleDeleteMutate,
      onSettled: handleSettled,
      onError: handleDeleteError
    }
  )

  return {
    deleteSegment: mutateDeleteSegment
  }
}

function extractSegmentDeleteError(
  err: any
): { segmentIds: string[]; flowIds: string[] } | undefined {
  return err?.response?.data?.error
}

function mapIdsToNames(
  ids: string[],
  items: SegmentListView[] | FlowUI[] | undefined,
  idField: string
) {
  return ids.map((id) => {
    const item = items?.find((item) => item && item[idField] === id)
    return { id, name: item?.name ?? id }
  })
}
