import { type Billing, type BillingService } from '@ghostmonitor/recartapis'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useCurrentBillingPeriods(
  options?: UseQueryOptions<BillingService.GetBillingPeriodsResponse>
): UseResource<Billing.BillingPeriod[]> {
  const queryName = [QUERY_NAME.currentBillingPeriods]

  const {
    data: billingPeriods,
    isLoading,
    isError
  } = useQuery<BillingService.GetBillingPeriodsResponse>(
    queryName,
    api.getCurrentBillingPeriods,
    options
  )

  return {
    data: billingPeriods?.data,
    isLoading,
    isError
  }
}
