import { type Action, type Store } from '@reduxjs/toolkit'
import { type AppDispatch } from '../../../store/create-store'
import { type DashboardState } from '../../../store/dashboard.state'
import { sequenceSaved } from '../../../store/slices/sequence-editor/sequence-editor.actions'
import { saveSequenceThunk } from '../../../store/slices/sequence-editor/thunks/save-sequence.thunk'

export function optinToolsMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  return (next) =>
    (action: Action): void => {
      next(action)
      const searchParams = new URLSearchParams(location.search)
      const optinToolId = searchParams.get('optinToolId') ?? undefined

      if (saveSequenceThunk.fulfilled.match(action) && optinToolId) {
        dispatch(
          sequenceSaved({
            sequenceId: action.payload._id!,
            optinToolId
          })
        )
      }
    }
}
