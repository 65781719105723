import { type ConditionalSplitFlowItemUI } from '@ghostmonitor/recartapis'
import { type ItemValidationRunner } from '../validator-factory'
import {
  FlowEditorError,
  FlowEditorErrorLevel
} from '../../../../../../utils/flow-editor/flow-editor-errors'
import { validateConditionalSplitFlowItemMustHaveConnection } from '../../../../../../utils/validators/flow/validate-conditional-split'
import { selectLaunchDarklyFlag } from '../../../../../selectors'
import { type DashboardState } from '../../../../../dashboard.state'

export function conditionalSplitV1Validator(
  flowItem: ConditionalSplitFlowItemUI,
  state: DashboardState
): ItemValidationRunner {
  return {
    getConfiguredValidators: () => ({
      conditionalSplitV1Deprecated: {
        validate: async () => {
          return [
            {
              message: FlowEditorError.ConditionalSplitV1DeprecatedError,
              level: FlowEditorErrorLevel.Error
            }
          ]
        }
      },
      logicFlowItemConnections: {
        validate: async () => {
          const isConditionalSplitV1SunsetEnabled = selectLaunchDarklyFlag(
            'conditional-split-v-1-sunset'
          )(state)

          if (isConditionalSplitV1SunsetEnabled) {
            return []
          }

          const error = validateConditionalSplitFlowItemMustHaveConnection(flowItem)
          return error !== undefined ? [error] : []
        }
      }
    })
  }
}
