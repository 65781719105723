import {
  convertSequenceAPIToSequenceUI,
  convertSequenceItemUIToSequenceItemAPI,
  convertSequenceUIToSequenceAPI,
  type SequenceAPI,
  type SequenceUI
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { type AxiosResponse } from 'axios'
import {
  getErrorMessage,
  SequenceEditorErrorMessage
} from '../../../../routes/SequenceEditor/types/sequence-editor-errors'
import { api } from '../../../../utils/api'
import { getSerializedErrorFromAxiosResponse, isHttpErrorResponse } from '../../../../utils/errors'
import { Notification } from '../../../../utils/notification/notification.util'
import type { AppDispatch } from '../../../create-store'
import { type DashboardState } from '../../../dashboard.state'
import { selectEditorSequence, selectSequenceItem } from '../../../selectors'
import { saveSequencePartialFailure } from '../sequence-editor.actions'

export const saveSequenceThunk = createAsyncThunk<
  SequenceUI,
  void,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/SAVE_SEQUENCE', async (args, store) => {
  const state = store.getState()
  const dispatch = store.dispatch
  const sequence: SequenceAPI = {
    ...convertSequenceUIToSequenceAPI(selectEditorSequence(state)),
    sequenceItems: []
  }

  delete sequence.flowEditorDiagram

  // ! We use a separate endpoint (PUT /api/sequences/:sequence_id/toggle) to enable/disable sequences
  delete sequence.isEnabled

  sequence.sequenceItems = sequence.sequenceItemIds.map((sequenceItemId) => {
    return convertSequenceItemUIToSequenceItemAPI(selectSequenceItem(sequenceItemId)(state))
  })

  const response: AxiosResponse<SequenceAPI> = await api.updateSequence(sequence)

  // Partial error handling
  // Responses with status code bigger >= 400 will be thrown out, and are handled
  // at saveSequence.rejected reducer
  if (response.status === 207 && isHttpErrorResponse(response)) {
    dispatch(saveSequencePartialFailure(getSerializedErrorFromAxiosResponse(response)))
    const errorMessage = getErrorMessage(
      getSerializedErrorFromAxiosResponse(response),
      SequenceEditorErrorMessage.SAVE_ERROR
    )
    Notification.warn(errorMessage)
  }

  return convertSequenceAPIToSequenceUI(response.data)
})
