import { type BillingSubscription } from '@ghostmonitor/recartapis'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function usePollBillingSubscriptions(
  enabled: boolean,
  refetchInterval = 2000
): UseResource<BillingSubscription[]> {
  return hooks.useBillingSubscriptions({ enabled, refetchInterval })
}
