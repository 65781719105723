import type { Moment } from 'moment'
import { createContext } from 'react'
import {
  TimeFrameOption,
  timeFrameOptions
} from '../filter-components/time-frame-filter/time-frame-option.type'

export function getDefaultTimeFrameFilter(
  defaultTimeFrameFilter = TimeFrameOption.ALLTIME
): TimeFrameFilterContextState {
  return {
    setDateFrom: () => undefined,
    setDateTo: () => undefined,
    dateFrom: timeFrameOptions[defaultTimeFrameFilter].from,
    dateTo: timeFrameOptions[defaultTimeFrameFilter].to
  }
}

export interface TimeFrameFilterContextState {
  setDateFrom: (date: Moment | null) => void
  setDateTo: (date: Moment | null) => void
  dateFrom: Moment | null
  dateTo: Moment | null
}

export const TimeFrameFilterContext = createContext<TimeFrameFilterContextState>(
  getDefaultTimeFrameFilter()
)
