import { type SMSCampaign, SMSCampaignStatus } from '@ghostmonitor/recartapis'

export function isSMSCampaignReadonly(smsCampaign: SMSCampaign): boolean {
  return [
    SMSCampaignStatus.SCHEDULED,
    SMSCampaignStatus.SCHEDULED_FOR_NOW,
    SMSCampaignStatus.PROCESSING,
    SMSCampaignStatus.CANCELLED,
    SMSCampaignStatus.FAILED,
    SMSCampaignStatus.SENDING,
    SMSCampaignStatus.SENT
  ].includes(smsCampaign?.status)
}
