import { PortType } from '@ghostmonitor/recartapis'
import { cloneElement } from 'react'
import {
  type DefaultLinkFactory,
  type DefaultLinkProps,
  DefaultLinkWidget,
  type PointModel
} from 'storm-react-diagrams'
import './link-widget.scss'

// Overrided methods are initially copied from the root implementation: https://github.com/projectstorm/react-diagrams/blob/v5.3.2/src/defaults/widgets/DefaultLinkWidget.tsx
// While link-widget only overrides generateLink(), this file also overrides render() to use a customized generateCurvePath() method
// generateCurvePath() is copied from here: https://github.com/projectstorm/react-diagrams/blob/v5.3.2/src/Toolkit.ts

export interface SequenceEditorLinkWidgetProps extends DefaultLinkProps {
  modelChange?: (serializedDiagram: any) => void
}

export class SequenceEditorLinkWidget extends DefaultLinkWidget {
  public props: SequenceEditorLinkWidgetProps

  public generateLink(path: string, extraProps: any, id: string | number): JSX.Element {
    const props = this.props
    const Bottom = cloneElement(
      (
        props.diagramEngine.getFactoryForLink(this.props.link) as DefaultLinkFactory
      ).generateLinkSegment(
        this.props.link,
        this,
        this.state.selected || this.props.link.isSelected(),
        path
      ),
      {
        ref: (ref) => ref && this.refPaths.push(ref)
      }
    )

    const Top = cloneElement(Bottom, {
      ...extraProps,
      strokeLinecap: 'round',
      onMouseLeave: () => {
        this.setState({ selected: false })
      },
      onMouseEnter: () => {
        if (props.diagramEngine.isLocked()) {
          return
        }
        this.setState({ selected: true })
      },
      // Overload default event handler that creates a Point
      onMouseDown: () => {
        return undefined
      },
      onMouseUp: () => {
        if (props.diagramEngine.isLocked()) {
          return
        }
        this.props.link.remove()
        const serializedDiagram = props.diagramEngine.diagramModel.serializeDiagram()
        props.modelChange(serializedDiagram)
      },
      ref: null,
      'data-linkid': this.props.link.getID(),
      strokeOpacity: this.state.selected ? 0.1 : 0,
      strokeWidth: 20,
      onContextMenu: () => {
        if (!this.props.diagramEngine.isModelLocked(this.props.link)) {
          event.preventDefault()
          this.props.link.remove()
        }
      }
    })

    return (
      <g data-testid='link' key={'link-' + id}>
        {Bottom}
        {Top}
      </g>
    )
  }

  public generateCurvePath(firstPoint: PointModel, lastPoint: PointModel): string {
    let curvyX = 200

    // Linking from sequence item port
    if (this.props.link.sourcePort.type === PortType.SEQUENCE_ITEM) {
      let curvyY = Math.floor(Math.abs(firstPoint.y - lastPoint.y) * 0.05)

      // Curviness increases with destination
      curvyX += Math.max(0, firstPoint.x - 300 - lastPoint.x)

      // For near objects where curvyY is small, reduce curvX
      curvyX -= Math.max(0, 100 - curvyY * 3)

      // If the destination object is more left then source object, increase curviness
      if (lastPoint.x > firstPoint.x) {
        curvyX += Math.min(100, (lastPoint.x - firstPoint.x) * 0.4)
        curvyY = 0
      }

      if (firstPoint.y <= lastPoint.y) {
        return `M${firstPoint.x},${firstPoint.y} C ${firstPoint.x - curvyX},${
          firstPoint.y - curvyY
        } ${lastPoint.x + curvyX},${lastPoint.y + curvyY} ${lastPoint.x},${lastPoint.y}`
      } else {
        return `M${firstPoint.x},${firstPoint.y} C ${firstPoint.x - curvyX},${
          firstPoint.y + curvyY
        } ${lastPoint.x + curvyX},${lastPoint.y - curvyY} ${lastPoint.x},${lastPoint.y}`
      }

      // "Normal" linking
    } else {
      let curvyY = Math.floor(Math.abs(lastPoint.y - firstPoint.y) * 0.05)

      // Curviness increases with destination
      curvyX += Math.max(0, lastPoint.x - 300 - firstPoint.x)

      // For near objects where curvyY is small, reduce curvX
      curvyX -= Math.max(0, 100 - curvyY * 3)

      // If the destination object is more left then source object, increase curviness
      if (firstPoint.x > lastPoint.x) {
        curvyX += Math.min(100, (firstPoint.x - lastPoint.x) * 0.4)
        curvyY = 0
      }

      if (firstPoint.y <= lastPoint.y) {
        return `M${firstPoint.x},${firstPoint.y} C ${firstPoint.x + curvyX},${
          firstPoint.y - curvyY
        } ${lastPoint.x - curvyX},${lastPoint.y + curvyY} ${lastPoint.x},${lastPoint.y}`
      } else {
        return `M${firstPoint.x},${firstPoint.y} C ${firstPoint.x + curvyX},${
          firstPoint.y + curvyY
        } ${lastPoint.x - curvyX},${lastPoint.y - curvyY} ${lastPoint.x},${lastPoint.y}`
      }
    }
  }

  /**
   * The only change here, compared to the copied default implementation, that we
   * use a customized generateCurvePath method
   */
  public render() {
    const { diagramEngine } = this.props
    if (!diagramEngine.nodesRendered) {
      return null
    }

    // ensure id is present for all points on the path
    const points = this.props.link.points
    const paths = []

    if (points.length === 2) {
      const isHorizontal = Math.abs(points[0].x - points[1].x) > Math.abs(points[0].y - points[1].y)
      const xOrY = isHorizontal ? 'x' : 'y'

      // draw the smoothing
      // if the points are too close, just draw a straight line
      let margin = 50
      if (Math.abs(points[0][xOrY] - points[1][xOrY]) < 50) {
        margin = 5
      }

      const pointLeft = points[0]
      const pointRight = points[1]

      const linkExtraProps = {
        onMouseDown: (event) => {
          this.addPointToLink(event, 1)
        }
      }

      const path = this.generateLink(
        this.generateCurvePath(pointLeft, pointRight),
        linkExtraProps,
        '0'
      )

      paths.push(path)

      // draw the link as dangeling
      if (this.props.link.targetPort === null) {
        paths.push(this.generatePoint(1))
      }
    }

    this.refPaths = []
    return <g {...this.getProps()}>{paths}</g>
  }
}
