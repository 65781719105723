import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Input, Select } from 'antd'
import cn from 'classnames'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import styles from './variable-popover.style.scss'

export interface VariablePopoverProps {
  selectedVariableType: string
  onVariableTypeChange: (variable: string) => void
  variableTypeOptions: { label: string; value: string }[]
  variableFallback: string
  onVariableFallbackChange: (value: string) => void
  onAddVariable?: (variable: string) => void
  onChangeVariable?: () => void
  onFocus: () => void
  onShow: (isOpen: boolean) => void
  onClose?: (isUserVariable?: boolean) => void
  isUserVariable?: boolean
}

export function VariablePopover(props: VariablePopoverProps) {
  function handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    if (props.onAddVariable) {
      props.onAddVariable(props.selectedVariableType)
    } else {
      props.onChangeVariable()
    }
  }

  function handleClose() {
    props.onShow(false)
    props.onClose?.(props.isUserVariable)
  }

  // TODO make it enum
  const showFallbackInput = ['first_name', 'last_name'].includes(props.selectedVariableType)

  return (
    <div
      data-testid='variable-popover-container'
      className={styles.variablePopoverContainer}
      onMouseDown={(event) => {
        event.stopPropagation()
      }}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <div className='font-bold mt-1'>
        {props.isUserVariable ? 'Would you like to insert a personalized variable?' : 'Variables'}
      </div>
      <div className='text-tiny mt-1 mb-3'>Insert variables to personalize your message.</div>
      <div className='text-tiny'>Personalized variables</div>
      <div className='my-2 mb-3'>
        <Select
          className={cn(styles.input, 'text-tiny w-full')}
          value={props.selectedVariableType}
          onChange={props.onVariableTypeChange}
          size='middle'
          aria-controls='test-uuid'
          onClick={() => {
            props.onFocus()
          }}
        >
          {props.variableTypeOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>

      {showFallbackInput && (
        <>
          <div className='text-tiny'>
            Fallback value{' '}
            <Tooltip
              title='Fallback value is used when personalized variable is not available for recipient.'
              color='#262626'
            >
              <InfoCircleOutlined className='cursor-pointer' />
            </Tooltip>
          </div>
          <div className='my-2'>
            <Input
              className={cn(styles.input, 'text-tiny w-full')}
              data-testid='variable-popover-fallback-input'
              maxLength={80}
              value={props.variableFallback}
              onChange={({ target: { value } }) => {
                props.onVariableFallbackChange(value)
              }}
              onBlur={() => {
                props.onVariableFallbackChange(props.variableFallback.trim())
              }}
              suffix={
                <Tooltip title={props.variableFallback} color='#262626'>
                  <span className={cn('text-tertiary', styles.inputSuffix)}>
                    {props.variableFallback.length}/80
                  </span>
                </Tooltip>
              }
              onFocus={props.onFocus}
            />
          </div>
        </>
      )}

      <div className='flex text-center mb-2 mt-3'>
        <Button
          className={styles.saveButton}
          data-testid='variable-popover-add-button'
          disabled={showFallbackInput && !props.variableFallback}
          type='primary'
          size='middle'
          block
          onClick={handleClick}
        >
          {props.onChangeVariable ? 'Save variable' : 'Insert variable'}
        </Button>
      </div>
      <div className='flex text-center'>
        <Button
          className={styles.backButton}
          type='link'
          block
          onClick={handleClose}
          data-testid='variable-popover-cancel-button'
        >
          {props.isUserVariable ? 'No thanks' : 'Back'}
        </Button>
      </div>
    </div>
  )
}
