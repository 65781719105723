import cn from 'classnames'
import hocStyles from '../../higher-order-components/make-sequence-item/make-sequence-item.hoc.scss'

interface SequenceItemWidgetTitleProps {
  wrapperClasses: string[]
  icon: React.ReactNode
  text: string
}

export function SequenceItemWidgetTitle(props: SequenceItemWidgetTitleProps) {
  return (
    <div className={cn(props.wrapperClasses, hocStyles['widget-title-wrapper'])}>
      <div className={hocStyles['widget-title-icon']}>{props.icon}</div>
      <div className={hocStyles['widget-title-text']}>{props.text}</div>
    </div>
  )
}
