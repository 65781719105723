import { type SequenceUI } from '@ghostmonitor/recartapis'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useSequence(sequenceId: string | null): UseResource<SequenceUI> {
  const { isLoading, data: sequences } = hooks.useSequences()

  return {
    data: sequences?.find((sequence) => sequence._id === sequenceId),
    isLoading
  }
}
