import { isSMSSequenceItemUI } from '@ghostmonitor/recartapis'
import {
  selectEditorSequence,
  selectMessageMeta,
  selectSequenceItemsById
} from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'
import { setVariablesMetaInMessageHandler } from './set-variables-meta-in-message.handler'

export function setVariablesMetaHandler(state: SequenceEditorState): void {
  const sequence = selectEditorSequence(state)
  const sequenceItemsBy = selectSequenceItemsById(state)

  sequence.sequenceItemIds.forEach((sequenceItemId) => {
    const sequenceItem = sequenceItemsBy[sequenceItemId]
    if (isSMSSequenceItemUI(sequenceItem)) {
      sequenceItem.messages.forEach((message, messageIndex) => {
        const messageMeta = selectMessageMeta(sequenceItemId, messageIndex)(state)
        setVariablesMetaInMessageHandler(state, message, messageMeta)
      })
    }
  })
}
