import { type GetProductTypesResponse } from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_NAME } from './query-names'

interface Params {
  type: string
  limit: number
  offset?: number
}

export function useProductTypes(queryParams?: Params) {
  async function fetchProductTypes({ pageParam = 0 }): Promise<GetProductTypesResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.get<GetProductTypesResponse>('product-types', { params })
  }

  const queryName = [QUERY_NAME.productTypes, queryParams?.type]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery<GetProductTypesResponse>(queryName, fetchProductTypes, {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
          return undefined
        }

        return pages.flatMap((page) => page.data).length
      }
    })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
