import { type SubscriberProfileView, type SubscriberResponse } from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSubscriber(subscriberId: string): UseResource<SubscriberProfileView> & {
  subscribeChannel: (channel: string) => Promise<unknown>
  unsubscribeChannel: (channel: string) => Promise<unknown>
} {
  const queryClient = useQueryClient()
  const queryName = [QUERY_NAME.subscriber, subscriberId]

  const { isInitialLoading, data, error } = useQuery<SubscriberResponse, AxiosError>(
    queryName,
    async () => {
      return request.get<SubscriberResponse>(`subscribers/${subscriberId}`)
    },
    {
      enabled: subscriberId !== undefined
    }
  )

  function subscribeChannel(channel: string) {
    return request.post(`subscribers/${subscriberId}/subscriptions/${channel}`)
  }

  function unsubscribeChannel(channel: string) {
    return request.del(`subscribers/${subscriberId}/subscriptions/${channel}`)
  }

  function handleSuccess() {
    queryClient.invalidateQueries(queryName)
  }

  const subscribeMutation = useMutation(subscribeChannel, {
    onSuccess: handleSuccess
  })

  const unsubscribeMutation = useMutation(unsubscribeChannel, {
    onSuccess: handleSuccess
  })

  return {
    isLoading: isInitialLoading,
    data: data?.data,
    error,
    subscribeChannel: subscribeMutation.mutateAsync,
    unsubscribeChannel: unsubscribeMutation.mutateAsync
  }
}
