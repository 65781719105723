import { type GetStaticDiscountCodeResponse } from '@ghostmonitor/recartapis'
import { type AxiosError } from 'axios'
import { api } from '../../../utils/api'
import { type GenericSerializedError, serializedError } from '../../../utils/serialized-error'
import { SequenceEditorError } from '../types/sequence-editor-errors'

export async function discountCodeValidator(
  discountCode: string
): Promise<GenericSerializedError<SequenceEditorError> | undefined> {
  try {
    const discountCodeResponse = await api.getDiscountCode(discountCode)

    if (discountCodeResponse.data) {
      if (discountCodeResponse.data.expired) {
        return serializedError<SequenceEditorError>(SequenceEditorError.DiscountCodeExpired)
      }
    }
  } catch (error) {
    const axiosError: AxiosError<GetStaticDiscountCodeResponse> = error

    if (axiosError.response.status === 404) {
      return serializedError<SequenceEditorError>(SequenceEditorError.DiscountCodeNotExists)
    }
  }
}
