import { type WebUrlButton } from '@ghostmonitor/recartapis'
import { ControlledTextField } from '../controlled-text-field.component'
import { type ButtonPopoverFormProps } from '../types/button-popover-form.type'
import { DiscountCode } from './dicount-code/discount-code.component'

export function WebUrlPopoverForm(props: ButtonPopoverFormProps<WebUrlButton>) {
  function handleUrlChange(url: string) {
    props.onChange({ url })
  }

  function handleDiscountCodeChange(discountCode: string, discountId?: string) {
    props.onChange({
      discount_code: discountCode,
      ...(discountId && { discount_id: discountId })
    })
  }

  return (
    <>
      <ControlledTextField
        label='Button url'
        value={props.payload.url}
        placeholder='http://example.com'
        onChange={handleUrlChange}
        onBlur={props.onBlur}
      />
      {props.enableCreateDiscount ? (
        <DiscountCode
          code={props.payload.discount_code}
          onChange={handleDiscountCodeChange}
          onShowInfo={props.onShowInfo}
          errorMessage={props.errorMessage}
        />
      ) : (
        <ControlledTextField
          label='Discount code'
          value={props.payload.discount_code}
          onChange={handleDiscountCodeChange}
          onBlur={props.onBlur}
        />
      )}
    </>
  )
}
