import { type GetProductTagsResponse } from '@ghostmonitor/recartapis'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_NAME } from './query-names'

interface Params {
  name: string
  limit: number
  offset?: number
}

export function useProductTags(queryParams?: Params) {
  async function fetchProductTags({ pageParam = 0 }): Promise<GetProductTagsResponse> {
    const params = {
      ...queryParams,
      offset: pageParam
    }

    return request.get<GetProductTagsResponse>('product-tags', { params })
  }

  const queryName = [QUERY_NAME.productTags, queryParams?.name]

  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery<GetProductTagsResponse>(queryName, fetchProductTags, {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < queryParams.limit || lastPage.data.length === 0) {
          return undefined
        }

        return pages.flatMap((page) => page.data).length
      }
    })

  return {
    isLoading,
    data: data?.pages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}
