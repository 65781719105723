// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EyxixxSyCaWrQagIAI20{color:#9da0a6}`, "",{"version":3,"sources":["webpack://./src/components/editor-drawer/components/joined-a-segment/segment-option/segment-option.component.scss"],"names":[],"mappings":"AACE,sBACE,aAAA","sourcesContent":[":local {\n  .disabledOption {\n    color: #9da0a6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabledOption": `EyxixxSyCaWrQagIAI20`
};
export default ___CSS_LOADER_EXPORT___;
