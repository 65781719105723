import { Button as AntButton } from 'antd'
import { type ButtonProps as AntButtonProps } from 'antd/lib/button'
import cn from 'classnames'
import styles from './button.component.scss'

export type ButtonProps = AntButtonProps

export function Button(props: ButtonProps) {
  const { className, ...restProps } = props

  return (
    <AntButton
      className={cn(styles.button, styles[props.type ?? 'default'], className)}
      {...restProps}
    >
      {props.children}
    </AntButton>
  )
}
