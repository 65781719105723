import { type ButtonActionType, type ButtonUI } from '@ghostmonitor/recartapis'
import { Divider, Popover, Select } from 'antd'
import { useState } from 'react'
import styles from './messenger-button.component.scss'
import { type ButtonTypeOption } from './types/button-type-option.type'

const { Option } = Select

interface ButtonPopoverProps {
  type: ButtonActionType
  payload: ButtonUI
  buttonTypeOptions: readonly ButtonTypeOption[]
  onHide: () => void
  onTypeChange?: (type: ButtonActionType) => void
  onValueChange: (payload: Partial<ButtonUI>) => void
  onBlur: () => void
  disableTypeChange?: boolean
  enableCreateDiscount?: boolean
  errorMessage?: string
  children: any
}

export function ButtonPopover(props: ButtonPopoverProps) {
  const [infoContent, setInfoContent] = useState<React.ReactElement>()

  const Content = props.buttonTypeOptions.find(
    (buttonTypeOption) => buttonTypeOption.value === props.type
  ).content

  function handleOpenChange(visible: boolean) {
    if (!visible) {
      props.onHide()
      props.onBlur()
      setInfoContent(undefined)
    }
  }

  function handleTypeChange(type: ButtonActionType) {
    if (props.onTypeChange) {
      props.onTypeChange(type)
    }
  }

  function handleValueChange(payload: Partial<ButtonUI>) {
    props.onValueChange(payload)
  }

  function handleShowInfo(info: React.ReactElement) {
    setInfoContent(info)
  }

  function handleMouseDown(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    event.stopPropagation()
  }

  function renderContent() {
    return (
      <div
        data-testid='popover-container'
        className={styles['popover-container']}
        onMouseDown={handleMouseDown}
      >
        {!props.disableTypeChange && (
          <>
            <Divider orientation='left'>Button function</Divider>
            <Select
              value={props.type}
              onChange={handleTypeChange}
              style={{ width: '100%' }}
              aria-controls='test-uuid'
            >
              {props.buttonTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </>
        )}

        {Content && (
          <Content
            onChange={handleValueChange}
            onBlur={props.onBlur}
            onShowInfo={handleShowInfo}
            payload={props.payload}
            enableCreateDiscount={props.enableCreateDiscount}
            errorMessage={props.errorMessage}
          />
        )}
      </div>
    )
  }

  return (
    <Popover
      trigger='click'
      onOpenChange={handleOpenChange}
      placement='right'
      content={infoContent ?? renderContent}
    >
      {props.children}
    </Popover>
  )
}
