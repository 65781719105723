import styles from './label.component.scss'

interface LabelProps {
  title: string
  children?: React.ReactNode
}

export function Label(props: LabelProps) {
  return (
    <>
      <label className={styles.label}>{props.title}</label>
      {props.children}
    </>
  )
}
