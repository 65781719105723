import { type RandomSplitSequenceItemUI } from '@ghostmonitor/recartapis'
import { type UpdateSplitAction } from '../sequence-editor.actions'
import { selectSequenceItem } from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'

export function updateSplitActionHandler(state: SequenceEditorState, action: UpdateSplitAction) {
  const sequenceItem = selectSequenceItem<RandomSplitSequenceItemUI>(action.sequenceItemId)(state)
  const split = sequenceItem.logic.randomSplit.variants[action.splitIndex]
  const newSplit = {
    ...split,
    percentage: action.percentage
  }

  sequenceItem.logic.randomSplit.variants.splice(action.splitIndex, 1, newSplit)
}
