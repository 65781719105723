import { type FlowDiagram, NodeType, PortType } from '@ghostmonitor/recartapis'

const automaticPortTypes = [PortType.LOGIC, PortType.FOLLOW_UP]

interface NeighborNode {
  node: FlowDiagram.Node
  edge: FlowDiagram.Edge | undefined
}

function getOutwardHandles(node: FlowDiagram.Node): FlowDiagram.Handle[] {
  const { followUpHandle, logicHandles } = node.data
  return [followUpHandle ?? [], ...(logicHandles ?? [])].flat()
}

function getNeighborNodesFromHandle(
  handle: FlowDiagram.Handle,
  diagram: FlowDiagram.FlowDiagram
): NeighborNode | undefined {
  if (!automaticPortTypes.includes(handle.portType)) {
    return
  }
  const edgeToNextNeighbor = diagram.edges.find((edge) => edge.sourceHandle === handle.id)
  if (!edgeToNextNeighbor) {
    return
  }
  const { targetHandle } = edgeToNextNeighbor
  if (!targetHandle) {
    return
  }
  const neighborNode = diagram.nodes.find((node) => node.data.inputHandle?.id === targetHandle)
  if (!neighborNode) {
    return
  }
  return { node: neighborNode, edge: edgeToNextNeighbor }
}

function getNeighborNodesFromHandles(
  handles: FlowDiagram.Handle[],
  diagram: FlowDiagram.FlowDiagram
): NeighborNode[] {
  return handles.reduce<NeighborNode[]>((accumulatedNeighborNodes, handle) => {
    const neighborNode = getNeighborNodesFromHandle(handle, diagram)
    return [...accumulatedNeighborNodes, ...(neighborNode ? [neighborNode] : [])]
  }, [])
}

function getLoopEdgeForNeighborNode(
  neighborNode: NeighborNode,
  diagram: FlowDiagram.FlowDiagram,
  visitedItems: Set<string>
): FlowDiagram.Edge | undefined {
  const { node, edge } = neighborNode
  if (visitedItems.has(node.id)) {
    return edge
  }
  visitedItems.add(node.id)

  const outwardHandles = getOutwardHandles(node)
  if (outwardHandles.length === 0) {
    return
  }

  const neighborNodesFromHandles: NeighborNode[] = getNeighborNodesFromHandles(
    outwardHandles,
    diagram
  )

  return neighborNodesFromHandles.reduce<FlowDiagram.Edge | undefined>((loopEdge, nextNeighbor) => {
    return loopEdge ?? getLoopEdgeForNeighborNode(nextNeighbor, diagram, new Set([...visitedItems]))
  }, undefined)
}

export function getLoopEdge(flowDiagram: FlowDiagram.FlowDiagram): FlowDiagram.Edge | undefined {
  if (!Array.isArray(flowDiagram.nodes) || flowDiagram.nodes.length === 0) {
    return undefined
  }

  for (const node of flowDiagram.nodes) {
    const loopEdge = getLoopEdgeForNeighborNode(
      {
        node,
        edge: undefined
      },
      flowDiagram,
      new Set<string>()
    )
    if (loopEdge) {
      return loopEdge
    }
  }

  return undefined
}
