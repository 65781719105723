import { type CartUrlButton } from '@ghostmonitor/recartapis'
import { ControlledTextField } from '../controlled-text-field.component'
import { type ButtonPopoverFormProps } from '../types/button-popover-form.type'

export function CartUrlPopoverForm(props: ButtonPopoverFormProps<CartUrlButton>) {
  return (
    <ControlledTextField
      label='Discount code'
      value={props.payload.discount_code}
      onChange={(discountCode) => {
        props.onChange({ discount_code: discountCode })
      }}
      onBlur={props.onBlur}
    />
  )
}
