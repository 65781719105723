import { type Action, type AsyncThunk, type ThunkAction } from '@reduxjs/toolkit'
import { type DashboardState } from './dashboard.state'

export type SyncThunk = ThunkAction<unknown, DashboardState, null, Action<string>>

export type Thunk = AsyncThunk<unknown, boolean | void, Record<string, unknown>> | (() => SyncThunk)

export function withPayloadType<P, M = P, E = Error>() {
  return (payload: P, meta?: M, error?: E) => ({ payload, meta, error })
}
