import {
  type ConditionalSplitSequenceItemUI,
  type LogicConditionalSplitCondition
} from '@ghostmonitor/recartapis'
import { type Action } from '@reduxjs/toolkit'
import { selectSequenceItem } from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'

export interface AddConditionAction extends Action {
  sequenceItemId: string
  condition: LogicConditionalSplitCondition
}

export function addConditionActionHandler(state: SequenceEditorState, action: AddConditionAction) {
  const sequenceItem = selectSequenceItem<ConditionalSplitSequenceItemUI>(action.sequenceItemId)(
    state
  )
  sequenceItem.logic.conditionalSplit.variants[0].conditions.push(action.condition)
}
