import { createScope } from '../../utils/logger/logger'

const { error } = createScope('sequence-editor')

export const crashReporter = (store) => (next) => async (action) => {
  try {
    return await next(action)
  } catch (err: any) {
    console.error('Caught an exception!', err)
    error(new Error(`Crash reporter middleware - ${err.message}`), {
      err,
      action,
      state: store.getState()
    })
    throw err
  }
}
