/* global LAUNCHDARKLY_KEY */
import * as LDClient from 'launchdarkly-js-client-sdk'
import { useEffect, useState } from 'react'
import { config } from '../../../config'

const launchDarklyClients: Record<string, Promise<LDClient.LDClient>> = {}

export async function getLaunchDarklyClient(flowId: string): Promise<LDClient.LDClient> {
  if (launchDarklyClients[flowId] !== undefined) {
    return launchDarklyClients[flowId]
  }

  launchDarklyClients[flowId] = new Promise((resolve) => {
    const client = LDClient.initialize(config.LAUNCHDARKLY_KEY, {
      kind: 'flowId',
      key: flowId
    })
    client.on('ready', () => {
      resolve(client)
    })
  })

  return launchDarklyClients[flowId]
}

export function useFeatureFlagByFlowId<TValue extends boolean>(
  flowId: string,
  flagKey: string
): TValue | undefined {
  const [flagValue, setFlagValue] = useState<TValue | undefined>()

  useEffect(() => {
    if (flowId === undefined) {
      throw new Error('Flow ID is undefined')
    }

    if (flagKey === undefined) {
      throw new Error('Flag key is undefined')
    }

    async function fetch() {
      const client = await getLaunchDarklyClient(flowId)
      const value = await client.variation(flagKey, false)
      setFlagValue(value)
    }

    fetch()
  }, [flowId, flagKey])

  return flagValue
}
