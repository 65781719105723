import { type ItemValidationRunner } from '../validator-factory'
import { type RandomSplitFlowItemUI } from '@ghostmonitor/recartapis'
import {
  validateRandomSplit,
  validateRandomSplitFlowItemMustHaveConnection
} from '../../../../../../utils/validators/flow/validate-random-split'

export function randomSplitValidator(
  flowItem: RandomSplitFlowItemUI,
  bypassWarnings?: boolean
): ItemValidationRunner {
  return {
    getConfiguredValidators: () => ({
      flowItemContent: {
        validate: async () => {
          const error = validateRandomSplit(flowItem.item.logic.split.variants)
          return error !== undefined ? [error] : []
        }
      },
      logicFlowItemConnections: {
        validate: async () => {
          const error = validateRandomSplitFlowItemMustHaveConnection(flowItem, bypassWarnings)
          return error !== undefined ? [error] : []
        }
      }
    })
  }
}
