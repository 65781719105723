import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { hooks } from '../../../../../hooks/hooks'
import { selectEntrySequenceItem } from '../../../../../store/selectors'
import { selectSiteUrl } from '../../../../../store/slices/site/site.selectors'
import {
  isAbandonmentSequenceItem,
  isFulillmentSequenceItem,
  isReceiptSequenceItem,
  isReviewSequenceItem
} from '../../../utils/assert-sequence-item-type'
import { SequenceEditorTray } from '../../sequence-editor-tray/sequence-editor-tray.component'
import {
  TrayItemAbandonedCartCarousel,
  TrayItemAudio,
  TrayItemCard,
  TrayItemFile,
  TrayItemFulfillment,
  TrayItemGallery,
  TrayItemMediaTemplateImage,
  TrayItemMediaTemplateVideo,
  TrayItemReceipt,
  TrayItemReview,
  TrayItemText
} from '../../tray-item/messenger/tray-item-message.component'
import {
  TrayItemConditionalSplitSequenceItem,
  TrayItemDelaySequenceItem,
  TrayItemMessageSequenceItem,
  TrayItemRandomSplitSequenceItem,
  TrayItemUserInputSequenceItem
} from '../../tray-item/sequence-item/tray-item-sequence-item.component'
import styles from '../tray.scss'

export function MessengerTray() {
  const stateSelector = useCallback(
    createStructuredSelector({
      entrySequenceItem: selectEntrySequenceItem
    }),
    []
  )

  const siteUrl = hooks.useSiteSelector(selectSiteUrl)
  const state = useSelector(stateSelector)

  const isReceiptSequence = isReceiptSequenceItem(state.entrySequenceItem)
  const isFulfillmentSequence = isFulillmentSequenceItem(state.entrySequenceItem)
  const isReviewSequence = isReviewSequenceItem(state.entrySequenceItem)
  const isAbandonmentSequence = isAbandonmentSequenceItem(state.entrySequenceItem)

  return (
    <SequenceEditorTray>
      <TrayItemMessageSequenceItem />
      <TrayItemConditionalSplitSequenceItem />
      <TrayItemDelaySequenceItem />
      <TrayItemUserInputSequenceItem />
      <TrayItemRandomSplitSequenceItem />
      <div className={styles.line} />
      <div className={styles.trayitems}>
        <TrayItemText />
        <TrayItemCard initialButtonUrl={siteUrl} />
        <TrayItemFile />
        <TrayItemAudio />
        <TrayItemMediaTemplateImage />
        <TrayItemGallery initialButtonUrl={siteUrl} />
        <TrayItemMediaTemplateVideo />
        {isReceiptSequence && <TrayItemReceipt />}
        {isFulfillmentSequence && <TrayItemFulfillment initialButtonUrl={siteUrl} />}
        {isReviewSequence && <TrayItemReview />}
        {isAbandonmentSequence && <TrayItemAbandonedCartCarousel />}
      </div>
    </SequenceEditorTray>
  )
}
