import { Button } from 'antd'
import { useState } from 'react'
import { Tooltip } from '../../../../components/ui-kit/tooltip/tooltip.component'
import styles from './save-button.component.scss'

export interface SaveButtonProps {
  onClick: VoidFunction
  disabled: boolean
  saving: boolean
}

export function SaveButton(props: SaveButtonProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  function handleTooltipOpenChange(visible: boolean): void {
    if (props.disabled) {
      setIsTooltipOpen(visible)
    }
  }

  return (
    <Tooltip
      title='You are not able to save this flow until you have not finished the process.'
      placement='bottomRight'
      onOpenChange={handleTooltipOpenChange}
      open={isTooltipOpen}
      arrowPointAtCenter
    >
      <Button
        type='primary'
        size='large'
        onClick={props.onClick}
        className={styles.button}
        disabled={props.saving || props.disabled}
        data-testid='save-sequence-button'
      >
        {props.saving ? 'Saving...' : 'Save'}
      </Button>
    </Tooltip>
  )
}
