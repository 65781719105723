// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JGfHY_1UiGYQFnk847Gg .ant-message-notice-content{background-color:var(--recart-dark-grey-2);color:#fff}.JGfHY_1UiGYQFnk847Gg .ant-message-notice-content .anticon{color:#fff;font-weight:700}.JGfHY_1UiGYQFnk847Gg.Vo7rNKu8isHHgfkAtSMR .ant-message-notice-content{background-color:var(--recart-green-2)}.JGfHY_1UiGYQFnk847Gg.Wl4zMjrqPhGqixAkrzLo .ant-message-notice-content{background-color:var(--recart-red-1)}.JGfHY_1UiGYQFnk847Gg.ohPu_ZfVS7LSNevbc8Uj .ant-message-notice-content{background-color:var(--recart-red-1)}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/toast/toast.component.scss"],"names":[],"mappings":"AAGM,kDAEE,0CAAA,CADA,UACA,CAEA,2DAEE,UAAA,CADA,eACA,CAOF,uEACE,sCAAA,CAOF,uEACE,oCAAA,CAOF,uEACE,oCAAA","sourcesContent":[":local {\n  .toast {\n    :global {\n      .ant-message-notice-content {\n        color: white;\n        background-color: var(--recart-dark-grey-2);\n\n        .anticon {\n          font-weight: bold;\n          color: white;\n        }\n      }\n    }\n\n    &.success {\n      :global {\n        .ant-message-notice-content {\n          background-color: var(--recart-green-2);\n        }\n      }\n    }\n\n    &.error {\n      :global {\n        .ant-message-notice-content {\n          background-color: var(--recart-red-1);\n        }\n      }\n    }\n\n    &.warning {\n      :global {\n        .ant-message-notice-content {\n          background-color: var(--recart-red-1);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `JGfHY_1UiGYQFnk847Gg`,
	"success": `Vo7rNKu8isHHgfkAtSMR`,
	"error": `Wl4zMjrqPhGqixAkrzLo`,
	"warning": `ohPu_ZfVS7LSNevbc8Uj`
};
export default ___CSS_LOADER_EXPORT___;
