import {
  CheckCircleOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  WarningOutlined
} from '@ant-design/icons'
import { message } from 'antd'
import cn from 'classnames'
import styles from './toast.component.scss'

export interface ToastProps {
  type: 'success' | 'error' | 'info' | 'warning' | 'loading'
  content: React.ReactNode
  link?: {
    action: () => void
    text: string
  }
  className?: string
  duration?: number
  key?: string | number
  style?: React.CSSProperties
  isCloseable?: boolean
}

export function Toast(props: ToastProps): void {
  function handleClick(link: { action: () => void; text: string }) {
    message.destroy(props.key)
    link.action()
  }

  function getContent() {
    return (
      <>
        {props.content}
        &nbsp;
        {props.link && (
          <span
            className='text-white underline cursor-pointer'
            onClick={() => {
              handleClick(props.link!)
            }}
          >
            {props.link.text}
          </span>
        )}
        {props.isCloseable && (
          <CloseOutlined
            className='text-base font-semibold ml-3 mr-0'
            onClick={() => {
              message.destroy(props.key)
            }}
          />
        )}
      </>
    )
  }

  const toastConfig = {
    content: getContent(),
    duration: props.isCloseable && !props.duration ? 0 : (props.duration ?? 5),
    key: props.key,
    style: props.style
  }

  switch (props.type) {
    case 'success':
      message.success({
        ...toastConfig,
        className: cn(styles.toast, styles.success, props.className, 'text-base'),
        icon: <CheckCircleOutlined />
      })
      break
    case 'error':
      message.error({
        ...toastConfig,
        className: cn(styles.toast, styles.error, props.className, 'text-base'),
        icon: <WarningOutlined />
      })
      break
    case 'info':
      message.info({
        ...toastConfig,
        className: cn(styles.toast, props.className, 'text-base'),
        icon: <InfoCircleOutlined />
      })
      break
    case 'warning':
      message.warning({
        ...toastConfig,
        className: cn(styles.toast, styles.warning, props.className, 'text-base'),
        icon: <WarningOutlined />
      })
      break
    case 'loading':
      message.loading({ toastConfig })
      break
    default:
      message.info({
        ...toastConfig,
        className: cn(styles.toast, props.className, 'text-base'),
        icon: <InfoCircleOutlined />
      })
  }
}
