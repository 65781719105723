import {
  ButtonActionType,
  type ButtonUI,
  type LinkingMeta,
  type MessageButtonTemplate,
  type MessageMediaTemplate,
  type MessengerUserInputSequenceItemUI,
  UserInputValidationType
} from '@ghostmonitor/recartapis'
import { Divider, Select, Skeleton } from 'antd'
import { ObjectId } from 'bson'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Tooltip } from '../../../../../components/ui-kit/tooltip/tooltip.component'
import { ReactComponent as RemoveIcon } from '../../../../../static/images/svg/streamline/outline/01-Interface-Essential/43-Remove-Add/remove-circle.svg'
import { ReactComponent as UserInputIcon } from '../../../../../static/images/svg/user-input.svg'
import { ReactComponent as TickIcon } from '../../../../../static/images/svg/valid.svg'
import {
  selectCurrencySlug,
  selectDraggedItemType,
  selectEditorSequence,
  selectLinking,
  selectLinkingMeta,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectShowDebugView,
  selectSiteUrl
} from '../../../../../store/selectors'
import {
  createSequenceItem,
  removeSequenceItem,
  updateButton,
  updateMessage,
  updateUserInputType
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import {
  type MessageMeta,
  type SequenceItemMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import {
  type SequenceItemNodeWidgetBaseProps,
  makeSequenceItem
} from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc'
import hocStyles from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc.scss'
import { type LogicPortModel } from '../../../models/port/logic-port-model'
import {
  type UserInputSequenceItemNodeModel,
  quickReplyLabel,
  tooltipInputLabel,
  tooltipInvalidLabel
} from '../../../models/sequence-item/user-input/user-input-sequence-item-model'
import { DEFAULT_SEQUENCE_ITEM_OFFSET } from '../../../types/sequence-editor-constants'
import { getErrorMessage } from '../../../types/sequence-editor-errors'
import {
  isMessageButtonTemplate,
  isMessageMediaTemplate
} from '../../../utils/assert-message-template-type'
import { InlineEditor } from '../../inline-editor/inline-editor.component'
import {
  type InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import { LogicSequenceItemPortWidget } from '../../logic-sequence-item-port-widget/logic-sequence-item-port-widget.component'
import { LegalDisclaimerTemplate } from '../../message-templates/legal-disclaimer-template/legal-disclaimer-template.component'
import classNames from '../../message-templates/messenger-button-template/messenger-button-template.component.scss'
import { WebUrlPopoverWithoutDiscountCodeForm } from '../../messenger-button/popover-forms/form-web-url-without-discount-code.component'
import { QuickReplyPortWidget } from '../../quick-reply-port-widget/quick-reply-port-widget.component'
import { SequenceItemWidgetTitle } from '../sequence-item-widget-title.component'
import styles from './user-input-node-widget.component.scss'

const { Option } = Select

export interface UserInputNodeWidgetProps extends SequenceItemNodeWidgetBaseProps {
  sequenceItemNode: UserInputSequenceItemNodeModel
  repaintCanvas: () => void
}

interface ControlProps {
  onMessageFocus: () => void
  onMessageBlur: () => void
  onMessageChange: (value: { text: string }, messageIndex: number) => void
  text: string
  className?: string
  messageIndex: number
}

export const InteractiveEditor = makeInteractive<InteractiveComponentProps & ControlProps>(
  (props) => {
    return (
      <div
        className={cn(classNames.text, {
          [classNames.blurred]: props.isBlurred,
          [props.className]: props.className
        })}
      >
        <InlineEditor
          isEditable
          multiline
          onChange={(newValue: string) => {
            props.onMessageChange({ text: newValue }, props.messageIndex)
          }}
          onFocus={props.onMessageFocus}
          onBlur={props.onMessageBlur}
          defaultValue={props.text}
          characterLimit={640}
          type='messenger'
        />
      </div>
    )
  }
)

export interface UserInputComponentProps {
  skipPort: LogicPortModel
  inputIsValidPort: LogicPortModel
  sequenceItemNode: UserInputSequenceItemNodeModel
  sequenceItemMeta: SequenceItemMeta
  sequenceItem: MessengerUserInputSequenceItemUI
  linkingMeta: LinkingMeta
  linking: boolean
  waitForFacebookUpload?: boolean

  onUpdateInputType: (value: string) => void
  onUpdateMessage: (value: { text: string }, messageIndex: number) => void
  onUpdateButton: (
    messageIndex: number,
    buttonIndex: number,
    change: Partial<ButtonUI>,
    messageItemIndex?: number
  ) => void
  repaintCanvas: () => void
}

export function UserInputComponent(props: UserInputComponentProps) {
  const userInputType = props.sequenceItem?.logic.userInput.type

  let legalDisclaimerMessage: MessageMediaTemplate = null
  let legalDisclaimerMessageMeta: MessageMeta = null
  const textMessages: MessageButtonTemplate[] = []
  const textMessagesMeta: MessageMeta[] = []
  const textMessagesStartingIndex = userInputType === UserInputValidationType.PHONE ? 1 : 0

  const selectedButtonTypeOptions = [
    {
      value: ButtonActionType.web_url,
      label: 'Open a website',
      content: WebUrlPopoverWithoutDiscountCodeForm
    }
  ]

  if (props.sequenceItem) {
    props.sequenceItem.messages.forEach((message, messageIndex) => {
      if (isMessageButtonTemplate(message)) {
        textMessages.push(message)
        textMessagesMeta.push(props.sequenceItemMeta.messages[messageIndex])
      } else if (isMessageMediaTemplate(message)) {
        legalDisclaimerMessage = message
        legalDisclaimerMessageMeta = props.sequenceItemMeta.messages[messageIndex]
      }
    })
  }

  const isLoading =
    !props.sequenceItem ||
    (props.waitForFacebookUpload && !legalDisclaimerMessage?.messengerTemplatePayload.attachment_id)

  function handleMessageFocus() {
    return undefined
  }

  function handleMessageBlur() {
    return undefined
  }

  function handleMessageChange(value: { text: string }, messageIndex: number) {
    props.onUpdateMessage(value, messageIndex)
  }

  function handleUpdateInputType(logicType: UserInputValidationType) {
    props.onUpdateInputType(logicType)
    props.repaintCanvas()
  }

  return (
    <div>
      <div className={hocStyles.title}>Input type</div>
      <Select
        value={props.sequenceItem?.logic.userInput.type}
        className={styles['full-width']}
        onChange={handleUpdateInputType}
        data-testid='input-type'
        aria-controls='test-uuid'
      >
        <Option
          value={UserInputValidationType.PHONE}
          data-testid={`input-type-${UserInputValidationType.PHONE}`}
        >
          Phone number
        </Option>
        <Option
          value={UserInputValidationType.EMAIL}
          data-testid={`input-type-${UserInputValidationType.EMAIL}`}
        >
          Email address
        </Option>
      </Select>
      <Divider className={styles['first-divider']} />
      {isLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 4 }} />
      ) : (
        <>
          {userInputType === UserInputValidationType.PHONE && (
            <LegalDisclaimerTemplate
              messengerTemplatePayload={legalDisclaimerMessage.messengerTemplatePayload}
              buttonTypeOptions={selectedButtonTypeOptions}
              onButtonChange={(...args) => {
                props.onUpdateButton(0, ...args)
              }}
              messageMeta={legalDisclaimerMessageMeta}
            />
          )}
          <InteractiveEditor
            data-testid='message-input'
            errorMessage={getErrorMessage(textMessagesMeta[0]?.errors[0])}
            isBlurred={false}
            isFirst
            isLast
            isSelected={false}
            messageIndex={textMessagesStartingIndex}
            onMessageBlur={handleMessageBlur}
            onMessageChange={handleMessageChange}
            onMessageFocus={handleMessageFocus}
            removeEnabled={false}
            text={textMessages[0].messengerTemplatePayload.text}
          />
          <Tooltip
            placement='right'
            overlayClassName={styles.tooltip}
            title={tooltipInputLabel[userInputType]}
          >
            <div className={styles['quick-reply-like-grey-box']} data-testid='user-phone-number'>
              {quickReplyLabel[userInputType]}
            </div>
          </Tooltip>
        </>
      )}
      <Divider className={styles['second-divider']}>
        <div className={styles.content}>
          <RemoveIcon className={styles.icon} />
          <div className={styles.label}>IF INPUT IS INVALID</div>
        </div>
      </Divider>
      {isLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 4 }} />
      ) : (
        <>
          <InteractiveEditor
            data-testid='message-invalid'
            errorMessage={getErrorMessage(textMessagesMeta[1]?.errors[0])}
            isBlurred={false}
            isFirst
            isLast
            isSelected={false}
            messageIndex={textMessagesStartingIndex + 1}
            onMessageBlur={handleMessageBlur}
            onMessageChange={handleMessageChange}
            onMessageFocus={handleMessageFocus}
            removeEnabled={false}
            text={textMessages[1].messengerTemplatePayload.text}
          />
          <Tooltip
            placement='right'
            overlayClassName={styles.tooltip}
            title={tooltipInvalidLabel[userInputType]}
          >
            <div className={styles['quick-reply-like-grey-box']}>
              {quickReplyLabel[userInputType]}
            </div>
          </Tooltip>
        </>
      )}
      <QuickReplyPortWidget
        blurred={false}
        className={styles['quick-reply']}
        error=''
        isDragDisabled
        isEditable={false}
        isRemoveDisabled
        title='Skip'
        linking={props.linking}
        linkingMeta={props.linkingMeta}
        onPortBlur={() => undefined}
        onPortFocus={() => undefined}
        onRemoveQuickReply={() => undefined}
        onUpdateQuickReply={() => undefined}
        port={props.skipPort}
        sequenceItemNode={props.sequenceItemNode}
        sorting={false}
      />
      <Divider className={styles['third-divider']}>
        <div className={styles.content}>
          <TickIcon className={styles.icon} />
          <div className={styles.label}>IF INPUT IS VALID</div>
        </div>
      </Divider>
      <LogicSequenceItemPortWidget
        linking={props.linking}
        linkingMeta={props.linkingMeta}
        node={props.sequenceItemNode}
        port={props.inputIsValidPort}
      />
    </div>
  )
}

export const UserInputSequenceItemWidget = makeSequenceItem(
  UserInputComponent,
  [hocStyles['user-input-wrapper']],
  <SequenceItemWidgetTitle wrapperClasses={[]} icon={<UserInputIcon />} text='Save User Input' />
)
export function UserInputSequenceItemNodeWidgetViewComponent(props: UserInputNodeWidgetProps) {
  const dispatch = useDispatch()

  const stateSelector = useCallback(
    createStructuredSelector({
      siteUrl: selectSiteUrl,
      draggedItemType: selectDraggedItemType,
      linking: selectLinking,
      linkingMeta: selectLinkingMeta,
      sequence: selectEditorSequence,
      sequenceItem: selectSequenceItem(props.sequenceItemNode.sequenceItemId),
      sequenceItemMeta: selectSequenceItemMeta(props.sequenceItemNode.sequenceItemId),
      currencySlug: selectCurrencySlug,
      showDebugView: selectShowDebugView
    }),
    [props.sequenceItemNode.sequenceItemId]
  )

  const state = useSelector(stateSelector)

  const isEntry = props.sequenceItemNode.sequenceItemId === state.sequence?.entrySequenceItemId
  const inputPort = props.sequenceItemNode.getInputPort()
  const skipPort = props.sequenceItemNode.getSkipPort()
  const inputIsValidPort = props.sequenceItemNode.getInputIsValidPort()
  const [removing, setRemoving] = useState(false)

  function handleRepaintCanvas() {
    if (!removing) {
      props.repaintCanvas()
    }
  }

  function handleRemoveSequenceItem() {
    setRemoving(true)
    dispatch(removeSequenceItem(props.sequenceItemNode.sequenceItemId))
  }

  function handleCloneSequenceItem() {
    const newSequenceItem = cloneDeep(state.sequenceItem) as MessengerUserInputSequenceItemUI
    const newSequenceItemId = new ObjectId().toHexString()
    const validationId = new ObjectId().toHexString()
    newSequenceItem._id = newSequenceItemId
    newSequenceItem.sequenceItemGroupId = new ObjectId().toHexString()
    newSequenceItem.sequenceItemIdsMap = {
      logic: new ObjectId().toHexString(),
      messages: newSequenceItemId,
      validation: validationId
    }
    newSequenceItem.trigger = [newSequenceItemId]
    newSequenceItem.tags = [...state.sequence.tags]

    newSequenceItem.logic.userInput.input_skipped_trigger = null
    newSequenceItem.logic.userInput.trigger = null
    newSequenceItem.logic.userInput.validation_item = validationId

    dispatch(
      createSequenceItem(newSequenceItem, {
        x: props.sequenceItemNode.x + DEFAULT_SEQUENCE_ITEM_OFFSET.x,
        y: props.sequenceItemNode.y + DEFAULT_SEQUENCE_ITEM_OFFSET.y
      })
    )
  }

  function handleUpdateInputType(inputType: UserInputValidationType) {
    dispatch(updateUserInputType(props.sequenceItemNode.sequenceItemId, inputType, state.siteUrl))
  }

  function handleUpdateMessage(value: { text: string }, messageIndex: number) {
    const text = value.text
    const sequenceItemId = props.sequenceItemNode.sequenceItemId
    dispatch(updateMessage(sequenceItemId, messageIndex, { text }))
  }

  function handleUpdateButton(
    messageIndex: number,
    buttonIndex: number,
    change: Partial<ButtonUI>
  ) {
    dispatch(updateButton(state.sequenceItem._id, messageIndex, buttonIndex, change))
  }

  return (
    <UserInputSequenceItemWidget
      faded={!!state.draggedItemType}
      inputIsValidPort={inputIsValidPort}
      inputPort={inputPort}
      isEntry={isEntry}
      linking={state.linking}
      linkingMeta={state.linkingMeta}
      sequenceItem={state.sequenceItem as MessengerUserInputSequenceItemUI}
      sequenceItemMeta={state.sequenceItemMeta}
      sequenceItemNode={props.sequenceItemNode}
      showDropZone={false}
      showSequenceItemStatistics={false}
      showDebugView={state.showDebugView}
      skipPort={skipPort}
      waitForFacebookUpload={false}
      onClone={handleCloneSequenceItem}
      onRemove={handleRemoveSequenceItem}
      onUpdateInputType={handleUpdateInputType}
      onUpdateMessage={handleUpdateMessage}
      onUpdateButton={handleUpdateButton}
      currencySlug={state.currencySlug}
      repaintCanvas={handleRepaintCanvas}
    />
  )
}

export const UserInputSequenceItemNodeWidget = memo(UserInputSequenceItemNodeWidgetViewComponent)
