import { SequenceTag } from '@ghostmonitor/recartapis'
import { JoinedASegmentDrawer } from '../../../../../components/editor-drawer/components/joined-a-segment/joined-a-segment.component'
import { LoopWorkDrawer } from '../../../../../components/editor-drawer/components/loopwork/loopwork.component'
import { WondermentDrawer } from '../../../../../components/editor-drawer/components/wonderment/wonderment.component'
import { type AppDispatch } from '../../../../../store/create-store'
import type { SyncThunk } from '../../../../../store/redux-helpers'
import { selectEditorSequence, selectSiteId } from '../../../../../store/selectors'
import { toggleSettingsDrawer } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { loadBlastThunk } from '../../../../../store/slices/sequence-editor/thunks/load-blast.thunk'
import { saveBlastThunk } from '../../../../../store/slices/sequence-editor/thunks/save-blast.thunk'
import { toggleSequenceThunk } from '../../../../../store/slices/sequence-editor/thunks/toggle-sequence.thunk'
import { validateBlastThunk } from '../../../../../store/slices/sequence-editor/thunks/validate-blast.thunk'
import { isCustomWelcomeSequence } from '../../../../../utils/is-custom-welcome-sequence'
import { BlastHeader } from '../../../components/header/blast-header.component'
import { JoinedASegmentHeader } from '../../../components/header/joined-a-segment-header.component'
import { SMSHeader } from '../../../components/header/sms-header.component'
import {
  cartUrl,
  fulfillmentUrl,
  loopworkGeneralVariableTypeOptions,
  loopworkVariableTypeOptions,
  orderStatusUrl,
  productName,
  productUrl,
  checkoutAbandonmentRecoveryLink,
  restoreLink,
  smsVariableTypeOptions,
  wondermentVariableTypeOptions
} from '../../../components/inline-editor/components/make-variable-input/variable-type-options'
import { CanvasToolbarSettings } from '../../../components/sequence-editor-canvas/toolbar-items/canvas-toolbar-settings.component'
import { SequenceItemStatsBarSMS } from '../../../components/sequence-item-stats-bar/sequence-item-stats-bar-sms.component'
import { SMSTray } from '../../../components/tray/sms-tray/sms-tray.component'
import { SMS_MAX_DELAY_DAYS } from '../../../config'
import { setSequenceEditorSetting } from '../../../hooks/use-sequence-editor-settings-context'
import {
  isJoinedASegmentSequence,
  isLoopWorkSequence,
  isSMSSequence,
  isWelcomeSequence,
  isWondermentSequence
} from '../../../utils/assert-sequence-type'
import { initialCanvasToolbarItems } from '../../context/defaults/canvas-toolbar-items'
import { initialConditionalSplitConditions } from '../../context/defaults/conditional-split-conditions'
import { initialLeaveEditorModal } from '../../context/defaults/leave-editor-modal'
import { initialSaveThunks } from '../../context/defaults/sequence-thunks'
import { SequenceEditorSettings } from '../../context/sequence-editor-settings.type'

function enableSequence(): SyncThunk {
  return async (dispatch: AppDispatch) => {
    await dispatch(toggleSequenceThunk(true))
  }
}

export function smsThunk(): SyncThunk {
  return async (dispatch, getState) => {
    const state = getState()
    const sequence = selectEditorSequence(state)
    const siteId = selectSiteId(state)

    if (!isSMSSequence(sequence)) {
      return
    }

    setSequenceEditorSetting(
      SequenceEditorSettings.conditionalSplitConditions,
      initialConditionalSplitConditions.filter((condition) => condition.channels.includes('sms'))
    )

    /*
      SMS Sequences
    */
    setSequenceEditorSetting(SequenceEditorSettings.tray, SMSTray)
    setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, smsVariableTypeOptions)
    setSequenceEditorSetting(SequenceEditorSettings.maxDelay, SMS_MAX_DELAY_DAYS * 24)
    setSequenceEditorSetting(
      SequenceEditorSettings.maxDelayNotificationText,
      `Delay can not be more than ${SMS_MAX_DELAY_DAYS} days.`
    )
    setSequenceEditorSetting(SequenceEditorSettings.hasDaysDelay, true)
    setSequenceEditorSetting(
      SequenceEditorSettings.entrySequenceItemStatsBarComponent,
      SequenceItemStatsBarSMS
    )
    setSequenceEditorSetting(
      SequenceEditorSettings.sequenceItemStatsBarComponent,
      SequenceItemStatsBarSMS
    )
    setSequenceEditorSetting(SequenceEditorSettings.hideMessengerSendTestButton, true)

    /*
      SMS Campaign / Blast
    */
    if (sequence.tags.includes(SequenceTag.CAMPAIGN)) {
      setSequenceEditorSetting(SequenceEditorSettings.header, BlastHeader)
      setSequenceEditorSetting(SequenceEditorSettings.isConditionalSplitEnabled, false)
      setSequenceEditorSetting(
        SequenceEditorSettings.saveThunks,
        [validateBlastThunk, ...initialSaveThunks, saveBlastThunk, enableSequence],
        'SMS Campaign'
      )

      setSequenceEditorSetting(SequenceEditorSettings.leaveEditorModal, {
        ...initialLeaveEditorModal,
        title: 'Unsaved campaign',
        content: "You haven't saved your campaign yet, are you sure you want to leave the page?",
        okText: 'Save as draft',
        cancelText: 'Discard changes'
      })

      await dispatch(loadBlastThunk({ siteId, sequenceId: sequence._id }))

      setSequenceEditorSetting(SequenceEditorSettings.canvasToolbarItems, [
        ...initialCanvasToolbarItems,
        CanvasToolbarSettings
      ])

      dispatch(toggleSettingsDrawer({ isVisible: true }))

      /*
      Other SMS Sequence
    */
    } else {
      setSequenceEditorSetting(SequenceEditorSettings.header, SMSHeader)

      /*
        SMS Welcome Sequence
      */
      if (isWelcomeSequence(sequence)) {
        setSequenceEditorSetting(SequenceEditorSettings.isLegalMessageNeeded, true)
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          restoreLink
        ])

        /*
          SMS Custom Welcome Sequence
        */
        if (isCustomWelcomeSequence(sequence)) {
          setSequenceEditorSetting(
            SequenceEditorSettings.saveThunks,
            [...initialSaveThunks, enableSequence],
            'isCustomWelcomeSequence - SMS'
          )
        }
      }

      /*
        SMS Help Sequence
      */
      if (['sms-help-flow'].includes(sequence.source?.templateSlug)) {
        setSequenceEditorSetting(
          SequenceEditorSettings.saveThunks,
          [...initialSaveThunks, enableSequence],
          'sms-help-flow'
        )
      }

      /*
        SMS Unsubscriber / Help / Welcome Popup
      */
      if (
        [SequenceTag.HELP, SequenceTag.SMS_WELCOME_POPUP].some((tag) => {
          return sequence.tags.includes(tag)
        })
      ) {
        setSequenceEditorSetting(SequenceEditorSettings.isEnabledLocked, true)
      }

      /*
        SMS Receipt
      */
      if (sequence.tags.includes(SequenceTag.RECEIPT)) {
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          orderStatusUrl
        ])
      }

      /*
        SMS Abandonment
      */
      if (sequence.tags.includes(SequenceTag.ABANDONMENT)) {
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          cartUrl
        ])
      }

      /*
        SMS Browse abandonment
      */
      if (sequence.tags.includes(SequenceTag.BROWSE_ABANDONMENT)) {
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          productName,
          productUrl
        ])
      }
      /*
        SMS Checkout abandonment
      */
      if (sequence.tags.includes(SequenceTag.CHECKOUT_ABANDONMENT)) {
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          checkoutAbandonmentRecoveryLink
        ])
      }

      /*
        SMS Fulfillment
      */
      if (sequence.tags.includes(SequenceTag.FULFILLMENT)) {
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          fulfillmentUrl
        ])
      }

      /*
        Joined a Segment (UI name: Custom triggers)
      */
      if (isJoinedASegmentSequence(sequence)) {
        setSequenceEditorSetting(SequenceEditorSettings.leaveEditorModal, {
          ...initialLeaveEditorModal,
          content: "You haven't saved your flow yet, are you sure you want to leave the page?"
        })
        setSequenceEditorSetting(SequenceEditorSettings.header, JoinedASegmentHeader)
        setSequenceEditorSetting(SequenceEditorSettings.drawer, JoinedASegmentDrawer)
        dispatch(toggleSettingsDrawer({ isVisible: true }))
      }

      /*
        Wonderment
      */
      if (isWondermentSequence(sequence)) {
        setSequenceEditorSetting(SequenceEditorSettings.drawer, WondermentDrawer)
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          ...wondermentVariableTypeOptions
        ])
        dispatch(toggleSettingsDrawer({ isVisible: true }))
      }

      /*
        Loop
      */
      if (isLoopWorkSequence(sequence)) {
        const isLoopBillingFailedSequence = [
          SequenceTag.LOOPWORK_BILLING_ATTEMPT_FAILED_CANCELED,
          SequenceTag.LOOPWORK_BILLING_ATTEMPT_FAILED_LAST,
          SequenceTag.LOOPWORK_BILLING_ATTEMPT_FAILED_RETRY
        ].some((tag) => sequence.tags.includes(tag))

        setSequenceEditorSetting(SequenceEditorSettings.drawer, LoopWorkDrawer)
        setSequenceEditorSetting(SequenceEditorSettings.variableTypeOptions, [
          ...smsVariableTypeOptions,
          ...(isLoopBillingFailedSequence
            ? loopworkVariableTypeOptions
            : loopworkGeneralVariableTypeOptions)
        ])
        dispatch(toggleSettingsDrawer({ isVisible: true }))
      }
    }
  }
}
