import { type DiscountCodePool, isSMSSequenceItemUI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { selectEditorSequence, selectSequenceItemsById } from '../store/selectors'
import { hooks } from './hooks'

export interface UseDiscountPoolAttachedToSequence {
  data: DiscountCodePool[]
  isLoading: boolean
}

export function useDiscountPoolAttachedToSequence(): UseDiscountPoolAttachedToSequence {
  const { data: discountCodePools, isLoading: isDiscountCodePoolsLoading } =
    hooks.useDiscountCodePools()
  const sequence = useSelector(selectEditorSequence)

  const sequenceItemsById = useSelector(selectSequenceItemsById)
  const sequenceItems = sequence.sequenceItemIds.map(
    (sequenceItemId) => sequenceItemsById[sequenceItemId]
  )

  const attachedDiscountPoolIds = sequenceItems
    ?.filter((item) => {
      if (isSMSSequenceItemUI(item) && item.discountCodePoolId) {
        return item
      }
      return null
    })
    .map((item) => {
      if (isSMSSequenceItemUI(item)) {
        return item.discountCodePoolId
      }
      return null
    })
  const attachedDiscountPools = discountCodePools?.filter((pool) =>
    attachedDiscountPoolIds.includes(pool.id)
  )

  return {
    isLoading: isDiscountCodePoolsLoading,
    data: discountCodePools && attachedDiscountPools
  }
}
