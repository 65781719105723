import { type FlowDiagram, type FlowUI } from '@ghostmonitor/recartapis'
import { produce } from 'immer'

export function sanitizeNodes(
  nodes: FlowDiagram.Node[],
  sanitizePositions: boolean
): FlowDiagram.Node[] {
  return produce(nodes, (draftNodes) => {
    draftNodes.forEach((node) => {
      delete node.width
      delete node.height
      delete node.selected
      delete node.dragging

      if (sanitizePositions) {
        delete node.position
        delete node.positionAbsolute
      }
    })
  })
}

export function sanitizeEdges(edges: FlowDiagram.Edge[]): FlowDiagram.Edge[] {
  return produce(edges, (draftNodes) => {
    draftNodes.forEach((edge) => {
      delete edge.selected
    })
  })
}

export function sanitizeFlow(flow: FlowUI, sanitizePositions: boolean): FlowUI {
  return produce(flow, (draftFlow) => {
    draftFlow.flowEditorDiagram.nodes = sanitizeNodes(
      draftFlow.flowEditorDiagram.nodes,
      sanitizePositions
    )

    draftFlow.flowEditorDiagram.edges = sanitizeEdges(draftFlow.flowEditorDiagram.edges)
  })
}
