import { type Action, type Store, isAnyOf } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { type AppDispatch } from '../../../store/create-store'
import { type DashboardState } from '../../../store/dashboard.state'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'

const DEBOUNCE_DELAY = 500

const getUpdateKeywordReplyTagOnFlow = (dispatch: AppDispatch) =>
  debounce(
    () => {
      dispatch(flowEditorActions.updateKeywordReplyTagOnFlow())
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )

export function updateKeywordReplyTagOnFlowMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  const updateKeywordReplyTagOnFlow = getUpdateKeywordReplyTagOnFlow(dispatch)

  return (next) => (action: Action) => {
    next(action)

    if (isAnyOf(flowEditorActions.updateKeywordRepliesOnFlowItem)(action)) {
      updateKeywordReplyTagOnFlow()
    }
    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      updateKeywordReplyTagOnFlow.cancel()
    }
  }
}
