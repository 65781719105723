import { NodeType } from '@ghostmonitor/recartapis'
import { trayIcons } from '../tray-icons'
import { TrayItemComponent } from '../tray-item.component'
import styles from './tray-item-sequence-item.component.scss'

export function TrayItemMessageSequenceItem() {
  return (
    <TrayItemComponent
      itemType={NodeType.MESSAGE_SEQUENCE_ITEM}
      icon={trayIcons.message}
      iconClassName={styles.messageIcon}
      title='New Message'
      sequenceItem
    />
  )
}

export function TrayItemSMSSequenceItem() {
  return (
    <TrayItemComponent
      itemType={NodeType.SMS_SEQUENCE_ITEM}
      icon={trayIcons.message}
      iconClassName={styles.messageIcon}
      title='New SMS'
      sequenceItem
    />
  )
}

export function TrayItemConditionalSplitSequenceItem() {
  return (
    <TrayItemComponent
      itemType={NodeType.CONDITIONAL_SPLIT_SEQUENCE_ITEM}
      icon={trayIcons.condition}
      iconClassName={styles.conditionIcon}
      title='Condition'
      sequenceItem
    />
  )
}

export function TrayItemDelaySequenceItem() {
  return (
    <TrayItemComponent
      itemType={NodeType.DELAY_SEQUENCE_ITEM}
      icon={trayIcons.delay}
      iconClassName={styles.delayIcon}
      title='Delay'
      sequenceItem
    />
  )
}

export function TrayItemUserInputSequenceItem() {
  return (
    <TrayItemComponent
      itemType={NodeType.USER_INPUT_SEQUENCE_ITEM}
      icon={trayIcons.userInput}
      iconClassName={styles.userInputIcon}
      title='Save User Input'
      sequenceItem
    />
  )
}

export function TrayItemRandomSplitSequenceItem() {
  return (
    <TrayItemComponent
      itemType={NodeType.RANDOM_SPLIT_SEQUENCE_ITEM}
      icon={trayIcons.randomSplit}
      iconClassName={styles.randomSplitIcon}
      title='Randomizer'
      sequenceItem
    />
  )
}
