import { type SMSMessageFlowItemUI } from '@ghostmonitor/recartapis'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'

export function validateKeywordReplyFlowItemMustHaveConnection(
  flowItem: SMSMessageFlowItemUI
): FlowEditorValidationError | undefined {
  if (flowItem.item.replyKeywordSettings === undefined) {
    return undefined
  }

  const allHandlesConnected = flowItem.item.replyKeywordSettings.keywords.every(
    (keyword) => !!keyword.trigger
  )

  if (allHandlesConnected) {
    return undefined
  }

  return {
    message: FlowEditorError.KeywordReplyFlowItemMustHaveConnection,
    level: FlowEditorErrorLevel.Error
  }
}
