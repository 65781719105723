import { type QuickReply } from '@ghostmonitor/recartapis'
import { type Action, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import type { AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { selectQuickReply } from '../../../../store/selectors'
import {
  quickReplyValidationError,
  quickReplyValidationSuccess,
  type UpdateQuickReplyAction,
  UPDATE_QUICK_REPLY
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { quickReplyValidator } from '../../validators/quick-reply.validator'

const DEBOUNCE_DELAY = 400

function getValidateQuickReply(dispatch: AppDispatch) {
  return debounce(
    (quickReply: QuickReply, action: UpdateQuickReplyAction) => {
      const quickReplyErrors = quickReplyValidator(quickReply)
      if (quickReplyErrors.length > 0) {
        dispatch(
          quickReplyValidationError(quickReplyErrors[0], {
            sequenceItemId: action.sequenceItemId,
            quickReplyIndex: action.quickReplyIndex
          })
        )
      } else {
        dispatch(
          quickReplyValidationSuccess(null, {
            sequenceItemId: action.sequenceItemId,
            quickReplyIndex: action.quickReplyIndex
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

function isValidableAction(action: Action): action is UpdateQuickReplyAction {
  return action.type === UPDATE_QUICK_REPLY
}

export function validateQuickReplyMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateQuickReply = getValidateQuickReply(dispatch)

  return (next) => (action: ValidatableAction) => {
    next(action)

    if (isValidableAction(action)) {
      const state = store.getState()
      const quickReply = selectQuickReply(action.sequenceItemId, action.quickReplyIndex)(state)

      validateQuickReply(quickReply, action)
    }
  }
}
