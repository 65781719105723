// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B4izG_WDtHFD105HSCEg{align-items:center;display:flex;height:65px;padding:var(--recart-spacing-3);padding-right:0}.fRwfPqx3GScwpcok7tcA{color:#212b36;max-width:140px}.i4vze94cbnM24rQCLDzU{align-items:center;background-repeat:no-repeat;background-size:cover;border-radius:50%;color:#fff;display:flex;justify-content:center;margin-left:var(--recart-spacing-1);text-align:center}.DhTeOHh3L7g_gBOItfU7{font-size:var(--recart-font-size-base);font-weight:var(--recart-font-weight-semibold);height:24px;line-height:var(--recart-line-height-base);width:24px}.PYxLufS9rjJrZM1ILRGB{color:#d9d9d9}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/message-templates/sms-contact-card-template/sms-contact-card-template.component.scss"],"names":[],"mappings":"AACE,sBAKE,kBAAA,CADA,YAAA,CAHA,WAAA,CACA,+BAAA,CACA,eAEA,CAGF,sBAEE,aAAA,CADA,eACA,CAGF,sBAOE,kBAAA,CAFA,2BAAA,CADA,qBAAA,CAHA,iBAAA,CAQA,UAAA,CAHA,YAAA,CAEA,sBAAA,CANA,mCAAA,CACA,iBAMA,CAGF,sBAGE,sCAAA,CAEA,8CAAA,CAHA,WAAA,CAEA,0CAAA,CAHA,UAIA,CAGF,sBACE,aAAA","sourcesContent":[":local {\n  .contactCardPreviewMini {\n    height: 65px;\n    padding: var(--recart-spacing-3);\n    padding-right: 0;\n    display: flex;\n    align-items: center;\n  }\n\n  .miniPreviewText {\n    max-width: 140px;\n    color: #212b36;\n  }\n\n  .previewImage {\n    border-radius: 50%;\n    margin-left: var(--recart-spacing-1);\n    text-align: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n  }\n\n  .mini {\n    width: 24px;\n    height: 24px;\n    font-size: var(--recart-font-size-base);\n    line-height: var(--recart-line-height-base);\n    font-weight: var(--recart-font-weight-semibold);\n  }\n\n  .iconColor {\n    color: #d9d9d9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactCardPreviewMini": `B4izG_WDtHFD105HSCEg`,
	"miniPreviewText": `fRwfPqx3GScwpcok7tcA`,
	"previewImage": `i4vze94cbnM24rQCLDzU`,
	"mini": `DhTeOHh3L7g_gBOItfU7`,
	"iconColor": `PYxLufS9rjJrZM1ILRGB`
};
export default ___CSS_LOADER_EXPORT___;
