import { type MessengerMediaTemplatePayload } from '@ghostmonitor/recartapis'
import { Image } from '../../file-editor/components/image.component'
import { makeInteractive } from '../../interactive-component/make-interactive.hoc'
import {
  type ButtonProps,
  MessengerButton
} from '../../messenger-button/messenger-button.component'
import { type MessengerTemplateProps } from '../messenger-template-props.type'
import styles from './legal-disclaimer-template.component.scss'

const InteractiveButton = makeInteractive<ButtonProps>(MessengerButton)

type LegalDisclaimerTemplateProps = Pick<
  MessengerTemplateProps<MessengerMediaTemplatePayload>,
  'messengerTemplatePayload' | 'buttonTypeOptions' | 'onButtonChange' | 'messageMeta'
>

export function LegalDisclaimerTemplate(props: LegalDisclaimerTemplateProps) {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image
          input={{
            value: props.messengerTemplatePayload.url
          }}
          loadFinished={() => null}
          isGallery={false}
        />
      </div>

      {props.messengerTemplatePayload.buttons.length > 0 && (
        <div className={styles.buttonContainer}>
          {props.messengerTemplatePayload.buttons.map((buttonPayload, buttonIndex) => {
            return (
              <div key={`button-${buttonIndex}`} data-testid='media-button'>
                <InteractiveButton
                  buttonTypeOptions={props.buttonTypeOptions}
                  disableTypeChange
                  payload={buttonPayload}
                  isBlurred={false}
                  isSelected={false}
                  sequenceItemId={null}
                  onChange={(...args) => {
                    props.onButtonChange(buttonIndex, ...args)
                  }}
                  linking={false}
                  linkingMeta={null}
                  onFocus={() => null}
                  onBlur={() => null}
                  removeEnabled={false}
                  isLast={props.messengerTemplatePayload.buttons.length - 1 === buttonIndex}
                  disableEdit
                  errorMessage={props.messageMeta?.buttons[buttonIndex]?.error}
                  hasError={props.messageMeta?.buttons[buttonIndex]?.error !== null}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
