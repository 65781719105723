import { type FlowItemUI } from '@ghostmonitor/recartapis'
import {
  isConditionalSplitFlowItemUI,
  isConditionalSplitV2FlowItemUI,
  isDelayFlowItemUI,
  isRandomSplitFlowItemUI,
  isSMSMessageFlowItemUI
} from '../../../../../types/guards/flow-item-ui.guards'
import { smsItemValidator } from './sms/sms-validator'
import { type DashboardState } from '../../../../dashboard.state'
import { delayValidator } from './logic/delay-validator'
import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type PayloadFlowItemValidation,
  type PayloadMessageValidationFailed,
  type PayloadMessageValidationSucceeded
} from '../../flow-editor.actions'
import { randomSplitValidator } from './logic/random-split-validator'
import { type ValidateFlowThunkBypassWarnings } from '../validate-flow.thunk'
import { conditionalSplitV1Validator } from './logic/conditional-split-v1-validator'
import { conditionalSplitV2Validator } from './logic/conditional-split-v2-validator'
import { type FlowEditorValidationError } from '../../../../../utils/flow-editor/flow-editor-errors'

export type Action = PayloadAction<PayloadFlowItemValidation>

export interface Validator {
  validate: () => Promise<FlowEditorValidationError[]>
}

export interface MessageValidationResult {
  succeeded: PayloadMessageValidationSucceeded[]
  failed: PayloadMessageValidationFailed[]
}

export interface ValidationResult {
  message: MessageValidationResult
  item: {
    errors: FlowEditorValidationError[]
  }
}

export interface ItemValidationRunner {
  getConfiguredValidators: () => { [key: string]: Validator }
}

export function getItemValidator(
  flowItem: FlowItemUI,
  state: DashboardState,
  { bypassWarnings }: ValidateFlowThunkBypassWarnings
) {
  switch (true) {
    case isSMSMessageFlowItemUI(flowItem):
      return smsItemValidator(flowItem, state)
    case isDelayFlowItemUI(flowItem):
      return delayValidator(flowItem, state)
    case isRandomSplitFlowItemUI(flowItem):
      return randomSplitValidator(flowItem, bypassWarnings?.randomSplitHasDisconnectedSplits)
    case isConditionalSplitFlowItemUI(flowItem):
      return conditionalSplitV1Validator(flowItem, state)
    case isConditionalSplitV2FlowItemUI(flowItem):
      return conditionalSplitV2Validator(flowItem)
  }

  return undefined
}
