import { type AnyAction, isAnyOf, type Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { type AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { flowEditorActions } from '../../../../store/slices/flow-editor/flow-editor.reducer'
import { validateFlowItemThunk } from '../../../../store/slices/flow-editor/thunks/validate-flow-item.thunk'

const DEBOUNCE_DELAY = 100

export interface ConditionalSplitV2ValidationArgs {
  flowItemId: string
}

function getValidateConditionalSplitV2(dispatch: AppDispatch) {
  return debounce(
    (args: ConditionalSplitV2ValidationArgs) => {
      const { flowItemId } = args
      dispatch(
        validateFlowItemThunk({
          flowItemId,
          validators: {
            conditionExpression: true
          }
        })
      )
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

export const getValidateConditionalSplitV2Action = (store: Store<DashboardState>) => {
  const dispatch: AppDispatch = store.dispatch
  const validateConditionalSplitV2 = getValidateConditionalSplitV2(dispatch)

  return (store: Store<DashboardState>, action: AnyAction) => {
    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      validateConditionalSplitV2.cancel()
    }

    if (
      isAnyOf(
        flowEditorActions.conditionV2ValueChanged,
        flowEditorActions.conditionV2ValueDeleted
      )(action)
    ) {
      const { flowItemId } = action.payload

      validateConditionalSplitV2({ flowItemId })
    }
  }
}
