// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wm0kCrMZPZMGQHICSRGw{min-width:30px}.wm0kCrMZPZMGQHICSRGw svg{width:20px}.ROzH0t2T_NIW5NVfdtG2{color:#595959;font-family:Lato,sans-serif;line-height:14px!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/popover-edit-discount-code-unique/components/discount-code-logos/discount-code-logos.component.scss"],"names":[],"mappings":"AACE,sBACE,cAAA,CAEA,0BACE,UAAA,CAIJ,sBAEE,aAAA,CADA,2BAAA,CAEA,0BAAA","sourcesContent":[":local {\n  .logo {\n    min-width: 30px;\n\n    svg {\n      width: 20px;\n    }\n  }\n\n  .text {\n    font-family: Lato, sans-serif;\n    color: #595959;\n    line-height: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `wm0kCrMZPZMGQHICSRGw`,
	"text": `ROzH0t2T_NIW5NVfdtG2`
};
export default ___CSS_LOADER_EXPORT___;
