import { type MessageMeta } from '../../../store/slices/sequence-editor/sequence-editor.state'

export function isAnyButtonSelected(messageMeta: MessageMeta): boolean {
  for (const buttonMeta of messageMeta.buttons) {
    if (buttonMeta.selected) {
      return true
    }
  }
  return false
}
