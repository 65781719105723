import { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { Login } from '../../routes/auth/login/login.component'
import {
  LazyAppLayoutRoute,
  LazyAuthRoute,
  LazyErrorRoute,
  LazyResetPasswordRoute
} from '../../routing-utilities/lazy/lazy'
import { store } from '../../store/create-store'
import { updateLocation } from '../../store/slices/location/location.reducer'
import '../../styles/core.scss'
import { gaPageView } from '../../utils/analytics/google-analytics'
import hubspotService from '../../utils/hubspot'
import { Notification } from '../../utils/notification/notification.util'
import '../../utils/scripts'

export function CoreLayout() {
  const navigate = useNavigate()
  const location = useLocation()

  function handleAIDemo() {
    if (window.location.search.includes('ai-demo') || sessionStorage.getItem('ai-demo') !== null) {
      sessionStorage.setItem('ai-demo', 'true')

      if (!window.location.search.includes('ai-demo')) {
        const url = new URL(window.location.href)
        url.searchParams.append('ai-demo', '1')
        navigate(
          {
            pathname: url.pathname,
            search: url.search
          },
          {
            replace: true
          }
        )
      }

      hubspotService.refreshCustomerChat()
    }
  }

  /**************************
   * Initial load
   **************************/
  useEffect(() => {
    handleAIDemo()
  }, [])

  /**************************
   * Actions on page change
   **************************/
  useEffect(() => {
    Notification.reset()
    store.dispatch(updateLocation(location))
    gaPageView(location.pathname + location.search)

    hubspotService.setPath(location.pathname + location.search)
    hubspotService.trackPageView()

    handleAIDemo()
  }, [location.pathname])

  return (
    <div className='h-full'>
      <Routes>
        <Route element={<LazyAuthRoute />}>
          <Route path='/login' element={<Login />} />
        </Route>
        <Route path='/resetpassword' element={<LazyResetPasswordRoute />} />
        <Route path='/error' element={<LazyErrorRoute />} />
        <Route path='/*' element={<LazyAppLayoutRoute />} />
      </Routes>
    </div>
  )
}
