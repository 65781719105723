import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSMSSettings(): UseResource<SMSSettings.SmsSetting> {
  const queryName = [QUERY_NAME.smsSettings]

  const {
    isLoading,
    data,
    error: requestError
  } = useQuery<SMSSettingsService.GetSMSSettingsResponse>(queryName, api.getSMSSettings)

  const error: SerializedError = requestError

  return {
    data: data?.data,
    error,
    isLoading
  }
}
