import {
  type FlowItemUI,
  type FlowUI,
  getEntryFlowItemTrigger,
  getFlowTags,
  getLegalFlowItemTags,
  getNonEntryFlowItemTags,
  getNonEntryFlowItemTrigger,
  Trigger
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { removeSMSCampaignLocalStorage } from '../../../../routes/flow-editor/utils/local-storage'
import { api } from '../../../../utils/api'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors } from '../../../selectors'
import { type ThunkAPI } from '../../../types/thunk-api.type'
import { flowEditorActions } from '../flow-editor.reducer'

export function cascadeFlowProperty(
  flow: FlowUI,
  propName: keyof FlowUI & keyof FlowItemUI,
  value: any
) {
  if (value === undefined) {
    delete flow[propName]
  } else {
    // @ts-ignore
    flow[propName] = value
  }
  flow.sequenceItems.forEach((sequenceItem) => {
    if (value === undefined) {
      delete sequenceItem[propName]
    } else {
      // @ts-ignore
      sequenceItem[propName] = value
    }
  })
}

export const changeTriggerThunk = createAsyncThunk(
  'flowEditor/changeTrigger',
  async (args: { trigger: Trigger; isLegalOptional: boolean }, thunkAPI: any) => {
    const { getState, dispatch }: ThunkAPI = thunkAPI

    dispatch(
      flowEditorActions.triggerChanged({
        trigger: args.trigger,
        isLegalOptional: args.isLegalOptional
      })
    )

    const state = getState()
    const flow = cloneDeep(flowEditorSelectors.selectEditorFlow(state))
    const flowMeta = flowEditorSelectors.selectEditorFlowMeta(state)
    const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    /******************
     * Joined a segment (UI name: Custom triggers)
     ******************/
    if (args.trigger === Trigger.joinedASegment) {
      cascadeFlowProperty(flow, 'sendingFrequency', {
        type: 'lockAfterMessageCount',
        numberOfSentMessages: 0
      })
      cascadeFlowProperty(flow, 'isSmartSendingEnabled', false)
      cascadeFlowProperty(flow, 'isQuietHoursEnabled', true)

      /******************
       * SMS Campaign
       ******************/
    } else if (args.trigger === Trigger.smsCampaign) {
      cascadeFlowProperty(flow, 'sendingFrequency', undefined)
      cascadeFlowProperty(flow, 'isSmartSendingEnabled', true)
      cascadeFlowProperty(flow, 'isQuietHoursEnabled', true)

      /******************
       * Welcome flow
       ******************/
    } else if (args.trigger === Trigger.welcome) {
      cascadeFlowProperty(flow, 'sendingFrequency', undefined)
      cascadeFlowProperty(flow, 'isSmartSendingEnabled', false)
      cascadeFlowProperty(flow, 'isQuietHoursEnabled', false)

      /******************
       * Custom Integration
       ******************/
    } else if (args.trigger === Trigger.customIntegration) {
      cascadeFlowProperty(flow, 'sendingFrequency', undefined)
      cascadeFlowProperty(flow, 'isSmartSendingEnabled', false)
      cascadeFlowProperty(flow, 'isQuietHoursEnabled', false)

      /******************
       * Anything else
       ******************/
    } else {
      throw new Error(`${args.trigger} can not be changed`)
    }

    const entryFlowItem = flow.sequenceItems.find(
      (flowItem) => flowItem._id === flow.entrySequenceItemId
    )

    flow.tags = getFlowTags('sms', args.trigger)
    flow.sequenceItems.forEach((flowItem) => {
      flowItem.trigger = getNonEntryFlowItemTrigger(flowItem._id)
      flowItem.tags = getNonEntryFlowItemTags(flow.tags)
    })

    if (entryFlowItem) {
      if (args.trigger === Trigger.welcome) {
        entryFlowItem.tags = getLegalFlowItemTags(flow.tags)
      }
      entryFlowItem.trigger = getEntryFlowItemTrigger(args.trigger, flow._id, entryFlowItem._id)
    }

    dispatch(
      flowEditorActions.flowLoaded({
        flow,
        // TODO figure out
        source: 'server',
        isEverSaved: false,
        unsavedChanges: true,
        isPreviewOn: flowMeta.isPreviewOn,
        isAITextGeneratorWizardClosed: flowMeta.isAITextGeneratorWizardClosed,
        isLegalFlowItemDeletable: flowMeta.isLegalFlowItemDeletable,
        isWelcomeFlowWarningModalClosed: flowMeta.isWelcomeFlowWarningModalClosed
      })
    )

    if (args.trigger !== Trigger.smsCampaign) {
      removeSMSCampaignLocalStorage(flow._id)
      if (smsCampaign !== undefined && flowMeta.isEverSaved) {
        try {
          await api.removeSMSCampaign(smsCampaign._id)
        } catch (err) {
          throw new Error(FlowEditorError.RemoveSMSCampaignFailed, { cause: err })
        }
      }
    }
  },
  { serializeError }
)
