// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DCvt79FWo_9wogpo26Bl{margin-right:15px;width:100px}.DCvt79FWo_9wogpo26Bl button{width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/save-button/save-button.component.scss"],"names":[],"mappings":"AAGE,sBAEE,iBAAA,CADA,WACA,CAEA,6BACE,UAAA","sourcesContent":["@use '../../sequence-editor' as *;\n\n:local {\n  .button {\n    width: 100px;\n    margin-right: 15px;\n\n    button {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `DCvt79FWo_9wogpo26Bl`
};
export default ___CSS_LOADER_EXPORT___;
