import { BlastStatus } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { TimeFrameFilter } from '../../../../../control-components/make-filterable/filter-components/time-frame-filter/time-frame-filter.component'
import { TimeFrameOption } from '../../../../../control-components/make-filterable/filter-components/time-frame-filter/time-frame-option.type'
import { selectEditorBlast, selectEditorSequence } from '../../../../../store/selectors'
import {
  isBlastSequence,
  isCampaignSequence,
  isSMSSequence
} from '../../../utils/assert-sequence-type'

export function HeaderTimeFrameFilter() {
  const sequence = useSelector(selectEditorSequence)
  const blast = useSelector(selectEditorBlast)

  if (
    sequence === undefined ||
    (isSMSSequence(sequence) && !isBlastSequence(sequence) && !sequence.hasEverBeenEnabled) ||
    (isBlastSequence(sequence) && blast.status !== BlastStatus.SENT)
  ) {
    return null
  }

  const timeFrameOption = isCampaignSequence(sequence)
    ? TimeFrameOption.ALLTIME
    : TimeFrameOption.DAYS30
  return <TimeFrameFilter defaultTimeFrameFilter={timeFrameOption} />
}
