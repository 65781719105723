import { type FlowUI, type HttpResponse } from '@ghostmonitor/recartapis'
import { useEffect, useMemo, useState } from 'react'
import { type UseResource } from '../../../hooks/types/use-resource.type'
import { api } from '../../../utils/api'
import { FlowEditorError } from '../../../utils/flow-editor/flow-editor-errors'
import { createScope } from '../../../utils/logger/logger'
import { getFlowEditorLocalStorage } from '../utils/local-storage'
const { error } = createScope('flow-editor')

const isPreviewOnDefaultValue = true

export function useFlowLatest(flowId?: string): UseResource<FlowUI> & {
  source: 'localstorage' | 'server' | undefined
  unsavedChanges: boolean | undefined
  isEverSaved: boolean | undefined
  isPreviewOn: boolean
  isAITextGeneratorWizardClosed: boolean
  isWelcomeFlowWarningModalClosed: boolean
  isLoading: boolean
  isError: boolean
} {
  const [flow, setFlow] = useState<FlowUI | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(flowId !== undefined)
  const [isError, setIsError] = useState<boolean>(false)
  const [source, setSource] = useState<'localstorage' | 'server' | undefined>()
  const [unsavedChanges, setUnsavedChanges] = useState<boolean | undefined>()
  const [isEverSaved, setIsEverSaved] = useState<boolean | undefined>()
  const [isPreviewOn, setIsPreviewOn] = useState<boolean>(isPreviewOnDefaultValue)
  const [isAITextGeneratorWizardClosed, setIsAITextGeneratorWizardClosed] = useState<boolean>(false)
  const [isWelcomeFlowWarningModalClosed, setIsWelcomeFlowWarningModalClosed] =
    useState<boolean>(false)

  const flowInLocalStorage = useMemo(() => getFlowEditorLocalStorage(flowId), [flowId])

  useEffect(() => {
    if (!flowId) {
      return
    }

    setIsLoading(true)

    async function fetchFlow() {
      try {
        const flowFromDb = await api.getFlow(`${flowId}`)
        setSource('server')
        setUnsavedChanges(false)
        setIsPreviewOn(isPreviewOnDefaultValue)
        setIsAITextGeneratorWizardClosed(true)
        setIsWelcomeFlowWarningModalClosed(
          flowInLocalStorage?.isWelcomeFlowWarningModalClosed ?? false
        )
        setIsEverSaved(true)
        setFlow(flowFromDb)
      } catch (err: any) {
        error(new Error(FlowEditorError.CannotFetchFlow), { error: err, flowId })
        setIsError(true)
      }
    }

    async function fetchLatest() {
      let response: HttpResponse<FlowUI | null> | null
      try {
        response = await api.getFlowIfNewerExists(flowId!, flowInLocalStorage!.flow.updatedAt!)
      } catch (err: any) {
        error(new Error(FlowEditorError.CannotFetchFlow), { error: err, flowId })
        setIsError(true)
        return
      }

      // If there is no newer on the server
      if (response === null) {
        setSource('localstorage')
        setUnsavedChanges(flowInLocalStorage!.unsavedChanges)
        setIsPreviewOn(flowInLocalStorage!.isPreviewOn ?? isPreviewOnDefaultValue)
        setIsAITextGeneratorWizardClosed(flowInLocalStorage!.isAITextGeneratorWizardClosed ?? false)
        setIsWelcomeFlowWarningModalClosed(
          flowInLocalStorage!.isWelcomeFlowWarningModalClosed ?? false
        )
        setFlow(flowInLocalStorage!.flow)
        return
      }

      // Anyway use the server version
      const { data: flowOnServer } = response

      setSource('server')
      setUnsavedChanges(false)
      setIsPreviewOn(flowInLocalStorage!.isPreviewOn ?? isPreviewOnDefaultValue)
      setIsAITextGeneratorWizardClosed(flowInLocalStorage!.isAITextGeneratorWizardClosed ?? true)
      setIsWelcomeFlowWarningModalClosed(
        flowInLocalStorage!.isWelcomeFlowWarningModalClosed ?? false
      )
      setFlow(flowOnServer!)
    }

    if (flowInLocalStorage?.isEverSaved) {
      setIsEverSaved(true)
      fetchLatest()

      // There is no server version
    } else if (flowInLocalStorage) {
      setIsEverSaved(false)
      setSource('localstorage')
      setUnsavedChanges(flowInLocalStorage!.unsavedChanges)
      setIsPreviewOn(flowInLocalStorage!.isPreviewOn ?? isPreviewOnDefaultValue)
      setIsAITextGeneratorWizardClosed(flowInLocalStorage!.isAITextGeneratorWizardClosed ?? false)
      setIsWelcomeFlowWarningModalClosed(
        flowInLocalStorage!.isWelcomeFlowWarningModalClosed ?? false
      )
      setFlow(flowInLocalStorage!.flow)

      // There is no localstorage version
    } else {
      fetchFlow()
    }

    setIsLoading(false)
  }, [flowId, flowInLocalStorage])

  return {
    data: flow,
    isLoading: isLoading && flow === undefined,
    source,
    unsavedChanges,
    isPreviewOn,
    isAITextGeneratorWizardClosed,
    isWelcomeFlowWarningModalClosed,
    isEverSaved,
    isError
  }
}
