import {
  isMessengerMessageSequenceItemUI,
  type MessengerGenericTemplatePayload
} from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import { isMessageGenericGalleryTemplate } from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { createScope } from '../../../../utils/logger/logger'
import { type UpdateMessageItemAction } from '../sequence-editor.actions'
import { selectSequenceItem } from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')

export function updateMessageItemActionHandler(
  state: SequenceEditorState,
  action: UpdateMessageItemAction
) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]
    if (isMessageGenericGalleryTemplate(message) && action.messageItemIndex !== undefined) {
      const messageItemPayload: MessengerGenericTemplatePayload =
        message.messengerTemplatePayload[action.messageItemIndex]
      merge(messageItemPayload, action.messagePayloadChange)
    } else {
      info('updateMessageItemActionHandler:1', { action, sequenceItem })
    }
  } else {
    info('updateMessageItemActionHandler:2', { action, sequenceItem })
  }
}
