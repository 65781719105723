import {
  type ButtonUI,
  type GetStaticDiscountCodeResponse,
  isPhoneNumberButton,
  isWebUrlButton
} from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { createScope } from '../../../utils/logger/logger'
import { phoneNumberRegexp } from '../../../utils/phone-number-regexp'
import { webURLRegexp } from '../../../utils/regexps/web-url-regexp'
import { request } from '../../../utils/request'
const { error } = createScope('sequence-editor')

export const buttonValidationErrors = {
  EMPTY_TITLE: 'Button label cannot be empty',
  EMPTY_URL: 'Button url cannot be empty',
  INVALID_URL: 'Button url is invalid',
  EMPTY_PHONE_NUMBER: 'Phone number seems to be incorrect',
  INVALID_PHONE_NUMBER: 'Phone number seems to be incorrect',
  INVALID_DISCOUNT_CODE: 'Invalid discount code'
}

export async function buttonValidator(
  button: ButtonUI,
  validateDiscountCode = false
): Promise<SerializedError[]> {
  const validationErrors = []

  if (!button) {
    return []
  }

  if (button.title === '') {
    validationErrors.push(new Error(buttonValidationErrors.EMPTY_TITLE))
  }

  if (isWebUrlButton(button)) {
    if (button.url === '') {
      validationErrors.push(new Error(buttonValidationErrors.EMPTY_URL))
    } else if (!webURLRegexp.test(button.url)) {
      validationErrors.push(new Error(buttonValidationErrors.INVALID_URL))
    }
    if (validateDiscountCode && button.discount_code?.length > 0) {
      const code = button.discount_code

      try {
        await request.get<GetStaticDiscountCodeResponse>(`discount-codes/${code}`)
      } catch (e) {
        error(e)
        validationErrors.push(new Error(buttonValidationErrors.INVALID_DISCOUNT_CODE))
      }
    }
  }

  if (isPhoneNumberButton(button)) {
    if (button.phone_number === '') {
      validationErrors.push(new Error(buttonValidationErrors.EMPTY_PHONE_NUMBER))
    } else if (!phoneNumberRegexp.test(button.phone_number)) {
      validationErrors.push(new Error(buttonValidationErrors.INVALID_PHONE_NUMBER))
    }
  }

  return validationErrors
}
