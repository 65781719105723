import {
  type MessengerMessageSequenceItemUI,
  type QuickReply,
  type QuickReplyIndexToId
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'

export function attachQuickRepliesIdsOnSequenceItem(
  sequenceItem: MessengerMessageSequenceItemUI,
  quickReplyIndexToId: QuickReplyIndexToId
): MessengerMessageSequenceItemUI {
  const newSequenceItem = cloneDeep(sequenceItem)
  const quickReplyIndexToIdMap = quickReplyIndexToId[sequenceItem._id]

  newSequenceItem.quickReplies = newSequenceItem.quickReplies.map(
    (quickReply: QuickReply, index: number) => {
      return {
        ...quickReply,
        id: quickReplyIndexToIdMap[index]
      }
    }
  )
  return newSequenceItem
}
