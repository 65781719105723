import { AbstractPortFactory } from 'storm-react-diagrams'
import { type SequenceEditorPortModel } from './port-model'

export class SequenceEditorPortFactory extends AbstractPortFactory {
  public cb: (initialConfig?: any) => SequenceEditorPortModel

  constructor(type: string, cb: (initialConfig?: any) => SequenceEditorPortModel) {
    super(type)
    this.cb = cb
  }

  public getNewInstance(initialConfig?: any): SequenceEditorPortModel {
    return this.cb(initialConfig)
  }
}
