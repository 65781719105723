import { type Blast } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { type AxiosResponse } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { request } from '../../../../utils/request'
import type { AppDispatch } from '../../../create-store'
import { type DashboardState } from '../../../dashboard.state'
import { selectEditorBlast } from '../../../selectors'

export const saveBlastThunk = createAsyncThunk<
  Blast,
  void,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/saveBlast', async (args, store) => {
  const state = store.getState()
  const blast = cloneDeep(selectEditorBlast(state))

  delete blast.name
  delete blast.createdAt
  delete blast.updatedAt

  const response = await request.put<AxiosResponse<Blast>>(`/blasts/${blast._id}`, blast)

  return response.data
})
