import { api } from '../../utils/api'
import { axiosInstance } from '../../utils/axios-instance'
import { type AxiosResponse } from 'axios'
import { type HttpResponse, type User } from '@ghostmonitor/recartapis'
import { QUERY_NAME } from './query-names'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { type UseResource } from '../types/use-resource.type'

type UseUserResource = UseResource<User, Partial<User>> & {
  changePassword: (currentPassword: string, password: string) => Promise<AxiosResponse<User>>
}

const changePassword = async (
  currentPassword: string,
  password: string
): Promise<AxiosResponse<User>> => {
  return await axiosInstance.put<User, AxiosResponse<User>>('me/password', {
    currentPassword,
    password
  })
}

export function useUser(): UseUserResource {
  const queryName = [QUERY_NAME.user]
  const { isLoading, data: response, isError } = useQuery(queryName, api.getUser)
  const { handlePatch, handleSettled, handleError } = useMutationHelpers<User>(queryName)
  const { mutateAsync: patchUser } = useMutation<HttpResponse<User>, unknown, Partial<User>>(
    api.patchUser,
    {
      onMutate: handlePatch,
      onSettled: handleSettled,
      onError: handleError
    }
  )

  return {
    data: response?.data,
    patch: patchUser,
    isError,
    isLoading,
    changePassword
  }
}
