import {
  type FlowDiagram,
  type FlowItemUI,
  getEntryFlowItemTags,
  getEntryFlowItemTrigger,
  getFlowTrigger,
  getNonEntryFlowItemTags,
  getNonEntryFlowItemTrigger,
  NodeType
} from '@ghostmonitor/recartapis'

import { selectEditorFlow } from '../flow-editor.selectors'
import { type FlowEditorState } from '../flow-editor.state'
import { connectNodeTo } from './connect-node'
import { insertFlowItem } from './insert-flow-item'
import { insertNode, insertNodeBetweenNodes } from './insert-node'

// Related design review:
// https://www.notion.so/recart/Allowing-to-change-entrySequenceItemId-on-active-sequences-a3c363f833744bf99c2f75d49179be15#050c42bd884e43318664056f257a68a4

export function detachEntryFlowItem(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const entryFlowItem = flow.sequenceItems.find(
    (flowItem) => flowItem._id === flow.entrySequenceItemId
  )

  if (!entryFlowItem) {
    throw new Error('Entry flow item not found')
  }

  // 1: Tags
  entryFlowItem.tags = getNonEntryFlowItemTags(flow.tags)

  // 2: Trigger
  entryFlowItem.trigger = getNonEntryFlowItemTrigger(entryFlowItem._id)

  // 3: entrySequenceItemId
  delete flow.entrySequenceItemId
}

// The only way to attach an entry flow item is if there is no entry flow item currently attached
export function attachEntryFlowItem(state: FlowEditorState, newEntryFlowItemId: string): void {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const newEntryFlowItem = flow.sequenceItems.find(
    (flowItem) => flowItem._id === newEntryFlowItemId
  )

  if (!newEntryFlowItem) {
    throw new Error('New entry flow item not found')
  }

  // 1: Tags
  // SequenceTag.FIRST_TEXT_MESSAGE will be handled on flow save
  newEntryFlowItem.tags = getEntryFlowItemTags(flow.tags)

  // 2: Trigger
  newEntryFlowItem.trigger = getEntryFlowItemTrigger(
    getFlowTrigger(flow.tags),
    flow._id,
    newEntryFlowItemId,
    flow.segmentId
  )

  // 3: entrySequenceItemId
  flow.entrySequenceItemId = newEntryFlowItemId
}

// eslint-disable-next-line @typescript-eslint/max-params
export function insertEntryFlowItem(
  state: FlowEditorState,
  nodeType: NodeType,
  flowItem: FlowItemUI,
  undeletable = false,
  uneditable = false
) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  insertFlowItem(state, flowItem)
  const newNode = insertNode(
    state,
    flowItem._id,
    nodeType,
    undefined,
    undefined,
    undeletable,
    uneditable
  )

  const entryFlowItemId = flow.entrySequenceItemId

  const sourceNode = flow.flowEditorDiagram.nodes.find((node) => node.type === NodeType.TRIGGER)

  if (!sourceNode) {
    throw new Error('Source node not found')
  }

  // The connection from Trigger node always comes from the first followup handle
  const sourceHandle: FlowDiagram.Handle | undefined = sourceNode.data.followUpHandle

  if (!sourceHandle) {
    throw new Error('Source handle not found')
  }

  /**********************************************
   * Trigger is connected to the entry flow item
   ***********************************************/
  if (entryFlowItemId) {
    detachEntryFlowItem(state)

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.data.flowItemId === entryFlowItemId
    )

    if (!targetNode) {
      throw new Error('Target node not found')
    }

    const targetHandle = targetNode.data.inputHandle

    if (!targetHandle) {
      throw new Error('Target handle not found')
    }

    insertNodeBetweenNodes(
      state,
      sourceNode.id,
      sourceHandle.id,
      targetNode.id,
      targetHandle.id,
      newNode,
      undeletable
    )
  } else {
    /******************************************************
     * Trigger is not currently connected to any flow item
     ******************************************************/
    connectNodeTo(state, newNode, sourceNode.id, sourceHandle.id, undeletable)
  }

  attachEntryFlowItem(state, flowItem._id)
}
