import { Radio, Space, Tooltip, type CheckboxOptionType, type RadioGroupProps } from 'antd'
import cn from 'classnames'
import { isValidElement } from 'react'
import { ReactComponent as InfoIcon } from '../../../static/images/svg/sequence-editor/info-icon.svg'
import styles from './labelled-radio-group.component.scss'

export interface LabelledRadioGroupProps extends RadioGroupProps {
  label?: string | React.ReactNode
  toolTipText?: string
}

function getLabelElement(label: string | React.ReactNode, toolTipText?: string): React.ReactNode {
  if (isValidElement(label)) {
    return label
  }

  if (typeof label === 'string') {
    return (
      <div className='flex items-center mb-2'>
        <div className='text-sm'>{label}</div>
        {toolTipText && (
          <Tooltip placement='top' title={toolTipText} arrowPointAtCenter>
            <div className={cn(styles.tooltipIcon, 'icon-tiny block')}>
              <InfoIcon />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  return null
}

function getRadio(option: number | string | CheckboxOptionType, idx: number): React.ReactElement {
  if (typeof option !== 'string' && typeof option !== 'number') {
    return (
      <Radio value={option.value} key={`${idx}${option.label}`}>
        {option.label}
      </Radio>
    )
  }

  return (
    <Radio value={option} key={`${idx}${option}`}>
      {option}
    </Radio>
  )
}

export function LabelledRadioGroup(props: LabelledRadioGroupProps) {
  const { options, label, toolTipText, ...restProps } = props

  return (
    <div className={styles.container}>
      {getLabelElement(label, toolTipText)}
      <Radio.Group {...restProps}>
        <Space direction='vertical' size='small'>
          {options?.map(getRadio)}
        </Space>
      </Radio.Group>
    </div>
  )
}
