import {
  type MessengerMessageSequenceItemUI,
  SequenceTag,
  type SequenceUI,
  type SMSSequenceItemUI
} from '@ghostmonitor/recartapis'

export function attachCanConvert(
  sequence: SequenceUI,
  sequenceItem: MessengerMessageSequenceItemUI
): MessengerMessageSequenceItemUI {
  const isEntry =
    sequence.entrySequenceItemId === sequenceItem._id || sequence.sequenceItemIds?.length === 0
  const isWelcomeEntry = isEntry && sequence.tags.includes(SequenceTag.WELCOME)
  const isConvertableSequence = [SequenceTag.UNSUBSCRIBE].every((tag) => {
    return !sequence.tags.includes(tag)
  })

  return {
    ...sequenceItem,
    canConvert: !isWelcomeEntry && isConvertableSequence
  }
}

export function attachCanConvertToNewSMSMessageSequenceItem(
  sequence: SequenceUI,
  sequenceItem: SMSSequenceItemUI
): SMSSequenceItemUI {
  const isConvertableSequence = [SequenceTag.UNSUBSCRIBE, SequenceTag.HELP].every(
    (tag) => !sequence.tags.includes(tag)
  )

  const isConvertableSequenceItem =
    !sequenceItem.tags.includes(SequenceTag.WELCOME) || sequence.sequenceItemIds.length >= 1

  return {
    ...sequenceItem,
    canConvert: isConvertableSequenceItem && isConvertableSequence
  }
}
