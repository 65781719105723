import { type OptinToolListView } from '@ghostmonitor/recartapis'
import { getOptinToolsBySequenceId } from '../../utils/utils'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useOptinToolsByFlowId(flowId: string | null): UseResource<OptinToolListView[]> {
  const { data: optinTools, isLoading } = hooks.useOptinToolsList()

  if (!flowId) {
    return {
      data: [],
      isLoading: false
    }
  }

  const optinToolsUsingFlow = getOptinToolsBySequenceId(optinTools, flowId)

  return {
    data: optinToolsUsingFlow,
    isLoading
  }
}
