import { type SequenceItemUI, type SequenceUI } from '@ghostmonitor/recartapis'
import debounce from 'lodash/debounce'
import { batch } from 'react-redux'
import { type Action, type Store } from '@reduxjs/toolkit'
import type { AppDispatch } from '../../../../store/create-store'
import { type DashboardState } from '../../../../store/dashboard.state'
import { selectEditorSequence, selectSequenceItem } from '../../../../store/selectors'
import {
  type RemoveSequenceItemAction,
  REMOVE_SEQUENCE_ITEM,
  sequenceItemValidationError,
  sequenceItemValidationSuccess,
  type UpdateDelayValue,
  type UpdateModelOnSequenceAction,
  UPDATE_DELAY_VALUE,
  UPDATE_MODEL_ON_SEQUENCE
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { sequenceValidator } from '../../validators/sequence.validator'

const DEBOUNCE_DELAY = 400

function getValidateSequence(dispatch: AppDispatch) {
  return debounce(
    (sequence: SequenceUI, sequenceItems: SequenceItemUI[]) => {
      const { sequenceItemErrors } = sequenceValidator(sequence, sequenceItems)
      const actions = []
      Object.entries(sequenceItemErrors).forEach(([sequenceItemId, errors]) => {
        if (errors.length > 0) {
          actions.push(
            sequenceItemValidationError(errors[0], {
              sequenceItemId
            })
          )
        } else {
          actions.push(
            sequenceItemValidationSuccess(null, {
              sequenceItemId
            })
          )
        }
      })
      batch(() => {
        actions.forEach(dispatch)
      })
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

type ValidableSequenceItemAction =
  | UpdateDelayValue
  | UpdateModelOnSequenceAction
  | RemoveSequenceItemAction

function isValidableAction(action: Action): action is ValidableSequenceItemAction {
  return [UPDATE_DELAY_VALUE, REMOVE_SEQUENCE_ITEM, UPDATE_MODEL_ON_SEQUENCE].includes(action.type)
}

export function validateSequenceMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateSequence = getValidateSequence(dispatch)

  return (next) => (action: ValidatableAction) => {
    next(action)

    if (isValidableAction(action)) {
      const state = store.getState()

      const sequence = selectEditorSequence(state)
      const sequenceItems = sequence.sequenceItemIds.map((sequenceItemId) =>
        selectSequenceItem(sequenceItemId)(state)
      )

      validateSequence(sequence, sequenceItems)
    }
  }
}
