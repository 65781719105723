// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tla4AmrgpWz6l2SLwlsr{max-width:320px}.tla4AmrgpWz6l2SLwlsr .ant-tooltip-arrow-content,.tla4AmrgpWz6l2SLwlsr .ant-tooltip-inner{background-color:var(--recart-dark-grey-2)}.tla4AmrgpWz6l2SLwlsr .ant-tooltip-inner{padding:var(--recart-spacing-1) var(--recart-spacing-2)}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/tooltip/tooltip.component.scss"],"names":[],"mappings":"AACE,sBACE,eAAA,CAGE,0FAEE,0CAAA,CAGF,yCACE,uDAAA","sourcesContent":[":local {\n  .tooltip {\n    max-width: 320px;\n\n    :global {\n      .ant-tooltip-arrow-content,\n      .ant-tooltip-inner {\n        background-color: var(--recart-dark-grey-2);\n      }\n\n      .ant-tooltip-inner {\n        padding: var(--recart-spacing-1) var(--recart-spacing-2);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tla4AmrgpWz6l2SLwlsr`
};
export default ___CSS_LOADER_EXPORT___;
