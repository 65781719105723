import {
  type FlowUI,
  type SMSCampaign,
  SMSCampaignStatus,
  type URLGenerationSettingsInUse
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'

interface GetNewSMSCampaignArgs {
  flow: FlowUI
  siteId: string
  urlGenerationSettings?: URLGenerationSettingsInUse
}

export function getNewSMSCampaign({
  flow,
  siteId,
  urlGenerationSettings
}: GetNewSMSCampaignArgs): SMSCampaign {
  return {
    _id: new ObjectId().toHexString(),
    createdAt: new Date().toISOString(),
    isQuietHoursEnabled: true,
    name: flow.name ?? '',
    scheduleType: 'siteTimezone',
    scheduledFor: null,
    sequenceId: flow._id,
    siteId,
    status: SMSCampaignStatus.DRAFT,
    updatedAt: new Date().toISOString(),
    ...(urlGenerationSettings
      ? {
          domain: urlGenerationSettings.domain,
          subdomain: urlGenerationSettings.subdomain
        }
      : {})
  }
}
