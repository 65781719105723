import { type WebUrlButton } from '@ghostmonitor/recartapis'
import { ControlledTextField } from '../controlled-text-field.component'
import { type ButtonPopoverFormProps } from '../types/button-popover-form.type'

export function WebUrlPopoverWithoutDiscountCodeForm(props: ButtonPopoverFormProps<WebUrlButton>) {
  return (
    <ControlledTextField
      label='Button url'
      value={props.payload.url}
      placeholder='http://example.com'
      onChange={(url) => {
        props.onChange({ url })
      }}
      onBlur={props.onBlur}
    />
  )
}
