import { type MessengerGenericTemplatePayload } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import Ajv from 'ajv'
import ajvErrors from 'ajv-errors'

const ajv = new Ajv({ allErrors: true, strict: true })
ajvErrors(ajv, { singleError: true })

const messengerGenericTemplatePayloadSchema = ajv.compile({
  type: 'object',
  properties: {
    buttons: {
      type: 'array'
    },
    title: {
      type: 'string',
      minLength: 1
    },
    subtitle: {
      type: 'string',
      minLength: 1
    },
    image_url: {
      type: 'string',
      minLength: 1
    }
  },
  required: ['buttons', 'title'],
  additionalProperties: false,
  errorMessage: {
    properties: {
      title: "Message title can't be empty",
      image_url: 'Please select an attachment file'
    }
  }
})

export function messageItemValidator(
  messageItemTemplatePayload: MessengerGenericTemplatePayload
): SerializedError[] {
  const errors: SerializedError[] = []

  const valid = messengerGenericTemplatePayloadSchema(messageItemTemplatePayload)
  if (!valid && messengerGenericTemplatePayloadSchema.errors) {
    errors.push(messengerGenericTemplatePayloadSchema.errors[0])
  }

  return errors
}
