// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JdRlaRyMrXI0fxG4dviZ .ant-modal-body{padding:var(--recart-spacing-5)}.JdRlaRyMrXI0fxG4dviZ .ant-modal-content{border-radius:var(--recart-border-radius)}.JdRlaRyMrXI0fxG4dviZ .ant-modal-close{right:var(--recart-spacing-3);top:var(--recart-spacing-3)}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/modal/modal.component.scss"],"names":[],"mappings":"AAGM,sCACE,+BAAA,CAGF,yCACE,yCAAA,CAGF,uCAEE,6BAAA,CADA,2BACA","sourcesContent":[":local {\n  .modalContainer {\n    :global {\n      .ant-modal-body {\n        padding: var(--recart-spacing-5);\n      }\n\n      .ant-modal-content {\n        border-radius: var(--recart-border-radius);\n      }\n\n      .ant-modal-close {\n        top: var(--recart-spacing-3);\n        right: var(--recart-spacing-3);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `JdRlaRyMrXI0fxG4dviZ`
};
export default ___CSS_LOADER_EXPORT___;
