import { type RandomSplitSequenceItemUI } from '@ghostmonitor/recartapis'
import { type RandomSplitSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/random-split/random-split-sequence-item-model'
import { type RemoveSplitAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectSplitMeta
} from '../sequence-editor.selectors'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { type SequenceEditorState } from '../sequence-editor.state'

export function removeSplitActionHandler(state: SequenceEditorState, action: RemoveSplitAction) {
  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem<RandomSplitSequenceItemUI>(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  const diagramModel = getDiagramModel(sequence.serializedDiagram)
  const sequenceItemNode = getNodeModel<RandomSplitSequenceItemNodeModel>(
    diagramModel,
    action.sequenceItemId
  )

  const portName = sequenceItemNode.generateSplitPortName(action.sequenceItemId, action.splitIndex)
  sequenceItemNode.removeSplitPort(portName)
  sequence.serializedDiagram = diagramModel.serializeDiagram() as any

  const splitMeta = selectSplitMeta(action.sequenceItemId, action.splitIndex)(state)
  if (splitMeta.error) {
    state.errorCount -= 1
  }

  sequenceItem.logic.randomSplit.variants.splice(action.splitIndex, 1)
  sequenceItemMeta.splits.splice(action.splitIndex, 1)
}
