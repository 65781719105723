import { DateFilterType } from '@ghostmonitor/recartapis'
import { createExpressionValidators } from '../../../components/expression-builder/expression-validator'
import { type ExpressionUI } from '../../../types/segment/condition-ui.type'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  type FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'

// for first parameter of antd the validator
const _ = undefined

export async function validateExpression(
  expression: ExpressionUI
): Promise<FlowEditorValidationError | undefined> {
  let hasError = false
  for (const andCondition of expression.and) {
    for (const orCondition of andCondition.or) {
      const validators = createExpressionValidators(orCondition)

      try {
        await validators.validateSlug(_, orCondition.slug)
        await validators.validateConditionValue(_, orCondition.value as string)
        if (orCondition.dateType) {
          switch (orCondition.dateType) {
            case DateFilterType.All:
            case DateFilterType.Exist:
            case DateFilterType.NotExist:
              break
            case DateFilterType.InTheLast:
            case DateFilterType.NotInTheLast:
              await validators.validateDateOffset(_, String(orCondition.daysOffset))
              break
            case DateFilterType.Between:
            case DateFilterType.NotBetween:
              await validators.validateDateRange(_, orCondition.dateRange)
              break
            case DateFilterType.On:
            case DateFilterType.Since:
            case DateFilterType.Until:
              await validators.validateDate()
              break
            default:
              throw new Error('Invalid date type')
          }
        }
        if (orCondition.subconditionSlug) {
          await validators.validateSubconditionValue(_, orCondition.subconditionValue as string)
        }
      } catch {
        hasError = true
        break
      }
    }
  }

  if (hasError) {
    return {
      message: FlowEditorError.ConditionalSplitV2ExpressionError,
      level: FlowEditorErrorLevel.Error
    }
  }

  return undefined
}
