import { Skeleton } from 'antd'
import cn from 'classnames'
import { type SkeletonProps } from '../list/types/skeleton.type'
import styles from './text-skeleton.style.scss'

type TextSkeletonProps = SkeletonProps & {
  width?: number[] | number | string
  size: 'tiny' | 'caption' | 'sm' | 'base' | 'lg' | 'h1' | 'h2' | 'h3' | 'message'
  rows?: number
  isInactive?: boolean
}

export function TextSkeleton(props: TextSkeletonProps) {
  return (
    <Skeleton
      className={cn(styles[props.size], styles.loader, props.className)}
      loading={props.isLoading}
      active={!props.isInactive}
      paragraph={{ rows: props.rows ?? 1, width: props.width }}
      title={false}
    >
      {props.children}
    </Skeleton>
  )
}
