import {
  type DuplicateSegmentRequest,
  type DuplicateSegmentResponse,
  type SegmentListResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_NAME } from './query-names'

export function useSegmentListDuplicate() {
  const queryClient = useQueryClient()

  const { mutateAsync: duplicateSegment, isLoading: isDuplicateSegmentLoading } = useMutation<
    DuplicateSegmentResponse,
    unknown,
    DuplicateSegmentRequest & { segmentId: string }
  >(
    ({ segmentId, name, type }) => {
      const segment: DuplicateSegmentRequest = {
        name,
        type
      }

      return request.post<DuplicateSegmentResponse>(`segments/${segmentId}/duplicate`, segment)
    },
    {
      onSuccess: (duplicateSegmentResponse: DuplicateSegmentResponse) => {
        const listQueryName = [QUERY_NAME.segmentsList, null]
        const segmentsList: SegmentListResponse = queryClient.getQueryData(listQueryName)
        if (segmentsList) {
          const newSegmentsList = {
            data: [...segmentsList.data, duplicateSegmentResponse.data]
          }

          queryClient.setQueryData(listQueryName, newSegmentsList)
          queryClient.invalidateQueries(listQueryName)
        }
      }
    }
  )

  return { duplicateSegment, isDuplicateSegmentLoading }
}
