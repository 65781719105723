import { isMessengerMessageSequenceItemUI } from '@ghostmonitor/recartapis'
import { type MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { type RemoveQuickReplyAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectQuickReplyMeta,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { type SequenceEditorState } from '../sequence-editor.state'

export function removeQuickReplyActionHandler(
  state: SequenceEditorState,
  action: RemoveQuickReplyAction
) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const sequence = selectEditorSequence(state)
    const diagramModel = getDiagramModel(sequence.serializedDiagram)

    const quickReply = sequenceItem.quickReplies[action.quickReplyIndex]
    sequenceItem.quickReplies.splice(action.quickReplyIndex, 1)

    const quickReplyMeta = selectQuickReplyMeta(sequenceItem._id, action.quickReplyIndex)(state)
    if (quickReplyMeta.error) {
      state.errorCount -= 1
    }

    sequenceItemMeta.quickReplies.splice(action.quickReplyIndex, 1)

    let quickReplyIndex = 0
    const updatedQuickReplyIndexToId = Object.entries(
      sequence.quickReplyIndexToId[action.sequenceItemId]
    ).reduce((quickReplyIndexToId, [_, quickReplyId]) => {
      if (quickReplyId !== quickReply.id) {
        return {
          ...quickReplyIndexToId,
          [quickReplyIndex++]: quickReplyId
        }
      }
      return quickReplyIndexToId
    }, {})

    sequence.quickReplyIndexToId[action.sequenceItemId] = updatedQuickReplyIndexToId

    const sequenceItemNode = getNodeModel<MessageSequenceItemNodeModel>(
      diagramModel,
      action.sequenceItemId
    )
    sequenceItemNode.removeQuickReply(quickReply.id)

    sequence.serializedDiagram = diagramModel.serializeDiagram() as any
  }
}
