import { type OptinToolListView, type OptinToolStatistics } from '@ghostmonitor/recartapis'
import { sortOptinToolListByStatus } from '../routes/optin-tools/utils/helpers'
import { hooks } from './hooks'

interface SubscriptionSourceStat {
  subscriptionSourceStat?: {
    messenger?: number
    email?: number
    sms?: number
  }
}

export type OptinToolsListData = OptinToolListView & OptinToolStatistics & SubscriptionSourceStat

export type PartialOptinToolListDataWithType = Partial<OptinToolsListData> &
  Pick<OptinToolsListData, 'type'>

export function useOptinToolsListData() {
  const { data: optinTools, isLoading } = hooks.useOptinToolsList()

  const popupOptinTools = optinTools?.filter((tool) => tool.type === 'popup') ?? []
  const keywordOptinTools = optinTools?.filter((tool) => tool.type === 'keyword') ?? []
  const landingPageOptinTools = optinTools?.filter((tool) => tool.type === 'landing-page') ?? []
  const embeddedFormOptinTools = optinTools?.filter((tool) => tool.type === 'embedded-form') ?? []
  const customerChat = optinTools?.filter((optinTool) => optinTool.type === 'customer-chat') ?? []
  const isSMSFeatureEnabled = hooks.useFeatureFlag('enable-sms-feature')

  const data: PartialOptinToolListDataWithType[] = [
    ...(isSMSFeatureEnabled ? keywordOptinTools : []),
    ...(isSMSFeatureEnabled ? landingPageOptinTools : []),
    ...(isSMSFeatureEnabled ? embeddedFormOptinTools : []),
    ...popupOptinTools,
    ...customerChat
  ]

  return {
    optinToolsListData: sortOptinToolListByStatus(data),
    isLoading
  }
}
